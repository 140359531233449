// features/Documents/DocumentsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import chalk from "chalk";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createExtraReducersSet, extraReducersSet, consoleLog } from "../common";

const generate = (args = {}) => {
  const { storeName, privateActions } = args;

  const initialState = {};

  const _slice = createSlice({
    name: storeName,
    initialState,
    reducers: {
      update(state, action) {
        consoleLog({ storeName, subActionName: "update" }, action.payload);

        if (!action.payload) return;

        const { key, value, persist } = action.payload;
        state[key] = state[key] || {};
        state[key].value = value;

        if ("persist" in action.payload) state[key].persist = persist;
      },

      reset(state) {
        const deleteHitlist = Object.entries(state)
          .filter(([, value]) => value.persist !== true) // include entries that are undefined or missing
          .map(([key]) => key);

        consoleLog({ storeName, subActionName: "reset" }, deleteHitlist);

        deleteHitlist.forEach((k) => {
          delete state[k];
        });
      },
    },
  });

  return { reducer: _slice.reducer, actions: _slice.actions };
};

export default generate;
