import _ from "lodash";

// import {
//   fnGetRiskItem as _fnGetRiskItem,
//   fnGetRiskData as _fnGetRiskData,
// } from "./utils";

import riskUtils from "./utils/risk";
import riskUtilsInitial from "./utils/risk/initial";

import templateUtils from "./utils/template";

// const fnIsError = (obj) => {
//   if (!obj) return false;
//   if (_.isArray(obj)) return obj.some((d) => fnIsError(d));
//   if (_.isObject(obj))
//     return Object.entries(obj).some(([key, data]) => {
//       if (key === "_error") {
//         if (!_.isEmpty(data)) return true;
//         return false;
//       }
//       if (_.isObject(data)) return fnIsError(data);
//     });
//   return false;
// };

const fnCheckObjForError = (obj, level = 1) => {
  //
};

const generate = (args = {}) => {
  const { storeName, template } = args;

  const fnGetSubState = (state) => state[storeName];
  const fnGetRiskItem = (state, path) =>
    riskUtils.find.item(fnGetSubState(state), path);
  const fnGetRiskData = (state, path) =>
    riskUtils.find.data(fnGetSubState(state), path);

  const fnGetRiskGroupData = (state, group) => {
    const subState = fnGetSubState(state);
    return subState.userData.risk.groups[group];
  };

  // const fnGetObjects = (obj, path) => {
  //   if (path.endsWith("/*"))
  //     return _.get(obj, path.substring(0, path.length - 2));
  //   return _.get(obj, path);
  // };

  const fnIsValid = (state, pathArray = [], options = {}) => {
    const isValid = !riskUtils.is.error(fnGetSubState(state), pathArray);
    return isValid;
  };

  const fnGenerateSelector_SalusData =
    ({ riskUtils }) =>
    // Pass in the config data we need
    (state, options = {}) => {
      // The final function
      // const baseData = fnGetRiskData(state);
      const { keyArrayId = undefined, stripEmptyValues = true } = options;
      const retData = riskUtils.process.buildTree(fnGetSubState(state), "", {
        keyArrayId: keyArrayId,
        fnProcessItem: (curNode, args = {}) => {
          const { searchPath } = args;
          //
          // console.log("searchPath", searchPath);

          const curItemTemplateData = templateUtils.getData(
            template,
            searchPath
          );

          // if (searchPath.join("/") === "Risk/ExtraDriverDetails/ProtectedNcd") {
          //   console.log({ curNode, curItemTemplateData });
          //   throw `hhh`;
          // }

          if (!curItemTemplateData?.found) {
            return {
              data: curNode?._value,
              output: false,
            };
          }

          // Empty value found:
          if (stripEmptyValues && curNode?._value === undefined) {
            return {
              data: curNode?._value,
              output: false,
            };
          }

          const retData = {
            data: curItemTemplateData.data.salus.fnTo(curNode?._value),
            output: curItemTemplateData?.data.isSubmit, //x?._submitKey ? true : false,
          };

          return retData;
        },
      });

      // console.log("SELECTORS salusData :", retData);
      return retData;
    };

  return {
    userData: {
      risk: {
        salusData: fnGenerateSelector_SalusData({ riskUtils }),
        full: (state) => fnGetRiskData(state),
        item: (state, path) => {
          if (!path) throw `Error in selector.userData.risk -- missing "path"`;
          const foundObject = fnGetRiskItem(state, path);

          if (!foundObject) {
            return undefined;
          }
          return foundObject;
        },

        metaData: (state, path) => {
          if (!path)
            throw `Error in selector.userData.metaData -- missing "path"`;

          const foundObject = fnGetRiskItem(state, path);

          if (!foundObject) {
            return undefined;
          }
          return {
            value: foundObject["_value"],
            hidden: foundObject["_hidden"],
            error: foundObject["_error"],
            errorShow: foundObject["_errorShow"],
            submitKey: foundObject["_submitKey"],
          };
        },

        metaDataTree: (
          state,
          path,
          processNode = (metaData) => ({ data: metaData, output: true })
        ) => {
          return riskUtils.process.buildTree(fnGetSubState(state), path, {
            fnProcessItem: processNode,
            fnProcessArrayItem: (id, data) => {
              return { ["_id"]: id, ...data };
            },
          });
        },
        getGroupPaths: (state, group) => fnGetRiskGroupData(state, group),
      },
      riskInitial: {
        salusData: fnGenerateSelector_SalusData({
          riskUtils: riskUtilsInitial,
        }),
        metaDataTree: (
          state,
          path,
          processNode = (metaData) => ({ data: metaData, output: true })
        ) => {
          return riskUtilsInitial.process.buildTree(
            fnGetSubState(state),
            path,
            {
              fnProcessItem: processNode,
              fnProcessArrayItem: (id, data) => {
                return { ["_id"]: id, ...data };
              },
            }
          );
        },
      },
    },
    errors: {
      isValid: fnIsValid,
      isGroupValid(state, group) {
        const pathList = fnGetRiskGroupData(state, group);

        const isValid = (function () {
          if (!pathList) return false;
          if (pathList.length === 0) return false;
          return fnIsValid(state, pathList);
        })();

        console.log(`ISGROUPVALID(${group})`, "=", isValid);
        return isValid;
      },
      // isValidGroup(state, group) {
      //   return true;
      // },
      list: (state, searchPath) => {
        const retData = [];
        // console.log("list", searchPath)
        const subState = fnGetSubState(state);

        riskUtils.process.all(
          subState,
          // state,
          searchPath,
          (obj, args = {}) => {
            if (riskUtils.is.error(subState, [args.searchPath.join("/")])) {
              retData.push({
                path: args.searchPath.join("/"),
                error: obj._error,
              });
            }
          }
        );
        return retData;
      },
    },
    // debug: {
    // userData: {
    //   risk: (state) => {
    //     return fnGetRiskData(state);
    //   },
    // },
    // logger: (state) => {
    //   const subState = fnGetSubState(state);
    //   return subState.logger;
    // },
    // },
  };
};

export default generate;
