import React from "react";
import BaseComponent from "./Base.Component";

const HeadingWithIcon = ({ icon, children }) => {
  const iconClass = ["icon", icon].toClassName();

  return (
    <div className="d-flex align-items-center renewal-start-heading">
      <div className="flex-shrink-0">
        <span className={iconClass}></span>
      </div>
      <div className="text">{children}</div>
    </div>
  );
};

const PanelRenewal = (props) => {
  const {
    className,
    classNameHeading,
    classNameBody,
    iconHeading,
    header,
    heading,
    ...otherProps
  } = props;

  const mainHeading = (function () {
    const text = header || heading;

    if (iconHeading)
      return (
        <div className="d-flex align-items-center icon-header">
          <div className="flex-shrink-0">
            <span className={["icon", iconHeading].toClassName()}></span>
          </div>
          <div className="text">{text}</div>
        </div>
      );

    return <div className="text">{text}</div>;
  })();

  const _classContainer = ["panel-renewal", "card", "mb-4", className].toClassName();
  const _classNameHeading = [
    "card-header",
    !iconHeading && "heading-main",
    classNameHeading,
  ].toClassName();
  const _classNameBody = ["card-body", classNameBody].toClassName();

  return (
    <BaseComponent
      heading={mainHeading}
      classNameContainer={_classContainer}
      classNameHeading={_classNameHeading}
      classNameBody={_classNameBody}
      {...otherProps}
    />
  );
};

export default PanelRenewal;
