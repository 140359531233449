import generateAuthSlice from "./authSlice";
import generateAuthActions from "./authActions";
import generateAuthSelectors from "./authSelectors";
import generateAuthHooks from "./authHooks";

const fnThrowError = (msg) => {
  throw `Error in create auth store: ${msg}`;
};

const generateStore = (args = {}) => {
  const { storeName, services = {} } = args;

  if (!storeName) fnThrowError("missing storeName");

  ["create", "activate", "authenticate", "getUserDetails", "link"].forEach(
    (k) => {
      if (!(k in services)) fnThrowError(`missing "services.${k}"`);
    }
  );

  const authSelectors = generateAuthSelectors(args);
  const authActions = generateAuthActions({
    ...args,
    selectors: authSelectors,
  });
  const authSlice = generateAuthSlice({ ...args, authActions });
  const authHooks = generateAuthHooks({
    ...args,
    authSelectors,
    authActions: { ...authActions, ...authSlice.actions },
  });

  const retData = {
    storeName,
    reducer: authSlice.reducer,
    actions: { ...authActions, ...authSlice.actions },
    selectors: authSelectors,
    hooks: authHooks,
  };

  console.log("STORE", "auth", storeName, "=", retData);
  return retData;
};

export default generateStore;
