import databaseClass from "@library/common/database/classes/database";

const _library = new databaseClass({
  database: require("./base/car").default({ mode: "mta" }),
});

export const database = _library.database;
export const getDatabaseSet = _library.functions.getDatabaseSet;
export const getProps = _library.functions.getProps;
export const rules = require("./rules").default;
