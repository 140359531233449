import React from "react";
import Card from "./Card";

const CardBorder = (props) => {
  const {
    className,
    classNameHeader,
    classNameBody,
    classNameFooter,
    ...otherProps
  } = props;

  const _className = ["card-border", className].toClassName();
  const _classNameHeader = [classNameHeader].toClassName();
  const _classNameBody = [classNameBody].toClassName();
  const _classNameFooter = [classNameFooter].toClassName();

  return (
    <Card
      hasBorder={true}
      className={_className}
      classNameHeader={_classNameHeader}
      classNameBody={_classNameBody}
      classNameFooter={_classNameFooter}
      {...otherProps}
    />
  );
};

export default CardBorder;
