import generateSlice from "./slice";
import generateActions from "./actions";
import generateSelectors from "./selectors";
import generateHooks from "./hooks";
import generateHelpers from "./helpers";
import generateApplyListeners from "./applyListeners";
import _ from "lodash";

const generateStore = (args = {}) => {
  const { storeName, riskStoreConfig } = args;

  const fnThrowError = (msg, _storeName = storeName) => {
    throw ["Error in generateStore", _storeName, "-", msg]
      .filter((x) => x)
      .join(" ");
  };

  if (!storeName) fnThrowError("missing storeName");
  if (!riskStoreConfig) fnThrowError("missing riskStoreConfig");

  if (!riskStoreConfig.storeType)
    fnThrowError("missing riskStoreConfig.storeType"); // "quoteAndBuy" or "legacy"

  ["services", "riskStore", "database", "getProps"].forEach((k, i) => {
    if (!(k in riskStoreConfig))
      fnThrowError(`missing ${k} in config item no: ${i}`);
  });

  // console.log("database", riskStoreConfig.database, riskStoreConfig.getProps);
  // throw "database";

  // Check the services
  [
    "getSalusRisk",
    "getQuote",
    "paymentLauncher",
    // "getPurchaseResponse",
    "getSavedQuotes",
  ].forEach((k, i) => {
    if (!(k in riskStoreConfig.services))
      fnThrowError(`missing ${k} in config item no: ${i}`);
  });

  // [
  //   "getPolicyDocuments",
  //   // "setDocumentDownloaded",
  //   "getDocument",
  //   "upload",
  // ].forEach((k) => {
  //   if (!(k in services)) fnThrowError(`missing "services.${k}"`);
  // });

  const _selectors = generateSelectors(args);
  const _actions = generateActions({ ...args, selectors: _selectors });

  const _slice = generateSlice({
    ...args,
    privateActions: _actions,
    selectors: _selectors,
  });

  const applyListeners = generateApplyListeners({
    ...args,
    privateActions: { ..._actions, ..._slice.actions },
    selectors: _selectors,
  });

  const hooks = generateHooks({
    ...args,
    privateActions: { ..._actions, ..._slice.actions },
    selectors: _selectors,
  });

  const helpers = generateHelpers({ ...args });

  const retData = {
    storeName,
    reducer: _slice.reducer,
    actions: { ..._actions, ..._slice.actions },
    selectors: _.omit(_selectors, ["private"]),
    helpers: helpers,
    hooks,
    applyListeners,
    riskStoreConfig,
    // config,
    // riskStore: config.riskStore,
    // database: config.database,
    // getProps: args.getProps,
  };
  // console.log("argsargsargddddds",config)
  // throw `hhh`
  console.log("STORE", storeName, "=", retData);
  return retData;
};

export default generateStore;
