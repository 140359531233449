import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import ButtonLabel from "@material-ui/icons/Search";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";

// import { tidyPostcode } from "@library/common/helpers/misc/postcode";
// import { underline } from "cli-color";
// import { compose } from "redux";

export default (args = {}) => {
  const {
    database,
    selectors,
    actions,
    siteComponents,
    storeComponentsSite,
    storeComponentsCustom,
    classes,
    services,
  } = args;

  // const { Error } = storeComponentsCustom;

  if (false) {
    const { Button, TextBox } = require("../../../getResources").default(
      args,
      "Vehicle"
    );
    const DefaultSearchTemplate = (props) => {
      const {
        heading,
        IDVehicleReg,
        searchVehicleReg,
        setSearchVehicleReg,
        RegNumberMinLength,
        RegNumberMaxLength,
        isSearching,
        setIsSearching,
        policyTypeHeading,
        onEdit,
        manualSearchText,
        showEdit,
        failedSearch,
        errorMessage,
        getData,
        onFoundData,
      } = props;

      return (
        <>
          <div className="row">
            <div className="col-12">
              <label className="form-label fw-bold">{heading}</label>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-auto">
              <TextBox
                id={IDVehicleReg}
                value={searchVehicleReg}
                onType={(value) => {
                  const newValue = value
                    ? value.toUpperCase().replace(/\s/g, "")
                    : undefined;
                  setSearchVehicleReg(newValue);
                }}
                onKeyDown={(e) => {
                  if (e.which === 32) e.preventDefault(); // Prevent SPACES
                }}
                placeholder="XX01ABC"
                size={RegNumberMaxLength}
                maxLength={RegNumberMaxLength}
                className="text-center"
                disabled={isSearching}
              />
            </div>
            <div className="col-12 col-md-auto mt-2 mt-md-0">
              {isSearching && (
                <div className="my-auto">
                  <CircularProgress size="1.5rem" />
                </div>
              )}
              {!isSearching && (
                <Button
                  type="button"
                  className="btn-primary w-100"
                  label={
                    <span>
                      <ButtonLabel />
                      Find My {policyTypeHeading}
                    </span>
                  }
                  onClick={() => {
                    if (
                      searchVehicleReg !== undefined &&
                      searchVehicleReg.length >= RegNumberMinLength
                    ) {
                      setIsSearching(true);
                      getData(searchVehicleReg).then((response) => {
                        onFoundData(response);
                        setIsSearching(false);
                      });
                    }
                  }}
                />
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-4">
              <button
                type="button"
                onClick={onEdit}
                className="btn btn-outline-primary btn-sm"
              >
                {manualSearchText}
              </button>
            </div>
          </div>

          {showEdit && (
            <div className="row">
              <div className="col-12 col-md-8">
                <span>No registration?</span>
                <a onClick={() => onEdit()} className="btn btn-link btn-sm">
                  Search for your vehicle model
                </a>
              </div>
            </div>
          )}

          {failedSearch && (
            <div className="row">
              <div className="col-12 alert alert-primary mt-3" role="alert">
                Sorry, we couldn't find your vehicle.
              </div>
            </div>
          )}

          {errorMessage && (
            <div className="row">
              <div className="col-12  alert alert-primary mt-3" role="alert">
                {errorMessage}
              </div>
            </div>
          )}
        </>
      );
    };
  }
  const DefaultSearchTemplate = undefined;
  //** MAIN component
  const VehicleSearchComponent = (props) => {
    const {
      onFoundData,
      onError,
      onEdit,
      forceShowErrors,
      heading,
      searchService,
      RegNumberMinLength = 3,
      RegNumberMaxLength = 10,
      showEdit = true,
      failedSearch = false,
      policyTypeHeading,
      SearchTemplate = DefaultSearchTemplate,
    } = props;

    const getData = useCallback((Registration) => {
      return searchService({ Registration });
    }, []);

    const [searchVehicleReg, setSearchVehicleReg] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [isSearching, setIsSearching] = useState(false);

    const IDVehicleReg = useCallback(_.uniqueId(`VehicleReg`));

    const manualSearchText = failedSearch
      ? `Click here to manually search for your ${policyTypeHeading}`
      : "Don't have the registration?";

    return (
      <SearchTemplate
        heading={heading}
        IDVehicleReg={IDVehicleReg}
        searchVehicleReg={searchVehicleReg}
        setSearchVehicleReg={setSearchVehicleReg}
        RegNumberMinLength={RegNumberMinLength}
        RegNumberMaxLength={RegNumberMaxLength}
        isSearching={isSearching}
        setIsSearching={setIsSearching}
        policyTypeHeading={policyTypeHeading}
        onEdit={onEdit}
        manualSearchText={manualSearchText}
        showEdit={showEdit}
        failedSearch={failedSearch}
        errorMessage={errorMessage}
        getData={getData}
        onFoundData={onFoundData}
      />
    );
  };

  return VehicleSearchComponent;
};
