import React from "react";

import Base from "./Base";
import _logo from "siteAssets/images/stella-logo-colour.svg";
import _logoNegative from "siteAssets/images/stella-logo-negative.svg";

import Header from "./Templates/HeaderWithMenu";

import { Carousel } from "react-bootstrap";

const carouselImages = [
  // { id: 9629, url: "https:\/\/withstella.co.uk\/wp-content\/uploads\/2022\/11\/with-stella-car-insurance-banner-01.jpg" },
  // { id: 9630, url: "https:\/\/withstella.co.uk\/wp-content\/uploads\/2022\/11\/with-stella-car-insurance-banner-02.jpg" },
  // { id: 9631, url: "https:\/\/withstella.co.uk\/wp-content\/uploads\/2022\/11\/with-stella-car-insurance-banner-03.jpg" },
  // { id: 9632, url: "https:\/\/withstella.co.uk\/wp-content\/uploads\/2022\/11\/with-stella-car-insurance-banner-04.jpg" },
  // { id: 9633, url: "https:\/\/withstella.co.uk\/wp-content\/uploads\/2022\/11\/with-stella-car-insurance-banner.jpg" },
  // { id: 9634, url: "https:\/\/withstella.co.uk\/wp-content\/uploads\/2022\/11\/with-stella-car-insurance-1.jpg" }
  {
    id: 9629,
    url: require("siteAssets/images/banner/with-stella-car-insurance-banner-01.webp"),
  },
  {
    id: 9630,
    url: require("siteAssets/images/banner/with-stella-car-insurance-banner-02.webp"),
  },
  {
    id: 9631,
    url: require("siteAssets/images/banner/with-stella-car-insurance-banner-03.webp"),
  },
  {
    id: 9632,
    url: require("siteAssets/images/banner/with-stella-car-insurance-banner-04.webp"),
  },
  // {
  //   id: 9633,
  //   url: require("siteAssets/images/banner/with-stella-car-insurance-banner.jpg"),
  // },
  // {
  //   id: 9634,
  //   url: require("siteAssets/images/banner/with-stella-car-insurance-1.jpg"),
  // },
];

const SlideShow = () => {
  return (
    <div >
      <Carousel
        className="slideshow-carousel"
        controls={false}
        indicators={false}
        touch={false}
        fade={true}
        //interval={null}
      >
        {carouselImages &&
          carouselImages.map((img, idx) => {
            const itemStyle = {
              backgroundImage: "url(" + img.url + ")",
            };

            return (
              <Carousel.Item
                key={img.id}
                className="ken-burnsx"
                style={itemStyle}
              ></Carousel.Item>
            );
          })}
      </Carousel>
      <div className="background-overlay"></div>
      <div className="shaper shaper-bottom">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="shape-fill"
            d="M0,0c0,0,0,6,0,6.7c0,18,240.2,93.6,615.2,92.6C989.8,98.5,1000,25,1000,6.7c0-0.7,0-6.7,0-6.7H0z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

const SiteLayout = (props) => {
  return (
    <Base
      {...props}
      className="layout-background-banner"
      headerData={<Header logoHeader={_logoNegative} navVariant="dark" />}
      backgroundSlide={<SlideShow />}
      headingText={
        <span>
          Welcome back{" "}
          <span className="d-none d-lg-inline">
            to <span className="text-buttermilk">My Account with Stella</span>
          </span>
        </span>
      }
      descriptionContent={
        <p className="text-buttermilk d-lg-none">
          My Account with Stella makes managing your policy quick and easy.
        </p>
      }
    />
  );
};

export default SiteLayout;
