import React, { useCallback, useState, useEffect, useMemo } from "react";
import { generateLookupObject } from "@library/common/helpers/service";
import _ from "lodash";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Create";

const DefaultFoundTemplate = (props) => {
  const {
    heading,
    RegNumber,
    vehicleDescription,
    onSearchManual,
    policyTypeHeading,
  } = props;

  return (
    <div className="card">
      <div className="card-header">
        <strong>{heading}</strong>
      </div>

      <div className="card-body">
        <div className="row my-2">
          <div className="col-12 col-md-auto">
            {RegNumber && (
              <span className="vehicle-registration text-center d-inline-block overflow-wrap-normal">
                {RegNumber}
              </span>
            )}
            {!RegNumber && "Registration number not entered."}
          </div>
          <div className="col-12 col-md-8 mt-2 mt-md-0">
            {vehicleDescription}
          </div>
        </div>
        <div className="row">
          <div className="col-12 px-0">
            <button
              onClick={onSearchManual}
              className="m-1 btn btn-link btn-sm"
            >
              Not your {policyTypeHeading}?
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const DefaultFoundTemplateVehicleDescription = (props) => {
  return (
    <>
      <strong className="fs-6">
        {props.Manufacturer} {props.ModelDescription}
      </strong>
      <p className="m-0">
        {props.Doors} door,{" "}
        {props.Seats !== undefined ? `${props.Seats} seats` : ""}{" "}
        {(props.Fuel && props.lookups.fueltype[props.Fuel]) || props.Fuel}
        {props.Transmission &&
          `, ${props.lookups.transmission[props.Transmission]}`}
        {props.Cc && props.Cc > 0 && `, ${props.Cc}cc`}
      </p>
    </>
  );
};
export default function (args) {
  const { siteComponents, fueltypeService, transmissionService } = args;

  // let lookups = {};
  // generateLookupObject([
  // 	{ key: "fueltype", service: fueltypeService },
  // 	{ key: "transmission", service: transmissionService },
  // ]).then((r) => (lookups = r));

  //** MAIN component
  return (props) => {
    const {
      RegNumber,
      ModelDescription,
      Manufacturer,
      Transmission,
      Cc,
      Value,
      Fuel,
      Seats,
      Doors,
      AbiCode,

      heading,
      onEdit,
      onSearch,
      onSearchManual,
      policyTypeHeading = "vehicle",
      policyType,

      FoundTemplate = DefaultFoundTemplate,
      FoundTemplateVehicleDescription, // = DefaultFoundTemplateVehicleDescription,
      ...otherProps
    } = props;

    const [lookups, setLookups] = useState(undefined);

    useEffect(() => {
      generateLookupObject([
        { key: "fueltype", service: fueltypeService },
        { key: "transmission", service: transmissionService },
      ]).then((response) => setLookups(response));
    }, []);

    const ID = useCallback(_.uniqueId(`Vehicle`));

    if (!lookups) return null;

    const propsVehicle = {
      RegNumber,
      ModelDescription,
      Manufacturer,
      Transmission,
      Cc,
      Value,
      Fuel,
      Seats,
      Doors,
      AbiCode,
    };

    const vehicleDescription = (function () {
      if (FoundTemplateVehicleDescription)
        return (
          <FoundTemplateVehicleDescription
            lookups={lookups}
            {...propsVehicle}
          />
        );

      switch (policyType) {
        case "PC":
          return (
            <>
              <strong className="fs-6">
                {Manufacturer} {ModelDescription}
              </strong>
              <p className="m-0">
                {Doors} door, {Seats !== undefined ? `${Seats} seats` : ""}{" "}
                {(Fuel && lookups.fueltype[Fuel]) || Fuel}
                {Transmission && `, ${lookups.transmission[Transmission]}`}
                {Cc && Cc > 0 && `, ${Cc}cc`}
              </p>
            </>
          );
        case "GV":
          return (
            <>
              <strong className="fs-6">
                {Manufacturer} {ModelDescription}
              </strong>
              <p className="m-0">
                {(Fuel && lookups.fueltype[Fuel]) || Fuel}
                {Transmission && `, ${lookups.transmission[Transmission]}`}
                {Cc && Cc > 0 && `, ${Cc}cc`}
              </p>
            </>
          );
      }
    })();

    return (
      <FoundTemplate
        {...otherProps}
        heading={heading}
        RegNumber={RegNumber}
        vehicleDescription={vehicleDescription}
        onSearchManual={onSearchManual}
        policyTypeHeading={policyTypeHeading}
      />
    );
  };
}
