import generateSnapshotSlice from "./snapshotSlice";
import generateSnapshotActions from "./snapshotActions";
import generateSnapshotSelectors from "./snapshotSelectors";
import generateSnapshotComponents from "./snapshotComponents";
import generateSnapshotHooks from "./snapshotHooks";
import generateSnapshotEnhancer from "./snapshotEnhancer";

const fnThrowError = (msg) => {
  throw `Error in create snapshot store: ${msg}`;
};

const generateStore = (args = {}) => {
  const { storeName, services = {}, watchlist = [] } = args;

  if (!storeName) fnThrowError("missing storeName");
  if (watchlist.length === 0) fnThrowError("missing watchlist");
  if (watchlist.includes(storeName))
    fnThrowError("you can't include the storeName in the watchlist");

  // ["init", "get", "save", "delete"].forEach((k) => {
  //   if (!(k in services)) fnThrowError(`missing "services.${k}"`);
  // });

  const snapshotSelectors = generateSnapshotSelectors(args);
  const snapshotActions = generateSnapshotActions({
    ...args,
    snapshotSelectors,
  });
  const snapshotSlice = generateSnapshotSlice({ ...args, snapshotActions });
  const snapshotComponents = generateSnapshotComponents({
    ...args,
    snapshotActions,
    snapshotSelectors,
  });
  const snapshotEnhancer = generateSnapshotEnhancer({
    ...args,
    snapshotActions,
  });

  const snapshotHooks = generateSnapshotHooks({
    ...args,
    selectors: snapshotSelectors,
    actions: { ...snapshotActions, ...snapshotSlice.actions },
  });

  const retData = {
    storeName,
    reducer: snapshotSlice.reducer,
    actions: { ...snapshotActions, ...snapshotSlice.actions },
    selectors: snapshotSelectors,
    components: snapshotComponents,
    enhancer: snapshotEnhancer,
    hooks: snapshotHooks,
  };

  console.log("STORE", "snapshot", storeName, "=", retData);
  return retData;
};

export default generateStore;
