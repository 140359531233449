// features/Documents/DocumentsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  createFnGenerateAction,
  createFnGenerateActionWithThunkAPI,
} from "../common";

const generateStore = (args = {}) => {
  const { storeName, services, selectors = {} } = args;

  const fnGenerateAction = createFnGenerateAction(storeName);
  const fnGenerateActionWithThunkApi =
    createFnGenerateActionWithThunkAPI(storeName);

  // const getDocument = fnGenerateAction("getDocument", services.getDocument);

  return {};
};

export default generateStore;
