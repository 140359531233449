import React from "react";

import PriceCard from "./PriceCard";
import PriceItem from "./PriceItem";
import { IptHeader } from "siteComponents";

const fnFormatCurrency = (v) =>
  new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(v);

const PriceCardAP = (props) => {

    const { curQuote, isStickyAnimationEnabled = false } = props;

    const priceCard = (
        <PriceCard
            dataCy="mta-ap-total"
            isSelected={true}
            priceItem={(
              <PriceItem
                value={curQuote.TotalAdjustmentAmount}
                className={
                  isStickyAnimationEnabled && "mta-price-item-animated"
                }
              />
            )}
            footerContent={
              curQuote.AllowAdjustmentInFull && curQuote.AllowSpreadMonthly ?
              <span>Choose to add to your instalments or pay in full below</span>
              :
              null
            }
          />
      );

    return priceCard;
}

const PriceCardRP = (props) => {
    const { curQuote, isStickyAnimationEnabled = false } = props;

    const priceCard = (
        <PriceCard
            dataCy="mta-rp-total"
            isSelected={true}
            priceItem={(
              <PriceItem
                value={curQuote.TotalAdjustmentAmount * -1}
                className={
                  isStickyAnimationEnabled && "mta-price-item-animated"
                }
              />
            )}
          />
      );

    return priceCard;
}


const PriceContainer = (props) => {
    const { dataCy, content, iptStatement } = props;

  console.log (iptStatement);

    return (
        <div className="mta-price" data-cy={dataCy}>
          {content && (
              <div className="row">
                <div className="col-12 mta-price-container is-selected">
                    {content}                    
                </div>
            </div>
          )}
            
          {iptStatement != null && (
            <div className="row ipt-statement justify-content-center" data-cy="mta-price-footer">
              <div className="col-12 col-md-9 d-flex align-items-center">
                  {iptStatement}
              </div>
            </div>
          )}
        </div>
    );
}


const PriceDetails = (props) => {
    const { curQuote, isStickyAnimationEnabled = false, allowOnlineWrapup } = props;
  
    // Do we exit early?
    switch (curQuote?.MtaType) {
      case "AP": {
        return (
            <PriceContainer 
                dataCy="mta-ap-price" 
                content={
                  <PriceCardAP 
                          curQuote={curQuote} 
                          isStickyAnimationEnabled={isStickyAnimationEnabled} 
                    />
                } 
                iptStatement={
                  <IptHeader         
                  resultType={curQuote.MtaType}
                  fee={curQuote.MtaFee}
                  allowOnlineWrapup={allowOnlineWrapup} />
                }
            />
        );
      }
      case "RP": {
        return (
            <PriceContainer 
                dataCy="mta-rp-price" 
                content={
                  <PriceCardRP 
                        curQuote={curQuote} 
                        isStickyAnimationEnabled={isStickyAnimationEnabled} 
                    />
                } 
                iptStatement={
                  <IptHeader 
                    allowOnlineWrapup={allowOnlineWrapup}     
                    resultType={curQuote.MtaType}
                    fee={curQuote.MtaFee}
                  />
                }
            />
        );
      }
  
      case "NIL": {
        return (
          <PriceContainer 
                dataCy="mta-nil-price" 
                iptStatement={
                  <IptHeader 
                   allowOnlineWrapup={allowOnlineWrapup} 
                   resultType={curQuote.MtaType}
                   fee={curQuote.MtaFee}
                   />
                } 
            />
        );
      }
      default:
        return null;
    }
};

export default PriceDetails;