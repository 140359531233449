import React, { useEffect, useState, useCallback } from "react";
import { useOutletContext } from "react-router-dom";
import { useLookupOptionList } from "moveToLibrary/hooks/useLookup";
import useStatus from "moveToLibrary/hooks/useStatus";
import { fnScrollToTop } from "moveToLibrary/hooks/useScrollTo";

import services from "siteService";
import { selectors, actions, hooks, helpers } from "siteStore";

import _ from "lodash";
import Base from "./Base";
import ButtonPanel from "./ButtonPanel";
import PriceDetails from "./PriceDetails";
import PriceDisplay from "./PriceDetails/Components/PriceDisplay";

import {
  PanelRenewal,
  ButtonToggleRenewal,
  ButtonStyled,
  Button,
  TelephoneService,
  VehicleReg,
  DevContainer,
  ExternalLink,
  IptPanel,
  Error,
  ButtonLink,
  Loading,
  DisplayItem,
  Modal,
  AnimatedOval,
  AnimatedPulse,
  OpexIncluded,
} from "siteComponents";
import { Markup } from "interweave";
const { useDocumentFunctions } = hooks.documents;
import { useDispatch } from "siteFunctions/hooks";

const InfoBox = ({ children }) => {
  return (
    <div className="alert alert-secondary py-2 px-md-5 text-center info-box">
      {children}
    </div>
  );
};

const DetailsSection = (props) => {
  const { heading, dataCy, data = [], missingText, className } = props;

  return (
    <div
      className={["policy-details", className].toClassName()}
      data-cy={dataCy}
    >
      <div className="policy-details-heading">{heading}</div>

      <div className="">
        {data.map(
          (
            {
              label,
              value,
              lblMyAuto,
              isHorizontalFormat,
              isHzntlMobile,
              labelClass,
              valueClass,
              dataCy,
              className,
            },
            i
          ) => {
            const _labelClass = [
              lblMyAuto ? "my-auto" : "",
              labelClass,
            ].toClassName();
            const _valueClass = [valueClass].toClassName();
            return (
              <DisplayItem
                key={i}
                dataCy={dataCy}
                label={label}
                value={value}
                outerClass={className}
                labelClass={_labelClass}
                valueClass={_valueClass}
                isHorizontalFormat={isHorizontalFormat}
                isHzntlMobile={isHzntlMobile}
                hzntDataCols={7}
                hzntSmDataCols={9}
                hzntMdDataCols={9}
                hzntXlDataCols={9}
              />
            );
          }
        )}
        {data.length === 0 && (
          <DisplayItem
            dataCy="item-missing"
            value={missingText}
            isHorizontalFormat={false}
            isHzntlMobile={false}
          />
        )}
      </div>
    </div>
  );
};

const DetailsSectionAccordianWIP = (props) => {
  const { heading, data = [], missingText, className } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const id = useCallback(_.uniqueId("RENEWAL_DETAILS_"));
  const _className = [
    // "row",
    "accordion",
    className,
  ].toClassName();

  return (
    <div className={_className}>
      <div className="accordion-item">
        <div className="accordion-header">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={id}
            aria-expanded="true"
            aria-controls={id}
          >
            {heading}
          </button>
        </div>
        <div className={["xxxaccordion-body"].toClassName()} id={id}>
          {data.map(({ label, value, lblMyAuto, dataClass }, i) => {
            if (label) {
              return (
                <React.Fragment key={i}>
                  <div
                    className={[
                      "col-12 col-md-4",
                      lblMyAuto ? "my-auto" : "",
                    ].toClassName()}
                  >
                    {label}:
                  </div>
                  <div
                    className={[
                      "col-12 col-md-8 fw-700 my-auto mb-2",
                      dataClass,
                    ].toClassName()}
                  >
                    {value}
                  </div>
                </React.Fragment>
              );
            }

            return (
              <div className={["col-12 mb-2", dataClass].toClassName()}>
                {value}
              </div>
            );
          })}
          {data.length === 0 && <div className="col-12">{missingText}</div>}
        </div>
      </div>
    </div>
  );
};

const InstalmentDetailsTextNoDeposit = (props) => {
  const { instalmentDetails, className } = props;

  if (instalmentDetails.HasVariedPayment) {
    if (instalmentDetails.VariedRepaymentStatus === "Next") {
      return (
        <span className={className}>
          If you choose to pay by instalments there's an initial payment of{" "}
          <PriceDisplay value={instalmentDetails.VariedPaymentAmount} />{" "}
          followed by{" "}
          <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong>{" "}
          payments of{" "}
          <PriceDisplay value={instalmentDetails.InstalmentAmount} />.
        </span>
      );
    }

    if (instalmentDetails.VariedRepaymentStatus === "Last") {
      return (
        <span className={className}>
          If you choose to pay by instalments there's{" "}
          <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong>{" "}
          payments of{" "}
          <PriceDisplay value={instalmentDetails.InstalmentAmount} /> followed
          by a final payment of{" "}
          <PriceDisplay value={instalmentDetails.VariedPaymentAmount} />.
        </span>
      );
    }
  }

  return (
    <>
      <span className={className}>
        If you choose to pay by instalments there's{" "}
        <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong> payments
        of <PriceDisplay value={instalmentDetails.InstalmentAmount} />.
      </span>
    </>
  );
};

const InstalmentDetailsTextHasDeposit = (props) => {
  const { instalmentDetails, className } = props;

  if (instalmentDetails.HasVariedPayment) {
    if (instalmentDetails.VariedRepaymentStatus === "Next") {
      return (
        <span className={className}>
          If you choose to pay by instalments you'll pay a deposit of{" "}
          <PriceDisplay value={instalmentDetails.DepositAmount} /> today
          followed by <strong>1</strong> payment of{" "}
          <PriceDisplay value={instalmentDetails.VariedPaymentAmount} /> and{" "}
          <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong>{" "}
          payments of{" "}
          <PriceDisplay value={instalmentDetails.InstalmentAmount} />.
        </span>
      );
    }

    if (instalmentDetails.VariedRepaymentStatus === "Last") {
      return (
        <span className={className}>
          If you choose to pay by instalments you'll pay a deposit of{" "}
          <PriceDisplay value={instalmentDetails.DepositAmount} /> today
          followed by{" "}
          <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong>{" "}
          payments of{" "}
          <PriceDisplay value={instalmentDetails.InstalmentAmount} /> and a
          final payment of{" "}
          <PriceDisplay value={instalmentDetails.VariedPaymentAmount} />.
        </span>
      );
    }
  }

  return (
    <span className={className}>
      If you choose to pay by instalments you'll pay a deposit of{" "}
      <PriceDisplay value={instalmentDetails.DepositAmount} /> today followed by{" "}
      <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong> payments of{" "}
      <PriceDisplay value={instalmentDetails.InstalmentAmount} />.
    </span>
  );
};

// ***********************************
// ** MAIN COMPONENT
// ***********************************
const RenewalStartView = (props) => {
  const dispatch = useDispatch();
  const [isSticky, setIsSticky] = useState(false);
  const [isStickyAnimationEnabled, setIsStickyAnimationEnabled] =
    useState(false);
  const [modalDocumentMessage, setModalDocumentMessage] = useState(undefined);

  const commonData = useOutletContext();
  const documentFunctions = useDocumentFunctions(dispatch, {
    description: "RENEWAL/START.jsx",
  });
  const paymentMethod = commonData.values.chosenOptions.paymentMethod;

  const policyData = commonData.values.policyData;

  const mainData = commonData.values.data;

  // const prevPaymentMethod = mainData.RenewalSummary.Proposer.PaymentMethod;
  const prevPaymentMethod = mainData.RenewalResult.IsPayInFull ? "F" : "M";

  const lookupOccupation = useLookupOptionList(
    services.lookups.person.jobtitles
  );

  const lookupVehicleFuelType = useLookupOptionList(
    services.lookups.vehicle.fueltype
  );

  const lookupVehicleTransmission = useLookupOptionList(
    services.lookups.vehicle.transmission
  );

  const lookupLicenceType = useLookupOptionList(
    services.lookups.person.licence.type
  );

  const lookupCC = (v) => (v ? `${v}cc` : ``);

  const nextPageMode = (function () {
    //NOTE: This may temporarily blank as "paymentMethod" may be temporarily blank.
    if (paymentMethod === "F") return "SPM";

    if (paymentMethod === "M") {
      if (prevPaymentMethod === "F") return "DIRECTDEBIT";
      if (prevPaymentMethod === "M") return "WRAPUP";
    }

    return undefined;
  })();

  // const loadingData = (function () {
  //   if (commonData.status.paymentLauncher.init)
  //     return {
  //       isCompleting: true,
  //       isCompletingMessage:
  //         "Please wait a moment while we complete your changes.",
  //     };
  //   return {};
  // })();

  const [fileData, fileDataControls] = useStatus();

  const fnOpenDocument = () => {
    const documentDetails = policyData.Documents.find(
      (x) => x.DocumentId === policyData.RenewalInfo.RenewalDocId
    );

    console.log(policyData);
    console.log(documentDetails);

    if (!documentDetails) {
      fileDataControls.setError(
        "We're sorry, there's been an error in retrieving your document."
      );
      return;
    }
    const documentId = documentDetails.DocumentId;
    const mimeContentType = documentDetails.MimeContentType;
    const policyId = policyData.PolicyId;

    fileDataControls.setRefreshing();
    fileDataControls.clearErrorMsg();
    documentFunctions.open({
      documentId: documentId,
      policyId: policyId,
      mimeContentType: mimeContentType,
      onStart: () => {
        setModalDocumentMessage("We're retrieving your documents...");
      },
      onSuccess: () => {
        setModalDocumentMessage(undefined);
        fileDataControls.setOk();
      },
      onError: (msg) => {
        setModalDocumentMessage(undefined);
        fileDataControls.setError(msg);
      },
    });
  };

  // ** UPDATE CHOSEN PAYMENTMETHOD (if not already set) **
  useEffect(() => {
    if (paymentMethod) return; //If already set, then exit

    const newMethod = (function () {
      switch (prevPaymentMethod) {
        case "F": {
          if (mainData.RenewalResult.AllowPayInFull) return "F";
          if (mainData.RenewalResult.AllowPayMonthly) return "M";
          return undefined;
        }
        case "M": {
          if (mainData.RenewalResult.AllowPayMonthly) return "M";
          if (mainData.RenewalResult.AllowPayInFull) return "F";
          return undefined;
        }
        default:
          return undefined;
      }
    })();

    commonData.functions.options.updatePaymentMethod(newMethod);
  }, []);

  // ** Payment Launcher **
  useEffect(() => {
    const myFn = async () => {
      if (_.isEmpty(commonData.values.paymentLauncher)) return;

      if (commonData.values.paymentLauncher.RedirectUrl) {
        commonData.functions.navigate.loadUrl(
          commonData.values.paymentLauncher.RedirectUrl
        );
        return;
      }

      if (commonData.values.paymentLauncher.WrapupAttempted) {
        await commonData.functions.wrapup.AP({
          startDate: commonData.functions.formatters.date(
            mainData.RenewalSummary.CoverFrom
          ),
          emailAddress: mainData.RenewalSummary.Proposer.Email,
          isWrapupComplete: commonData.values.paymentLauncher.WrapupComplete
            ? true
            : false,
        });

        await commonData.functions.session.save();

        return;
      }
    };

    myFn();
  }, [_.isEmpty(commonData.values.paymentLauncher)]);

  if (commonData.status.start.error) return <div></div>;

  const errorData = (function () {
    if (commonData.status.paymentLauncher.error)
      return {
        errorMessage: commonData.status.paymentLauncher.error,
        disableNextButton: false,
      };
    return { disableNextButton: false };
  })();

  const BaseStatusData = (function () {
    let _isLoading = false;
    let _isCompleting = false;
    let _isLoadingMessage = "";
    let _isCompletingMessage = "";

    const fnGenerateRetData = () => ({
      isLoading: _isLoading,
      isCompleting: _isCompleting,
      isLoadingMessage: _isLoadingMessage,
      isCompletingMessage: _isCompletingMessage,
    });

    if (commonData.status.paymentLauncher.init) {
      _isLoading = true;
      // _isLoadingMessage = "Redirecting to the payment page...";
      return fnGenerateRetData();
    }

    if (commonData.status.paymentLauncher.error) {
      _isLoading = false;
      // _isLoadingMessage = "";
      return fnGenerateRetData();
    }

    if (commonData.values.paymentLauncher.RedirectUrl) {
      _isLoading = true;
      // _isLoadingMessage = "Redirecting to the payment page...";
      return fnGenerateRetData();
    }

    return fnGenerateRetData();
  })();

  const headingData = (function () {
    const headingText = "Your renewal premium";
    const descriptionContent = (
      <PriceDetails
        RenewalResult={mainData?.RenewalResult}
        isStickyAnimationEnabled={isStickyAnimationEnabled}
        isSticky={isSticky && isStickyAnimationEnabled}
        selectedPayMethod={paymentMethod}
        allowOnlineWrapup={policyData.Mta.online}
      />
    );

    const showHeader = true;
    return { headingText, descriptionContent, showHeader };
  })();

  // const priceDetails = getPriceDetails({
  //   instalmentDetails: mainData.RenewalResult.instalmentDetails,
  //   isInstalments: mainData.RenewalResult.instalmentDetails ? true : false,
  //   info: "Start.jsx",
  // });

  console.log("RENEWAL START.jsx", {
    mainData,
    policyData,
    fileDataControls,
    fileData, // errorMsg
    services,
    commonData,
  });

  // console.log("dddddd", require("siteComponents"))
  return (
    <Base
      onHeaderStick={() => {
        setIsSticky(true);
        setIsStickyAnimationEnabled(true);
      }}
      onHeaderUnstick={() => {
        setIsSticky(false);
      }}
      pageId="START"
      {...BaseStatusData}
      // isLoading={true}
      // isLoadingMessage={"dddddddd"}
      // isCompleting={loadingData.isCompleting}
      // isCompletingMessage={loadingData.isCompletingMessage}
      className="renewal-start"
      isSticky={true} // implemented in PageLayoutBase
      headingText={headingData.headingText}
      descriptionContent={headingData.descriptionContent}
      showHeader={headingData.showHeader}
    >
      <Modal
        enabled={modalDocumentMessage ? true : false}
        className=""
        classNameBody=""
        classNameHeader=""
        classNameFooter=""
        footer={undefined}
      >
        <div className="row mb-3">
          <div className="col-12">
            <h5>{modalDocumentMessage}</h5>
          </div>
          <div className="col-12">
            {/* <AnimatedOval width={14} height={14} /> */}
            <AnimatedPulse />
          </div>
        </div>
      </Modal>

      <DevContainer enabled={false}>
        <ButtonStyled
          onClick={async () => {
            commonData.functions.process.paymentLauncher();
          }}
        >
          Payment launcher
        </ButtonStyled>

        <ButtonStyled
          onClick={async () => {
            await commonData.functions.wrapup.AP({
              startDate: commonData.functions.formatters.date(
                mainData.RenewalSummary.CoverFrom
              ),
              emailAddress: mainData.RenewalSummary.Proposer.Email,
              isWrapupComplete: commonData.values.paymentLauncher.WrapupComplete
                ? true
                : false,
            });
            await commonData.functions.session.save();
          }}
        >
          Imediate wrapup test
        </ButtonStyled>

        {/* 
        {["AP", "RP", "NIL"].map((type) => (
          <ButtonStyled
            key={type}
            onClick={async () => commonData.functions.wrapup[type]()}
          >
            {type}
          </ButtonStyled>
        ))} */}
      </DevContainer>

      <div className="mb-4 ps-2 row">
        <div className="col-6 col-md-auto">
          <Button
            onClick={commonData.functions.navigate.exit}
            className="btn btn-sm btn-outline-dark"
            data-cy="goto-route-exit"
          >
            Back
          </Button>
        </div>
      </div>

      <PanelRenewal
        heading="Your car insurance renewal"
        data-cy="renewal-intro"
        iconHeading="icon-calendar-sync"
      >
        <div data-cy="intro-this-year">
          The renewal premium for your car insurance this year is{" "}
          <PriceDisplay value={mainData.RenewalResult.TotalRenewalAmount} />.
        </div>
        <div data-cy="intro-last-year">
          Last year you paid{" "}
          <PriceDisplay
            value={mainData.RenewalSummary.PreviousYear.TotalSellingPrice}
          />
          .
        </div>
        <div data-cy="intro-check-details">
          To renew your policy, first{" "}
          {/* TODO: Get this text to wrap - may need to be moved so it's not a button, but an <a> tag instead */}
          <ButtonLink
            className="btn-link-inline link-purple hover-inline"
            data-cy={`renewal-pack-${policyData.RenewalInfo.RenewalDocId}`}
            onClick={() => {
              fnOpenDocument();
            }}
            // isLoading={fileData.isRefreshing}
            loadingInline={true}
            LoadingComponent={() => (
              <Loading
                size="5px"
                margin="2px"
                cssOverride={{ display: "inline" }}
              />
            )}
          >
            read your renewal pack
          </ButtonLink>{" "}
          and check your details below are all correct. If you're happy you can
          go ahead and renew your policy online today.
        </div>

        {fileData.errorMsg && (
          <div>
            <Error>{fileData.errorMsg}</Error>
          </div>
        )}

        <InfoBox>
          If you need to make any changes please call us on <TelephoneService />{" "}
          and we'll be happy to help you.
        </InfoBox>
      </PanelRenewal>

      <PanelRenewal
        heading={"Your policy details"}
        data-cy="renewal-policy-details"
      >
        <div data-cy="intro-text" className="intro-text">
          Please check the details below are correct and let us know if anything
          has changed.
        </div>
        <DetailsSection
          //
          heading="Your details"
          dataCy="your-details"
          data={[
            {
              label: "Address",
              value: [
                [
                  mainData.RenewalSummary.Proposer.Address.HouseNameOrNumber,
                  mainData.RenewalSummary.Proposer.Address.AddressLine1,
                ]
                  .filter(Boolean)
                  .join(" "),
                mainData.RenewalSummary.Proposer.Address.AddressLine2,
                mainData.RenewalSummary.Proposer.Address.AddressLine3,
                mainData.RenewalSummary.Proposer.Address.AddressLine4,
                mainData.RenewalSummary.Proposer.Address.Postcode,
              ]
                .filter(Boolean)
                .join(", "),
              dataCy: "your-details-address",
            },

            {
              label: "Licence",
              value: [
                lookupLicenceType(
                  mainData.RenewalSummary.Proposer.Licence.Type
                ),

                (function () {
                  if (!mainData.RenewalSummary.Proposer.Licence.Date) return "";

                  const days = commonData.functions.formatters.yearsToToday(
                    new Date(mainData.RenewalSummary.Proposer.Licence.Date)
                  );

                  if (days >= 2) return ["for", days, "years"].join(" ");
                  return ["for", days, "year"].join(" ");
                })(),
              ].join(" "),
              dataCy: "your-details-licence",
            },
            {
              label: "Occupation",
              value: lookupOccupation(
                mainData.RenewalSummary.Proposer.FullTimeOccupation.OccCode
              ),
              dataCy: "your-details-occupation",
            },
          ]}
        />

        <DetailsSection
          heading="Your drivers"
          dataCy="your-drivers"
          data={(function () {
            const fnFormatDriver = (drv) => {
              const strName = [
                drv.Name.Title,
                drv.Name.Forenames,
                drv.Name.Surname,
              ]
                .filter(Boolean)
                .join(" ");

              const strHistory = [
                "has",
                drv?.Claims?.length || 0,
                "claim(s)",
                "and",
                drv?.Licence?.Convictions?.length || 0,
                "conviction(s)",
              ].join(" ");

              return [strName, strHistory].join(" ");
            };
            return [
              {
                label: "Proposer",
                value: fnFormatDriver(mainData.RenewalSummary.Proposer),
                isHzntlMobile: false,
                dataCy: "your-drivers-proposer",
              },

              ...(mainData.RenewalSummary.Drivers || []).map((x, i) => {
                return {
                  label: `Driver ${i + 2}`,
                  value: fnFormatDriver(x),
                  isHzntlMobile: false,
                  dataCy: `your-drivers-driver${i + 2}`,
                };
              }),
            ];
          })()}
        />

        <DetailsSection
          heading="Your car"
          dataCy="your-car"
          data={[
            {
              label: "Registration",
              lblMyAuto: true,
              value: (
                <VehicleReg>
                  {mainData.RenewalSummary.Vehicle.RegNumber}
                </VehicleReg>
              ),
              className: "car-reg",
              dataCy: "your-car-reg",
            },
            {
              label: "Make & model",
              value: [
                mainData.RenewalSummary.Vehicle.Make,
                mainData.RenewalSummary.Vehicle.Model,
              ]
                .filter(Boolean)
                .join(" "),
              dataCy: "your-car-make",
            },
            {
              label: "Car details",
              value: [
                lookupVehicleFuelType(mainData.RenewalSummary.Vehicle.Fuel),
                lookupVehicleTransmission(
                  mainData.RenewalSummary.Vehicle.Transmission
                ),
                lookupCC(mainData.RenewalSummary.Vehicle.EngineSize),
              ]
                .filter(Boolean)
                .join(", "),
              dataCy: "your-car-details",
            },
            {
              label: "Purchase date",
              value: commonData.functions.formatters.date(
                mainData.RenewalSummary.Vehicle.PurchaseDate,
                0
              ),
              dataCy: "your-car-purchasedate",
            },
          ]}
        />

        <div className="policy-details" data-cy="inc-opex">
          <div className="policy-details-heading">
            Your included optional cover
          </div>

          {(!mainData.RenewalResult.IncludedOpex ||
            mainData.RenewalResult.IncludedOpex.length === 0) && (
            <DisplayItem
              dataCy="item-missing"
              value={"You have no optional cover included on this policy."}
              isHorizontalFormat={false}
              isHzntlMobile={false}
            />
          )}

          {mainData.RenewalResult.IncludedOpex &&
            mainData.RenewalResult.IncludedOpex.length > 0 && (
              <>
                <div data-cy="intro-text" className="mb-3">
                  Your renewal premium includes the following optional cover
                  that you have with your current policy.
                </div>

                {mainData.RenewalResult.IncludedOpex.map((opex, i) => (
                  <OpexIncluded key={opex.GroupCode} opex={opex} />
                ))}
                <div></div>
              </>
            )}
        </div>
      </PanelRenewal>

      <PanelRenewal heading="Important information" data-cy="renewal-imp-info">
        <div data-cy="intro-text" className="intro-text">
          Please review the details below and ensure you agree before you
          continue.
        </div>

        <DetailsSection
          heading="Policy information"
          dataCy="policy-info"
          className=""
          data={[
            {
              label: "Period",
              value: [
                commonData.functions.formatters.date(
                  mainData.RenewalSummary.CoverFrom
                ),
                "to",
                commonData.functions.formatters.date(
                  mainData.RenewalSummary.CoverTo
                ),
              ].join(" "),
              dataCy: "policy-info-period",
            },
            {
              label: "Years NCD",
              value: <span>{mainData.RenewalSummary.YearsNcb}</span>,
              dataCy: "policy-info-yearsncd",
            },
            {
              label: "Protected NCD",
              value: (
                <span>
                  {mainData.RenewalResult.GrantedProtectedNcb ? "Yes" : "No"}
                </span>
              ),
              dataCy: "policy-info-ncdprot",
            },
            {
              label: "Underwritten by",
              value: <span>{mainData.RenewalResult.InsurerName}</span>,
              dataCy: "policy-info-insurer",
            },
            {
              label: "Cover and Use",
              value: (function () {
                const data = mainData.RenewalResult.DisplayCover;

                if (!data) return "None";
                if (data.length === 0) return "None";

                return data.map((x, i) => (
                  <div
                    key={`${i}-${x.Id}`}
                    className={[
                      "policy-details-cover-item",
                      x.Value,
                    ].toClassName()}
                  >
                    <Markup content={x.Text} tagName="div" />
                  </div>
                ));
              })(),
              isHorizontalFormat: true,
              isHzntlMobile: false,
              dataCy: "policy-info-cover",
            },
          ]}
        />

        <div className="policy-details mb-4">
          <InfoBox>
            For more details on the policy cover, including the benefits, terms,
            conditions and exclusions, it's important that you read and
            understand the{" "}
            <ExternalLink
              href={mainData.RenewalResult.PolicyWordingUrl}
              label="Policy Wording"
            />{" "}
            and the{" "}
            <ExternalLink
              href={mainData.RenewalResult.IpidUrl}
              label="Product Information"
            />
            .
          </InfoBox>
        </div>

        <DetailsSection
          heading="Excesses &amp; Endorsements"
          dataCy="excess-endo"
          className=""
          data={[
            {
              value: (function () {
                const data = mainData.RenewalResult.DisplayExcesses;

                if (!data) return "None";
                if (data.length === 0) return "None";

                return data.map((x, i) => (
                  <div
                    key={x.Code}
                    className={[
                      "policy-details-excess-item",
                      x.Value,
                    ].toClassName()}
                  >
                    <Markup content={x.Text} tagName="div" />
                  </div>
                ));
              })(),
              isHorizontalFormat: false,
              isHzntlMobile: false,
              dataCy: "excess-endo-excesses",
            },
            {
              value: (function () {
                const data = mainData.RenewalResult.DisplayEndorsements;

                if (!data) return "None";
                if (data.length === 0) return "None";

                return data.map((x, i) => (
                  <div
                    key={x.Code}
                    className={[
                      "policy-details-endorsement-item",
                      x.Value,
                    ].toClassName()}
                  >
                    <Markup content={x.Text} tagName="div" />
                  </div>
                ));
              })(),
              isHorizontalFormat: false,
              isHzntlMobile: false,
              dataCy: "excess-endo-endos",
            },
          ]}
          missingText="There are no excesses or endorsements"
        />
      </PanelRenewal>

      <PanelRenewal heading="How to pay" data-cy="renewal-choose-paymethod">
        <div className="row my-3">
          {mainData.RenewalResult.AllowPayMonthly && (
            <div className="col-12 col-md-6 mb-3">
              <ButtonToggleRenewal
                isActive={paymentMethod === "M"}
                onClick={() =>
                  commonData.functions.options.updatePaymentMethod("M")
                }
                className="w-100"
                data-cy="btn-pay-monthly"
              >
                Pay by instalments
              </ButtonToggleRenewal>
            </div>
          )}

          {mainData.RenewalResult.AllowPayInFull && (
            <div className="col-12 col-md-6">
              <ButtonToggleRenewal
                isActive={paymentMethod === "F"}
                onClick={() =>
                  commonData.functions.options.updatePaymentMethod("F")
                }
                className="w-100"
                data-cy="btn-pay-infull"
              >
                Pay in full
              </ButtonToggleRenewal>
            </div>
          )}
        </div>

        {(function () {
          const TextCredit = (
            <>
              Total cost of credit{" "}
              <PriceDisplay
                value={mainData.RenewalResult.Instalments[0].TotalCostOfCredit}
              />
              , including a{" "}
              <PriceDisplay value={5} decimalPlaces={0} allowZero={true} />{" "}
              facility fee. Total amount of credit{" "}
              <PriceDisplay
                value={
                  mainData.RenewalResult.Instalments[0].TotalAmountOfCredit
                }
              />
              . Representative APR{" "}
              {mainData.RenewalResult.Instalments[0].AprRate}%.
            </>
          );

          if (!mainData.RenewalResult.AllowPayMonthly) return null;
          if (!mainData.RenewalResult.Instalments) return null;
          if (mainData.RenewalResult.Instalments.length === 0) return null;

          if (!mainData?.RenewalResult?.Instalments[0].DepositAmount > 0)
            return (
              <div className="inst-details" data-cy="inst-details">
                <InstalmentDetailsTextNoDeposit
                  instalmentDetails={mainData.RenewalResult.Instalments[0]}
                />{" "}
                {TextCredit}
              </div>
            );

          return (
            <div className="inst-details" data-cy="inst-details">
              <InstalmentDetailsTextHasDeposit
                instalmentDetails={mainData.RenewalResult.Instalments[0]}
              />{" "}
              {TextCredit}
            </div>
          );
        })()}

        <div data-cy="renewal-ipt">
          <IptPanel resultType="RNL" fee={mainData.RenewalResult.Fee / 100} />
        </div>
      </PanelRenewal>

      <PanelRenewal heading="Confirmation" data-cy="renewal-confirmation">
        <div>
          <div className="mb-2" data-cy="continue-text">
            If the above details are all correct and you want to proceed with
            the renewal of your policy, please click{" "}
            {(function () {
              switch (nextPageMode) {
                case "DIRECTDEBIT":
                  return <strong>Continue to Direct Debit</strong>;
                case "SPM":
                  return <strong>Continue to payment</strong>;
                case "WRAPUP":
                  return <strong>Continue</strong>;
              }
            })()}
            .
          </div>
          <div data-cy="back-text">
            If you do not wish to proceed click <strong>Back</strong> to return
            to your account.
          </div>
        </div>
      </PanelRenewal>

      <ButtonPanel
        onPrev={commonData.functions.navigate.exit}
        prevLabel="Back"
        onNext={async () => {
          if (errorData.disableNextButton) return;

          if (nextPageMode === "DIRECTDEBIT") {
            fnScrollToTop();
            await commonData.functions.session.save();
            commonData.functions.navigate.directDebit();
            return;
          }

          fnScrollToTop();
          await commonData.functions.session.save();
          await commonData.functions.process.paymentLauncher();

          // commonData.functions.process.complete({ data_for_payinfull: 1111 });
        }}
        nextLabel={(function () {
          switch (nextPageMode) {
            case "DIRECTDEBIT":
              return "Continue to Direct Debit";
            case "SPM":
              return "Continue to payment";
            case "WRAPUP":
              return "Continue";
          }
        })()}
        modalData={(function () {
          if (errorData.disableNextButton) return undefined;

          if (nextPageMode === "WRAPUP") {
            return {
              title: "Confirm your renewal",
              body: (
                <div>
                  <p>
                    As you're paying by instalments there's nothing to pay
                    today.
                  </p>
                  <p>
                    <strong>Are you happy to renew your policy now?</strong>
                  </p>
                </div>
              ),
              label: { cancel: "No", confirm: "Yes, renew my policy" },
            };
          }
          return undefined;
        })()}
        errorMsg={errorData.errorMessage}
      />
    </Base>
  );
};

export default RenewalStartView;
