import React from "react";
import { Navigate, useOutletContext, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// fnGenerateRedirect

const useBlockers = (args = {}) => {
  //   console.log("dadadadadad", args);
  const {
    isStart = false,
    isChange = false,
    isQuote = false,
    isFeedback = false,
    isWrapup = false,
    isWrapupLauncher = false,
  } = args;

  const location = useLocation();

  const commonData = useOutletContext();

  // const paymentLauncherData = useSelector(
  //   commonData.selectors.getPaymentLauncherData
  // );

  const { functions, selectors, isInit, editComponents, routes, policyNumber } =
    commonData;

  // ** function **
  const fnGenerateRedirect = (_targetRoute = "..", info) => {
    const targetRoute = _targetRoute || "..";

    functions.consoleLog("Redirecting to:", targetRoute, {
      info,
      hasPolicyNumber,
      isStoreValid,
      // data_Wrapup,
      isStart,
      isChange,
      isQuote,
      isFeedback,
      isWrapup,
      isInit,
    });
    return <Navigate to={targetRoute} state={location.state} replace />;
  };

  // ** selectors **
  // const data_Wrapup = useSelector(selectors.getWrapupData);
  const isStoreValid = useSelector(
    selectors.riskStore.getIsStoreValid,
    require("lodash").isEqual
  );
  const hasPolicyNumber = policyNumber ? true : false;
  // const isCompleted = useSelector(selectors.getIsCompleted);
  // const isCompleted = (function () {
  //   if (data_Wrapup?.isAP) return true;
  //   if (data_Wrapup?.isRP) return true;
  //   if (data_Wrapup?.isNIL) return true;

  //   return false;
  // })();

  // ** final RETURNS **
  if (!hasPolicyNumber) {
    return fnGenerateRedirect(routes.exit, "Missing policy number");
  }

  if (!isInit) {
    if (!(isWrapup || isWrapupLauncher || isFeedback))
      return fnGenerateRedirect(routes.start, "NOT isInit");
  }

  if (editComponents.length === 0) {
    if (isChange)
      return fnGenerateRedirect(routes.start, "No CHANGE component selected");
  }

  if (!isStoreValid) {
    if (isQuote) return fnGenerateRedirect(routes.change, "Invalid store");
  }

  // if (isCompleted) {
  //   if (!isWrapup)
  //     return fnRedirect(routes.wrapup, "isComplete but not on wrapup");
  // }

  // if (!isCompleted) {
  //   if (isWrapup)
  //     return fnRedirect(routes.exit, "NOT isComplete but on wrapup");
  // }

  return undefined;
};

export default useBlockers;
