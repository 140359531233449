import React from "react";
import {
  TextBox,
  Panel,
  PanelHeader,
  DevContainer,
  Button,
  LoadingRow,
  Loading,
  LoadingPanel,
} from "siteComponents";

const SessionRefreshing = (props) => {
  const { children = undefined } = props;

  return (
    <LoadingPanel>
      {/* <span className="icon icon-clock" /> */}
      {children}
    </LoadingPanel>
  );
  return (
    <div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Loading />
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">{children}</div>
      </div>
    </div>
  );
};

export default SessionRefreshing;
