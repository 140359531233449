import React, { useEffect } from "react";
import config from "config";

import ErrorView from '../../Errors/404';

const TestView = () => {
    if (!config.isDev) return null;

    return <ErrorView />;
};
export default TestView;
