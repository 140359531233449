import React from "react";
import PriceDisplay from "../Components/PriceDisplay";
import getPriceDetails from "../getPriceDetails";
import Divider from "../Components/Divider";

// ***************************************************
// ** MAIN
// ***************************************************

const TierItemAnnual = (props) => {
  const {
    data,
    classNamePound,
    classNamePence,
    classNamePostFix,
    showDivider = true,
  } = props;

  if (!data) return null;

  const className = [
    "lctr-tieritem-annual",
    "annual",
    "card",
    "border-0",
    "bg-transparent",
    "h-md-100",
    // isSingleTier ? "" : "h-lg-auto",
  ]
    .filter((x) => x)
    .join(" ");

  const instalmentDetails = data.Instalments[0];
  
  const priceDetails = getPriceDetails({
    instalmentDetails: instalmentDetails,
    isInstalments: false,
    PayInFullPremium: data.PayInFullPremium,
  });

  return (
    <div className={className}>
      <div className="card-header text-center bg-transparent border-0 pb-1">
        {showDivider && <Divider />}
        {priceDetails.mainPriceText({
          classNamePound,
          classNamePence,
          classNamePostFix,
        })}
      </div>

      {data.AllowPayMonthly && 
        priceDetails.premiumSavingValue !== undefined &&
        priceDetails.premiumSavingValue > 0 && (
          <div className="card-body price-info">
            <span className="pb-1 d-block">
              Paying annually saves you{" "}
              <PriceDisplay value={priceDetails.premiumSavingValue} />
            </span>
          </div>
        )}
    </div>
  );
};

export default TierItemAnnual;
