import _ from "lodash";

const generate = (args = {}) => {
  const { storeName } = args;

  const fnGetSubState = (state) => state[storeName];

  const getValue = (state, key) => {
    const subState = fnGetSubState(state);
    if (!subState) return undefined;

    const item = subState[key];
    if (!item) return undefined;

    return item.value;
  };

  return { getValue };
};

export default generate;
