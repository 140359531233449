import React from "react";
import ContactDetails from "../../ContactDetails";
import _logo from "siteAssets/images/stella-logo-colour.svg";
import _logoNegative from "siteAssets/images/stella-logo-negative.svg";
import ScreenFinder from "siteComponents/dev/ScreenFinder";
import { Navbar, Nav, Container } from "react-bootstrap";
import { selectors, actions } from "siteStore";
import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";

import {
  Outlet,
  Link,
  NavLink,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { ConfirmModalButton, ButtonLink } from "siteComponents";

// console.log("ConfirmModalButton",ConfirmModalButton)
// throw "ConfirmModalButton"
const ContactDetailsFlex = (props) => {
  const { expand, alone } = props;

  const _className = [
    "d-flex",
    "align-items-center",
    "justify-content-end",
    alone ? "flex-fill" : "",
    !alone ? "flex-shrink-0" : "",
    //"justify-content-md-center",
    //`ps-${expand}-4`,
  ].toClassName();

  return (
    <div className={_className}>
      <ContactDetails />
    </div>
  );
};

const GetMenuItem = (props) => {
  const { itemId, navigate, dispatch } = props;

  switch (itemId) {
    case "login": {
      return (
        <NavLink className="nav-link" to="/" data-cy="nav-login">
          Log in
        </NavLink>
      );
    }

    case "about": {
      return (
        <NavLink
          className="nav-link"
          to="https://withstella.co.uk/about/"
          target="_blank"
          data-cy="nav-about"
        >
          About
        </NavLink>
      );
    }

    case "help": {
      return (
        <NavLink
          className="nav-link"
          to="https://withstella.co.uk/help/"
          target="_blank"
          data-cy="nav-help"
        >
          Help
        </NavLink>
      );
    }

    case "claims": {
      return (
        <NavLink
          className="nav-link"
          to="https://withstella.co.uk/claim/"
          target="_blank"
          data-cy="nav-claims"
        >
          Claims
        </NavLink>
      );
    }

    case "manageaccount": {
      return (
        <NavLink
          className="nav-link"
          to="/manage-account"
          data-cy="nav-manage-account"
        >
          Manage account
        </NavLink>
      );
    }

    case "home": {
      return (
        <NavLink className="nav-link" to="/" data-cy="nav-home">
          Home
        </NavLink>
      );
    }

    case "mta": {
      return (
        <NavLink className="nav-link" to="/mta" data-cy="nav-mta">
          MTA
        </NavLink>
      );
    }

    case "faqs": {
      return (
        <NavLink className="nav-link" to="/faq" data-cy="nav-faq">
          FAQs
        </NavLink>
      );
    }

    case "logout": {
      return (
        <ConfirmModalButton
          onConfirm={() => {
            // resetStore()
            dispatch(actions.auth.logout());
            navigate("/");
          }}
          onCancel={() => {}}
          cancelText={"Cancel"}
          confirmText={"Log out"}
          ButtonComponent={({ onClick, ...otherProps }) => (
            <a
              className="nav-link"
              data-cy="nav-logout-confirm"
              href=""
              onClick={(e) => {
                e.preventDefault();
                onClick();
              }}
              {...otherProps}
            />
          )}
          buttonText={"Log out"}
          data-cy="nav-logout"
        >
          <div className="fw-bold">Are you sure you want to log out?</div>
        </ConfirmModalButton>
      );
    }

    default:
      return null;
  }
};

const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const resetStore = useResetStore(dispatch);
  const isLoggedIn = useSelector(
    selectors.auth.isLoggedIn,
    require("lodash").isEqual
  );

  const {
    onLogoClick = () => navigate("/"),
    logoHeader = _logo,
    navVariant = "light",
    expand = "lg",
  } = props;

  const navItems = [
    {
      showItem: true,
      itemId: "help",
    },
    {
      showItem: true,
      itemId: "claims",
    },
    {
      showItem: isLoggedIn,
      itemId: "manageaccount",
    },
    {
      showItem: isLoggedIn,
      itemId: "logout",
    },
    {
      showItem: !isLoggedIn,
      itemId: "login",
    },
  ];

  const showNavToggle =
    navItems && navItems.filter((x) => x.showItem).length > 0;

  const navCollapseClass = [
    `justify-content-${expand}-between`,
    `align-items-${expand}-center`,
  ].toClassName();
  const navInnerClass = [
    `justify-content-${expand}-center`,
    `flex-${expand}-fill`,
  ].toClassName();

  return (
    <Navbar collapseOnSelect expand={expand} variant={navVariant}>
      <>
        <Navbar.Brand>
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              onLogoClick();
            }}
          >
            <img
              className="brand-img img-fluid"
              src={logoHeader}
              alt="Stella Insurance"
            />
          </a>
        </Navbar.Brand>
        {showNavToggle && (
          <>
            <Navbar.Toggle aria-controls="nav-site" />
            <Navbar.Collapse id="nav-site" className={navCollapseClass}>
              <Nav className={navInnerClass}>
                {navItems
                  .filter((x) => x.showItem)
                  .map((x, i) => {
                    return (
                      <GetMenuItem
                        key={x.itemId}
                        itemId={x.itemId}
                        navigate={navigate}
                        dispatch={dispatch}
                      />
                    );
                  })}
              </Nav>
              <ContactDetailsFlex expand={expand} />
            </Navbar.Collapse>
          </>
        )}
        {!showNavToggle && <ContactDetailsFlex alone={true} />}
      </>
    </Navbar>
  );
};

export default Header;
