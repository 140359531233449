import _ from "lodash";
// import { exportData } from "./helpers/userInfo";

const generate = (args = {}) => {
  const { storeName, authActions } = args;

  const getUserToken = (state) => state[storeName].userToken;
  const getSubState = (state) => state[storeName];

  const isLoggedIn = (state) => {
    if (getUserToken(state)) return true;
    return false;
  };

  // const getSessionVariable = (state, key) => {
  //   const subState = getSubState(state);
  //   if (!subState) return;

  //   return subState.sessionVariables[key];
  // };

  // const getDefaultUserInfo = (state) => {
  //   if (!getUserToken(state)) return undefined;
  //   const userInfo = state[storeName]["_userInfo"];
  //   return userInfo;
  // };

  // const getDefaultNotifications = (state) => {
  //   const userInfo = getDefaultUserInfo(state);

  //   if (!userInfo) return;
  //   return userInfo.Notifications;
  // };

  // const getDefaultDocuments = (state) => {
  //   const userInfo = getDefaultUserInfo(state);

  //   if (!userInfo) return;
  //   if (!userInfo.Policies) return;
  //   if (userInfo.Policies.length === 0) return;

  //   return Object.fromEntries(
  //     userInfo.Policies.map((x) => {
  //       return [x.PolicyId, x.Documents];
  //     })
  //   );
  // };

  return {
    // getSessionVariable,
    // getDefaultUserInfo,
    // getDefaultDocuments,
    // getDefaultNotifications,
    getUserToken,
    // getCurrentPolicy,
    isLoggedIn,
    activate: {
      getIsLoading: (state) => state[storeName].activateLoading,
      getLastResponse: (state) => state[storeName].activateLastResponse,
    },
    authenticate: {
      getIsLoading: (state) => state[storeName].authenticateLoading,
      getLastResponse: (state) => state[storeName].authenticateLastResponse,
    },
    link: {
      getIsLoading: (state) => state[storeName].linkLoading,
      getLastResponse: (state) => state[storeName].linkLastResponse,
    },
    create: {
      getIsLoading: (state) => state[storeName].createLoading,
      getLastResponse: (state) => state[storeName].createLastResponse,
    },
  };
};

export default generate;
