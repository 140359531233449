import { list, set, item } from "@library/common/database/classes";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";

import constants from "@library/common/constants/database";
import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import generateBaseRuleData from "../../../generateBaseRuleData";

export default (args) => {
  return new list({
    ...args,
    template: {
      Id: new item({
        submitKey: "Id",
      }),
      ClaimType: new item({
        submitKey: "ClaimType",
        defaultValue: "M",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      DateOfIncident: new item({
        submitKey: "ClaimDate",
        label: "Date of incident",
        helpText:
          "We only need to know about incidents within the last five years",
        fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
        fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),

        rules: [
          // new ruleItem({
          // 	...generateBaseRuleData("general", "salusConvertStringToDate"),},{
          // 	runOnUserUpdate: false,
          // 	forceUpdate: true,
          // }),
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "",

              // errorMessage: "Please enter the date of the incident",
            }
          ),
          new ruleItem(
            {
              ...generateBaseRuleData("general", "dateComparison"),
            },
            {
              errorMessage: "", //"You can't enter a future date",
              yearOffset: 0,
              comparator: "<=",
            }
          ),
          // new ruleItem({
          // 	...generateBaseRuleData("general", "dateComparison"),},{
          // 	errorMessage: "Incident must be within the last 5 years",
          // 	yearOffset: -5,
          // 	comparator: ">=",
          // }),
        ],
      }),

      OwnDamageCost: new item({
        submitKey: "OwnDamageCost",
        defaultValue: 0,
        // rules: [
        // 	new ruleItem({
        // 		...generateBaseRuleData("general", "isRequired"),},{
        // 		errorMessage: "", // errorMessage: "Required",
        // 	}),
        // ],
      }),
      ThirdPartyCost: new item({
        submitKey: "ThirdPartyCost",
        defaultValue: 0,

        // rules: [
        // 	new ruleItem({
        // 		...generateBaseRuleData("general", "isRequired"),},{
        // 		errorMessage: "", // errorMessage: "Required",
        // 	}),
        // ],
      }),

      // same as above?
      NcbPrejudiced: new item({
        submitKey: "NcbPrejudiced",
        label: "Was your no claims discount affected?",
        helpText:
          "Please choose 'Yes' if the no claims discount would have been affected, even if it was protected.",
        html: {
          itemData: constants.lists.yesNo,
        },
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", //"Please select",
            }
          ),
        ],
      }),

      PersonalInjury: new item({
        defaultValue: false,
        submitKey: "PersonalInjury",
        label: "Were there any personal injuries?",
        helpText: "This could be for anyone involved in the incident.",
        html: {
          itemData: constants.lists.yesNo,
        },
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // "Please select",
            }
          ),
        ],
      }),

      UnderPolicyholdersCover: new item({
        submitKey: "UnderPolicyholdersCover",
        defaultValue: false,
        // label: "Did this claim occur on your car insurance policy?",
        // html: {
        // 	itemData: constants.lists.yesNo,
        // },
        // rules: [
        // 	new ruleItem(
        // 		{
        // 			...generateBaseRuleData("general", "isRequired"),
        // 		},
        // 		{
        // 			errorMessage: "", //"Please select",
        // 		}
        // 	),
        // ],
      }),

      Cause: new item({
        submitKey: "Code",
        label: "Cause or description",
        helpText:
          "Please select an option from the list that best describes the cause of the incident.",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "",
              // errorMessage: "Please enter the cause",
            }
          ),
        ],
      }),
      BrokerReference: new item({
        submitKey: "BrokerReference",
      }),
    },
  });
};
