import dayjs from "dayjs";
import functions from "../../../general";
import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

const { dateComparison } = functions;

export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper").default,
  } = args;
  const dh = new dataHelper({
    mappings: ["ModificationInd", "Modifications"],
    dataSet,
    functionArgs,
  });

  if (dh.isPostRecall()) {
    // single element array fix
    if (
      dh.getValue("Modifications") !== undefined &&
      !Array.isArray(dh.getValue("Modifications"))
    ) {
      dh.setValue("Modifications", [dh.getValue("Modifications")]);
    }

    const newModificationInd =
      dh.getValue("Modifications") !== undefined &&
      dh.getValue("Modifications").length !== 0;

    dh.setValue("ModificationInd", newModificationInd);
    dh.setVisible("Modifications", newModificationInd);
  }

  if (!dh.isPostRecall()) {
    if (dh.isUpdated("ModificationInd")) {
      if (dh.getValue("ModificationInd") === true) {
        dh.setVisible("Modifications", true);
      } else {
        dh.setValue("Modifications", []);
        dh.setVisible("Modifications", false);
      }
    }

    if (
      dh.getValue("ModificationInd") === true &&
      (dh.getValue("Modifications") === undefined ||
        dh.getValue("Modifications").length === 0)
    ) {
      dh.addError(
        "Modifications",
        ""
        // "Please select your modifications."
      );
    }

    dh.addErrorIfEmpty("ModificationInd", ""); // "Required")
  }

  // console.log("dddd", dh);

  dh.commit();
};
