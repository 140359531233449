import React from "react";
import { generateCypressProps } from "siteFunctions/cypress";

const Panel = (props) => {
  const {
    children,
    classNameContainer,
    classNameHeading,
    classNameBody,
    heading,
  } = props;

  const _classContainer = [
    "lctr-panel",
    "panel",
    classNameContainer,
  ].toClassName();
  const _classNameHeading = ["panel-heading", classNameHeading].toClassName();
  const _classNameBody = ["panel-body", classNameBody].toClassName();

  return (
    <div className={_classContainer} {...generateCypressProps("panel", props)}>
      {heading && <div className={_classNameHeading}>{heading}</div>}
      <div className={_classNameBody}>{children}</div>
    </div>
  );
};

export default Panel;
