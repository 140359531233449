//  migration.js

import { rules as ruleLibrarySite } from "moveToLibrary/database/stella/carRisk";
import ReactDomServer from "react-dom/server";

const debugData = true;
const fnLog = (...args) => {
  if (!debugData) return;
  console.log(...args);
};
const fnOutputErrorInfo = (...args) => {
  console.log("********************************************");
  console.log("ERRORINFO:", ...args);
  console.log("********************************************");
};

import _ from "lodash";
import hash from "object-hash";

const fnArrayFilterLastItem = (arr = []) =>
  arr.filter((x, i) => i !== arr.length - 1);

export const fnParseDataSetArgItem = (dataSetArgItem, baseSearchPath = []) => {
  // console.log("fnParseDataSetArgItem", { dataSetArgItem, baseSearchPath });

  // if (dataSetArgItem.componentTag === "Paid") {
  //   console.log("dataSetArgItem", dataSetArgItem, {
  //     path: [
  //       ...(fnArrayFilterLastItem(baseSearchPath) || []),
  //       dataSetArgItem.componentTag,
  //     ].filter(Boolean),
  //   });
  //   // throw `hhhh`;
  // }

  if (!_.isObject(dataSetArgItem)) return undefined;

  if (dataSetArgItem.componentId) return dataSetArgItem.componentId.split("/");

  if (
    dataSetArgItem.useDefaultComponentSet &&
    dataSetArgItem.useDefaultComponentTag
  ) {
    return baseSearchPath;
  }

  if (dataSetArgItem.componentSet) {
    return [dataSetArgItem.componentSet, dataSetArgItem.componentTag];
  }

  if (dataSetArgItem.useDefaultComponentSet) {
    return [
      ...(fnArrayFilterLastItem(baseSearchPath) || []),
      dataSetArgItem.componentTag,
    ].filter(Boolean);
  }

  //componentSetPath
  if (dataSetArgItem.componentSetPath) {
    const newPath = dataSetArgItem.componentSetPath.reduce(
      (acc, curr, idx) => {
        // console.log("....", idx, { acc, curr });
        switch (curr) {
          case "..":
          case "...":
            return fnArrayFilterLastItem(acc);
          default:
            return [...acc, curr];
        }
      },
      fnArrayFilterLastItem(baseSearchPath) // Remove the last item (as this is the currentItem)
    );

    return [...newPath, dataSetArgItem.componentTag].filter(Boolean);
  }

  return undefined;
};

export const fnArgItemToSearchPath = (argItem, updatePath = []) => {
  const data = fnParseDataSetArgItem(argItem, updatePath);
  if (data) return data;
  return argItem;
};

export const database = (database, ruleLibrary, options = {}) => {
  // window.console.log = () => {};

  console.log("MIGRATION", "Processing:", database);
  const { autoMappingExclusions = [] } = options;

  const ruleList = [];
  const templateData = {};
  const reMappings = {}; // Where the submitKey <> the path
  // const functionSet = {};
  // const itemData = {};

  const fnRemap = (path) => {
    // if (path === "Risk/AdditionalInsuredSet") {
    //   console.log("ddddd", { templateData });
    //   throw `hhh`;
    // }
    if (_.isArray(path))
      throw `Error in migration fnRemap -- path is an array, should be a string`;
    if (reMappings[path]) return reMappings[path];
    return path;
  };

  const _fnGenerateReMappings = (node, path = [], level = 1) => {
    const nodeType = node.getItemType();
    const submitKey = node.getSubmitKey();

    switch (nodeType) {
      case "set": {
        const subItems = Object.entries(node.getKeys());
        subItems.forEach(([subKey, subNode]) => {
          _fnGenerateReMappings(subNode, [...path, subKey], level + 1);
        });
        break;
      }
      case "list": {
        const subItems = Object.entries(node.getTemplate());
        subItems.forEach(([subKey, subNode]) => {
          _fnGenerateReMappings(
            subNode,
            [
              ...path.map((x, i) => {
                if (i === path.length - 1) return `${x}[]`;
                return x;
              }),
              subKey,
            ],
            level + 1
          );
        });
        break;
      }
      case "item": {
        if (!submitKey) break;
        if (submitKey !== path[path.length - 1]) {
          reMappings[path.join("/")] = path
            .filter((x, i, arr) => i !== arr.length - 1)
            .concat(submitKey)
            .join("/");
        }
        break;
      }
      default:
    }
  };

  const fnRuleGenerateItem = ({
    ruleData,
    path,
    mappings,
    functionArgs = {},
    keys,
  }) => {
    const ruleType = ruleData.ruleType;
    // const fnKey = [ruleData.ruleLibrary, ruleData.ruleType].join("-");

    const fn = (...args) => {
      const ruleFn = ruleLibrarySite.getRule({
        ruleLibrary: ruleData.ruleLibrary,
        ruleType: ruleData.ruleType,
      });

      const executionPath =
        (args.length >= 1 && _.isObject(args[0]) && args[0].executionPath) ||
        "unknown path";
      const _console =
        (args.length >= 1 && _.isObject(args[0]) && args[0].console) || console;

      const timerKey = [
        "TIMER",
        "MIGRATION",
        executionPath,
        ruleData.ruleLibrary,
        ruleData.ruleType,
      ].join("-");

      _console.time(timerKey);
      _console.groupCollapsed(
        "EXECUTING MIGRATION RULE:",
        executionPath,
        "(",
        [ruleData.ruleLibrary, ruleData.ruleType].join("/"),
        ")"
      );

      _console.log("args:", ...args);
      _console.log("Other:", {
        ruleLibrarySite,
        rule: ruleData,
      });
      _console.groupEnd();

      const retData = ruleFn(...args);
      _console.timeEnd(timerKey);

      return retData;
    };

    const fnText = [
      "(...args) => {",
      // "const ruleData=",
      // ruleData.toString(),
      // ";",
      "const ruleFn = ruleLibrarySite.getRule(",
      `{ruleLibrary: "${ruleData.ruleLibrary}",ruleType: "${ruleData.ruleType}" });`,
      " return ruleFn(...args);",
      "}",
    ].join("");

    // console.log(ruleType, path, {
    //   mappings,
    //   functionArgs,
    // });

    // Try and update the mappingBase and mappings
    const mappingData = (function () {
      if (_.isEmpty(mappings)) return { mappings };

      const _mappingBaseArray = Object.values(mappings)
        // Filter for anything with an "[]"
        .filter((x) => x.includes("[]"))
        // Get only the array part
        .map((x) =>
          x
            .split("[]") // spit by "[]"
            .filter((y, i, arr) => i !== arr.length - 1) // Remove the last item
            .join("[]")
            .concat("[]")
        )
        // Deduplicate
        .reduce((acc, cur) => (acc.includes(cur) ? acc : [...acc, cur]), []);

      // if there is no arrays, exit early
      if (_mappingBaseArray.length === 0) return { mappings };
      if (_mappingBaseArray.length === 2) return { mappings };

      const _mappingBase = _mappingBaseArray[0];

      // if (!_mappingBase) {
      //   fnOutputErrorInfo({ mappings });
      //   throw `Error in updateItem -- empty mappingBase`;
      // }
      const _mappings = Object.fromEntries(
        Object.entries(mappings).map(([k, d]) => {
          // console.log("d", d, d.includes(_mappingBase));
          if (d.includes(_mappingBase))
            return [k, `${d.replace(_mappingBase, "")}`]; //NOTE: the replace will leave the PREFIX "/" (which is what we want)

          return [k, d];
        })
      );

      // if (ruleType === "convictionLogic") {
      //   console.log("ddddd", { _mappings, _mappingBase });
      //   throw `hhhh`;
      // }

      return { mappings: _mappings, base: _mappingBase };
    })();

    const data = {
      paths: [],
      ...(mappingData.base ? { mappingBase: mappingData.base } : {}),
      mappings: mappingData.mappings,
      fn: fn,
      fnText: fnText,
      functionArgs: functionArgs,
      isLegacy: true,
      errorKey: [ruleData.ruleLibrary, ruleData.ruleType].join("/"),
      // isAllowRuleRunMultipleTimes:
      //   Object.keys(mappingData.mappings).length <= 1,
      // defaultValue: keys.defaultValue,
      // defaultVisible: keys.defaultVisible,
      // defaultDisabled: keys.defaultDisabled,
    };

    const itemKey = [
      ruleType,
      `<${data.mappingBase || "nobase"}>`,
      hash(_.pick(data, ["functionArgs", "mappings", "mappingBase"])),
    ].join("__");

    return { itemKey, ruleType, path, data };
  };

  const fnRuleListAppend = (_newData) => {
    ruleList.push(_newData);
  };

  const fnTemplateAppend = ({ path = [], node, ruleList = [] }) => {
    const isSubmit = node.getSubmitKey() ? true : false;
    const keys = node.getKeys() || {};
    const props = node.getProps({ componentSet: "dummy" }) || {};
    // console.log("xxxx", path.join("/"), node);
    //fnReduxToSalus
    //fnSalusToRedux

    if (path.length === 0) return;

    const { fnReduxToSalus = (v) => v, fnSalusToRedux = (v) => v } = props;

    const _fnProcessPathNode = (node, path = [], level = 1) => {
      // console.log("_fnProcessPathNode", path.join("/"));
      const [pathFirst, ...pathRest] = path;

      const fnReduxToSalusText = fnReduxToSalus.toString();

      if (path.length === 1) {
        node[pathFirst] = {
          _isTemplateDataNode: true,
          ruleList: ruleList,
          isSubmit: isSubmit,
          label: keys.label,
          helpText: keys.helpText,
          helpTextFurther: keys.helpTextFurther,
          html: keys.html,
          defaultValue: keys.defaultValue,
          salus: {
            fnToText: fnReduxToSalus.toString(),
            fnFromText: fnSalusToRedux.toString(),
            fnTo: (value) => fnReduxToSalus(value), //TODO: STRINGIFY
            fnFrom: (value) => fnSalusToRedux(value),
          },
        };
        return;
      }

      node[pathFirst] = node[pathFirst] || {};
      _fnProcessPathNode(node[pathFirst], pathRest, level + 1);
    };

    console.groupCollapsed("fnTemplateAppend()", path.join("/"), {
      keys,
      isSubmit,
    });
    _fnProcessPathNode(templateData, path);
    console.groupEnd();
  };

  const _fnProcessNode = (node, path = [], level = 1) => {
    const nodeType = node.getItemType();
    const submitKey = node.getSubmitKey();

    // console.log(" ".repeat(level * 10), `(${nodeType})`, path.join("/"));

    switch (nodeType) {
      case "set": {
        const subItems = Object.entries(node.getKeys());

        subItems.forEach(([subKey, subNode]) => {
          _fnProcessNode(subNode, [...path, subKey], level + 1);
        });
        break;
      }
      case "list": {
        const subItems = Object.entries(node.getTemplate());
        subItems.forEach(([subKey, subNode]) => {
          _fnProcessNode(
            subNode,
            [
              ...path.map((x, i) => {
                if (i === path.length - 1) return `${x}[]`;
                return x;
              }),
              subKey,
            ],
            level + 1
          );
        });
        break;
      }
      case "item": {
        const ruleList = node.getKeys().rules;
        const templateRules = [];
        if (ruleList) {
          ruleList.forEach((r) => {
            const genuineFunctionArgs = {}; // A list of the functionArgs that don't map to anything in the RISK data

            const newMappings =
              r.functionArgs &&
              Object.fromEntries(
                Object.entries(r.functionArgs)
                  .filter(([mappingKey, data]) => {
                    if (autoMappingExclusions.includes(mappingKey))
                      return false;
                    return true;
                  })
                  .map(([mappingKey, data]) => {
                    const newData = fnParseDataSetArgItem(data, path);

                    if (newData === undefined) {
                      // We've not found an item, so let's assume it's a genuine functionArg (and not a mapping)
                      genuineFunctionArgs[mappingKey] = data;
                      return [mappingKey, undefined];
                    }

                    return [mappingKey, fnRemap(newData.join("/"))];
                  })
                  .filter(([mappingKey, data]) => data !== undefined)
              );

            {
              const _newRuleData = fnRuleGenerateItem({
                ruleData: r,
                path: fnRemap(path.join("/")),
                mappings: newMappings,
                functionArgs: genuineFunctionArgs,
                keys: node.getKeys(),
              });

              console.groupCollapsed(
                "fnRuleListAppend()",
                r.ruleType,
                path.join("/"),
                _newRuleData.itemKey
              );

              fnRuleListAppend(_newRuleData);
              templateRules.push(_newRuleData.itemKey);
              console.groupEnd();
            }
          });
        }

        // console.log("templateRules", templateRules)
        fnTemplateAppend({
          path: fnRemap(path.join("/")).split("/"),
          node: node,
          ruleList: templateRules,
        }); //submitKey

        break;
      }
      default:
    }
  };

  console.groupCollapsed("DATABASE MIGRATION");
  _fnGenerateReMappings(database, [database.getComponentTag()]);
  _fnProcessNode(database, [database.getComponentTag()]);
  console.groupEnd();

  const ruleData = ruleList.reduce((acc, cur, idx) => {
    const itemKey = cur.itemKey;
    // console.log("....", itemKey, { cur });

    acc[itemKey] = acc[itemKey] || cur.data;
    acc[itemKey].paths.push(cur.path);

    return acc;
  }, {});

  console.time(`MIGRATION`);

  console.log("MIGRATION", "complete:", {
    ruleList,
    ruleData,
    templateData,
    reMappings,
  });

  //*******************************
  // #3019 - migrate the config
  //*******************************
  if (false) {
    const copyData = (function () {
      const fnParseRuleData = (node) => {
        if (_.isObject(node)) {
          if ("fn" in node) {
            return [
              JSON.stringify({
                fn: node.fnText,
                ..._.omit(node, ["fn", "fnText"]),
              }),
            ];
          }

          return [
            "{",
            ...Object.entries(node).flatMap(([k, d]) => {
              return [k, ":", ...fnParseRuleData(d)];
            }),
            "}",
          ];
        }
        return [node];
      };

      const fnOutputData = (node, path = []) => {
        const thisKey = path[path.length - 1];
        const nodeType = typeof node;

        if (false) {
          console.log(" ".repeat(path.length * 3), `(${thisKey})`, nodeType, {
            node: node,
            // definition: node && node.toString(),
            // isArray: _.isArray(node),
            // isObject: _.isObject(node),
          });
        }

        switch (nodeType) {
          case "function":
            if (node) return [node.toString()];
          //return ["{",`["${k}"]` , node.toString(),"}"];
        }

        if (node === undefined) return ["undefined"];
        if (node === null) return ["null"];
        if (_.isString(node)) return [`\`${node}\``];

        if (_.isArray(node)) {
          const arrayData = node.flatMap((d, i) => {
            const data = fnOutputData(d, [...path, `k[${i}]`]);

            if (i === 0) return data;
            return [",", ...data];
          });

          // console.log("dddddd", arrayData);

          return ["[", ...arrayData, "]"];
        }

        if (_.isObject(node)) {
          if (node["$$typeof"] === Symbol.for("react.element")) {
            //https://stackoverflow.com/questions/34114679/convert-a-react-element-to-a-jsx-string
            // console.log("ddddddddddd", node, {
            //   renderToString: ReactDomServer.renderToString(node),
            //   renderToStaticMarkup: ReactDomServer.renderToStaticMarkup(node),
            // });

            return ["<>", ReactDomServer.renderToStaticMarkup(node), "</>"];
          }

          return [
            "{",
            ...Object.entries(node).flatMap(([k, d]) => {
              return [`["${k}"]`, ":", ...fnOutputData(d, [...path, k]), ","];
            }),
            "}",
          ];
        }
        return [node];
      };

      // const fnParseFunctionSet = (node) => {
      //   return Object.fromEntries(
      //     Object.entries(node).map(([k, d]) => {
      //       return [k, d];
      //     })
      //   );
      // };

      return [
        `import React from "react"`,
        `import { rules as ruleLibrarySite } from "moveToLibrary/database/stella/carRisk";`,
        "const ruleList =",
        ...fnOutputData(ruleData),
        ";",
        "const templateData = ",
        ...fnOutputData(templateData),
        "export default {ruleList,templateData};",
      ].join("\r\n");

      // return {
      //   ruleData: fnParseRuleData(ruleData),
      //   functionSet: fnParseFunctionSet(functionSet),
      // };
    })();

    console.groupCollapsed("MIGRATION COPY");
    console.log(copyData);
    // copyData.forEach((x) => console.log(x));
    console.groupEnd();
    throw `migration disable`;
  }
  console.timeEnd("MIGRATION");

  // throw `hhh`;
  return { rules: ruleData, template: templateData };
  // console.log(
  //   "MIGRATION",
  //   "proposerLogic",
  //   itemData.proposerLogic.mappings
  // );
};
