import React from "react";
import Base from "./Base";

const PageSplitLayout = (props) => {
  const { children, sideContent = <></>, ...otherProps } = props;

  return (
    <Base {...otherProps}>
      <div className="row">
        <div className="col-12 col-lg-8"> {children}</div>
        <div className="col-12 col-lg-4 d-none d-lg-block">{sideContent}</div>
      </div>
    </Base>
  );
};

export default PageSplitLayout;
