import React, { useState, useCallback, useMemo } from "react";
import FileSaver from "file-saver";
import { detect } from "detect-browser";
import _ from "lodash";
// import queue from "queue";
import syncQueue from "sync-queue";
import useValueSet from "moveToLibrary/hooks/useValueSet";
import Cookies from "js-cookie";

/*
https://www.npmjs.com/package/detect-browser

https://www.npmjs.com/package/@react-pdf/renderer
https://stackoverflow.com/questions/66373180/display-a-pdf-in-the-browser-in-a-modal-from-blob-data-using-react-pdf-react-p

https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari

https://stackoverflow.com/questions/62575805/convert-blob-to-pdf-file
https://stackoverflow.com/questions/70877777/open-a-pdf-file-via-javascript-not-supported-in-safari-15-1-on-ios-15-1-in-react

https://stackoverflow.com/questions/37817591/unable-to-download-pdf-blob-url-on-safari
window.open(fileBlob, '_blank');

*/
const generate = (args = {}) => {
  const { storeName, actions, selectors, services } = args;

  // ******************************
  // GLOBAL ITEMS
  // ******************************
  const queue = new syncQueue();

  // ******************************
  // RETURN ITEM(S)
  // ******************************
  const useDocumentFunctions = (dispatch, options = {}) => {
    const throwError = (msg) => {
      throw new Error(`error in useDocumentFunctions - ${msg}`);
    };

    if (!dispatch) throwError(`missing dispatch`);

    const [status, setStatus] = useValueSet();

    const _fnProcessDocument = async (mode, args) => {
      const { onError = () => {} } = args;

      try {
        switch (mode) {
          case "open":
          case "save": {
            ["documentId", "policyId", "mimeContentType"].forEach((key) => {
              if (!(key in args)) throwError(`missing key ${key}`);
              if (!args[key]) throwError(`empty key ${key}`);
            });

            // console.log("fnOpenDocument", args)

            const { onStart = () => {}, onError = () => {}, documentId } = args;

            const fnSetStatus = async (status) => {
              // await dispatch(actions.downloadSetStatus({ documentId, status }));
              setStatus(documentId, status);
            };

            await fnSetStatus("WAITING");

            // **************************************
            // START: Add the PROCESS function to the queue
            // **************************************
            const fnProcess = async () => {
              const {
                documentId,
                policyId,
                mimeContentType,
                onSuccess = () => {},
                generateFileName = (f) => f,
                onWindowOpenFail = () => {},
              } = args;

              onStart();
              await fnSetStatus("PROCESSING");

              const response = await services.getDocument({
                policyId: policyId,
                documentId: documentId,
                mimeType: mimeContentType,
              });

              const {
                byteArrayData,
                base64Data,
                filename,
                isSuccess,
                mimeType,
                message,
              } = response || {};

              if (isSuccess) {
                const _fileName = generateFileName(filename);

                if (mode === "save") {
                  {
                    // const { type } = args;
                    // switch (type) {
                    //   case "original": {
                    //     const blob = new Blob([byteArrayData], {
                    //       type: mimeType,
                    //     });
                    //     FileSaver.saveAs(blob, _fileName);
                    //     break;
                    //   }
                    //   case "createObjectURL": {
                    //     // https://github.com/eligrey/FileSaver.js/issues/12
                    //     const blob = new Blob([byteArrayData], {
                    //       type: mimeType,
                    //     });
                    //     const url = window.URL.createObjectURL(blob);
                    //     FileSaver.saveAs(url, _fileName);
                    //     break;
                    //   }
                    //   case "open": {
                    //     const blob = new Blob([byteArrayData], {
                    //       type: mimeType,
                    //     });
                    //     window.open(URL.createObjectURL(blob));
                    //     break;
                    //   }
                    //   case "open-timeout": {
                    //     const blob = new Blob([byteArrayData], {
                    //       type: mimeType,
                    //     });
                    //     setTimeout(() => {
                    //       window.open(URL.createObjectURL(blob), "_blank");
                    //     });
                    //     break;
                    //   }
                    //   case "hyperlink": {
                    //     let a = document.createElement("a");
                    //     document.body.appendChild(a);
                    //     a.style = "display: none";
                    //     a.href = `data:${mimeType};base64,${base64Data}`;
                    //     a.download = _fileName;
                    //     a.click();
                    //     document.body.removeChild(a);
                    //     // console.log("a.href", a.href);
                    //     break;
                    //   }
                    // }
                  }

                  const blob = new Blob([byteArrayData], { type: mimeType });
                  FileSaver.saveAs(blob, _fileName);
                }

                if (mode === "open") {
                  const blob = new Blob([byteArrayData], { type: mimeType });

                  const myWin = window.open(
                    URL.createObjectURL(blob),
                    "_blank"
                  );

                  if (!myWin) {
                    onWindowOpenFail({ blob: blob, fileName: _fileName });
                  }
                }

                await dispatch(
                  actions.updateIsViewed({ documentId, policyId })
                );
                onSuccess(message, {
                  dataString: `data:${mimeType};base64,${base64Data}`,
                });
                await fnSetStatus("COMPLETE");
              } else {
                onError(message);
                await fnSetStatus("ERROR");
              }

              queue.next();
            };

            queue.place(fnProcess);
            // **************************************
            // END: Add to the queue
            // **************************************

            break;
          }

          case "mail": {
            const {
              documentIdList = [],
              onStart = () => {},
              onSuccess = () => {},
              webReference,
              policyId,
            } = args;
            const statusKey = "MAIL";

            const fnSetStatus = async (status) => setStatus(statusKey, status);

            await fnSetStatus("WAITING");

            const fnProcess = async () => {
              onStart();
              await fnSetStatus("PROCESSING");

              const response = await services.mailDocument({
                documentIds: documentIdList,
                policyId: policyId,
                webReference: webReference,
              });
              const { isSuccess, message } = response || {};

              if (isSuccess) {
                await fnSetStatus("COMPLETE");
                onSuccess(message);
              } else {
                await fnSetStatus("ERROR");
                onError(message);
              }
              queue.next();
            };

            queue.place(fnProcess);

            break;
          }
        }
      } catch (e) {
        console.error("useDocumentFunctions", e);
        onError(e);
      }
    };

    const fnOpenDocument = async (args) => {
      // return await _fnProcessDocument("open", args);

      const browser = detect();
      // console.log("browser.os", browser.os);
      switch (browser.os) {
        case "iOS":
          return await _fnProcessDocument("save", args);
        default:
          return await _fnProcessDocument("open", args);
      }
    };

    const fnOpenDirectDocument = async (link, cookies = {}) => {
      // https://www.npmjs.com/package/js-cookie
      const linkDetails = document.createElement("a");
      linkDetails.href = link;
      const browser = detect();
      console.log("browser.os", browser.os);

      const cookieOptions = {
        domain: linkDetails.hostname,
      };

      console.log("ddd", { link, cookies, linkDetails, cookieOptions });

      Object.entries(cookies).forEach(([key, value]) => {
        Cookies.set(key, value, cookieOptions);
      });
      window.open(link, "_blank");
    };

    const fnSaveDocument = async (args) =>
      await _fnProcessDocument("save", args);

    const fnMailDocument = async (args) => {
      await _fnProcessDocument("mail", args);
    };

    const fnPrintDocument = async (item) => {
      //Ticket #2480
      throw `Not yet implemented`;
      // https://www.npmjs.com/package/print-js
    };

    // console.log("dddd", retStatus, Object.keys(retStatus).length);
    //
    // console.log("status", description, status, test);

    const processing = Object.fromEntries(
      Object.entries(status).filter(([key, value]) =>
        ["WAITING", "PROCESSING"].includes(value)
      )
    );

    const processingCount = Object.keys(processing).length;

    const retStatus = Object.fromEntries(
      Object.entries(status).map(([key, value]) => {
        const newData = {
          isWaiting: value === "WAITING",
          isProcessing: value === "PROCESSING",
          isComplete: value === "COMPLETE",
          isError: value === "ERROR",
        };
        return [key, newData];
      })
    );

    // console.log("ddddd", status, retStatus);

    return {
      openDirect: fnOpenDirectDocument,
      open: fnOpenDocument,
      save: fnSaveDocument,
      mail: fnMailDocument,
      isDocumentWating: (documentId) => status[documentId] === "WAITING",
      isDocumentProcessing: (documentId) => status[documentId] === "PROCESSING",
      isDocumentComplete: (documentId) => status[documentId] === "COMPLETE",
      isDocumentError: (documentId) => status[documentId] === "ERROR",
      print: fnPrintDocument,
      status: retStatus,
      // processing: processing,
      processingCount: processingCount,
      isProcessing: processingCount >= 1,
    };
  };

  // Final return
  return { useDocumentFunctions };
};
export default generate;
