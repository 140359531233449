import React from "react";

const PriceDisplay = (props) => {
	const { value, children, decimalPlaces = 2, allowZero = false, isStrong = true } = props;

	if (!allowZero && !value && !children) return null;

	const _value = (allowZero && !value && !children ? 0 : (value || children));

	if (isNaN(_value)) return null;

	const displayValue = _value.toLocaleString("en-GB", {
		style: "decimal",
		maximumFractionDigits: decimalPlaces,
		minimumFractionDigits: decimalPlaces,
	});

	if (isStrong)
		return <strong>{`£${displayValue}`}</strong>;

	return <span>{`£${displayValue}`}</span>;
};

export default PriceDisplay;
