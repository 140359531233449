import services from "siteService";
import dateHelpers from "@library/common/helpers/misc/date";
import JSONhelper from "@library/common/helpers/misc/json";

import dayjs from "dayjs";
import { generateKeys } from "siteHelpers/services";
// console.log("services", services);

// services.lookups.auth.getSecurityQuestions().then((response) => {
//   console.log("getSecurityQuestions", response);
// });
// DEBUG MODE
// if (false) {
//   const [response] = await Promise.all([
//     Promise.resolve({
//       Status: (function () {
//         const seed = Math.floor(Math.random() * 4 + 1);

//         if (seed === 1) return "SUCCESS";
//         if (seed === 2) return "INVALID_TOKEN";
//         if (seed === 3) return "INVALID_LOGIN_DETAILS";
//         if (seed === 4) return "USER_ACTIVATE_FAILED";
//         if (seed === 4) return "GET_USER_DETAILS_FAILED";
//       })(),
//       // Token: "xxxxxxxTOKENxxxxxx",
//     }),
//     new Promise((resolve) => setTimeout(resolve, 1000)),
//   ]);
//   return response;
// }

const auth = {
  getUserDetails: async (args = {}) => {
    ["userId"].argChecker(args);

    // console.log("ddd", "getUserDetails", args);
    const response = await services.auth.getUserDetails();
    // console.log("dddd ", response);

    if (response === "ABORT") return "ABORT";

    return {
      isSuccess: response.Status === "SUCCESS",
      userInfo: response.userInfo,
      message: response.Message,
    };
  },
  create: async (payload = {}) => {
    const fnDebug = (...args) =>
      console.log("store.services.js", "create", ...args);

    fnDebug("payload", payload);

    const servicePayLoad = {
      DateOfBirth: `${dayjs(payload.dateOfBirth).format(
        "YYYY-MM-DD"
      )}T00:00:00`, // "1987-01-01T00:00:00", // dateHelpers.toString(payload.dateOfBirth),
      WebReference: payload.webReference,
      EmailAddress: payload.emailAddress,
      HasEmailChanged: payload.hasEmailChanged,
      Postcode: payload.postcode,
      Username: payload.username,
      Password: payload.password,
      PolicyId: payload.policyId,
      ProposerId: payload.proposerId,
      SecurityQuestions: payload.securityQuestions
        ? payload.securityQuestions.map((x) => ({
            Question: x.question,
            Answer: x.answer,
          }))
        : undefined,
    };

    // fnDebug("servicePayLoad", servicePayLoad);

    const response = await services.auth.create(servicePayLoad);
    if (response === "ABORT") return "ABORT";

    // console.log("webservice reponse", response);
    const storeResponse = {
      activationToken: response.Token,
      isAccountCreated: response.AccountCreated,
      isAccountActivated: response.AccountActivated,
      ...generateKeys(response.Status, response.Message, [
        {
          key: "isUnsuccessful",
          value: "SUCCESS",
          comparator: (a, b) => a !== b,
        },
        { key: "isSuccess", value: "SUCCESS" },
        { key: "isPolicyUnavailable", value: "POLICY_UNAVAILABLE" },
        { key: "isUsernameUnavailable", value: "USERNAME_UNAVAILABLE" },
        { key: "isCreateFailed", value: "USER_CREATE_FAILED" },
      ]),
    };

    return storeResponse;
  },
  link: async (payload = {}) => {
    const servicePayLoad = {
      WebReference: payload.webReference,
      Username: payload.username,
      Password: payload.password,
    };

    const response = await services.auth.addPolicyToUser(servicePayLoad);
    if (response === "ABORT") return "ABORT";

    // console.log("webservice reponse", response);
    const storeResponse = {
      // isAccountActivated: response.Status === "SUCCESS",
      isValid: response.IsValid,
      ...generateKeys(response.Status, response.Message, [
        { key: "isSuccess", value: "SUCCESS" },
        {
          key: "isUnsuccessful",
          value: "SUCCESS",
          comparator: (a, b) => a !== b,
        },
        { key: "isInvalidToken", value: "INVALID_TOKEN" },
        { key: "isInvalidLoginDetails", value: "INVALID_LOGIN_DETAILS" },
        { key: "isUserActivateFailed", value: "USER_ACTIVATE_FAILED" },
        { key: "isGetUSerDetailsFailed", value: "GET_USER_DETAILS_FAILED" },
      ]),
      userInfo: response.UserInfo,
      userToken: response.Token,
    };

    return storeResponse;
  },

  activate: async (payload = {}) => {
    const servicePayLoad = {
      WebReference: payload.webReference,
      Username: payload.username,
      Password: payload.password,
      Token: payload.token,
    };

    const response = await services.auth.activate(servicePayLoad);
    if (response === "ABORT") return "ABORT";

    // console.log("webservice reponse", response);
    const storeResponse = {
      // isAccountActivated: response.Status === "SUCCESS",
      isValid: response.IsValid,
      // isInvalidToken: response.Status === "INVALID_TOKEN",
      // isInvalidLoginDetails: response.Status === "INVALID_LOGIN_DETAILS",
      // isUserActivateFailed: response.Status === "USER_ACTIVATE_FAILED",
      // isGetUSerDetailsFailed: response.Status === "GET_USER_DETAILS_FAILED",
      ...generateKeys(response.Status, response.Message, [
        { key: "isSuccess", value: "SUCCESS" },
        {
          key: "isUnsuccessful",
          value: "SUCCESS",
          comparator: (a, b) => a !== b,
        },
        { key: "isInvalidToken", value: "INVALID_TOKEN" },
        { key: "isInvalidLoginDetails", value: "INVALID_LOGIN_DETAILS" },
        { key: "isUserActivateFailed", value: "USER_ACTIVATE_FAILED" },
        { key: "isGetUSerDetailsFailed", value: "GET_USER_DETAILS_FAILED" },
      ]),
      userInfo: response.UserInfo,
      userToken: response.Token,
    };

    return storeResponse;
  },
  authenticate: async (payload = {}) => {
    const servicePayLoad = {
      WebReference: payload.webReference,
      Username: payload.username,
      Password: payload.password,
    };

    // console.log("webservice payload", payload);
    const response = await services.auth.authenticate(servicePayLoad);
    if (response === "ABORT") return "ABORT";

    const storeResponse = {
      isValid: response.IsValid,
      ...generateKeys(response.Status, response.Message, [
        {
          key: "isUnsuccessful",
          value: "SUCCESS",
          comparator: (a, b) => a !== b,
        },
        { key: "isAuthenticated", value: "SUCCESS" },
        { key: "isInvalidLoginDetails", value: "INVALID_LOGIN_DETAILS" },
        { key: "isUserDetailsFailed", value: "GET_USER_DETAILS_FAILED" },
      ]),
      // isAuthenticated: response.Status === "SUCCESS",
      // isInvalidLoginDetails: response.Status === "INVALID_LOGIN_DETAILS",
      // isUserDetailsFailed: response.Status === "GET_USER_DETAILS_FAILED",
      userInfo: response.UserInfo,
      userToken: response.Token,
    };

    return storeResponse;
  },
};

export default auth;
