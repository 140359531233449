import React, { useEffect } from "react";
import Base from "./Base";
import { useLocation } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import {
  DevContainer,
  JsonViewer,
  ButtonStyled,
  ButtonStyledRenewal,
  ButtonLink,
  PanelRenewal,
  TelephoneService,
} from "siteComponents";
import Feedback from "./Feedback";

const RenewalFeedbackSPMView = (props) => {
  const { feedbackType } = props;

  // const commonData = useOutletContext();

  // useEffect(() => {
  //   const myFn = async () => {
  //     // if (feedbackType) {
  //     //  await commonData.functions.navigate.feedback(feedbackType);
  //     //   return;
  //     // }
  //     console.log("ddddd");
  //     // await commonData.functions.navigate.start()
  //     await commonData.functions.navigate.feedback("unknown");
  //     console.log("ddddd");
  //   };
  //   myFn();
  // }, []);
  // return null;

  return <Feedback feedbackType={feedbackType} />;
  return (
    <Base pageId="FEEDBACKSPM" headingText={"Please wait..."} toolbarText={""}>
      {feedbackType}
    </Base>
  );
};

export default RenewalFeedbackSPMView;
