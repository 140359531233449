// generateOcupation

import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";

import ButtonLabel from "@material-ui/icons/Search";

const requiredItemProps = ["Type", "OccCode", "EmpCode"];

export default ({
  database,
  selectors,
  actions,
  siteComponents,
  storeComponentsSite,
  storeComponentsCustom,
  classes,
  functions,
  store,
}) => {
  const { DropDownWebService, DropDownTypeWebService, TextBox } =
    storeComponentsSite;

  const { FormGroup } = storeComponentsCustom;
  // const { TextBox } = siteComponents;

  // Generator function
  return ({
    services,
    itemProps,
    // searchOccCodeMinLength = 3,
    // searchOccCodeMaxLength = 20,
    // searchEmpCodeMinLength = 3,
    // searchEmpCodeMaxLength = 20,
  }) => {
    if (!services) throw `Error in generator Occupation -- missing service`;

    requiredItemProps.forEach((key) => {
      if (!(key in itemProps))
        throw `Error in generator Occupation -- missing itemProp: ${key}`;
    });

    const makeMapStateToProps = () => {
      return (state) => {
        let retObj = {};
        // requiredItemProps.forEach((key) => {
        //   retObj[key] = store.selectors.userData.risk.metaData(
        //     state,
        //     itemProps[key].path
        //   )?.value;
        // });
        return retObj;
      };
    };

    const mapDispatchToProps = {
      update: store.actions.updateValue,

      // update: actions.update,
      // register: actions.register,
      // validate: actions.validate,
    };

    //** MAIN component
    const Component = connect(
      makeMapStateToProps,
      mapDispatchToProps
    )((props) => {
      const { itemColClassName = "col-md-10 col-lg-8" } = props;

      return (
        <>
          <FormGroup databaseProps={itemProps.Type} isStoreComponent={true}>
            <div className="row">
              <div className={itemColClassName}>
                <DropDownTypeWebService
                  databaseProps={itemProps.Type}
                  DataFunction={services.Type}
                  emptyLabel="Please choose..."
                />
              </div>
            </div>
          </FormGroup>

          <FormGroup databaseProps={itemProps.OccCode} isStoreComponent={true}>
            <div className="row">
              <div className={itemColClassName}>
                <DropDownTypeWebService
                  databaseProps={itemProps.OccCode}
                  DataFunction={services.OccCode}
                />
              </div>
            </div>
          </FormGroup>
          <FormGroup databaseProps={itemProps.EmpCode}>
            <div className="row">
              <div className={itemColClassName}>
                <DropDownTypeWebService
                  databaseProps={itemProps.EmpCode}
                  DataFunction={services.EmpCode}
                />
              </div>
            </div>
          </FormGroup>
        </>
      );
    });

    return Component;
  };
};
