import React, { useEffect, useState } from "react";
import { useDispatch } from "siteFunctions/hooks";
import { useSelector } from "react-redux";
import _ from "lodash";
import useSalusCompare from "moveToLibrary/hooks/useSalusCompare";
import {
  useLocation,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

import {
  selectors as siteSelectors,
  actions,
  components,
  database,
  helpers,
  hooks,
  mtaCar,
} from "siteStore";
import Base from "../Base.jsx";
import {
  DevContainer,
  JsonViewer,
  ButtonStyled,
  TelephoneService,
  PanelMta,
} from "siteComponents";
import dateHelpers from "@library/common/helpers/misc/date";
import dayjs from "dayjs";

// import { getProps, mtaComponents } from "../../common";

const WraupView = () => {
  //** NOTE: You can only reach this page via "useNavigate()"
  //** if you do an F5, the TOPLEVEL container will act as a shield as the policyNumber will be missing (in both the STATE and the REDUX STORE/useCommon)

  const { functions, selectors } = useOutletContext();
  const location = useLocation();

  // const data_Wrapup_initial = useSelector(selectors.getWrapupData);
  // const [data_Wrapup] = useState(data_Wrapup_initial);

  const data_Wrapup = location?.state?.data_Wrapup;

  // const [isPageInit, setIsPageInit] = useState(false);

  console.groupCollapsed("MTA/Wrapup.jsx");
  console.log({ data_Wrapup, location });
  console.groupEnd();

  if (!data_Wrapup) return functions.navigation.components.exit;

  // Saftey check.
  if (!["AP", "RP", "NIL"].includes(data_Wrapup.mtaType)) {
    throw `Error in MTA/Wrapup.jsx -- unknown wrapup type`;
  }

  // **********************************************
  // VARS
  // **********************************************
  //TODO
  const startDate = dateHelpers.toDate(data_Wrapup.startDate);
  const isFuture = dayjs(startDate).isAfter(dayjs());
  const isWrapupComplete = data_Wrapup.isWrapupComplete;

  // **********************************************
  // EFFECTS
  // **********************************************
  useEffect(() => {
    const myFn = async () => {
      // ** Let's do a reset! We now use the LOCAL copy
      await functions.process.reset();
      await functions.refreshUserInfo();
    };

    myFn();
  }, []);

  // **********************************************
  // FINALISE
  // **********************************************

  const [heading, displayData] = (function () {
    const fragmentButtonExit = (
      <ButtonStyled onClick={functions.navigation.exit}>
        Return home
      </ButtonStyled>
    );

    if (!isWrapupComplete) {
      if (isFuture)
        return [
          "Thank you, your changes are being processed",
          <PanelMta data-cy="wrapup-fail-future">
            <p>
              Your changes will be effective from{" "}
              {dayjs(startDate).format("DD MMM YYYY")}.
            </p>

            {data_Wrapup.emailAddress && (
              <p>
                A confirmation email will be sent to{" "}
                <strong>{data_Wrapup.emailAddress}</strong> once we've completed
                processing your changes.
              </p>
            )}
            <p>
              If you have any questions, please don't hesitate to get in touch
              with us on <TelephoneService />.
            </p>
            {fragmentButtonExit}
          </PanelMta>,
        ];

      // is NOT FUTURE
      return [
        "Thank you, your changes are being processed",
        <PanelMta data-cy="wrapup-fail-sameday">
          {(function () {
            if (data_Wrapup.mtaType === "AP")
              return (
                <p>
                  Your payment has been successful but due to a system error
                  we've not yet been able to complete your changes
                </p>
              );
            return (
              <p>
                Due to a system error we've not yet been able to complete your
                changes
              </p>
            );
          })()}

          <h4>PLEASE DO NOT ATTEMPT TO MAKE THE CHANGES AGAIN</h4>

          <p>
            Please contact our call centre on <TelephoneService /> and one of
            our agents will be happy to assist you.
          </p>

          {data_Wrapup.emailAddress && (
            <p>
              A confirmation email will be sent to{" "}
              <strong>{data_Wrapup.emailAddress}</strong> once we've completed
              processing your changes.
            </p>
          )}

          {fragmentButtonExit}
        </PanelMta>,
      ];
    }

    return [
      "Thank you, your policy has been updated",
      <PanelMta data-cy="wrapup-success">
        <p>
          Your changes will be effective from{" "}
          {dayjs(startDate).format("DD MMM YYYY")}.
        </p>
        {data_Wrapup.emailAddress && (
          <p>
            A confirmation email has been sent to{" "}
            <strong>{data_Wrapup.emailAddress}</strong>.
          </p>
        )}
        <p>
          If you have any questions, please don't hesitate to get in touch with
          us on <TelephoneService />.
        </p>
        {fragmentButtonExit}
      </PanelMta>,
    ];
  })();

  return (
    <Base
      className="mta-wrapup"
      headingText={heading}
      showSessionSaving={false}
      isWrapup={true}
      data-cy="layout-mta-wrapup-complete"
    >
      {displayData}
    </Base>
  );
};

export default WraupView;
