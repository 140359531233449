// features/dashboard/dashboardSlice.js
import { createSlice } from "@reduxjs/toolkit";
import chalk from "chalk";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  createExtraReducersSet,
  extraReducersSet,
  consoleLog,
} from "../common";

const generate = (args = {}) => {
  const { storeName, privateActions, abortChecker } = args;

  // const consoleLog = (...args) => {
  //   if (!debug) return;
  //   console.log(chalk.green.bold("REDUX:"), storeName, ":", ...args);
  // };

  const initialState = {
    // notifications: [],
    // notificationsStatus: "OKAY",
    // policyDetails: {},
    userInfo: {},
    userInfoRefreshing: false,
    dataValues: {},
  };

  const dashboardSlice = createSlice({
    name: storeName,
    initialState,
    reducers: {
      setValue(state, action) {
        const { key, value } = action.payload;
        state.dataValues = state.dataValues || {};
        state.dataValues[key] = value;
      },
      importUserInfo(state, action) {
        state.userInfo = action.payload;
      },
      importNotifications(state, action) {
        consoleLog(
          { storeName, subActionName: "importNotifications" },
          action.payload
        );
        state.notifications = action.payload;
      },
      reset() {
        consoleLog({ storeName, subActionName: "reset" });
        return initialState;
      },
      updateRemainingUploads(state, { payload = {} }) {
        const { policyNumber, value } = payload;

        if (!policyNumber)
          throw `Error in updateRemainingUploads -- missing policyNumber`;
        if (value === undefined)
          throw `Error in updateRemainingUploads -- missing value`;

        const foundItem = state.userInfo.Policies.find(
          (x) => x.PolicyNumber === policyNumber
        );

        if (foundItem) {
          foundItem.RemainingUploads = value;
        }
      },
    },
    extraReducers: (builder) => {
      extraReducersSet(
        builder,
        privateActions.refreshUserInfo,
        "refreshUserInfo",
        {
          pending: (state) => {
            // state.userInfo = undefined;
            state.userInfoRefreshing = true;
          },
          fulfilled: (state, { payload = {} }) => {
            state.userInfo = payload.userInfo;
            state.userInfoRefreshing = false;
          },
          rejected: (state) => {
            state.userInfo = undefined;
            state.userInfoRefreshing = false;
          },
        },
        { storeName, abortChecker }
      );
      // extraReducersSet(builder,
      //   privateActions.getPolicyDetails,
      //   "getPolicyDetails",
      //   {
      //     pending: (state) => {
      //       // state.notificationsStatus = "LOADING";
      //       // state.notifications = [];
      //     },
      //     fulfilled: (state, { payload = {} }) => {
      //       // state.notifications = payload.notifications;
      //       // state.notificationsStatus = "OKAY";
      //     },
      //     rejected: (state) => {
      //       // state.notificationsStatus = "ERROR";
      //       // state.notifications = [];
      //     },
      //   },
      //   { storeName, abortChecker }
      // ),
    },
  });

  return { reducer: dashboardSlice.reducer, actions: dashboardSlice.actions };
};

export default generate;
