import React, { useEffect, useState } from "react";
import _ from "lodash";
import PleaseWait from "../resources/components/PleaseWait";

import { useParams, useOutletContext } from "react-router-dom";

import Base from "./Base.jsx";

const RenewalWrapupView = () => {
  const params = useParams();

  const { paymentId } = params; // Get the paymentId from the url

  if (!paymentId) throw `Error in RenewalWrapupView -- missing paymentId`;

  const commonData = useOutletContext();
  const { functions } = commonData;
  const mainData = commonData.values.data;
  console.log("mainData", mainData);

  // **********************************************
  // EFFECTS
  // **********************************************

  useEffect(() => {
    const myFn = async () => {
      const response = await functions.wrapup.getPurchaseResponse(paymentId);

      // if (!response.isSuccess) {
      //   functions.navigate.feedback("purchaseResponseError");
      //   return;
      // }

      const baseArgs = {
        startDate: commonData.functions.formatters.date(
          mainData.RenewalSummary.CoverFrom
        ),
        emailAddress: mainData.RenewalSummary.Proposer.Email,
        isWrapupComplete: response?.isSuccess,
        isError: response?.isError,
      };

      await functions.wrapup.AP(baseArgs, { replace: true });
      await functions.session
        .save
        // "Please wait a moment while we complete your changes."
        ();
    };
    myFn();
  }, []);

  // **********************************************
  // FINALISE
  // **********************************************

  return (
    <Base pageId="WRAPUP">
      <PleaseWait>
        Please wait a moment while we complete your renewal.
      </PleaseWait>
      {/* <FeedbackWrapup /> */}
    </Base>
  );
};

export default RenewalWrapupView;
