import React from "react";
import config from "config";
import { generateCypressProps } from "siteFunctions/cypress";

// import "siteAssets/css/mainStyle";

const ScreenFinder = (props) => {
  const { className } = props;

  if (!config.isDev) return null;

  const _className = [
    "lctr-resolution-finder",
    "text-red",
    className,
  ].toClassName();

  return (
    <span
      className={_className}
      {...generateCypressProps("resolution-finder", props)}
    >
      <span className="d-inline-block d-sm-none">XS</span>
      <span className="d-none d-sm-inline-block d-md-none">SM</span>
      <span className="d-none d-md-inline-block d-lg-none">MD</span>
      <span className="d-none d-lg-inline-block d-xl-none">LG</span>
      <span className="d-none d-xl-inline-block d-xxl-none">XL</span>
      <span className="d-none d-xxl-inline-block">XXL</span>
    </span>
  );
};

export default ScreenFinder;
