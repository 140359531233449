import riskUtils from "../utils/risk";

import _ from "lodash";

const fnOutputErrorInfo = (...args) => {
  console.log("********************************************");
  console.log("ERRORINFO:", ...args);
  console.log("********************************************");
};
export const fnCreate = (state, path, options = {}) => {
  // console.log("fnCreate", path);
  const obj = riskUtils.create.item(state, path, {
    ...options,
    generateBlankItem: () => riskUtils.create.dataNode(),
  });

  // if (path === "Risk/Vehicle/Modifications") throw `hhhh`;
  return obj;
};

export const fnUpdate = (state, path, metaKey, value, options = {}) => {
  if (riskUtils.searchPath.array.isArrayAction(path)) return;

  Object.entries({ state, path, metaKey }).forEach(([k, v]) => {
    if (!v) {
      fnOutputErrorInfo({ path, metaKey, value });
      throw `Error in fnUpdate -- missing "${k}"`;
    }
  });

  if (!path) {
    fnOutputErrorInfo({ state, path, metaKey, value });
    throw `error in fnUpdate -- missing path`;
  }

  if (path.includes("[]")) {
    fnOutputErrorInfo({ path, metaKey, value });
    throw `Error in fnUpdate -- found path with [] "${path}"`;
  }

  // console.log(`processor.fnUpdate("${path}")`, { value, metaKey });

  const obj = riskUtils.find.itemAndCreate(state, path, {
    ...options,
    // showDebug: path === "Updating Risk/Vehicle/Modifications",
  });

  if (!riskUtils.is.dataNode(obj)) {
    fnOutputErrorInfo({ path, metaKey, value });
    throw `Error in fnUpdate -- can't find a dataNode at "${path}"`;
  }

  obj[metaKey] = value;
  return obj;
};

export const fnUpdateAddSubKey = (state, path, metaKey, metaSubKey, value) => {
  if (!path) {
    fnOutputErrorInfo({ state, path, metaKey, metaSubKey, value });
    throw `error in fnUpdateAddSubKey -- missing path`;
  }

  // NOTE: We don't want to create an object if the key doesn't exist
  const foundObject = riskUtils.find.item(state, path);

  if (foundObject) {
    foundObject[metaKey] = { ...foundObject[metaKey], [metaSubKey]: value };
  }
};

export const fnUpdateRemoveSubKey = (state, path, metaKey, metaSubKey) => {
  if (!path) {
    fnOutputErrorInfo({ state, path, metaKey, metaSubKey });
    throw `error in fnUpdateRemoveSubKey -- missing path`;
  }

  // NOTE: We don't want to create an object if the key doesn't exist
  const foundObject = riskUtils.find.item(state, path);

  if (foundObject) {
    foundObject[metaKey] = _.omit(foundObject[metaKey], [metaSubKey]);
  }
};

//
