import merge from "deepmerge";
import axios from "axios";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import _ from "lodash";
import nodecache from "node-cache";
const serviceDataCache = new nodecache();

export const createService = (obj) => {
  const {
    axiosOptions,
    postProcessAxios = [],
    debounce = false,
    debounceOptions = {},
    requiredPayload = [],
    description = "",
    cacheKey,
    fnModifyUrl = (baseUrl, payload) => baseUrl,
  } = obj;

  const { delay: debounceOptionsDelay = 500, ...debounceOptionsOther } =
    debounceOptions;
  // **************************
  // support function
  // **************************
  const fnThrowError = (msg) => {
    throw [
      "Error",
      description && `(${description})`, // Optional
      "--",
      msg,
    ].join(" ");
  };
  // **************************
  // Final function
  // **************************
  const retFn = (payload, options = {}) => {
    // axiosOptions.method = get
    // axiosOptions.url = axiosOptions.url + "/" + payload
    // if (cacheKey) console.log("ddd", obj, serviceDataCache.has(cacheKey));

    if (cacheKey && serviceDataCache.has(cacheKey)) {
      return serviceDataCache.get(cacheKey);
    }

    const { headers: _headers } = options;

    const mergeHitlist = [
      { timeout: 30000 },
      axiosOptions,
      // payload ? { data: payload } : "",
      _headers ? { headers: _headers } : "",
    ].filter((x) => x);

    const axiosPostData = merge.all(mergeHitlist);

    if (payload) {
      axiosPostData.data = {
        ...(axiosOptions.data || {}),
        ...payload,
        // test: { test2: [1, 2, 3, 4] },
      };
    }

    if (axiosPostData.method.toLowerCase() === "get" && payload) {
      axiosPostData.url += `/${payload}`;
    }

    axiosPostData.url = fnModifyUrl(axiosPostData.url, payload);

    requiredPayload.forEach((key) => {
      if (!(key in axiosPostData.data))
        fnThrowError(`missing payload item "${key}"`);
    });

    return axios(axiosPostData)
      .then((res) => res.data)
      .then((res) => postProcessAxios.reduce((_v, curFn) => curFn(_v), res))
      .then((res) => {
        if (cacheKey && !serviceDataCache.has(cacheKey)) {
          serviceDataCache.set(cacheKey, res);
        }

        return res;
      });
  };

  if (!debounce) return retFn;

  return AwesomeDebouncePromise(
    retFn,
    debounceOptionsDelay,
    debounceOptionsOther
  );
};
