import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import ButtonLabel from "@material-ui/icons/Search";
import { useSelector } from "react-redux";

export default (args = {}) => {
  const {
    store,
    database,
    selectors,
    actions,
    siteComponents,
    storeComponentsCustom,
    storeComponentsSite,
    classes,
  } = args;
  const { FormGroup } = storeComponentsCustom;
  const { TextBox, TextBoxPostcode } = storeComponentsSite;

  //** MAIN component
  return (props) => {
    [TextBox, TextBoxPostcode].forEach((x, i) => {
      if (!x)
        throw `Error -- missing required component in storeComponentsSite in Address Edit at index ${i}`;
    });

    const {
      DBitems,
      onSearch,
      houseNumberMaxLength = 30,
      address1MaxLength = 32,
      address2MaxLength = 32,
      address3MaxLength = 32,
      address4MaxLength = 32,
      postcodeMaxLength = 8,
      forceShowErrors = false,
    } = props;

    const getValue = (state, path) =>
      store.selectors.userData.risk.metaData(state, path)?.value;

    const riskPostcode = useSelector((state) =>
      getValue(state, DBitems.postcode.path)
    );

    // console.log("dddd", DBitems.postcode)
    return (
      <>
        <FormGroup
          databaseProps={DBitems.HouseNameOrNumber}
          forceShowErrors={forceShowErrors}
          isStoreComponent={true}
        >
          <div className="col-md-6">
            <TextBox
              databaseProps={DBitems.HouseNameOrNumber}
              maxLength={houseNumberMaxLength}
            />
          </div>
        </FormGroup>

        <FormGroup
          databaseProps={DBitems.address1}
          forceShowErrors={forceShowErrors}
          isStoreComponent={true}
        >
          <div className="col-md-6">
            <TextBox
              databaseProps={DBitems.address1}
              maxLength={address1MaxLength}
            />
          </div>
        </FormGroup>
        <FormGroup
          databaseProps={DBitems.address2}
          forceShowErrors={forceShowErrors}
          isStoreComponent={true}
        >
          <div className="col-md-6">
            <TextBox
              databaseProps={DBitems.address2}
              maxLength={address2MaxLength}
            />
          </div>
        </FormGroup>
        <FormGroup
          databaseProps={DBitems.address3}
          forceShowErrors={forceShowErrors}
          isStoreComponent={true}
        >
          <div className="col-md-6">
            <TextBox
              databaseProps={DBitems.address3}
              maxLength={address3MaxLength}
            />
          </div>
        </FormGroup>
        <FormGroup
          databaseProps={DBitems.address4}
          forceShowErrors={forceShowErrors}
          isStoreComponent={true}
        >
          <div className="col-md-6">
            <TextBox
              databaseProps={DBitems.address4}
              maxLength={address4MaxLength}
            />
          </div>
        </FormGroup>

        <FormGroup
          databaseProps={DBitems.postcode}
          forceShowErrors={forceShowErrors}
          isStoreComponent={true}
        >
          <div className="col-md-6">
            {riskPostcode}
            {/* <TextBoxPostcode
							databaseProps={DBitems.postcode}
							maxLength={postcodeMaxLength}
						/> */}
          </div>
        </FormGroup>

        {/* {onSearch && (
					<FormGroup>
						<div className="col-md-6">
							<Button
								type="button"
								className="btn-primary"
								label={<span>Search again</span>}
								onClick={onSearch}
							/>
						</div>
					</FormGroup>
				)} */}
      </>
    );
  };
};
