// RegisterScreen.js
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";
import { useLookupOptionList } from "moveToLibrary/hooks/useLookup";
import ButtonPanel from "../ButtonPanel";
import {
  useLocation,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

import Base from "../Base";
import _ from "lodash";
import { components } from "siteStore";
import services from "siteService";
import config from "config";
// const { SessionSavingChecker } = components.session;

import {
  PanelForm,
  Checkbox,
  VehicleReg,
  Help,
  DisplaySection,
} from "siteComponents";

import dayjs from "dayjs";

const fnFormatDate = (d) => {
  if (!d) return "";
  return dayjs(d).format("DD/MM/YYYY");
};
// ********************************************************
// SELECTIONITEM COMPONENT
// ********************************************************
const SelectionItem = (props) => {
  const {
    heading,
    icon,
    subheading,
    data,
    fnPagerAdd,
    fnPagerRemove,
    checked,
    disabled,
  } = props;

  // console.log("dddd props", heading, props);
  // if (!("checked" in props)) return null;
  // console.log("dddd props", heading, props);
  // const [checked, setChecked] = useState(false);

  const _className = [
    "mta-start-section",
    disabled ? "disabled" : "",
  ].toClassName();

  return (
    <DisplaySection
      dataCy={props["data-cy"] && `mta-start-section-${props["data-cy"]}`}
      className={_className}
      heading={
        <div className="d-flex">
          {icon && (
            <div>
              <i className={["icon", `icon-${icon}`].toClassName()} />
            </div>
          )}
          <div
            className="heading-text my-auto flex-grow-1"
            data-cy={props["data-cy"] && `mta-type-heading-${props["data-cy"]}`}
          >
            {heading}
          </div>
        </div>
      }
      subheading={
        <div className="d-flex">
          <div
            className="heading-text flex-grow-1"
            data-cy={
              props["data-cy"] && `mta-type-subheading-${props["data-cy"]}`
            }
          >
            {subheading}
          </div>
          <div className="section-select">
            <Checkbox
              checked={checked}
              onSelect={() => fnPagerAdd()}
              onUnselect={() => fnPagerRemove()}
              disabled={disabled}
              data-cy={
                props["data-cy"] && ["mta-checkbox-", props["data-cy"]].join("")
              }
            />
          </div>
        </div>
      }
      data={data}
    />
  );
};

// ********************************************************
// Pager props Hook
// ********************************************************
const usePagerProps = (setPagerHitlist, pagerHitlist, args = {}) => {
  ["pagerKey", "order"].forEach((k) => {
    if (!(k in args)) {
      throw `Error in useSectionItemData -- missing "${k}"`;
    }
  });

  const { pagerKey, order, pagerKeyDisable } = args;
  const [checked, setChecked] = useState(false);

  const fnPagerAdd = (pagerKey, order) => {
    setPagerHitlist((oldList) =>
      [
        // Existing item
        ...oldList.filter((x) => x.pagerKey !== pagerKey),
        // The new item
        { pagerKey, order, pagerKeyDisable },
      ].sort((x, y) => x.order - y.order)
    );
  };

  const fnPagerRemove = (pagerKey) => {
    setPagerHitlist((oldList) =>
      oldList.filter((x) => x.pagerKey !== pagerKey)
    );
  };

  const disabled = pagerHitlist.some(
    (x) => x.pagerKeyDisable && x.pagerKeyDisable.includes(pagerKey)
  );

  return {
    disabled: disabled,
    checked: checked,
    fnPagerAdd: useCallback(async () => {
      fnPagerAdd(pagerKey, order);
      setChecked(true);
    }, []),
    fnPagerRemove: useCallback(async () => {
      fnPagerRemove(pagerKey);
      setChecked(false);
    }, []),
  };
};

// ********************************************************
// MAIN COMPONENT
// ********************************************************

const MTAStartView = (props) => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { functions, selectors, isInit } = useOutletContext();

  // const status_Start = useSelector(selectors.status.start);
  // const status_getSavedQuote = useSelector(selectors.status.getSavedQuote);
  // const isSessionSaving = useSelector(selectors.session.getIsSaving);

  const riskValues = useSelector(
    (state) =>
      selectors.riskStore.getValue(state, [
        ...["RegNumber", "Manufacturer", "ModelDescription"].map(
          (x) => `Risk/Vehicle/${x}`
        ),
        ...[
          "HouseNameOrNumber",
          "Postcode",
          "AddressLine1",
          "AddressLine2",
          "AddressLine3",
          "AddressLine4",
        ].map((x) => `Risk/Proposer/Address/${x}`),
        "Risk/TotalMileage",
        "Risk/BusinessMileage",
        "Risk/ClassOfUseFrontEnd",
        "Risk/AdditionalInsuredSet",
      ]),
    require("lodash").isEqual
  );

  //console.log("MTA Start - Risk values: ", riskValues);

  const lookupClassOfUse = useLookupOptionList(
    services.lookups.vehicle.car.classofuseStellaDisplay
  );

  if (false) {
    const riskSets = useSelector((state) =>
      selectors.riskStore.getValueSet(state, [
        { parentKey: "Risk/Duqs", tags: ["Id", "QuestionNumber"] },
      ])
    );
    const riskStoreData = useSelector((state) => state?.mtaRiskCar);

    console.log("Start.jsx", { riskStoreData, riskValues, riskSets });
  }
  // *********************************************************
  // PAGER HITLIST
  // *********************************************************

  const [pagerHitlist, setPagerHitlist] = useState([
    { pagerKey: "effectiveDate", order: 0 },
  ]);

  const pagerHitlistKeys = (function () {
    const disabledPagerKeys = pagerHitlist
      .flatMap((x) => x.pagerKeyDisable)
      .filter(Boolean);

    const finalHitlist = pagerHitlist
      .map((x) => x.pagerKey)
      .filter((k) => !disabledPagerKeys.includes(k));

    return finalHitlist;
  })();

  const isStartEnabled = pagerHitlistKeys.length >= 2; // As effectiveDate is compulsory

  // *********************************************************
  // FUNCTIONS
  // *********************************************************

  const fnGoToPrev = async () => {
    await functions.navigation.exit();
  };

  const fnGoToNext = async () => {
    if (!isStartEnabled) return;
    await functions.process.setPagerData(pagerHitlistKeys);
    await functions.sessionSave();
    await functions.navigation.changeFromStart();
  };

  // *********************************************************
  // EFFECT
  // *********************************************************

  //** INIT **
  useEffect(() => {
    const myFn = async () => {
      if (!isInit) return;

      await functions.process.restart();

      // await functions.process.resetPagerData(); // This will clear out the policyNumber
      // await functions.process.resetQuote();
      // await functions.fnGetSavedQuotes();
    };

    myFn();
  }, []);

  // *********************************************************
  // LOADING VARS
  // *********************************************************
  const [isLoading, isLoadingMessage] = (function () {
    // if (isSessionSaving) return [true, "We're getting your details..."];

    // if (!isInit || status_Start.isInitiating)
    //   return [true, "We're getting your details..."];

    // if (status_getSavedQuote.isInitiating)
    //   return [true, "We're getting your saved quotes..."];

    if (isRedirecting)
      return [
        true,
        undefined, // "Please wait while we redirect to the payment page..."
      ];

    return [false, undefined];
  })();

  // TODO: Hook up when previous quotes are available
  const hasPreviousQuotes = false;

  const totalMileage = riskValues[`Risk/TotalMileage`];
  const businessMileage = riskValues[`Risk/BusinessMileage`];

  // *********************************************************
  // RETURN
  // *********************************************************

  return (
    <Base
      className="mta-start"
      isLoading={isLoading}
      isLoadingMessage={isLoadingMessage}
      headingText={isInit && "Make a change to my policy"}
      descriptionContent={
        isInit &&
        (hasPreviousQuotes ? (
          "If you've done a previous quote you can pick up where you left off, or start a new change below."
        ) : (
          <span>&nbsp;</span>
        ))
      }
      isStart={true}
      data-cy="layout-mta-start"
      routeExit="/"
    >
      <PanelForm
        heading={
          <>
            <h2 data-cy="panel-mta-type-select-heading">
              What would you like to change?
            </h2>
            <div className="small" data-cy="panel-mta-type-select-subheading">
              Select from the items below and click 'Next' to get started.
            </div>
          </>
        }
        data-cy="panel-mta-type-select"
      >
        {(function () {
          const props = usePagerProps(setPagerHitlist, pagerHitlist, {
            pagerKey: "vehicleAbi",
            order: 1,
          });
          return (
            <SelectionItem
              pagerKey="vehicleAbi"
              order={1}
              data-cy="vehicle"
              icon="policytype-car2"
              heading="Your car"
              subheading="Change your car"
              data={[
                {
                  dataCy: "current-car",
                  label: "Current car",
                  value: (
                    <>
                      <div className="" data-cy="vehicle-regno">
                        <VehicleReg>
                          {riskValues["Risk/Vehicle/RegNumber"]}
                        </VehicleReg>
                      </div>
                      <div className="my-auto" data-cy="vehicle-make-model">
                        <strong>
                          {riskValues["Risk/Vehicle/Manufacturer"]}{" "}
                          {riskValues["Risk/Vehicle/ModelDescription"]}
                        </strong>
                      </div>
                    </>
                  ),
                  valueClass: "vehicle-details",
                  isHzntlMobile: false,
                },
                props.checked && {
                  value: (
                    <Help data-cy="vehicle-confirm">
                      <div>
                        By proceeding, you confirm that you have read and agree
                        to the following;
                      </div>
                      <div className="confirm-title">
                        <strong>Your car</strong>;{" "}
                      </div>
                      <ul className="">
                        <li>
                          has not been modified (other than for disabilities,
                          such as wheelchair ramps and lifts, altered foot
                          pedals, hand controls and steering aids).
                        </li>
                        <li>is standard right hand drive.</li>
                      </ul>
                    </Help>
                  ),
                  isHorizontalFormat: false,
                  isHzntlMobile: false,
                },
              ]}
              {...props}
            />
          );
        })()}

        {(function () {
          const props = usePagerProps(setPagerHitlist, pagerHitlist, {
            pagerKey: "address",
            order: 2,
            pagerKeyDisable: ["vehicleUsageAndMileage"],
          });
          return (
            <SelectionItem
              data-cy="address"
              icon="house"
              heading="Your address"
              subheading="Change your address"
              data={[
                {
                  dataCy: "current-address",
                  label: "Current address",
                  value: (
                    <strong>
                      {[
                        [
                          riskValues[`Risk/Proposer/Address/HouseNameOrNumber`],
                          riskValues[`Risk/Proposer/Address/AddressLine1`],
                        ]
                          .filter(Boolean)
                          .join(" "),

                        riskValues[`Risk/Proposer/Address/AddressLine2`],
                        riskValues[`Risk/Proposer/Address/AddressLine3`],
                        riskValues[`Risk/Proposer/Address/AddressLine4`],
                        riskValues[`Risk/Proposer/Address/Postcode`],
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </strong>
                  ),
                  isHzntlMobile: false,
                },
                props.checked && {
                  value: (
                    <Help data-cy="address-confirm">
                      <div>
                        You can also update where your car is kept, your usage
                        and mileage.
                      </div>
                    </Help>
                  ),
                  isHorizontalFormat: false,
                  isHzntlMobile: false,
                },
              ]}
              {...props}
            />
          );
        })()}

        {(function () {
          const props = usePagerProps(setPagerHitlist, pagerHitlist, {
            pagerKey: "additionalDrivers",
            order: 4,
          });

          const listData = riskValues["Risk/AdditionalInsuredSet"] || [];
          const hasListData = listData.length >= 1;

          return (
            <SelectionItem
              pagerKey="vehicleAbi"
              order={1}
              data-cy="additional-drivers"
              icon="drivers1"
              heading="Your additional drivers"
              subheading="Add or remove drivers"
              data={[
                {
                  dataCy: "current-drivers",
                  label: "Current drivers",
                  value: (
                    <>
                      {!hasListData && (
                        <div className="" data-cy="no-drivers">
                          <strong>
                            You have no additional drivers on your policy
                          </strong>
                        </div>
                      )}

                      {hasListData &&
                        listData.map((data, i) => (
                          <div className="" key={i} data-cy={`driver-${i + 1}`}>
                            {/* {fnFormatDate(data.DateOfBirth)} */}
                            <strong>
                              {[
                                data.Name.Title,
                                data.Name.Forenames,
                                data.Name.Surname,
                              ]
                                .filter(Boolean)
                                .join(" ")}
                            </strong>
                          </div>
                        ))}
                    </>
                  ),
                  isHzntlMobile: false,
                },
                props.checked && {
                  value: (
                    <Help data-cy="driver-confirm" className="">
                      <div>
                        By proceeding, you confirm that you have read and agree
                        to the following;
                      </div>
                      <div className="confirm-title">
                        <strong>All drivers</strong>;{" "}
                      </div>
                      <ul className="">
                        <li>
                          hold a full UK driving licence (for manual or
                          automatic cars).
                        </li>
                        <li>
                          have advised the DVLA of any notifiable medical
                          conditions and they have approved the issue of a
                          licence.
                        </li>
                        <li>
                          have not been advised by a doctor that they shouldn't
                          drive.
                        </li>
                      </ul>
                    </Help>
                  ),
                  isHorizontalFormat: false,
                  isHzntlMobile: false,
                },
              ]}
              {...props}
            />
          );
        })()}

        <SelectionItem
          {...usePagerProps(setPagerHitlist, pagerHitlist, {
            pagerKey: "vehicleUsageAndMileage",
            order: 5,
          })}
          data-cy="usage"
          icon="mileage"
          heading="Your car usage and mileage"
          subheading="Change your car usage or mileage"
          data={[
            {
              dataCy: "current-usage",
              label: "Current usage",
              value: (
                <strong>
                  {lookupClassOfUse(riskValues["Risk/ClassOfUseFrontEnd"])}
                </strong>
              ),
              isHzntlMobile: false,
            },
            {
              dataCy: "current-mileage",
              label: "Current mileage",
              value: (
                <>
                  <div data-cy="current-totalmiles">
                    {totalMileage && businessMileage && (
                      <strong>
                        {parseInt(totalMileage).toLocaleString()} total miles
                      </strong>
                    )}
                    {totalMileage && !businessMileage && (
                      <strong>
                        {parseInt(totalMileage).toLocaleString()} miles
                      </strong>
                    )}
                  </div>
                  {businessMileage && (
                    <div data-cy="current-businessmiles">
                      <strong>
                        {parseInt(businessMileage).toLocaleString()} business
                        miles
                      </strong>
                    </div>
                  )}
                </>
              ),
              isHzntlMobile: false,
            },
          ]}
        />
      </PanelForm>

      <ButtonPanel
        onPrev={fnGoToPrev}
        prevLabel="Back"
        onNext={fnGoToNext}
        nextLabel="Next"
        disabledNext={!isStartEnabled}
        className="mb-3"
      />

      {/* <MtaSavedQuotes
        onSelectQuote={async (quoteId) => {
          setIsRedirecting(true);
          const isOkay = await functions.process.paymentLauncher({ quoteId });

          if (!isOkay) setIsRedirecting(false);
        }}
      /> */}
    </Base>
  );
};

export default MTAStartView;
