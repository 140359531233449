import React from "react";


import CardAnnual from "./Cards/Annual";
import CardMonthly from "./Cards/Monthly";

const PriceCardStaticDual = (props) => {
  const { RenewalResult, isSticky = false, selectedPayMethod } = props;

  const isSelectedMonthly = selectedPayMethod === "M";
  const { AllowPayInFull, AllowPayMonthly } = RenewalResult;

  if (AllowPayMonthly && AllowPayInFull)
    return (
      <div className="row">
        <div className="col-12 col-md-6">
          <CardMonthly data={RenewalResult} />
        </div>
        <div className="col-12 d-md-none"><strong className="fs-5">OR</strong></div>
        <div className="col-12 col-md-6">
          <CardAnnual data={RenewalResult} />
        </div>
      </div>
    );

  if (AllowPayInFull)
  return (
    <div className="row">
      <div className="col-12">
        <CardAnnual data={RenewalResult} />
      </div>
    </div>
  );

  if (AllowPayMonthly)
  return (
    <div className="row">
      <div className="col-12">
        <CardMonthly data={RenewalResult} />
      </div>
    </div>
  );
 
};

const PriceCardStickyFull = (props) => {
  const { RenewalResult, isSticky = false } = props;

  return <CardAnnual data={RenewalResult} />;

};

const PriceCardStickyMonthly = (props) => {
  const { RenewalResult, isSticky = false } = props;

  return <CardMonthly data={RenewalResult} />;
  
};

const PriceContainer = (props) => {
  const { dataCy, children, isStickyAnimationEnabled } = props;

  return (
    <div className="renewal-price" data-cy={dataCy}>
      {children && (
        <div className="row">
          <div
            className={[
              "col-12",
              "renewal-price-container",
              isStickyAnimationEnabled && "is-selected renewal-animated",
            ].toClassName()}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

const PriceDetails = (props) => {
  const {
    RenewalResult,
    isSticky = false,
    selectedPayMethod,
    isStickyAnimationEnabled,
  } = props;

  const { AllowPayInFull, AllowPayMonthly } = RenewalResult;
  const isSelectedMonthly = selectedPayMethod === "M";

  if (!isSticky)
    return (
      <PriceContainer
        dataCy="renewal-price-dual"
        isStickyAnimationEnabled={isStickyAnimationEnabled}
      >
        <PriceCardStaticDual
          RenewalResult={RenewalResult}
          selectedPayMethod={selectedPayMethod}
        />
      </PriceContainer>
    );

  if (AllowPayMonthly && isSelectedMonthly)
    return (
      <PriceContainer
        dataCy="renewal-price-sticky-monthly"
        isStickyAnimationEnabled={isStickyAnimationEnabled}
      >
        <PriceCardStickyMonthly
          RenewalResult={RenewalResult}
          selectedPayMethod={selectedPayMethod}
        />
      </PriceContainer>
    );

  if (!isSelectedMonthly)
    return (
      <PriceContainer
        dataCy="renewal-price-sticky-annual"
        isStickyAnimationEnabled={isStickyAnimationEnabled}
      >
        <PriceCardStickyFull
          RenewalResult={RenewalResult}
          selectedPayMethod={selectedPayMethod}
        />
      </PriceContainer>
    );

  // Should never reach this part
  return undefined;
};

export default PriceDetails;
