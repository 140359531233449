import {
  selectors as selectorsSite,
  mtaCar,
  quoteAndBuyCar, // #QUOTEANDBUY
  actions,
  snapshotMtaCar,
} from "siteStore";
import _ from "lodash";
// "services", "riskStore", "database", "getProps

export default (riskConfigArgs = {}) => {
  const retData = {
    storeType: "quoteAndBuy", // "quoteAndBuy" or "legacy"
    services: require("../../../services").default,
    database: {},
    riskStore: {
      storeName: riskConfigArgs.store.storeName,
      actions: {
        ...riskConfigArgs.store.actions,
        loadRisk: (salusData) => {
          return riskConfigArgs.store.actions.salusLoad({
            salusData: salusData,
          });
        },
      },
      selectors: riskConfigArgs.store.selectors,
      components: require("./components").default({
        store: riskConfigArgs.store,
      }),
    },

    getProps: (path, args = {}) => {
      // Gets the props for the specified path. Will return values with the original path
      // e.g. Risk/Array[id1] returns all items with a path begining with Risk/Array[id1]

      const { group } = args;
      if (!path) {
        console.log("ERROR INFO:", { args });
        throw `Error in riskConfig.getProps -- missing path`;
      }

      const fnGenSubProp = (path, node) => {
        if (!node)
          throw `Error in riskConfig getprops fnGenSubProp -- missing node `;
        return {
          path: path,
          ...((group && { group: group }) || {}),
          defaultValue: node.defaultValue,
        };
      };

      const pathWithoutIdx =
        riskConfigArgs.store.helpers.searchPath.array.removeIndex(path);

      // console.log("getProps", path, { pathClean: pathWithoutIdx });

      const template = riskConfigArgs.store.template;

      const _fnGetTemplateData = (obj, searchPath = [], level = 1) => {
        const [pathFirst, ...pathRest] = searchPath;

        if (searchPath.length === 0) {
          if (!obj) return undefined;
          // Else, return obj, but with all the values turned to fnGenSubProp()

          const _fnUpdate = (subObj, foundPath = []) => {
            if (_.isObject(subObj)) {
              if (subObj._isTemplateDataNode === true) {
                // console.log("ddddd", [path, ...foundPath].join("/"), subObj);

                return fnGenSubProp([path, ...foundPath].join("/"), subObj); // return a path with any indexes
              }
              return Object.fromEntries(
                Object.entries(subObj).map(([k, d]) => {
                  if (k.endsWith("[]"))
                    return [
                      k,
                      fnGenSubProp(
                        [path, ...foundPath, k.replace("[]", "")].join("/"),
                        d
                      ),
                    ];

                  return [k, _fnUpdate(d, [...foundPath, k])];
                })
              );
            }

            throw `Error in getProps._fnGetTemplateData._fnUpdate -- not an object`;
          };

          return _fnUpdate(obj, []);
        }
        if (!(pathFirst in obj)) {
          throw `Error in riskConfig.getProps -- can't find "${pathFirst}" in template (cleaned:"${pathWithoutIdx}", original:"${path}")`;
        }
        return _fnGetTemplateData(obj[pathFirst], pathRest, level + 1);
      };

      const _foundItem = _fnGetTemplateData(
        template,
        pathWithoutIdx.split("/")
      );

      // console.log("getProps", path, _foundItem, {
      //   pathClean: pathWithoutIdx,
      //   template,
      // });

      return _foundItem;

      // const subPathList = Object.keys(_foundItem);

      // const retData = Object.fromEntries(
      //   subPathList.map((subPath) => [
      //     subPath,
      //     fnGenSubProp([pathWithoutIdx, subPath].join("/")),
      //   ])
      // );

      // console.log("quoteandbuy getProps:", path, {
      //   pathClean: pathWithoutIdx,
      //   subPathList,
      //   retData,
      //   _foundItem,
      //   template,
      // });

      // return retData;
    },
  };

  return retData;
};
