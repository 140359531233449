import React, { useRef, useEffect } from "react";
import { Card } from "../Cards";

const Modal = (props) => {
  const { header, children, dataCy = "modal", footer } = props;

  return (
    <div>
      <div className="modal-faded-background" />
      <div className="modal-container">
        <div className="modal-main container">
          <Card
            data-cy={dataCy}
            header={header}
            footer={footer}
            className={props.className}
            classNameBody={props.classNameBody}
            classNameHeader={props.classNameHeader}
            classNameFooter={props.classNameFooter}
          >
            {children}
          </Card>
        </div>
      </div>
    </div>
  );
};

const ModalWrapper = (props) => {
  const { enabled = false, ...otherProps } = props;

  if (!enabled) return null;

  return <Modal {...otherProps} />;
};
export default ModalWrapper;
