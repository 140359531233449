import React, { useEffect } from "react";

const PanelHeader = (props) => {

    const { headerText, policyIdentifier, iconClass } = props;

    const _textClass = ["d-block", "d-md-inline-flex", policyIdentifier ? "pb-3 pb-md-0" : ""].toClassName();


    return (
        <div
            className={[
                "d-flex",
                "flex-column",
                "flex-wrap",
                "justify-content-start",
                "align-items-start",

                "flex-md-row",
                "flex-md-nowrap",
                "justify-content-md-between",
                "align-items-md-center",
            ].toClassName()}
        >
            <div className={_textClass} >
                <h2>
                    {iconClass && (
                        <span className={["icon", iconClass].toClassName()}></span>
                    )}
                    {headerText}
                </h2>
            </div>
            {policyIdentifier && (
                <div className="d-flex w-100 justify-content-between w-md-auto">
                    {policyIdentifier}
                </div>
            )}
            
        </div>
    );
}

export default PanelHeader;
