import _ from "lodash";

const generate = (args = {}) => {
  const { storeName } = args;

  const fnGetSubState = (state) => state[storeName];

  const getDocumentsByPolicyId = (state, policyId) => {
    if (!policyId) return;
    const subState = fnGetSubState(state);
    if (!subState) return;
    if (!subState.documents) return;
    if (!(policyId in subState.documents)) return;
    return subState.documents[policyId];
    // return subState.documents.filter((x) => x.PolicyNumber === policyNumber);
  };

  const getDocumentsHasNewByPolicyId = (state, policyId) => {
    if (getIsRefreshing(state)) return false;

    const documents = getDocumentsByPolicyId(state, policyId);

    if (!documents) return false;
    if (documents.length === 0) return false;
    return documents.some((x) => x.IsViewed === false);
  };

  const getFeedbackErrorMsg = (state) => {
    const subState = fnGetSubState(state);
    if (!subState) return;
    return subState.feedbackErrorMsg;
  };

  const getIsRefreshing = (state) => {
    const subState = fnGetSubState(state);
    if (!subState) return;
    return subState.isRefreshing;
  };

  const getIsRehydrating = (state) => {
    const subState = fnGetSubState(state);
    if (!subState) return;
    return subState.isRehydrating;
  };

  const getError = (state, key) => {
    const subState = fnGetSubState(state);

    if (!subState) return;
    if (!subState.errors) return;

    return subState.errors[key];
  };

  const getUploadFileStatus = (state) => {
    const subState = fnGetSubState(state);

    if (!subState) return;

    return subState.uploadFileStatus;
  };

  // const getDownloadFileStatus = (state) => {
  //   const subState = fnGetSubState(state);

  //   if (!subState) return;

  //   return subState.downloadStatus;
  // };

  // const getDownloadFileProcessing = (state) => {
  //   const downloadData = getDownloadFileStatus(state);
  //   if (!downloadData) return;

  //   return Object.fromEntries(
  //     Object.entries(downloadData).filter(([key, value]) =>
  //       ["WAITING", "DOWNLOADING"].includes(value)
  //     )
  //   );
  // };

  return {
    getDocumentsByPolicyId,
    getDocumentsHasNewByPolicyId,
    getIsRefreshing,
    getIsRehydrating,
    getError,
    getFeedbackErrorMsg,
    getUploadFileStatus,
    // getDownloadFileStatus,
    // getDownloadFileProcessing,
  };
};

export default generate;
