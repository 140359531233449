import _ from "lodash";

const applyCommonListeners = (
  listenerMiddleware,
  stores = {},
  options = {}
) => {
  ///
  const { auth, documents, dashboard, session, storage } = stores;
  const {
    debug = false,
    consoleLog = (...args) => console.log(...args),
    onLogout = async () => {},
  } = options;

  // *********************************************************
  // DEBUG
  // *********************************************************
  if (false) {
    listenerMiddleware.startListening({
      predicate: (action, currState, prevState) => {
        return true;
      },
      effect: async (action, listenerApi) => {
        console.log("action", action);
        // consoleLog("Previous state ", listenerApi.getOriginalState());
        // consoleLog("Current state ", listenerApi.getState());
      },
    });
  }

  // *********************************************************
  // Logging in
  // *********************************************************
  if (auth) {
    listenerMiddleware.startListening({
      predicate: (action) => {
        return [
          `${auth.storeName}/authenticate/fulfilled`,
          `${auth.storeName}/activate/fulfilled`,
          `${auth.storeName}/link/fulfilled`,
        ].includes(action.type);
      },
      effect: async (action, listenerApi) => {
        const { dispatch } = listenerApi;

        if (!action.payload) return;
        const userInfo = action.payload.userInfo;

        if (!userInfo) return;

        // IMPORT USERINFO
        if (dashboard) {
          await dispatch(dashboard.actions.importUserInfo(userInfo));
        }

        // IMPORT DOCUMENTS
        if (documents) {
          if (!userInfo.Policies) return;
          if (userInfo.Policies.length === 0) return;

          const _documents = Object.fromEntries(
            userInfo.Policies.map((x) => [x.PolicyId, x.Documents])
          );

          await dispatch(documents.actions.importDocuments(_documents));
        }

        // SESSION SAVE
        if (session) {
          await dispatch(session.actions.save());
        }
      },
    });
  }

  // *********************************************************
  // Logging out
  // *********************************************************
  if (auth) {
    listenerMiddleware.startListening({
      predicate: (action) => [`${auth.storeName}/logout`].includes(action.type),

      effect: async (action, listenerApi) => {
        consoleLog("Logging out");
        const { dispatch } = listenerApi;

        await onLogout(dispatch);
      },
    });
  }

  // *********************************************************
  // DOCUMENTS refresh
  // *********************************************************
  if (documents) {
    listenerMiddleware.startListening({
      predicate: (action) =>
        [
          `${documents.storeName}/rehydratePolicyDocuments/fulfilled`,
          `${documents.storeName}/getPolicyDocuments/fulfilled`,
        ].includes(action.type),
      effect: async (action, listenerApi) => {
        // SESSION SAVE
        if (session) await listenerApi.dispatch(session.actions.save());

        // DO a notification refresh
        if (dashboard)
          await listenerApi.dispatch(dashboard.actions.refreshUserInfo());
      },
    });
  }

  // *********************************************************
  // DASHBOARD updateRemainingUploads
  // *********************************************************
  if (dashboard) {
    listenerMiddleware.startListening({
      predicate: (action) =>
        [`${dashboard.storeName}/updateRemainingUploads`].includes(action.type),
      effect: async (action, listenerApi) => {
        // SESSION SAVE
        if (session) await listenerApi.dispatch(session.actions.save());
      },
    });
  }

  // *********************************************************
  // DASHBOARD refreshUserInfo
  // *********************************************************
  if (dashboard) {
    listenerMiddleware.startListening({
      predicate: (action) =>
        [`${dashboard.storeName}/refreshUserInfo/fulfilled`].includes(
          action.type
        ),
      effect: async (action, listenerApi) => {
        // SESSION SAVE
        if (session) await listenerApi.dispatch(session.actions.save());
      },
    });
  }
};

export default applyCommonListeners;
