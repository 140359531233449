import generateSessionSlice from "./sessionSlice";
import generateSessionActions from "./sessionActions";
import generateSessionSelectors from "./sessionSelectors";
import generateSessionComponents from "./sessionComponents";
import generateSessionEnhancer from "./sessionEnhancer";

const fnThrowError = (msg) => {
  throw `Error in create session store: ${msg}`;
};

const generateStore = (args = {}) => {
  const { storeName, services = {}, excludeKeys = [] } = args;

  if (!storeName) fnThrowError("missing storeName");

  ["init", "get", "save", "delete"].forEach((k) => {
    if (!(k in services)) fnThrowError(`missing "services.${k}"`);
  });

  const sessionSelectors = generateSessionSelectors(args);
  const sessionActions = generateSessionActions({ ...args, sessionSelectors });
  const sessionSlice = generateSessionSlice({ ...args, sessionActions });
  const sessionComponents = generateSessionComponents({
    ...args,
    sessionActions,
    sessionSelectors,
  });
  const sessionEnhancer = generateSessionEnhancer({ ...args, sessionActions });

  const retData = {
    storeName,
    reducer: sessionSlice.reducer,
    actions: { ...sessionActions, ...sessionSlice.actions },
    selectors: sessionSelectors,
    components: sessionComponents,
    enhancer: sessionEnhancer,
  };

  console.log("STORE", "session", storeName, "=", retData);
  return retData;
};

export default generateStore;
