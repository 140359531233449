import _ from "lodash";

const generate = (args = {}) => {
  const {
    storeName,
    riskStoreConfig,
    quotePayloadModifier = (payloadArgs = {}) => {
      const { baseSalusData, newSalusData } = payloadArgs;
      return newSalusData;
    },
  } = args;

  // *****************************************************************
  // IMPORTANT SELECTORS
  // *****************************************************************
  const getUserInfoSalus = (state) => {
    const subState = fnGetSubState(state);
    return subState.dataInit.base.salus;
  };

  const getBaseSalus = (state) => {
    const subState = fnGetSubState(state);
    return subState.dataChange.base.salus;
  };

  const getBaseRisk = (state) => {
    const subState = fnGetSubState(state);
    return subState.dataChange.base.risk;
  };

  const getQuoteData = (state) => {
    const subState = fnGetSubState(state);
    return subState.dataChange.quote.response;
  };

  const getQuoteDataPayload = (state) => {
    const subState = fnGetSubState(state);
    return subState.dataChange.quote.payload;
  };
  // *****************************************************************
  // RISK STORE
  // *****************************************************************

  const riskStoreSelectors = (function () {
    if (riskStoreConfig.storeType === "legacy") {
      const fnGetRiskStoreValue = (
        riskStoreState,
        hitlist = [],
        outputComponentSet = false
      ) => {
        const getValue = (parentComponentId, level = 1) => {
          // get all children componentId list
          const fnLog = (...args) => {
            return;
            console.log(" ".repeat(level * 5), ...args);
          };

          fnLog(parentComponentId);

          // Is a VALUE
          if (parentComponentId in riskStoreState.value) {
            return riskStoreState.value[parentComponentId];
          }

          // Is a SET/LIST
          if (parentComponentId in riskStoreState.componentSetOwnership) {
            const fnProcessComponentSet = (componentSet) => {
              // console.log("...componentSet", componentSet)
              const componentSetList =
                riskStoreState.componentSet_reverseMapping[componentSet] || [];

              const retObj = Object.fromEntries(
                componentSetList.map((childComponentId) => {
                  const tag = riskStoreState.componentTag[childComponentId];
                  return [tag, getValue(childComponentId, level + 1)];
                })
              );

              if (level === 1 && outputComponentSet)
                return { componentSet: componentSet, data: retObj };

              return retObj;
            };

            const _foundData =
              riskStoreState.componentSetOwnership[parentComponentId];

            const isSet = _foundData.every(
              (testId) => riskStoreState.registered[testId]
            );

            fnLog("isSet", parentComponentId, "-", isSet);
            // console.log(parentComponentId, "foundDataItem", foundDataItem);
            if (isSet) {
              const componentSet = _foundData[0];
              return fnProcessComponentSet(componentSet);
            } else {
              return _foundData.map((componentSet) => {
                // console.log("LIST", componentSet);
                return fnProcessComponentSet(componentSet);
              });
            }
          }

          return undefined;
        };

        // riskStoreState.componentSetOwnership
        // outputComponentSet
        const retData = Object.fromEntries(
          hitlist.map((key) => {
            return [key, getValue(key)];
          })
        );

        return retData;
      };

      const getStoreName = () => {
        // #QUOTEANDBUY
        return riskStoreConfig.riskStore.storeName;
      };

      const getStoreState = (state) => {
        const storeNameMta = riskStoreSelectors.getStoreName();
        return state[storeNameMta];
      };

      const isNewComponentSet = (state, testComponentSet) => {
        const riskStoreState = getBaseRisk(state);
        return !(
          testComponentSet in
          riskStoreState.componentSetOwnership_reverseMapping
        );
      };

      const getIsGroupValid = (state, group) => {
        // #QUOTEANDBUY
        return riskStoreConfig.riskStore.selectors.getIsGroupValid(
          state,
          group
        );
      };

      const getIsStoreValid = (state) =>
        // #QUOTEANDBUY
        riskStoreConfig.riskStore.selectors.getIsStoreValid(state);

      const getValue = (state, ...args) =>
        fnGetRiskStoreValue(getStoreState(state), ...args);

      const getValueBase = (state, ...args) =>
        fnGetRiskStoreValue(getBaseRisk(state), ...args);

      const fnGetSalusData = (state, outputComponentSetKey = false) => {
        const riskStoreState = getStoreState(state);

        const rawData = _.pick(riskStoreState, [
          "componentSet",
          "componentSet_reverseMapping",
          "componentSetOwnership",
          "componentSetOwnership_reverseMapping",
          "componentTag",
          "componentTag_reverseMapping",
          "value",
          "registered",
        ]);

        if (!rawData) return;
        if (_.isEmpty(rawData)) return;
        if (_.isEmpty(rawData.value)) return;

        // console.log("aaaa", rawData);
        // #QUOTEANDBUY
        const salusData =
          riskStoreConfig.riskStore.functions.salus.reduxToSalus(rawData, {
            outputComponentSetKey: outputComponentSetKey
              ? "componentSet"
              : undefined,
          });
        // console.log("bbbb", salusData?.data);

        if (!salusData) return undefined;

        return salusData.data;
      };

      const getSalusData = (state) => fnGetSalusData(state, true);
      const getSalusDataPayload = (state) => fnGetSalusData(state, false);

      return {
        getStoreName: getStoreName,
        getStoreState: getStoreState,
        isNewComponentSet: isNewComponentSet,
        getIsGroupValid: getIsGroupValid,
        getIsStoreValid: getIsStoreValid,
        getValue: getValue,
        getValueBase: getValueBase,
        getSalusData: getSalusData,
        getSalusDataPayload: getSalusDataPayload,
      };
    }

    if (riskStoreConfig.storeType === "quoteAndBuy") {
      const fnGetRiskStoreValue = (
        riskStoreState,
        hitlist = [],
        outputComponentSet = false
      ) => {
        throw `not implemented fnGetRiskStoreValue`;
        const getValue = (parentComponentId, level = 1) => {
          // get all children componentId list
          const fnLog = (...args) => {
            return;
            console.log(" ".repeat(level * 5), ...args);
          };

          fnLog(parentComponentId);

          // Is a VALUE
          if (parentComponentId in riskStoreState.value) {
            return riskStoreState.value[parentComponentId];
          }

          // Is a SET/LIST
          if (parentComponentId in riskStoreState.componentSetOwnership) {
            const fnProcessComponentSet = (componentSet) => {
              // console.log("...componentSet", componentSet)
              const componentSetList =
                riskStoreState.componentSet_reverseMapping[componentSet] || [];

              const retObj = Object.fromEntries(
                componentSetList.map((childComponentId) => {
                  const tag = riskStoreState.componentTag[childComponentId];
                  return [tag, getValue(childComponentId, level + 1)];
                })
              );

              if (level === 1 && outputComponentSet)
                return { componentSet: componentSet, data: retObj };

              return retObj;
            };

            const _foundData =
              riskStoreState.componentSetOwnership[parentComponentId];

            const isSet = _foundData.every(
              (testId) => riskStoreState.registered[testId]
            );

            fnLog("isSet", parentComponentId, "-", isSet);
            // console.log(parentComponentId, "foundDataItem", foundDataItem);
            if (isSet) {
              const componentSet = _foundData[0];
              return fnProcessComponentSet(componentSet);
            } else {
              return _foundData.map((componentSet) => {
                // console.log("LIST", componentSet);
                return fnProcessComponentSet(componentSet);
              });
            }
          }

          return undefined;
        };

        // riskStoreState.componentSetOwnership
        // outputComponentSet
        const retData = Object.fromEntries(
          hitlist.map((key) => {
            return [key, getValue(key)];
          })
        );

        return retData;
      };

      const getStoreName = () => {
        // #QUOTEANDBUY
        // throw `not implemented getStoreName`;
        const storeName = riskStoreConfig.riskStore.storeName;

        if (!storeName)
          throw `Error in getStoreName -- missing storeName in riskStoreConfig.riskStore`;
        return storeName;
      };

      const getStoreState = (state) => {
        // throw `not implemented getStoreState`;
        const storeNameMta = riskStoreSelectors.getStoreName();
        return state[storeNameMta];
      };

      const isNewComponentSet = (state, testComponentSet) => {
        // checks if testComponentSet already exists
        // return true; //todoCHANGE THIS
        throw `not implemented isNewComponentSet`;

        const riskStoreState = getBaseRisk(state);
        return !(
          testComponentSet in
          riskStoreState.componentSetOwnership_reverseMapping
        );
      };

      const getIsGroupValid = (state, group) => {
        return riskStoreConfig.riskStore.selectors.errors.isGroupValid(
          state,
          group
        );

        throw `not implemented getIsGroupValid`;

        // #QUOTEANDBUY
        return riskStoreConfig.riskStore.selectors.getIsGroupValid(
          state,
          group
        );
      };

      const getIsStoreValid = (state) => {
        return riskStoreConfig.riskStore.selectors.errors.isValid(state, [
          "Risk",
        ]);

        throw `not implemented getIsStoreValid`;
        return riskStoreConfig.riskStore.selectors.getIsStoreValid(state);
      };

      const getValue = (state, pathList = []) => {
        // Returns an object, containing all the keys in pathList -- with the nested data

        const retData = Object.fromEntries(
          pathList.map((_curPath) => {
            return [
              _curPath,
              riskStoreConfig.riskStore.selectors.userData.risk.metaDataTree(
                state,
                _curPath,
                (metaData) => ({ data: metaData?._value, output: true })
              ),
            ];
          })
        );

        return retData;
      };

      const getValueInitial = (state, pathList = []) => {
        // Returns an object, containing all the keys in pathList -- with the nested data

        const retData = Object.fromEntries(
          pathList.map((_curPath) => {
            return [
              _curPath,
              riskStoreConfig.riskStore.selectors.userData.riskInitial // <<<-- NOTE: the different path
                .metaDataTree(state, _curPath, (metaData) => ({
                  data: metaData?._value,
                  output: true,
                })),
            ];
          })
        );

        return retData;
      };

      // const getValueBase = (state, ...args) => {
      //   throw `not implemented getValueBase`;

      //   return fnGetRiskStoreValue(getBaseRisk(state), ...args);
      // };

      const _fnGetSalusData = (state, options = {}) => {
        const { keyArrayId = undefined } = options;
        return riskStoreConfig.riskStore.selectors.userData.risk.salusData(
          state,
          { keyArrayId }
        );
      };

      const _fnGetSalusDataInitial = (state, options = {}) => {
        const { keyArrayId = undefined } = options;
        return riskStoreConfig.riskStore.selectors.userData.riskInitial.salusData(
          state,
          { keyArrayId }
        );
      };

      return {
        getStoreName: getStoreName,
        getStoreState: getStoreState,
        isNewComponentSet: isNewComponentSet,
        getIsGroupValid: getIsGroupValid,
        getIsStoreValid: getIsStoreValid,
        getValue: getValue,
        getValueInitial: getValueInitial,
        getSalusData: (state) => _fnGetSalusData(state, { keyArrayId: "_id" }),
        getSalusDataPayload: (state) => _fnGetSalusData(state),

        getSalusDataInitial: (state) =>
          _fnGetSalusDataInitial(state, { keyArrayId: "_id" }),
        getSalusDataPayloadInitial: (state) => _fnGetSalusDataInitial(state),
      };
    }

    throw `Error in selectors -- unknown riskStoreConfig.storeType "${riskStoreConfig.storeType}"`;
  })();

  // *****************************************************************
  // MTA
  // *****************************************************************

  const fnGetSubState = (state) => state[storeName];

  const getStatusData = (state, key) => {
    const subState = fnGetSubState(state);

    return {
      errorMessage: subState.statusErrorMessage[key],
      isInitiating: subState.statusInitiating[key],
      isError: subState.statusErrorMessage[key] ? true : false,
    };

    // return {
    //   ..._.pick(subState, [
    //     "isLoading",
    //     "isLoadError",
    //     "isInit",
    //     "feedbackErrorMsg",
    //     "isQuoting",
    //     "quoteErrMsg",
    //   ]),
    //   hasQuote: getQuoteData(state) ? true : false,
    // };
  };

  const hasValueChanged = (state, path) => {
    if (!path) throw `Error in hasValueChanged -- missing componentId`;

    const dataSalusBase = getBaseSalus(state);
    const salusData = riskStoreSelectors.getSalusData(state);

    const lodashPath = _.replace(path, new RegExp("/", "g"), "."); // Replace all "/" with "."
    const oldValue = _.get(dataSalusBase, lodashPath);
    const newValue = _.get(salusData, lodashPath);

    // console.log("dsdadad", {
    //   dataSalusBase,
    //   salusData,
    //   oldValue,
    //   newValue,
    //   path,
    //   lodashPath,
    // });

    return !_.isEqual(oldValue, newValue);
  };

  const getHasGetQuote_PayloadChanged = (state) => {
    const quoteSalusPayload = getQuoteDataPayload(state);
    const curSalusPayload = riskStoreSelectors.getSalusData(state);

    return !_.isEqual(curSalusPayload, quoteSalusPayload);
  };

  const getPaymentLauncherData = (state) => {
    const subState = fnGetSubState(state);
    return subState.dataPaymentLauncher;
  };

  // const getWrapupData = (state) => {
  //   const subState = fnGetSubState(state);

  //   return subState.dataWrapup;
  // };
  // const getIsCompleted = (state) => {
  //   const subState = fnGetSubState(state);
  //   return subState.isCompleted;
  // };

  const getSavedQuotes = (state) => {
    const subState = fnGetSubState(state);

    return subState.dataSavedQuotes;
  };

  const hasQuote = (state) => !_.isEmpty(getQuoteData(state));

  // const getPolicyId = (state) => {
  //   const subState = fnGetSubState(state);

  //   return subState.policyId;
  // };

  const getIsInit = (state) => {
    const subState = fnGetSubState(state);

    return subState.dataInit.policy ? true : false;
  };

  // const getIsWrapped = (state) => {
  //   const dataWrapup = getWrapupData(state);

  //   if (dataWrapup?.isAP) return true;
  //   if (dataWrapup?.isRP) return true;
  //   if (dataWrapup?.isNIL) return true;

  //   return false;
  // };

  // const getEffectiveDate = (state) => {
  //   const subState = fnGetSubState(state);

  //   return subState.effectiveDate;
  // };
  const getPaymentMethod = (state) => {
    const subState = fnGetSubState(state);

    return subState.paymentMethod;
  };

  const getPolicyData = (state) => {
    const subState = fnGetSubState(state);
    return subState.dataInit.policy;
  };

  const getStartDateLimits = (state) => {
    const subState = fnGetSubState(state);
    return subState.startDate;
  };
  // const isStarted = (state) => {
  //   const subState = fnGetSubState(state);
  //   return subState.dataPolicy ? true : false;
  // };

  // *****************************************************************
  // MISC
  // *****************************************************************

  const getValue = (state, key) => {
    const subState = fnGetSubState(state);
    return subState.dataValues[key];
  };
  // *****************************************************************
  // PAYLOADS
  // *****************************************************************

  const payloads = {
    quote: (state) => {
      const subState = fnGetSubState(state);
      const returnSalusData = riskStoreSelectors.getSalusDataPayload(state);

      const { PolicyId: policyId, WebReference: webReference } =
        subState.dataInit.policy || {};

      return {
        policyId,
        webReference,
        salusData: returnSalusData,
      };
    },

    paymentLauncher: (state, additionalValues = {}) => {
      const quoteData = getQuoteData(state);

      if (!quoteData) return undefined;

      // validate "additionalValues"
      {
        const fnCheckNested = (obj, path) => {
          if (obj === undefined) return false;

          const pathArray = path.split(".");
          const [level, ...rest] = pathArray;

          if (rest.length == 0 && obj.hasOwnProperty(level)) return true;
          return fnCheckNested(obj[level], ...rest);
        };

        // CHECK additionalValues
        [
          "cardholderAddress.houseNameOrNumber",
          "cardholderAddress.addressLine1",
          "cardholderAddress.addressLine2",
          "cardholderAddress.addressLine3",
          "cardholderAddress.addressLine4",
          "cardholderAddress.postcode",

          "redirects.paymentSuccess",
          "redirects.back",
          "redirects.paymentError",
          "redirects.refused",
          "redirects.timeout",
          "redirects.referred",
          "redirects.declined",
          "redirects.technicalError",

          "sessionId",
          "paymentMethod",
          "policyData",
        ].forEach((k) => {
          const found = fnCheckNested(additionalValues, k);

          console.log(
            "paymentLauncher",
            "CHECKING:",
            k,
            ":",
            found && "OK",
            "(",
            _.get(additionalValues, k),
            ")"
          );

          if (!found) {
            throw `Error in paymentLauncher -- missing additionalValues.${k}`;
          }
        });
      }

      // console.log("selectors.paymentLauncher", { quoteData, additionalValues });

      const IsPayInFull = additionalValues.paymentMethod === "F";

      return {
        RequestType: "MTA",
        Affinity: additionalValues.policyData.Affinity,
        PolicyType: additionalValues.policyData.PolicyType,
        WebReference: additionalValues.policyData.WebReference,
        PolicyId: additionalValues.policyData.PolicyId,
        ProposerId: additionalValues.policyData.ProposerId,
        BusinessEventId: quoteData["BusinessEventId"],
        SessionId: additionalValues.sessionId,
        MtaType: quoteData["MtaType"],
        IsPayInFull: IsPayInFull,
        MtaStartDate: additionalValues.startDate,

        RemainingBalance: quoteData["RemainingBalance"],
        AmountToPay: (function () {
          if (IsPayInFull) return quoteData["TotalAdjustmentAmount"];
          return quoteData["ToPayToday"];
        })(),
        ForceFinaliseFail:
          "forceFail" in additionalValues ? additionalValues.forceFail : false,
        Redirects: {
          PaymentSuccess: additionalValues.redirects.paymentSuccess,
          Back: additionalValues.redirects.back,
          PaymentError: additionalValues.redirects.paymentError,
          Refused: additionalValues.redirects.refused,
          Timeout: additionalValues.redirects.timeout,
          Referred: additionalValues.redirects.referred,
          Declined: additionalValues.redirects.declined,
          TechnicalError: additionalValues.redirects.technicalError,
        },
        CardholderAddress: {
          HouseNameOrNumber:
            additionalValues.cardholderAddress.houseNameOrNumber,
          AddressLine1: additionalValues.cardholderAddress.addressLine1,
          AddressLine2: additionalValues.cardholderAddress.addressLine2,
          AddressLine3: additionalValues.cardholderAddress.addressLine3,
          AddressLine4: additionalValues.cardholderAddress.addressLine4,
          Postcode: additionalValues.cardholderAddress.postcode,
        },
      };
    },

    getWrapupPayload: (state) => {
      return {};
    },
  };

  // *****************************************************************
  // RETURN
  // *****************************************************************
  return {
    riskStore: riskStoreSelectors,
    payloads,
    // getRiskValueObject,
    getStatusData,
    getQuoteData,
    hasQuote,
    // getPolicyId,

    getIsInit,
    getBaseSalus,
    // getEffectiveDate,

    hasValueChanged,

    getPaymentLauncherData,

    getSavedQuotes,
    // getWrapupData,

    getPaymentMethod,
    getPolicyData,

    // getIsCompleted,
    getHasGetQuote_PayloadChanged,
    getValue,
    getStartDateLimits,
    getUserInfoSalus,
    private: { getUserInfoSalus },
  };
};

export default generate;
