import _ from "lodash";

const generate = (args = {}) => {
  const { storeName } = args;

  // ** BASE FUNCTIONS
  const fnGetSubState = (state) => state[storeName];

  return {
    isInit: (state) => fnGetSubState(state).isInit === true,
    config: (state) => fnGetSubState(state).config,
    policy: {
      data: (state) => fnGetSubState(state).data,
    },
    paymentLauncher: (state) => fnGetSubState(state).paymentLauncher,
    status: {
      init: (state, key) => fnGetSubState(state).statusInit[key],
      error: (state, key) => fnGetSubState(state).statusError[key],
    },

    chosenOptions: {
      paymentMethod: (state) =>
        fnGetSubState(state).chosenOptions["paymentMethod"],
      autoRenewal: (state) => fnGetSubState(state).chosenOptions["autoRenewal"],
    },
  };
};

export default generate;
