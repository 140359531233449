import React from "react";
import templates from "./templates/car";
import { list, set, item } from "@library/common/database/classes";

import constants from "@library/common/constants/database";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";

import generateBaseRuleData from "./generateBaseRuleData";
import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import regExConstants from "@library/common/constants/regEx";
import composeDatabase from "@library/common/database/functions/composeDatabase";
import dayjs from "dayjs";
/*kk
	regex for cloning a tag:
	search: 			// componentTag:  "(.*?)"
	replace: 			// componentTag:  "$1", submitKey: "$1"

*/

// note: composeDatabase does the following --
//
// 1) adds "componentTag" to all the nodes (based on the node's key)
// 2) adds "componentSet" based on the "componentTag" tree, stopping on anything after a "list"

const classOfUseLogic = new ruleItem(
  { ...generateBaseRuleData("vehicle", "classOfUseLogic") },
  {
    AdditionalInsuredSet: new dataSetArgItem({
      componentTag: "AdditionalInsuredSet",
      componentSet: "Risk",
    }),
    AdditionalInsuredSet_RelationshipToProposer_TAG: "RelationshipToProposer",

    ClassOfUse: new dataSetArgItem({
      componentTag: "ClassOfUse",
      useDefaultComponentSet: true,
    }),
    ClassOfUseFrontEnd: new dataSetArgItem({
      componentTag: "ClassOfUseFrontEnd",
      useDefaultComponentSet: true,
    }),
  }
);

const riskLogic = new ruleItem(
  {
    ...generateBaseRuleData("vehicle", "riskLogic"),
  },
  {
    ShowAdditionalDrivers: new dataSetArgItem({
      componentTag: "ShowAdditionalDrivers",
      componentSet: "Risk/RiskData",
    }),

    VehicleExtraDetailsConfirmation: new dataSetArgItem({
      componentTag: "VehicleExtraDetailsConfirmation",
      componentSet: "Risk/RiskData",
    }),

    AdditionalInsuredSet: new dataSetArgItem({
      componentTag: "AdditionalInsuredSet",
      componentSet: "Risk",
    }),
    Confirmation: new dataSetArgItem({
      componentTag: "Confirmation",
      componentSet: "Risk/RiskData",
    }),
  }
);

const ruleItemNcbLogic = new ruleItem(
  {
    ...generateBaseRuleData("vehicle", "ncbLogic"),
  },
  {
    ProtectedBonus: new dataSetArgItem({
      componentTag: "ProtectedNcd",
      useDefaultComponentSet: true,
    }),
    Years: new dataSetArgItem({
      componentTag: "YearsNcd",
      useDefaultComponentSet: true,
    }),
    AllowProtectedNcd: new dataSetArgItem({
      componentTag: "AllowProtectedNcd",
      useDefaultComponentSet: true,
    }),
    DrivingExperienceYears: new dataSetArgItem({
      componentTag: "DrivingExperienceYears",
      useDefaultComponentSet: true,
    }),
    DrivingExperienceType: new dataSetArgItem({
      componentTag: "DrivingExperienceType",
      useDefaultComponentSet: true,
    }),
  }
);

const ruleItemAdditionalDriverLogic = new ruleItem(
  {
    ...generateBaseRuleData("vehicle", "additionalDriverLogic"),
  },
  {
    RelationshipToProposer: new dataSetArgItem({
      componentTag: "RelationshipToProposer",
      useDefaultComponentSet: true,
    }),
    MaritalStatus: new dataSetArgItem({
      componentTag: "MaritalStatus",
      useDefaultComponentSet: true,
    }),
    DateOfBirth: new dataSetArgItem({
      componentTag: "DateOfBirth",
      useDefaultComponentSet: true,
    }),
    ProposerDateOfBirth: new dataSetArgItem({
      componentTag: "DateOfBirth",
      componentSet: "Risk/Proposer",
    }),

    ProposerMaritalStatus: new dataSetArgItem({
      componentTag: "MaritalStatus",
      componentSet: "Risk/Proposer",
    }),

    ProposerForenames: new dataSetArgItem({
      componentTag: "Forenames",
      componentSet: "Risk/Proposer/Name",
    }),

    ProposerSurname: new dataSetArgItem({
      componentTag: "Surname",
      componentSet: "Risk/Proposer/Name",
    }),

    ProposerTitle: new dataSetArgItem({
      componentTag: "Title",
      componentSet: "Risk/Proposer/Name",
    }),

    ProposerClassOfUse: new dataSetArgItem({
      componentTag: "ClassOfUse",
      componentSet: "Risk",
    }),
    ProposerClassOfUseFrontEnd: new dataSetArgItem({
      componentTag: "ClassOfUseFrontEnd",
      componentSet: "Risk",
    }),
  }
);

const startDateRule = new ruleItem(
  {
    ...generateBaseRuleData("vehicle", "startDateLogic"),
  },
  {
    PolicyInceptionDate: new dataSetArgItem({
      componentTag: "PolicyInceptionDate",
      useDefaultComponentSet: true,
    }),
    EndDate: new dataSetArgItem({
      componentTag: "EndDate",
      useDefaultComponentSet: true,
    }),
  }
);

const fnGenerateDatabase = (args = {}) => {
  const { mode = "quote" } = args;

  const isQuote = mode === "quote";
  const isMta = mode === "mta";

  if (!isQuote && !isMta)
    throw `Error in fnGenerateDatabase -- unknown mode = "${mode}"`;

  const vehicleLogicRule = templates.Vehicle.generateLogicRule(
    {
      Postcode: new dataSetArgItem({
        componentTag: "Postcode",
        componentSet: "Risk/Proposer/Address",
      }),
      PolicyInceptionDate: new dataSetArgItem({
        useDefaultComponentSet: true,
        componentTag: isMta ? "StartDate" : "PolicyInceptionDate",
      }),
    },
    //componentSetPath
    ["Vehicle"],
    mode
  );

  const database = composeDatabase({
    Risk: new set({
      // componentSet: "Risk",
      submitKey: "Risk",
      items: {
        DebugData: new item({ label: "Debug into" }),

        PreviousInsurance: new set({
          submitKey: "PreviousInsurance",
          items: {
            Ncb: new set({
              submitKey: "Ncb",
              items: {
                Years: new item({
                  submitKey: "Years",
                  exampleValue: "20000",
                }),
                ProtectedBonus: new item({
                  submitKey: "ProtectedBonus",
                }),
                AppliedToAnotherVehicle: new item({
                  submitKey: "AppliedToAnotherVehicle",
                  defaultValue: false,
                }),
              },
            }),

            PreviousPolicyBonusProtected: new item({
              submitKey: "PreviousPolicyBonusProtected",
              defaultValue: false,
            }),

            ExpiryDate: new item({
              submitKey: "ExpiryDate",
              // fnReduxToSalus: (v) =>
              // 	v
              // 		? v
              // 		: reduxToSalusHelpers.processDate(dayjs().subtract(1, "days")),
              // fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),
            }),
            YearsHeld: new item({
              submitKey: "YearsHeld",
            }),
            Type: new item({
              submitKey: "Type",
            }),
            InsurerName: new item({
              submitKey: "InsurerName",
            }),
          },
        }),

        Cover: new item({
          submitKey: "Cover",
          defaultValue: "C",
          rules: [
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "", // errorMessage: "Required",
              }
            ),
          ],
        }),

        TotalMileage: new item({
          submitKey: "TotalMileage",
          label: isMta
            ? "How many miles will you drive in your car in the next 12 months?"
            : "How many miles will this car be driven in the next 12 months?",

          rules: [
            new ruleItem(
              {
                ...generateBaseRuleData(
                  "vehicle",
                  "vehicleBusinessMileageLogic"
                ),
              },
              {
                TotalMileage: new dataSetArgItem({
                  componentTag: "TotalMileage",
                  useDefaultComponentSet: true,
                }),
                BusinessMileage: new dataSetArgItem({
                  componentTag: "BusinessMileage",
                  useDefaultComponentSet: true,
                }),
                ClassOfUse: new dataSetArgItem({
                  componentTag: "ClassOfUse",
                  useDefaultComponentSet: true,
                }),
                policyType: "PC",
                errorMessage: "", // errorMessage: "Required",
              }
            ),
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "", // errorMessage: "Required",
              }
            ),
          ],
        }),

        BusinessMileage: new item({
          submitKey: "BusinessMileage",
          label:
            "How much of your estimated annual mileage will be used for business?",
          //helpText: "How much of your estimated annual mileage will be used for business?",
          rules: [
            new ruleItem(
              {
                ...generateBaseRuleData(
                  "vehicle",
                  "vehicleBusinessMileageLogic"
                ),
              },
              {
                TotalMileage: new dataSetArgItem({
                  componentTag: "TotalMileage",
                  useDefaultComponentSet: true,
                }),
                BusinessMileage: new dataSetArgItem({
                  componentTag: "BusinessMileage",
                  useDefaultComponentSet: true,
                }),
                ClassOfUse: new dataSetArgItem({
                  componentTag: "ClassOfUse",
                  useDefaultComponentSet: true,
                }),
                policyType: "PC",
                errorMessage: "", // errorMessage: "Required",
              }
            ),
          ],
          // rules: [
          // 	new ruleItem({
          // 		...generateBaseRuleData("general", "isRequired"),},{
          // 		errorMessage: "", // errorMessage: "Required",
          // 	}),
          // 	require("./templates").default.Policy.logicRule,
          // ],
        }),

        //** defaulted on backend */
        Restrict: new item({
          submitKey: "Restrict",
        }),

        ClassOfUseFrontEnd: new item({
          label: "Will you use the car to travel to work?",
          helpText: (
            <span>
              Your policy will automatically include social, domestic and
              pleasure use. Let us know if you also use the car to travel to
              work.
            </span>
          ),
          rules: [
            templates.Policy.logicRule,
            classOfUseLogic,
            new ruleItem(
              generateBaseRuleData("vehicle", "vehicleBusinessMileageLogic"),
              {
                TotalMileage: new dataSetArgItem({
                  componentTag: "TotalMileage",
                  useDefaultComponentSet: true,
                }),
                BusinessMileage: new dataSetArgItem({
                  componentTag: "BusinessMileage",
                  useDefaultComponentSet: true,
                }),
                ClassOfUse: new dataSetArgItem({
                  componentTag: "ClassOfUse",
                  useDefaultComponentSet: true,
                }),
                errorMessage: "", // errorMessage: "Required",
              }
            ),
            new ruleItem(generateBaseRuleData("general", "isRequired"), {
              errorMessage: "", // errorMessage: "Required",
            }),
          ],
        }),
        ClassOfUse: new item({
          submitKey: "ClassOfUse",
          rules: [classOfUseLogic],
        }),

        Vehicle: templates.Vehicle.generateSet(
          {
            additionalMappings: {
              Postcode: new dataSetArgItem({
                componentTag: "Postcode",
                componentSet: "Risk/Proposer/Address",
              }),
              PolicyInceptionDate: new dataSetArgItem({
                componentSetPath: ["..."],
                componentTag: isMta ? "StartDate" : "PolicyInceptionDate",
              }),
            },
          }

          // // modify items:
          // [
          // 	{
          // 		componentTag: "Purchased",
          // 		key: "rules",
          // 		data: [
          // 			new ruleItem({
          // 				...generateBaseRuleData("general", "dateComparison"),},{
          // 				errorMessage:
          // 					"Your policy start cannot be before the vehicle purchase date.",
          // 				compareDate: new dataSetArgItem({
          // 					componentSetPath: ["..."],
          // 					componentTag: "PolicyInceptionDate",
          // 				}),
          // 				comparator: "<=",
          // 			}),
          // 		],
          // 	},
          // ]
        ),

        ExtraDriverDetails: new set({
          submitKey: "ExtraDriverDetails",
          items: {
            DriverType: new item({
              submitKey: "DriverType",
              defaultValue: "PC",
            }),
            YearsNcd: new item({
              submitKey: "YearsNcd",
              label:
                "How many years no claims discount do you have on a car insurance policy in your name?",
              //helpText: ,
              helpTextFurther: (
                <>
                  <p>
                    Every year you hold a policy in your own name without making
                    a claim, you build up a no claims discount which saves you
                    money. This proof should be a renewal notice from your
                    previous Insurance Company, or a letter on headed paper from
                    the Insurance Company.
                  </p>
                  <p>
                    N.B. This no claims discount can only be used on one policy
                    at any one time, and must have been used on a policy within
                    the last two years.
                  </p>
                </>
              ),
              html: {
                itemData: [
                  { label: "0 years", value: "0" },
                  { label: "1 year", value: "1" },
                  { label: "2 years", value: "2" },
                  { label: "3 years", value: "3" },
                  { label: "4 years", value: "4" },
                  { label: "5 years", value: "5" },
                  { label: "6 years", value: "6" },
                  { label: "7 years", value: "7" },
                  { label: "8 years", value: "8" },
                  { label: "9 years or over", value: "9" },
                ],
              },
              rules: [
                new ruleItem(
                  {
                    ...generateBaseRuleData("general", "isRequired"),
                  },
                  {
                    errorMessage: "", // errorMessage: "Required",
                  }
                ),
                ruleItemNcbLogic,
              ],
            }),
            ProtectedNcd: new item({
              submitKey: "ProtectedNcd",
              label: "Would you like to protect your no claims discount?",
              html: {
                itemData: constants.lists.yesNo,
              },
              rules: [ruleItemNcbLogic],
            }),
            AllowProtectedNcd: new item({
              submitKey: "AllowProtectedNcd",
              defaultValue: true,
              rules: [ruleItemNcbLogic],
            }),

            DrivingExperienceType: new item({
              submitKey: "DrivingExperienceType",
              label:
                " Do you have any named driver experience on another car insurance policy?",
              rules: [ruleItemNcbLogic],
            }),

            DrivingExperienceYears: new item({
              submitKey: "DrivingExperienceYears",
              label:
                "How many years claim free driving experience do you have?",
              html: {
                itemData: [
                  { label: "0 years", value: "0" },
                  { label: "1 year", value: "1" },
                  { label: "2 years", value: "2" },
                  { label: "3 years", value: "3" },
                  { label: "4 years", value: "4" },
                  { label: "5 years", value: "5" },
                  { label: "6 years", value: "6" },
                  { label: "7 years", value: "7" },
                  { label: "8 years", value: "8" },
                  { label: "9 years or over", value: "9" },
                ],
              },
              rules: [ruleItemNcbLogic],
            }),

            // OwnAndInsureAnotherVehicle: new item({
            // 	submitKey: "OwnAndInsureAnotherVehicle",
            // 	defaultValue: false,
            // 	label:
            // 		"Do you own and insure another vehicle, excluding motorcycles?",
            // 	html: {
            // 		itemData: constants.lists.yesNo,
            // 	},
            // 	rules: [
            // 		new ruleItem(
            // 			{
            // 				...generateBaseRuleData("vehicle", "ncbOtherVehicleLogic"),
            // 			},
            // 			{
            // 				OwnAndInsureAnotherVehicle: new dataSetArgItem({
            // 					componentTag: "OwnAndInsureAnotherVehicle",
            // 					useDefaultComponentSet: true,
            // 				}),
            // 				OtherVehicleYearsNcd: new dataSetArgItem({
            // 					componentTag: "OtherVehicleYearsNcd",
            // 					useDefaultComponentSet: true,
            // 				}),
            // 			}
            // 		),
            // 	],
            // }),

            // OtherVehicleYearsNcd: new item({
            // 	submitKey: "OtherVehicleYearsNcd",
            // 	label:
            // 		"How many years no claims discount do you have on your other vehicle?",
            // 	html: {
            // 		itemData: [
            // 			{ label: "0 years", value: "0" },
            // 			{ label: "1 year", value: "1" },
            // 			{ label: "2 years", value: "2" },
            // 			{ label: "3 years", value: "3" },
            // 			{ label: "4 years", value: "4" },
            // 			{ label: "5 years", value: "5" },
            // 			{ label: "6 years", value: "6" },
            // 			{ label: "7 years", value: "7" },
            // 			{ label: "8 years", value: "8" },
            // 			{ label: "9 years or over", value: "9" },
            // 		],
            // 	},
            // 	rules: [
            // 		new ruleItem(
            // 			{
            // 				...generateBaseRuleData("vehicle", "ncbOtherVehicleLogic"),
            // 			},
            // 			{
            // 				OwnAndInsureAnotherVehicle: new dataSetArgItem({
            // 					componentTag: "OwnAndInsureAnotherVehicle",
            // 					useDefaultComponentSet: true,
            // 				}),
            // 				OtherVehicleYearsNcd: new dataSetArgItem({
            // 					componentTag: "OtherVehicleYearsNcd",
            // 					useDefaultComponentSet: true,
            // 				}),
            // 			}
            // 		),
            // 	],
            // }),
          },
        }),

        Frequency: new item({
          submitKey: "Frequency",
          defaultValue: "PlcyAnnual",
          rules: [
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "", // errorMessage: "Required",
              }
            ),
          ],
        }),

        PolicyTypeCode: new item({
          submitKey: "PolicyTypeCode",
          defaultValue: "PC",
          rules: [
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "", // errorMessage: "Required",
              }
            ),
          ],
        }),

        VolXs: new item({
          submitKey: "VolXs",
          exampleValue: "200",
          label: "Voluntary excess",
          helpText: (
            <>
              <span className="d-block">
                A voluntary excess can help to reduce your premium.{" "}
              </span>
              <span>
                This will be in addition to the compulsory excess which would be
                paid in the event of a claim.
              </span>
            </>
          ),
          html: {
            itemData: [
              { label: "£0", value: "0" },
              { label: "£100", value: "100" },
              { label: "£200", value: "200" },
              { label: "£300", value: "300" },
              { label: "£400", value: "400" },
              { label: "£500", value: "500" },
            ],
          },
          rules: [
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "", // errorMessage: "Required",
              }
            ),
          ],
        }),

        // ...(isMta && {
        //   RenewalDate: new item({
        //     submitKey: "RenewalDate",
        //     fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
        //     fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),
        //   }),
        // }),

        // QuoteDate: new item({
        // 	submitKey: "QuoteDate",
        // }),

        // EndDate: new item({
        // 	submitKey: "EndDate",
        // 	fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
        // 	fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),
        // 	rules: [
        // 		new ruleItem({
        // 			...generateBaseRuleData("general", "isRequired"),},{
        // 			errorMessage: "", // errorMessage: "Required",
        // 		}),
        // 	],
        // }),

        QuoteType: new item({
          submitKey: "QuoteType",
          defaultValue: "NewQuotTyp",
          rules: [
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "", // errorMessage: "Required",
              }
            ),
          ],
        }),

        ...(isMta && {
          StartDate: new item({
            label: "When would you like your change to start?",
            helpText:
              "Your change will be effective from the date you choose and last until the end of your policy",
            submitKey: "StartDate",
            fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
            fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),
            rules: [
              vehicleLogicRule,
              new ruleItem(generateBaseRuleData("general", "isRequired"), {
                errorMessage: "",
              }),
            ],
          }),
        }),

        ...(isMta && {
          EndDate: new item({
            submitKey: "EndDate",
            // Removed processing re: #2869
            // fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
            // fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),
          }),
        }),

        PolicyInceptionDate: new item({
          submitKey: "PolicyInceptionDate",
          exampleValue: "2020-10-22T00:00:00+01:00",
          label: "When would you like your policy to start?",
          helpText:
            "You can choose a date up to a maximum of 30 days from today.",
          fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
          fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),

          rules: [
            // new ruleItem({
            // 	...generateBaseRuleData("general", "salusConvertStringToDate"),},{
            // 	runOnUserUpdate: false,
            // 	forceUpdate: true,
            // }),
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "", // errorMessage: "Required",
              }
            ),

            ...(isQuote
              ? [
                  new ruleItem(
                    generateBaseRuleData("general", "dateComparison"),
                    {
                      errorMessage: "", //"Your policy start date cannot be in the past",
                      today: true,
                      comparator: ">=",
                    }
                  ),
                ]
              : []),
            // new ruleItem({
            // 	...generateBaseRuleData("general", "dateComparison"),},{
            // 	errorMessage: "", //"Your policy start cannot be before the vehicle purchase date.",
            // 	compareDate: new dataSetArgItem({
            // 		componentSetPath: ["Vehicle"],
            // 		componentTag: "Purchased",
            // 	}),
            // 	comparator: ">=",
            // }),
            new ruleItem(
              {
                ...generateBaseRuleData("general", "runRules"),
              },
              {
                componentTagList: ["DateOfBirth"],
              }
            ),
            startDateRule,
            vehicleLogicRule,
          ],
        }),

        Proposer: templates.PersonProposer.generateSet(
          {
            submitKey: "Proposer",

            modifyItem: [
              {
                componentTag: "Email",
                key: "rules",
                data: new ruleItem(
                  generateBaseRuleData("vehicle", "emailLogic"),
                  {
                    Email: new dataSetArgItem({
                      componentTag: "Email",
                      useDefaultComponentSet: true,
                    }),
                    EmailConfirmed: new dataSetArgItem({
                      componentTag: "EmailConfirmed",
                      componentSetPath: ["..", "QuoteData"],
                    }),
                    storeName: "carStore",
                  }
                ),
              },
              {
                componentTag: "VehicleUse",
                key: "rules",
                data: templates.PersonAddDriver.generateVehicleUseRule({
                  ComponentTagVehicleUse: "VehicleUse",
                  AdditionalInsuredSet: new dataSetArgItem({
                    componentTag: "AdditionalInsuredSet",
                    componentSetPath: [".."],
                  }),
                  MainUserComponentId: new dataSetArgItem({
                    componentTag: "MainUserComponentId",
                    componentSetPath: [".."],
                  }),
                  ProposerVehicleUse: new dataSetArgItem({
                    componentTag: "VehicleUse",
                    useDefaultComponentSet: true,
                  }),
                  VehicleUseCurrent: new dataSetArgItem({
                    componentTag: "VehicleUse",
                    useDefaultComponentSet: true,
                  }),
                }),
              },
            ],
          },
          {
            AdditionalInsuredSet: new dataSetArgItem({
              componentTag: "AdditionalInsuredSet",
              componentSet: [".."],
            }),
            AdditionalInsuredSet_RelationshipToProposer_TAG:
              "RelationshipToProposer",
            defaultLicenceCategoryValue: "CatB",
            isMta: isMta,
          }
        ),

        AdditionalInsuredSet: templates.PersonAddDriver.generateList(
          {
            submitKey: "AdditionalInsuredSet",
            rules: [
              templates.PersonAddDriver.generateVehicleUseRule({
                ComponentTagVehicleUse: "VehicleUse",
                AdditionalInsuredSet: new dataSetArgItem({
                  componentTag: "AdditionalInsuredSet",
                  useDefaultComponentSet: true,
                }),
                MainUserComponentId: new dataSetArgItem({
                  componentTag: "MainUserComponentId",
                  useDefaultComponentSet: true,
                }),
                ProposerVehicleUse: new dataSetArgItem({
                  componentTag: "VehicleUse",
                  componentSetPath: ["Proposer"],
                }),
              }),
              riskLogic,
            ],
            modifyItem: [
              {
                componentTag: "VehicleUse",
                key: "rules",
                data: templates.PersonAddDriver.generateVehicleUseRule({
                  ComponentTagVehicleUse: "VehicleUse",
                  AdditionalInsuredSet: new dataSetArgItem({
                    componentTag: "AdditionalInsuredSet",
                    componentSetPath: [".."],
                  }),
                  MainUserComponentId: new dataSetArgItem({
                    componentTag: "MainUserComponentId",
                    componentSetPath: [".."],
                  }),
                  ProposerVehicleUse: new dataSetArgItem({
                    componentTag: "VehicleUse",
                    componentSetPath: ["..", "Proposer"],
                  }),
                  VehicleUseCurrent: new dataSetArgItem({
                    componentTag: "VehicleUse",
                    useDefaultComponentSet: true,
                  }),
                }),
              },
            ],
          },
          {
            ruleItemAdditionalDriverLogic,
            defaultLicenceCategoryValue: "CatB",
            isMta: isMta,
          }
        ),

        MainUserComponentId: new item({
          label: "Who is the main user of the vehicle?",
          rules: [
            templates.PersonAddDriver.generateVehicleUseRule({
              ComponentTagVehicleUse: "VehicleUse",
              AdditionalInsuredSet: new dataSetArgItem({
                componentTag: "AdditionalInsuredSet",
                useDefaultComponentSet: true,
              }),
              MainUserComponentId: new dataSetArgItem({
                componentTag: "MainUserComponentId",
                useDefaultComponentSet: true,
              }),
              ProposerVehicleUse: new dataSetArgItem({
                componentTag: "VehicleUse",
                componentSetPath: ["Proposer"],
              }),
            }),
            // new ruleItem({
            // 	...generateBaseRuleData("general", "isRequired"),},{
            // 	errorMessage: "", // errorMessage: "Required",
            // }),
          ],
        }),

        Userfields: new list({
          submitKey: "Userfields",
          template: {
            Code: new item({
              submitKey: "Code",
              exampleValue: "COMMEM",
            }),
            Response: new item({
              submitKey: "Response",
              exampleValue: "Yes",
            }),
            ShortResponse: new item({
              submitKey: "ShortResponse",
              exampleValue: "YES",
            }),
            IsList: new item({
              submitKey: "IsList",
            }),
            ScreenPrompt: new item({
              submitKey: "ScreenPrompt",
            }),
          },
        }),

        MedicalConsentQuestionsActive: new item({
          submitKey: "MedicalConsentQuestionsActive",
          exampleValue: false,
          // rules: [
          // 	new ruleItem({
          // 		...generateBaseRuleData("general", "isRequired"),},{
          // 		errorMessage: "", // errorMessage: "Required",
          // 	}),
          // ],
        }),
        ConvictionsConsentQuestionsActive: new item({
          submitKey: "ConvictionsConsentQuestionsActive",
          exampleValue: false,
          // rules: [
          // 	new ruleItem({
          // 		...generateBaseRuleData("general", "isRequired"),},{
          // 		errorMessage: "", // errorMessage: "Required",
          // 	}),
          // ],
        }),

        // MarketingConsent: new set({
        // 	submitKey: "MarketingConsent",
        // 	items: {
        // 		Email: new item({
        // 			submitKey: "Email",
        // 			html: { label: "by Email" },
        // 			defaultValue: false,
        // 			rules: [
        // 				new ruleItem({
        // 					...generateBaseRuleData("general", "isRequired"),},{
        // 					errorMessage: "", // errorMessage: "Required",
        // 				}),
        // 			],
        // 		}),
        // 		Post: new item({
        // 			submitKey: "Post",
        // 			html: { label: "by Post" },
        // 			defaultValue: false,

        // 			rules: [
        // 				new ruleItem({
        // 					...generateBaseRuleData("general", "isRequired"),},{
        // 					errorMessage: "", // errorMessage: "Required",
        // 				}),
        // 			],
        // 		}),
        // 		Phone: new item({
        // 			submitKey: "Phone",
        // 			html: { label: "by Phone" },
        // 			defaultValue: false,

        // 			rules: [
        // 				new ruleItem({
        // 					...generateBaseRuleData("general", "isRequired"),},{
        // 					errorMessage: "", // errorMessage: "Required",
        // 				}),
        // 			],
        // 		}),
        // 		Sms: new item({
        // 			submitKey: "Sms",
        // 			html: { label: "by Text" },
        // 			defaultValue: false,

        // 			rules: [
        // 				new ruleItem({
        // 					...generateBaseRuleData("general", "isRequired"),},{
        // 					errorMessage: "", // errorMessage: "Required",
        // 				}),
        // 			],
        // 		}),
        // 	},
        // }),

        MarketingConsent: new set({
          submitKey: "MarketingConsent",
          items: {
            Email: new item({
              submitKey: "Email",
            }),
            Post: new item({
              submitKey: "Post",
            }),
            Phone: new item({
              submitKey: "Phone",
            }),
            Sms: new item({
              submitKey: "Sms",
            }),
            HouseholdRenewal: new item({
              submitKey: "HouseholdRenewal",
            }),
            PetRenewal: new item({
              submitKey: "PetRenewal",
            }),
          },
        }),
        SourceOfBusiness: new item({
          submitKey: "SourceOfBusiness",
          defaultValue: "Website",
          exampleValue: null,
          rules: [
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "", // errorMessage: "Required",
              }
            ),
          ],
        }),
        SourceOfEnquiry: new item({
          submitKey: "SourceOfEnquiry",
          // defaultValue: "DRCT",
          exampleValue: null,
          // rules: [
          // 	new ruleItem({
          // 		...generateBaseRuleData("general", "isRequired"),},{
          // 		errorMessage: "", // errorMessage: "Required",
          // 	}),
          // ],
        }),

        Duqs: new list({
          submitKey: "Duqs",
          template: {
            Id: new item({
              // componentTag:  "Id",
              submitKey: "Id",
              exampleValue: null,
            }),
            QuestionNumber: new item({
              // componentTag:  "QuestionNumber",
              submitKey: "QuestionNumber",
              exampleValue: "64",
            }),
            Response: new item({
              // componentTag:  "Response",
              submitKey: "Response",
              exampleValue: "Diesel",
            }),
            ShortResponse: new item({
              // componentTag:  "ShortResponse",
              submitKey: "ShortResponse",
              exampleValue: "D",
            }),
            ScreenPrompt: new item({
              // componentTag:  "ScreenPrompt",
              submitKey: "ScreenPrompt",
              exampleValue: null,
            }),
          },
        }),

        DrivingExperience: new set({
          submitKey: "DrivingExperience",
          items: {
            Type: new item({
              submitKey: "Type",
            }),
            YearsClaimFree: new item({
              submitKey: "YearsClaimFree",
            }),
          },
        }),

        // **************************************
        // ** Custom values
        // **************************************
        RiskData: new set({
          items: {
            Confirmation: new item({
              html: { itemData: constants.lists.yesNo },
              label: "Do you agree with these statements?",
              rules: [
                new ruleItem(generateBaseRuleData("general", "isRequired"), {
                  errorMessage: "",
                }),
                riskLogic,
              ],
            }),

            ShowAdditionalDrivers: new item({
              label: "Will any other people drive this car?",
              defaultValue: false,
              html: {
                itemData: constants.lists.yesNo,
              },
              rules: [
                new ruleItem(generateBaseRuleData("general", "isRequired"), {
                  errorMessage: "",
                }),
                riskLogic,
              ],
            }),

            VehicleExtraDetailsConfirmation: new item({
              label: "Are the following details about your car correct?",
              html: {
                itemData: constants.lists.yesNo,
              },
              rules: [riskLogic],
            }),
          },
        }),

        QuoteData: new set({
          items: {
            MainTelephoneConfirmed: new item({}),
            EmailConfirmed: new item({
              label: "What's your email address?",
              html: { maxLength: 50 },
              rules: [
                new ruleItem(generateBaseRuleData("vehicle", "emailLogic"), {
                  Email: new dataSetArgItem({
                    componentTag: "Email",
                    componentSetPath: ["..", "Proposer"],
                  }),
                  EmailConfirmed: new dataSetArgItem({
                    componentTag: "EmailConfirmed",
                    useDefaultComponentSet: true,
                  }),
                  storeName: "carStore",
                }),
              ],
            }),
          },
        }),

        // ** Below may  not be needed

        // Context: new item({
        // 	// componentTag:  "Context",
        // 	submitKey: "Context",
        // 	exampleValue: null,
        // }),

        // DrivingExperience: new item({
        // 	// componentTag:  "DrivingExperience",
        // 	submitKey: "DrivingExperience",
        // 	exampleValue: null,
        // }),

        // TrailerCover: new item({
        // 	// componentTag:  "TrailerCover",
        // 	submitKey: "TrailerCover",
        // 	exampleValue: null,
        // 	label: "Do you require trailer cover?",
        // 	html: {
        // 		itemData: constants.lists.yesNo,
        // 	},
        // 	rules: [
        // 		new ruleItem({
        // 			...generateBaseRuleData("general", "isRequired"),},{
        // 			errorMessage: "", // errorMessage: "Required",
        // 		}),
        // 	],
        // }),
        Id: new item({
          submitKey: "Id",
        }),

        ...(isMta && {
          Affinity: new item({
            submitKey: "Affinity",
            exampleValue: "XTRA",
          }),
        }),

        // PreferredDeliveryMethod: new item({
        // 	// componentTag:  "PreferredDeliveryMethod",
        // 	submitKey: "PreferredDeliveryMethod",
        // 	exampleValue: null,

        // 	// rules: [
        // 	// 	new ruleItem({
        // 	// 		...generateBaseRuleData("general", "isRequired"),},{
        // 	// 		errorMessage: "", // errorMessage: "Required",
        // 	// 	}),
        // 	// ],
        // }),

        // Enrichments: new item({
        // 	submitKey: "Enrichments",
        // 	exampleValue: null,
        // }),
        // ScratchData: new item({
        // 	submitKey: "ScratchData",
        // 	exampleValue: null,
        // }),

        // TargetPremiumInsurerName: new item({
        // 	submitKey: "TargetPremiumInsurerName",
        // 	exampleValue: null,
        // }),
        // TargetPremiumInsurerType: new item({
        // 	submitKey: "TargetPremiumInsurerType",
        // 	exampleValue: null,
        // }),
        // TargetPremium: new item({
        // 	submitKey: "TargetPremium",
        // 	exampleValue: null,
        // }),
        // PreferredCollectionDay: new item({
        // 	submitKey: "PreferredCollectionDay",
        // 	exampleValue: null,
        // }),
      },
    }),
    // SubagentCodes: new item({
    // 	// componentTag:  "SubagentCodes",
    // 	submitKey: "SubagentCodes",
    // 	exampleValue: null,
    // }),
  });

  // database.addRule(
  // 	"Risk/Proposer/Address",
  // 	"Postcode",
  // 	templates.Vehicle.logicRule
  // );
  // console.log("xxxxx database", database);
  // console.log("Created car database.");
  return database;
};

export default fnGenerateDatabase;
