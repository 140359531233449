import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";

const generate = (args = {}) => {
  const { storeName, actions, selectors } = args;

  const useSnapshotControls = (dispatch, options = {}) => {
    if (!dispatch) throw `Error in useSnapshotControls -- missing dispatch`;

    const { autoSave = false } = options;

    const autoSaveEnabled = useRef(autoSave);

    //********************************
    // EFFECT
    //********************************
    useEffect(() => {
      // console.log("useSnapshotHook", "LOADED");
      const myFn = async () => {
        if (!autoSaveEnabled.current) return;

        console.log("useSnapshotHook", "Creating");

        dispatch(actions.create());
      };
      myFn();

      return async () => {
        // if (!autoSave) return;
        // console.log("useSnapshotHook", "UNLOADED");

        if (!autoSaveEnabled.current) return;
        console.log("useSnapshotHook", "Commiting");
        await dispatch(actions.commit());
      };
    }, []);

    //********************************
    // FUNCTION
    //********************************

    const fnReset = async () => {
      autoSaveEnabled.current = false;
      await dispatch(actions.reset());
    };

    const fnCreate = async () => {
      autoSaveEnabled.current = false;
      await dispatch(actions.create());
    };

    const fnCommit = async () => {
      autoSaveEnabled.current = false;
      await dispatch(actions.commit());
    };

    const fnUndo = async () => {
      autoSaveEnabled.current = false;
      await dispatch(actions.undo());
    };
    //********************************
    // RETURN
    //********************************
    return { commit: fnCommit, undo: fnUndo, create: fnCreate, reset: fnReset };
  };
  return { useSnapshotControls };
};

export default generate;
