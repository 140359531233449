import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  createExtraReducersSet,
  extraReducersSet,
  consoleLog,
} from "../common";

const generate = (args = {}) => {
  const { storeName, sessionActions, abortChecker } = args;

  // const consoleLog = (...args) => {
  //   if (!debug) return;
  //   console.log(chalk.green.bold("REDUX:"), storeName, ":", ...args);
  // };

  const initialState = {
    sessionId: undefined,
    isInit: false,

    isInitiating: false,
    isLoading: false,
    isSaving: false,
    isRegenerating: false,
    hasLoadedData: false,
    savingMessage: undefined,
  };

  const sessionSlice = createSlice({
    name: storeName,
    initialState,
    reducers: {
      // reset: (state) => {
      //   consoleLog({ storeName, subActionName: "reset" });
      //   state.hasLoadedData = false;
      // },
    },
    extraReducers: (builder) => {
      extraReducersSet(
        builder,
        sessionActions.init,
        "init",
        {
          pending: (state) => {
            state.isInitiating = true;
            state.hasLoadedData = false;
          },
          fulfilled: (state, { payload }) => {
            // console.log("sessionActions.init.fulfilled", payload.data);
            state.isInit = true;
            state.isInitiating = false;
            state.sessionId = payload.sessionId;
            state.hasLoadedData = payload.data ? true : false;
          },
          rejected: (state, { payload }) => {
            state.isInit = true;
            state.isInitiating = false;
          },
        },
        { storeName, abortChecker }
      );
      extraReducersSet(
        builder,
        sessionActions.get,
        "get",
        {
          pending: (state) => {
            state.isLoading = true;
            state.hasLoadedData = false;
          },
          fulfilled: (state, { payload }) => {
            state.isLoading = false;
            // state.lastResponse = payload;
            state.hasLoadedData = payload ? true : false;
          },
          rejected: (state, { payload }) => {
            state.isLoading = false;
          },
        },
        { storeName, abortChecker }
      );

      extraReducersSet(
        builder,
        sessionActions.save,
        "save",
        {
          pending: (state, action) => {
            // console.log("action dsadsadsad", { action });
            // const { payload: savingMessage } = action;
            state.isSaving = true;
            state.savingMessage = action?.meta?.arg;
          },
          fulfilled: (state, { payload }) => {
            state.isSaving = false;
            state.savingMessage = undefined;
          },
          rejected: (state, { payload }) => {
            state.isSaving = false;
            state.savingMessage = undefined;
          },
        },
        { storeName, abortChecker }
      );

      extraReducersSet(
        builder,
        sessionActions.reset,
        "reset",
        {
          pending: (state, action) => {},
          fulfilled: (state, { payload }) => {
            Object.values(state).forEach((key) => {
              delete state[key];
            });

            Object.entries(initialState).forEach(([key, value]) => {
              state[key] = _.cloneDeep(value);
            });
          },
          rejected: (state, { payload }) => {},
        },
        { storeName, abortChecker }
      );

      extraReducersSet(
        builder,
        sessionActions.regenerate,
        "regenerate",
        {
          pending: (state, action) => {
            state.isRegenerating = true;
          },
          fulfilled: (state, { payload }) => {
            state.sessionId = payload.sessionId;
            state.isRegenerating = false;
          },
          rejected: (state, { payload }) => {
            state.isRegenerating = false;
          },
        },
        { storeName, abortChecker }
      );
    },
  });

  return { reducer: sessionSlice.reducer, actions: sessionSlice.actions };
};

export default generate;
