// features/Documents/DocumentsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import chalk from "chalk";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  createExtraReducersSet,
  extraReducersSet,
  consoleLog,
} from "../common";
import _ from "lodash";

const generate = (args = {}) => {
  const {
    storeName,
    privateActions,
    abortChecker,
    errorMsgFallback = "Sorry, there's been an error",
    selectors,
  } = args;

  // const restartKeepKeys = ["dataPolicy", "dataSalusBase", "startDate"];
  const restartKeepKeys = ["dataInit", "startDate"];

  const initialState = {
    statusInitiating: {},
    statusErrorMessage: {},

    // dataSalusBase: undefined,
    // dataPolicy: undefined,

    dataSavedQuotes: [],
    dataPaymentLauncher: undefined,

    dataInit: {
      policy: undefined,
      base: { salus: undefined },
    },
    dataChange: {
      base: { risk: undefined, salus: undefined },
      quote: {},
    },

    startDate: { min: undefined, max: undefined },
    paymentMethod: "F",

    dataValues: {},
  };

  const fnSetStatusInitiating = (state, key) =>
    (state.statusInitiating[key] = true);

  const fnSetStatusInitiatingComplete = (state, key) =>
    (state.statusInitiating[key] = false);

  const fnSetStatusErrorMessage = (state, key, value) =>
    (state.statusErrorMessage[key] = value);

  const fnClearStatusErrorMessage = (state, key) =>
    (state.statusErrorMessage[key] = undefined);

  const fnResetStatus = (state, key) => {
    delete state.statusInitiating[key];
    delete state.statusErrorMessage[key];
  };

  const fnResetState = (state, keeplist = []) => {
    Object.values(state).forEach((key) => {
      if (keeplist.includes(key)) return;
      delete state[key];
    });

    Object.entries(initialState).forEach(([key, value]) => {
      if (keeplist.includes(key)) return;
      state[key] = _.cloneDeep(value);
    });
  };

  const _slice = createSlice({
    name: storeName,
    initialState: initialState,
    reducers: {
      setValue(state, action) {
        const { key, value } = action.payload;
        state.dataValues[key] = value;
      },
      resetQuote(state) {
        state.dataChange.quote = {};
        fnResetStatus(state, "getQuote");
      },
      resetPaymentLauncher(state) {
        state.dataPaymentLauncher = undefined;
        fnResetStatus(state, "paymentLauncher");
      },

      // loadData(state, action) {
      //   const { policyData, salusData } = action.payload;
      //   state.dataPolicy = policyData;
      //   state.dataSalusBase = salusData;
      // },

      setStartDateLimit(state, action) {
        const { min, max } = action.payload || {};
        // console.log("dsdsd", action.payload);
        state.startDate = {
          min: min,
          max: max,
        };
      },

      updatePaymentMethod(state, action) {
        if (!["M", "F"].includes(action.payload))
          throw `Error in MTA.updatePaymentMethod -- unknown paymentMethod "${action.payload}"`;

        state.paymentMethod = action.payload;
      },
    },
    extraReducers: (builder) => {
      //**LOADDATA
      extraReducersSet(
        builder,
        privateActions.loadData,
        "loadData",
        {
          pending: (state, action) => {
            fnSetStatusInitiating(state, "loadData");
            fnClearStatusErrorMessage(state, "loadData");
          },
          fulfilled: (state, action) => {
            const { payload } = action;
            const { salusData, policyData } = payload;

            state.dataInit.policy = policyData;
            state.dataInit.base.salus = salusData;

            fnSetStatusInitiatingComplete(state, "loadData");
            // fnSetStatusErrorMessage(state, "loadData", errorMsgFallback);
          },
          rejected: (state, data) => {
            fnSetStatusInitiatingComplete(state, "loadData");
            fnSetStatusErrorMessage(
              state,
              "loadData",
              data?.error?.message || "Sorry, there's been an error"
            );
          },
        },
        { storeName, abortChecker }
      );

      //**START
      // extraReducersSet(
      //   builder,
      //   privateActions.start,
      //   "start",
      //   {
      //     pending: (state, action) => {
      //       fnSetStatusInitiating(state, "start");
      //       fnClearStatusErrorMessage(state, "start");
      //       fnResetState(state);
      //     },
      //     fulfilled: (state, action) => {
      //       const { payload } = action;

      //       const { policyData } = action.meta.arg;

      //       state.dataInit.policy = policyData;
      //       state.dataChange.base.salus = payload.salusData;
      //       state.dataChange.base.risk = payload.riskData;

      //       fnSetStatusInitiatingComplete(state, "start");
      //       // fnSetStatusErrorMessage(
      //       //   state,
      //       //   "start",
      //       //   payload.message || errorMsgFallback
      //       // );
      //     },
      //     rejected: (state, data) => {
      //       fnSetStatusInitiatingComplete(state, "start");
      //       fnSetStatusErrorMessage(
      //         state,
      //         "start",
      //         "Sorry, there's been an error"
      //       );
      //     },
      //   },
      //   { storeName, abortChecker }
      // );

      //**RESTART
      extraReducersSet(
        builder,
        privateActions.restart,
        "restart",
        {
          pending: (state, action) => {
            fnSetStatusInitiating(state, "restart");
            fnClearStatusErrorMessage(state, "restart");
            fnResetState(state, restartKeepKeys);
          },
          fulfilled: (state, action) => {
            const { payload } = action;
            fnSetStatusInitiatingComplete(state, "restart");

            state.dataChange.base.salus = payload.salusData;
            state.dataChange.base.risk = payload.riskData;

            // console.log("dsadsadsad", selectors.riskStore.getStoreState(state));
          },
          rejected: (state, data) => {
            // console.log("ddddddd", data.error.message);

            // if (data.error.message) throw `dddd  ${data.error.message}`;

            fnSetStatusInitiatingComplete(state, "restart");
            fnSetStatusErrorMessage(
              state,
              "restart",
              data?.error?.message || "Sorry, there's been an error"
            );
          },
        },
        { storeName, abortChecker }
      );

      //**reset
      extraReducersSet(
        builder,
        privateActions.reset,
        "reset",
        {
          pending: (state, action) => {
            fnSetStatusInitiating(state, "reset");
            fnClearStatusErrorMessage(state, "reset");
          },
          fulfilled: (state, action) => {
            fnSetStatusInitiatingComplete(state, "reset");

            // Because we can't "return initialState;"
            fnResetState(state);
          },
          rejected: (state, data) => {
            fnSetStatusInitiatingComplete(state, "reset");
            fnSetStatusErrorMessage(
              state,
              "reset",
              "Sorry, there's been an error."
            );
          },
        },
        { storeName, abortChecker }
      );

      //**getQuote
      extraReducersSet(
        builder,
        privateActions.getQuote,
        "getQuote",
        {
          pending: (state, action) => {
            fnSetStatusInitiating(state, "getQuote");
            fnClearStatusErrorMessage(state, "getQuote");
            state.dataChange.quote = {};
          },
          fulfilled: (state, action) => {
            fnSetStatusInitiatingComplete(state, "getQuote");

            const {
              payload: { response, quotePayload },
            } = action;
            // console.log("dqdadad", { response, quotePayload });

            state.dataChange.quote.response = response.data;
            state.dataChange.quote.payload = quotePayload?.salusData;

            if (!response.isSuccess)
              fnSetStatusErrorMessage(
                state,
                "getQuote",
                response.message || errorMsgFallback
              );
          },
          rejected: (state, data) => {
            fnSetStatusInitiatingComplete(state, "getQuote");
            fnSetStatusErrorMessage(
              state,
              "getQuote",
              "Sorry, there's been an error."
            );

            state.isQuoting = false;
          },
        },
        { storeName, abortChecker }
      );

      //**getSavedQuotes
      extraReducersSet(
        builder,
        privateActions.getSavedQuotes,
        "getSavedQuotes",
        {
          pending: (state, action) => {
            fnSetStatusInitiating(state, "getSavedQuotes");
            fnClearStatusErrorMessage(state, "getSavedQuotes");
            state.dataSavedQuotes = [];
          },
          fulfilled: (state, action) => {
            fnSetStatusInitiatingComplete(state, "getSavedQuotes");

            const { payload } = action;

            state.dataSavedQuotes = payload.savedQuotes;

            if (!payload.isSuccess)
              fnSetStatusErrorMessage(
                state,
                "getSavedQuotes",
                payload.message || errorMsgFallback
              );
          },
          rejected: (state, data) => {
            fnSetStatusInitiatingComplete(state, "getSavedQuotes");
            fnSetStatusErrorMessage(
              state,
              "getSavedQuotes",
              "Sorry, there's been an error."
            );

            state.isQuoting = false;
          },
        },
        { storeName, abortChecker }
      );

      //**paymentLauncher
      extraReducersSet(
        builder,
        privateActions.paymentLauncher,
        "paymentLauncher",
        {
          pending: (state, action) => {
            fnSetStatusInitiating(state, "paymentLauncher");
            fnClearStatusErrorMessage(state, "paymentLauncher");
            state.dataPaymentLauncher = undefined;
          },
          fulfilled: (state, action) => {
            const { payload } = action;
            // console.log("dadadad", payload);

            fnSetStatusInitiatingComplete(state, "paymentLauncher");
            state.dataPaymentLauncher = payload.data;

            if (!payload.isSuccess) {
              fnSetStatusErrorMessage(
                state,
                "paymentLauncher",
                payload.message || errorMsgFallback
              );
            }
          },
          rejected: (state, data) => {
            fnSetStatusInitiatingComplete(state, "paymentLauncher");
            fnSetStatusErrorMessage(
              state,
              "paymentLauncher",
              "Sorry, there's been an error."
            );
          },
        },
        { storeName, abortChecker }
      );

      //**Misc Items

      [
        "updateRisk",
        "updateRiskByGroup",
        "updateErrorsShowByGroup",
        "registerRiskItem",
      ].forEach((key) => {
        if (!(key in privateActions)) return;

        extraReducersSet(
          builder,
          privateActions[key],
          key,
          {
            pending: (state) => {
              fnSetStatusInitiating(state, key);
              fnClearStatusErrorMessage(state, key);
            },
            fulfilled: (state) => {
              fnSetStatusInitiatingComplete(state, key);
            },
            rejected: (state) => {
              fnSetStatusInitiatingComplete(state, key);
              fnSetStatusErrorMessage(
                state,
                key,
                "Sorry, there's been an error."
              );
            },
          },
          { storeName, abortChecker }
        );
      });

      //**getPurchaseResponse
      // extraReducersSet(
      //   builder,
      //   privateActions.getPurchaseResponse,
      //   "getPurchaseResponse",
      //   {
      //     pending: (state, action) => {
      //       fnSetStatusInitiating(state, "getPurchaseResponse");
      //       fnClearStatusErrorMessage(state, "getPurchaseResponse");
      //       state.dataWrapup = undefined;
      //     },
      //     fulfilled: (state, action) => {
      //       const { payload } = action;

      //       state.dataWrapup = { ...payload.data, isAP: true };
      //       fnSetStatusInitiatingComplete(state, "getPurchaseResponse");
      //     },
      //     rejected: (state, data) => {
      //       fnSetStatusInitiatingComplete(state, "getPurchaseResponse");
      //       fnSetStatusErrorMessage(
      //         state,
      //         "getPurchaseResponse",
      //         "Sorry, there's been an error."
      //       );
      //     },
      //   },
      //   { storeName, abortChecker }
      // );

      // extraReducersSet(builder,
      //   privateActions.end,
      //   "end",
      //   {
      //     pending: (state, action) => {},
      //     fulfilled: (state, action) => {
      //       const { payload } = action;
      //     },
      //     rejected: (state, data) => {},
      //   },
      //   { storeName, abortChecker }
      // ),
    },
  });

  return { reducer: _slice.reducer, actions: _slice.actions };
};

export default generate;
