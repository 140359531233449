// features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import JSONhelper from "@library/common/helpers/misc/json";
import _ from "lodash";

import { createFnGenerateActionWithThunkAPI } from "../common";

const generateStore = (args = {}) => {
  const { storeName, services, snapshotSelectors, watchlist } = args;

  const fnGenerateActionWithThunkApi =
    createFnGenerateActionWithThunkAPI(storeName);

  //save -> create
  // rollback -> undo
  // purge -> commit/ commitAll
  const create = fnGenerateActionWithThunkApi(
    "create",
    async (payload, thunkApi) => {
      const state = thunkApi.getState();
      const snapshot = _.pick(state, watchlist);
      return snapshot;
    }
  );

  const undo = fnGenerateActionWithThunkApi(
    "undo",
    async (payload, thunkApi) => {
      const state = thunkApi.getState();

      const historyLength = state[storeName].history.length;
      if (historyLength === 0) return;

      const newData = state[storeName].history[historyLength - 1];
      return newData;
    }
  );

  const commit = fnGenerateActionWithThunkApi(
    "commit",
    async (payload, thunkApi) => {}
  );

  const commitAll = fnGenerateActionWithThunkApi(
    "commitAll",
    async (payload, thunkApi) => {}
  );

  return { create, undo, commitAll, commit };
  // const init = fnGenerateAction("init", services.init);
  // const get = fnGenerateAction("get", services.get);

  // const save = fnGenerateActionWithThunkApi(
  //   "save",
  //   async (payload, thunkApi) => {
  //     const state = thunkApi.getState();
  //     const snapshotId = snapshotSelectors.getSnapshotId(state);
  //     const newPayload = { data: JSONhelper.stringify(state), snapshotId };
  //     return await services.save(newPayload);
  //   }
  // );

  // const initAndGet = (params) => async (dispatch) => {
  //   await dispatch(init(params));
  //   await dispatch(get(params));
  // };

  // return { init, get, save, initAndGet };
};

export default generateStore;
