import React, { useState, useEffect, useCallback } from "react";
import { useIdleTimer } from "react-idle-timer";

const useSessionTimeout = (args = {}) => {
  const {
    onTimeout = () => {},
    onClearTimeout = () => {},
    timeout = 1000 * 60 * 30, //Default to 30 minutes
  } = args;

  const [isTimeout, setIsTimeout] = useState(false);

  //   console.log("useSessionTimeout isTimeout", isTimeout);

  const { getRemainingTime } = useIdleTimer({
    onIdle: async () => {
      await onTimeout();
      setIsTimeout(true);
    },
    // onActive,
    // onAction,
    timeout,
    throttle: 500,
  });

  const fnClearTimeout = async () => {
    await Promise.resolve(onClearTimeout());
    setIsTimeout(false);
  };

  return [isTimeout, fnClearTimeout];
};

export default useSessionTimeout;
