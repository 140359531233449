import React from "react";
import { generateCypressProps } from "siteFunctions/cypress";

const Card = (props) => {
  const {
    hasBorder = false,
    className,
    classNameHeader,
    classNameBody,
    classNameFooter,
    header,
    children,
    footer,
  } = props;

  const classData = {
    container: [
      "lctr-card",
      "card-fb",
      "card",
      hasBorder ? "" : "no-border",
      className,
    ].toClassName(),
    header: ["lctr-card-header", "card-header", classNameHeader].toClassName(),
    body: ["lctr-card-body", "card-body", classNameBody].toClassName(),
    footer: ["lctr-card-footer", "card-footer", classNameFooter].toClassName(),
  };

  return (
    <div
      className={classData.container}
      {...generateCypressProps("card", props)}
    >
      {header && <div className={classData.header}>{header}</div>}
      {children && <div className={classData.body}>{children}</div>}
      {footer && <div className={classData.footer}>{footer}</div>}
    </div>
  );
};

export default Card;
