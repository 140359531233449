import dayjs from "dayjs";
import dataHelper from "@library/common/database/rules/helpers/dataHelper";

const fnGenerateTitleGender = (function () {
  let lookupLists = undefined;

  require("siteService")
    .default.lookups.person.titleWithoutSessionId()
    .then((response) => {
      if (!response) return;
      lookupLists = response
        .map((x) => x.value)
        .map((x) => ({ ...JSON.parse(x), string: x }));
    });

  const retFn = (title) => {
    if (!lookupLists) return undefined;
    if (!title) return;
    return lookupLists.find(
      (x) => x.title.toUpperCase() === title.toUpperCase()
    )?.string;
  };
  return retFn;
})();

export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper")
      .default,
  } = args;

  const dh = new dataHelper({
    mappings: ["Title", "Gender", "TitleGender", "Salutation", "Surname"],
    dataSet,
    functionArgs,
  });

  // console.log("nameLogic", dh.current.componentTag, dh.dataSet.locklist);

  // ** salutation
  if (dh.isUpdated("Title", "Surname")) {
    if (
      dh.getValue("Title") !== undefined &&
      dh.getValue("Surname") !== undefined
    ) {
      dh.setData(
        "value",
        "Salutation",
        `${dh.getValue("Title")} ${dh.getValue("Surname")}`
      );
    } else {
      dh.setData("value", "Salutation", undefined);
    }
  }

  if (!dh.isPostRegistration() && dh.isUpdated("TitleGender")) {
    const JSONString = dh.getValue("TitleGender");

    const JSONData =
      JSONString === undefined ? undefined : JSON.parse(JSONString);

    if (JSONData) {
      dh.setData("value", "Title", JSONData["title"]);
      dh.setData("value", "Gender", JSONData["gender"]);
    } else {
      dh.setData("value", "Title", undefined);
      dh.setData("value", "Gender", undefined);
    }
  }

  // 3408 -- handle the GENDER box
  {
    const showGender = (function () {
      const JSONString = dh.getValue("TitleGender");
      if (!JSONString) return false;
      const JSONData = JSON.parse(JSONString);
      if ("gender" in JSONData) return false;
      return true;
    })();

    dh.setVisible("Gender", showGender);
    if (showGender) dh.addErrorIfEmpty("Gender", "");
  }

  if (dh.isPostRegistration()) {
    // {"title":"Dr","gender":"M"}
    const Title = dh.getValue("Title");
    const Gender = dh.getValue("Gender");

    // console.log("dddd nameLogic", dh.errorKey, dh, Title, Gender);

    if (Title !== undefined && Gender !== undefined) {
      // console.log("TitleGender -- setting to something");
      dh.setData("value", "TitleGender", fnGenerateTitleGender(Title));

      // dh.setData(
      //   "value",
      //   "TitleGender",
      //   `{"title":"${Title}","gender":"${Gender}"}`
      // );
    } else {
      // console.log("TitltGender -- setting to undefined");

      dh.setData("value", "TitleGender", undefined);
    }
  }

  dh.commit();
};
