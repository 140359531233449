// WIP code for this. Not yet finished, but I'm putting it here for reference:

import React, { useCallback, useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import ButtonLabel from "@material-ui/icons/Search";

export default (args = {}) => {
  return () => {
    throw `Vehicle.edit not yet implemented`;
  };
  if (false) {
    const {
      database,
      selectors,
      actions,
      siteComponents,
      storeComponentsCustom,
      storeComponentsSite,
      classes,
      services,
      functions,
    } = args;

    const {
      Button,
      RadioButtonsWebService,
      DropDownTypeWebService,
      DropDownType,
      SubPanel,
    } = require("../../../../getResources").default(args, "Vehicle");

    const { FormGroup } = storeComponentsCustom;

    const DefaultEditTemplate = (props) => {
      [
        Button,
        RadioButtonsWebService,
        DropDownTypeWebService,
        DropDownType,
        SubPanel,
        FormGroup,
      ].forEach((x, i) => {
        if (!x)
          throw `Error -- missing required component in Vehicle Edit at index ${i}`;
      });

      const {
        isValid,
        isSearching,
        controlsDisabled,
        policyTypeHeading,
        listMakeOnChange,
        searchMake,
        listMake,
        setSearchModel,
        searchModel,
        listModel,
        setSearchTransmission,
        searchTransmission,
        transmissionService,
        setSearchFuelType,
        searchFuelType,
        fueltypeService,
        isSearchingYear,
        listYear,
        setSearchYear,
        searchYear,
        isValidWithoutYear,
        vehicleList,
        chosenVehicleOnChange,
        chosenVehicle,
        onChangeVehicle,
        onCancel,
        LoadingComponent,
      } = props;

      return (
        <div className="col-12">
          <SubPanel>
            <FormGroup isStoreComponent={true}>
              <strong>
                You can enter your car details manually below but we'll need
                your registration before you can purchase a policy.
              </strong>
            </FormGroup>
            <FormGroup isStoreComponent={true}>
              <div className="col-12">
                <label className="form-label">
                  Make of {policyTypeHeading}
                </label>
              </div>
              <div className="col-md-7">
                <DropDownTypeWebService
                  onChange={(value) => listMakeOnChange(value)}
                  value={searchMake}
                  itemData={listMake}
                  disabled={controlsDisabled}
                />
              </div>
            </FormGroup>

            <FormGroup isStoreComponent={true}>
              <div className="col-12">
                <label className="form-label">Model</label>
              </div>
              <div className="col-md-7">
                <DropDownTypeWebService
                  onChange={(value) => setSearchModel(value)}
                  value={searchModel}
                  itemData={listModel || []}
                  disabled={searchMake === undefined || controlsDisabled}
                />
              </div>
            </FormGroup>

            <FormGroup isStoreComponent={true}>
              <div className="col-12">
                <label className="form-label">Transmission</label>
              </div>
              <div className="col-md-6">
                <RadioButtonsWebService
                  onChange={(value) => setSearchTransmission(value)}
                  value={searchTransmission}
                  DataFunction={transmissionService}
                  disabled={controlsDisabled}
                />
              </div>
            </FormGroup>
            <FormGroup isStoreComponent={true}>
              <div className="col-12">
                <label className="form-label">Fuel Type</label>
              </div>
              <div className="col-md-6">
                <RadioButtonsWebService
                  onChange={(value) => setSearchFuelType(value)}
                  value={searchFuelType}
                  DataFunction={fueltypeService}
                  disabled={controlsDisabled}
                />
              </div>
            </FormGroup>

            {isSearchingYear === true && (
              <div className="col-12">
                <LoadingComponent />
              </div>
            )}
            {listYear && listYear.length !== 0 && (
              <FormGroup isStoreComponent={true}>
                <div className="col-12">
                  <label className="form-label">Year of manufacture</label>
                </div>
                <div className="col-md-4">
                  <DropDownTypeWebService
                    onChange={(value) => setSearchYear(value)}
                    value={searchYear}
                    itemData={listYear || []}
                    // DataFunction={YearOfManufactureService}
                    disabled={controlsDisabled}
                  />
                </div>
              </FormGroup>
            )}

            {isSearchingYear === false &&
              isValidWithoutYear &&
              (!listYear || listYear.length === 0) && (
                <FormGroup isStoreComponent={true}>
                  <div className="col-12">
                    <div>Sorry, we couldn't find your vehicle.</div>
                  </div>
                </FormGroup>
              )}

            {isSearching === true && (
              <FormGroup isStoreComponent={true}>
                <div className="col-12">
                  <LoadingComponent />
                </div>
              </FormGroup>
            )}

            {isSearching === false &&
              isValid &&
              (!vehicleList || vehicleList.length === 0) && (
                <FormGroup isStoreComponent={true}>
                  <div className="col-12">
                    <div>Sorry, we couldn't find your vehicle.</div>
                  </div>
                </FormGroup>
              )}

            {isSearching === false &&
              vehicleList &&
              vehicleList.length !== 0 && (
                <FormGroup isStoreComponent={true}>
                  <div className="col-12">
                    <label className="form-label">
                      Please select your {policyTypeHeading}
                    </label>
                  </div>
                  <div className="col-md-10">
                    <DropDownType
                      onChange={chosenVehicleOnChange}
                      value={chosenVehicle}
                      itemData={vehicleList}
                    />
                  </div>
                </FormGroup>
              )}
          </SubPanel>
          <div className="row mx-0 mx-md-3 page-navigation justify-content-center">
            <div className="col-12 col-md-6 pb-3 pb-md-0 text-end order-1 order-md-2">
              {chosenVehicle && (
                <Button
                  type="button"
                  className="btn-lg btn-success w-100 w-md-auto"
                  label="Continue"
                  onClick={() => {
                    onChangeVehicle(JSON.parse(chosenVehicle));
                  }}
                />
              )}
            </div>

            <div className="col-10 col-md-6 order-2 order-md-1">
              <Button
                label="Go back to registration search"
                className="btn btn-link btn-sm w-100 w-md-auto"
                onClick={onCancel}
              />
            </div>
          </div>
        </div>
      );
    };

    //** MAIN component
    return functions.composePage({
      propPathList: [],
      createSandbox: false,
      Component: (props) => {
        const {
          DBitems,
          onChangeVehicle,
          onCancel,
          group,

          searchService,
          searchServiceManualManufacturer,
          searchServiceManualResults,
          fueltypeService,
          transmissionService,
          YearOfManufactureService,
          policyTypeHeading,
          LoadingComponent,
          EditTemplate = DefaultEditTemplate,
        } = props;

        //modify DBitems to add "group", so that the "disable button" code works.
        // const _DBitems = useMemo(() => {
        // 	let retObj = {};
        // 	Object.entries(DBitems).forEach(([key, data]) => {
        // 		retObj[key] = { ...data, group };
        // 	});

        // 	return retObj;
        // }, [group]);

        const [rawData, setRawData] = useState();
        const [listYear, setListYear] = useState(undefined);
        const [listMake, setListMake] = useState();
        const [listModel, setListModel] = useState();

        const [searchMake, setSearchMake] = useState();
        const [searchModel, setSearchModel] = useState();
        const [searchTransmission, setSearchTransmission] = useState();
        const [searchFuelType, setSearchFuelType] = useState();
        const [searchYear, setSearchYear] = useState();
        const [vehicleList, setVehicleList] = useState();

        const [isSearching, setIsSearching] = useState(undefined); //NOTE: undefined == "haven't searched yet"
        const [isSearchingYear, setIsSearchingYear] = useState(undefined);

        const [chosenVehicle, setChosenVehicle] = useState();

        const controlsDisabled = isSearching || isSearchingYear;
        const isValid = [
          searchMake,
          searchModel,
          searchTransmission,
          searchFuelType,
          searchYear,
        ].every((x) => x !== undefined);
        const isValidWithoutYear = [
          searchMake,
          searchModel,
          searchTransmission,
          searchFuelType,
        ].every((x) => x !== undefined);

        useEffect(() => {
          searchServiceManualManufacturer().then((response) => {
            if (!response) return;
            // console.log("response", response);
            // value, label, options
            const _listMake = response.map(({ value, label }) => ({
              value,
              label,
            }));

            setRawData(response);
            setListMake(_listMake);
            setListModel(undefined);
            setSearchMake(undefined);
            setSearchModel(undefined);
          });
        }, []);

        useEffect(() => {
          if (isValidWithoutYear) {
            setIsSearchingYear(true);

            const searchPayload = {
              Make: searchMake,
              Model: searchModel,
              Transmission: searchTransmission,
              FuelType: searchFuelType,
              // Year: _.toInteger(searchYear),
            };
            searchServiceManualResults(searchPayload).then((response) => {
              if (!response || response.length === 0) {
                setSearchYear(undefined);
                setListYear(undefined);
                setIsSearchingYear(false);
                return;
              }

              // old way -- removed by #724
              // const yearList = response.map((x) => JSON.parse(x.value).StartYear);

              const yearList = (function () {
                const curYear = new Date().getFullYear();
                const rawData = response.map((x) => JSON.parse(x.value));

                const start = Math.min(...rawData.map((x) => x.StartYear));
                const end = Math.max(
                  ...rawData.map((x) => x.EndYear || curYear)
                );

                const length = Math.floor(Math.abs(end - start)) + 1;
                return Array.from(Array(length), (x, index) => start + index);
              })();

              // console.log("dddd", yearList, minYear, maxYear)

              const newListYear = _.reverse(_.sortBy(_.uniq(yearList))).map(
                (x) => ({ label: `${x}`, value: `${x}` })
              );

              //** Chain version -- not liked by: babel-plugin-lodash.*/
              // const newListYear = _(yearList)
              // 	.uniq()
              // 	.sortBy()
              // 	.reverse()
              // 	.value()
              // 	.map((x) => ({ label: `${x}`, value: `${x}` }));

              setSearchYear(undefined);
              setListYear(newListYear);
              setIsSearchingYear(false);
            });
          } else {
            setSearchYear(undefined);
            setListYear(undefined);
          }
        }, [searchMake, searchModel, searchTransmission, searchFuelType]);

        useEffect(() => {
          if (isValid) {
            const searchPayload = {
              Make: searchMake,
              Model: searchModel,
              Transmission: searchTransmission,
              FuelType: searchFuelType,
              Year: _.toInteger(searchYear),
            };
            setIsSearching(true);

            searchServiceManualResults(searchPayload).then((response) => {
              setVehicleList(response);
              setIsSearching(false);
              // console.log("found vehicles:", response);
            });
          } else {
            setChosenVehicle(undefined);
            setVehicleList(undefined);
          }
        }, [
          searchMake,
          searchModel,
          searchTransmission,
          searchFuelType,
          searchYear,
        ]);

        const chosenVehicleOnChange = (value) => {
          setChosenVehicle(value);
        };

        const listMakeOnChange = (value) => {
          setSearchMake(value);
          listModelRefresh(value);
          setSearchModel(undefined);
        };

        const listModelRefresh = (value) => {
          const _listModel = rawData
            .filter((x) => x.value === value)
            .flatMap((x) => x.options);
          setListModel(_listModel ? _listModel : undefined);
        };

        if (!rawData || !listMake) return null;

        const editTemplateProps = {
          isValid,
          controlsDisabled,
          isSearching,
          policyTypeHeading,
          listMakeOnChange,
          searchMake,
          listMake,
          setSearchModel,
          searchModel,
          listModel,
          setSearchTransmission,
          searchTransmission,
          transmissionService,
          setSearchFuelType,
          searchFuelType,
          fueltypeService,
          isSearchingYear,
          listYear,
          setSearchYear,
          searchYear,
          isValidWithoutYear,
          vehicleList,
          chosenVehicleOnChange,
          chosenVehicle,
          onChangeVehicle,
          onCancel,
          LoadingComponent,
        };
        return <EditTemplate {...editTemplateProps} />;
      },
    });
  }
};
