import React from "react";

import { PanelMta } from "siteComponents";

const PleaseWait = ({ children }) => {
  return (
    <PanelMta className="mta-session-refreshing">
      <div className="row">
        <div className="col-12 d-flex justify-content-center mb-4">
          <span className="icon icon-clock" />
        </div>
        <div className="col-12 d-flex justify-content-center">{children}</div>
      </div>
    </PanelMta>
  );
};

export default PleaseWait;
