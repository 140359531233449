import { list, set, item } from "@library/common/database/classes";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";

import constants from "@library/common/constants/database";
import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import generateBaseRuleData from "../../../generateBaseRuleData";
import Name from "./Name";
import Occupation from "./Occupation";
import Licence from "./Licence";
import Claim from "./Claim";
import DateOfBirth from "./DateOfBirth";
import generateAddress from "./Address";
import generateTelephone from "./Telephone";

import regExConstants from "@library/common/constants/regEx";
import templatePerson from "./Person";
// import deepmerge from "deepmerge"; can't use this as it doesn't merge classes

export default {
  generateSet: (args = {}, itemArgs = {}) => {
    require("@library/common/helpers/misc/argChecker")(itemArgs, [
      "AdditionalInsuredSet",
      "AdditionalInsuredSet_RelationshipToProposer_TAG",
    ]);

    const {
      AdditionalInsuredSet,
      AdditionalInsuredSet_RelationshipToProposer_TAG,
      defaultLicenceCategoryValue,
      isMta: isMta = false,
    } = itemArgs;

    const {
      items: argItems = {},
      modifyItem: argModifyItems = [],
      ...argOther
    } = args;

    let setProps = {
      items: {
        ...templatePerson.generateKeys(
          {},
          {
            msgDateOfBirth:
              "You must be 17 on or before the policy start date.",
            defaultLicenceCategoryValue,
            defaultEmailAddress: undefined,
            isMta: isMta,
          }
        ),
        ...((isMta && {
          Id: new item({
            submitKey: "Id",
          }),
        }) ||
          {}),
        IsExistingClient: new item({
          submitKey: "IsExistingClient",
          exampleValue: "NotKnown",
          // defaultValue: false,
          // // may be true on portal
          // rules: [
          // 	new ruleItem({
          // 		...generateBaseRuleData("general", "isRequired"),},{
          // 		errorMessage: "", // errorMessage: "Required",
          // 	}),
          // ],
        }),

        ClientCategory: new item({
          submitKey: "ClientCategory",
          // defaultValue: "STANDARD",
          // rules: [
          // 	new ruleItem({
          // 		...generateBaseRuleData("general", "isRequired"),},{
          // 		errorMessage: "", // errorMessage: "Required",
          // 	}),
          // ],
        }),
        ClientStatus: new item({
          submitKey: "ClientStatus",
          defaultValue: "Prospect",
          rules: [
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "", // errorMessage: "Required",
              }
            ),
          ],
        }),

        NumberOfOtherVehicles: new item({
          submitKey: "NumberOfOtherVehicles",
          defaultValue: "0",
          rules: [
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "", // errorMessage: "Required",
              }
            ),
          ],
        }),
        Address: generateAddress({
          submitKey: "Address",
        }),

        MainTelephone: generateTelephone({
          submitKey: "MainTelephone",
        }),

        PaymentMethod: new item({
          submitKey: "PaymentMethod",
          label: "How do you usually pay for your car insurance?",
          html: {
            itemData: [
              { label: "Annually", value: "F" },
              { label: "Monthly", value: "M" },
            ],
          },
          // defaultValue: "F",
          rules: [
            // paymentMethodLogic,
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "", // errorMessage: "Required",
              }
            ),
          ],
        }),
        DriverType: new item({
          submitKey: "DriverType",
          defaultValue: "Prpsr",
          rules: [
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "", // errorMessage: "Required",
              }
            ),
          ],
        }),
        ...argItems,
      },

      modifyItem: [
        {
          componentTag: "RelationshipToProposer",
          key: "defaultValue",
          data: "Proposer",
        },
        // disabled: handled by vehicleUseLogic
        // {
        // 	componentTag: "VehicleUse",
        // 	key: "defaultValue",
        // 	data: "MainUser",
        // },
        {
          path: "Address",
          componentTag: "Postcode",
          key: "rules",
          data: new ruleItem(
            {
              ...generateBaseRuleData("general", "runRules"),
            },
            {
              componentTagList: ["KeptAtHomeQuestion"],
            }
          ),
        },
        {
          componentTag: "DateOfBirth",
          key: "rules",
          data: new ruleItem(
            {
              ...generateBaseRuleData("general", "runRules"),
            },
            {
              componentTagList: ["RelationshipToProposer"],
            }
          ),
        },
        {
          componentTag: "Email",
          key: "rules",
          data: new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "",
              // errorMessage: "Please enter an e-mail.",
            }
          ),
        },
        {
          componentTag: "Email",
          key: "rules",
          data: new ruleItem(
            {
              ...generateBaseRuleData("general", "regEx"),
            },
            {
              regEx: regExConstants.misc.email,
              errorMessage: "", //"Please enter a valid email address.",
            }
          ),
        },
        {
          componentTag: "MaritalStatus",
          key: "rules",
          data: new ruleItem(
            {
              ...generateBaseRuleData("vehicle", "proposerLogic"),
            },
            {
              MaritalStatus: new dataSetArgItem({
                componentTag: "MaritalStatus",
                useDefaultComponentSet: true,
              }),
              AdditionalInsuredSet,
              AdditionalInsuredSet_RelationshipToProposer_TAG,
            }
          ),
        },

        ...argModifyItems,
      ],
      ...argOther,
    };

    // console.log("setProps", setProps);
    return new set(setProps);
  },
};
