import React, { useState } from "react";
import { ButtonToggle } from "../../Buttons";
import { generateCypressProps } from "siteFunctions/cypress";
import Card from "./views/Card";
import Expand from "./views/Expand";


const TabPanel = (props) => {
  const { data = [], cardHeaderText } = props;

  // ** ERROR CHECKING **
  if (data.length === 0) throw `Error in TabPanel -- missing data`;

  data.forEach((x) => {
    if (!("label" in x))
      throw `Error in TabPanel -- incorrectly formatted data`;
    if (!("data" in x))
      throw `Error in TabPanel --  incorrectly formatted data`;
  });

  // ** Variables **
  const [curIdx, setCurIdx] = useState(0);

  // map the keys to keep React happy
  // .map(({ data }, i) => <span key={i}>{data}</span>);

  const validTabs = data.filter((x) => x.data != undefined);
  const selectedData = validTabs[curIdx].data;

  // console.log("curIdx", curIdx);
  // console.log("Selected Data: ", selectedData);
  // console.log("Data: ", data);
  // console.log("Valid Tabs: ", validTabs);

  return (
    <div className="tab-panel" {...generateCypressProps("tab-panel", props)}>
      <div className="d-md-none tab-panel-mobile">
        <Expand
          validTabs={validTabs}
          selectedData={selectedData}
          setCurIdx={setCurIdx}
          curIdx={curIdx}
          cardHeaderText={cardHeaderText}
        />
      </div>
      <div className="d-none d-md-block tab-panel-card">
        <Card
          validTabs={validTabs}
          selectedData={selectedData}
          setCurIdx={setCurIdx}
          curIdx={curIdx}
          cardHeaderText={cardHeaderText}
        />
      </div>
    </div>
  );
};

export default TabPanel;
