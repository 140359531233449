import _ from "lodash";

const generate = (args = {}) => {
  const { storeName, snapshotActions, excludeKeys = [] } = args;

  return (createStore) => (reducer, initialState, enhancer) => {
    const snapshotReducer = (state = initialState, action) => {
      const { payload = {} } = action;

      switch (action.type) {
        case `${storeName}/undo/fulfilled`: {
          const retState = { ...state, ...payload };
          return reducer(retState, action);
        }

        default:
          return reducer(state, action);
      }
    };

    return createStore(snapshotReducer, initialState, enhancer);
  };
};

export default generate;
