import dayjs from "dayjs";

import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import * as constants from "@library/common/constants/salus";
// proposerLogic
export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper").default,
  } = args;

  require("@library/common/helpers/misc/argChecker")(functionArgs, [
    "AdditionalInsuredSet",
    "AdditionalInsuredSet_RelationshipToProposer_TAG",
  ]);

  const dh = new dataHelper({
    logging: false,
    mappings: ["MaritalStatus"],
    dataSet,
    functionArgs,
  });

  const {
    AdditionalInsuredSet,
    AdditionalInsuredSet_RelationshipToProposer_TAG,
  } = functionArgs;

  const addDriverTree =
    dh.dataSet.getTree({
      argItem: AdditionalInsuredSet,
      componentTagHitlist: [AdditionalInsuredSet_RelationshipToProposer_TAG],
    }) || [];

  // ********************************************
  // ** (FOR RECALL and DIRECT) Set the marital status based on the RelationshipToProposer
  // ********************************************
  if (false) {
    const newMartialStatus = (function () {
      if (
        addDriverTree.some(
          (x) =>
            x[AdditionalInsuredSet_RelationshipToProposer_TAG] ===
            constants.relationship.civilPartner
        )
      )
        return constants.maritalStatus.civilPartnered;

      if (
        addDriverTree.some(
          (x) =>
            x[AdditionalInsuredSet_RelationshipToProposer_TAG] ===
            constants.relationship.spouse
        )
      )
        return constants.maritalStatus.married;

      return constants.maritalStatus.single;
    })();

    console.log("proposerLogic:", addDriverTree, newMartialStatus);
    dh.setValue("MaritalStatus", newMartialStatus);
  }

  if (dh.isPostRecall()) return;

  // call the rule(s) on all additional drivers "RelationshipToProposer"
  addDriverTree.forEach((x) => {
    //dataSetArgItem
    const componentSet = x.componentSet;
    const componentTag = AdditionalInsuredSet_RelationshipToProposer_TAG;
    const componentId = x.data[componentTag].componentId;
    const argItem = new dataSetArgItem({
      componentSet,
      componentTag,
      componentId,
    });
    // console.log("....driver:", argItem);
    dataSet.dependencyAdd(argItem);
  });

  dh.commit();
};
