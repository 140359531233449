import riskUtils from "./risk";
import _ from "lodash";
const fnOutputErrorInfo = (...args) => {
  console.log("********************************************");
  console.log("ERRORINFO:", ...args);
  console.log("********************************************");
};
const _fnGetTemplateData = (template, path = []) => {
  const emptyItem = { found: false, data: undefined };

  if (!_.isArray(path)) {
    console.log("ERROR INFO: ", { path });
    throw `Error in _fnGetTemplateData -- path is NOT an array`;
  }
  if (path.length === 0) return emptyItem;

  const _fnRecursive = (templateNode, subPath = [], level = 1) => {
    const [pathFirst, ...pathRest] = subPath;

    // if (subPath.join("/").startsWith("Risk/AdditionalInsuredSet")) {
    //   console.log("xxxxxx", path.join("/"), subPath.join("/"), {
    //     template,
    //     subPath,
    //     templateNode,
    //   });
    // }

    if (subPath.length === 0) {
      // console.log("dddddd", path.join("/"), { templateNode, template });
      if (_.isObject(templateNode) && templateNode._isTemplateDataNode === true)
        return { found: true, data: templateNode };

      return { found: false, data: undefined };
    }

    //Is Array
    if (riskUtils.searchPath.array.isArrayWithIndex(pathFirst)) {
      const _newPath = riskUtils.searchPath.array
        .parse(pathFirst)
        .path?.concat("[]");

      if (!_newPath) {
        console.log("ERROR INFO:", { templateNode, subPath, level });
        throw `Error in _fnGetTemplateData "${path.join(
          "/"
        )} -- can't parse path info"`;
      }

      if (!(_newPath in templateNode)) {
        return emptyItem;
      }
      return _fnRecursive(templateNode[_newPath], pathRest, level + 1);
    }

    //Is object
    if (pathFirst in templateNode) {
      return _fnRecursive(templateNode[pathFirst], pathRest, level + 1);
    }

    return emptyItem;
    {
      const [pathFirst, ...pathRest] = subPath;

      if (subPath.length === 1) {
        console.log("TTTTTT", subPath.join("/"), { templateNode, pathFirst });
        if (_.isObject(templateNode)) {
          if (pathFirst in templateNode)
            return { found: true, data: templateNode[pathFirst] };

          return emptyItem;
        }

        console.log("ERROR INFO:", { subPath, templateNode });
        throw `Error in _fnGetTemplateData -- can't find object at root for "${subPath.join(
          "/"
        )}"`;
      }

      if (subPath.length >= 2) {
        if (!_.isObject(templateNode)) {
          console.log("ERROR INFO:", subPath.join("/"), {
            template,
            templateNode,
            level,
          });
          throw `Error in _fnGetTemplateData -- not an object "${pathFirst}" - "${path.join(
            "/"
          )}")`;
        }

        if (riskUtils.searchPath.array.isArrayWithIndex(pathFirst)) {
          const _path =
            riskUtils.searchPath.array.parse(pathFirst)?.path + "[]";

          if (!(_path in templateNode)) {
            return emptyItem;
          }

          // console.log("...", templateNode, _path);
          return _fnRecursive(templateNode[_path], pathRest, level + 1);
        }

        if (pathFirst in templateNode) {
          return _fnRecursive(templateNode[pathFirst], pathRest, level + 1);
        }

        // Missing items (e.g. "Id")
        return emptyItem;
      }
    }
  };

  const foundData = _fnRecursive(template, path);

  // console.log("_fnGetTemplateData", path.join("/"), foundData);

  // Returns something of the format  { found: <bool>, data: .... }
  return foundData;
  // return { found: foundData ? true : false, data: foundData };
};

const _fnGenerateSalusData = (template, salusData) => {
  const fnRecursive = (templateNode, salusNode, options = {}) => {
    const {
      level = 1,
      isCreateMode = false, // For when there's no matching salus data (we need this flas as we can't query "salusNode" as the actualy value might be "undefined" or "false")
    } = options;

    const _fnDebug = (...args) => console.log(" ".repeat(level * 5), ...args);

    // Reached a template node
    if (_.isObject(templateNode) && templateNode._isTemplateDataNode === true) {
      if (isCreateMode) return templateNode.defaultValue;
      // if (salusNode === undefined) return templateNode.defaultValue;
      return salusNode;
    }

    // NOT a TEMPLATENODE and isCreateMode... so we need to NOT traverse any further.
    // if (isCreateMode) {
    //   return undefined;
    // }

    if (_.isObject(templateNode)) {
      // Existing entries
      const retObj = Object.fromEntries(
        Object.keys(templateNode)
          .map((k) => {
            const _newIsCreateMode = (function () {
              if (k.endsWith("[]")) return false;
              if (isCreateMode) return true;
              if (!(k in salusNode)) return true;
              return false;
            })();

            if (false) _fnDebug(k, _newIsCreateMode ? "(NEW)" : "");

            if (_newIsCreateMode) {
              const newData = fnRecursive(templateNode[k], undefined, {
                level: level + 1,
                isCreateMode: true,
              });
              if (newData === undefined) return undefined;
              return [k, newData];
            }

            if (!salusNode) return undefined;

            if (k.endsWith("[]")) {
              const keyClean = k.endsWith("[]") ? k.slice(0, -2) : k; // Strip off the "[]"

              if (!(keyClean in salusNode)) return undefined;
              return [
                keyClean,
                salusNode[keyClean].map((arrItem) =>
                  fnRecursive(templateNode[k], arrItem, {
                    level: level + 1,
                    isCreateMode: _newIsCreateMode,
                  })
                ),
              ];
            }

            if (!(k in salusNode)) {
              return [
                k,
                fnRecursive(templateNode[k], undefined, {
                  level: level + 1,
                  isCreateMode: true,
                }),
              ];
            }

            return [
              k,
              fnRecursive(templateNode[k], salusNode[k], {
                level: level + 1,
                isCreateMode: _newIsCreateMode,
              }),
            ];
          })
          .filter(Boolean)
      );
      // Don't return empty objects
      if (_.isEmpty(retObj)) return undefined;

      return retObj;
    }

    fnOutputErrorInfo({ templateNode, salusNode, level });
    throw `Error in _fnComposeSalusData -- Unknown templateNode`;
  };

  const retData = fnRecursive(template, salusData);

  console.log("TEMPLATE._fnGenerateSalusData", {
    template,
    salusData,
    retData,
  });
  // throw `hhh`;
  return retData;
};

export default {
  getData: _fnGetTemplateData,
  generateSalusData: _fnGenerateSalusData,
};
