import React, { useState } from "react";

import {
  ButtonStyledRenewal,
  ButtonOutlineRenewal,
  LoadingRow,
  Panel,
  Error,
  ConfirmModal,
  DevContainer,
} from "siteComponents";

const ButtonPanel = (props) => {
  const {
    onPrev,
    prevLabel,
    onNext,
    onNextClick = () => true,
    nextLabel,
    errorMsg,
    disabledPrev = false,
    disabledNext = false,
    className,
    dataCyPrev = "renewal-button-prev",
    dataCyNext = "renewal-button-next",
    modalData,
  } = props;

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  return (
    <div
      className={["renewal-button-panel", className].toClassName()}
      data-cy="renewal-confirm"
    >
      {modalData && (
        <ConfirmModal
          enabled={showConfirmModal}
          title={modalData.title || "Confirm these changes?"}
          cancelText={modalData.label.cancel || "Cancel"}
          confirmText={modalData.label.confirm || "Confirm"}
          onConfirm={async () => {
            setShowConfirmModal(false);
            await onNext();
          }}
          onCancel={async () => {
            setShowConfirmModal(false);
          }}
          dataCy="renewal-confirm-changes"
        >
          {modalData.body}
        </ConfirmModal>
      )}

      <div className="row">
        {onPrev && (
          <div className="col-12 col-md-3 order-2 order-md-1">
            <ButtonOutlineRenewal
              onClick={onPrev}
              className="px-5"
              disabled={disabledPrev}
              data-cy={dataCyPrev}
            >
              <span className="fw-bolder">{prevLabel}</span>
            </ButtonOutlineRenewal>
          </div>
        )}

        {onNext && (
          <div className="col-12 col-md-9 order-1 order-md-2 pb-3 pb-md-0 text-end">
            <ButtonStyledRenewal
              onClick={() => {
                if (!onNextClick()) return;

                if (modalData) {
                  setShowConfirmModal(true);
                  return;
                }
                onNext();
              }}
              className="w-100"
              disabled={disabledNext}
              data-cy={dataCyNext}
            >
              <span className="fw-bolder">{nextLabel}</span>
            </ButtonStyledRenewal>
          </div>
        )}
      </div>

      {errorMsg && (
        <div className="row mt-3">
          <div className="col-12">
            <Error>{errorMsg}</Error>
          </div>
        </div>
      )}
    </div>
  );
};

export default ButtonPanel;
