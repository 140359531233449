import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
const fnOutputErrorInfo = (...args) => {
  console.log("********************************************");
  console.log("ERRORINFO:", ...args);
  console.log("********************************************");
};
import chalk from "chalk";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  createExtraReducersSet,
  extraReducersSet,
  consoleLog,
} from "../common";

import riskUtils from "./utils/risk";
import riskSalusUtils from "./utils/riskSalus";
import templateUtils from "./utils/template";

// const { salus: salusUtils } = riskUtils;

import processorClass from "./processor";

const generate = (args = {}) => {
  const {
    storeName,
    privateActions,
    rules,
    defaultRisk = {},
    template,
    console: _console = console,
  } = args;

  const initialState = {
    userData: {
      risk: { data: defaultRisk, dataInitial: defaultRisk, groups: {} },
    },
    debugData: {
      template: template,
      salusLoad: undefined,
    },
  };

  const fnGroupRegister = (state, payload) => {
    const { group, path } = payload;
    if (!group) {
      _console.log("ERROR INFO:", { path });
      throw `Error in groupRegister -- missing group for path = "${path}"`;
    }
    state.userData.risk.groups[group] = state.userData.risk.groups[group] || [];

    if (!state.userData.risk.groups[group].includes(path))
      state.userData.risk.groups[group].push(path);
  };

  const _slice = createSlice({
    name: storeName,
    initialState: initialState,
    reducers: {
      runRules(state, action) {
        const processor = new processorClass({
          _console,
          template,
          rules,
          state,
          debugData: { action: "runRules", payload: action.payload },
        });
        processor.runRules(action.payload.path, {
          debugInfo: "slice.create runRules",
        });
      },
      create(state, action) {
        const processor = new processorClass({
          _console,
          template,
          rules,
          state,
          debugData: { action: "create", payload: action.payload },
        });
        const { path, pathList = [], group, defaultValue } = action.payload;

        if (pathList.length >= 1) {
          pathList.forEach(({ defaultValue, path }) => {
            // console.log("DDDD creating:", path, {
            //   pathList,
            //   group,
            //   defaultValue,
            // });
            processor.create(path);
            processor.updateValue(path, defaultValue);
            if (group) fnGroupRegister(state, { group, path });
          });

          pathList.forEach(({ path }) => {
            // _console.log("DDDD runRules:", path, { pathList, group });

            processor.runRules(path, {
              isPostSalusLoad: false,
              isPostRegistration: true,
              debugInfo: "slice.create pathList",
            });
          });

          return;
        }

        processor.create(path);
        if (!riskUtils.searchPath.array.isArrayAction(path)) {
          // This prevents the updateValue creating a duplicate entry
          processor.updateValue(path, defaultValue);
        }
        processor.runRules(path, { debugInfo: "slice.create" });
      },

      removeArrayItem(state, action) {
        const processor = new processorClass({
          _console,
          template,
          rules,
          state,
          debugData: { action: "removeArrayItem", payload: action.payload },
        });
        const { path = "" } = action.payload;
        const pathArray = path.split("/");
        const pathLastItem = pathArray[pathArray.length - 1];

        if (!riskUtils.searchPath.array.isArrayWithIndex(pathLastItem)) {
          throw `Error in removeArrayItem -- path ("${path}") must be an Array with index`;
        }

        const pathAction = `${path}-`;

        const pathRunRules = pathArray
          .map((x, i) => {
            if (i === pathArray.length - 1)
              return riskUtils.searchPath.array.parse(pathLastItem).path;
            return x;
          })
          .join("/");

        processor.create(pathAction);
        processor.runRules(pathRunRules, {
          debugInfo: "slice.removeArrayItem",
        });
      },

      groupRegister(state, action) {
        return fnGroupRegister(state, action.payload);
      },

      updateValue(state, action) {
        const processor = new processorClass({
          _console,
          template,
          rules,
          state,
          debugData: { action: "updateValue", payload: action.payload },
        });
        const { path, value } = action.payload;
        if (!path) {
          throw `Error in redux store updateValue -- missing path`;
        }

        processor.updateValue(path, value);
        processor.runRules(path, { debugInfo: "slice.updateValue" });
      },

      updateHidden(state, action) {
        const processor = new processorClass({
          _console,
          template,
          rules,
          state,
          debugData: { action: "updateHidden", payload: action.payload },
        });
        const { path, value } = action.payload;
        processor.updateHidden(path, value);

        // processor.runRules(path);
      },

      updateErrorShow(state, action) {
        const { path, value, pathList = [] } = action.payload;
        const processor = new processorClass({
          _console,
          template,
          rules,
          state,
          debugData: { action: "updateErrorShow", payload: action.payload },
        });

        if (pathList.length >= 1) {
          pathList.forEach((path) => {
            processor.updateErrorShow(path, value);
          });
          return;
        }

        processor.updateErrorShow(path, value);
      },

      updateErrorShowByGroup(state, action) {
        const { group, value } = action.payload;
        const processor = new processorClass({
          _console,
          template,
          rules,
          state,
          debugData: {
            action: "updateErrorShowByGroup",
            payload: action.payload,
          },
        });
        const pathList = state.userData.risk.groups[group];

        if (!pathList) return;

        pathList.forEach((path) => processor.updateErrorShow(path, value));
      },

      errorAdd(state, action) {
        const { path, errorKey, description } = action.payload;

        const processor = new processorClass({
          _console,
          template,
          rules,
          state,
          debugData: {
            action: "errorAdd",
            payload: action.payload,
          },
        });
        processor.updateErrorAdd(path, errorKey, description);
        processor.runRules(path, { debugInfo: "slice.errorAdd" });
      },
      errorRemove(state, action) {
        const { path, errorKey } = action.payload;

        const processor = new processorClass({
          _console,
          template,
          rules,
          state,
          debugData: {
            action: "errorRemove",
            payload: action.payload,
          },
        });
        processor.updateErrorRemove(path, errorKey);
        processor.runRules(path, { debugInfo: "slice.errorRemove" });
      },

      salusLoad(state, action) {
        console.time(`SALUSLOAD`);
        console.groupCollapsed("SALUSLOAD");

        state.debugData.salusLoad = undefined;
        const processor = new processorClass({
          _console,
          template,
          rules,
          state,
          debugData: {
            action: "salusLoad",
            payload: action.payload,
          },
        });
        const listAdded = [];
        const {
          salusData,
          isPostSalusLoad = true,
          isPostRegistration = true,
        } = action.payload;

        _console.log("salusLoad.loading salusData:", salusData, { rules });

        console.time(`SALUSLOAD.IMPORT`);

        const salusDataComposed = templateUtils.generateSalusData(
          template,
          salusData
        );

        const convertedSalusData = riskSalusUtils.import(
          salusDataComposed,
          template,
          (path) => listAdded.push(path.join("/")) // Log the added items
        );

        state.userData.risk.data = convertedSalusData;

        console.timeEnd("SALUSLOAD.IMPORT");
        _console.groupCollapsed("salusLoad.listAdded");
        _console.log(listAdded);
        console.groupEnd();
        //Risk/AdditionalInsuredSet[0]/Name/Forenames

        _console.time(`SALUSLOAD.RULES`);

        // console.log("ddddddddlistAdded", {listAdded, convertedSalusData})
        // throw `hhhhhh`
        processor.runRulesBatch(listAdded, {
          isPostSalusLoad: isPostSalusLoad,
          isPostRegistration: isPostRegistration,
          debugInfo: "slice.salusLoad",
        });

        // listAdded.forEach((path) => {
        //   if (!path) {
        //     fnOutputErrorInfo({ listAdded });
        //     throw `error in salusLoad -- missing path in listAdded`;
        //   }
        //   _console.time("SALUSLOAD.RUNRULE" + path);

        //   _console.groupCollapsed("salusLoad running rules:", path);
        //   processor.runRules(path, {
        //     isPostSalusLoad: isPostSalusLoad,
        //     isPostRegistration: isPostRegistration,
        //     debugInfo: "slice.salusLoad",
        //   });
        //   _console.groupEnd();

        //   _console.timeEnd("SALUSLOAD.RUNRULE" + path);
        // });

        _console.timeEnd("SALUSLOAD.RULES");

        // Set dataInitial
        state.userData.risk.dataInitial = state.userData.risk.data;

        console.groupEnd();
        console.timeEnd("SALUSLOAD");

        state.debugData.salusLoad = {
          payload: action.payload,
        };

        // processor.debugData();
      },

      testData(state) {
        _console.time(`testData`);
        {
          const processor = new processorClass({
            _console,
            template,
            rules,
            state,
            debugData: {
              action: "testData",
            },
          });
          processor.testState();
        }
        _console.timeEnd("testData");
      },
      reset() {
        // _console.warn("RESET DISABLED");

        return initialState;
      },
    },
  });

  return { reducer: _slice.reducer, actions: _slice.actions };
};

export default generate;
