import ruleSet from "@library/common/database/rules/classes/ruleSet";

class siteRuleSet extends ruleSet {
  getRule(args) {
    require("@library/common/helpers/misc/argChecker")(args, [
      "ruleType",
      "ruleLibrary",
    ]);

    const { ruleType, ruleLibrary } = args;
    const ruleData = this.data;

    const item = (ruleData[ruleLibrary] || {})[ruleType];

    if (item === undefined) {
      console.log("ruleData:", ruleData);
      console.log("ruleLibrary:", ruleLibrary);
      console.log("ruleType:", ruleType);

      throw `Error in ruleSet.getItem -- can't find item`;
    }

    return item;
  }
}
export default new siteRuleSet({
  data: {
    // van: require("./templates/van").default,
    vehicle: require("./templates/vehicle").default,
    general: require("./templates/general").default,
  },
});
