import _ from "lodash";

const fnGenerateRiskUtils = (args = {}) => {
  const {
    _fnGetRiskData = (state) => {
      try {
        return state.userData.risk.data;
      } catch (e) {
        fnOutputErrorInfo("ERROR IN fnGetRiskData", { state });
        throw e;
      }
    },
    description: riskTypeDescription,
    _fnOnMissingItem = (errorMessage, ...logArgs) => {
      fnOutputErrorInfo(...logArgs);
      throw errorMessage;
    },
  } = args;

  const fnOutputErrorInfo = (...args) => {
    console.log("********************************************");
    console.log("ERRORINFO:", riskTypeDescription, ...args);
    console.log("********************************************");
  };

  const debugData = true;
  const fnLog = (...args) => {
    if (!debugData) return;
    console.log(...args);
  };

  const fnCreateDataNode = (args = {}) => {
    const { value = undefined, arrayData = undefined } = args;
    return {
      _arrayData: arrayData,
      _value: value,
      _hidden: false,
      _error: {},
      _errorShow: false,
    };
  };

  const fnCreateArrayItem = (id) => {
    return {
      id: id,
      data: {},
    };
  };

  const _fnIsDataNode = (node) => {
    if (!node) return false;
    if (_.isArray(node)) return false;
    if (!_.isObject(node)) return false;

    if (Object.keys(node).some((x) => x.startsWith("_"))) return true;

    return false;
  };

  const _fnIsDataNodeArray = (node) => {
    if (!_fnIsDataNode(node)) return false;
    if (!node._arrayData) return false;
    return true;
  };

  const _fnIsDataNodeError = (node) => {
    if (!_fnIsDataNode) return false;
    return !_.isEmpty(node._error);
  };

  const fnCleanArray = (pathnode) =>
    pathnode.replaceAll(/\[[\S\s]+\]$/g, "").replaceAll(/\[\]$/g, ""); // Strips out all instance of array brackets

  // Removes the "+" or "-"

  const fnIsArray = (pathNode) => /\[\]$/.test(pathNode);
  const fnIsArrayWithIndex = (pathNode) => /\[[\S\s]+\]$/.test(pathNode);
  const fnIsArrayAction = (pathNode) => {
    if (/\[[\S\s]+\]\+$/.test(pathNode)) return true;
    if (/\[[\S\s]+\]\-$/.test(pathNode)) return true;
    return false;
  };

  const fnArrayRemoveAction = (pathnode) => {
    if (!fnIsArrayAction(pathnode)) return pathnode;
    return pathnode
      .split("/")
      .map((x, i, arr) => {
        if (i === arr.length - 1) {
          return x.split("[").find((x2, i2) => i2 === 0);
        }
        return x;
      })
      .join("/");
  };

  const fnParseArrayItem = (path) => {
    if (!fnIsArrayWithIndex(path) && !fnIsArray(path) && !fnIsArrayAction(path))
      return undefined;

    const data = path.split("[");
    const _path = data[0];

    const _dataEnd = data[1].split("]");
    const _index = _dataEnd[0];
    const _action = _dataEnd.length >= 2 ? _dataEnd[1] : undefined;
    return { path: _path, index: _index, action: _action };
  };

  const fnArrayRemoveIndex = (pathnode) =>
    pathnode
      .split("/")
      .map((x) => {
        if (fnIsArrayWithIndex(x)) return `${fnParseArrayItem(x).path}[]`;
        return x;
      })
      .join("/");
  // *********************************************
  // Important functions
  // *********************************************

  const _fnGetRiskGroup = (state, group) => {
    try {
      return state.userData.risk.groups[group];
    } catch (e) {
      fnOutputErrorInfo("ERROR IN fnGetRiskData", { state });
      throw e;
    }
  };

  const _fnFindItem = (state, searchPath = "") => {
    if (!_.isObject(state))
      throw `Error in fnFindItem -- state is not an object`;

    if (!_.isString(searchPath)) {
      fnOutputErrorInfo("ERROR", { searchPath });
      throw `Error in fnFindItem -- searchPath is not a string`;
    }

    if (searchPath.includes("[]")) {
      fnOutputErrorInfo("ERROR", { searchPath });
      throw `Error in fnFindItem -- found [] in "${searchPath}"`;
    }

    const _fnRecursive = (obj, searchPathArray = [], level = 1) => {
      const retFallback = undefined;
      const [curSearchNode, ...otherSearchNodes] = searchPathArray;

      if (searchPathArray.length === 0)
        throw `Error in fnFindItem -- missing searchPath`;

      if (searchPathArray.length === 1) {
        const _curSearchNode = fnCleanArray(curSearchNode); // remove any brackets
        if (_curSearchNode === "*") return obj;
        if (_curSearchNode in obj) return obj[_curSearchNode];
        return retFallback;
      }

      // Handle arrays
      if (fnIsArrayWithIndex(curSearchNode)) {
        // const { path: _path, index: _index } = fnParseArrayItem(curSearchNode);
        const _metaArray = fnParseArrayItem(curSearchNode);

        if (!_fnIsDataNode(obj[_metaArray.path])) {
          return _fnOnMissingItem(
            `Error in _fnFindItem -- trying to find an array but found none dataNode`,
            {
              ["obj[curSearchNode]"]: obj[_metaArray.path],
              obj,
              curSearchNode,
              searchPath,
              searchPathArray,
              _metaArray,
            }
          );
        }

        const _curArray = obj[_metaArray.path]._arrayData;
        const _curArrayItem = _curArray.find((x) => x.id === _metaArray.index);

        if (!_curArrayItem) {
          return _fnOnMissingItem(
            `Error in fnFindItem -- can't find array with id = "${_metaArray.index}"`,
            { searchPath, _curArray, _metaArray }
          );
        }

        return _fnRecursive(_curArrayItem.data, otherSearchNodes, level + 1);
      }

      // Handle Objects
      if (curSearchNode in obj) {
        return _fnRecursive(obj[curSearchNode], otherSearchNodes, level + 1);
      }

      return retFallback;
    };

    if (searchPath === "") return _fnGetRiskData(state);
    const retData = _fnRecursive(_fnGetRiskData(state), searchPath.split("/"));

    return retData;
  };

  const _fnProcessAll = (state, startPath = "", fnProcessItem = () => {}) => {
    // Finds every node and runs fnProcessItem against the node

    const _FnRecusive = (obj, searchPath = [], level = 1) => {
      if (!obj) return;

      // found a datanode
      if (_fnIsDataNode(obj)) {
        // if it's an array
        if (obj._arrayData) {
          obj._arrayData.forEach((x) => {
            _FnRecusive(
              x.data,
              searchPath.map((searchSeg, idx, arr) => {
                if (idx === arr.length - 1) return `${searchSeg}[${x.id}]`;
                return searchSeg;
              }),
              level + 1
            );
          });

          return;
        }

        fnProcessItem(obj, { searchPath });
        return;
      }

      if (_.isObject(obj)) {
        Object.entries(obj).forEach(([key, data]) =>
          _FnRecusive(data, [...searchPath, key], level + 1)
        );
        return;
      }
    };

    if (startPath === "") {
      _FnRecusive(_fnFindItem(state, startPath), [], 1);
      return;
    }
    _FnRecusive(_fnFindItem(state, startPath), startPath.split("/"), 1);
  };

  const fnIsError = (state, pathList = []) => {
    if (pathList.length === 0) {
      fnOutputErrorInfo("fnIsError");
      throw `Error in fnIsError -- empty pathList`;
    }

    const _FnRecusive = (obj, searchPath = [], level = 1) => {
      if (!obj) return false;

      // found a datanode
      if (_fnIsDataNode(obj)) {
        // if it's an array
        if (obj._arrayData) {
          return obj._arrayData.some((x) => {
            return _FnRecusive(
              x.data,
              searchPath.map((searchSeg, idx, arr) => {
                if (idx === arr.length - 1) return `${searchSeg}[${x.id}]`;
                return searchSeg;
              }),
              level + 1
            );
          });
        }

        const _isError = _fnIsDataNodeError(obj);
        // if (_isError) console.log("FOUNDERROR:", searchPath.join("/"));

        return _isError;
      }

      if (_.isObject(obj)) {
        return Object.entries(obj).some(([key, data]) =>
          _FnRecusive(data, [...searchPath, key], level + 1)
        );
      }
      return false;
    };

    const isError = pathList.some((path) => {
      return _FnRecusive(_fnFindItem(state, path), path.split("/"));
    });

    return isError;
  };

  const fnBuildTree = (state, startPath = "", options = {}) => {
    const {
      fnProcessItem = () => ({
        data: undefined,
        output: true,
        newSubPath: undefined,
      }),
      fnProcessArrayItem = (id, data) => data,
      keyArrayId = undefined, //Do we inject the ID in each array data?
    } = options;

    const _FnRecusive = (dataObj, searchPath = [], level = 1) => {
      // console.log(
      //   "_FnRecusive",
      //   " ".repeat(level * 5),
      //   level,
      //   searchPath.join("/"),
      //   dataObj
      // );

      if (!dataObj) return { data: undefined, output: false };

      // found a datanode
      if (_fnIsDataNode(dataObj)) {
        // if it's an array

        if (_fnIsDataNodeArray(dataObj)) {
          const _arrayData = dataObj._arrayData
            .map((_arrItem) => {
              const _subData = _FnRecusive(
                _arrItem.data,
                searchPath.map((segment, idx, arr) => {
                  if (idx === arr.length - 1)
                    return `${segment}[${_arrItem.id}]`;
                  return segment;
                }),
                level + 1
              );

              if (keyArrayId) {
                const data = fnProcessArrayItem(_arrItem.id, _subData.data);
                if (_.isObject(data) && keyArrayId in data) {
                  throw `Error in fnBuildTree -- Array -- found "${keyArrayId} in data"`;
                }

                return {
                  output: _subData.output,
                  data: { ...data, [keyArrayId]: _arrItem.id },
                };
              }

              return {
                output: _subData.output,
                data: fnProcessArrayItem(_arrItem.id, _subData.data),
              };
            })
            .filter(({ data, output, newSubPath }) => {
              if (output) return true;
              return false;
            })
            .map(({ data }) => data);

          const _arrayOutput = _arrayData.some((x) => !_.isEmpty(x));
          // console.log("dddddd ARRAY", searchPath.join("/"), {
          //   _arrayData,
          //   _arrayOutput,
          // });

          return { output: _arrayOutput, data: _arrayData };
        }

        // if (searchPath.join("/").endsWith("Name")) {
        //   console.log("DDDD", searchPath.join("/"), {
        //     startPath,
        //     dataObj,
        //     searchPath,
        //     level,
        //   });
        // }
        // Not an array
        return fnProcessItem(dataObj, { searchPath });
      }

      if (_.isObject(dataObj)) {
        const newObj = Object.fromEntries(
          Object.entries(dataObj)
            .map(([key, data]) => [
              key,
              _FnRecusive(data, [...searchPath, key], level + 1),
            ])
            .filter(([k, d]) => d.output)
            .map(([key, d]) => {
              return [d.newSubPath || key, d.data];
            })
        );

        return { data: newObj, output: !_.isEmpty(newObj) };
      }
    };

    // console.log("ddddd", { state });

    if (!startPath) {
      const riskData = _fnGetRiskData(state);
      // console.log("ddddd", { riskData });

      const retObj = Object.fromEntries(
        Object.entries(riskData)
          .map(([key, d]) => {
            // console.log("dddd..", key);
            return [key, _FnRecusive(d, [key], 1)];
          })
          .filter(([key, d]) => d.output)
          .map(([key, d]) => [key, d.data])
      );

      // console.log("ddddd2", startPath, { state, riskData, retObj });
      return retObj;
    }

    const _startPath = startPath.split("/");
    const _baseState = _fnFindItem(state, startPath);
    const retObj = _FnRecusive(_baseState, _startPath, 1);
    if (!retObj.output) return undefined;

    // console.log("dddd buildTree", startPath, retObj);

    return retObj.data;
  };

  const _fnCreateItem = (state, createPath = "", options = {}) => {
    const {
      generateBlankItem = () => {
        // console.log("generateBlankItem default")
        return {};
      },
      throwErrorIfExists = true,
    } = options;
    // NOTE: Reducer will return the last entry, which we want to return
    // console.log("calling _fnCreateItem()", createPath, { options });
    //Risk/AdditionalInsuredSet

    return createPath.split("/").reduce(
      (acc, cur, idx, arr) => {
        const isLastSearchNode = idx === arr.length - 1;
        // console.log("dddd", createPath, cur);
        if (false && createPath.includes("/Name")) {
          console.log("DDDD", createPath, cur, {
            cur,
            idx,
            arr,
            options,
            fnIsArray: fnIsArray(cur),
            fnIsArrayAction: fnIsArrayAction(cur),
            fnIsArrayWithIndex: fnIsArrayWithIndex(cur),
            blankItem: generateBlankItem({
              searchNode: cur,
              idx: idx,
              searchAcc: acc,
              isLastNode: idx === arr.length - 1,
            }),
          });
        }

        if (false) {
          console.log(
            "_".repeat(idx * 2),
            cur,
            // fnIsArrayWithIndex(cur),
            {
              searchPath: createPath,
              cur,
              idx,
              arr,
              fnIsArray: fnIsArray(cur),
              fnIsArrayAction: fnIsArrayAction(cur),
              fnIsArrayWithIndex: fnIsArrayWithIndex(cur),
            }
          );
        }

        // Array actions -- possible to be in the middle *or* last
        if (fnIsArrayAction(cur)) {
          //e.g. MyKey[]+, MyKey[0]+
          const _metaArray = fnParseArrayItem(cur);

          fnLog("ACTION", cur, _metaArray.action);

          acc[_metaArray.path] =
            acc[_metaArray.path] || fnCreateDataNode({ arrayData: [] });

          switch (_metaArray.action) {
            case "+": {
              acc[_metaArray.path]._arrayData = [
                ...acc[_metaArray.path]._arrayData,
                fnCreateArrayItem(_metaArray.index),
              ];

              return acc[_metaArray.path]._arrayData.find(
                (x) => x.id === _metaArray.index
              ).data;
            }
            case "-": {
              if (!isLastSearchNode) {
                throw `Error in _fnCreateItem -- Array "-" action is not the last entry in the path`;
              }
              acc[_metaArray.path]._arrayData = acc[
                _metaArray.path
              ]._arrayData.filter((x) => x.id !== _metaArray.index);

              return undefined;
            }
            default:
              throw `Error in fnCreateObject -- unknown action "${_metaArray.action}"`;
          }
        }

        // NOT last node
        if (!isLastSearchNode) {
          // Is an array e.g. "Claims[]"
          if (fnIsArray(cur)) {
            //e.g. MyKey[]
            const _metaArray = fnParseArrayItem(cur);

            acc[_metaArray.path] =
              acc[_metaArray.path] || fnCreateDataNode({ arrayData: [] });
            //fnCreateArrayItem
            const arrItem = acc[_metaArray.path]._arrayData.find(
              (x) => x.id === _metaArray.index
            );

            if (!arrItem) {
              fnOutputErrorInfo({
                isLastSearchNode,
                searchPath: createPath,
                cur,
                idx,
                arr,
              });
              throw `Error in _fnCreateItem -- can't find array with id="${_metaArray.index}" in request "${createPath}"`;
            }

            return arrItem.data;
          }

          // Is an array with index e.g. "Claims[ID1]"
          if (fnIsArrayWithIndex(cur)) {
            const _metaArray = fnParseArrayItem(cur);
            acc[_metaArray.path] =
              acc[_metaArray.path] || fnCreateDataNode({ arrayData: [] });

            const foundArrItem = acc[_metaArray.path]._arrayData
              //Find the item by the id
              .find((x) => x.id === _metaArray.index);

            if (!foundArrItem) {
              fnOutputErrorInfo({
                isLastSearchNode,
                searchPath: createPath,
                cur,
                idx,
                arr,
              });
              throw `Error in _fnCreateItem -- can't find array with id="${_metaArray.index}" in request "${createPath}"`;
            }

            // console.log("FOUND", foundArrItem);
            return foundArrItem.data;

            // for (let i = 0; i <= _metaArray.index; i++) {
            //   acc[_metaArray.path].metaArray.arrayData[i] =
            //     acc[_metaArray.path]._arrayData[i] || {};
            // }

            // return acc[_metaArray.path]._arrayData[_metaArray.index];
          }

          // Must an object
          acc[cur] = acc[cur] || {};
          return acc[cur];
        }

        // Last NODE code
        {
          if (acc[cur]) {
            if (throwErrorIfExists) {
              fnOutputErrorInfo("Risk.create", {
                searchPath: createPath,
                cur,
                options,
              });
              throw `Error in riskUtils -- tried to create something that already exists "${createPath}"`;
            }
            return acc[cur];
          }

          // if (createPath === "Risk/AdditionalInsuredSet") {
          //   console.log(generateBlankItem());
          //   throw `_fnCreateItem hhhhhhhh2222222`;
          // }
          acc[cur] = generateBlankItem({
            searchNode: cur,
            idx: idx,
            searchAcc: acc,
            isLastNode: idx === arr.length - 1,
          });
          return acc[cur];
        }
      },
      //Base start data
      _fnGetRiskData(state)
    );
  };

  // *********************************************
  // OTHER
  // *********************************************

  const _fnFindItemAndCreateBase = (state, searchPath = "", options = {}) => {
    const {
      defaultValue = undefined,
      generateBlankItem = undefined,
      onFind = () => {},
      onCreate = () => {},
    } = options;

    if (!_.isObject(state))
      throw `Error in fnGetRiskItemAndCreate -- state is not an object`;

    if (!searchPath)
      throw `Error in fnGetRiskItemAndCreate -- missing searchPath`;

    const obj = _fnFindItem(state, searchPath);

    if (!obj) {
      // console.log("ddddddd not found", searchPath);

      const createdObj = _fnCreateItem(state, searchPath, {
        generateBlankItem: generateBlankItem,
      });

      createdObj._value = defaultValue;

      onCreate({ searchPath });
      // console.log("dddd _fnFindItemAndCreate created", searchPath);

      return createdObj;
    }

    // console.log("dddd _fnFindItemAndCreate found", searchPath);
    // console.log("ddddddd  found", searchPath);
    onFind({ searchPath });
    return obj;
  };

  const _fnFindItemAndCreate = (state, searchPath = "", options = {}) => {
    return _fnFindItemAndCreateBase(state, searchPath, options);
  };

  const _fnFindItemAndCreateArray = (state, searchPath, options = {}) => {
    const { onCreate = () => {} } = options;
    const retData = _fnFindItemAndCreateBase(state, searchPath, {
      ...options,
      generateBlankItem: () => {
        const retData = fnCreateDataNode({ arrayData: [] });
        onCreate({ searchPath });
        return retData;
      },
    });

    return retData;
  };

  const _fnFindItemByNodeName = (state, searchNodeName) => {
    const riskData = _fnGetRiskData(state);
    const retData = [];

    const _fnRecursive = (node, path = [], level = 1) => {
      // fnLog(" ".repeat(level * 10), level, path.join("/"), {
      //   node,
      //   searchNodeName,
      // });

      // if (level > 20) throw `fnFindItemByNodeName`;

      if (_fnIsDataNode(node)) {
        if (level === 1)
          throw `Error in _fnFindItemByNodeName -- first level can't be a dataNode`;

        if (node._arrayData) {
          node._arrayData.forEach((n, nodeIdx) =>
            _fnRecursive(
              n,
              path.map((x, pathIdx, thisArray) => {
                if (pathIdx === thisArray.length - 1) return `${x}[${nodeIdx}]`;
                return x;
              }),
              level + 1
            )
          );
          return;
        }

        if (searchNodeName === path[path.length - 1]) retData.push(path);
      }

      if (_.isObject(node)) {
        Object.entries(node).forEach(([key, data]) => {
          _fnRecursive(data, [...path, key], level + 1);
        });
        return;
      }
    };

    _fnRecursive(riskData, [], 1);
    return retData;
  };

  return {
    // salus: salusUtils,
    searchPath: {
      array: {
        isArray: fnIsArray,
        isArrayWithIndex: fnIsArrayWithIndex,
        isArrayAction: fnIsArrayAction,
        parse: fnParseArrayItem,
        clean: fnCleanArray,
        removeIndex: fnArrayRemoveIndex,
        removeAction: fnArrayRemoveAction,
      },
    },
    find: {
      data: _fnGetRiskData,
      item: _fnFindItem,
      itemAndCreate: _fnFindItemAndCreate,
      itemAndCreateArray: _fnFindItemAndCreateArray,
      itemByNodeName: _fnFindItemByNodeName,
      group: _fnGetRiskGroup,
    },
    process: { all: _fnProcessAll, buildTree: fnBuildTree },
    is: {
      error: fnIsError,
      dataNode: _fnIsDataNode,
      dataNodeArray: _fnIsDataNodeArray,
    },
    create: {
      item: _fnCreateItem,
      dataNode: fnCreateDataNode,
      arrayItem: fnCreateArrayItem,
    },
  };
};

export default fnGenerateRiskUtils;
