import dayjs from "dayjs";
// import functions from "../../../base";
import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";
import _ from "lodash";
import { isEmpty } from "@library/common/database/rules/helpers";

export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper")
      .default,
  } = args;

  const dh = new dataHelper({
    mappings: ["ClassOfUse", "BusinessMileage", "TotalMileage"],
    dataSet,
    functionArgs,
  });

  const { policyType } = functionArgs;

  const isBusiness = (function () {
    if (dh.getValue("ClassOfUse") === undefined) return false;
    if (["SDP", "SDPexC"].includes(dh.getValue("ClassOfUse"))) return false;
    return true;
  })();

  dh.setVisible("BusinessMileage", isBusiness);

  if (dh.getValue("BusinessMileage") === "0") {
    dh.setValue("BusinessMileage", undefined, { ignoreProtection: true });
    // "ignoreProtection" so that this code runs on recalls as well (which are normally protected)
  }

  if (!isBusiness && dh.isUpdated("ClassOfUse")) {
    dh.setValue("BusinessMileage", undefined);
    dh.clearError("BusinessMileage");
  }

  if (isBusiness) {
    dh.addErrorIfEmpty("BusinessMileage", "");

    if (
      policyType == "PC" &&
      !isEmpty(dh.getValue("TotalMileage")) &&
      !isEmpty(dh.getValue("BusinessMileage")) &&
      _.toNumber(dh.getValue("TotalMileage")) <
        _.toNumber(dh.getValue("BusinessMileage"))
    ) {
      dh.addError(
        "BusinessMileage",
        `This cannot be greater than your total mileage of ${dh.getValue(
          "TotalMileage"
        )}`
      );
      dh.addError("TotalMileage", "");
    }
  }

  dh.commit();
};
