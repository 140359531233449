import dayjs from "dayjs";
import functions from "../../../general";
import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

const { dateComparison } = functions;

export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper")
      .default,
  } = args;
  const dh = new dataHelper({
    mappings: ["MonthBanQuestion", "MonthBan"],
    dataSet,
    functionArgs,
  });

  // console.log("dddddddddddddd", {
  //   MonthBanQuestion: dh.getValue("MonthBanQuestion"),
  //   MonthBan: dh.getValue("MonthBan"),
  //   debugData: dh.returnDebugData(),
  // });

  // ***********************************
  // ** RECALL
  // ***********************************
  if (dh.isPostRecall()) {
    const _MonthBanQuestion = dh.getValue("MonthBan") !== undefined;

    dh.setValue("MonthBanQuestion", _MonthBanQuestion);
    dh.setVisible("MonthBan", _MonthBanQuestion);
  }

  // ***********************************
  // ** MANUAL EDIT
  // ***********************************
  if (!dh.isPostRecall()) {
    if (dh.isUpdated("MonthBanQuestion")) {
      if (dh.getValue("MonthBanQuestion") === true) {
        dh.setVisible("MonthBan", true);
      } else {
        dh.setValue("MonthBan", undefined);
        dh.setVisible("MonthBan", false);
      }
    }

    if (
      dh.getValue("MonthBanQuestion") !== false &&
      (dh.getValue("MonthBan") === undefined || dh.getValue("MonthBan") === "")
    ) {
      // console.log("dddddddddddddd", {
      //   MonthBanQuestion: dh.getValue("MonthBanQuestion"),
      //   MonthBan: dh.getValue("MonthBan"),
      //   debugData: dh.returnDebugData(),
      // });
      dh.addError("MonthBan", ""); // "Required")
    }
  }

  dh.commit();
};
