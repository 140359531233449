import React from "react";
import { generateCypressProps } from "siteFunctions/cypress";

import CookieConsentLibrary from "@library/common/components/base/misc/CookieConsent";
// layout-max-width
const Container = (props) => {
  return (
    <>
      {props.enabled && props.showBanner && (
        <div
          className="container-fluid bg-white"
          {...generateCypressProps("cookie-consent-banner", props)}
        >
          <div className="row justify-content-center">
            <div className="col-12 px-2 pt-3 px-lg-5">
              <div className="alert alert-primary">
                <div className="row align-items-center">
                  <div className="col-12 col-md">{props.text}</div>
                  <div className="col-12 col-md-auto text-end">
                    <button
                      type="button"
                      className={props.buttonClassName}
                      onClick={props.onClick}
                    >
                      {props.buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {props.children}
    </>
  );
};

const CookieConsent = () => {
  return (
    <CookieConsentLibrary
      expireTime={30}
      cookieId="CookieConsent"
      text={
        <span>
          Our website uses cookies to deliver you the best possible experience.
          By continuing to use this website you are agreeing to the use of
          cookies. For further details, please review our{" "}
          <a
            target="_blank"
            href="https://withstella.co.uk/privacy"
            rel="noreferrer noopener"
          >
            privacy notice
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href="https://withstella.co.uk/cookie-policy/"
            rel="noreferrer noopener"
          >
            cookie policy.
          </a>
        </span>
      }
      buttonText={<span>Okay</span>}
      buttonClassName="btn btn-warning"
      enabled={true}
      Container={Container}
    />
  );
};

export default CookieConsent;
