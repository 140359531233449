import React, { useEffect, useCallback, useMemo, useState } from "react";
import config from "config";
import services from "siteService";
import { Outlet, Link, NavLink } from "react-router-dom";
import { selectors } from "siteStore";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import _ from "lodash";
// import Menu from "../Menu";

// import { StickyContainer, Sticky } from "react-sticky";

import { Sticky } from "siteComponents";
import { generateCypressProps } from "siteFunctions/cypress";
import { useScrollToTop, useSnapToTop } from "moveToLibrary/hooks/useScrollTo";

const ContainerWrapper = ({ children }) => (
  <div className="container-fluid container-lg layout-max-width">
    {children}
  </div>
);

const PageLayoutBase = (props) => {
  // const isLoggedIn = useSelector(selectors.auth.isLoggedIn);

  const {
    children,
    className,
    headingText,
    isSticky = false,
    stickyOffset = 0,
    descriptionContent,
    autoComplete = false,
    toolbarText,
    routeExit,
    // lctr,
    scrollToTop = false,
    snapToTop = true,
    // isStandardCss = true,
    onHeaderStick = () => {},
    onHeaderUnstick = () => { },
    showHeader = true,
  } = props;

  const _className = [
    !config.isProd ? "lctr-page-layout" : "",
    // !config.isProd && lctr ? lctr : "",
    "layout-page",
    descriptionContent ? "has-subheader" : "",
    className,
    // isStandardCss && "standard-css",
  ].toClassName();

  //const showHeader = true; //Aways show the header so that there's a space.

  useSnapToTop(snapToTop);
  useScrollToTop(scrollToTop);

  // **************
  // ** WRAPPERS **
  // **************

  const HeaderWrapper = useMemo(() => {
    if (isSticky) {
      if (headingText || descriptionContent) {
        // return ({ children }) => <div className="sticky-top">{children}</div>;
        // eslint-disable-next-line react/display-name
        return ({ children }) => {
          const [isActive, setIsActive] = useState(false);

          const _className = [
            !isActive && "header-sticky",
            isActive && "header-sticky-active",
          ].toClassName();

          return (
            <div className="z-index-max">
              <Sticky
                className={_className}
                stickyClassName="header-sticky-inner"
                topOffset={stickyOffset}
                onChange={(v) => {
                  setIsActive(v);

                  if (v) onHeaderStick();
                  else onHeaderUnstick();
                }}
              >
                <div
                  data-cy="page-header"
                  className={[
                    "header-container",
                    "header-container-sticky",

                    // "position-relative",
                    // "position-absolute",
                    // "top-0",
                    // "start-0",
                  ].toClassName()}
                >
                  <ContainerWrapper> {children}</ContainerWrapper>
                </div>
              </Sticky>
            </div>
          );
        };
      }
    }

    return ({ children }) => (
      <div
        className={["header-container", className].toClassName()}
        data-cy="page-header"
      >
        <ContainerWrapper> {children}</ContainerWrapper>
      </div>
    );
  }, [isSticky, stickyOffset]);

  // **************
  // ** FINAL RETURN **
  // **************
  return (
    <div className={_className} {...generateCypressProps("page", props)}>
      {toolbarText && <Helmet>{toolbarText}</Helmet>}

      {showHeader && (
        <HeaderWrapper>
          {headingText && (
            <h1 className="header-text" data-cy="page-header-main">
              {headingText}
            </h1>
          )}

          {descriptionContent && (
            <div className="description-text" data-cy="page-header-description">
              {descriptionContent}
            </div>
          )}

          {routeExit && (
            <div data-cy="page-header-links">
              <NavLink to={routeExit} className="btn btn-sm btn-outline-dark" data-cy="goto-route-exit">
                Back
              </NavLink>
            </div>
          )}
        </HeaderWrapper>
      )}
      <div className="body-container">
        <ContainerWrapper>{children}</ContainerWrapper>
      </div>
      {/* <div className="footer-container"></div> */}
    </div>
  );
};

export default PageLayoutBase;
