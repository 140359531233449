import React, { useCallback } from "react";
import _ from "lodash";
import deepmerge from "deepmerge";
import { useForm, Controller, useController } from "react-hook-form";
import {
  isPostcode,
  isWebReference,
  isEmail,
} from "@library/common/helpers/validation";
import { isPassword } from "moveToLibrary/functions";
import { DevContainer, JsonViewer } from "siteComponents";

const fnTrim = (v) => {
  if (_.isString(v)) return v.trim();
  return v;
};

const fnGenerateFormItemTemplate = (baseProps = {}, description) => {
  const FormItemTemplate = (props) => {
    // const {
    //   rules = {},
    //   onChangeProcess = (v) => v,
    //   ...additionalProps
    // } = props;

    // const fnProcess = (obj) => obj || {};

    const newProps = {
      ...baseProps,
      ...props,

      onChangeProcess: (function () {
        const { onChangeProcess: fnBase = (v) => v } = baseProps;
        const { onChangeProcess: fnAdditional = (v) => v } = props;
        return (v) => fnAdditional(fnBase(v));
      })(),

      rules: {
        ...(baseProps.rules || {}),
        ...(props.rules || {}),
        validate: {
          ...((baseProps.rules || {}).validate || {}),
          ...((props.rules || {}).validate || {}),
        },
      },
    };

    // if (description === "password") console.log(description, newProps);

    return <FormItem {...newProps} />;
  };
  return FormItemTemplate;
};

// function usePrevious(name, key, value) {
//   const ref = React.useRef();
//   React.useEffect(() => {
//     console.log(name, key); //, ref.current, "->", value);
//     ref.current = value;
//   }, [value]); // Only re-run if value changes
//   return ref.current;
// }

const FormItem = (props) => {
  const {
    enabled = true,
    debug = false,
    label,
    name,
    cypressKey,
    rules = {},
    Component,
    helptext,
    onChangeProcess,
    disabled = false,
    getFieldState,
    formState,
    control,
    className,
    classNameLabel,
    classNameControl,
    classNameHelp,
    classNameError, //"has-error"
    classNameValid, //"is-validated"
    rulesTrim = false,
    lengthMin = undefined,
    lengthMax = undefined,
    onBlur: propsOnBlur = () => {},
    onChange: propsOnChange = () => {},
    saveValueOnBlur = false,
    showStatus = false, //Show the tick or exclaimation mark?
    children,
    enableShowErrors = true,
    enableShowValid = true,
    feedbackMsg = undefined,
    ...otherProps
  } = props;

  if (!enabled) return null;

  // console.log(name, props);

  ["getFieldState", "formState"].argChecker(props);

  if (false) {
    console.log("RERENDERING", name, props);
    Object.entries(props).forEach(([k, v]) => {
      usePrevious(name, k, v);
    });
  }

  const { invalid, isDirty, isTouched, error } = getFieldState(name);

  const showErrors =
    enableShowErrors & invalid && (isTouched || formState.isSubmitted);
  const showValid = enableShowValid & !invalid && isTouched;

  const errMsg =
    showErrors && error && error.message ? error.message : undefined;

  //

  if (debug) {
    console.groupCollapsed(name, "FormItem");
    console.log({ rules });
    console.groupEnd();
  }

  const _classNameContainer = [
    className,
    "fb-form-group",
    helptext ? "has-helptext" : "",
    classNameError && showErrors ? classNameError : "",
    classNameValid && showValid ? classNameValid : "",
  ].toClassName();

  const _classNameLabel = [classNameLabel].toClassName();
  const _classNameControl = [classNameControl].toClassName();
  const _classNameHelp = [
    "help-text",
    "text-purple",
    "form-text",
    "mb-3",
    classNameHelp,
  ].toClassName();
  const _classNameFeedback = ["fixed-height"].toClassName();

  const _rules = {
    ...rules,
    ...((lengthMin !== undefined && fnGenerateRuleMin(lengthMin)) || {}),
    ...((lengthMax !== undefined && fnGenerateRuleMax(lengthMax)) || {}),
  };

  const fngenerateCypressProps = (elementType) => {
    const _cypressKey = cypressKey || name;
    if (!_cypressKey) return undefined;
    if (elementType) return `${_cypressKey}:${elementType}`;
    return _cypressKey;
  };

  return (
    <div
      className={_classNameContainer}
      data-cy={fngenerateCypressProps("form-group")}
    >
      <div className={_classNameLabel}>
        <label
          htmlFor={name}
          className="form-label fb-form-label"
          data-cy={fngenerateCypressProps("label")}
        >
          {label}
        </label>
      </div>

      {helptext && (
        <div
          className={_classNameHelp}
          data-cy={fngenerateCypressProps("help")}
        >
          {helptext}
        </div>
      )}

      <div className={_classNameControl}>
        <Controller
          control={control}
          name={name}
          rules={_rules}
          render={(subProps) => {
            const {
              field: { onChange, value, ref, onBlur },
            } = subProps;

            const applyProcessing = (v) => {
              const hitlist = [
                rulesTrim ? fnTrim : undefined,
                onChangeProcess,
              ].filter((x) => x);

              if (hitlist.length === 0) return { value: v, isUpdated: false };

              const newValue = hitlist.reduce((_v, curFn) => curFn(_v), v);

              return { value: newValue, isUpdated: newValue !== v };
            };

            const fnOnChange = (v) => {
              const newValue = v;

              if (debug) console.log(name, "fnOnChange", ":", newValue);

              propsOnChange(newValue);
              onChange(newValue);
            };

            const fnOnBlur = (v) => {
              const { value: newValue, isUpdated } = applyProcessing(v);

              if (debug)
                console.log(name, "fnOnBlur:", {
                  isUpdated,
                  oldValue: v,
                  newValue: newValue,
                });

              if (saveValueOnBlur || isUpdated) onChange(newValue); // Need to call this as value might have been changed by applyProcessing()
              // saveValueOnBlur is needed for the dateBox as there is no genuine onChange
              propsOnBlur();
              onBlur(); // The call the react-hook-form onBlur() so that the validation kicks in
            };

            const statusProps = {};
            if (showStatus) {
              statusProps.showValid = showValid;
              statusProps.showErrors = showErrors;
            }

            return (
              <Component
                data-cy={fngenerateCypressProps("input")}
                aria-invalid={invalid ? "true" : "false"}
                value={value}
                disabled={disabled}
                onChange={fnOnChange}
                onBlur={fnOnBlur}
                {...statusProps}
                {...otherProps}
              />
            );
          }}
        />
      </div>

      {children}

      {/* We need this div to permanently show */}
      <div
        className={_classNameFeedback}
        data-cy={fngenerateCypressProps("feedback")}
      >
        {errMsg && (
          <div
            className="error-text text-danger form-text"
            data-cy={fngenerateCypressProps("error")}
          >
            {errMsg}
          </div>
        )}

        {!errMsg ? feedbackMsg : undefined}
      </div>

      <DevContainer enabled={debug} heading={`DEBUG ${name}`}>
        <JsonViewer>
          {{
            fieldState: getFieldState(name),
            formState,
            showErrors,
            showValid,
            enableShowErrors,
            isSubmitted: formState.isSubmitted,
          }}
        </JsonViewer>
      </DevContainer>
    </div>
  );
};

const fnGenerateRuleMin = (val) => ({
  minLength: {
    value: val,
    message: `A minimum of ${val} characters`,
  },
});

const fnGenerateRuleMax = (val) => ({
  maxLength: {
    value: val,
    message: `A maximum of ${val} characters`,
  },
});

export const FormItemUsernameRegister = fnGenerateFormItemTemplate(
  {
    rules: {
      ...fnGenerateRuleMin(6),
      ...fnGenerateRuleMax(50),
    },
  },
  "username"
);

export const FormItemPasswordRegister = fnGenerateFormItemTemplate(
  {
    rules: {
      ...fnGenerateRuleMin(8),
      ...fnGenerateRuleMax(32),
      validate: {
        validPassword: (v) => {
          if (!isPassword(v)) return "Please enter a valid password";
        },
      },
    },
  },
  "password"
);

export const FormItemEmail = fnGenerateFormItemTemplate(
  {
    rules: {
      validate: {
        validEmail: (v) => {
          if (!isEmail(v)) return "Please enter a valid email";
        },
      },
      ...fnGenerateRuleMin(2),
      ...fnGenerateRuleMax(50),
    },
  },
  "email"
);

export const FormItemTemplates = {
  UsernameRegister: FormItemUsernameRegister,
  PasswordRegister: FormItemPasswordRegister,
  Email: FormItemEmail,
  Standard: FormItem,
};

export default FormItem;
