import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

// https://stackoverflow.com/questions/43441856/how-to-scroll-to-an-element
// https://bosctechlabs.com/scroll-to-an-element-in-react/
// https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition

export const fnScrollToTop = () => {
  document.documentElement.scrollTo(0, 0);
};

export const fnSnapToTop = () => {
  document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
};

export const useScrollToTop = (watchValue = true) => {
  useLayoutEffect(() => {
    if (!watchValue) return;
    fnScrollToTop();
  }, [watchValue]);
};

export const useSnapToTop = (watchValue = true) => {
  useLayoutEffect(() => {
    if (!watchValue) return;
    fnSnapToTop();
  }, [watchValue]);
};

const useScrollTo = (watchValue) => {
  const scrollToRef = useRef(null);
  const isInit = useRef(false);

  const fnScrollTo = () => {
    console.warn("SCROLLTO");
    scrollToRef.current.scrollIntoView();
  };

  const Anchor = () => {
    // useLayoutEffect(() => {
    //   if (!watchValue) return;
    //   fnScrollTo();
    // }, [watchValue]);
    return <span ref={scrollToRef}>ANCHOR</span>;

    // const location = useLocation();
    // useEffect(() => {
    //   const element = document.getElementById(location.hash);
    //   if (element) element.scrollIntoView();
    //   // setTimeout(() => {
    //   //   window.scrollTo({
    //   //     behavior: element ? "smooth" : "auto",
    //   //     top: element ? element.offsetTop : 0,
    //   //   });
    //   // }, 0);
    // }, [location]);

    // return null;

    // if (isInit.current === false) {
    //   isInit.current = true;
    //   return;
    // }

    // setTimeout(() => {
    //   fnScrollTo();
    // }, 0);

    return <span ref={scrollToRef} />;
  };

  // useEffect(() => {
  //   if (isInit.current === false) {
  //     isInit.current = true;
  //     return;
  //   }

  //   if (watchValue === true) {
  //     console.warn("SCROLLTO");

  //     setTimeout(() => {
  //       fnScrollTo();
  //     }, 100);
  //   }
  // }, [watchValue]);

  return [Anchor, fnScrollTo];
};

export default useScrollTo;
