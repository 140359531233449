import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import rdiff from "recursive-diff";

const useSalusCompare = (database, baseData, compareData) => {
  if (!database) throw `Error in useSalusCompare -- missing database`;

  // return {};

  // console.log("baseData", baseData);

  //NOTE:
  //

  const changes = rdiff
    .getDiff(baseData, compareData)
    .filter((x) => x.op === "update")
    .map((x) => {
      let change_key;
      // Try and find the path in database (check in REVERSE ORDER)
      for (let i = x.path.length - 1; i >= 0; i--) {
        const testPath = x.path.slice(0, i).join("/");
        change_key = database[testPath] || database[`${testPath}/*`];

        if (change_key) break;
      }
      const retData = { change_key, ..._.pick(x, ["val", "path"]) };
      // console.log("...", retData);
      return retData;
    })
    .filter((x) => x.change_key)
    .reduce((acc, cur) => {
      const retData = acc;
      retData[cur.change_key] = [
        ...(retData[cur.change_key] || []),
        {
          value: cur.val,
          path: cur.path.join("/"),
        },
      ];
      return retData;
    }, {});

  const retData = { changes, baseData, compareData };

  // console.log("useSalusCompare", { changes });
  return retData;
};

export default useSalusCompare;
