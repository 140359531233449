import React, { useState, useEffect, useRef, useCallback } from "react";
import LibraryDatePickerTextBox from "@library/common/components/base/form/DatePicker/Textbox";
import { generateCypressProps } from "siteFunctions/cypress";
import _ from "lodash";
import { TextBox } from "./base";
import { ButtonToggle, ButtonToggleMta } from "./Buttons";

import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
dayjs.extend(require("dayjs/plugin/isSameOrBefore"));
dayjs.extend(require("dayjs/plugin/isBetween"));

import dateHelpers from "@library/common/helpers/misc/date";
import enLocale from "date-fns/locale/en-GB";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { StaticDatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";

export const DatePickerTextBox = (props) => {
  const {
    className,
    showValid = false,
    showErrors = false,
    "data-cy": dataCy,
    disabled,
    ...otherProps
  } = props;

  const _className = [
    "lctr-library-datePicker-text",
    "form-control",
    "d-flex",
    "date-picker",
    "justify-content-center",
    // props.disabled ? "disabled" : "",
    className,
  ].toClassName();

  return (
    <div
      className={_className}
      disabled={disabled}
      {...generateCypressProps("datepicker", props)}
    >
      <LibraryDatePickerTextBox
        {...otherProps}
        disabled={disabled}
        TextBox={TextBox}
        classNameDay="append-slash"
        classNameMonth="append-slash"
        classNameYear=""
        classNameDayInput="text-center d-inline-block day"
        classNameMonthInput="text-center d-inline-block month"
        classNameYearInput="text-center d-inline-block year"
      />
    </div>
  );
};

export const DatePickerTextBoxForm = (props) => {
  const newProps = {
    ...props,
    onChange: props.onBlur,
  };

  return <DatePickerTextBox {...newProps} />;
};

export const DatePickerWithButtons = (props) => {
  const {
    onChange,
    value,
    // className,
    // label = "Choose your start date",
    // Container = DefaultContainer,
    forceShowCalendar = false,
    filterDaysBefore = undefined,
    filterDaysAfter = undefined,
    filterMinDate,
    filterMaxDate,
    labelDateFallback = "Choose a date",
  } = props;

  const today = new dayjs().startOf("day");

  const minDate = (function () {
    if (filterMinDate) return filterMinDate;
    if (filterDaysBefore !== undefined)
      return today
        .clone()
        .add(-1 * filterDaysBefore, "days")
        .toDate();

    return undefined;
  })();

  const maxDate = (function () {
    if (filterMaxDate) return filterMaxDate;
    if (filterDaysAfter !== undefined)
      return today.clone().add(filterDaysAfter, "days").toDate();

    return undefined;
  })();

  const _onChange = (value) => onChange(value);

  const buttonData = [
    {
      label: "Today",
      value: today.clone().toDate(),
      type: "date",
      dataCy: "btn-today",
    },
    {
      label: "Tomorrow",
      value: today.clone().add(1, "days").toDate(),
      type: "date",
      dataCy: "btn-tomorrow",
    },
  ]
    .filter((x) => dayjs(x.value).isBetween(minDate, maxDate, "day", "[]"))
    .concat({
      label: (
        <span className="d-flex justify-content-between">
          <span className="pe-2">
            <i className="bi bi-calendar-event" />
          </span>
          <span>Pick a date</span>
        </span>
      ),
      showCalendar: true,
      type: "showCalendar",
      dataCy: "btn-pickdate",
    });

  const isButtonsEnabled = (function () {
    return buttonData.some((x) => x.type === "date");
    // return buttonData
    //   .filter((x) => x.value)
    //   .map((x) => x.value)
    //   .some((v) => dayjs(v).isBetween(minDate, maxDate, "day", "[]"));
  })();

  const isButtonsContainCurrentDate = buttonData
    .filter((b) => b.value)
    .some((b) => dayjs(b.value).isSame(dayjs(value)));

  const [isCalendarVisible, setIsCalendarVisible] = useState(
    (function () {
      if (forceShowCalendar) return true;
      if (!isButtonsEnabled) return true;
      if (value && !isButtonsContainCurrentDate) return true;
      return false;
    })()
  );

  const buttonDataFinal = (function () {
    if (!isButtonsEnabled) return undefined;

    return buttonData.map((b, i) => {
      const isSelected = (function () {
        if (b.value !== undefined) {
          if (forceShowCalendar) return false;
          if (isCalendarVisible) return false;
          if (dayjs(b.value).isSame(dayjs(value))) return true;
          return false;
        }

        if (b.showCalendar) {
          if (isCalendarVisible) return true;
          return false;
        }

        return false;
      })();

      const onClick = (function () {
        if ("value" in b)
          return () => {
            _onChange(b.value);
            if (!forceShowCalendar) setIsCalendarVisible(false);
          };

        if (b.showCalendar)
          return () => {
            if (!forceShowCalendar) setIsCalendarVisible(b.showCalendar);
          };

        return undefined;
      })();

      return (
        <ButtonToggleMta
          key={i}
          isActive={isSelected}
          onClick={onClick}
          className="me-2 mb-2"
          data-cy={b.dataCy}
        >
          {b.label}
        </ButtonToggleMta>
      );
    });
  })();

  // console.log("DatePickerWithButtons.jsx", {
  //   buttonData,
  //   buttonDataFinal,
  //   isCalendarVisible,
  //   isButtonsEnabled,
  //   minDate,
  //   maxDate,
  // });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
      <div
        className="row datepicker-with-buttons"
        {...generateCypressProps("datepicker-with-buttons", props)}
      >
        {buttonDataFinal && <div className="col-12">{buttonDataFinal}</div>}

        {isCalendarVisible && (
          <div className="fb-datepicker col-12 col-md-10 col-lg-auto">
            <StaticDatePicker
              //   displayStaticWrapperAs="desktop"
              showToolbar={true}
              //   openTo="day"
              value={value}
              onChange={(newValue) => {
                _onChange(newValue);
              }}
              slotProps={{
                textField: { variant: "outlined" },
                actionBar: { hidden: true },
                toolbar: { hidden: true },
              }}
              toolbarPlaceholder="Please choose"
              toolbarFormat="EEEE d MMM yyyy"
              minDate={minDate}
              maxDate={maxDate}
              //   disableOpenPicker={true}
            />
          </div>
        )}

        <div className="col-12 datepicker-date-display">
          {value ? (
            dayjs(value).format("dddd DD MMM YYYY")
          ) : (
            <span>{labelDateFallback}</span>
          )}
        </div>
      </div>
    </LocalizationProvider>
  );
};
