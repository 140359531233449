// https://dev.azure.com/FreedomServicesGroup/Odyssey/_workitems/edit/2922
import { useCallback } from "react";
import { mtaCar } from "siteStore";
import { store } from "siteStore";
import TagManager from "react-gtm-module-with-nonce";
import config from "config";

const dataLayerName = "dataLayer";
if (config.google.gtmId) {
  TagManager.initialize({
    gtmId: config.google.gtmId,
    dataLayerName: dataLayerName,
  });
}

const fnLogToGoogle = (args = {}) => {
  const tagManagerArgs = {
    dataLayer: args,
    dataLayerName: dataLayerName,
  };
  TagManager.dataLayer(tagManagerArgs);
};

const fnGenerateLoggingFunctions = (args = {}) => {
  const { mtaStore } = args;

  const fnGetPayloadMta = () => {
    const state = store.getState();
    const policyData = mtaStore.selectors.getPolicyData(state);
    return {
      PolicyID: policyData?.PolicyNumber,
      QuoteId: policyData?.WebReference,
    };
  };

  const fnLog = (args = {}) => {
    console.log("analytics:", args.event, args);
    fnLogToGoogle(args);
  };

  return {
    login: {
      start: () => fnLog({ event: "login.start" }),
      success: () => fnLog({ event: "login.result", Result: "successful" }),
      fail: () => fnLog({ event: "login.result", Result: "unsuccessful" }),
    },
    mta: {
      start: () => fnLog({ event: "mta.start", ...fnGetPayloadMta() }),
      typeChange: (type) =>
        fnLog({
          event: "mta.type",
          Type: type,
          ...fnGetPayloadMta(),
        }),
      quote: (PolicyGross) =>
        fnLog({
          event: "mta.quote",
          ...fnGetPayloadMta(),
          PolicyGross: PolicyGross,
        }),
      complete: () =>
        fnLog({
          event: "mta.complete",
          ...fnGetPayloadMta(),
        }),
    },
  };
};

//** HOOKS **
const useAnalyticsBase = (args = {}) => {
  const { mtaStore } = args;
  // const state = useSelector((state) => state);

  const functions = useCallback(fnGenerateLoggingFunctions({ mtaStore }), []);
  return functions;
};

export const useAnalyticsCar = () => useAnalyticsBase({ mtaStore: mtaCar });

//** LISTENER **
// imported in client/store/storeData.js
export const storeListeners = (listenerMiddleware, stores = {}) => {
  const { auth, mta = [] } = stores;

  //Authentication
  if (auth) {
    const functions = fnGenerateLoggingFunctions();
    listenerMiddleware.startListening({
      predicate: (action) =>
        `${auth.storeName}/authenticate/pending` === action.type,
      effect: async () => functions.login.start(),
    });

    listenerMiddleware.startListening({
      predicate: (action) =>
        `${auth.storeName}/authenticate/fulfilled` === action.type,
      effect: async (action) => {
        if (action.payload.isAuthenticated) functions.login.success();
        else functions.login.fail();
      },
    });
  }

  // MTA LOGGING
  mta.forEach((mtaStore) => {
    const functions = fnGenerateLoggingFunctions({ mtaStore });

    // MTA START
    listenerMiddleware.startListening({
      predicate: (action) =>
        `${mtaStore.storeName}/loadData/fulfilled` === action.type,
      effect: async () => functions.mta.start(),
    });

    // MTA QUOTE
    listenerMiddleware.startListening({
      predicate: (action) =>
        `${mtaStore.storeName}/getQuote/fulfilled` === action.type,
      effect: async (action) => {
        if (!action.payload?.response?.isSuccess) return;

        functions.mta.quote(
          action.payload.response.data?.TotalAdjustmentAmount //PolicyGross
        );
      },
    });
  });
};
