import postProcessAxiosFunctions from "@library/configs/site/configItem/postProcessAxiosFunctions";
import _ from "lodash";
import nodecache from "node-cache";

import {
  createService,
  createServiceSessionId,
  createServiceSecure,
  generateSet,
  EndPointSet as _EndPointSet,
  endPointItem as _endPointItem,
} from "./resources";

// const lookupPostProcessAxiosStandard = [
//   postProcessAxiosFunctions.JsonOptions,
//   postProcessAxiosFunctions.JSONParse,
//   postProcessAxiosFunctions.convertLabels,
// ];

const list = [
  "person-advqual",
  "person-employstatus-second",
  "vehicle-immobiliser",
  "vehicle-van-classofuse",
  "vehicle-owner",
  "vehicle-alarm",
  "vehicle-van-bodytype",
  "person-deliverycompany",
  "vehicle-van-modlist",
  "person-licence-type",
  "vehicle-car-classofuse",
  "vehicle-keptovernight",
  "policy-drivingrestriction",
  "security-questions",
  "person-motoringorg",
  "vehicle-fueltype",
  "person-motorclaim",
  "person-drivingexperiencetype",
  //   "person-title",
  "policy-paymethod",
  "person-motorconviction",
  "excess-reason",
  "vehicle-car-classofuse2",
  "vehicle-van-makes-models",
  "person-relationship",
  "vehicle-car-modlist",
  "person-dvlarestrictions",
  "months",
  "vehicle-car-makes-models",
  "person-alcoholreading",
  "person-industries",
  "person-employstatus-main",
  "person-motorclaimswithcategory",
  "person-maritalstatus",
  "vehicle-transmission",
  "excess-policysection",
  "person-jobtitles",
  "person-parceldrops",
  "person-vehicleuse",
  "vehicle-van-bodysubtype",
  "contact-us-types",
  "vehicle-car-bodytype",
  "vehicle-tracker",
  "person-title-stella",
  "vehicle-years",
  "person-vehicleaccess",
];

const fnDebugList = (list) => {
  //https://stackoverflow.com/questions/18936915/dynamically-set-property-of-nested-object
  const retData = {};

  function set(path, value) {
    // console.log(path, value, retData);
    var schema = retData; // a moving reference to internal objects within obj
    var pList = path.split("-");
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }

    schema[pList[len - 1]] = value;
  }

  list.forEach((listName) => {
    set(listName, `fnGenEndPoint("${listName}")`);
  });

  console.log(retData);
};

const serviceDataCache = new nodecache();
// const cacheKey = "service-all";
const timeDelay = 5000;
//serviceDataCache

const generateLookups = (endPointSet) => {
  if (false) {
    fnDebugList(list);
    throw `fake error`;
  }

  const fnGetAll = createServiceSessionId({
    axiosOptions: {
      method: "POST",
      headers: {
        "x-api-key": endPointSet.apiKey,
      },
      url: `${endPointSet.url}`,
      data: {
        Operation: "all",
      },
    },
  });

  setTimeout(async () => {
    const results = await fnGetAll();
    if (!results) return;
    results.forEach((x) => serviceDataCache.set(x.ListName, x.ListOption));
    console.log("LISTS cached");
  }, timeDelay);

  const fnGenEndPoint = (
    listName,
    postProcessAxios = [
      postProcessAxiosFunctions.JsonOptions,
      postProcessAxiosFunctions.JSONParse,
      postProcessAxiosFunctions.convertLabels,
    ],
    fnCreateService = createServiceSessionId
  ) => {
    const fnFallback = fnCreateService({
      postProcessAxios: postProcessAxios,
      axiosOptions: {
        method: "POST",
        headers: {
          "x-api-key": endPointSet.apiKey,
        },
        url: `${endPointSet.url}`,
        data: {
          Operation: "single",
          ListName: listName,
        },
      },
    });

    // Final function
    return (...args) => {
      if (serviceDataCache.has(listName)) {
        const foundData = serviceDataCache.get(listName);
        console.log("HITTING CACHE:", listName);

        const foundDataProcessed = postProcessAxios.reduce(
          (_v, curFn) => curFn(_v),
          foundData
        );
        return Promise.resolve(foundDataProcessed);
      }

      return fnFallback(...args);

      // .then((response) => {
      //   console.log("response", listName, response);
      //   return response;
      // });
    };
  };

  const retData = {
    // all: fnGetAll,
    person: {
      advqual: fnGenEndPoint("person-advqual"),
      employstatus: {
        second: fnGenEndPoint("person-employstatus-second"),
        main: fnGenEndPoint("person-employstatus-main"),
      },
      deliverycompany: fnGenEndPoint("person-deliverycompany"),
      licence: {
        type: fnGenEndPoint("person-licence-type"),
      },
      motoringorg: fnGenEndPoint("person-motoringorg"),
      motorclaim: fnGenEndPoint("person-motorclaim"),
      drivingexperiencetype: fnGenEndPoint("person-drivingexperiencetype"),
      motorconviction: fnGenEndPoint("person-motorconviction"),
      relationship: fnGenEndPoint("person-relationship"),
      dvlarestrictions: fnGenEndPoint("person-dvlarestrictions"),
      alcoholreading: fnGenEndPoint("person-alcoholreading"),
      industries: fnGenEndPoint("person-industries"),
      motorclaimswithcategory: fnGenEndPoint("person-motorclaimswithcategory", [
        postProcessAxiosFunctions.JsonOptions,
        postProcessAxiosFunctions.JSONParse,
        postProcessAxiosFunctions.splitByCategory,
      ]),
      maritalstatus: fnGenEndPoint("person-maritalstatus"),
      jobtitles: fnGenEndPoint("person-jobtitles"),
      parceldrops: fnGenEndPoint("person-parceldrops"),
      vehicleuse: fnGenEndPoint("person-vehicleuse"),
      gender: fnGenEndPoint("person-gender"),
      title: fnGenEndPoint("person-title-dr-stella", [
        postProcessAxiosFunctions.JsonOptions,
        postProcessAxiosFunctions.JSONParse,
        postProcessAxiosFunctions.convertLabels,
        // postProcessAxiosFunctions.title,
        (response) => {
          if (!response) return [];

          const retData = response.map((item) => {
            const splitData = item.value.split("-");
            const title = splitData[0];
            const gender = splitData[1]; //May not exist
            return { ...item, value: JSON.stringify({ title, gender }) };
          });

          return retData;
        },
      ]),

      titleWithoutSessionId: fnGenEndPoint(
        "person-title-dr-stella",
        [
          postProcessAxiosFunctions.JsonOptions,
          postProcessAxiosFunctions.JSONParse,
          postProcessAxiosFunctions.convertLabels,
          // postProcessAxiosFunctions.title,
          (response) => {
            if (!response) return [];

            const retData = response.map((item) => {
              const splitData = item.value.split("-");
              const title = splitData[0];
              const gender = splitData[1]; //May not exist
              return { ...item, value: JSON.stringify({ title, gender }) };
            });

            return retData;
          },
        ],
        createService //#3408 -- use the basic service
      ),
      // title: {
      //   stella: fnGenEndPoint("person-title-stella", [
      //     postProcessAxiosFunctions.JsonOptions,
      //     postProcessAxiosFunctions.JSONParse,
      //     postProcessAxiosFunctions.convertLabels,
      //     postProcessAxiosFunctions.title,
      //   ]),
      // },
      vehicleaccess: fnGenEndPoint("person-vehicleaccess"),
    },
    vehicle: {
      immobiliser: fnGenEndPoint("vehicle-immobiliser"),
      van: {
        classofuse: fnGenEndPoint("vehicle-van-classofuse"),
        bodytype: fnGenEndPoint("vehicle-van-bodytype"),
        modlist: fnGenEndPoint("vehicle-van-modlist", [
          postProcessAxiosFunctions.JsonOptions,
          postProcessAxiosFunctions.JSONParse,
          postProcessAxiosFunctions.splitByCategory,
        ]),
        makes: {
          models: fnGenEndPoint("vehicle-van-makes-models", [
            postProcessAxiosFunctions.JsonOptions,
            postProcessAxiosFunctions.JSONParse,
            postProcessAxiosFunctions.manualManufacturer,
          ]),
        },
        bodysubtype: fnGenEndPoint("vehicle-van-bodysubtype"),
      },
      owner: fnGenEndPoint("vehicle-owner"),
      alarm: fnGenEndPoint("vehicle-alarm"),
      car: {
        classofuse: fnGenEndPoint("vehicle-car-classofuse"),
        classofuseStella: fnGenEndPoint("vehicle-car-classofuse-stella"),
        classofuseStellaDisplay: fnGenEndPoint(
          "vehicle-car-classofuse-stella-display"
        ),
        modlist: fnGenEndPoint("vehicle-car-modlist", [
          postProcessAxiosFunctions.JsonOptions,
          postProcessAxiosFunctions.JSONParse,
          postProcessAxiosFunctions.splitByCategory,
        ]),
        makes: {
          models: fnGenEndPoint("vehicle-car-makes-models", [
            postProcessAxiosFunctions.JsonOptions,
            postProcessAxiosFunctions.JSONParse,
            postProcessAxiosFunctions.manualManufacturer,
          ]),
        },
        bodytype: fnGenEndPoint("vehicle-car-bodytype"),
      },
      keptovernight: fnGenEndPoint("vehicle-keptovernight"),
      fueltype: fnGenEndPoint("vehicle-fueltype", [
        postProcessAxiosFunctions.JsonOptions,
        postProcessAxiosFunctions.JSONParse,
        postProcessAxiosFunctions.convertLabels,
        (response) => {
          if (!response) return response;
          return response.map((x) => {
            let newValue = x.value;
            switch (x.value) {
              case "FuelPetrol":
                newValue = "P";
                break;
              case "FuelDiesel":
                newValue = "D";
                break;
              case "FuelElec":
                newValue = "E";
                break;
            }
            return { ...x, value: newValue };
          });
        },
      ]),
      transmission: fnGenEndPoint("vehicle-transmission"),
      tracker: fnGenEndPoint("vehicle-tracker"),
      years: fnGenEndPoint("vehicle-years"),

      annualMileage: () => {
        let retData = [];
        for (let i = 1; i <= 25; i++) {
          let val = i * 1000;
          let display = val.toString();
          retData.push({ label: `Up to ${display}`, value: `${val}` });
        }

        retData.push({
          label: `Up to ${"30,000"}`,
          value: "30000",
        });
        retData.push({
          label: `Up to ${"50,000"}`,
          value: "50000",
        });
        retData.push({ label: `Over 50,000`, value: "60000" });
        return Promise.resolve(retData);
      },
    },
    policy: {
      drivingrestriction: fnGenEndPoint("policy-drivingrestriction"),
      paymethod: fnGenEndPoint("policy-paymethod"),
    },
    security: {
      questions: fnGenEndPoint("security-questions"),
    },
    excess: {
      reason: fnGenEndPoint("excess-reason"),
      policysection: fnGenEndPoint("excess-policysection"),
    },
    months: fnGenEndPoint("months"),
    contact: {
      us: {
        types: fnGenEndPoint("contact-us-types"),
      },
    },
  };

  return retData;
};

export default generateLookups;
