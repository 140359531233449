import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import _ from "lodash";
import Sticky from "react-sticky-el";

const StickyComponent = (props) => {
  const {
    children,
    // stickyContent = props.children,
    stickyClassName,
    className,

    topOffset,
    onChange = () => null,
  } = props;

  // const [isSticky, setIsSticky] = useState(false);

  // return (
  //   <div className={["sticky-top", stickyClassName].toClassName()}>
  //     {children}
  //   </div>
  // );

  // //   const [isSticky, setIsSticky] = useState(false);

  const _onChange = (newIsSticky) => {
    // setIsSticky(newIsSticky);
    onChange(newIsSticky);
  };

  const _className = [
    className,
    //  isSticky && stickyClassName
  ].toClassName();

  // console.log("dsdadsad", { isSticky, _className });

  return (
    <Sticky
      onFixedToggle={_onChange}
      className={_className}
      stickyClassName={stickyClassName}
      topOffset={topOffset}
    >
      {children}
    </Sticky>
  );
};

export default StickyComponent;
