import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";

const useValueSet = (initialData = {}) => {
  const [data, setData] = useState(initialData);

  const fnSetValue = (key, value) => {
    setData((prevState) => {
      const newData = _.cloneDeep(prevState);
      newData[key] = value;
      return newData;
    });
  };

  return [data, fnSetValue];
};

export default useValueSet;
