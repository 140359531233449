import generateSlice from "./slice";
import generateActions from "./actions";
import generateSelectors from "./selectors";
import generateHooks from "./hooks";
import generateHelpers from "./helpers";
import { database as migrateDatabase } from "./utils/migration";

const generateStore = (args = {}) => {
  const { storeName, services = {}, selectors, rules, dev, template } = args;

  const fnThrowError = (msg, _storeName = storeName) => {
    throw ["Error in generateStore", _storeName, "-", msg]
      .filter((x) => x)
      .join(" ");
  };

  if (!storeName) fnThrowError("missing storeName");

  if (!dev?.migrate?.database && !template) fnThrowError("missing template");

  const { rules: _rules, template: _template } = (function () {
    if (rules && template) return { rules, template };

    if (dev?.migrate?.database) {
      console.log("MIGRATING RULES", dev.migrate);

      const retdata = migrateDatabase(
        dev.migrate.database, // The old style database
        dev.migrate.rules
      );

      if (dev.migrate.rulesAdditional)
        retdata.rules = { ...retdata.rules, ...dev.migrate.rulesAdditional };

      // console.log("dddddd", dev.migrate, retdata);
      // throw `hh`;
      return retdata;
    }

    return {};
  })();

  if (!_rules) fnThrowError("missing rules");
  if (!_template) fnThrowError("missing template");

  const baseArgs = {
    ...args,
    rules: _rules,
    template: _template,
  };

  // console.log(baseArgs);
  // throw `ddd`;

  const _actions = generateActions(baseArgs);
  const _selectors = generateSelectors(baseArgs);
  const _slice = generateSlice({
    ...baseArgs,
    privateActions: _actions,
  });

  const _hooks = generateHooks({
    ...baseArgs,
    storageSelectors: _selectors,
    storageActions: { ..._actions, ..._slice.actions },
  });

  const _helpers = generateHelpers({
    ...baseArgs,
    actions: { ..._actions, ..._slice.actions },
    selectors: _selectors,
  });

  const retData = {
    storeName,
    reducer: _slice.reducer,
    actions: { ..._actions, ..._slice.actions },
    selectors: _selectors,
    hooks: _hooks,
    helpers: _helpers,
    template: _template,
  };

  console.log("STORE", storeName, "=", retData);
  return retData;
};

export default generateStore;
