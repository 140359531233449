import generateSlice from "./slice";
import generateActions from "./actions";
import generateSelectors from "./selectors";
import helpers from "./helpers";
import generateHooks from "./hooks";

const generateStore = (args = {}) => {
  const { storeName, services = {}, stores = {} } = args;

  const fnThrowError = (msg, _storeName = storeName) => {
    throw ["Error in generateStore", _storeName, "-", msg]
      .filter((x) => x)
      .join(" ");
  };

  if (!storeName) fnThrowError("missing storeName");

  // ["auth"].forEach((k) => {
  //   if (!(k in stores)) fnThrowError(`missing "stores.${k}"`);
  // });

  [
    "getPolicyDocuments",
    "mailDocument",
    // "setDocumentDownloaded",
    "getDocument",
    "upload",
  ].forEach((k) => {
    if (!(k in services)) fnThrowError(`missing "services.${k}"`);
  });

  const _actions = generateActions(args);
  const _selectors = generateSelectors(args);
  const _slice = generateSlice({
    ...args,
    privateActions: _actions,
  });

  const _hooks = generateHooks({
    ...args,
    selectors: _selectors,
    actions: { ..._actions, ..._slice.actions },
  });

  const retData = {
    storeName,
    reducer: _slice.reducer,
    actions: { ..._actions, ..._slice.actions },
    selectors: _selectors,
    helpers: helpers,
    hooks: _hooks,
  };

  console.log("STORE", storeName, "=", retData);
  return retData;
};

export default generateStore;
