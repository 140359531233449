import * as siteComponentsMta from "siteComponents"; //** NOTE: Import from a different folder of composed components **
import { Button } from "siteComponents";
import React from "react";
import _ from "lodash";

const ButtonWithLabel = (props) => {
  const { label, children, ...otherProps } = props;
  //   return <div>xxx</div>;
  return <Button {...otherProps}>{label || children}</Button>;
};

const FormGroupError = (props) => {
  const { children, className, id, ...otherProps } = props;

  const _className = ["form-text", "text-danger", className]
    .filter((x) => x)
    .join(" ");

  if (!children) return null;
  return (
    <div id={id} className={_className} {...otherProps}>
      {children}
    </div>
  );
};

const componentResources = {
  FormGroup: {
    ..._.pick(siteComponentsMta, ["Help", "HelpFurther"]),
    Error: FormGroupError,
    Container: (props) => {
      // console.log("dddd", props)
      return (
        <div
          className={[
            "lctr-formgroup-container-mta",
            "fb-form-group",
            "py-4",
            //"row",   // ** Commmented out 07/10 to fix mobile wiggle **
            // "pb-3",
            //"px-md-3",
            props.hasError ? "has-error" : "",
            props.hasHelpText ? "has-helptext" : "",
            props.className,
          ].toClassName()}
        >
          {props.children}
        </div>
      );
    },
    Children: (props) => (
      <div
        className={[
          `lctr-formgroup-children`,
          `col-${props.xsCols ? props.xsCols : "12"}`,
          props.className,
        ]
          .filter((x) => x)
          .join(" ")}
      >
        {props.children}
      </div>
    ),
    className: {
      label: "col-12 fb-formlabel",
      helpText: "col-12 mb-3",
      helpTextFurther: "col-12 mb-2",
    },
  },
  //NOTE: No need for Vehicle as we have a local template
  Vehicle: {
    ..._.pick(siteComponentsMta, [
      "DropDownType",
      "RadioButtonsWebService",
      "DropDownTypeWebService",
    ]),
    Button: ButtonWithLabel,
    SubPanel: (props) => <span {...props} />,
  },
  Address: {
    ..._.pick(siteComponentsMta, ["TextBox", "TextBoxPostcode"]),
  },
  Modifications: {
    ..._.pick(siteComponentsMta, ["DropDownTypeWebService"]),
  },
};

export default componentResources;
