import React from "react";
import Base from "../Base";

const MaintenanceView = (props) => {

  return (
    <Base
      heading="Our site is down for maintenance"
      icon="icon-repairing"
    >
      <div >
        We're sorry but we're currently busy making changes to our site so it's not available right now.
      </div>
      <div >Please try again later. We'll be as quick as we can!</div>
    </Base>
  );
};

export default MaintenanceView;
