import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import * as regExConstants from "@library/common/constants/regEx";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

import Base from "./Base";
import ButtonPanel from "./ButtonPanel";
import PriceDetails from "./PriceDetails";
import PriceDisplay from "./PriceDetails/Components/PriceDisplay";

import useDirectDebit from "moveToLibrary/hooks/useDirectDebit";

import { useOutletContext, useLocation, Navigate } from "react-router-dom";
import {
  LoadingRow,
  DevContainer,
  ButtonStyled,
  Button,
  PanelRenewal,
  TextBox,
  Checkbox,
  Help,
  Error,
  ExternalLink,
} from "siteComponents";

const SortCode = (props) => {
  ["value", "onChange", "id"].forEach((x) => {
    if (!(x in props)) {
      console.log("sortcode props:", props);
      throw `Error in Sortcode -- missing a prop: ${x}`;
    }
  });
  const { onChange, value = "", id, disabled } = props;

  const [pt1, setPt1] = useState();
  const [pt2, setPt2] = useState();
  const [pt3, setPt3] = useState();

  useEffect(() => {
    //** if not valid, then return "undefined"
    if (value === undefined || isNaN(value) || value.length !== 6) {
      onChange(undefined);
      return;
    }

    // ...else split "value"
    setPt1(value.slice(0, 2));
    setPt2(value.slice(2, 4));
    setPt3(value.slice(4, 6));
  }, [value]);

  const fnUpdate = (section, value) => {
    //NOTE: won't be actually updated until next render (i.e. after this function has finished)
    if (section === "1") setPt1(value);
    if (section === "2") setPt2(value);
    if (section === "3") setPt3(value);

    const _pt1 = section === "1" ? value : pt1;
    const _pt2 = section === "2" ? value : pt2;
    const _pt3 = section === "3" ? value : pt3;

    // check all 3 parts
    const isValid = ![_pt1, _pt2, _pt3].some(
      (x) => x === undefined || isNaN(x) || x.length !== 2
    );

    if (!isValid) {
      onChange(undefined);
      return;
    }
    onChange(`${_pt1}${_pt2}${_pt3}`);
  };

  return (
    <div className="row">
      <div className="col-auto">
        <TextBox
          value={pt1}
          onChange={(v) => fnUpdate("1", v)}
          regEx={regExConstants.numbers.whole}
          maxLength="2"
          size="2"
          id={id}
          autoComplete="off"
          className="text-center"
          disabled={disabled}
        />
      </div>
      <div className="col-auto align-middle p-0 m-0 px-1 my-auto fw-bold">
        -
      </div>
      <div className="col-auto">
        <TextBox
          value={pt2}
          onChange={(v) => fnUpdate("2", v)}
          regEx={regExConstants.numbers.whole}
          maxLength="2"
          size="2"
          autoComplete="off"
          className="text-center"
          disabled={disabled}
        />
      </div>
      <div className="col-auto align-middle p-0 m-0 px-1 my-auto fw-bold">
        -
      </div>
      <div className="col-auto">
        <TextBox
          value={pt3}
          onChange={(v) => fnUpdate("3", v)}
          regEx={regExConstants.numbers.whole}
          maxLength="2"
          size="2"
          autoComplete="off"
          className="text-center"
          disabled={disabled}
        />
      </div>
    </div>
  );
};


const DirectDebitInfoBox = (props) => {
  const { className, classNameImg, classNameAddress } = props;

  return (
    <div className={className}>
      <div className={classNameImg}>
        <img
          src={require("siteAssets/images/directdebit/direct-debit-logo.png")}
          alt="Direct Debit Logo"
          className={"img-fluid border border-dark dd-img"}
        />
      </div>

      <div className={classNameAddress}>
        <strong className="pb-2 small">Finance is provided by</strong>
        <address className="m-0 small">
          Premium Credit Limited
          <br />
          Premium Credit House
          <br />
          60 East Street
          <br />
          Epsom
          <br />
          Surrey
          <br />
          KT17 1HB
        </address>
      </div>
    </div>
  );
};

const DDHeading = ({ children, dataCy }) => (
  <div className="col-12 mb-2"><div className="dd-heading" data-cy={`dd-subheading-${dataCy}`}>{children}</div></div>
);

const DDTextBlock = ({ children, className, dataCy }) => (
  <div className={["col-12", "mb-3", className].filter(Boolean).join(" ")} data-cy={`dd-textblock-${dataCy}`}>
    {children}
  </div>
);

const ComponentValidating = () => (
  <div className="d-flex">
    <CircularProgress size="1.5rem" />
    <span className="ps-2 align-self-center">
      <strong>Checking your bank details... </strong>
    </span>
  </div>
);
const ComponentOkay = () => (
  <div className="text-purple d-flex">
    <CheckRoundedIcon />
    <span className="ps-2 align-self-center">
      Your bank details have been validated.
    </span>
  </div>
);
const ComponentError = () => (
  <div className="text-red d-flext">
    <ReportProblemOutlinedIcon />
    <span className="ps-2 align-self-center">
      Sorry, those details don't appear to be valid. Please check and try again.
    </span>
  </div>
);

const RenewalDirectDebitView = (props) => {
  const commonData = useOutletContext();
  const mainData = commonData.values.data;
  const policyData = commonData.values.policyData;
  const paymentMethod = commonData.values.chosenOptions.paymentMethod;

  const directDebitData = useDirectDebit(
    commonData.functions.process.bank.validateBankDetails
  );

  const [showErrors, setShowErrors] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isStickyAnimationEnabled, setIsStickyAnimationEnabled] =
    useState(false);

  // const [DDStatus, setDDStatus] = useState();
  const [DDConfirmation, setDDConfirmation] = useState(undefined);
  const [DDErrShowConfirmation, setDDErrShowConfirmation] = useState(false);

  const chosenQuoteInstalments =
    commonData.values.data.RenewalResult.Instalments[0];

  const isValidating = directDebitData.status.isValidating;

  const DDStatus = (function () {
    if (isValidating) return <ComponentValidating />;
    if (directDebitData.status.isValid) return <ComponentOkay />;
    if (directDebitData.status.isInvalid) return <ComponentError />;

    return undefined;
  })();

  // ** auto renewal **
  const autoRenewalOptIn = commonData.values.chosenOptions.autoRenewal;
  const fnUpdateAutoRenewalOptIn = (v) =>
    commonData.functions.options.updateAutoRenewal(v);

  // ** Payment Launcher **
  useEffect(() => {
    // if (loadingData.isCompleting) return;
    if (_.isEmpty(commonData.values.paymentLauncher)) return;

    console.log("paymentLauncher", commonData.values.paymentLauncher);
    if (commonData.values.paymentLauncher.RedirectUrl) {
      commonData.functions.navigate.loadUrl(
        commonData.values.paymentLauncher.RedirectUrl
      );
    }
  }, [_.isEmpty(commonData.values.paymentLauncher)]);

  const errorData = (function () {
    if (!DDConfirmation) {
      return {
        disableNextButton: true,
      };
    }

    if (directDebitData.status.isValidating) {
      return {
        disableNextButton: true,
      };
    }

    if (directDebitData.status.errorMessage) {
      return {
        errorMessage: showErrors && directDebitData.status.errorMessage,
        disableNextButton: true,
      };
    }

    if (directDebitData.status.isInvalid) {
      return {
        errorMessage: showErrors && "Please correct the errors above",
        disableNextButton: true,
      };
    }

    if (commonData.status.paymentLauncher.error)
      return {
        errorMessage: commonData.status.paymentLauncher.error,
        disableNextButton: false,
      };

    return { disableNextButton: false };
  })();

  const headingData = (function () {
    const headingText = "Your renewal premium";

    const _RenewalResult = {
      ...mainData?.RenewalResult,
      AllowPayInFull: false,
    };

    const descriptionContent = (
      <PriceDetails
        RenewalResult={_RenewalResult}
        isStickyAnimationEnabled={isStickyAnimationEnabled}
        isSticky={isSticky && isStickyAnimationEnabled}
        selectedPayMethod={paymentMethod}
      //allowOnlineWrapup={policyData.Mta.online}
      />
    );

    const showHeader = true;
    return { headingText, descriptionContent, showHeader };
  })();

  console.log("RenewalDirectDebitView.jsx", {
    showErrors,
    DDStatus,
    DDConfirmation,
    DDErrShowConfirmation,
    directDebitData,
    commonData,
    errorData,
  });

  return (
    <Base
      pageId="DIRECTDEBIT"
      data-cy="renewal-direct-debit"
      isSticky={true} // implemented in PageLayoutBase
      onHeaderStick={() => {
        setIsSticky(true);
        setIsStickyAnimationEnabled(true);
      }}
      onHeaderUnstick={() => {
        setIsSticky(false);
      }}
      className="renewal-directdebit"
      headingText={headingData.headingText}
      descriptionContent={headingData.descriptionContent}
      showHeader={headingData.showHeader}
    >
      <div className="mb-4 ps-2 row">
        <div className="col-6 col-md-auto">
          <Button
            onClick={commonData.functions.navigate.start}
            className="btn btn-sm btn-outline-dark"
            data-cy="goto-route-exit"
          >
            Back
          </Button>
        </div>
      </div>

      <PanelRenewal
        heading={"Set up your Direct Debit"}
        data-cy="panel-direct-debit"
      >
        <div className="row mb-2" data-cy="section-instalment-details">
          <div className="col-12">
            <p>
              By choosing to finance the cost of your insurance you'll need to
              pay an initial payment of{" "}
              <PriceDisplay value={chosenQuoteInstalments.DepositAmount} />{" "}
              followed by <strong>{chosenQuoteInstalments.NumberOfPaymentsToDisplay}</strong>{" "}
              monthly repayments of{" "}
              <PriceDisplay value={chosenQuoteInstalments.InstalmentAmount} />,
              equal to an APR of {chosenQuoteInstalments.AprRate}%. The total
              cost of the credit is{" "}
              <PriceDisplay
                value={Math.abs(chosenQuoteInstalments.TotalCostOfCredit)}
              />
              , including a <strong>&pound;5</strong> facility fee.
            </p>
            <p>We'll take the payment for your deposit on the next page.</p>
          </div>

        </div>
        <div className="row mb-2" data-cy="section-bank-details">

          <div className="order-1 col-12"><DDHeading dataCy="account-entry">Bank/Building Society Details</DDHeading></div>
          <div className="order-1 col-12 col-md-8" data-cy="dd-details">
            <div data-cy="dd-account-name" class="fb-form-group has-helptext mb-3">
              <label htmlFor="ddAccountName" className="form-label fb-form-label">
                Account name
              </label>
              <Help>
                Enter the name of the account holder as shown on your debit card.
              </Help>
              <TextBox
                value={directDebitData.values.accountName}
                onChange={(v) => directDebitData.functions.update.accountName(v)}
                disabled={isValidating}
                id="ddAccountName"
              />
            </div>

            <div data-cy="dd-sortcode" class="fb-form-group mb-3">
              <label htmlFor="ddSortCode" className="form-label fb-form-label">
                Sort code
              </label>
              <SortCode
                value={directDebitData.values.sortCode}
                onChange={(v) => directDebitData.functions.update.sortCode(v)}
                id="ddSortCode"
                disabled={isValidating}
                data-cy="dd-sortcode"
              />
            </div>

            <div data-cy="dd-account-number" class="fb-form-group mb-3">
              <label htmlFor="ddAccountNumber" className="form-label fb-form-label">
                Account number
              </label>
              <TextBox
                value={directDebitData.values.accountNumber}
                onChange={(v) =>
                  directDebitData.functions.update.accountNumber(v)
                }
                disabled={isValidating}
                id="ddAccountNumber"
              />
            </div>
          </div>

          <div
            className={[
              "order-3",
              "order-md-2",
              "col-12",
              "col-md-4",
              "mb-3",
              "mb-md-0",
              "mw-md-280-px",
            ].join(" ")}
          >
            <DirectDebitInfoBox
              className={[
                "row",
                "bg-light",
                "border",
                "rounded",
                "p-3",
                "m-0",
                // "max-w-200-px",
              ].join(" ")}
              classNameImg={[
                "mb-0 p-0 pb-1",
                "col-10 col-sm-6",
                "col-md-12 mb-md-2",
                // "col-xl-10",
                "mb-xxl-0",
              ].join(" ")}
              classNameAddress="col-12 col-sm-6 col-md-12 p-0 ps-sm-2 ps-md-0"
            />
          </div>

          <div
            className="col-12 row mb-2 order-2 order-md-3"
            style={{ minHeight: "2rem" }}
            data-cy="validate-feedback"
          >
            {DDStatus}
          </div>
        </div>

        <div className="row mb-2" data-cy="section-instruction">
          <DDTextBlock dataCy="use-facility">
            To use this facility you must be resident in the UK, aged 18 years
            or over and hold a bank or building society current account which
            can support Direct Debit payments. Credit is available subject to
            status. After purchasing this policy you will receive a Welcome Pack
            from your finance provider with all the relevant documentation
            (including two copies of your agreement). The monthly instalments
            will be taken on the day of the inception of this policy for the
            following {chosenQuoteInstalments.NumberOfPayments} months. i.e. for
            a policy incepted on 1st March, the instalments will be collected
            starting on 1st April.
          </DDTextBlock>

          <DDHeading dataCy="instruction">
            Instruction to your Bank/Building Society to pay by Direct Debit
          </DDHeading>

          <DDTextBlock dataCy="please-pay">
            Please pay PCL LTD Direct Debits from the account detailed in this
            Instruction, subject to the safeguards assured by the Direct Debit
            Guarantee. I understand that this instruction may remain with PCL
            LTD and, if so, details will be passed electronically to my
            Bank/Building Society.
          </DDTextBlock>
          <DDTextBlock dataCy="default-fee">
            Your premium finance company will charge a default fee of £25.00 for
            any missed or failed payments. All charges are explained in your
            pre-contractual explanations document that will be sent to you by
            Premium Credit Limited once your policy is set up.
          </DDTextBlock>
          <DDTextBlock dataCy="dd-guarantee">
            <ExternalLink
              label="Direct Debit Guarantee"
              href="https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Docs/DirectDebitGuarantee.pdf"
              dataCy="link-dd-guarantee"
            />
          </DDTextBlock>

          <DDTextBlock dataCy="dd-confirm-auth">
            <div className="row">
              <div className="col-auto">
                <Checkbox
                  data-cy="checkbox-dd-confirm-auth"
                  checked={DDConfirmation}
                  onSelect={() => setDDConfirmation(true)}
                  onUnselect={() => setDDConfirmation(false)}
                  onClick={() => setDDErrShowConfirmation(true)}
                />
              </div>
              <div className="col" data-cy="label-dd-confirm-auth">
                I can confirm that I'm both the policyholder and authorised to
                initiate debits from this account.
              </div>
            </div>
          </DDTextBlock>

          {DDErrShowConfirmation && !DDConfirmation && (
            <div className="col-12" data-cy="error-dd-confirm-auth">
              <Error>Please confirm if you wish to proceed.</Error>
            </div>
          )}
        </div>

        {/* AUTO RENEWAL */}
        <div className="row" data-cy="section-autorenew">
          <DDTextBlock>
            <hr />
          </DDTextBlock>

          <DDHeading dataCy="autorenew">Automatic renewal</DDHeading>

          <DDTextBlock dataCy="autorenew-text">
            As you are paying for your insurance by Direct Debit your policy may
            be automatically renewed and your revised payments may be debited
            from the bank account details we hold. Further information of
            whether your policy will be automatically renewed will be displayed
            on your renewal invitation.
          </DDTextBlock>

          <DDTextBlock dataCy="autorenew-confirm">
            <div className="row">
              <div className="col-auto">
                <Checkbox
                  data-cy="checkbox-autorenew-confirm"
                  checked={autoRenewalOptIn}
                  onSelect={() => fnUpdateAutoRenewalOptIn(true)}
                  onUnselect={() => fnUpdateAutoRenewalOptIn(false)}
                  onClick={() => fnUpdateAutoRenewalOptIn(true)}
                />
              </div>
              <div className="col" data-cy="label-autorenew-confirm">
                <strong>Please automatically renew my policy.</strong>
              </div>
            </div>
          </DDTextBlock>

          {!autoRenewalOptIn && (
            <DDTextBlock dataCy="autorenew-responsibility">
              <strong>
                I understand that arranging adequate insurance for my vehicle
                once this policy has expired is my responsibility.
              </strong>
            </DDTextBlock>
          )}

        </div>
      </PanelRenewal>

      <DevContainer>
        <ButtonStyled onClick={() => directDebitData.functions.validate()}>
          Validate
        </ButtonStyled>
        <ButtonStyled
          onClick={() => {
            directDebitData.functions.update.accountName("Mr Test");
            directDebitData.functions.update.accountNumber("87654321");
            directDebitData.functions.update.sortCode("112233");
          }}
        >
          Dummy data
        </ButtonStyled>
        {/* <ButtonStyled
          onClick={() => commonData.functions.process.paymentLauncher()}
        >
          Payment Launcher
        </ButtonStyled> */}
      </DevContainer>

      <ButtonPanel
        onPrev={commonData.functions.navigate.start}
        prevLabel="Back"
        onNextClick={() => {
          if (isValidating) {
            return false;
          }

          if (!DDConfirmation) {
            setDDErrShowConfirmation(true);
            return false;
          }

          setShowErrors(true);

          if (errorData.disableNextButton) return false;

          return true;
        }}
        onNext={async () => {
          await commonData.functions.session.save();
          commonData.functions.process.paymentLauncher({
            directDebit: {
              sortCode: directDebitData.values.sortCode,
              accountName: directDebitData.values.accountName,
              accountNumber: directDebitData.values.accountNumber,
            },
          });
        }}
        nextLabel={(function () {
          return "Continue to payment";
        })()}
        modalData={(function () {
          return undefined;
          if (errorData.disableNextButton) return undefined;
          return {
            title: "Confirm",
            body: <p>Please confirm</p>,
            label: { cancel: "Cancel", confirm: "Confirm" },
          };
        })()}
        errorMsg={errorData.errorMessage}
      />
    </Base>
  );
};

export default RenewalDirectDebitView;
