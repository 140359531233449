import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

const ContainerTopLevel = React.lazy(() =>
  import("siteViews/Renewals/Containers/TopLevel")
);
// const ContainerPage = React.lazy(() =>
//   import("siteViews/Renewals/Containers/Page")
// );

import Start from "siteViews/Renewals/Views/Start";
import Complete from "siteViews/Renewals/Views/Complete";
import DirectDebit from "siteViews/Renewals/Views/DirectDebit";
import Wrapup from "siteViews/Renewals/Views/Wrapup";

import Feedback from "siteViews/Renewals/Views/Feedback";
import FeedbackSPM from "siteViews/Renewals/Views/Feedback.SPM";

const baseRoute = "renewal";

const routePaths = {
  start: "",
  complete: "thank-you",
  feedback: "feedback",
  wrapup: "processing",
  directDebit: "direct-debit",
  exit: "/",
};

const routePathsFeedback = [
  { route: "refused", feedbackType: "refused" },
  { route: "paymenterror", feedbackType: "paymenterror" },
  { route: "timeout", feedbackType: "timeout" },
  { route: "referred", feedbackType: "referred" },
  { route: "declined", feedbackType: "declined" },
  { route: "error", feedbackType: "error" },
];

const routes = [
  {
    path: baseRoute,
    element: <ContainerTopLevel routes={routePaths} />,
    children: [
      {
        element: <Start />,
        path: routePaths.start,
      },
      {
        element: <Complete />,
        path: routePaths.complete,
      },
      {
        element: <Wrapup />,
        path: [routePaths.wrapup, "/:paymentId"].join(""),
      },
      {
        element: <DirectDebit />,
        path: routePaths.directDebit,
      },
      {
        element: <Feedback />,
        path: routePaths.feedback,
      },
      ...routePathsFeedback.map(({ route, feedbackType }) => ({
        // http://localhost:8100/renewal/refused
        path: [routePaths.feedback, route].join("/"),
        element: <FeedbackSPM feedbackType={feedbackType} />,
        // element: (
        //   <Navigate
        //     to={["../", routePaths.feedback].join("/")}
        //     state={{ feedbackType: feedbackType }}
        //     // replace
        //   />
        // ),
      })),

      // {
      //   element: <div>xxxx</div>,
      //   path: "*",
      // },
    ],
  },
];

export default routes;
