import React, { useEffect } from "react";

const ContainerTopLevel = React.lazy(() =>
  import("siteViews/Mta/Containers/TopLevel")
);
const ContainerPage = React.lazy(() => import("siteViews/Mta/Containers/Page"));

import StartView from "siteViews/Mta/SubViews/Start";
import ChangeView from "siteViews/Mta/SubViews/Change";
import QuoteView from "siteViews/Mta/SubViews/Quote";
import WrapupLauncherView from "siteViews/Mta/SubViews/Wrapup/Launcher";
import FeedbackView from "siteViews/Mta/SubViews/Feedback";
import WrapupReceiptView from "siteViews/Mta/SubViews/Wrapup/Receipt";

const baseRoute = "mta";

const routePaths = {
  start: "",
  change: "change",
  quote: "quote",
  wrapupLauncher: "processing",
  feedback: "feedback",
  wrapup: "thank-you",
  exit: "/",
};

const routes = [
  {
    path: baseRoute,
    element: (
      <ContainerTopLevel
        routeStart={routePaths.start}
        routeChange={routePaths.change}
        routeQuote={routePaths.quote}
        routeWrapup={routePaths.wrapup}
        routeWrapupLauncher={routePaths.wrapupLauncher}
        routeExit={routePaths.exit}
        routeFeedback={routePaths.feedback}
      />
    ),
    children: [
      //**************************************
      // MTA
      //**************************************
      {
        element: <ContainerPage isStart={true} />,
        path: routePaths.start,
        children: [
          {
            path: "",
            element: <StartView />,
          },
        ],
      },
      {
        element: <ContainerPage isChange={true} />,
        path: routePaths.change,
        children: [
          {
            path: "",
            element: <ChangeView />,
          },
        ],
      },
      {
        element: <ContainerPage isQuote={true} />,
        path: routePaths.quote,
        children: [
          {
            path: "",
            element: <QuoteView />,
          },
        ],
      },

      //**************************************
      // WRAPUP
      //**************************************
      {
        element: <ContainerPage isWrapup={true} />,
        children: [
          {
            path: `${routePaths.wrapupLauncher}/:paymentId`,
            element: <WrapupLauncherView />,
          },
          {
            path: routePaths.wrapup,
            element: <WrapupReceiptView />,
          },
        ],
      },

      //**************************************
      // FEEDBACK
      //**************************************
      {
        element: <ContainerPage isFeedback={true} />,
        children: [
          {
            path: `${routePaths.feedback}/:type`,
            element: <FeedbackView />,
          },
        ],
      },
    ],
  },
];

export default routes;
