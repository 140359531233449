import dataHelper from "@library/common/database/rules/helpers/dataHelper";

export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper").default,
  } = args;
  const occupationBaseLogic = require("../occupationLogic/baseLogic").default;

  const dh = new dataHelper({
    mappings: ["HasSecondOccupation", "Type", "OccCode", "EmpCode"],
    dataSet,
    functionArgs,
  });

  // console.log("ddddd****************************************");

  // [
  // 	"dh.getValue('HasSecondOccupation')",
  // 	"dh.isPostRegistration()",
  // 	`dh.isUpdated("HasSecondOccupation")`,
  // ].forEach((k) => console.log(k, eval(k)));

  if (dh.isPostRegistration()) {
    if (dh.getValue("Type") !== undefined) {
      dh.setData(
        "value",
        "HasSecondOccupation",
        dh.getValue("Type") !== "None"
      );
    }
  }

  // Set Visibility
  // console.log("ddddddddddddd HasSecondOccupation",dh.isUpdated("HasSecondOccupation"))
  if (dh.isUpdated("HasSecondOccupation")) {
    const isVisible = dh.getValue("HasSecondOccupation") === true;

    dh.setVisible("Type", isVisible);
    dh.setVisible("OccCode", false);
    dh.setVisible("EmpCode", false);

    if (!dh.isPostRegistration()) {
      if (isVisible === false) {
        dh.setData("value", "Type", "None");
        dh.setData("value", "OccCode", "220");
        dh.setData("value", "EmpCode", "186");
      } else {
        dh.setData("value", "Type", undefined);
        dh.setData("value", "OccCode", undefined);
        dh.setData("value", "EmpCode", undefined);
      }
    }
  }

  occupationBaseLogic(args, dh);

  //ERROR checking
  if (dh.getValue("HasSecondOccupation") === true) {
    // console.log("xxxx dh", dh);
    dh.addErrorIfEmpty("Type", ""); // "Required")
    dh.addErrorIfEmpty("OccCode", ""); // "Required")
    dh.addErrorIfEmpty("EmpCode", ""); // "Required")
  }

  dh.commit();
};
