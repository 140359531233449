import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from "react";
import { connect, useSelector } from "react-redux";
import SiteLayoutError from "siteLayouts/Site/Error";
import MaintenanceView from "siteViews/Errors/Maintenance";

import services from "siteService";

const MaintenanceChecker = (props) => {
  const { children } = props;
  const [isInit, setIsInit] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    const myFn = async () => {
      try {
        const response = (await services.status()) || {};

        const { Maintenance = false, BrandInfo } = response;

        // console.log("MaintenanceChecker", response, Maintenance);
        setIsMaintenance(Maintenance);
        setIsInit(true);
      } catch (e) {
        setIsInit(true);
      }
    };
    myFn();
  }, []);

  if (!isInit) return null;

  if (isMaintenance)
    return (
      <SiteLayoutError>
        <MaintenanceView />
      </SiteLayoutError>
    );

  return <>{children}</>;
};

export default MaintenanceChecker;
