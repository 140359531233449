import React, { useEffect } from "react";

import Dev from "../subData/Dev";
import ScreenFinder from "siteComponents/dev/ScreenFinder";
import services from "siteService";

import _logo from "siteAssets/images/stella-logo-colour.svg";
import _logoNegative from "siteAssets/images/stella-logo-negative.svg";
import {
  CookieConsent,
  SiteAlerts,
  ExternalLink,
  LoadingPanel,
  Panel,
} from "siteComponents";
import PageLayout from "siteLayouts/Page";

import { generateCypressProps } from "siteFunctions/cypress";

import { useDispatch, useMountCount } from "siteFunctions/hooks";
import { actions, selectors } from "siteStore";

const SuspenseWrapper = ({ children }) => {
  const fallback = <></>; //     <LoadingPanel>Please wait...</LoadingPanel>
  return <React.Suspense fallback={fallback}>{children}</React.Suspense>;
};

const SiteLayoutBase = (props) => {
  const {
    children,
    className,
    classNameHeader,
    logoHeader = _logo,
    logoFooter = _logoNegative,
    headerData,
    backgroundSlide,
    headingText,
    descriptionContent,
  } = props;

  const containerClass = [
    "container-fluid",
    "container-lg",
    "layout-max-width",
  ].toClassName();

  const SiteAlertsWrapper = ({ children }) => (
    <div className={containerClass}>{children}</div>
  );

  return (
    <div {...generateCypressProps("site", props)}>
      <div
        className={[
          "lctr-layout-template",
          "flex-shrink-0",
          "d-flex",
          "flex-column",
          "min-vh-100",
          backgroundSlide ? "" : "bg-buttermilk",
          className,
        ].toClassName()}
      >
        <CookieConsent />

        {backgroundSlide && (
          <>
            <div className="background-slideshow">
              {headerData && (
                <header
                  className={["layout-header", classNameHeader].toClassName()}
                >
                  <div className={containerClass}>{headerData}</div>
                  <SiteAlerts
                    SiteType="portal"
                    className="my-3"
                    Wrapper={SiteAlertsWrapper}
                  />
                </header>
              )}

              <div className="header-container" data-cy="page-header">
                <div className={containerClass}>
                  <div className="row">
                    <div className="col-12 col-md-9">
                      <div className="header-text" data-cy="page-header-main">
                        {headingText}
                      </div>
                      <div
                        className="header-description"
                        data-cy="page-header-description"
                      >
                        {descriptionContent}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {backgroundSlide}
            </div>
            <div className="layout-main">
              <SuspenseWrapper>{children}</SuspenseWrapper>
            </div>
          </>
        )}

        {!backgroundSlide && (
          <>
            {headerData && (
              <header
                className={["layout-header", classNameHeader].toClassName()}
              >
                <div className={containerClass}>{headerData}</div>
              </header>
            )}

            <div className="layout-main">
              <SiteAlerts
                SiteType="portal"
                className="my-3"
                Wrapper={SiteAlertsWrapper}
              />
              <SuspenseWrapper>{children}</SuspenseWrapper>
            </div>
          </>
        )}

        <footer className="layout-footer min-h-100-px mt-auto bg-black">
          <div className={containerClass}>
            <div className="row py-4">
              <div className="col-6 col-sm-auto">
                <img
                  className="brand-img img-fluid"
                  src={logoFooter}
                  alt="Stella Insurance"
                />
                <br />
                <ScreenFinder />
              </div>
              <div className="col-6 col-sm-auto ms-auto footer-links">
                <ExternalLink
                  href={require("config").default.lookups.documents.toba}
                  label="Terms of Business"
                  className="link-white"
                />
                <ExternalLink
                  href={
                    require("config").default.lookups.documents.privacyPolicy
                  }
                  label="Privacy Notice"
                  className="link-white"
                />
                <ExternalLink
                  href="https://withstella.co.uk/contact/"
                  label="Contact Us"
                  className="link-white"
                />
                <ExternalLink
                  href="https://withstella.co.uk/about/"
                  label="About"
                  className="link-white"
                />
              </div>
            </div>

            <div className="row py-4">
              <div className="col-12">
                <p className="text-white">
                  <small>
                    &copy; {process.env.BUILD_TIME.split(",")[0].split("/")[2]}{" "}
                    Freedom Brokers Ltd.
                  </small>
                </p>
                <p className="text-white">
                  <small>
                    Stella is a trading style of Freedom Brokers Ltd. Freedom
                    Brokers Ltd is an appointed representative of Action 365 Ltd
                    which is authorised and regulated by the Financial Conduct
                    Authority (FRN 306011). Freedom Brokers Ltd is registered in
                    England and Wales, company number 10399389 and its
                    registered address is BizSpace Cheadle, Cheadle Place,
                    Stockport Rd, Cheadle, SK8 2JX.
                  </small>
                </p>
              </div>
            </div>
          </div>

          <Dev />
        </footer>
      </div>
    </div>
  );
};

export default SiteLayoutBase;
