import generateSlice from "./slice";
import generateActions from "./actions";
import generateSelectors from "./selectors";

const fnThrowError = (msg) => {
  throw `Error in create dashboard store: ${msg}`;
};

const generateStore = (args = {}) => {
  const { storeName, services = {} } = args;

  if (!storeName) fnThrowError("missing storeName");

  ["refreshUserInfo"].forEach((k) => {
    if (!(k in services)) fnThrowError(`missing "services.${k}"`);
  });

  // ["auth"].forEach((k) => {
  //   if (!(k in stores)) fnThrowError(`missing "services.${k}"`);
  // });

  // ["auth"].forEach((k) => {
  //   if (!(k in selectorsExternal)) fnThrowError(`missing "selector.${k}"`);
  // });

  const _actions = generateActions(args);
  const _selectors = generateSelectors({
    ...args,
  });
  const _slice = generateSlice({ ...args, privateActions: _actions });

  const retData = {
    storeName,
    reducer: _slice.reducer,
    actions: { ..._actions, ..._slice.actions },
    selectors: _selectors,
  };

  console.log("STORE", storeName, "=", retData);
  return retData;
};

export default generateStore;
