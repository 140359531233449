import React from "react";

const BadgesPolicyStatus = (props) => {
    const { status, className, prefix = "Policy" } = props;

    if (!status) throw `Error in BadgesPolicyStatus -- missing status`;

    // const { Status: status } = policyDetails;

    const badgeClass = (function () {
        if (status.isActive) return "icon-green-checkmark";
        if (status.isPending) return "bi bi-clock-history";
        return "icon-red-x";
    })();

    const _className = [
        "badge",
        "policy-badge",
        "d-flex",
        "align-items-center",
        className,
    ].toClassName();

    const _classNameIcon = ["icon", "badge-icon", badgeClass].toClassName();

    return (
        <div className={_className}>
            <span className="prefix">{prefix}</span>
            <span className="status">{status.statusDisplay}</span>            
            <span className={_classNameIcon} />
        </div>
    );
};

export default BadgesPolicyStatus;
