import React from "react";
import BaseComponent from "./Base.Component";

const Panel = (props) => {
  const { className, classNameHeading, classNameBody, ...otherProps } = props;

  const mainHeading = props.header || props.heading;

  const _classContainer = [className].toClassName();
  const _classNameHeading = [classNameHeading].toClassName();
  const _classNameBody = ["my-3", classNameBody].toClassName();

  return (
    <BaseComponent
      heading={mainHeading}
      classNameContainer={_classContainer}
      classNameHeading={_classNameHeading}
      classNameBody={_classNameBody}
      {...otherProps}
    />
  );
};

export default Panel;
