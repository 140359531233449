import React from "react";
import * as siteComponents from "siteComponents";
import dateHelpers from "@library/common/helpers/misc/date";

const FormGroup = (props) => {
  const {
    value,
    hidden,
    error = {},

    children,
    className,
    errorShow = false,
    label,
    helpText,
    helpTextFurther,
  } = props;

  if (hidden) return null;

  const errorList = Object.values(error);
  const hasError = errorShow && errorList.length >= 1;
  const errorMessage = errorList.find((x) => x != ""); // find the first message
  const hasHelpText = helpText ? true : false;

  // console.log("RENDERING FormGroup", label, props);

  return (
    <div
      className={[
        "lctr-formgroup-container-mta",
        "fb-form-group",
        "py-4",
        hasError ? "has-error" : "",
        hasHelpText ? "has-helptext" : "",
        className,
      ].toClassName()}
    >
      {label && <label className="col-12 fb-formlabel">{label}</label>}

      {hasHelpText && (
        <siteComponents.Help value={helpText} className="col-12 mb-3" />
      )}

      {helpTextFurther && (
        <siteComponents.HelpFurther
          value={helpTextFurther}
          className="col-12 mb-2"
        />
      )}

      <div
        className={[
          `lctr-formgroup-children`,
          `col-${props.xsCols ? props.xsCols : "12"}`,
          className,
        ].toClassName()}
      >
        {children}
      </div>

      {errorMessage && (
        <div className="form-text text-danger">{errorMessage}</div>
      )}
    </div>
  );
};

export default (args = {}) => {
  // console.log("dddd", args);
  const { helpers } = args.store;

  const fnComposeComponent = (Component, otherOptions = {}) => {
    // console.log("COMPOSING:", Component);
    return helpers.composeComponent(Component, {
      templatePropsPath: "databaseProps",
      ...otherOptions,
    });
  };

  const baseRetData = {
    DatePickerTextBox: fnComposeComponent(siteComponents.DatePickerTextBox, {
      fnModifyValueInput: (v) => dateHelpers.toDate(v),
      fnModifyValueOutput: (v) => dateHelpers.toString(v),
    }),

    DatePickerWithButtons: fnComposeComponent(
      siteComponents.DatePickerWithButtons,
      {
        fnModifyValueInput: (v) => dateHelpers.toDate(v),
        fnModifyValueOutput: (v) => dateHelpers.toString(v),
      }
    ),

    FormGroup: fnComposeComponent(FormGroup),
    TextBox: fnComposeComponent(siteComponents.TextBox),
    // FormRowWithError: fnComposeComponent(siteComponents.FormRowWithError),
    Error: fnComposeComponent(siteComponents.Error),
    TextBoxPostcode: fnComposeComponent(siteComponents.TextBoxPostcode),

    DropDownType: fnComposeComponent(siteComponents.DropDownType),
    DropDownTypeWebService: fnComposeComponent(
      siteComponents.DropDownTypeWebService
    ),
    RadioButtonsWebService: fnComposeComponent(
      siteComponents.RadioButtonsWebServiceMta
    ),
    RadioButtons: fnComposeComponent(siteComponents.RadioButtonsMta),
    // ,Modifications : fnComposeComponent(siteComponents.Modifications)
    // ShowValue: fnComposeComponent(siteComponents.ShowValue),
    // Affiliate: fnComposeComponent(siteComponents.Affiliate),
    // OptimiseTracking: fnComposeComponent(siteComponents.OptimiseTracking),
    // ListItemViewer: fnComposeComponent(siteComponents.ListItemViewer),
    // ListItemCreateButton: fnComposeComponent(
    //   siteComponents.ListItemCreateButton
    // ),
    // VehicleUse: fnComposeComponent(siteComponents.VehicleUse),
    // DevUpdater: fnComposeComponent(siteComponents.DevUpdater),
  };

  return baseRetData;
};
