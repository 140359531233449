import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";

const DefaultFallbackComponent = () => null;

const generate = (args = {}) => {
  const { storeName, services, snapshotActions, snapshotSelectors } = args;

  const SnapshotChecker = (props) => {
    const { children, FallbackComponent = DefaultFallbackComponent } = props;

    const dispatch = useDispatch();
    const isInit = useSelector(
      snapshotSelectors.getIsInit,
      require("lodash").isEqual
    );

    useEffect(() => {
      if (isInit) return;
      // console.log("TODO", "SnapshotChecker");
      dispatch(snapshotActions.init({ get: true }));
    }, []);

    if (!isInit) return <FallbackComponent />;

    return <>{children}</>;
  };

  // Auto saves the snapshot if a watch variable changes (SHALLOW comparison only)
  const SnapshotAutoSave = (props) => {
    const { children, ...otherProps } = props;

    const hasChanged = useRef(false);
    const dispatch = useDispatch();
    const dataArray = Object.values(otherProps);
    const [initDataArray] = useState(dataArray);
    const [isSaving, setIsSaving] = useState(false);
    const [requestCount, setRequestCount] = useState(0);

    // Append to the queue
    //Removed as it was react.stricyt causing the multiple renders
    // useEffect(() => {
    //   // For some reason, this component may mount multiple times, so we need the "_.isEqual" check until we are sure a value has changed
    //   if (hasChanged.current === false && _.isEqual(initDataArray, dataArray)) {
    //     return;
    //   } else {
    //     hasChanged.current = true;
    //   }

    //   setRequestCount(requestCount + 1);
    // }, dataArray);

    useEffect(() => {
      if (hasChanged.current === false) {
        hasChanged.current = true;
        return;
      }
      setRequestCount(requestCount + 1);
    }, dataArray);

    // Handle the queue
    useEffect(() => {
      if (isSaving) return;
      if (requestCount === 0) return;

      console.log("SnapshotAutoSave", "saving...", requestCount, otherProps);
      setIsSaving(true);
      dispatch(snapshotActions.save())
        .then(() => {
          console.log("SnapshotAutoSave", "...saved");
          setIsSaving(false);
          setRequestCount(requestCount - 1);
        })
        .catch(() => {
          console.log("SnapshotAutoSave", "...failed");
          setIsSaving(false);
          setRequestCount(requestCount - 1);
        });
    }, [isSaving, requestCount]);

    return <>{children}</>;
  };

  return { SnapshotChecker, SnapshotAutoSave };
};

export default generate;
