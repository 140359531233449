import config from "config";

export const generateCypressProps = (v, props) => {
  if (!props) throw `Error in generateCypressProps -- missing "props"`;

  if (config.isProd) return {};

  if ("data-cy" in props) return { ["data-cy"]: props["data-cy"] };

  if (!v) return {};

  return { ["data-cy"]: v };
};
