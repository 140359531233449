import _ from "lodash";

const generate = (args = {}) => {
  const { storeName, authActions } = args;

  const getSnapshotId = (state) => state[storeName].snapshotId;
  const getIsInit = (state) => state[storeName].isInit;
  const getHasLoadedData = (state) => state[storeName].hasLoadedData;

  return { getSnapshotId, getIsInit, getHasLoadedData };
};

export default generate;
