import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  createContext,
} from "react";
import { generateCypressProps } from "siteFunctions/cypress";
import { useDropzone } from "react-dropzone";
import { ButtonStyled, ButtonLink, ButtonClose } from "./Buttons";

import { ErrorText, DropDownType } from "./base";
// import { Error } from "./custom/Feedback";
import base64js from "base64-js";

import _ from "lodash";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
export const FileUploadContext = createContext({});

export const FileUpload = (props) => {
  const {
    children,
    className,
    onUpload = () => {},
    validate = (file) => null,
    accept, // an object of acceptable files. See https://react-dropzone.js.org/
    headingRejections,
    enabled = true,
  } = props;

  const handleOnDrop = (acceptedFiles) => {
    if (!enabled) return;
    onUpload(acceptedFiles);
  };
  const fnValidate = (file) => validate(file);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
    open,
  } = useDropzone({
    onDrop: handleOnDrop,
    onDropRejected: (...args) => console.log("dadd", ...args),
    noClick: true,
    noKeyboard: true,
    accept: accept,
    validator: fnValidate,
    // noDragEventsBubbling: true,
  });

  const _className = [
    "lctr-file-upload",
    "file-upload",
    className,
  ].toClassName();

  const contextValue = { fnOpen: () => open(), isDragActive, fileRejections };

  return (
    <FileUploadContext.Provider value={contextValue}>
      <div
        className={_className}
        {...generateCypressProps("file-upload", props)}
      >
        <div
          {...getRootProps()}
          className={[
            "file-upload-drag-area",
            isDragActive && "file-upload-drag-area-active",
          ].toClassName()}
        >
          {children}
        </div>

        <input {...getInputProps()} />
      </div>
    </FileUploadContext.Provider>
  );
};

export const FileAddButton = (props) => {
  const { msgButton = "Add a document", onClick = () => {} } = props;
  const { fnOpen } = useContext(FileUploadContext);

  return (
    <ButtonStyled
      onClick={() => {
        onClick();
        fnOpen();
      }}
      type="button"
    >
      {msgButton}
    </ButtonStyled>
  );
};

export const FileRejectionList = (props) => {
  const { fileRejections } = useContext(FileUploadContext);

  if (fileRejections && fileRejections.length >= 1)
    return (
      <div className="file-upload-errors mt-3 alert alert-danger">
        Sorry, we were unable to upload the following file
        {fileRejections.length > 1 ? "s" : ""}. Please read our guidance on file
        types and sizes.
        <ul className="mb-0 mt-1">
          {fileRejections.map(({ file, errors }, i) => {
            const errMsg = (function () {
              const obj = errors.find((x) => x.code === "duplicate");
              if (!obj) return undefined;
              return ` - ${obj.message}`;
            })();

            return (
              <li key={i}>
                {file.name}
                {errMsg}
              </li>
            );
          })}
        </ul>
      </div>
    );

  return null;
};

export const FileList = (props) => {
  const {
    files = [],
    onRemove = (i) => {},
    className,
    isUploadingOverride,
  } = props;

  if (!files) return null;
  if (files.length === 0) return null;

  //   console.log("FileList", "files", files);
  const _className = [
    "table",
    "table-borderless",
    "align-middle",
    "mb-3",
    "file-upload-list",
    className,
  ].toClassName();

  // const isUploadingAll = files.some((x) => x.isUploading);

  return (
    <table className={_className}>
      <thead className="file-upload-list-item-header fb-list-headers">
        <tr className="d-flex d-md-table-row justify-content-between">
          <th scope="col" className="text-center d-none d-md-table-cell remove">
            Remove
          </th>
          <th scope="col" >
            Document <span className="d-none d-md-inline">Name</span>
            <span className="d-md-none">/ Type</span>
          </th>
          <th scope="col" className="d-none d-md-table-cell">
            Document Type
          </th>
          <th scope="col" className="text-center">
            Preview
          </th>
        </tr>
      </thead>
      <tbody>
        {files.map((fileItem, i) => {
          return (
            <TableRowFileItem
              key={fileItem.key}
              onRemove={() => onRemove(i)}
              file={fileItem.data}
              name={fileItem.data.name}
              type={fileItem.data.type}
              size={fileItem.data.size}
              isUploading={isUploadingOverride}
              // isUploading={fileItem.isUploading}
              isComplete={fileItem.isComplete}
              errorUploadMsg={fileItem.errorUploadMsg}
              errorValidation={fileItem.errorValidation}
              uploadType={fileItem.uploadType}
              setUploadType={fileItem.setUploadType}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export const TableRowFileItem = (props) => {
  const {
    className,
    onRemove = () => {},
    name,
    type,
    size,
    file,
    isUploading,
    isComplete,
    errorUploadMsg,
    errorValidation,
    uploadType,
    setUploadType,
  } = props;

  const _className = [
    "file-upload-list-item",
    "d-flex",
    "flex-wrap",
    "d-md-table-row",
    className,
  ].toClassName();

  //   console.log("dddd", errorUploadMsg)

  // const iconData = (function () {
  //   // if (isUploading) return <i className="bi-fb bi bi-cloud-arrow-up pe-3" />;
  //   // if (isComplete) return <i className="bi-fb bi bi-check pe-3" />;
  //   if (errorUploadMsg)
  //     return <i className="bi-fb bi bi-exclamation text-red ps-1" />;
  //   return null; // <i className="bi-fb bi bi-circle-fill pe-3" />;
  // })();

  return (
    <>
      <tr className={_className}>
        <td className="text-center remove">
          <ButtonClose
            className="btn-link-inline link-black"
            onClick={() => onRemove()}
            disabled={isUploading}
            content={<i className="bi bi-trash3"></i>}
          />
        </td>

        <td className="flex-fill d-flex align-content-start d-md-table-cell">
          {name}
        </td>

        {/* DESKTOP ONLY */}
        <td className="d-none d-md-table-cell doc-type">
          <UploadDocumentType
            uploadType={props.uploadType}
            setUploadType={props.setUploadType}
            hasError={errorValidation.missingUploadType}
          />
        </td>

        <td className="text-end text-md-center d-flex justify-content-end justify-content-md-center">
          <div className="mb-md-3 overflow-hidden">
            <FileThumbnail file={file} />
          </div>
        </td>
      </tr>

      {/* MOBILE ONLY */}
      <tr className="doc-type-xs d-md-none">
        <td colSpan="4" className="doc-type">
          <UploadDocumentType
            uploadType={props.uploadType}
            setUploadType={props.setUploadType}
            hasError={errorValidation.missingUploadType}
          />
        </td>
      </tr>

      {/* {errorUploadMsg && (
        <tr className="upload-error">
          <td colSpan="4" >
            <div className="d-flex align-items-center">
              <i className="bi-fb bi bi-exclamation text-danger pe-1" />
              <ErrorText>{errorUploadMsg}</ErrorText>
            </div>
          </td>
        </tr>
      )} */}
    </>
  );
};

export const FileThumbnail = (props) => {
  const { file, className } = props;
  // const [preview, setPreview] = useState();
  const canvasRef = useRef(null);
  // https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258
  //http://jsfiddle.net/a_incarnati/fua75hpv/

  const [previewData, setReturnData] = useState(null);
  const _className = ["file-upload-list-item-preview", className].toClassName();

  useEffect(() => {
    // console.log("FileThumbnail EFFECT", file.name);
    if (!file) return;
    const fnUnload = () => URL.revokeObjectURL(objectUrl);

    const objectUrl = URL.createObjectURL(file);
    // setPreview(objectUrl);

    if (file.type.startsWith("image/")) {
      setReturnData(<img src={objectUrl} className={_className} />);
      return fnUnload;
    }

    if (file.type === "application/pdf") {
      // The ICON only code
      if (false) {
        setReturnData(
          <img
            className={["img-fluid", _className].toClassName()}
            src={require("siteAssets/images/pdf-file-icon.svg")}
            alt="PDF icon"
          />
        );

        return fnUnload;
      }

      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = function (ev) {
        const result = ev.target.result;
        const base64 = base64js.fromByteArray(new Uint8Array(result));
        const dataString = `data:application/pdf;base64,${base64}`;
        // console.log("dadad", dataString);

        setReturnData(
          <div className={_className}>
            <Document
              file={dataString}
              // onLoadSuccess={(...args) => {
              //   console.log("asasa", ...args);
              // }}
            >
              <Page
                renderTextLayer={false}
                renderAnnotationLayer={false}
                // customTextRenderer={false}
                // renderMode="svg"
                height={100}
                // className={_className}
                // pageIndex={0}
                pageNumber={1}
              />
            </Document>
          </div>
        );
      };
      return fnUnload;
    }

    return fnUnload;
  }, []);

  if (!file) return null;
  // if (!preview) return null;

  // console.log("FileThumbnail", file.name, file.type);

  // if (file.type.startsWith("image/"))
  //   return <img src={preview} className={_className} />;

  // if (file.type === "application/pdf") {
  //   const fileReader = new FileReader();
  //   fileReader.readAsArrayBuffer(file);
  //   fileReader.onload = function (ev) {
  //     const result = ev.target.result;
  //     const base64 = base64js.fromByteArray(new Uint8Array(result));
  //     const dataString = `data:application/pdf;base64,${base64}`;
  //     console.log("dadad", dataString);
  //   };

  //   return <div>PDF</div>;
  // }

  return previewData;
  return null;

  //   return <object data={preview} type={file.type} />;
  return <canvas ref={canvasRef} />;

  return null;

  //   return <Image image={preview} />;
};

export const UploadDocumentType = (props) => {
  const {
    uploadType = "NONE",
    setUploadType,
    itemData = [
      { label: "Please choose...", value: "NONE" },
      { label: "Proof of NCD", value: "Proof of NCD" },
      { label: "Licence", value: "Licence" },
    ],
    className,
    hasError = false,
  } = props;

  ["setUploadType", "uploadType"].argChecker(props);

  // console.log("hasError", hasError);

  return (
    <span className={[hasError && "has-error"].toClassName()}>
      <DropDownType
        itemData={itemData}
        value={uploadType}
        onChange={(v) => {
          if (v === "NONE") {
            setUploadType(undefined);
            return;
          }
          setUploadType(v);
        }}
        className={className}
      />
    </span>
  );
};
