import React from "react";

import Base from "./Base";
import { useNavigate } from "react-router-dom";

import Header from "./Templates/HeaderWithMenu";

const SiteLayout = (props) => {
  return <Base {...props} headerData={<Header />} />;
};

export default SiteLayout;
