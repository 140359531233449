import generateSlice from "./slice";
import generateActions from "./actions";
import generateSelectors from "./selectors";
import generateHooks from "./hooks";

const generateStore = (args = {}) => {
  const { storeName, services = {}, selectors } = args;

  const fnThrowError = (msg, _storeName = storeName) => {
    throw ["Error in generateStore", _storeName, "-", msg]
      .filter((x) => x)
      .join(" ");
  };

  if (!storeName) fnThrowError("missing storeName");

  const _actions = generateActions(args);
  const _selectors = generateSelectors(args);
  const _slice = generateSlice({
    ...args,
    privateActions: _actions,
  });

  const _hooks = generateHooks({
    ...args,
    storageSelectors: _selectors,
    storageActions: { ..._actions, ..._slice.actions },
  });

  const retData = {
    storeName,
    reducer: _slice.reducer,
    actions: { ..._actions, ..._slice.actions },
    selectors: _selectors,
    hooks: _hooks,
  };

  console.log("STORE", storeName, "=", retData);
  return retData;
};

export default generateStore;
