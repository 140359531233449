import React from "react";

import { Outlet } from "react-router-dom";
import { Loading } from "siteComponents";

// import ViewDetailsCar from "./ViewDetails/Car";
const ViewDetailsCar = React.lazy(() =>
  import("siteViews/PolicyViewer/ViewDetails/Car")
);

const routes = [
  {
    path: "policy",
    element: (
      // <React.Suspense fallback={<Loading />}>
      <Outlet />
      // </React.Suspense>
    ),
    children: [
      {
        path: "",
        element: <ViewDetailsCar />,
      },
    ],
  },
];

export default routes;
