import React from "react";
import { Helmet } from "react-helmet";
import { ButtonLink } from "siteComponents";

import Base from "../Base";

// NOTE: THis view is not used anywhere
const Error404View = (props) => {
  return (
    <Base heading="Sorry, we can’t find the page you’re looking for">
      <Helmet>
        <title>404</title>
      </Helmet>

      <div className="row py-3">
        <div className="col-12">
          <p>
            <ButtonLink
              onClick={() => {
                clearTimeout();
              }}
            >
              Click here to log in
            </ButtonLink>
          </p>
        </div>
      </div>
    </Base>
  );
};

export default Error404View;
