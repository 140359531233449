import _ from "lodash";
import {
  createFnGenerateAction,
  createFnGenerateActionWithThunkAPI,
} from "../common";

const generateStore = (args = {}) => {
  const { storeName, services } = args;

  const fnGenerateAction = createFnGenerateAction(storeName);
  const fnGenerateActionWithThunkApi =
    createFnGenerateActionWithThunkAPI(storeName);

  const start = fnGenerateActionWithThunkApi(
    "start",
    async (payload = {}, thunkApi) => {
      const fnGenerateError = (message = "Oops! There's been an error.") => {
        return thunkApi.rejectWithValue({
          isSuccess: false,
          message,
        });
      };

      const { eventId, policyId, webReference } = payload;
      
      const response = await services.getRenewalData({
        policyId,
        eventId,
        // webReference,
      });

      if (!response) return fnGenerateError();
      if (!response.isSuccess) return fnGenerateError(response.message);

      return response;
    }
  );

  const paymentLauncher = fnGenerateActionWithThunkApi(
    "paymentLauncher",
    async (payload = {}, thunkApi) => {
      const fnGenerateError = (message = "Oops! There's been an error.") => {
        return thunkApi.rejectWithValue({
          isSuccess: false,
          message,
        });
      };
      const response = await services.paymentLauncher(payload);
      if (!response) return fnGenerateError();
      if (!response.isSuccess) return fnGenerateError(response.message);

      return response;
    }
  );

  return {
    start,
    paymentLauncher,
  };
};

export default generateStore;
