import React, { useCallback, useState, useEffect } from "react";
import _ from "lodash";

import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Create";

export default ({
	database,
	selectors,
	actions,
	siteComponents,
	storeComponentsCustom,
	classes,
}) => {
	// const { Button } = siteComponents;

	const DefaultFoundTemplate = (props) => {
		return (
			<div className="address-found">
				{/* {heading && (
					<div className="col-12">
						<label className="form-label" htmlFor={ID}>
							{heading}
						</label>
					</div>
				)} */}
				<div className="address" id={props.ID}>
					{props.addressData}
				</div>

				<div className="row">
					<div className="col-12 p-0">
						<button className="btn btn-link text-start" onClick={props.onEdit}>
							Edit this address
						</button>
					</div>

					<div className="col-12 p-0">
						<button
							className="btn btn-link text-start"
							onClick={props.onSearch}
						>
							Search for another address
						</button>
					</div>
				</div>
			</div>
		);
	};
	// ******************************************
	// ** MAIN component
	// ******************************************
	return (props) => {
		const {
			searchPostcode,
			postcode,
			HouseNameOrNumber,
			address1,
			address2,
			address3,
			address4,
			heading,
			onEdit,
			onSearch,
			FoundTemplate = DefaultFoundTemplate,
		} = props;

		const ID = useCallback(_.uniqueId(`Address`), []);

		const addressData = [
			(HouseNameOrNumber || address1) &&
				[HouseNameOrNumber, address1].join(" "),
			address2,
			address3,
			address4,
			postcode,
		]
			.filter((x) => x)
			.flatMap((x, i) => {
				if (i === 0) return [x];
				return [<br />, x];
			})
			.map((x, i) => <span key={i}>{x}</span>);

		const templateProps = {
			ID,
			addressData,
			searchPostcode,
			postcode,
			HouseNameOrNumber,
			address1,
			address2,
			address3,
			address4,
			heading,
			onEdit,
			onSearch,
		};

		return <FoundTemplate {...templateProps} />;
	};
};
