import React from "react";
import { Markup } from "interweave";
import { generateCypressProps } from "siteFunctions/cypress";

export const Alert = (props) => {
    const { className, children, ...otherProps } = props;

    const _className = [
        "alert",
        "alert-primary",
        //"m-0",
        "feedback",
        "feedback-info",
        className,
    ].toClassName();
    return (
        <div className={_className} {...otherProps}>
            {children}
        </div>
    );
};

export const Error = (props) => {
    const { className, children, ...otherProps } = props;

    if (!children) return;

    const _className = [
        "alert",
        "alert-danger",
        //"m-0",
        "feedback",
        "feedback-error",
        className,
    ].toClassName();
    return (
        <div className={_className} {...otherProps}>
            {children}
        </div>
    );
};

const Feedback = (props) => {
    const {
        debug = false,
        config = [],
        data = {},
        fallbackMsg: globalFallbackMsg,
        fallbackGeneral = null,
        className,
        Wrapper = ({ children }) => <>{children}</>,
    } = props;

    const foundItem = config
        .filter((x) => x.key in data)
        .map((x) => {
            let value = data[x.key];
            return { ...x, value };
        })
        .find((x) => x.value); // Filter anything that isn't truthy (e.g. " ", null, false, undefined)

    if (debug) console.log("Feedback", { config, data, foundItem });

    if (foundItem) {
        const { type, fallbackMsg = globalFallbackMsg, value } = foundItem;

        const MsgData = (function () {
            if (value === true) return <span>{fallbackMsg}</span>;

            return <Markup content={value} />; // Convert to HTML
        })();

        if (type === "error")
            return (
                <Wrapper>
                    <Error
                        className={className}
                        {...generateCypressProps("feedback-error", props)}
                    >
                        {MsgData}
                    </Error>
                </Wrapper>
            );

        return (
            <Wrapper>
                <Alert
                    className={className}
                    {...generateCypressProps("feedback-success", props)}
                >
                    {MsgData}
                </Alert>
            </Wrapper>
        );
    }

    return fallbackGeneral;
};

export default Feedback;
