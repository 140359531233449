// features/Documents/DocumentsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import chalk from "chalk";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  createExtraReducersSet,
  extraReducersSet,
  consoleLog,
} from "../common";
import _ from "lodash";

const generate = (args = {}) => {
  const { storeName, riskStoreConfig, privateActions, selectors } = args;

  return (listenerMiddleware) => {}; // ** DISABLED as part of #QUOTEANDBUY ** WASN'T USED ANYWAY

  return (listenerMiddleware) => {
    const { storeName: _storeName, actions: _actions } =
      riskStoreConfig.riskStore;

    listenerMiddleware.startListening({
      predicate: (action) => {
        // console.warn(action.type);
        if (action.type === _actions.UPDATE_SUCCESS(_storeName)) return true;
        if (action.type === _actions.UPDATE_REQUEST(_storeName)) return true;

        return false;
      },
      effect: async (action, listenerApi) => {
        return; // DISABLED
        const { dispatch, getState } = listenerApi;
        const { payload } = action;

        const state = getState();
        if (!selectors.getIsInit(state)) return;
        if (selectors.riskStore.getStoreName(state) !== _storeName) return;

        const genuinueUpdates = (function () {
          if (!payload) return [];
          if (!_.isArray(payload)) return [];
          return payload.filter((x) => "value" in x.data);
        })();

        console.log(
          "mta",
          "listener",
          action.type,
          genuinueUpdates,
          action.payload
          //, _.pick(payload, ["componentId","componentTag", "componentSet"])
        );

        //Reset the quote
        await dispatch(privateActions.resetQuote());

        // const salusData = selectors.riskStore.getSalusData(getState(), _storeName);

        // console.log("xxx", "salusData", salusData);
      },
    });

    listenerMiddleware.startListening({
      predicate: (action) => {
        return action.type === _actions.LOADRISK_SUCCESS(_storeName);
      },
      effect: async (action, listenerApi) => {
        // console.log(action.type);
        const { dispatch, getState } = listenerApi;
      },
    });
  };
};

export default generate;
