import React from "react";
import SiteLayout from "siteLayouts/Site";

import PageLayout from "siteLayouts/Page";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
} from "react-router-dom";

// import LegacyHandler from "./Handler";
const LegacyHandler = React.lazy(() => import("siteViews/Legacy/Handler"));

const routes = [
  {
    element: (
      <SiteLayout>
        <PageLayout>
          {/* <React.Suspense fallback={<Loading />}> */}
          <Outlet />
          {/* </React.Suspense> */}
        </PageLayout>
      </SiteLayout>
    ),
    children: [
      {
        path: "freedom/portalrecall.launch",
        element: <LegacyHandler mode="login" />,
      },
      {
        path: "freedom/registerportaluser.launch",
        element: <LegacyHandler mode="register" />,
      },
      {
        path: "freedom/portalconfirmnewpassword.launch",
        element: <LegacyHandler mode="resetpassword" />,
      },
    ],
  },
];

export default routes;
