import React from "react";
import PageLayout from "siteLayouts/Page";
import config from "config";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";

import { LoadingPanel } from "siteComponents";
import SiteLayout from "siteLayouts/Site";
import SiteWithImage from "siteLayouts/Site/WithImage";
// import AuthenticateView from "./Forms/Authenticate";

// import RegisterView from "./Forms/Register";
// import ForgotUsername from "./Forms/Reset/ForgotUsername";
// import ForgotPassword from "./Forms/Reset/ForgotPassword";
// import ForgotPasswordReset from "./Forms/Reset/ForgotPasswordReset";
// import ActivateView from "./Forms/Authenticate/Activate";
// import LinkView from "./Forms/Authenticate/Link";

const RegisterView = React.lazy(() => import("siteViews/Auth/Forms/Register"));
const ForgotUsername = React.lazy(() =>
  import("siteViews/Auth/Forms/Reset/ForgotUsername")
);
const ForgotPassword = React.lazy(() =>
  import("siteViews/Auth/Forms/Reset/ForgotPassword")
);
const ForgotPasswordReset = React.lazy(() =>
  import("siteViews/Auth/Forms/Reset/ForgotPasswordReset")
);
const ActivateView = React.lazy(() =>
  import("siteViews/Auth/Forms/Authenticate/Activate")
);
const LinkView = React.lazy(() =>
  import("siteViews/Auth/Forms/Authenticate/Link")
);
// import ConfirmEmailView from "./Forms/Register/ConfirmEmail";

// const Wrapper = (props) => {
//   return (
//     <PageLayout>
//       <Outlet />
//     </PageLayout>
//   );
// };

// const constants = {
//   // login: "login",
//   // profile: "my-account",
//   create: "register",
//   activate: "activate",
// };

// export const manifest = {
//   create: `/${constants.create}`,
//   activate: `/${constants.activate}`,
//   forgotusername: "/forgotusername",
//   forgotpassword: "/forgotpassword",
// };

// const SiteLayoutSwitcher = ({ children }) => {
//   const location = useLocation();
//   const useImageLayout = location.pathname.indexOf("login") > 0;
//   if (!useImageLayout) return <SiteLayout>{children}</SiteLayout>;
//   return <SiteWithImage>{children}</SiteWithImage>;
// };

const routes = [
  {
    element: (
      <Outlet />
      // <SiteLayoutSwitcher>
      //   <React.Suspense fallback={<LoadingPanel>Please wait...</LoadingPanel>}>
      //     <Outlet />
      //   </React.Suspense>
      // </SiteLayoutSwitcher>
    ),
    children: [
      // LinkPolicy
      {
        path: "login",
        element: (
          <SiteWithImage>
            <Outlet />
          </SiteWithImage>
        ),
        children: [
          {
            path: ":webReference",
            element: <LinkView routeDashboard={`/`} />,
          },
          {
            path: "",
            element: <Navigate to="/" />,
          },
        ],
      },

      {
        path: "activate",
        element: (
          <SiteLayout>
            <Outlet />
          </SiteLayout>
        ),
        children: [
          {
            path: ":activationToken",
            element: <ActivateView routeDashboard={`/`} />,
          },
          {
            path: "",
            element: <Navigate to="/" />,
          },
        ],
      },

      {
        path: "register",
        element: (
          <SiteLayout>
            <RegisterView routeLogin="/" />
          </SiteLayout>
        ),
      },

      {
        path: "forgotusername",
        element: (
          <SiteLayout>
            <ForgotUsername routeLogin="/" />
          </SiteLayout>
        ),
      },

      {
        path: "forgotpassword",
        element: (
          <SiteLayout>
            <ForgotPassword routeLogin="/" />
          </SiteLayout>
        ),
      },
      {
        path: "resetpassword/:activationToken",
        element: (
          <SiteLayout>
            {" "}
            <ForgotPasswordReset routeLogin="/" />
          </SiteLayout>
        ),
      },
    ],
  },
];

export default routes;
