import _ from "lodash";

const generate = (args = {}) => {
  const { storeName, authActions } = args;

  const getSessionId = (state) => state[storeName].sessionId;
  const getIsInit = (state) => state[storeName].isInit;
  const getHasLoadedData = (state) => state[storeName].hasLoadedData;
  const getIsSaving = (state) => state[storeName].isSaving;
  const getSavingMessage = (state) => state[storeName].savingMessage;

  return {
    getSessionId,
    getIsInit,
    getHasLoadedData,
    getIsSaving,
    getSavingMessage,
  };
};

export default generate;
