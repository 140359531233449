// ProtectedRoute.js
import React from "react";
import { useSelector } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";
import { actions, selectors } from "siteStore";
import PageLayout from "siteLayouts/Page";
import AuthenticateView from "siteViews/Auth/Forms/Authenticate";
import useSessionTimeout from "moveToLibrary/hooks/useSessionTimeout";
import TimeoutView from "siteViews/Errors/Timeout";
// import ProtectedTimeout from "./ProtectedTimeout";

const ProtectedRouteForm = (props) => {
  const isLoggedIn = useSelector(
    selectors.auth.isLoggedIn,
    require("lodash").isEqual
  );

  // const [isTimeOut, fnClearTimeout] = useSessionTimeout({ timeout: 1000 * 5 });

  // if (isTimeOut)
  //   return (
  //     <ComponentTimeout
  //       clearTimeout={() => {
  //         fnClearTimeout();
  //       }}
  //     />
  //   );

  if (!isLoggedIn) return <AuthenticateView />;

  return <Outlet context={{ test: 111 }} />;
  // return (

  //     {!isLoggedIn && (
  //       <AuthenticateView
  //         routeRegister="/register"
  //         routeForgotUsername="/forgotusername"
  //         routeForgotPassword="/forgotpassword"
  //       />
  //     )}
  //     {isLoggedIn && <Outlet />}

  // );
};

export default ProtectedRouteForm;
// export default (props) => (
//   <ProtectedTimeoutView>
//     <ProtectedRouteForm {...props} />
//   </ProtectedTimeoutView>
// );
