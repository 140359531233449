import React, { useRef, useEffect } from "react";

import { Card } from "../Cards";
import { ButtonStyled } from "../../Buttons";

import FocusTrap from "focus-trap-react";

// import useAutoFocus from "moveToLibrary/hooks/useAutoFocus";

// import ModalLibrary from "@library/common/components/base/form/Modal";
// import Panel from "../Panel";

const ConfirmModal = (props) => {
  const {
    title,
    cancelText,
    confirmText,
    onCancel = () => {},
    onConfirm = () => {},
    children,
    dataCy = "confirm-modal",
  } = props;

  const fnOnCancel = () => {
    onCancel();
  };

  const fnOnConfirm = () => {
    onConfirm();
  };

  // const ref = useAutoFocus();

  return (
    <div>
      <div className="modal-faded-background" />
      <div className="modal-container">
        <FocusTrap
          focusTrapOptions={{
            initialFocus: false, // Prevent the default focus on the CANCEL button
          }}
        >
          <div className="modal-main container">
            <Card
              data-cy={dataCy}
              header={title}
              footer={
                <div className="row">
                  <div className="col-12 col-sm-6 order-2 order-sm-1">
                    {cancelText && (
                      <ButtonStyled
                        onClick={fnOnCancel}
                        className="btn-outline-fb w-100"
                        // innerRef={ref}
                        data-cy="modal-btn-cancel"
                      >
                        {cancelText}
                      </ButtonStyled>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 order-1 order-sm-2 d-flex justify-content-end">
                    {confirmText && (
                      <ButtonStyled
                        onClick={fnOnConfirm}
                        className="w-100"
                        data-cy="modal-btn-confirm"
                      >
                        {confirmText}
                      </ButtonStyled>
                    )}
                  </div>
                </div>
              }
              className={""}
              classNameBody={""}
              classNameHeader={""}
              classNameFooter={""}
            >
              {children}
            </Card>
            {/* </div> */}
          </div>
        </FocusTrap>
      </div>
    </div>
  );
};

const ConfirmModalWrapper = (props) => {
  const { enabled = false, ...otherProps } = props;

  if (!enabled) return null;

  return <ConfirmModal {...otherProps} />;
};
export default ConfirmModalWrapper;
