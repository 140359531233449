import React from "react";
import _ from "lodash";
import createMta from "moveToLibrary/redux/mta";

import { createRiskStore } from "@library/store";
import siteConfig from "config";
import * as siteComponentsMta from "siteComponents";
import carDefaultState from "../../../templates/redux/carDefault.json";
import services from "siteService";

import dateHelpers from "@library/common/helpers/misc/date";
import { tidyPostcode } from "@library/common/helpers/misc/postcode";
import componentResources from "./componentResources";
import mtaServices from "./services";

const siteComponentsComposeListRisk = [
  { component: siteComponentsMta.TextBox, name: "TextBox" },
  { component: siteComponentsMta.TextBoxPostcode, name: "TextBoxPostcode" },
  {
    component: siteComponentsMta.DatePickerTextBox,
    name: "DatePickerTextBox",
    fnModifyValueInput: (v) => dateHelpers.toDate(v),
    fnModifyValueOutput: (v) => dateHelpers.toString(v),
  },

  {
    component: siteComponentsMta.DatePickerWithButtons,
    name: "DatePickerWithButtons",
    fnModifyValueInput: (v) => dateHelpers.toDate(v),
    fnModifyValueOutput: (v) => dateHelpers.toString(v),
  },

  { component: siteComponentsMta.DropDownType, name: "DropDownType" },
  {
    component: siteComponentsMta.DropDownTypeWebService,
    name: "DropDownTypeWebService",
  },
  {
    component: siteComponentsMta.RadioButtonsWebServiceMta,
    name: "RadioButtonsWebService",
  },

  {
    component: siteComponentsMta.RadioButtonsMta,
    name: "RadioButtons",
  },

  /*
				RadioButtonsWebService,
				DropDownTypeWebService,
  */
  // { component: siteComponents.Button, name: "Button" },
  // { component: siteComponents.Checkbox, name: "Checkbox" },
  // { component: siteComponents.CheckboxButton, name: "CheckboxButton" },
  // {
  //   component: siteComponents.DatePicker,
  //   name: "DatePicker",
  //   fnModifyValueInput: (v) => dateHelpers.toDate(v),
  //   fnModifyValueOutput: (v) => dateHelpers.toString(v),
  // },

  // {
  //   component: siteComponents.DatePickerCalendar,
  //   name: "DatePickerCalendar",
  //   fnModifyValueInput: (v) => dateHelpers.toDate(v),
  //   fnModifyValueOutput: (v) => dateHelpers.toString(v),
  // },
  // {
  //   component: siteComponents.DatePickerCalendarWebService,
  //   name: "DatePickerCalendarWebService",
  //   fnModifyValueInput: (v) => dateHelpers.toDate(v),
  //   fnModifyValueOutput: (v) => dateHelpers.toString(v),
  // },

  // {
  //   component: siteComponents.DatePickerDropDown,
  //   name: "DatePickerDropDown",
  //   fnModifyValueInput: (v) => dateHelpers.toDate(v),
  //   fnModifyValueOutput: (v) => dateHelpers.toString(v),
  // },
  // {
  //   component: siteComponents.DatePickerDropDownDay,
  //   name: "DatePickerDropDownDay",
  // },
  // {
  //   component: siteComponents.DatePickerDropDownMonth,
  //   name: "DatePickerDropDownMonth",
  // },
  // {
  //   component: siteComponents.DatePickerDropDownYear,
  //   name: "DatePickerDropDownYear",
  // },
  // { component: siteComponents.DropDown, name: "DropDown" },
  // { component: siteComponents.DropDownWebService, name: "DropDownWebService" },

  // { component: siteComponents.InceptionDate, name: "InceptionDate" },

  // { component: siteComponents.Error, name: "Error" },
  // { component: siteComponents.RadioButtons, name: "RadioButtons" },
  // { component: siteComponents.RadioButtons, name: "RadioButtons2" },

  // {
  //   component: siteComponents.TextBox,
  //   name: "TextBoxPostcode",
  //   fnModifyValueOutput: (v) => tidyPostcode(v),
  // },
  // { component: siteComponents.Hidden, name: "Hidden" },
];

const _siteComponents = {
  ..._.pick(siteComponentsMta, ["SubPanel"]),
};
const {
  database: _carRiskDefaultDatabase,
  getProps: _carRiskDefaultGetProps,
  rules: _carRiskRules,
  // database: _carRiskDatabase,
} = require("moveToLibrary/database/stella/carRisk");

// _carRiskDefaultDatabase.modifyItem({
//   path: "Risk/Proposer",
//   componentTag: "Email",
//   key: "componentTag",
//   data: "dddd",
// });
// console.log("database", _carRiskDefaultDatabase);
// throw `hhh`;

export const mtaRiskCar = createRiskStore({
  storeName: "mtaRiskCar",
  database: _carRiskDefaultDatabase,
  ruleData: _carRiskRules,
  siteComponentsComposeList: siteComponentsComposeListRisk,
  siteComponents: _siteComponents,
  defaultState: carDefaultState,
  config: siteConfig,
  componentResources,
});

// export const carRiskGetProps = _carRiskDefaultGetProps;
// export const carRiskDatabase = _carRiskDefaultDatabase;

// const sampleCarSalusData = require("./car/sampleCarSalusData.json");

// mtaRiskCar.functions.salus.salusToRedux
// mtaRiskCar.functions.salus.reduxToSedux
// mtaRiskCar.actions.loadRisk
// throw `hh`;
// console.log(mtaRiskCar)
// throw `test`
export const quoteAndBuyCar = require("./quoteandbuyStore/car").store;


export const mtaCar = createMta({
  storeName: "mtaCar",

  //#QUOTEANDBUY
  // quoteAndBuyStore: require("./quoteandbuyStore").quoteandbuyStore,
  riskStoreConfig: require("./quoteandbuyStore/car").riskConfig,

  // riskStoreConfig: {
  // storeType: "legacy",
  //   riskStore: mtaRiskCar,
  //   database: _carRiskDefaultDatabase,
  //   getProps: _carRiskDefaultGetProps,
  //   services: mtaServices,
  // },

  // mtaRiskCar: {
  //   actions: { loadRisk: (data) => mtaRiskCar.actions.loadRisk(data, true) },
  //   services: {
  //     getSalusRisk: async () => {
  //       // console.log("sampleCarSalusData", sampleCarSalusData);
  //       const [response] = await Promise.all([
  //         Promise.resolve({
  //           Status: "SUCCESS",
  //           Data: sampleCarSalusData,
  //         }),
  //         new Promise((resolve) => setTimeout(resolve, 500)),
  //       ]);

  //       if (response.Status !== "SUCCESS") {
  //         return {
  //           isSuccess: false,
  //           message: response.Message,
  //         };
  //       }

  //       const salusData = response.Data;

  //       console.log("salusData", salusData);
  //       const reduxData = mtaRiskCar.functions.salus.salusToRedux(salusData);
  //       console.log("reduxData", reduxData);

  //       return {
  //         isSuccess: response.Status === "SUCCESS",
  //         data: response.Data,
  //       };
  //     },
  //   },
  // },

  abortChecker: (r) => r === "ABORT",
  /*
  quotePayloadModifier: (payloadArgs = {}) => {
    const { baseSalusData, newSalusData } = payloadArgs;

    const exceptions = {
      "Risk.Duqs": true,
      "Risk.Userfields": true,
    };

    const fnMerge = (baseSalusData = {}, newSalusData = {}, metaData = {}) => {
      const { path = [], level = 1 } = metaData;

      const keyList = _.uniq([
        ...Object.keys(baseSalusData),
        ...Object.keys(newSalusData),
      ]);

      // Return statement
      return Object.fromEntries(
        keyList.map((k) => {
          const fullPathStr = [...path, k].join(".");
          const baseValue = baseSalusData[k];
          const newValue = newSalusData[k];
          const mergeArgs = {
            level: level + 1,
            path: [...path, k],
          };

          const fnThrowError = (msg, extraData = {}) => {
            console.log(fullPathStr, "DEBUGDATA:", {
              baseValue,
              newValue,
              baseSalusData,
              newSalusData,
              metaData,
              extraData,
            });
            throw msg;
          };

          const fnGenerateId = (x) => {
            if (_.isObject(x)) {
              // NOTE: COnvictions has Code anyway
              if ("Id" in x) return x["Id"];
              // if ("Code" in x) return x["Code"];

              fnThrowError(
                `Error in quotePayloadModifier -- missing a known ID`,
                { item: x, fullPath: fullPathStr }
              );
            }

            return x;
          };

          // // debug Logging
          // if (!_.isEqual(baseValue, newValue))
          //   console.log(fullPath || "ROOT", baseValue, "->", newValue);

          //Exceptions
          if (exceptions[fullPathStr]) {
            // console.log("EXCEPTION:", fullPathStr);
            return [k, baseSalusData[k]];
          }

          // Only in base
          if (k in baseSalusData && !(k in newSalusData)) {
            return [k, baseSalusData[k]];
          }

          // Only in new
          if (!(k in baseSalusData) && k in newSalusData) {
            return [k, newSalusData[k]];
          }

          // Array
          if (_.isArray(baseValue)) {
            if (!_.isArray(newValue)) {
              fnThrowError(
                `Error in quotePayloadModifier ARRAY CHECK "${path}.${k}"`
              );
            }

            const fnComposeItem = (x) => {
              return { id: fnGenerateId(x), data: x };
            };

            const newItems = newValue.map((x) => fnComposeItem(x));
            const baseItems = baseValue.map((x) => fnComposeItem(x));

            const returnItems = (function () {
              const commonItems = _.intersectionBy(
                newItems,
                baseItems,
                "id"
              ).map(({ id }) => {
                //  Merge the children
                const newData = fnMerge(
                  baseItems.find((x) => x.id === id).data,
                  newItems.find((x) => x.id === id).data,
                  mergeArgs
                );
                return { id: id, data: newData };
              });

              const addedItem = _.differenceBy(newItems, baseItems, "id");
              return [...commonItems, ...addedItem].map((x) => x.data);
            })();

            return [k, returnItems];
          }

          //  Object
          if (_.isObject(baseValue)) {
            if (!_.isObject(newValue)) {
              fnThrowError(
                `Error in quotePayloadModifier OBJECT CHECK "${path}"`
              );
            }

            return [k, fnMerge(baseValue, newValue, mergeArgs)];
          }

          // Other
          return [k, newValue];
        })
      );
    };

    // const retData = fnMerge(require("./sampleRisk.json"), newSalusData);
    const retData = fnMerge(baseSalusData, newSalusData);
    return retData;
  },
  */
  // TODO: In TopLevel.jsx, do the following for dev purposes:  const dummy = useSelector(mtaCar.selectors.payloads.quote);
});
