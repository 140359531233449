import dayjs from "dayjs";

import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import * as constants from "@library/common/constants/salus";
// proposerLogic

export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper")
      .default,
  } = args;
  // console.log("classOfUseLogic");

  require("@library/common/helpers/misc/argChecker")(functionArgs, [
    "AdditionalInsuredSet",
    "AdditionalInsuredSet_RelationshipToProposer_TAG",
  ]);

  const dh = new dataHelper({
    logging: false,
    mappings: ["ClassOfUse", "ClassOfUseFrontEnd"],
    dataSet,
    functionArgs,
  });


  if (dh.isPostRecall()) {
    const _ClassOfUseFrontEnd = (function () {
      if (dh.getValue("ClassOfUse") === "JC1") return "C1";
      return dh.getValue("ClassOfUse");
    })();

    dh.setValue("ClassOfUseFrontEnd", _ClassOfUseFrontEnd);
    dh.commit();
    return;
  }

  const addDriverTree =
    dh.dataSet.getTree({
      argItem: functionArgs.AdditionalInsuredSet,
      componentTagHitlist: [
        functionArgs.AdditionalInsuredSet_RelationshipToProposer_TAG,
      ],
    }) || [];

  const hasSpouse = addDriverTree.some((x) =>
    ["SPOUSEREL", "COMSPOUSE", "CivPrtnr"].includes(
      x.data.RelationshipToProposer.value
    )
  );

  //** sync the ClassOfUse Value
  const _ClassOfUse = (function () {
    const _ClassOfUseFrontEnd = dh.getValue("ClassOfUseFrontEnd");

    if (hasSpouse && _ClassOfUseFrontEnd === "C1") return "JC1";

    return _ClassOfUseFrontEnd;
  })();

  dh.setValue("ClassOfUse", _ClassOfUse);

  // console.log("classOfUseLogic", "_ClassOfUse", _ClassOfUse, dh);

  dh.commit();
};
