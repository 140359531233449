import React from "react";

const Divider = (props) => {
	// const { isSingleTier = true } = props;

	// const dividerClass = isSingleTier ? "d-md-none" : "d-md-none d-lg-block";

	return (
		<div className="d-md-none">
			<div className="fade_rule" />
		</div>
	);
};

export default Divider;
