import React, { useEffect, useState } from "react";
import config from "config";
import _ from "lodash";
import { useSelector } from "react-redux";
import { selectors, actions, helpers } from "siteStore";
import { useDispatch } from "siteFunctions/hooks";

import GlobalState from "@library/common/components/devTools/GlobalState";
import { database as carRiskDatabase } from "moveToLibrary/database/stella/carRisk";

import {
  Button,
  DevContainer,
  VehicleDetails,
  ButtonStyled,
  ButtonOutline,
  PanelHeader,
  RegPlate,
  CarIcon,
  BadgesPolicyStatus,
  CardHeaderVehicleStatus,
  TelephoneService,
  TextBox,
  Error,
  JsonViewer,
} from "siteComponents";

const sampleSalusData = {
  Risk: {
    PreviousInsurance: {
      Ncb: {
        Years: "9",
        ProtectedBonus: true,
        AppliedToAnotherVehicle: false,
      },
      PreviousPolicyBonusProtected: true,
      ExpiryDate: "2023-10-02T00:00:00",
      YearsHeld: "9",
      Type: "PC",
    },
    Cover: "C",
    TotalMileage: "10000",
    Restrict: "NMDDRVRS",
    ClassOfUse: "SDP",
    Vehicle: {
      VehicleType: "Car",
      Abs: true,
      BodyStyle: "H",
      Lhd: false,
      Doors: "5",
      Seats: "5",
      AbiCode: "39199161",
      AbiProductionYears: "2013 - 2017",
      Alarm: "NONE",
      Purchased: "2016-09-09",
      Cc: "1598",
      Fuel: "D",
      Immob: "NONE",
      Manufacturer: "PEUGEOT",
      ModelDescription: "308 ACTIVE THP 125",
      Owner: "Proposer",
      Value: "5420",
      Paid: "5420",
      Keeper: true,
      RegNumber: "KN14UKA",
      Tracker: "NONE",
      Transmission: "M",
      WhereKept: "Driveway",
      KeptPostcode: "CF82 8AA",
      YearOfManufacture: "2014",
      Modifications: [],
      ModificationInd: false,
    },
    ExtraDriverDetails: {
      DriverType: "PC",
      YearsNcd: "9",
      ProtectedNcd: true,
      AllowProtectedNcd: true,
    },
    Frequency: "PlcyAnnual",
    PolicyTypeCode: "PC",
    VolXs: "0",
    QuoteType: "NewQuotTyp",
    StartDate: "2024-01-08",
    EndDate: "2024-10-02T22:59:59+00:00",
    PolicyInceptionDate: "2023-10-03",
    Proposer: {
      Name: {
        Forenames: "Gurjit",
        Salutation: "Mr Lee",
        Surname: "Lee",
        Title: "Mr",
      },
      DateOfBirth: "1967-07-31",
      AccessToOtherVehicles: "BLANK",
      ResidentSince: "1967-07-31",
      IsResidentSinceBirth: true,
      HasSecondOccupation: false,
      FullTimeOccupation: {
        Type: "Employee",
        OccCode: "B15",
        EmpCode: "160",
      },
      PartTimeOccupation: {
        Type: "None",
        OccCode: "220",
        EmpCode: "186",
      },
      Licence: {
        Category: "CatB",
        Convictions: [
          {
            componentSet: "Risk/Proposer/Licence/Convictions/000",
            Code: "SP30",
            Date: "2019-01-01",
            Fine: "0",
            OffenceDate: "2019-01-01",
            Points: "0",
            Disqualification: "0",
          },
        ],
        Date: "2010-01-01",
        Type: "Full Licence",
      },
      Gender: "M",
      InsuranceRefused: false,
      MaritalStatus: "S",
      NonRTAConviction: false,
      Smoker: false,
      Tests: "1",
      VehicleUse: "MainUser",
      StarRider: "No",
      DvlaRestriction: "N",
      ConsentForConvictions: "NotKnown",
      Claims: [
        {
          componentSet: "Risk/Proposer/Claims/000",
          ClaimType: "M",
          ClaimDate: "2020-01-01",
          OwnDamageCost: "0",
          ThirdPartyCost: "0",
          NcbPrejudiced: false,
          PersonalInjury: false,
          UnderPolicyholdersCover: false,
          Code: "M051",
          BrokerReference: "319291/001/C1",
        },
      ],
      Email: "victoria.turner@freedomservicesgroup.co.uk",
      RelationshipToProposer: "Proposer",
      MotoringOrg: "BLANK",
      ConsentForMedicalConditions: "NotKnown",
      Id: "319291",
      ClientCategory: "STANDARD",
      ClientStatus: "Active",
      NumberOfOtherVehicles: "0",
      Address: {
        Postcode: "CF82 8AA",
        AbodeType: "House",
        Usage: "Main",
        CountryCode: "GB",
        HouseNameOrNumber: "3",
        AddressLine1: "Pengam Road",
        AddressLine2: "Ystrad Mynach",
        AddressLine3: "HENGOED",
        AddressLine4: "Mid Glamorgan",
      },
      MainTelephone: {
        Usage: "Mobile",
        AreaCode: "07777",
        PhoneNumber: "777777",
        TelephoneNumber: "07777777777",
      },
      PaymentMethod: "F",
      DriverType: "Prpsr",
    },
    AdditionalInsuredSet: [
      {
        componentSet: "Risk/AdditionalInsuredSet/000",
        Name: {
          Forenames: "Gurjita",
          Salutation: "Miss Lee",
          Surname: "Lee",
          Title: "Miss",
        },
        DateOfBirth: "1970-01-01",
        AccessToOtherVehicles: "BLANK",
        ResidentSince: "1970-01-01",
        IsResidentSinceBirth: true,
        HasSecondOccupation: false,
        FullTimeOccupation: {
          Type: "Retired",
          OccCode: "R09",
          EmpCode: "947",
        },
        PartTimeOccupation: {
          Type: "None",
          OccCode: "220",
          EmpCode: "186",
        },
        Licence: {
          Category: "CatB",
          Convictions: [
            {
              componentSet:
                "Risk/AdditionalInsuredSet/000/Licence/Convictions/000",
              Code: "SP30",
              Date: "2020-02-02",
              Fine: "0",
              OffenceDate: "2020-02-02",
              Points: "0",
              Disqualification: "0",
            },
          ],
          Date: "2000-01-01",
          Type: "Full Licence",
        },
        Gender: "F",
        InsuranceRefused: false,
        MaritalStatus: "S",
        NonRTAConviction: false,
        Smoker: false,
        Tests: "0",
        VehicleUse: "Frequently",
        StarRider: "No",
        DvlaRestriction: "N",
        ConsentForConvictions: "NotKnown",
        Claims: [
          {
            componentSet: "Risk/AdditionalInsuredSet/000/Claims/000",
            ClaimType: "M",
            ClaimDate: "2021-02-02",
            OwnDamageCost: "0",
            ThirdPartyCost: "0",
            NcbPrejudiced: false,
            PersonalInjury: false,
            UnderPolicyholdersCover: false,
            Code: "M051",
            BrokerReference: "319291/001/C2",
          },
        ],
        RelationshipToProposer: "COMSPOUSE",
        MotoringOrg: "BLANK",
        ConsentForMedicalConditions: "NotKnown",
        Id: "319306",
        DriverType: "NmdDrvr",
      },
      {
        componentSet: "Risk/AdditionalInsuredSet/001",
        Name: {
          Forenames: "Gurjitanna",
          Salutation: "Miss Lee-Lee",
          Surname: "Lee-Lee",
          Title: "Miss",
        },
        DateOfBirth: "1990-01-01",
        AccessToOtherVehicles: "BLANK",
        ResidentSince: "1990-01-01",
        IsResidentSinceBirth: true,
        HasSecondOccupation: false,
        FullTimeOccupation: {
          Type: "Employee",
          OccCode: "53D",
          EmpCode: "001",
        },
        PartTimeOccupation: {
          Type: "None",
          OccCode: "220",
          EmpCode: "186",
        },
        Licence: {
          Category: "CatB",
          Convictions: [
            {
              componentSet:
                "Risk/AdditionalInsuredSet/001/Licence/Convictions/000",
              Code: "SP30",
              Date: "2020-03-03",
              Fine: "0",
              OffenceDate: "2020-03-03",
              Points: "0",
              Disqualification: "0",
            },
          ],
          Date: "2010-01-01",
          Type: "Full Licence",
        },
        Gender: "F",
        InsuranceRefused: false,
        MaritalStatus: "S",
        NonRTAConviction: false,
        Smoker: false,
        Tests: "0",
        VehicleUse: "Frequently",
        StarRider: "No",
        DvlaRestriction: "N",
        ConsentForConvictions: "NotKnown",
        Claims: [
          {
            componentSet: "Risk/AdditionalInsuredSet/001/Claims/000",
            ClaimType: "M",
            ClaimDate: "2021-03-03",
            OwnDamageCost: "0",
            ThirdPartyCost: "0",
            NcbPrejudiced: false,
            PersonalInjury: false,
            UnderPolicyholdersCover: false,
            Code: "M051",
            BrokerReference: "319291/001/C3",
          },
        ],
        RelationshipToProposer: "OFFSPRNG",
        MotoringOrg: "BLANK",
        ConsentForMedicalConditions: "NotKnown",
        Id: "319307",
        DriverType: "NmdDrvr",
      },
    ],
    Userfields: [
      {
        componentSet: "Risk/Userfields/000",
        Code: "HEAR",
        Response: "Direct Web Business",
        ShortResponse: "DRCT",
        IsList: true,
        ScreenPrompt: "Where did you hear about us?",
      },
      {
        componentSet: "Risk/Userfields/001",
        Code: "NBQLAG",
        Response: "0",
        ShortResponse: "0",
        IsList: false,
        ScreenPrompt: "Quote Lag",
      },
    ],
    MedicalConsentQuestionsActive: false,
    ConvictionsConsentQuestionsActive: false,
    MarketingConsent: {
      Email: true,
      Post: true,
      Phone: true,
      Sms: true,
    },
    SourceOfBusiness: "Website",
    SourceOfEnquiry: "DRCT",
    Duqs: [
      {
        componentSet: "Risk/Duqs/000",
        Id: "54035111",
        QuestionNumber: "595",
        Response: "Full Payment",
        ShortResponse: "F",
        ScreenPrompt: "How do you pay for your insurance?",
      },
    ],
    Id: "179988095",
    Affinity: "STELLA",
  },
};

const QBTextBox = helpers.quoteAndBuyCar.composeComponent(TextBox);

const QBFormGroup = helpers.quoteAndBuyCar.composeComponent((props) => {
  const {
    children,
    className,
    heading,
    value,
    hidden,
    error = [],
    errorShow = false,
    debugData,
  } = props;
  //   console.log(heading, props);
  if (hidden) return null;

  const errorList = Object.values(error);
  const hasError = errorShow && errorList.length >= 1;
  const errorMessage = errorList.find((x) => x != "");

  return (
    <div
      className={[
        "form-group",
        "fb-form-group",
        className,
        hasError && "has-error",
      ].toClassName()}
    >
      <h6>{heading}</h6>
      {debugData && (
        <div>
          <JsonViewer>{debugData}</JsonViewer>
        </div>
      )}
      {children}
      {hasError >= 1 && errorMessage && <Error>{errorMessage}</Error>}
    </div>
  );
});

const ReduxQuoteAndBuy = () => {
  const [adhocPath, setAdhocPath] = useState("Risk");

  // const adhocData = useSelector((state) =>
  //   selectors.quoteAndBuyCar.userData.risk.item(state, adhocPath)
  // );

  useEffect(() => {
    const myFn = async () => {
      await dispatch(
        actions.quoteAndBuyCar.salusLoad({ salusData: sampleSalusData })
      );
      // await dispatch(actions.quoteAndBuyCar.testData());
    };
    myFn();
    return;
    helpers.quoteAndBuyCar.migration.database(carRiskDatabase, {
      //   autoMappingExclusions: [
      //     "AdditionalInsuredSet_RelationshipToProposer_TAG",
      //     "compareDateString",
      //     "policyType",
      //     "errorMessage",
      //     "errorMessages",
      //     "comparator",
      //     "componentTagList", // used for running other rules (path, data:["dateOfBirth"])
      //     "length",
      //     "yearOffset",
      //     "msgDateOfBirth",
      //     "today",
      //     "ComponentTagVehicleUse",
      //     "regEx",
      //     "storeName",
      //   ],
    });
  }, []);

  const dispatch = useDispatch();

  // const debugFullRisk = useSelector(
  //   selectors.quoteAndBuyCar.userData.risk.full
  // );

  // const AdditionalInsuredSetData = useSelector((state) =>
  //   selectors.quoteAndBuyCar.userData.risk.item(
  //     state,
  //     "Risk/AdditionalInsuredSet"
  //   )
  // );

  const errorCheckList = [
    // "Risk/Proposer/Name/Surname",
    // "Risk/*",
    // "Risk/AdditionalInsuredSet[0]/*",
    "Risk",
  ];
  const isValid = useSelector(
    (state) => selectors.quoteAndBuyCar.errors.isValid(state, errorCheckList),
    require("lodash").isEqual
  );

  const errorList = useSelector(
    (state) => selectors.quoteAndBuyCar.errors.list(state, "Risk"),
    require("lodash").isEqual
  );

  console.groupCollapsed("QuoteAndBuy.jsx", "errorList");
  errorList.forEach((x) => console.log(x));
  console.groupEnd();

  return (
    <div>
      {!isValid && (
        <Error>
          GLobal Error detected in:{" "}
          {errorCheckList.map((path) => (
            <p key={path} className="m-0">
              {path}
            </p>
          ))}
        </Error>
      )}

      <hr />
      <div className="bg-success p-2">
        <TextBox value={adhocPath} onChange={(v) => setAdhocPath(v)} />
        <QBFormGroup
          key={adhocPath}
          path={adhocPath}
          heading={adhocPath}
          showDebug={true}
          className="p-2 mb-3"
        >
          <QBTextBox path={adhocPath} />
        </QBFormGroup>
      </div>

      <hr />

      <ButtonStyled
        onClick={async () => {
          await dispatch(
            actions.quoteAndBuyCar.create({
              path: "Risk/AdditionalInsuredSet[TestID]+",
            })
          );
          await dispatch(
            actions.quoteAndBuyCar.updateValue({
              value: "testval",
              path: "Risk/AdditionalInsuredSet[TestID]/Name/Surname",
            })
          );
        }}
      >
        Risk/AdditionalInsuredSet[TestID]/Name/Surname
      </ButtonStyled>

      <hr />

      {[
        "Risk/Cover",
        "Risk/Proposer/Name/Surname",
        "Risk/AdditionalInsuredSet[Item_0]/Name/Surname",
      ].map((path) => (
        <QBFormGroup
          key={path}
          path={path}
          heading={path}
          className="p-2 mb-3"
          showDebug={true}
        >
          <QBTextBox path={path} />
        </QBFormGroup>
      ))}

      <hr />

      {[
        "Risk/Test",
        "Risk/Test2/TestArray[]",
        "Risk/Test2/TestArray[]+",
        "Risk/Test2/TestArray[Item_0]-",
        "Risk/AdditionalInsuredSet[TestID]+",
        "Risk/AdditionalInsuredSet[Item_0]-",
      ].map((path) => (
        <ButtonStyled
          key={path}
          onClick={async () => {
            await dispatch(actions.quoteAndBuyCar.create({ path: path }));
          }}
        >
          Create `{path}`
        </ButtonStyled>
      ))}
      <hr />

      <ButtonStyled
        onClick={async () => {
          await dispatch(
            actions.quoteAndBuyCar.salusLoad({ salusData: sampleSalusData })
          );
        }}
      >
        Salus LOAD TEST
      </ButtonStyled>

      <hr />

      {[
        { path: "Risk/Proposer/Name/Surname", value: "Test surname" },
        {
          path: "Risk/AdditionalInsuredSet[Item_0]/Name/Surname",
          value: "Test ADD surname 0",
        },
        {
          path: "Risk/AdditionalInsuredSet[Item_1]/Name/Forename",
          value: "Test ADD Forename 1",
        },
        {
          path: "Risk/AdditionalInsuredSet[Item_1]/Name/Surname",
          value: "Test ADD surname 1",
        },
      ].map((x) => (
        <div key={x.path} className="p-2 mb-3">
          <h6>{x.path}</h6>
          <ButtonStyled
            onClick={async () => {
              await dispatch(actions.quoteAndBuyCar.updateValue(x));
            }}
          >
            SET value: {x.value}
          </ButtonStyled>

          <ButtonStyled
            onClick={async () => {
              await dispatch(
                actions.quoteAndBuyCar.updateHidden({
                  path: x.path,
                  value: true,
                })
              );
            }}
          >
            Hide
          </ButtonStyled>
          <ButtonStyled
            onClick={async () => {
              await dispatch(
                actions.quoteAndBuyCar.errorAdd({
                  path: x.path,
                  errorKey: "TestErrorKey",
                  description: "Error description",
                })
              );
            }}
          >
            Add Error
          </ButtonStyled>
          <ButtonStyled
            onClick={async () => {
              await dispatch(
                actions.quoteAndBuyCar.errorRemove({
                  path: x.path,
                  errorKey: "TestErrorKey",
                })
              );
            }}
          >
            Remove Error
          </ButtonStyled>
        </div>
      ))}
      <hr />

      <DevContainer heading="Global state">
        {/* {[
          { label: "data", data: debugFullRisk },
          { label: "AdditionalInsuredSetData", data: AdditionalInsuredSetData },
        ].map((x) => (
          <div key={x.label} className="p-2 border mb-2">
            <h6>{x.label}</h6>
            <JsonViewer>{x.data}</JsonViewer>
          </div>
        ))} */}

        <div>
          <h5>Errors:</h5>
          {errorCheckList.map((k) => (
            <p key={k} className="m-0">
              {k}
            </p>
          ))}
          <p>isValid: {isValid ? "true" : "false"}</p>
        </div>
        <GlobalState />
      </DevContainer>
    </div>
  );
};

export default ReduxQuoteAndBuy;
