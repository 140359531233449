import { isEmpty } from "@library/common/database/rules/helpers";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

export default (args) => {
	const { dataSet, functionArgs } = args;
	//  return;
	if (!dataSet) throw `Error -- missing dataSet`;
	if (!functionArgs) throw `Error -- missing functionArgs`;

	const currentComponentTag = dataSet.getCurrentComponentTag();
	const componentTagHitlist = [currentComponentTag];

	const tree = dataSet.getTreeSiblings({ componentTagHitlist });

	// console.log(
	// 	"ssss tree",
	// 	tree,
	// 	"componentSet",
	// 	dataSet.componentSet,
	// 	"componentTag",
	// 	dataSet.componentTag
	// );
	const isValid = !(
		tree
			.map((x) => x[currentComponentTag])
			.some(
				(x) =>
					x.curcomponentSet === false &&
					x.value === functionArgs.compareConstant
			) &&
		dataSet.getValue(
			new dataSetArgItem({
				useDefaultComponentSet: true,
				useDefaultComponentTag: true,
			}),
			"value"
		) === functionArgs.compareConstant
	);

	//parentComponentTag
	//compareConstant
	// const testValue = dataSet.getValue({ key: "value" });
	// const isValid = !isEmpty(testValue);

	dataSet.setValue(
		new dataSetArgItem({
			key: "errors",
			errorKey: functionArgs.errorKey,
			value: isValid ? undefined : functionArgs.errorMessage,
			useDefaultComponentSet: true,
			useDefaultComponentTag: true,
		})
	);

	return null;
};
