import dateHelpers from "@library/common/helpers/misc/date";

const getPolicyTypeVariables = (createArgs, args) => {
	const { policyType, editType } = createArgs;
	//ABI code, engine size, transmission, doors, body style, fuel type
	switch (policyType) {
		case "PC": {
			return {
				SubViewEdit: (function () {
					// if (editType === "TYPE2")
					// 	return require("./subComponents/Edit/PC.type2").default(args);
					return require("./subComponents/Edit/PC").default(args);
				})(),
				policyTypeHeading: "car",
				requiredItemProps: [
					{
						key: "RegNumber",
						serviceKeyLookup: "RegNumber",
					},
					{
						key: "ModelDescription",
						serviceKeyLookup: "ModelDescription",
						serviceKeyManual: "AbiDescription",
					},
					{
						key: "Manufacturer",
						serviceKeyLookup: "Manufacturer",
						serviceKeyManual: "Make",
					},
					{
						key: "Transmission",
						serviceKeyLookup: "Transmission",
						serviceKeyManual: "Transmission",
					},
					{
						key: "Cc",
						serviceKeyLookup: "Cc",
						serviceKeyManual: "EngineSize",
					},
					{
						key: "Fuel",
						serviceKeyLookup: "Fuel",
						serviceKeyManual: "FuelType",
					},
					{
						key: "YearOfManufacture",
						serviceKeyLookup: "YearOfManufacture",
						serviceKeyManual: "StartYear",
					},
					{
						key: "Value",
						serviceKeyLookup: "Value",
						optional: true,
					},

					{
						key: "VehicleType",
						serviceKeyLookup: "VehicleType",
						serviceKeyManual: "VehicleType",
					},
					{
						key: "BodyStyle",
						serviceKeyLookup: "BodyStyle",
						serviceKeyManual: "BodyStyle",
					},
					{
						key: "Lhd",
						serviceKeyLookup: "Lhd",
						serviceKeyManual: "Lhd",
						optional: true,
					},
					// "Doors",
					{
						key: "Seats",
						serviceKeyLookup: "Seats",
						optional: true,
					},
					{
						key: "AbiCode",
						serviceKeyLookup: "AbiCode",
						serviceKeyManual: "AbiCode",
					},
					{
						key: "AbiProductionYears",
						serviceKeyLookup: "AbiProductionYears",
						serviceKeyManual: "AbiProductionYears",
					},
					{
						key: "Purchased",
						serviceKeyLookup: "Purchased",
						optional: true,
						fnOutput: (v) =>
							dateHelpers.toString(v, { inputFormat: "YYYY-MM-DDThh:mm:ss" }),
					},
					{
						key: "Paid",
						serviceKeyLookup: "Paid",
						optional: true,
					},
					{
						key: "Doors",
						serviceKeyLookup: "Doors",
						serviceKeyManual: "NumberOfDoors",
					},
					{
						key: "IsLookup",
						serviceKeyLookup: "AbiCode",
						serviceKeyManual: "AbiCode",

						fnOutput: (v) => (v ? true : false),
					},

					{
						key: "Immob",
						serviceKeyLookup: "Immob",
						serviceKeyManual: "Immob",
						optional: true,
					},

					{
						key: "Alarm",
						serviceKeyLookup: "Alarm",
						serviceKeyManual: "Alarm",
						optional: true,
					},

					{
						key: "Tracker",
						serviceKeyLookup: "Tracker",
						serviceKeyManual: "Tracker",
						optional: true,
					},
				],
			};
		}
		case "GV": {
			return {
				SubViewEdit: (function () {
					// if (editType === "TYPE2")
					// 	return require("./subComponents/Edit/GV.type2").default(args);
					return require("./subComponents/Edit/GV").default(args);
				})(),
				policyTypeHeading: "van",
				requiredItemProps: [
					{
						key: "RegNumber",
						serviceKeyLookup: "RegNumber",
					},
					{
						key: "ModelDescription",
						serviceKeyLookup: "ModelDescription",
						serviceKeyManual: "AbiDescription",
					},
					{
						key: "Manufacturer",
						serviceKeyLookup: "Manufacturer",
						serviceKeyManual: "Make",
					},
					{
						key: "Transmission",
						serviceKeyLookup: "Transmission",
						serviceKeyManual: "Transmission",
					},
					{
						key: "Cc",
						serviceKeyLookup: "Cc",
						serviceKeyManual: "EngineSize",
					},
					{
						key: "Fuel",
						serviceKeyLookup: "Fuel",
						serviceKeyManual: "FuelType",
					},
					{
						key: "YearOfManufacture",
						serviceKeyLookup: "YearOfManufacture",
						serviceKeyManual: "StartYear",
					},
					{
						key: "Value",
						serviceKeyLookup: "Value",
						optional: true,
					},

					{
						key: "VehicleType",
						serviceKeyLookup: "VehicleType",
						serviceKeyManual: "VehicleType",
					},
					{
						key: "BodyStyle",
						serviceKeyLookup: "BodyStyle",
						serviceKeyManual: "BodyStyle",
					},
					{
						key: "BodySubStyle",
						serviceKeyLookup: "BodySubStyle",
						serviceKeyManual: "BodySubStyle",
						optional: true,
					},
					{
						key: "Lhd",
						serviceKeyLookup: "Lhd",
						optional: true,
					},
					// "Doors",
					{
						key: "Seats",
						serviceKeyLookup: "Seats",
						optional: true,
					},
					{
						key: "AbiCode",
						serviceKeyLookup: "AbiCode",
						serviceKeyManual: "AbiCode",
					},
					{
						key: "AbiProductionYears",
						serviceKeyLookup: "AbiProductionYears",
						serviceKeyManual: "AbiProductionYears",
					},
					{
						key: "Purchased",
						serviceKeyLookup: "Purchased",
						optional: true,
						fnOutput: (v) =>
							dateHelpers.toString(v, { inputFormat: "YYYY-MM-DDThh:mm:ss" }),
					},
					{
						key: "Paid",
						serviceKeyLookup: "Paid",
						optional: true,
					},
					{
						key: "BodyStyle",
						serviceKeyLookup: "BodyStyle",
					},
					{
						key: "GrossWeight",
						serviceKeyLookup: "GrossWeight",
						serviceKeyManual: "GrossWeight",
					},
					{
						key: "IsLookup",
						serviceKeyLookup: "AbiCode",
						serviceKeyManual: "AbiCode",

						fnOutput: (v) => (v ? true : false),
					},

					{
						key: "Immob",
						serviceKeyLookup: "Immob",
						serviceKeyManual: "Immob",
						optional: true,
					},

					{
						key: "Alarm",
						serviceKeyLookup: "Alarm",
						serviceKeyManual: "Alarm",
						optional: true,
					},

					{
						key: "Tracker",
						serviceKeyLookup: "Tracker",
						serviceKeyManual: "Tracker",
						optional: true,
					},
					// Doors is not required for VAN
					//{
					//	key: "Doors",
					//	serviceKeyLookup: "Doors",
					//	serviceKeyManual: "NumberOfDoors",
					//},
				],
			};
		}

		default: {
			console.log("createArgs", createArgs);
			throw `Error -- unknow policyType`;
		}
	}
};

export default getPolicyTypeVariables;
