import React, { useState } from "react";
import _ from "lodash";

import { ButtonToggle } from "../../../Buttons";
import { CardBorder } from "../../Cards";

const Item = (props) => {
  const { id, label, parentId, data, cardHeaderText } = props;

  const idHeader = `${id}_header`;
  const idBody = `${id}_body`;

  return (
    <div className="tab-panel-data accordion-item">
      <h2 className="tab-heading w-100 accordion-header" id={idHeader}>
        <button
          // onClick={() => props.onSelect()}
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${idBody}`}
          aria-expanded="false"
          aria-controls={idBody}
        >
          {label}
        </button>
      </h2>
      <div
        id={idBody}
        className="accordion-collapse collapse"
        data-bs-parent={`#${parentId}`}
        aria-labelledby={idHeader}
      >
        <div className="accordion-body">{data}</div>
      </div>
      {/* {props.isSelected && <>{props.data}</>} */}
      {/* {props.isSelected? "Selected": "no"} */}
      {/* <button onClick={() => props.onSelect()}>click</button> */}
    </div>
  );
};

const ItemSticky = (props) => {
  // Sticky version
  return (
    <div className="tab-panel-data">
      <div className="sticky-top bg-white w-100">
        <div className="tab-heading">{props.label}</div>
      </div>
      <>{props.data}</>
    </div>
  );
};

const TabPanelViewExpand = (props) => {
  const { validTabs, setCurIdx, curIdx, cardHeaderText } = props;

  const [id] = useState(_.uniqueId("tab-panel-"));
//   console.log("dadaadad ", { props, CardBorder });
  return (
    <CardBorder
      className="tab-panel-card"
      classNameBody="p-0"
      header={cardHeaderText}
    >
      <div className="accordion accordion-flush" id={id}>
        {validTabs.map((x, i) => {
          return (
            <Item
              parentId={id}
              id={`${id}_${i}`}
              key={i}
              label={x.label}
              data={x.data}
              onSelect={() => setCurIdx(i)}
              isSelected={i === curIdx}
              // curIdx={curIdx}
            />
          );
        })}
      </div>
    </CardBorder>
  );
};

export default TabPanelViewExpand;
