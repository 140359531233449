import dayjs from "dayjs";
import _ from "lodash";

import dataHelper from "@library/common/database/rules/helpers/dataHelper";

export default (args) => {
	return; // NOTE IN USE.

	const { dataSet, functionArgs } = args;

	const dh = new dataHelper({
		mappings: ["PolicyInceptionDate", "EndDate"],
		dataSet,
		functionArgs,
	});

	// exit out if it's a recall
	if (dh.isPostRecall()) return;

	if (dh.isUpdated("PolicyInceptionDate")) {
		const _PolicyInceptionDate = dh.getValueDate("PolicyInceptionDate");
		// console.log("ddddd", _PolicyInceptionDate, dh);

		if (!_PolicyInceptionDate) {
			dh.setValueDate("EndDate", _PolicyInceptionDate);
		} else {
			const newEndDate = new dayjs(_PolicyInceptionDate)
				.subtract(1, "days")
				.toDate();

			// console.log("ddddd2", newEndDate);
			dh.setValueDate("EndDate", newEndDate);

			// Date copy = new Date(original.getTime());
			// let newEndDate = _.cloneDeep(_PolicyInceptionDate).;
			// dh.setData("value", "EndDate", _PolicyInceptionDate);
		}
	}

	// dh.addErrorIfEmpty("EndDate", ""); // "Required")
	// console.log("startDateLogic", dh.getCurrent(), dh);
	dh.commit();
	return;
};
