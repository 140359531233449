import React from "react";
import Routes from "siteRoutes";
import config from "config";

import "moveToLibrary/prototypes";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.min.js";

// import { StickyContainer, Sticky } from "react-sticky";

import "siteAssets/css";

import SiteStore from "siteStore";
import useLiveChat from "./useLiveChat";

import { ErrorBoundarySite } from "siteComponents/Errors";
import { LoadingPanel } from "siteComponents";

const ServicesCache = () => {
  // Call any services that CACHE here
  return null;
};

const BaseApp = () => {
  return (
    <SiteStore>
      <ErrorBoundarySite>
        <ServicesCache />
        <Routes />
      </ErrorBoundarySite>
    </SiteStore>
  );
};

const App = (props) => {
  useLiveChat();
  if (config.isDev && config.useStrictMode) {
    console.log("*** USING STRICT MODE ***");
    return (
      <React.StrictMode>
        <BaseApp />
      </React.StrictMode>
    );
  }

  return <BaseApp />;
};

export default App;
