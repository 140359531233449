import services from "siteService";
import dateHelpers from "@library/common/helpers/misc/date";
import JSONhelper from "@library/common/helpers/misc/json";
import _ from "lodash";
import dayjs from "dayjs";
import { generateKeys } from "siteHelpers/services";
import base64js from "base64-js";

const documents = {
  upload: async (args = {}) => {

    ["files", "webReference", "policyId"].argChecker(args);

    if ("comments" in args) {
      if ("taskId" in args)
        throw `Error in documents upload -- can't have a "comments" at the same time as "taskId"`;
      if ("diaryId" in args)
        throw `Error in documents upload -- can't have a "comments" at the same time as "diaryId"`;
    }

    try {
      const fileData = await Promise.all(
        args.files.map(
          ({ data, description }) =>
            new Promise((resolve) => {
              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(data);
              fileReader.onload = function (ev) {
                const result = ev.target.result;

                resolve({
                  FileName: data.name,
                  Description: description,
                  Document: base64js.fromByteArray(new Uint8Array(result)),
                });
              };
            })
        )
      );

      const payload = {
        PolicyId: args.policyId,
        WebReference: args.webReference,
        UploadDetails: fileData,
        TaskId: args.taskId,
        DiaryId: args.diaryId,
      };

      if (args.comments) payload.Comments = args.comments;

      // console.log("site function uploadDocuments payload", payload);

      // console.warn("DISABLED", payload);
      // throw `UPLOAD DISABLED`;

      const response = (await services.documents.upload(payload)) || {};
      if (response === "ABORT") return "ABORT";

      return {
        isSuccess: response.Status === "SUCCESS",
        message: response.Message,
        remainingUploads: response.RemainingUploads,
        diaryId: response.DiaryId,
        taskId: response.TaskId,
      };
    } catch (e) {
      return {
        isSuccess: false,
        message: e.message,
      };
    }
  },
  mailDocument: async (args = {}) => {
    const fnCreateRetObj = (response) => ({
      isSuccess: response.Status === "SUCCESS",
      message: response.Message,
    });

    [
      "webReference",
      "policyId",
      "documentIds",
      // , "ProposerId"
    ].argChecker(args);

    try {
      const { webReference, 
        // proposerId, 
        policyId, documentIds } = args;

      const payload = {
        WebReference: webReference,
        // ProposerId: proposerId,
        PolicyId: policyId,
        DocumentIds: documentIds,
      };

      const response = await services.documents.requestPrint(payload);
      if (response === "ABORT") return "ABORT";

      return fnCreateRetObj(response);
    } catch (e) {
      return {
        isSuccess: false,
        message: e.message,
      };
    }
  },
  getDocument: async (args = {}) => {
    try {
      const { policyId, documentId } = args;

      ["documentId", "policyId"].forEach((k) => {
        if (!(k in args))
          throw `Error in setDocumentDownloaded -- missing "${k}"`;
      });

      const response = await services.documents.getDocument(
        [policyId, "Documents", documentId].join("/")
      );

      if (response === "ABORT") return "ABORT";

      const fnCreateRetObj = (response) => ({
        isSuccess: response.Status === "SUCCESS",
        byteArrayData: base64js.toByteArray(response.Document),
        base64Data: response.Document,
        mimeType: response.MimeType,
        filename: response.Filename,
        message: response.Message,
        transactionId: response.TransactionId,
      });

      if (!response.Document)
        return fnCreateRetObj({
          ..._.pick(response, ["Message", "TransactionId"]),
          isSuccess: false,
        });

      return fnCreateRetObj(response);
    } catch (e) {
      return {
        isSuccess: false,
        message: e.message,
      };
    }
  },

  getPolicyDocuments: async (args = {}) => {
    try {
      const { policyId } = args;

      ["policyId"].argChecker(args);

      const response = await services.documents.getPolicyDocuments(
        [policyId, "Documents"].join("/")
      );
      if (response === "ABORT") return "ABORT";

      return {
        documents: response.Documents,
        isSuccess: response.Status === "SUCCESS",
        message: response.Message,
      };
    } catch (e) {
      return {
        isSuccess: false,
        message: e.message,
      };
    }
  },
};

export default documents;
