// Vehicle.jsx

import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import _ from "lodash";
import { connect } from "react-redux";

// example reg
import getPolicyTypeVariables from "./getPolicyTypeVariables";

export const views = {
  search: "search",
  edit: "edit",
  found: "found",
  notFound: "notFound",
};
const _defaultView = views.search;

export default (args) => {
  // Generator function
  const { store, storeComponentsCustom } = args;
  const { FormGroup: StoreFormGroup } = storeComponentsCustom;

  const _baseFunction = (createArgs = {}) => {
    require("@library/common/helpers/misc/argChecker")(createArgs, [
      "itemProps",
      // "itemPropsClearList",
      "searchService",
      "searchServiceManualManufacturer",
      "searchServiceManualResults",
      "fueltypeService",
      "transmissionService",
      "YearOfManufactureService",
      "policyType",
    ]);

    const {
      itemProps,
      // itemPropsClearList,
      searchService,
      searchServiceManualManufacturer,
      searchServiceManualResults,
      fueltypeService,
      transmissionService,
      YearOfManufactureService,
      showEdit = true,
      policyType,
      registrationPlateDivClass = "registrationPlate",
      onSearch = () => null,
      onSearchManual = () => null,
      onEdit = () => null,
      onError = () => null,
      onChangeVehicle = () => null,
      onCancel = () => null,
      onFoundData = () => null,
      onReset = () => null,
      FoundTemplate,
      SearchTemplate,
      EditTemplate,
      editTemplateConfig,
      FoundTemplateVehicleDescription,
      EditButtonConfirm,
      LoadingComponent = () => <div>Please wait...</div>,
    } = createArgs;

    // ["SearchTemplate", "EditTemplate", "FoundTemplate"].forEach((k) => {
    //   if (!(k in createArgs))
    //     throw `Error in generateVehicle -- missing "${k}"`;
    // });

    // Get the WRAPPERS
    const baseDatabaseProps = {
      pathList: [itemProps.AbiCode.path],
    };

    const {
      WrapperSearch = ({ children }) => (
        <StoreFormGroup databaseProps={baseDatabaseProps}>
          {children}
        </StoreFormGroup>
      ),
      WrapperFound = ({ children }) => (
        <StoreFormGroup databaseProps={baseDatabaseProps}>
          {children}
        </StoreFormGroup>
      ),
      WrapperEdit = ({ children }) => <>{children}</>,
    } = createArgs;

    const SubViewSearch = require("./subComponents/Search").default({
      ...args,
      registrationPlateDivClass,
    });
    const SubViewFound = require("./subComponents/Found").default({
      ...args,
      fueltypeService,
      transmissionService,
      registrationPlateDivClass,
    });

    const id = _.uniqueId("VehicleComponent");

    const { requiredItemProps, SubViewEdit, policyTypeHeading } =
      getPolicyTypeVariables(createArgs, args);

    const makeMapStateToProps = () => {
      // const getValue = store.selectors.makeGetValue();

      return (state) => {
        let retObj = {};
        requiredItemProps.forEach(({ key }) => {
          if (!(key in itemProps) || !("path" in itemProps[key]))
            throw `Error in Vehicle Generator -- missing DBitem = ${key}`;
          retObj[key] = store.selectors.userData.risk.metaData(
            state,
            itemProps[key].path
          )?.value;
        });
        return retObj;
      };
    };

    const mapDispatchToProps = {
      update: store.actions.updateValue,
      // update: store.actions.update,
      // register: store.actions.register,
      // validate: store.actions.validate,
      // funcRegisterWithoutSaga: store.actions.registerWithoutSaga,
    };

    const pathList = Object.values(itemProps).map((item) => item.path);

    const resetVehicle = async (dispatch) => {
      if (!dispatch) throw `Error in resetVehicle -- missing "dispatch"`;

      const hitlist = requiredItemProps
        .filter((x) => x.serviceKeyLookup)
        .map(({ key }) => {
          return itemProps[key];
        });

      hitlist.forEach(async (x) => {
        await dispatch(
          store.actions.updateValue({
            path: x.path,
            value: x.defaultValue,
          })
        );
      });

      //updateValue({path, value})
      // return new Promise((resolve) => {
      //   dispatch(actions.update(hitlist, () => resolve()));
      // });
    };

    // *****************************
    // ** MAIN component
    // *****************************
    const GeneratedComponent = connect(
      makeMapStateToProps,
      mapDispatchToProps
    )((props) => {
      const {
        update,
        funcRegisterWithoutSaga,
        forceShowErrors,
        searchHeading = "Please enter your registration",
        foundHeading = "Your vehicle",
        editHeading = "Your vehicle",
        defaultView = _defaultView,

        currentView,
        changeView,
        ...otherProps
      } = props;
      const [internalCurrentView, setInternalCurrentView] =
        useState(defaultView);

      // in case the view is managed by the parent component
      const finalCurrentView = currentView || internalCurrentView;

      const _changeView = useCallback((view) => {
        if (changeView) changeView(view);
        setInternalCurrentView(view);
      }, []);

      const setVehicle = useCallback((vehicleData, isManualLookup = false) => {
        const hitlist = [];

        //NOTE: it's okay for vehicleData to be "undefined"
        console.groupCollapsed("Vehicle Lookup");
        console.log("requiredItemProps:", requiredItemProps);
        console.log("vehicleData:", vehicleData);

        requiredItemProps.forEach(
          ({
            key,
            serviceKeyManual,
            serviceKeyLookup,
            optional = false,
            fnOutput = (v) => v,
            resetErrors = true,
          }) => {
            const lookupKey = isManualLookup
              ? serviceKeyManual
              : serviceKeyLookup;

            if (lookupKey) {
              // if we have vehicleData, make sure all the keys we requrie are in there
              if (vehicleData && !optional && !(lookupKey in vehicleData)) {
                console.log("key:", key);
                console.log("lookupKey:", lookupKey);
                console.log("itemProps:", itemProps);
                console.log("vehicleData:", vehicleData);
                console.log("requiredItemProps:", requiredItemProps);
                throw `Error in vehicle lookup -- can't find ${key} in vehicleDetails`;
              }
              const newValue = vehicleData
                ? fnOutput(vehicleData[lookupKey])
                : undefined;

              console.log("updating", itemProps[key].path, ":", newValue);

              if (resetErrors) onReset();

              // console.log("ddddd", itemProps[key]);
              const newItem = {
                key,
                path: itemProps[key].path,
                value: newValue,
                // componentData: {
                //   componentId: itemProps[key].componentId,
                //   componentSet: itemProps[key].componentSet,
                //   value: newValue, //NOTE: this may be undefined
                //   ...(resetErrors && { errorsShow: false }),
                // },
              };

              hitlist.push(newItem);
            }
          }
        );

        hitlist.forEach((x) => update({ path: x.path, value: x.value }));
        if (props.onSetVehicle) props.onSetVehicle(hitlist);
        console.groupEnd();
      }, []);

      useEffect(() => {
        // const registerArray =
        //   Object.values(itemProps).map((x) => classes.componentDefinition(x)) ||
        //   [];
        // funcRegisterWithoutSaga(registerArray);
      }, []);

      switch (finalCurrentView) {
        case views.notFound:
        case views.search: {
          return (
            <WrapperSearch>
              <SubViewSearch
                {...otherProps}
                failedSearch={finalCurrentView === views.notFound}
                pathList={pathList}
                onFoundData={(vehicleData) => {
                  setVehicle(vehicleData);

                  onFoundData(vehicleData);
                  if (props.onFoundData) props.onFoundData(vehicleData);

                  if (vehicleData) {
                    _changeView(views.found);
                  } else {
                    _changeView(views.notFound);
                  }
                }}
                onEdit={() => {
                  onEdit();
                  if (props.onEdit) props.onEdit();
                  _changeView(views.edit);
                }}
                onError={() => {
                  onError();
                  if (props.onError) props.onError();

                  // _changeView(views.edit);
                }}
                heading={searchHeading}
                policyTypeHeading={policyTypeHeading}
                forceShowErrors={forceShowErrors}
                searchService={searchService}
                showEdit={showEdit}
                SearchTemplate={SearchTemplate}
              />
            </WrapperSearch>
          );
        }
        case views.found: {
          let newProps = {};
          requiredItemProps.forEach(({ key }) => {
            newProps[key] = props[key];
          });

          return (
            <WrapperFound>
              <SubViewFound
                {...otherProps}
                {...newProps}
                onEdit={() => {
                  onEdit();
                  if (props.onEdit) props.onEdit();

                  _changeView(views.edit);
                }}
                onSearch={() => {
                  setVehicle(undefined);
                  onSearch();
                  if (props.onSearch) props.onSearch();

                  _changeView(views.search);
                }}
                onSearchManual={() => {
                  setVehicle(undefined);
                  // resetRuleErrorsShow(true);

                  onSearchManual();
                  if (props.onSearchManual) props.onSearchManual();

                  _changeView(views.search);
                }}
                heading={foundHeading}
                policyTypeHeading={policyTypeHeading}
                policyType={policyType}
                FoundTemplate={FoundTemplate}
                FoundTemplateVehicleDescription={
                  FoundTemplateVehicleDescription
                }
              />
            </WrapperFound>
          );
        }
        case views.edit: {
          return (
            <WrapperEdit>
              <SubViewEdit
                {...otherProps}
                editTemplateConfig={editTemplateConfig}
                DBitems={itemProps}
                onChangeVehicle={(vehicleData) => {
                  setVehicle(vehicleData, true);
                  // resetRuleErrorsShow(true);

                  onChangeVehicle(vehicleData);
                  if (props.onChangeVehicle) props.onChangeVehicle(vehicleData);

                  _changeView(views.found);
                }}
                onCancel={() => {
                  onCancel();
                  if (props.onCancel) props.onCancel();

                  _changeView(views.search);
                }}
                editHeading={editHeading}
                group={`${id}_edit`}
                searchService={searchService}
                searchServiceManualManufacturer={
                  searchServiceManualManufacturer
                }
                searchServiceManualResults={searchServiceManualResults}
                fueltypeService={fueltypeService}
                transmissionService={transmissionService}
                YearOfManufactureService={YearOfManufactureService}
                policyTypeHeading={policyTypeHeading}
                ButtonConfirm={EditButtonConfirm}
                LoadingComponent={LoadingComponent}
                EditTemplate={EditTemplate}
              />
            </WrapperEdit>
          );
        }
        default: {
          return null;
        }
      }
    });

    return { Component: GeneratedComponent, functions: { resetVehicle } };
  };

  return {
    create: (...args) => _baseFunction(...args).Component,
    generate: (...args) => _baseFunction(...args), // V2 -- returns "component" and "functions"
    views,
  };
};
