import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";
import config from "config";

import PageMtaLayout from "siteLayouts/Page/Mta";
// import ChangeViewer, { useChanges } from "../Components/ChangeViewer";
import useBlockers from "../common/useBlockers";
import { useScrollToTop } from "moveToLibrary/hooks/useScrollTo";
import { Helmet } from "react-helmet";
import _ from "lodash";
import {
  useLocation,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

import { components } from "siteStore";

import {
  ButtonStyled,
  LoadingRow,
  Loading,
  PanelMta,
  Error,
  ConfirmModalButton,
  DevContainer,
} from "siteComponents";

import SessionRefreshing from "../../common/SessionRefreshing";
import PleaseWait from "./PleaseWait";
const Wrapper = (props) => {
  // const { functions, pagerData, selectors } = useOutletContext();
  // const salusData = useSelector(selectors.riskStore.getSalusData);
  // const fnGetChangeData = useChanges(salusData);
  // useScrollToTop();

  return (
    <PageMtaLayout {...props}>
      {props.children}

      {/* <DevContainer heading="Changes">
        <ChangeViewer data={fnGetChangeData} />
      </DevContainer> */}
    </PageMtaLayout>
  );
};

const MtaBase = (props) => {
  const {
    children,
    isLoading = false,
    isLoadingMessage = undefined, //"Please wait...",
    isCompleting = false,
    isCompletingMessage = undefined, //"Please wait...",
    className,
    headingText = "",

    isStart = false,
    isChange = false,
    isQuote = false,
    isFeedback = false,
    isWrapup = false,
    isWrapupLauncher = false,
    showSessionSaving = true,
    ...otherProps
  } = props;

  // console.log("dadadad", props);
  // throw `hhh`
  const { functions, pagerData, selectors, session } = useOutletContext();

  const redirectObject = useBlockers({
    isStart,
    isChange,
    isQuote,
    isFeedback,
    isWrapup,
    isWrapupLauncher,
  });

  const statusData_loadData = useSelector(
    selectors.status.loadData,
    require("lodash").isEqual
  );
  const statusData_restart = useSelector(
    selectors.status.restart,
    require("lodash").isEqual
  );

  //****************************************
  // ERROR CHECKING
  //****************************************

  [
    { data: statusData_loadData, dataType: "loadData" },
    { data: statusData_restart, dataType: "restart" },
  ].forEach(({ data, dataType }) => {
    if (data.errorMessage)
      throw `BASE.jsx error -- ${dataType} - ${data.errorMessage}`;
  });

  // const isSessionSaving = useSelector(selectors.session.getIsSaving);
  //****************************************
  // VARS
  //****************************************
  const baseProps = {
    snapToTop: true,
    headingText: headingText, //`Welcome back ${userInfo.DisplayName}`,
    ["data-cy"]: "layout-page-mta",
    className: [className].toClassName(),
    ...otherProps,
  };

  const [_isLoading, _isLoadingMessage] = (function () {
    if (isLoading) return [true, isLoadingMessage];
    if (statusData_loadData.isInitiating) return [true, isLoadingMessage];
    if (statusData_restart.isInitiating) return [true, isLoadingMessage];
    if (statusData_loadData.errorMessage) return [true, isLoadingMessage];
    if (statusData_restart.errorMessage) return [true, isLoadingMessage];

    if (showSessionSaving && session.isSaving) return [true, session.message];

    return [false, undefined];
  })();

  // console.log(
  //   "dddd statusData_restart",
  //   statusData_restart
  // );

  // console.log("dddd", {
  //   _isLoading,
  //   _isLoadingMessage,
  //   statusData_loadDataisInitiating: statusData_loadData.isInitiating,
  //   statusData_restartisInitiating: statusData_restart.isInitiating,
  //   statusData_loadData: statusData_loadData,
  //   statusData_restart: statusData_restart,
  // });

  //****************************************
  // SCROLLTOTOP
  //****************************************
  // SCROLL TO TOP if the LOADING BANNER appears or disappears
  // NOTE: We're using the option "watchValue" argument -- we need 2xoccurences of useScrollToTop(), one of which will always be true
  useScrollToTop(_isLoading);
  useScrollToTop(!_isLoading);

  //****************************************
  // RETURN STATEMENTS
  //****************************************
  if (redirectObject) return redirectObject;

  // if (statusData_start?.errorMessage)
  //   return (
  //     <Wrapper {...baseProps}>
  //       <Error>{statusData_start?.errorMessage}</Error>
  //     </Wrapper>
  //   );

  // if (statusData_restart?.errorMessage)
  //   return (
  //     <Wrapper {...baseProps}>
  //       <Error>{statusData_restart?.errorMessage}</Error>
  //     </Wrapper>
  //   );

  // console.log("dadadadad", { _isLoadingMessage})
  if (isCompleting)
    return (
      <Wrapper
        {...baseProps}
        headingText={undefined} // Override any heading
        descriptionContent={undefined}
      >
        {/* <PanelMta className="py-3"> */}
        <PleaseWait>{isCompletingMessage}</PleaseWait>
        {/* </PanelMta> */}
      </Wrapper>
    );

  if (_isLoading)
    return (
      <Wrapper
        {...baseProps}
        headingText={undefined} // Override any heading
        descriptionContent={undefined}
      >
        {/* <PanelMta className="py-3"> */}
        <SessionRefreshing>{_isLoadingMessage}</SessionRefreshing>
        {/* </PanelMta> */}
      </Wrapper>
    );

  // if (!sessionSaveCheck) return <Wrapper {...baseProps}>{children}</Wrapper>;

  return <Wrapper {...baseProps}>{children}</Wrapper>;
};

export default MtaBase;
