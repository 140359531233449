import dayjs from "dayjs";
import functions from "../../../general";
import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

const { dateComparison } = functions;
// personLogic
export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper").default,
  } = args;
  //  console.log("dddd", functionArgs)
  const dh = new dataHelper({
    mappings: [
      "IsResidentSinceBirth",
      "ResidentSince",
      "DateOfBirth",
      "PolicyInceptionDate",
      "LicenceDate",
      "LicenceType",
    ],
    dataSet,
    functionArgs,
  });

  //  console.log("ddddd dh", dh, functionArgs);

  // Licence Consts
  const {
    constLicenceTypeFull = "Full Licence",
    constLicenceTypeProvisional = "Provisional Licence",
    msgDateOfBirth = "You must be 17 on or before the policy start date.",
  } = functionArgs;

  // console.log("xxx functionArgs", functionArgs);
  // *******************
  // ** UPDATES
  // *******************
  // if (dh.isPostRecall()) {

  // 	if(dh.getValueDate("ResidentSince") === dh.getValueDate("DateOfBirth")){
  // 		dh.setValue("IsResidentSinceBirth", !dh.getValue("ResidentSince"));
  // 	}

  // 	dh.setValue("IsResidentSinceBirth", !dh.getValue("ResidentSince"));
  // } else {
  //**  copy the DateOfBirth into ResidentSince if the question is not false (i.e. allow undefined)
  if (
    dh.getValue("IsResidentSinceBirth") === true &&
    dh.isUpdated("IsResidentSinceBirth", "DateOfBirth")
  )
    dh.setValue("ResidentSince", dh.getValue("DateOfBirth"));

  if (
    dh.getValue("IsResidentSinceBirth") !== true &&
    dh.isUpdated("IsResidentSinceBirth")
  ) {
    // console.log("ddddd ResidentSince reset")
    dh.setValue("ResidentSince", undefined);
  }

  // *******************
  // ** VISIBILITY
  // *******************
  // ** Show or hide the ResidencySince question
  dh.setVisible("ResidentSince", dh.getValue("IsResidentSinceBirth") === false);

  // if (!dh.isPostRegistration()) {
  // 	dh.addError("PolicyInceptionDate", "test1");
  // 	dh.addError("PolicyInceptionDate", "test2");
  // }

  // *******************
  // ** ERROR MESSAGES
  // *******************
  // ** Check to ensure the licence date is on or after the person's 17th birthday
  {
    const PolicyStartDate = dh.getValueDate("PolicyInceptionDate");
    const DateOfBirth = dh.getValueDate("DateOfBirth");

    if (
      PolicyStartDate &&
      DateOfBirth &&
      dayjs(PolicyStartDate).diff(DateOfBirth, "year") <= 16
    ) {
      // dh.addError(
      // 	"PolicyInceptionDate",
      // 	"" //"All drivers must be 17 or older when the policy starts"
      // );
      dh.addError(
        "DateOfBirth",
        msgDateOfBirth
        // `Must be 17 or older when the policy starts on ${dayjs(
        // 	PolicyStartDate
        // ).format("D MMM YYYY")}`
      );
    }
  }

  if (
    dh.getValue("IsResidentSinceBirth") === false &&
    dh.getValue("DateOfBirth") !== undefined &&
    dh.getValue("ResidentSince") !== undefined
  ) {
    if (dh.getValueDate("DateOfBirth") > dh.getValueDate("ResidentSince")) {
      const errorMessage = "";
      dh.addError("ResidentSince", errorMessage);
      // dh.addError("DateOfBirth", errorMessage);
    }
  }

  // console.log("dddddd ", dh.getValue("IsResidentSinceBirth"), dh);

  if (
    dh.getValue("IsResidentSinceBirth") !== true &&
    dh.getValueDate("ResidentSince") > dh.getValueDate("PolicyInceptionDate")
  ) {
    const errorMessage = "";
    // "The resident date needs to be before the start of your policy";
    dh.addError("PolicyInceptionDate", errorMessage);
    dh.addError("ResidentSince", errorMessage);
  }

  if (
    dh.getValueDate("LicenceDate") !== undefined &&
    dh.getValueDate("DateOfBirth") !== undefined &&
    dh.getValue("LicenceType") !== undefined
  ) {
    const _LicenceType = dh.getValue("LicenceType");
    const _DateOfBirth = dayjs(dh.getValueDate("DateOfBirth"));
    const _LicenceDate = dayjs(dh.getValueDate("LicenceDate"));
    const _ageYears = _LicenceDate.diff(_DateOfBirth, "year", true);
    const _ageMonths = _LicenceDate.diff(_DateOfBirth, "month", true);

    const generateMsg = (age) =>
      `The licence date does not correspond with the date of birth. A driver must be at least ${age} to obtain a licence. Please check the dates and update where appropriate.`;

    if (_LicenceType !== constLicenceTypeProvisional && _ageYears < 17) {
      const msg = generateMsg("17");
      dh.addError("LicenceDate", msg);
    }

    if (
      _LicenceType === constLicenceTypeProvisional &&
      _ageMonths < 189 // 15 years and 9 months = 189 months
    ) {
      const msg = generateMsg("15 years and 9 months old");
      dh.addError("LicenceDate", msg);
    }
  }

  dh.commit();
};
