import React from "react";
import PriceDisplay from "./Components/PriceDisplay";

const PriceDisplayMain = (props) => {
  const {
    value,
    children,
    decimalPlaces = 2,
    classNamePound,
    classNamePence,
  } = props;

  if (!value && !children) return null;

  const _value = value || children;

  if (isNaN(_value)) return null;

  const displayValue = _value
    .toLocaleString("en-GB", {
      style: "decimal",
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces,
    })
    .split(".");

  const _valuePound = displayValue[0];
  const _valuePence = decimalPlaces >= 1 ? displayValue[1] : "00";

  const _classNamePound = ["pound", classNamePound].toClassName();
  const _classNamePence = ["pence", classNamePence].toClassName();

  return (
    <>
      <span className={_classNamePound}>£{_valuePound}</span>
      <span className={_classNamePence}>.{_valuePence}</span>
    </>
  );
  // return <span>{`£${displayValue}`}</span>;
};

const InstalmentDetailsText = (props) => {
  const { instalmentDetails, className } = props;

  if (instalmentDetails.HasVariedPayment) {
    if (instalmentDetails.VariedRepaymentStatus === "Next") {
      return (
        <span className="d-inline-block varied-payment" data-cy="deposit-varied-next">
          <span className="d-inline-block">
            <span className="space">&nbsp;</span>followed by <strong>1</strong> payment of{" "}
            <PriceDisplay value={instalmentDetails.VariedPaymentAmount} />
            <span className="space">&nbsp;</span>
          </span>
          <span className="d-inline-block">
            and <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong>{" "}
            payments of{" "}
            <PriceDisplay value={instalmentDetails.InstalmentAmount} />
          </span>
        </span>
      );
    }

    if (instalmentDetails.VariedRepaymentStatus === "Last") {
      return (
        <span className="d-inline-block varied-payment" data-cy="deposit-varied-last">
          <span className="d-inline-block">
            <span className="space">&nbsp;</span>followed by{" "}
            <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong>{" "}
            payments of{" "}
            <PriceDisplay value={instalmentDetails.InstalmentAmount} />
            <span className="space">&nbsp;</span>
          </span>
          <span className="d-inline-block">
            and a final payment of{" "}
            <PriceDisplay value={instalmentDetails.VariedPaymentAmount} />
          </span>
        </span>
      );
    }
  }

  return (
    <>
      <span className={className} data-cy="deposit-varied-none">
        &nbsp;followed by{" "}
        <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong> payments
        of{" "}
        <PriceDisplay value={instalmentDetails.InstalmentAmount} />
      </span>
    </>
  );
};

const InstalmentDetailsTextNoDeposit = (props) => {
  const { instalmentDetails, className } = props;

  if (instalmentDetails.HasVariedPayment) {
    if (instalmentDetails.VariedRepaymentStatus === "Next") {
      return (
        <span className="d-inline-block varied-payment" data-cy="no-deposit-varied-next">
          <span className="d-inline-block">
            Initial payment of{" "}
            <PriceDisplay value={instalmentDetails.VariedPaymentAmount} />
            <span className="space">&nbsp;</span>
          </span>
          <span className="d-inline-block">
            followed by{" "}
            <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong>{" "}
            payments of{" "}
            <PriceDisplay value={instalmentDetails.InstalmentAmount} />
          </span>
        </span>
      );
    }

    if (instalmentDetails.VariedRepaymentStatus === "Last") {
      return (
        <span className="d-inline-block varied-payment" data-cy="no-deposit-varied-last">
          <span className="d-inline-block">
            <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong>{" "}
            payments of{" "}
            <PriceDisplay value={instalmentDetails.InstalmentAmount} />
            <span className="space">&nbsp;</span>
          </span>
          <span className="d-inline-block">
            followed by a final payment of{" "}
            <PriceDisplay value={instalmentDetails.VariedPaymentAmount} />
          </span>
        </span>
      );
    }
  }

  return (
    <>
      <span className={className} data-cy="no-deposit-varied-none">
        <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong> payments
        of{" "}
        <PriceDisplay value={instalmentDetails.InstalmentAmount} />
      </span>
    </>
  );
};

const getPriceDetails = (args = {}) => {
  const {
    PayInFullPremium,
    instalmentDetails,
    isInstalments,
    postFixMonth = <>/m</>,
    postFixYear = <>/yr</>,
  } = args;

  //# 2769 changed
  if (isInstalments === undefined) {
    console.log("getPriceDetails", { args });
    throw `Error in getPriceDetails -- missing isInstalments`;
  }

  if (isInstalments && instalmentDetails === undefined) {
    console.log("getPriceDetails", { args });
    throw `Error in getPriceDetails -- isInstalments is TRUE but instalmentDetails is undefined`;
  }

  let mainPriceText;
  let depositPriceText;
  let premiumSavingValue;

  if (isInstalments) {
    mainPriceText = ({ classNamePound, classNamePence, classNamePostFix = "postfix" }) => {
      return (
        <div className="price">
          <PriceDisplayMain
            value={instalmentDetails.InstalmentAmount}
            classNamePound={classNamePound}
            classNamePence={classNamePence}
          />
          <span className={classNamePostFix}>{postFixMonth}</span>
        </div>
      );
    };

    const isZeroDeposit = !instalmentDetails.DepositAmount > 0;

    depositPriceText = () => {
      if (isZeroDeposit) {
        return [
          <>
            <InstalmentDetailsTextNoDeposit
              instalmentDetails={instalmentDetails}
            />{" "}
          </>,
          <>
            Total amount payable:{" "}
            <PriceDisplay value={instalmentDetails.TotalAmountToPay} />
          </>,
        ];
      }

      return [
        <>
          Deposit of{" "}
          <PriceDisplay value={instalmentDetails.DepositAmount} />
          <InstalmentDetailsText instalmentDetails={instalmentDetails} />{" "}
        </>,
        <>
          Total amount payable:{" "}
          <PriceDisplay value={instalmentDetails.TotalAmountToPay} />
        </>,
      ];
    };
  }

  if (!isInstalments) {
    if (instalmentDetails)
      premiumSavingValue = instalmentDetails.TotalCostOfCredit;

    mainPriceText = ({ classNamePound, classNamePence, classNamePostFix = "postfix" }) => {
      return (
        <div className="price">
          <PriceDisplayMain
            value={PayInFullPremium}
            classNamePound={classNamePound}
            classNamePence={classNamePence}
          />
          <span className={classNamePostFix}>{postFixYear}</span>
        </div>
      );
    };
  }

  return {
    mainPriceText,
    depositPriceText,
    isInstalments,
    premiumSavingValue,
  };
};

export default getPriceDetails;
