import React from "react";
import Base from "./Base";
// import { useScrollToTop } from "moveToLibrary/hooks/useScrollTo";

const PageLayout = (props) => {
  // useScrollToTop();
  return <Base {...props} />;
};

export default PageLayout;
