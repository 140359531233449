import React, { useState, useEffect, useRef } from "react";

import composeWebService from "@library/common/components/base/functions/composeWebService";
import { tidyPostcode } from "@library/common/helpers/misc/postcode";

import LibraryTextBox from "@library/common/components/base/form/TextBox";
import LibraryDatePickerTextBox from "@library/common/components/base/form/DatePicker/Textbox";
import LibraryDropDownType from "@library/common/components/base/form/DropDown/Type";
import LibraryRadioButtons from "@library/common/components/base/form/RadioButtons";

import { generateCypressProps } from "siteFunctions/cypress";
import AnimatedPulse from "./custom/Animated/Pulse";
import AnimatedOval from "./custom/Animated/Oval";

// export const FormGroup = (props) => {
//   const newProps = {
//     ...props,
//     className: ["fb-form-group", props.className].toClassName(),
//   };

//   return <div {...newProps} />;
// };

export const TextBox = (props) => {
  const {
    className,
    showStatus = false,
    showValid = false,
    showErrors = false,
    ...otherProps
  } = props;

  const _className = ["form-control", className].toClassName();
  const classNameOkay = [
    "input-group-status",
    "input-group-text",
    "input-group-joined-end",
    showValid ? "bi bi-check-circle icon" : "",
    showErrors ? "bi bi-exclamation-circle" : "",
  ].toClassName();

  if (!showStatus)
    return (
      <LibraryTextBox
        autoComplete="off"
        className={_className}
        {...generateCypressProps("textbox", props)}
        {...otherProps}
      />
    );

  return (
    <div className="input-group">
      <LibraryTextBox
        autoComplete="off"
        className={_className}
        {...generateCypressProps("textbox", props)}
        {...otherProps}
      />
      <div className={classNameOkay} />
    </div>
  );
};

export const TextBoxPostcode = (props) => {
  const { onChange, ...otherProps } = props;
  const _onChange = (v) => {
    const _v = tidyPostcode(v);
    onChange(_v);
  };
  return <TextBox {...otherProps} onChange={_onChange} />;
};
export const TextBoxForm = (props) => {
  const { onChange, onBlur, showStatus = false, ...otherProps } = props;

  return (
    <TextBox
      {...otherProps}
      onType={onChange}
      onChange={onBlur}
      showStatus={showStatus}
    />
  );
  // const { value = "", ...otherProps } = props;

  // const newProps = {
  //   ...otherProps,
  //   value,
  //   autoComplete: "off",
  //   className: (["form-control", props.className]).toClassName(),
  //   ...Object.fromEntries(
  //     ["onChange", "onBlur"]
  //       .filter((k) => k in props)
  //       .map((k) => [
  //         k,
  //         (e) => {
  //           // console.log(props, k, e.target.value);
  //           props[k](e.target.value);
  //         },
  //       ])
  //   ),
  // };
  // // console.log("ddd", newProps);
  // return <input {...newProps} />;
};

export const PasswordTextBoxForm = (props) => {
  const {
    "data-cy": dataCy,
    showStatus = false,
    showValid = false,
    showErrors = false,
    ...otherProps
  } = props;

  const [isVisible, setIsVisible] = useState(false);

  const _type = isVisible ? "text" : "password";

  const _classNameToggle = [
    isVisible ? "bi-eye" : "bi-eye-slash",
    "btn",
    "btn-fb",
  ].toClassName();

  return (
    <div className="input-group">
      <TextBoxForm
        {...otherProps}
        showStatus={false}
        type={_type}
        {...generateCypressProps("textbox-password", props)}
      />
      <button
        type="button"
        className={_classNameToggle}
        onClick={() => setIsVisible(!isVisible)}
      />
    </div>
  );
};

const dropdownStyle = {
  container: (base) => ({
    ...base,
    paddingLeft: 0,
    paddingRight: 0,
  }),
  groupHeading: (base) => ({
    ...base,
    color: "green",
    fontWeight: "bold",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 20, // make sure it's on top of everything else
  }),
};

export const DropDownType = (props) => {
  const { className = "", ...otherProps } = props;

  const _className = [className, "fb-select", "align-middle"].toClassName();

  // console.log("DropDown", props);

  const newProps = {
    className: _className,
    classNamePrefix: "dropdown-type",
    styles: dropdownStyle,
    isClearable: true,
    ...otherProps,
    // onChange: (v) => {
    //   console.log("DropDown", "onChange", v);
    //   if (otherProps.onChange) otherProps.onChange(v);
    // },
    // onBlur: (v) => {
    //   console.log("DropDown", "onBlur", v.target.value);
    //   if (otherProps.onBlur) otherProps.onBlur(v);
    // },
  };
  // console.log("dsadsad DropDownType", newProps);
  return (
    <LibraryDropDownType
      {...newProps}
      {...generateCypressProps("dropdown", props)}
    />
  );
};

export const DropDownTypeWebService = (props) => {
  const { DataFunction, ...otherProps } = props;
  return <DropDownType {...otherProps} DataFunction={DataFunction} />;
};

export const DropDownForm = (props) => {
  const {
    itemData,
    className,
    onChange = () => {},
    onBlur = () => {},
    ...otherProps
  } = props;
  if (!itemData) return null;

  // const _className = (["input-group", "xxxxx-control", className]).toClassName();

  const handleOnChange = (e) => {
    const value = e.target.value;
    onChange(value);
  };

  const handleOnBlur = () => {
    onBlur();
  };

  return (
    <div className={[className].toClassName()}>
      <select
        className="form-control form-select"
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        {...otherProps}
      >
        {itemData.map((x) => (
          <option value={x.value} key={x.label}>
            {x.label}
          </option>
        ))}
      </select>
    </div>
  );

  // const curValue = useRef(props.value);

  // const newProps = {
  //   ...props,
  //   onChange: (v) => {
  //     curValue.current = v;
  //     props.onChange(v);
  //   }, // leave as is
  //   // the LibraryDropDown doesn't supply the value for onBlur, so let's handle it here...
  //   onBlur: (e) => {
  //     return curValue.current;
  //     // return props.value; //Because this is controlled, return the controlled value
  //   },
  // };

  // return <DropDown {...newProps} />;
};

export const RadioButtons = (props) => {
  const {
    classNameOuter,
    classNameItem,
    LabelWrapper = undefined,
    itemData,
    forceYesNo = false,
    ...otherProps
  } = props;

  const isYesNo =
    itemData &&
    ((itemData[0].label == "Yes" && itemData[1].label == "No") || forceYesNo);

  // outer DIV className
  const _className = [
    "lctr-radiobuttons-container",
    "fsg-btn-group",
    isYesNo ? "yes-no" : "",
    classNameOuter,
  ].toClassName();

  const _itemData = (function () {
    if (LabelWrapper) {
      return itemData.map((x) => {
        const newLabel = x.label
          .split(" ")
          .map((x) => <LabelWrapper>{x}</LabelWrapper>);
        return { ...x, label: newLabel };
      });
    }
    return itemData;
  })();

  // individual item classes
  const _itemClassName = ["btn", "fb-radio-btn", classNameItem].toClassName();

  const _itemClassNameSelected = ["btn-outline-fb"].toClassName();
  const _itemClassNameUnselected = ["btn-outline-fb"].toClassName();
  return (
    <div
      className={_className}
      {...generateCypressProps("radio-buttons", props)}
    >
      <LibraryRadioButtons
        itemClassName={_itemClassName} // the label
        itemClassNameSelected={_itemClassNameSelected}
        itemClassNameUnselected={_itemClassNameUnselected}
        itemData={_itemData}
        {...otherProps}
      />
    </div>
  );
};

export const RadioButtonsWebService = composeWebService({
  DataComponent: RadioButtons,
  dataKey: "itemData",
});

export const Loading = (props) => {
  return (
    <AnimatedPulse {...props} {...generateCypressProps("loading", props)} />
  );
};

export const LoadingRow = (props) => {
  const { children, className, classNameChildren, classNameAnimation } = props;

  return (
    <div className={["row", className].toClassName()}>
      {children && (
        <div className={["col-12", classNameChildren].toClassName()}>
          {children}
        </div>
      )}

      <div className={["col-12", classNameAnimation].toClassName()}>
        <Loading />
      </div>
    </div>
  );
};

export const LoadingPanel = (props) => {
  const { children } = props;
  return (
    <div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Loading />
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">{children}</div>
      </div>
    </div>
  );
};

export const LoadingCard = (props) => {
  const { msg, className, msgClassName, loadingClassName } = props;

  const _className = ["card", className].toClassName();
  const _msgClassName = ["card-body", msgClassName].toClassName();
  const _loadingClassName = ["card-body", loadingClassName].toClassName();

  return (
    <div className={_className}>
      {msg && <div className={_msgClassName}>{msg}</div>}
      <div className={_loadingClassName}>
        <Loading />
      </div>
    </div>
  );
};

export const TelephoneNumber = (props) => {
  const {
    phonenumber,
    altLabel,
    className = "",
    isLink = false,
    isStrong = true,
  } = props;

  const _className = [
    "whitespace-no-wrap",
    "link-black",
    className,
  ].toClassName();

  if (!isLink) {
    const noLinkClassName = ["tel-no-link", _className].toClassName();

    if (isStrong) {
      return (
        <strong
          className={noLinkClassName}
          {...generateCypressProps("telephone-number", props)}
        >
          {phonenumber}
        </strong>
      );
    }

    return (
      <span
        className={noLinkClassName}
        {...generateCypressProps("telephone-number", props)}
      >
        {phonenumber}
      </span>
    );
  }

  const displayText = altLabel != undefined ? altLabel : phonenumber;
  const display = isStrong ? (
    <strong>{displayText}</strong>
  ) : (
    <span>{displayText}</span>
  );

  const linkNumber = phonenumber.replaceAll(" ", "");
  return (
    <a
      className={["tel-link", _className].toClassName()}
      href={`tel:${linkNumber}`}
      {...generateCypressProps("telephone-number", props)}
    >
      {display}
    </a>
  );
};

export const TextArea = (props) => {
  const {
    value,
    onChange,
    rows = "10",
    cols = "40",
    className,
    ...otherProps
  } = props;

  const _className = ["form-control", className].toClassName();
  const _value = value === undefined ? "" : value; // Changed "undefined" to "", else <textarea /> doesn't recognise the change

  return (
    <textarea
      value={_value}
      onChange={(e) => {
        e.preventDefault();
        onChange(e.target.value);
      }}
      rows={rows}
      cols={cols}
      className={_className}
      {...generateCypressProps("text-area", props)}
      {...otherProps}
    />
  );
};

export const VehicleReg = (props) => {
  const { children } = props;
  return (
    <span
      className="vehicle-registration"
      {...generateCypressProps("vehicle-registration", props)}
    >
      {children}
    </span>
  );
};

// export const Card = (props) => {
//   const {
//     hasBorder = false,
//     className,
//     classNameHeader,
//     classNameBody,
//     classNameFooter,
//     header,
//     children,
//     footer,
//   } = props;

//   const classData = {
//     container: ["lctr-card", "card-fb", "card", hasBorder ? "" : "no-border", className].toClassName(),
//     header: ["lctr-card-header", "card-header", classNameHeader].toClassName(),
//     body: ["lctr-card-body", "card-body", classNameBody].toClassName(),
//     footer: ["lctr-card-footer", "card-footer", classNameFooter].toClassName(),
//   };

//   return (
//     <div
//       className={classData.container}
//       {...generateCypressProps("card", props)}
//     >
//       {header && <div className={classData.header}>{header}</div>}
//       {children && <div className={classData.body}>{children}</div>}
//       {footer && <div className={classData.footer}>{footer}</div>}
//     </div>
//   );
// };

// export const CardBorder = (props) => {
//   const {
//     className,
//     classNameHeader,
//     classNameBody,
//     classNameFooter,
//     ...otherProps
//   } = props;

//   const _className = ["card-border", className].toClassName();
//   const _classNameHeader = [classNameHeader].toClassName();
//   const _classNameBody = [classNameBody].toClassName();
//   const _classNameFooter = [classNameFooter].toClassName();

//   return (
//     <Card
//       hasBorder={true}
//       className={_className}
//       classNameHeader={_classNameHeader}
//       classNameBody={_classNameBody}
//       classNameFooter={_classNameFooter}
//       {...otherProps}
//     />
//   );
// };

export const ErrorText = (props) => {
  const { children } = props;

  if (!children) return null;

  return (
    <span
      className="text-danger"
      {...generateCypressProps("error-text", props)}
    >
      {children}
    </span>
  );
};

export const InfoText = (props) => {
  const { children } = props;

  if (!children) return null;

  return (
    <span
      className="fb-text-info"
      {...generateCypressProps("info-text", props)}
    >
      {children}
    </span>
  );
};

export const HeadingLarge = ({ children }) => <p className="h3">{children}</p>;
export const Heading = ({ children }) => <p className="h5">{children}</p>;

export const Help = (props) => {
  const { value, children, className = "", id } = props;

  if (!value && !children) return null;

  const _className = [
    "form-text",
    "text-purple",
    "help-text",
    className,
  ].toClassName();

  return (
    <div
      id={id}
      className={_className}
      {...generateCypressProps("help", props)}
    >
      {value || children}
    </div>
  );
};

export const HelpFurther = (props) => {
  const { value, children, className, id } = props;
  const [isDisplayed, setIsDisplayed] = useState(false);

  if (!value && !children) return null;

  const _className = [
    "help-purple",
    "help-furtherinfo",
    className,
  ].toClassName();

  if (!isDisplayed)
    return (
      <div
        className={_className}
        {...generateCypressProps("help-further", props)}
      >
        <button
          className="btn btn-link btn-sm text-teal fw-bold ps-0"
          type="button"
          onClick={() => setIsDisplayed(true)}
        >
          More info
        </button>
      </div>
    );

  return (
    <Help id={id} className={_className}>
      {value || children}
    </Help>
  );
};

export const TelephoneService = () => {
  return (
    <TelephoneNumber
      phonenumber={require("config").default.lookups.telephoneNumbers.service}
      isLink={true}
    />
  );
  //TelephoneNumber
  return (
    <a
      href={`tel:${require("config").default.lookups.telephoneNumbers.service}`}
    >
      {require("config").default.lookups.telephoneNumbers.service}
    </a>
  );
};

// export const Error = (props) => {
//   const { children, className, id } = props;

//   const _className = [
//     "lctr-site-error",
//     "form-text",
//     "text-danger",
//     className,
//   ].toClassName();

//   if (!children) return null;
//   return (
//     <div
//       id={id}
//       className={_className}
//       {...generateCypressProps("error", props)}
//     >
//       {children}
//     </div>
//   );
// };

export const RadioButtonsMta = (props) => {
  const {
    classNameOuter,
    classNameItem,
    LabelWrapper = undefined,
    itemData,
    forceYesNo = false,
    ...otherProps
  } = props;

  const isYesNo =
    itemData &&
    ((itemData[0].label == "Yes" && itemData[1].label == "No") || forceYesNo);

  // outer DIV className
  const _className = [
    "lctr-radiobuttons-container",
    "fsg-btn-group",
    isYesNo ? "yes-no" : "",
    classNameOuter,
  ].toClassName();

  const _itemData = (function () {
    if (LabelWrapper) {
      return itemData.map((x) => {
        const newLabel = x.label
          .split(" ")
          .map((x) => <LabelWrapper>{x}</LabelWrapper>);
        return { ...x, label: newLabel };
      });
    }
    return itemData;
  })();

  // individual item classes
  const _itemClassName = ["btn", "fb-radio-btn", classNameItem].toClassName();

  const _itemClassNameSelected = ["btn-outline-mta"].toClassName();
  const _itemClassNameUnselected = ["btn-outline-mta"].toClassName();
  return (
    <div
      className={_className}
      {...generateCypressProps("radio-buttons", props)}
    >
      <LibraryRadioButtons
        itemClassName={_itemClassName} // the label
        itemClassNameSelected={_itemClassNameSelected}
        itemClassNameUnselected={_itemClassNameUnselected}
        itemData={_itemData}
        {...otherProps}
      />
    </div>
  );
};

export const RadioButtonsWebServiceMta = composeWebService({
  DataComponent: RadioButtonsMta,
  dataKey: "itemData",
});

export const RadioButtonsRenewal = (props) => {
  const {
    classNameOuter,
    classNameItem,
    LabelWrapper = undefined,
    itemData,
    forceYesNo = false,
    ...otherProps
  } = props;

  const isYesNo =
    itemData &&
    ((itemData[0].label == "Yes" && itemData[1].label == "No") || forceYesNo);

  // outer DIV className
  const _className = [
    "lctr-radiobuttons-container",
    "fsg-btn-group",
    isYesNo ? "yes-no" : "",
    classNameOuter,
  ].toClassName();

  const _itemData = (function () {
    if (LabelWrapper) {
      return itemData.map((x) => {
        const newLabel = x.label
          .split(" ")
          .map((x) => <LabelWrapper>{x}</LabelWrapper>);
        return { ...x, label: newLabel };
      });
    }
    return itemData;
  })();

  // individual item classes
  const _itemClassName = ["btn", "fb-radio-btn", classNameItem].toClassName();

  const _itemClassNameSelected = ["btn-outline-mta"].toClassName();
  const _itemClassNameUnselected = ["btn-outline-mta"].toClassName();
  return (
    <div
      className={_className}
      {...generateCypressProps("radio-buttons", props)}
    >
      <LibraryRadioButtons
        itemClassName={_itemClassName} // the label
        itemClassNameSelected={_itemClassNameSelected}
        itemClassNameUnselected={_itemClassNameUnselected}
        itemData={_itemData}
        {...otherProps}
      />
    </div>
  );
};

export const RadioButtonsWebServiceRenewal = composeWebService({
  DataComponent: RadioButtonsRenewal,
  dataKey: "itemData",
});
