import _ from "lodash";

const generate = (args = {}) => {
  const { storeName, dashboardActions, selectorsExternal } = args;

  const getSubState = (state) => state[storeName];
  // const getCurrentPolicyNumber = (state) => {
  //   const { currentPolicy } = getSubState(state);
  //   return currentPolicy;
  // };

  // const getCurrentPolicy = (state) => {
  //   const policyList = selectorsExternal.dashboard.getPolicyListList(state);
  //   const currentPolicy = getCurrentPolicyNumber(state);

  //   if (!currentPolicy) return;
  //   if (!policyList) return;
  //   if (policyList.length === 0) return;

  //   return policyList.find((x) => x.PolicyNumber === currentPolicy);
  // };

  // const getVehicleDetails = (state) => {
  //   const policyData = getCurrentPolicy(state);
  //   if (!policyData) return;
  //   return { PolicyType: policyData.PolicyType, ...policyData.VehicleData };
  // };

  const getUserInfo = (state) => {
    // if (!getUserToken(state)) return undefined;
    const userInfo = state[storeName].userInfo;

    return userInfo;
  };

  const getPermissions = (state) => {
    const userInfo = getUserInfo(state);
    if (!userInfo) return {};
    return userInfo.Features || {};
  };

  const getUserInfoRefreshing = (state) => {
    return state[storeName].userInfoRefreshing;
  };

  const getNotifications = (state) => {
    const userInfo = getUserInfo(state);
    if (!userInfo) return;
    return userInfo.Notifications;
  };

  const isNotificationsUnread = (state) => {
    const notifications = getNotifications(state);
    if (!notifications) return false;
    return notifications.find((x) => x.Type === "UNREAD") ? true : false;
  };

  // const getNotificationsStatus = (state) => {
  //   const subState = getSubState(state);

  //   return {
  //     isLoading: subState.notificationsStatus === "LOADING",
  //     isOkay: subState.notificationsStatus === "OKAY",
  //     isError: subState.notificationsStatus === "ERROR",
  //     isEmpty: (function () {
  //       const _notifications = getNotifications(state);
  //       if (!_notifications) return true;
  //       if (_notifications.length === 0) return true;
  //       return false;
  //     })(),
  //   };
  // };

  const getUserId = (state) => {
    const userInfo = getUserInfo(state);
    if (!userInfo) return;

    return userInfo.UserId;
  };

  const getPolicyList = (state) => {
    const userInfo = getUserInfo(state);

    if (!userInfo) return;
    if (!("Policies" in userInfo)) return;

    return userInfo.Policies.map((_policy) => {
      // Ticket #2680 -- append the statuses
      const DataStatus = {
        isActive: _policy.IsActive ? true : false,
        isPending: _policy.DisplayStatus === "Pending",
        isCancelled: _policy.DisplayStatus === "Cancelled",
        isExpired: _policy.DisplayStatus === "Expired",
        statusDisplay: _policy.DisplayStatus,
      };

      const DataMta = (function () {
        // #2847 MTA FLAG TWEAKS
        //https://dev.azure.com/FreedomServicesGroup/Odyssey/_wiki/wikis/Odyssey.wiki/470/Feature-Switches-Allowed-Actions

        return {
          online: (function () {
            // Can perform MTA ONLINE
            if (!userInfo?.Features.AllowMTA) return false;
            if (!_policy?.AllowedActions) return false;
            if (!_policy?.AllowedActions.MTA) return false;
            return _policy?.AllowedActions.MTATakeup ? true : false;
          })(),
          offline: (function () {
            // Can perform MTA OFFLINE
            if (!userInfo?.Features.AllowMTA) return false;
            if (!_policy?.AllowedActions) return false;
            if (!_policy?.AllowedActions.MTA) return false;
            return true;
          })(),
          nonRating: (function () {
            // Can perform non-rating MTA
            if (!userInfo?.Features.AllowNonRatingMTA) return false;
            if (!_policy?.AllowedActions) return false;
            return _policy?.AllowedActions.NonRatingMTA ? true : false;
          })(),
          renewalOptOut: (function () {
            return userInfo?.Features.AllowRenewalOptOut ? true : false;
          })(),
        };
      })();

      const DataRenewal = (function () {
        //https://dev.azure.com/FreedomServicesGroup/Odyssey/_wiki/wikis/Odyssey.wiki/470/Feature-Switches-Allowed-Actions

        return {
          offered: (function () {
            // Can perform Renewal ONLINE
            if (!userInfo?.Features.AllowRenewal) return false;
            return _policy?.RenewalInfo?.IsOffered ? true : false;
          })(),
          notOffered: (function () {
            // Can perform Renewal ONLINE
            if (!userInfo?.Features.AllowRenewal) return false;
            if (!_policy.RenewalInfo) return false;
            return _policy?.RenewalInfo?.IsOffered ? false : true;
          })(),
        };
      })();

      return { ..._policy, Status: DataStatus, Mta: DataMta, Renewal: DataRenewal };
    });
  };

  const getPolicy = (state, policyNumber) => {
    if (!policyNumber) return;
    const policyList = getPolicyList(state);
    if (!policyList) return;
    return policyList.find((x) => x.PolicyNumber === policyNumber);
  };

  const getPolicyCurrent = (state, policyNumber) => {
    const policy = getPolicy(state, policyNumber);
    if (!policy) return;
    return policy.EffectiveEvent;
  };

  const getPolicyPending = (state, policyNumber) => {
    return [
      {
        Id: "xxxxx",
        EventType: "xxxx",
        DisplayName: "Test MTA in the selector",
        Status: "STATUS",
        ReasonCode: "ReasonCode",
        ReasonDescription: "ReasonDescription",
        QuoteDate: "2023-04-12T14:00:35.828+00:00",
        StartDate: "2023-08-12T14:00:35.828+00:00",
        EndDate: "2023-08-31T14:00:35.828+00:00",
        RenewalDate: "2023-08-131T14:00:35.828+00:00",
        ProposerName: "Mr Gurjit Lee",
        InsuredNames: ["Mr Gurjit Lee"],
        Postcode: "CF82 8AA",
        VehicleData: {
          RegNumber: "<string>",
          Make: "PEUGEOT",
          Model: "308 ACTIVE THP 125",
          Doors: 5,
          Fuel: "xxxx",
          Transmission: "xxxx",
          EngineSize: "1598",
        },
        AllowedActions: {
          MTA: true,
          NonRatingMTA: true,
        },
      },
    ];

    const policy = getPolicy(state, policyNumber);
    if (!policy) return;
    return policy.FutureEvents;
  };

  const getValue = (state, key) => {
    const subState = state[storeName] || {};
    // console.log("dsadsad", { state, subState });
    if (!("dataValues" in subState)) return undefined;
    return subState.dataValues[key];
  };

  return {
    getNotifications,
    isNotificationsUnread,
    getPermissions,
    // getNotificationsStatus,
    getUserInfo,
    getUserInfoRefreshing,
    getUserId,
    getPolicyList,
    getPolicy,
    getValue,
    // getPolicyCurrent,
    // getPolicyPending,
    // getCurrentPolicy,
    // getCurrentPolicyNumber,
    // getVehicleDetails,
  };
};

export default generate;
