import React from "react";
import Base from "../Base";

import { NavLink } from "react-router-dom";
import { ButtonStyled } from "siteComponents";

// There is no
const TimeoutView = (props) => {
  const { routeStart, clearTimeout = () => {} } = props;

  return (
    <Base heading="Your session has expired" icon="icon-time-limit">
      <div>
        For your security your session with us has expired due to inactivity.
      </div>
      <div>You can log in again below and pick up where you left off.</div>

      {routeStart && (
        <div>
          <ButtonStyled
            data-cy="clear-timeout"
            onClick={() => {
              clearTimeout();
            }}
          >
            Log in
          </ButtonStyled>
        </div>
      )}
    </Base>
  );
};

export default TimeoutView;
