import React, { useEffect, useState } from "react";
import { useDispatch } from "siteFunctions/hooks";
import { useSelector } from "react-redux";
import {
  useLocation,
  useParams,
  useNavigate,
  useOutletContext,
  useResolvedPath,
} from "react-router-dom";
// import useSalusCompare from "moveToLibrary/hooks/useSalusCompare";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

import _ from "lodash";

import Base from "../Base";
import MtaSavedQuotes from "../../Components/SavedQuotes";
// import ChangeViewer, { useChanges } from "../../Components/ChangeViewer";
import ButtonPanel from "../ButtonPanel";
// import NoQuoteView from "siteViews/Errors/NoQuote";
import PleaseWait from "../PleaseWait";
import PriceDetails from "./PriceDetails";
import ErrorPanel from "./ErrorPanel";
import { IptPanel } from "siteComponents";

import {
  TextBox,
  PanelMta,
  DevContainer,
  JsonViewer,
  ButtonStyled,
  ButtonStyledMta,
  ButtonToggleMta,
  Alert,
  Error,
  DatePickerTextBox,
  Fullscreen,
  Loading,
  LoadingRow,
  Feedback,
  TelephoneService,
  ConfirmModal,
  PanelSticky,
  DisplaySection,
} from "siteComponents";

const fnFormatDate = (v) => {
  if (!v)
    throw `Error in Quote.jsx/fnFormatDate -- trying to format an empty value (see ticket #2900)`;

  return dayjs(v).format("Do MMM YYYY");
};
const fnFormatCurrency = (v) =>
  new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(v);

// ********************************************************************************************
// MAIN COMPONENT
// ********************************************************************************************
const QuoteView = (props) => {
  // **********************************************
  // COMMON DATA
  // **********************************************
  const {
    functions,
    selectors,
    editComponents = [],
    fnGetChangeData,
    feedbackComponents: {
      GetQuote: FeedbackGetQuote,
      PaymentLauncher: FeedbackPaymentLauncher,
    },
    policyData,
  } = useOutletContext();

  // **********************************************
  // VARIABLES
  // **********************************************
  const [isProceeding, setIsProceeding] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isStickyAnimationEnabled, setIsStickyAnimationEnabled] =
    useState(false);

  const [forceFail, setForceFail] = useState(false);

  // **********************************************
  // SELECTORS
  // **********************************************
  const isStoreValid = useSelector(
    selectors.riskStore.getIsStoreValid,
    require("lodash").isEqual
  );
  const salusData = useSelector(
    selectors.riskStore.getSalusData,
    require("lodash").isEqual
  );
  const baseSalusData = useSelector(
    selectors.getBaseSalus,
    require("lodash").isEqual
  );

  const paymentMethod = useSelector(
    selectors.getPaymentMethod,
    require("lodash").isEqual
  );
  const hasQuote = useSelector(selectors.hasQuote, require("lodash").isEqual);

  const curQuote = useSelector(
    selectors.getQuoteData,
    require("lodash").isEqual
  );
  const data_PaymentLauncher = useSelector(
    selectors.getPaymentLauncherData,
    _.isEqual
  );
  const hasGetQuote_PayloadChanged = useSelector(
    selectors.getHasGetQuote_PayloadChanged,
    require("lodash").isEqual
  );

  const status_GetQuote = useSelector(
    selectors.status.getQuote,
    require("lodash").isEqual
  );
  const status_PaymentLauncher = useSelector(
    selectors.status.getPaymentLauncher,
    require("lodash").isEqual,
    require("lodash").isEqual
  );

  const riskValues = useSelector(
    (state) =>
      selectors.riskStore.getValue(state, [
        "Risk/StartDate",
        "Risk/EndDate",
        "Risk/Proposer/Email",
      ]),
    _.isEqual
  );

  // **********************************************
  // CHANGE DATA
  // **********************************************
  const { displayList: changeDisplayList, reasonList: changeReasonList } =
    (function () {
      return fnGetChangeData(salusData, baseSalusData);
    })();

  // console.log("xxxx", { changeReason, salusData, baseSalusData });
  // **********************************************
  // HOOKS
  // **********************************************
  // const fnGetChangeData = useChanges(salusData);

  // **********************************************
  // FUNCTIONS
  // **********************************************

  const fnComplete = async () => {
    setIsProceeding(true);
    await functions.process.paymentLauncher({ forceFail });
    await functions.sessionSave();
  };

  // **********************************************
  // EFFECTS
  // **********************************************
  // console.log("QUOTE.jsx", {
  //   curQuote,
  //   data_PaymentLauncher,
  //   hasQuote,
  //   status_PaymentLauncher,
  //   status_GetQuote,
  // });

  //

  // ** INIT **
  useEffect(() => {
    const myFn = async () => {
      if (!isStoreValid) return;
      // console.log("ddddd", { hasGetQuote_PayloadChanged });
      if (!hasGetQuote_PayloadChanged) return;

      await functions.process.getQuote({ reasonList: changeReasonList }); // Also does a reset of everything else
      await functions.sessionSave();
    };

    myFn();

    // REMOVED: Not sure if needed anymore
    // return () => {
    //   // Clear the paymentLauncherData upon exiting the PAGE (it's only needed on this page)
    //   functions.process.resetPaymentLauncher();
    // };
  }, []);

  // ** PAYMENT METHOD **
  useEffect(() => {
    if (!hasQuote) return;

    if (curQuote.AllowAdjustmentInFull && curQuote.AllowSpreadMonthly) {
      functions.fnUpdatePaymentMethod("M");
      return;
    }

    if (curQuote.AllowSpreadMonthly) {
      functions.fnUpdatePaymentMethod("M");
      return;
    }

    if (curQuote.AllowAdjustmentInFull) {
      functions.fnUpdatePaymentMethod("F");
      return;
    }
  }, [hasQuote]);

  // **paymentLauncher
  // console.log("data_PaymentLauncher", data_PaymentLauncher);

  useEffect(() => {
    //ERROR CHECK: for general errors (e.g. network failure, or if the STATUS !== "SUCCESS"). The logic for this is elsewhere
    if (!status_PaymentLauncher?.isError) return;
    setIsProceeding(false);
  }, [status_PaymentLauncher?.isError]);

  useEffect(() => {
    const myFn = async () => {
      if (!isProceeding) return;
      if (!data_PaymentLauncher) return;

      // NOTE: We don't need to do a SESSIONSAVE as COMMONLISTENERS/RefreshUserInfo does one for us;
      switch (curQuote.MtaType) {
        case "NIL": {
          await functions.wrapup.NIL({
            startDate: riskValues["Risk/StartDate"],
            emailAddress: riskValues["Risk/Proposer/Email"],
            isWrapupComplete: data_PaymentLauncher.WrapupComplete,
          });
          break;
        }
        case "RP": {
          await functions.wrapup.RP({
            startDate: riskValues["Risk/StartDate"],
            emailAddress: riskValues["Risk/Proposer/Email"],
            isWrapupComplete: data_PaymentLauncher.WrapupComplete,
          });
          break;
        }
        case "AP": {
          if (!data_PaymentLauncher.RedirectUrl) {
            throw `Error in MTA/QUOTE.jsx data_PaymentLauncher -- got MtaType of "${curQuote.MtaType}" but missing "RedirectUrl"`;
          }

          await functions.navigation.loadUrl(data_PaymentLauncher.RedirectUrl);

          break;
        }
      }
    };
    myFn();
  }, [isProceeding, data_PaymentLauncher ? true : false]);

  // **********************************************
  // FINALISE
  // **********************************************

  // const allowMtaOnline = true; //curQuote?.

  if (!isStoreValid) return null;

  // return <NoQuoteView />;

  const [isLoading, isLoadingMessage] = (function () {
    if (status_GetQuote?.isError) return [false, undefined];
    if (status_PaymentLauncher?.isError) return [false, undefined];

    if (status_GetQuote.isInitiating)
      return [
        true,
        undefined, //"We're getting your price..."
      ];

    if (isProceeding && curQuote?.MtaType === "AP")
      return [
        true,
        undefined, // "Redirecting to the payment page..."
      ];

    return [false, undefined];
  })();

  const [isCompleting, isCompletingMessage] = (function () {
    if (isProceeding && curQuote?.MtaType !== "AP")
      return [true, "Please wait a moment while we complete your changes."];

    return [false, undefined];
  })();

  const displayContent = (function () {
    if (isLoading) return null;
    if (isProceeding) return null;
    if (!curQuote) return null; // #3219

    const fragmentChanges = (
      <PanelMta
        heading="What you're changing"
        className="mta-changes-summary"
        data-cy="panel-changes-summary"
      >
        {changeDisplayList.length >= 1 && (
          <>
            <div className="intro-text">
              Here's a summary of the changes you've requested.
            </div>
            <div className="mta-change-list">
              {changeDisplayList.map(({ heading, data, key }) => {
                const dataCy = `summary-item-${key}`;
                return (
                  <DisplaySection heading={heading} key={key} dataCy={dataCy}>
                    {data}
                  </DisplaySection>
                );
              })}

              {/* 
              {changeDisplayList.map(({ heading, data, key }) => {
                return (
                  <div className="row mta-change-item-container" key={key}>
                    {heading && (
                      <div className="col-12 heading-secondary">{heading}</div>
                    )}
                    {data.map((subData, i) => (
                      <div className="col-12" key={i}>
                        {subData}
                      </div>
                    ))}
                  </div>
                );
              })}
              */}
            </div>

            {/* The Summary */}

            <div
              className="mta-change-item-container alert alert-secondary info-box my-3 py-2"
              data-cy="mta-date-summary"
            >
              <div className="row">
                <div className="col-12 text-center">
                  Your changes will be effective from{" "}
                  <strong className="d-block d-sm-inline">
                    {fnFormatDate(riskValues["Risk/StartDate"])}
                  </strong>
                </div>
                <div className="col-12 text-center">
                  until your policy ends on{" "}
                  <strong className="d-block d-sm-inline">
                    {fnFormatDate(riskValues["Risk/EndDate"])}
                  </strong>
                </div>
              </div>
            </div>
          </>
        )}
      </PanelMta>
    );

    const generateButtons = (nextLabel, isConfirm = false) => {
      const extraProps = (function () {
        if (nextLabel) {
          if (!isConfirm) return { onNext: fnComplete, nextLabel: nextLabel };

          return {
            onNext: () => setShowConfirmModal(true),
            nextLabel: nextLabel,
          };
        }
        return {};
      })();

      return (
        <ButtonPanel
          onPrev={functions.navigation.change}
          prevLabel="Back"
          {...extraProps}
          className="mb-3"
        />
      );
    };

    // #2807
    switch (curQuote?.QuoteResultType) {
      case "SUCCESS":
      case "SUCCESS-MTA":
        break; // Do nothing

      case "NQ":
      case "RTP":
      case "REFER":
        // Return the ERROR MESSAGE
        return (
          <ErrorPanel
            icon="icon-exclamation-triangle"
            heading="We're sorry!"
            data-cy="panel-mta-error"
          >
            <PanelMta>
              <p>
                We've not been able to offer you a quote today. Please call us
                on <TelephoneService /> and one of our agents will be happy to
                help.
              </p>
              <div>
                <ButtonStyled
                  onClick={functions.navigation.exit}
                  data-cy="error-gohome"
                  className="btn-warning"
                >
                  Return home
                </ButtonStyled>
              </div>
            </PanelMta>
            <div>{generateButtons()}</div>
          </ErrorPanel>
        );

      default:
        // Return the ERROR MESSAGE
        return (
          <ErrorPanel
            icon="icon-exclamation-triangle"
            heading="We're sorry!"
            data-cy="panel-mta-error"
          >
            <PanelMta>
              <p>
                We've not been able to offer you a quote today. Please call us
                on <TelephoneService /> and one of our agents will be happy to
                help.
              </p>
              <p>Please try again later or return home.</p>
              <div>
                <ButtonStyled
                  onClick={functions.navigation.exit}
                  data-cy="error-gohome"
                  className="btn-warning"
                >
                  Return home
                </ButtonStyled>
              </div>
            </PanelMta>
            <div>{generateButtons()}</div>
          </ErrorPanel>
        );
    }

    if (!policyData.Mta.online)
      return (
        <>
          {fragmentChanges}

          <PanelMta data-cy="panel-offline-only">
            Sorry, we're not able to process your change at the moment. If you
            need to update your policy please call us on <TelephoneService />
          </PanelMta>

          <ButtonPanel
            onPrev={functions.navigation.change}
            prevLabel="Back"
            className="mb-3"
          />
        </>
      );

    if (curQuote?.MtaType === "RP")
      return (
        <>
          {fragmentChanges}

          <PanelMta heading="You're due a refund" data-cy="mta-rp-refund">
            {curQuote?.IsPayInFull && (
              <p>
                We'll process your refund using the same payment card you used
                to purchase the policy. Please allow 7-10 days for this to be
                completed.
              </p>
            )}
            {!curQuote?.IsPayInFull && (
              <p>
                Your refund will be deducted from your instalment balance.
                You'll be sent an email within 10 working days to confirm full
                details of your payment dates and amounts.
              </p>
            )}
            <p>
              If you need to contact us about this please call us on{" "}
              <TelephoneService />
            </p>

            <IptPanel resultType={curQuote.MtaType} fee={curQuote.MtaFee} />
          </PanelMta>

          {generateButtons("Confirm these changes", true)}
        </>
      );

    if (curQuote?.MtaType === "NIL")
      return (
        <>
          {fragmentChanges}

          {generateButtons("Confirm these changes", true)}
        </>
      );

    if (curQuote?.MtaType === "AP")
      return (
        <>
          {fragmentChanges}

          <PanelMta heading="How to pay" data-cy="mta-ap-payment">
            <div className="row mb-3">
              {curQuote.AllowSpreadMonthly && (
                <div className="col-12 col-md-6 mb-3">
                  <ButtonToggleMta
                    isActive={paymentMethod === "M"}
                    onClick={() => functions.fnUpdatePaymentMethod("M")}
                    className="w-100"
                  >
                    Add to instalments
                  </ButtonToggleMta>
                </div>
              )}
              <div className="col-12 col-md-6">
                <ButtonToggleMta
                  isActive={paymentMethod === "F"}
                  onClick={() => functions.fnUpdatePaymentMethod("F")}
                  className="w-100"
                >
                  Pay in full
                </ButtonToggleMta>
              </div>
            </div>

            {curQuote.AllowSpreadMonthly && (
              <small className="inst-details">
                If you choose to pay by instalments you'll pay a deposit of{" "}
                <span className="price">
                  {fnFormatCurrency(curQuote.ToPayToday)}
                </span>{" "}
                today and{" "}
                <span className="price">
                  {fnFormatCurrency(curQuote.RemainingBalance)}
                </span>{" "}
                (plus interest) will be spread over your remaining instalments.
                You'll be sent an email within 10 working days to confirm full
                details of your payment dates and amounts.
              </small>
            )}
            {!curQuote.AllowSpreadMonthly && (
              <p>Pay the full amount by credit or debit card today.</p>
            )}

            <IptPanel resultType={curQuote.MtaType} fee={curQuote.MtaFee} />
          </PanelMta>

          {generateButtons("Continue to payment", false)}
        </>
      );

    // Fallback
    return <div>{generateButtons()}</div>;
  })();

  const headingText = (function () {
    if (!curQuote) return null; // #3219
    if (status_GetQuote.isInitiating) return "Your quote";
    if (isLoading) return "";
    if (isProceeding) return "Thank you";

    switch (curQuote?.MtaType) {
      case "AP":
        return "Your premium will increase by";

      case "RP":
        return "Your premium will decrease by";

      case "NIL":
        return "There is no change to your premium";

      default:
        return "";
    }
  })();

  const headingDisplayContent = (function () {
    if (!curQuote) return null; // #3219
    if (isLoading) return undefined;
    if (isProceeding) return undefined;
    if (hasQuote)
      return (
        <PriceDetails
          curQuote={curQuote}
          isStickyAnimationEnabled={isStickyAnimationEnabled}
          selectedPayMethod={paymentMethod}
          allowOnlineWrapup={policyData.Mta.online}
        />
      );

    return undefined;
  })();

  // console.log("Quote.jsx", {
  //   curQuote,
  //   salusData,
  //   baseSalusData,
  //   riskValues,
  //   headingText,
  //   headingDisplayContent,
  // });

  return (
    <Base
      onHeaderStick={() => {
        setIsSticky(true);
        setIsStickyAnimationEnabled(true);
      }}
      onHeaderUnstick={() => {
        setIsSticky(false);
      }}
      usePageLayout={false}
      isSticky={!isLoading} // implemented in PageLayoutBase
      //stickyOffset={40} // Fix for large screen scrolling craziness!
      className="mta-quote"
      // isEnabled={hasQuote ? true : false}
      isLoading={isLoading}
      isLoadingMessage={isLoadingMessage}
      isCompleting={isCompleting}
      isCompletingMessage={isCompletingMessage}
      headingText={headingText}
      descriptionContent={headingDisplayContent}
      isQuote={true}
      data-cy="layout-mta-quote"
      showHeader={headingText ? true : false}
    >
      <ConfirmModal
        enabled={showConfirmModal}
        title={"Confirm these changes"}
        cancelText={"Cancel"}
        confirmText={"Confirm"}
        onConfirm={async () => {
          setShowConfirmModal(false);
          await fnComplete();
        }}
        onCancel={async () => {
          setShowConfirmModal(false);
        }}
        dataCy="mta-confirm-changes"
      >
        <p>
          By clicking 'Confirm' your policy will be updated with the changes
          you've requested.
        </p>
        <p>If you're not ready to proceed just yet, please click 'Cancel'.</p>
      </ConfirmModal>

      {displayContent}

      <FeedbackGetQuote className="mt-3" />
      <FeedbackPaymentLauncher className="mt-3" />

      <DevContainer heading="Force fail">
        <ButtonToggleMta
          checked={forceFail}
          onClick={() => {
            setForceFail(!forceFail);
          }}
        >
          Force Fail {forceFail ? "ON" : "OFF"}
        </ButtonToggleMta>
      </DevContainer>

      <DevContainer heading="Navigation">
        <ButtonStyledMta
          onClick={async () => {
            await functions.wrapup.RP({
              startDate: riskValues["Risk/StartDate"],
              emailAddress: riskValues["Risk/Proposer/Email"],
              isWrapupComplete: data_PaymentLauncher?.WrapupComplete,
            });
          }}
        >
          RP
        </ButtonStyledMta>
      </DevContainer>
      <DevContainer heading="Cur quote">
        <JsonViewer>{curQuote}</JsonViewer>
        <ButtonStyledMta
          onClick={() => functions.navigation.loadUrl("https://www.bbc.co.uk")}
        >
          Goto BBC
        </ButtonStyledMta>
      </DevContainer>
      <DevContainer heading="SAVED QUOTES">
        <MtaSavedQuotes
          onSelectQuote={async (quoteId) => {
            await functions.process.paymentLauncher({ quoteId });
            await functions.sessionSave();
          }}
        />
      </DevContainer>

      <DevContainer heading="Status">
        <JsonViewer>{status_GetQuote}</JsonViewer>
      </DevContainer>
    </Base>
  );
};

export default QuoteView;
