import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch } from "siteFunctions/hooks";
import { useSelector } from "react-redux";
import { retryFunction } from "moveToLibrary/functions";

import useSalusCompare from "moveToLibrary/hooks/useSalusCompare";
import PleaseWait from "../PleaseWait.jsx";
import {
  useLocation,
  useParams,
  useNavigate,
  useOutletContext,
  Navigate,
} from "react-router-dom";

import Base from "../Base.jsx";

const WraupLauncherView = () => {
  const params = useParams();
  const {
    functions,
    selectors,
    feedbackComponents: { Wrapup: FeedbackWrapup },
    routes,
  } = useOutletContext();

  const { paymentId } = params; // Get the paymentId from the url

  if (!paymentId) throw `Error in WraupLauncherView -- missing paymentId`;

  // const data_Wrapup = useSelector(selectors.getWrapupData);

  // const [alreadyProcessed] = useState(data_Wrapup?.isAP ? true : false);

  const riskValues = useSelector(
    (state) =>
      selectors.riskStore.getValue(state, [
        "Risk/StartDate",
        "Risk/Proposer/Email",
      ]),
    _.isEqual
  );

  // console.log("MTA/Wrapup/Launcher.jsx", {
  //   data_Wrapup,
  //   paymentId,
  //   alreadyProcessed,
  // });
  // **********************************************
  // EFFECTS
  // **********************************************

  useEffect(() => {
    const myFn = async () => {
      // if (alreadyProcessed) {
      //   console.log("Already processed");
      //   // A redirect further on will send the browser to the WRAPUP page
      //   return;
      // }

      const response = await functions.wrapup.getPurchaseResponse(paymentId);

      console.log("getPurchaseResponse response:", response);

      const baseArgs = {
        startDate: riskValues["Risk/StartDate"],
        emailAddress: riskValues["Risk/Proposer/Email"],
        isWrapupComplete: response?.isSuccess,
        isError: response?.isError,
      };

      await functions.wrapup.AP(baseArgs, { replace: true });
      // const _data_Wrapup = _.cloneDeep(data_Wrapup);
      //       await functions.process.reset();
      await functions
        .sessionSave
        // "Please wait a moment while we complete your changes."
        ();
      // await functions.navigation.wrapup({
      //   replace: true,
      //   state: { data_Wrapup: data_Wrapup },
      // });
    };
    myFn();
  }, []);

  // **********************************************
  // FINALISE
  // **********************************************
  // if (alreadyProcessed) return <Navigate to={routes.wrapup} replace />;

  return (
    <Base
      className="mta-wrapup-interstitial"
      // isLoading={true}
      // isLoadingMessage={"We're currently completing your purchase..."}
      // headingText={`Tidying up`}
      isWrapupLauncher={true}
      data-cy="layout-mta-wrapup-launch"
    >
      <PleaseWait>
        Please wait a moment while we complete your changes.
      </PleaseWait>
      <FeedbackWrapup />
    </Base>
  );
};

export default WraupLauncherView;
