export default {
	copyValue: require("./functions/copyValue").default,
	//convertToInteger: require("./functions/convertToInteger").default,
	isRequired: require("./functions/isRequired").default,
	//isDate: require("./functions/isDate").default,
	//isTrue: require("./functions/isTrue").default,
	minLength: require("./functions/length/min").default,
	maxLength: require("./functions/length/max").default,

	//	maxLength: require("./functions/maxLength").default,
	regEx: require("./functions/regEx").default,
	//regExCancel: require("./functions/regExCancel").default,
	splitJSON: require("./functions/splitJSON").default,
	uniqueTreeItem: require("./functions/uniqueTreeItem").default,
	purchaseOffline: require("./functions/purchaseOffline").default,
	runRules: require("./functions/runRules").default,
	...require("./functions/stringFunctions").default,
	...require("./functions/dateFunctions").default,
};
