import services from "siteService";
import _ from "lodash";
import { retryFunction } from "moveToLibrary/functions";

const mtaservices = {
  paymentLauncher: async (payload) => {
    console.log("paymentLauncher", payload);

    const retryResponse = await retryFunction(
      async () => await services.update.payment.register(payload),
      2
    );

    if (retryResponse === "ABORT") return "ABORT";

    if (!retryResponse.isSuccess) {
      // This "isSuccess" is from retryFunction(), not the actual endpoint
      throw retryResponse.err;
    }

    const response = retryResponse.response;

    console.log("paymentLauncher", "response", response);
    const { Status, Message, ...otherProps } = response || {};

    // https://dev.azure.com/FreedomServicesGroup/Odyssey/_wiki/wikis/Odyssey.wiki/463/RegisterPayment

    return {
      isSuccess: Status === "SUCCESS",
      message: Message,
      data: otherProps,
    };
  },
  // getPurchaseResponse: async (args = {}) => {
  //   ["paymentId"].argChecker(args);

  //   const payload = { PaymentId: args.paymentId };
  //   console.log("services", "getPurchaseResponse", args);

  //   //     const response = await services.update.payment.purchaseResponse(payload);
  //   if (true) {
  //     const [response] = await Promise.all([
  //       Promise.resolve({
  //         Status: "SUCCESS",
  //         WebReference: "WEB-REFERENCE",
  //         IsSuccess: true,
  //       }),
  //       new Promise((resolve) => setTimeout(resolve, 500)),
  //     ]);

  //     return {
  //       isSuccess: response.isSuccess, //  response.Status === "SUCCESS",
  //       message: response.Message,
  //       data: response.Data,
  //     };
  //   }
  // },
  getSavedQuotes: async (args = {}) => {
    if (true) {
      const [response] = await Promise.all([
        Promise.resolve({
          Status: "SUCCESS",
          QuoteData: { amount: 123.45 },
        }),
        new Promise((resolve) => setTimeout(resolve, 2000)),
      ]);

      if (response === "ABORT") return "ABORT";

      return {
        isSuccess: response.Status === "SUCCESS",
        savedQuotes: [
          { quoteId: 12345, quoteData: {} },
          { quoteId: 67890, quoteData: {} },
        ],
      };
    }
  },
  getQuote: async (args = {}) => {
    console.log("services.getQuote", args);

    ["policyId", "salusData", "webReference", "reasonList"].argChecker(
      args,
      "site mta services getQuote()"
    );

    const payload = {
      WebReference: args.webReference,
      PolicyId: args.policyId,
      Risk: args.salusData.Risk,
      ChangeReasons: args.reasonList,
    };

    const response = await services.update.mta.quote(payload);
    if (response === "ABORT") return "ABORT";

    // console.log("getQuote", { response });

    return {
      isSuccess: (function () {
        return true; // #2807 -- Let the REDUX store think it's a sucess, and then the frontend component can handle the various responses;
        if (response.Status === "SUCCESS") return true;

        switch (response?.QuoteResult?.QuoteResultType) {
          case "NQ":
            return true;
          default:
            return false;
        }
      })(),

      data: (function () {
        //#3237
        if (response.QuoteResult) return response.QuoteResult;
        return { QuoteResultType: response.Status };
      })(),

      // data: {
      //   ...response.QuoteResult,
      //   QuoteResultType: "INVALID_RISK",
      //   MtaType: "",
      // },
      message: response.Message,
    };

    if (true) {
      const [response] = await Promise.all([
        Promise.resolve({
          // Status: "SUCCESS",
          // QuoteData: fnGenerateExampleQuote(),
          // QuoteData: { Amount: 123.45, QuoteId: "000000" },

          Status: "SUCCESS",
          QuoteResult: {
            MtaType: "ddddd",
            TotalAdjustmentAmount: 123.45,
            AdjustmentAmount: 111,
            Ipt: 1221,
            IptRate: 0.5,
            MtaFee: 222,
            ToPayToday: 2222,
            RemainingBalance: 2222,
            IpidUrl: "xxxxx",
            AllowAdjustmentInFull: true,
            AllowSpreadMonthly: true,
          },
        }),
        new Promise((resolve) => setTimeout(resolve, 2000)),
      ]);

      return {
        isSuccess: response.Status === "SUCCESS",
        QuoteResult: response.QuoteResult,
        // quoteId: response.QuoteId,
        message: response.Message,
      };
    }
  },
  getSalusRisk: async (args = {}) => {
    const payload = {
      PolicyId: args.policyId,
      Risk: args.salusData,
      // "Reason": ""
    };

    if (false) {
      const response = await services.update.mta.recall(payload);
      console.log("getSalusRisk", { response });
    }

    console.log("services.getSalusRisk", args);

    ["policyId", "eventId"].argChecker(args);

    console.log("storeServices.getSalusRisk", args);

    const response = await services.userDetails.getPolicyDetails(
      [args.policyId, "Events", args.eventId].join("/")
    );
    if (response === "ABORT") return "ABORT";

    console.log("storeServices.getSalusRisk", response);
    // const [response] = await Promise.all([
    //   Promise.resolve({
    //     Status: "SUCCESS",
    //     Data: sampleCarSalusData,
    //   }),
    //   new Promise((resolve) => setTimeout(resolve, 500)),
    // ]);

    const {
      Status,
      Message = "Sorry, there's been an error",
      ...otherProps
    } = response;

    if (Status !== "SUCCESS") {
      return {
        isSuccess: false,
        message: Message,
      };
    }

    return {
      isSuccess: Status === "SUCCESS",
      data: otherProps,
    };
  },
};

export default mtaservices;
