import React from "react";
import PageLayout from "siteLayouts/Page";
import { Panel, CardBorder } from "siteComponents";

const HeaderWithIcon = (props) => {
  const { heading, icon } = props;

  return (
    <div className="d-flex align-items-center icon-header">
      <div className="flex-shrink-0">
        <span className={["icon", icon].toClassName()}></span>
      </div>
      <div className="text">{heading}</div>
    </div>
  );
};

const ErrorBaseView = (props) => {
  const { children, heading, icon } = props;

  return (
    <PageLayout
      snapToTop={true}
      headingText={<HeaderWithIcon icon={icon} heading={heading} />}
      className="error-page"
      data-cy="page-layout-error-template"
    >
      <Panel
        data-cy="error-panel"
        //header={}
      >
        <CardBorder>{children}</CardBorder>
      </Panel>
    </PageLayout>
  );
};

export default ErrorBaseView;
