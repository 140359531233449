// ProtectedRoute.js
import React from "react";
import { useSelector } from "react-redux";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { actions, selectors } from "siteStore";
import PageSplitLayout from "siteLayouts/Page/Split";
import { Panel } from "siteComponents";
// import ProtectedTimeout from "./ProtectedTimeout";
import Timeout from "siteViews/Errors/Timeout";

// https://blog.logrocket.com/handling-user-authentication-redux-toolkit/

const ProtectedRoute = (props) => {
  const { routeLogin } = props;

  if (!routeLogin) throw `Error in ProtectedRoute -- missing routeLogin`;

  const navigate = useNavigate();
  const isLoggedIn = useSelector(
    selectors.auth.isLoggedIn,
    require("lodash").isEqual
  );

  if (!isLoggedIn) {
    const onClick = () => {
      navigate("/");
    };

    return <Timeout clearTimeout={onClick} routeStart="/" />;
  }

  return <Outlet />;
};
export default ProtectedRoute;
