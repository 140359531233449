import _ from "lodash";
import generateComponentSet from "@library/common/database/functions/generateComponentSet";

const generate = (args = {}) => {
  const { riskStoreConfig } = args;

  return {
    database: {
      getPropsTree: (key, componentSet, options = {}) => {
        if (!componentSet) {
          console.log("ERROR INFO:", { key, componentSet, options });
          throw `Error in getPropsTree -- missing componentSet`;
        }
        const { group } = options;

        const fnProcessNode = (node, componentSet, level = 1) => {
          const isEntry = level === 1;

          const itemType = node.getItemType();
          const fnGetProps = () => ({
            ...node.getProps({ componentSet }),
            ...((group && { group: group }) || {}), // Append group if it's there
          });

          if (itemType === "item") return fnGetProps();
          if (itemType === "list") {
            if (isEntry)
              return Object.fromEntries(
                Object.entries(node.getItems()).map(([key, subNode]) => [
                  key,
                  fnProcessNode(subNode, componentSet, level + 1),
                ])
              );

            return fnGetProps();
          }

          if (itemType === "set") {
            return Object.fromEntries(
              Object.entries(node.getItems()).map(([key, subNode]) => [
                key,
                fnProcessNode(
                  subNode,
                  generateComponentSet({
                    parentComponentSet: componentSet,
                    componentTag: node.getComponentTag(),
                  }),
                  level + 1
                ),
              ])
            );
          }

          // Fallback
          return {};
        };

        const baseNode = riskStoreConfig.database.findTreeItem(key);

        const retData = fnProcessNode(baseNode, componentSet, 1);
        return retData;
      },
    },
  };
};

export default generate;
