import React from "react";
import PageLayout from "siteLayouts/Page";
import config from "config";
import { Loading } from "siteComponents";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";

import SiteLayout from "siteLayouts/Site";

// import FAQView from "./FAQ";
// import ContactUsView from "./ContactUs";

const FAQView = React.lazy(() => import("siteViews/Help/FAQ"));
const ContactUsView = React.lazy(() => import("siteViews/Help/ContactUs"));

const routes = [
  {
    element: (
      <SiteLayout>
        {/* <React.Suspense fallback={<Loading />}> */}
        <Outlet />
        {/* </React.Suspense> */}
      </SiteLayout>
    ),
    children: [
      {
        path: "contact-us",
        element: <ContactUsView routeLogin="/" />,
      },

      {
        path: "faq",
        element: <FAQView routeLogin="/" />,
      },
    ],
  },
];

export default routes;
