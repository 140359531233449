import React, { useEffect } from "react";
import { useDispatch } from "siteFunctions/hooks";
import { useSelector } from "react-redux";
import {
  useLocation,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import _ from "lodash";
import {
  Loading,
  DevContainer,
  ButtonStyled,
  ButtonStyledMta,
} from "siteComponents";
// import ChangeViewer, { useChanges } from "./ChangeViewer";

// import { mtaStore } from "../common";

const MtaSavedQuotesItem = (props) => {
  const { quoteId, onClick, quoteData } = props;

  // const fnGetChangeData = useChanges(quoteData);

  return (
    <div className="p-2">
      {quoteId}
      <p>CHANGE VIEW</p>
      {/* <ChangeViewer data={fnGetChangeData} /> */}
      <ButtonStyledMta onClick={() => onClick(quoteId)}>
        Pay for
      </ButtonStyledMta>
    </div>
  );
};

const Wrapper = ({ children }) => (
  <div className="mta-quotes-saved">{children}</div>
);

const MtaSavedQuotes = (props) => {
  ["onSelectQuote"].argChecker(props);
  const { onSelectQuote } = props;

  const { functions, selectors, mtaStore } = useOutletContext();

  const savedQuotes = useSelector(
    mtaStore.selectors.getSavedQuotes,
    require("lodash").isEqual
  );
  const savedQuotesStatus = useSelector(
    selectors.status.getSavedQuotesStatus,
    require("lodash").isEqual
  );

  // console.log("savedQuotes", { savedQuotes, savedQuotesStatus });

  if (savedQuotesStatus.isInitiating)
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );

  if (savedQuotes.length === 0) return null;

  return (
    <Wrapper>
      {savedQuotesStatus.isInitiating ? "Loading" : ""}
      {savedQuotes.map((x) => (
        <MtaSavedQuotesItem key={x.quoteId} {...x} onClick={onSelectQuote} />
      ))}
    </Wrapper>
  );
};

export default MtaSavedQuotes;
