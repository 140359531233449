import React from "react";

const ErrorPanel = (props) => {
    const {
      icon = "icon-hand",
      heading = "We're sorry!",
      className,
      children,
    } = props;
  
    return (
      <div
        className={["error-quote", className].toClassName()}
        data-cy="error-panel"
      >
        <div className="d-flex align-items-center icon-header p-3 mb-3">
          <div className="flex-shrink-0">
            <span className={["icon", icon].toClassName()}></span>
          </div>
          <div className="text">{heading}</div>
        </div>
        {children}
      </div>
    );
};

export default ErrorPanel;