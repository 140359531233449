import React from "react";
import Base from "../Base";

import { ButtonStyled } from "siteComponents";
import { useSelector } from "react-redux";
import { actions, selectors } from "siteStore";

const ChunkView = (props) => {
  const isLoggedIn = useSelector(
    selectors.auth.isLoggedIn,
    require("lodash").isEqual
  );

  const onClick = () => {
    location.reload(true);
  };

  return (
    <Base heading="Our site has been updated!" icon="icon-upgrade-update">
      <div>
        Since you were last here our site has been updated. To ensure you get
        everything up to date you'll need to reload our site.
      </div>
      <div>
        Please refresh your browser and return{" "}
        {isLoggedIn ? <span>home</span> : <span>log in</span>}.
      </div>
      <div>
        <ButtonStyled onClick={onClick}>Refresh your browser</ButtonStyled>
      </div>
    </Base>
  );
};

export default ChunkView;
