// template car vehicle
import { list, set, item } from "@library/common/database/classes";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";

import constants from "@library/common/constants/database";
import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import generateBaseRuleData from "../../../generateBaseRuleData";
import regExConstants from "@library/common/constants/regEx";
import baseData from "../../base";
const { Postcode: PostcodeTemplate } = baseData;

const generateLogicRule = (additionalMappings = {}, componentSetPath, mode) => {
  console.log("generateLogicRule", {
    additionalMappings,
    componentSetPath,
    mode,
  });
  //   throw `hhh`;

  const generateRuleProps = (componentTag) => {
    if (componentSetPath) return { componentTag, componentSetPath };

    return {
      componentTag,
      useDefaultComponentSet: true,
    };
  };

  return new ruleItem(
    {
      ...generateBaseRuleData("vehicle", "vehicleLogic"),
    },
    {
      Postcode: new dataSetArgItem(generateRuleProps("Postcode")),
      KeptAtHomeQuestion: new dataSetArgItem(
        generateRuleProps("KeptAtHomeQuestion")
      ),
      KeptPostcode: new dataSetArgItem(generateRuleProps("KeptPostcode")),

      Keeper: new dataSetArgItem(generateRuleProps("Keeper")),
      Owner: new dataSetArgItem(generateRuleProps("Owner")),
      OwnerKeeperQuestion: new dataSetArgItem(
        generateRuleProps("OwnerKeeperQuestion")
      ),
      PurchasedNotYet: new dataSetArgItem(generateRuleProps("PurchasedNotYet")),
      Purchased: new dataSetArgItem(generateRuleProps("Purchased")),
      ...additionalMappings,
      errorMessages: {
        purchasedFuture: mode === "mta" && "Error",
      },
    }
  );
};

const generateSet = (args) => {
  const { items = {}, additionalMappings = {} } = args;
  const vehicleLogicRule = generateLogicRule(additionalMappings);

  const retSet = new set({
    // componentSet: "Risk/Vehicle",
    submitKey: "Vehicle",
    items: {
      ...items,
      IsLookup: new item({
        defaultValue: false,
      }),
      VehicleType: new item({
        submitKey: "VehicleType",
        // defaultValue: "GoodsVehicle",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      Abs: new item({
        submitKey: "Abs",
        defaultValue: false,
        exampleValue: true,
        // rules: [
        // 	new ruleItem({
        // 		...generateBaseRuleData("general", "isRequired"),},{
        // 		errorMessage: "", // errorMessage: "Required",
        // 	}),
        // ],
      }),
      BodyStyle: new item({
        submitKey: "BodyStyle",
        // rules: [
        // 	new ruleItem({
        // 		...generateBaseRuleData("general", "isRequired"),},{
        // 		errorMessage: "", // errorMessage: "Required",
        // 	}),
        // ],
      }),
      Lhd: new item({
        submitKey: "Lhd",
        label: "Is the car standard right hand drive?",
        defaultValue: false,
        html: {
          itemData: constants.lists.yesNoFlipped,
        },
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      // DaytimeLocation: new item({
      // 	submitKey: "DaytimeLocation",
      // 	defaultValue: "HOME",
      // }),
      Doors: new item({
        submitKey: "Doors",
        exampleValue: "3",
        // rules: [
        // 	new ruleItem({
        // 		...generateBaseRuleData("general", "isRequired"),},{
        // 		errorMessage: "", // errorMessage: "Required",
        // 	}),
        // ],
      }),
      Seats: new item({
        // componentTag:  "Seats",
        submitKey: "Seats",
        exampleValue: "3",
        label: "How many seats are in the car?",
        html: {
          itemData: [
            { label: "1 seat", value: "1" },
            { label: "2 seats", value: "2" },
            { label: "3 seats", value: "3" },
            { label: "4 seats", value: "4" },
            { label: "5 seats", value: "5" },
            { label: "6 seats", value: "6" },
            { label: "7 seats", value: "7" },
            { label: "8 seats", value: "8" },
            { label: "9 seats", value: "9" },
          ],
        },
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      // AnyTrailers: new item({
      // 	submitKey: "AnyTrailers",
      // 	rules: [
      // 		new ruleItem({
      // 			...generateBaseRuleData("general", "isRequired"),},{
      // 			errorMessage: "", // errorMessage: "Required",
      // 		}),
      // 	],
      // }),

      // AnyContainers: new item({
      // 	submitKey: "AnyContainers",
      // 	rules: [
      // 		new ruleItem({
      // 			...generateBaseRuleData("general", "isRequired"),},{
      // 			errorMessage: "", // errorMessage: "Required",
      // 		}),
      // 	],
      // }),

      AbiCode: new item({
        submitKey: "AbiCode",
        exampleValue: "90302546",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      // TrailerValue: new item({
      // 	// componentTag:  "TrailerValue",
      // 	submitKey: "TrailerValue",
      // 	exampleValue: null,
      // }),
      // Id: new item({
      // 	// componentTag:  "Id",
      // 	submitKey: "Id",
      // 	exampleValue: null,
      // }),

      AbiProductionYears: new item({
        submitKey: "AbiProductionYears",
        exampleValue: "2002-2009",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      Alarm: new item({
        submitKey: "Alarm",
        exampleValue: "NONE",
        label: "Is the car fitted with an alarm?",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      // PurchasedNotYet: new item({
      // 	rules: [logicRule],
      // }),

      Purchased: new item({
        submitKey: "Purchased",
        exampleValue: "2020-01-09T00:00:00",
        label: "When did you purchase the car?",
        fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
        fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),
        rules: [
          vehicleLogicRule,
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
          new ruleItem(
            {
              ...generateBaseRuleData("general", "dateComparison"),
            },
            {
              errorMessage: "", //"Vehicle must be purchased after 1970",
              compareDateString: "1 JAN 1970",
              comparator: ">=",
            }
          ),
          // new ruleItem({
          // 	...generateBaseRuleData("general", "dateComparison"),},{
          // 	errorMessage:
          // 		"You have indicated you have purchased your car after you have chosen the policy to start. Please review and update.", //"Your policy start cannot be before the vehicle purchase date.",
          // 	compareDate: policyInceptionDate,
          // 	comparator: "<=",
          // }),
        ],
      }),
      Cc: new item({
        submitKey: "Cc",
        exampleValue: "1753",
        label: "Engine size",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),
      Fuel: new item({
        submitKey: "Fuel",
        exampleValue: "FuelDiesel",
        label: "Fuel type",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      Immob: new item({
        submitKey: "Immob",
        label: "Is the car fitted with an immobiliser?",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      Manufacturer: new item({
        submitKey: "Manufacturer",
        exampleValue: "FORD",
        label: "Manufacturer",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      ModelDescription: new item({
        submitKey: "ModelDescription",
        exampleValue: "T220 CONNECT TDI (75) LWB",
        label: "Model",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      OwnerKeeperQuestion: new item({
        label: "Are you the owner and registered keeper of the car?",
        html: {
          itemData: constants.lists.yesNo,
        },
        rules: [
          vehicleLogicRule,
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      Owner: new item({
        // componentTag:  "Owner",
        submitKey: "Owner",
        exampleValue: "Proposer",
        label: "Who is the owner of the car?",
        // html: {
        // 	itemData: constants.lists.yesNo,
        // },
        rules: [
          // new ruleItem(
          // 	{
          // 		...generateBaseRuleData("general", "isRequired"),
          // 	},
          // 	{
          // 		errorMessage: "", // errorMessage: "Required",
          // 	}
          // ),
          vehicleLogicRule,
        ],
      }),

      Value: new item({
        // componentTag:  "Value",
        label: "What's the value of the car?",
        submitKey: "Value",
        exampleValue: "5000",
        html: { regEx: regExConstants.numbers.whole, maxLength: 10 },
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
          new ruleItem(
            {
              ...generateBaseRuleData("general", "copyValue"),
            },
            {
              target: new dataSetArgItem({
                componentTag: "Paid",
                useDefaultComponentSet: true,
              }),
            }
          ),
        ],
      }),
      Paid: new item({
        submitKey: "Paid",
        exampleValue: "5000",
        html: { regEx: regExConstants.numbers.whole, maxLength: 10 },
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),
      Keeper: new item({
        submitKey: "Keeper",
        label: "Are you the registered keeper of the car?",
        html: {
          itemData: constants.lists.yesNo,
        },
        rules: [
          vehicleLogicRule,
          // new ruleItem(
          // 	{
          // 		...generateBaseRuleData("general", "isRequired"),
          // 	},
          // 	{
          // 		errorMessage: "", // errorMessage: "Required",
          // 	}
          // ),
        ],
      }),

      RegNumber: new item({
        submitKey: "RegNumber",
        label: "Vehicle Reg",
        rules: [
          new ruleItem(
            generateBaseRuleData("general", "emptyStringToUndefined"),
            {}
          ),
          // new ruleItem({
          // 	...generateBaseRuleData("general", "isRequired"),},{
          // 	message: "Required",
          // }),
          // new ruleItem({
          // 	...generateBaseRuleData("general", "regEx"),},{
          // 	regEx: regExConstants.vehicle.registrationNumber,
          // 	errorMessage: "Please enter a valid registration.",
          // }),
        ],
      }),

      Tracker: new item({
        submitKey: "Tracker",
        exampleValue: "NONE",
        label: "Is the car fitted with a tracker?",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      Transmission: new item({
        submitKey: "Transmission",
        exampleValue: "TransMan",
        label: "Transmission",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      WhereKept: new item({
        submitKey: "WhereKept",
        exampleValue: "Driveway",
        label: "Where is the car kept overnight?",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      //None STRATA question
      KeptAtHomeQuestion: new item({
        label: "Is the car kept overnight at your home address?",
        html: {
          itemData: constants.lists.yesNo,
        },
        rules: [
          vehicleLogicRule,
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      KeptPostcode: new item({
        submitKey: "KeptPostcode",
        exampleValue: "ST13 7JL",
        label: "Postcode of where the car is kept overnight",
        rules: [
          vehicleLogicRule,
          // ...PostcodeTemplate.generateRules(),
          // new ruleItem(
          // 	{
          // 		...generateBaseRuleData("general", "isRequired"),
          // 	},
          // 	{
          // 		errorMessage: "",
          // 		// errorMessage: "Please enter your postcode",
          // 	}
          // ),
        ],
      }),

      YearOfManufacture: new item({
        submitKey: "YearOfManufacture",
        exampleValue: "2006",
        label: "Year of manufacture",
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("general", "isRequired"),
            },
            {
              errorMessage: "", // errorMessage: "Required",
            }
          ),
        ],
      }),

      Modifications: new item({
        label: "Please select up to 5 modifications from the list",
        submitKey: "Modifications",

        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("vehicle", "modificationLogic"),
            },
            {
              ModificationInd: new dataSetArgItem({
                componentTag: "ModificationInd",
                useDefaultComponentSet: true,
              }),
              Modifications: new dataSetArgItem({
                componentTag: "Modifications",
                useDefaultComponentSet: true,
              }),
            }
          ),
        ],
      }),

      ModificationInd: new item({
        defaultValue: false,
        label: "Does the car have any modifications?",
        submitKey: "ModificationInd",
        helpText:
          "If your vehicle has been changed from the manufacturers standard specification in any way then please answer Yes.",
        html: {
          itemData: constants.lists.yesNo,
        },
        rules: [
          new ruleItem(
            {
              ...generateBaseRuleData("vehicle", "modificationLogic"),
            },
            {
              ModificationInd: new dataSetArgItem({
                componentTag: "ModificationInd",
                useDefaultComponentSet: true,
              }),
              Modifications: new dataSetArgItem({
                componentTag: "Modifications",
                useDefaultComponentSet: true,
              }),
            }
          ),
        ],
      }),

      CurrentMileage: new item({
        submitKey: "CurrentMileage",
        // rules: [
        // 	new ruleItem({
        // 		...generateBaseRuleData("general", "isRequired"),},{
        // 		errorMessage: "", // errorMessage: "Required",
        // 	}),
        // ],
      }),
    },
  });

  // console.log("dddd vehicleSet:", retSet);
  return retSet;
};

export default {
  generateLogicRule,
  generateSet,
};
