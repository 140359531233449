import React, { useEffect } from "react";
import _ from "lodash";
import { VehicleReg } from "siteComponents";

const VehicleDetails = (props) => {
    // console.log("VehicleDetails", props);
    const {
        className,
        showReg = true,
        showDescription = true,
        showIcon = true,
        ...otherProps
    } = props;

    const _className = [
        "d-flex",
        "flex-column",
        "flex-md-row",
        "vehicle-details",
        "vehicle-details-pc",
        className,
    ].toClassName();

    const description = [props.Make, props.Model].join(" ");
    const details = [
        props.Doors ? `${props.Doors} door` : "",
        props.EngineSize ? `${props.EngineSize} cc` : "",
    ]
        .filter((x) => x)
        .join(", ");

    return (
        <div className={_className}>
            {showReg && (
                <div className="d-flex justify-content-center align-items-center text-center mb-3 mb-md-0">
                    <VehicleReg>{props.RegNumber}</VehicleReg>
                </div>
            )}

            {showDescription && (
                <div className="d-flex justify-content-center justify-content-md-start align-items-center description">{description}</div>
            )}

            {showIcon && (
                <div className="col d-none d-md-flex justify-content-end">
                    <i className="icon icon-car-side vehicle-details-icon" />
                </div>
            )}
        </div>
    );
};

export default VehicleDetails;
