import React, { useState, useEffect } from "react";
import config from "config";
import services from "siteService";
import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";
import _ from "lodash";
import { actions, selectors, helpers } from "siteStore";

import { DevContainer, JsonViewer, Button } from "siteComponents";

import GlobalState from "@library/common/components/devTools/GlobalState";
import GlobalErrors from "@library/common/components/devTools/GlobalErrors";
import { generateCypressProps } from "siteFunctions/cypress";
import { isPassword } from "moveToLibrary/functions";
import compare from "@library/common/helpers/misc/compareObjects";

// const DevContainer = ({ children, heading }) => (
//   <div className="m-2 p-3 bg-light rounded-3">
//     {heading && <p className="h5 fw-700">{heading}</p>}
//     {children}
//   </div>
// );

import QuoteAndBuyStoreSalusChecker, {
  QuoteAndBuyStoreSalusHitlist,
} from "./DevComponents/QuoteAndBuyStoreSalusChecker";

const AutoQuoteAndBuy = (props) => {
  const [lastResponse, setLastResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fnAutoQuoteAndBuy = async ({
    WebReference,
    PayMonthly,
    EmailAddress,
  }) => {
    // console.log("services", services.test);
    const payload = {
      WebReference,
      PayMonthly,
      EmailAddress,
    };
    setIsLoading(true);
    const response = await services.test.autoQuoteAndBuy(payload);
    setIsLoading(false);
    console.log("response", response);
    setLastResponse(
      JSON.stringify({
        ...response,
        suggestedUsername: `mockuser_${Date.now()}`,
        suggestedPassword: `Testing123!`, //`Password_!${_.random(10000, 99999)}`,
        suggestedSecurityAnswer: `Elvis`,
      })
    );
  };

  // console.log("dddd", isLoading, lastResponse);

  // if (isLoading) return <span>Please wait...</span>;

  // if (lastResponse) return <JsonViewer>{lastResponse}</JsonViewer>;
  if (lastResponse) return <div>{lastResponse}</div>;
  //     EmailAddress: "some-email@fsg.co.uk",
  return (
    <span>
      {[
        {
          WebReference: "MR-PC-STELLA",
          PayMonthly: false,
          label: "MR-PC-STELLA Annual",
          EmailAddress: "mock-recall@action365.co.uk",
        },
        {
          WebReference: "MR-PC-STAN",
          PayMonthly: false,
          label: "MR-PC-STAN FREEDOM Annual",
          EmailAddress: "mock-recall@action365.co.uk",
          isEnabled: false,
        },
      ]
        .filter((x) => x.isEnabled !== false)
        .map((x, i) => (
          <Button
            key={i}
            onClick={() => {
              fnAutoQuoteAndBuy(x);
            }}
            isLoading={isLoading}
          >
            {x.label}
          </Button>
        ))}

      {lastResponse && <JsonViewer>{lastResponse}</JsonViewer>}
    </span>
  );
};

const TestArea = (props) => {
  // console.log("ssss", actions);
  const dispatch = useDispatch();

  const fnPersistDirect = async (e) => {
    e.preventDefault();
    const persistId = await services.persist.create();
    console.log("persistId", persistId);

    const responseSave = await services.persist.save({
      Id: persistId,
      DataString: "{test:111}",
    });
    console.log("responseSave", responseSave);

    const responseGet = await services.persist.get({
      Id: persistId,
    });
    console.log("responseGet", responseGet);

    const responseDelete = await services.persist.delete({
      Id: persistId,
    });
    console.log("responseDelete", responseDelete);
  };

  const fnSession = async (e) => {
    e.preventDefault();

    dispatch(actions.session.init());
  };
  return (
    <span>
      <button onClick={fnPersistDirect}>Test</button>
    </span>
  );
};

const SessionControls = () => {
  const dispatch = useDispatch();
  const sessionId = useSelector(
    selectors.session.getSessionId,
    require("lodash").isEqual
  );
  const isInit = useSelector(
    selectors.session.getIsInit,
    require("lodash").isEqual
  );

  const fnSave = async (e) => {
    e.preventDefault();
    dispatch(actions.session.save());
  };

  return (
    <div>
      <JsonViewer>{{ sessionId, isInit }}</JsonViewer>
      <button onClick={fnSave}>Save Persist</button>
      <Button
        onClick={async (e) => {
          e.preventDefault();
          await dispatch(actions.session.regenerate());
        }}
      >
        regenerate SESSION
      </Button>
    </div>
  );
};

const Auth = () => {
  const userInfo =
    useSelector(selectors.dashboard.getUserInfo, require("lodash").isEqual) ||
    {};
  const isLoggedIn = useSelector(
    selectors.auth.isLoggedIn,
    require("lodash").isEqual
  );
  const userToken = useSelector(
    selectors.auth.getUserToken,
    require("lodash").isEqual
  );
  return (
    <div className="row">
      <div className="col-12 py-3">
        <JsonViewer>{{ isLoggedIn, userToken }}</JsonViewer>
      </div>

      {userInfo && (
        <div className="col-12 py-3">
          <JsonViewer>{userInfo}</JsonViewer>
        </div>
      )}
    </div>
  );
};

const QuoteAndBuyStore = () => {
  const [showErrors, setShowErrors] = useState(false);

  // console.log("dddddd",selectors)
  // return <div>xxx</div>;

  const ErrorList =
    useSelector(
      (state) => selectors.quoteAndBuyCar.errors.list(state, ""),
      _.isEqual
    ) || [];

  // console.groupCollapsed(
  //   "QuoteAndBuyStore ERRORLIST:",
  //   "count=",
  //   ErrorList.length
  // );
  // ErrorList.forEach(({ path, ...otherProps }) => {
  //   console.log(path, otherProps);
  // });
  // console.groupEnd();

  if (!showErrors)
    return (
      <Button onClick={() => setShowErrors(true)}>
        Show QuoteAndBuyStore Errors
      </Button>
    );
  return (
    <dl>
      {ErrorList.map((x) => (
        <React.Fragment key={x.path}>
          <dt>{x.path}</dt>
          <dd>{JSON.stringify(x.error)}</dd>
        </React.Fragment>
      ))}
    </dl>
  );
};

const Debug = (props) => {
  if (!(config.isDev || config.isTest)) return null;

  // console.log("PASSWORD", isPassword("A111-") ? "true" : "false");
  const dispatch = useDispatch();

  return (
    <div
      className="debug-info"
      {...generateCypressProps("layout-debug-panel", props)}
    >
      <DevContainer heading="QuoteAndBuyStore ERRORS">
        <QuoteAndBuyStore />
      </DevContainer>

      <DevContainer heading="QuoteAndBuyStore Salus checker">
        <QuoteAndBuyStoreSalusChecker />
      </DevContainer>

      <DevContainer heading="QuoteAndBuyStore Salus HITLIST checker">
        <QuoteAndBuyStoreSalusHitlist />
      </DevContainer>

      <DevContainer heading="AutoQuoteAndBuy">
        <AutoQuoteAndBuy />
      </DevContainer>

      <DevContainer heading="Session persist tools">
        <SessionControls />
      </DevContainer>

      <DevContainer heading="Error links">
        <p>
          <a href="/error/maintenance">Maintenance</a>
        </p>
        <p>
          <a href="/error/chunk">Chunk</a>
        </p>
        <p>
          <a href="/error/errorboundary">errorboundary</a>
        </p>
        <p>
          <a href="/error/timeout">timeout</a>
        </p>
      </DevContainer>

      <DevContainer heading="Global state">
        <GlobalState />
      </DevContainer>

      <DevContainer heading="Authentication data">
        <Auth />
      </DevContainer>

      {/* <DevContainer heading="Test area">
        <TestArea />
      </DevContainer> */}
    </div>
  );
};

export default Debug;
