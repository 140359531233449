import dayjs from "dayjs";
import _ from "lodash";
// import { isWebReference as libraryIsWebReference } from "@library/common/helpers/validation";

//-- start of snippet --
// export const isPopupBlocked = () => {
//   var PUtest = window.open(
//     "",
//     "",
//     "width=1,height=1,left=0,top=0,scrollbars=no"
//   );
//   try {
//     PUtest.close();
//     return false;
//   } catch (e) {
//     return true;
//   }
// };

export const isPassword = (v) => {
  if (!v) return false;

  const isLetterUpper = (v) => /[A-Z]/.test(v);
  const isLetterLower = (v) => /[a-z]/.test(v);
  const isNumber = (v) => /\d/.test(v);

  if (!isLetterUpper(v)) return false;
  if (!isNumber(v)) return false;

  const isSpecial = v.split("").some((c) => {
    if (isLetterUpper(c)) return false;
    if (isLetterLower(c)) return false;
    if (isNumber(c)) return false;
    return true;
  });

  if (false)
    console.log("isPassword:", v, {
      upper: isLetterUpper(v),
      lower: isLetterLower(v),
      number: isNumber(v),
      special: isSpecial,
    });

  if (!isSpecial) return false;

  return true;
  // https://www.w3resource.com/javascript/form/password-validation.php

  const regEx = /^(?=.*[0-9])(?=.*[!@#$%^&*_])[a-zA-Z0-9!@#$%^&*_]*$/;

  // #2326
  // Must have 1x UPPER WESTERN LETTER
  // 1x number
  // 1x other (aka symbol) (includes spaces)

  return regEx.test(v);
  // if (!v) return false;

  // if (v.trim().length <= 7) return false;
  // if (!/\d/.test(v)) return false; // check numbers
  // if (!/[a-zA-Z]/.test(v)) return false; // check letter

  // return true;
  /*

Your password must contain:
case sensitive?
at least 8 characters
at least 1 number
at least one symbol (e.g. - / + @ £ $ % ^ & *)
  */
};

export const isWebReference = (v) => {
  // If "v" contains anything other than a NUMBER or "-", then reject
  if (!/^[0-9\-]+$/.test(v)) return false;

  // Original regex as used by CDL (not foolproof on its own, see #2766 -- allows ":4652-427312-8280")
  return /^(\d{5}-\d{5}-\d{5})|(\d{4}-\d{6}-\d{4})$/.test(v);
};

export const getDaysAwayFromToday = (testDate, options = {}) => {
  const {
    msgToday = "Today",
    msgPast = undefined,
    includeDaysText = true,
  } = options;

  const eventdate = dayjs(testDate);
  const todaysdate = dayjs();

  const daysToGo = eventdate.diff(todaysdate, "days");

  if (daysToGo >= 1) return includeDaysText ? `${daysToGo} days` : daysToGo;
  if (daysToGo === 0) return msgToday;

  return msgPast;
};

export const getDaysDifference = (startDate, EndDate, options = {}) => {
  const {
    msgReached = undefined,
    msgPast = undefined,
    includeDaysText = true,
  } = options;

  const _startDate = dayjs(startDate);
  const _endDate = dayjs(EndDate);

  const daysToGo = _endDate.diff(_startDate, "days");
  // console.log("dadadad ", { daysToGo, _startDate, _endDate });
  if (daysToGo >= 2) return includeDaysText ? `${daysToGo} days` : daysToGo;
  if (daysToGo === 1) return includeDaysText ? `${daysToGo} day` : daysToGo;

  if (daysToGo === 0) return msgReached;

  return msgPast;
};

export const debugServices = (services) => {
  const _debugServices = async (services, parentKey) => {
    // console.log("debugServices", services);
    if (!services) return;
    if (!_.isObject(services)) return;

    Object.entries(services)
      // .sort(([, a], [, b]) => _.isFunction(b) < _.isFunction(a))
      // .sort((k1, k2) => {
      //   console.log(k1, k2);
      //   return k2 < k1;
      // })
      .forEach(async ([key, v]) => {
        const fnLog = (data) => {
          console.groupCollapsed("ENDPOINT:", fullKey);
          console.log(data);
          console.groupEnd();
        };

        // console.log("ddddd", key, v);
        const fullKey = [parentKey, key].filter((x) => x).join(".");

        // An ARRAY is an object, so put this first
        if (_.isArray(v)) {
          fnLog(v);
          return;
        }

        // A FUNCTION is an object, so put this first
        if (_.isFunction(v)) {
          try {
            const data = await v();
            fnLog(data);
          } catch (e) {
            console.error("ENDPOINT:", "Error in ", fullKey);
            throw new Error(e);
          }

          return;
        }

        // RECURSIVE CALL
        if (_.isObject(v)) {
          _debugServices(v, fullKey);
          return;
        }

        console.log("DDDDDD", key, v);
      });
  };

  _debugServices(services);
};

export const retryFunction = async (fn, retries = 0) => {
  if (retries <= 0) throw `Error in retryFunction -- enter retries >=1`;

  const fnPrivateRetry = async (fn, retries) => {
    try {
      const response = await fn();
      return { response: response, isSuccess: true };
    } catch (err) {
      if (retries > 0) return fnPrivateRetry(fn, retries - 1);
      return { isSuccess: false, err: err };
    }
  };

  return await fnPrivateRetry(fn, retries);
};

export const format = {
  integer: (v) => {
    return v.toLocaleString(
      undefined, // use the client's browser
      { minimumFractionDigits: 0 }
    );
  },
};
