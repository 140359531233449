import _ from "lodash";
import { useSelector } from "react-redux";
import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";

import {
  Routes,
  Route,
  Link,
  Outlet,
  useNavigate,
  useLocation,
  Navigate,
  matchRoutes,
  matchPath,
  useParams,
} from "react-router-dom";

const generate = (args = {}) => {
  const { privateActions, selectors } = args;

  // ******************************************************************************
  // RETURN OBJECT
  // ******************************************************************************
  return {};
};

export default generate;
