export default {
	//titleGender: require(`./functions/titleGender`).default,
	startDateLogic: require(`./functions/startDateLogic`).default,
	riskLogic: require(`./functions/riskLogic`).default,

	personLogic: require(`./functions/personLogic`).default,

	secondaryOccupationLogic: require(`./functions/secondaryOccupationLogic`)
		.default,

	occupationLogic: require(`./functions/occupationLogic`).default,

	licenceLogic: require(`./functions/licenceLogic`).default,

	convictionLogic: require(`./functions/convictionLogic`).default,

	convictionMonthBanLogic: require(`./functions/convictionMonthBanLogic`)
		.default,

	additionalDriverLogic: require(`./functions/additionalDriverLogic`).default,
	proposerLogic: require(`./functions/proposerLogic`).default,

	vehicleLogic: require(`./functions/vehicleLogic`).default,
	nameLogic: require(`./functions/nameLogic`).default,

	paymentMethodLogic: require(`./functions/paymentMethodLogic`).default,

	policyDetailsLogic: require(`./functions/policyDetailsLogic`).default,
	ncbLogic: require(`./functions/ncbLogic`).default,
	ncbOtherVehicleLogic: require(`./functions/ncbOtherVehicleLogic`).default,

	modificationLogic: require(`./functions/modificationLogic`).default,

	vehicleUseLogic: require(`./functions/vehicleUseLogic`).default,
	vehicleDetailsLogic: require(`./functions/vehicleDetailsLogic`).default,
	vehicleBusinessMileageLogic:
		require(`./functions/vehicleBusinessMileageLogic`).default,

	classOfUseLogic: require(`./functions/classOfUseLogic`).default,

	emailLogic: require(`./functions/emailLogic`).default,
	//	...require("./functions/JOI").default,
};
