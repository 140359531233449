import React from "react";

import { Panel, CardBorder } from "siteComponents";

const Feature = (props) => {
    const { icon, text } = props;

    const iconClass = ["icon", icon].toClassName();

    return (
        <div className="d-flex align-items-center feature">
            <div className="flex-shrink-0">
                <span className={iconClass}></span>
            </div>
            <div className="text">
                {text}
            </div>
        </div>
    );
}

const Content = () => {

    return (
        <>
            <div >
                My Account with Stella makes managing your policy quick and easy.
            </div>

            <Feature icon="icon-document-download" text="View or download your documents" />
            <Feature icon="icon-file-upload" text="Upload a document" />
            <Feature icon="icon-manual-information-file" text="View your policy details" />
            <Feature icon="icon-policytype-car2" text="Amend your policy" />
            <Feature icon="icon-calendar-sync" text="Renew your policy" />
            <Feature icon="icon-manage-account" text="Manage your account" />

            <div >
                We'll be adding more features soon!
            </div>
        </>
    );
}

const SidePanel = (props) => {

    return (
        <Panel header="&nbsp;" className="ps-xl-3" data-cy="side-content">
            <CardBorder
                className="bg-buttermilk side-content"
            >
                <Content />
            </CardBorder>
        </Panel>
    );

}

export default SidePanel;
