import React, { useEffect, useState } from "react";
import services from "siteService";
import {
  Outlet,
  Link,
  NavLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { selectors, actions } from "siteStore";
import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";

import { DevContainer, JsonViewer, Button, ConfirmModal } from "siteComponents";

import Base from "./Base";
import _ from "lodash";

const PageRenewalLayout = (props) => {
  const userInfo =
    useSelector(selectors.dashboard.getUserInfo, require("lodash").isEqual) ||
    {};
  const isLoggedIn = useSelector(
    selectors.auth.isLoggedIn,
    require("lodash").isEqual
  );

  if (!isLoggedIn) return null;

  const { children, className, ...otherProps } = props;

  return (
    <Base
      {...otherProps}
      className={["layout-page-renewal", className].toClassName()}
    >
      {children}

      <DevContainer heading="userInfo" enabled={false}>
        {userInfo && <JsonViewer>{userInfo}</JsonViewer>}
      </DevContainer>
    </Base>
  );
};

export default PageRenewalLayout;
