import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import {
  useDispatch as _useDispatch,
  useSelector as _useSelector,
} from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import { database as migrateDatabase } from "./utils/migration";
import templateUtils from "./utils/template";
import riskUtils from "./utils/risk";

const generate = (args = {}) => {
  const { actions, selectors, template } = args;

  return {
    searchPath: riskUtils.searchPath,
    migration: {
      database: migrateDatabase,
    },
    debug: () => {
      console.groupCollapsed("DEBUG QUOTEANDBUY");
      console.log(args);
      console.groupEnd();
    },
    composeComponent: (Component, options = {}) => {
      if (!Component) throw `Error in composeComponent -- missing Component`;

      const {
        useDispatch = _useDispatch,
        useSelector = _useSelector,
        templatePropsPath = undefined,
        fnModifyValueInput = (v) => v,
        fnModifyValueOutput = (v) => v,
      } = options;

      // ***************************************
      // ** SELECTOR (MEMOISED)
      // ***************************************
      const metaDataGenerate = (state, { path, pathList, debugLabel }) => {
        // if (debugLabel) console.log("DDDDDDDDDDDDDDD MEMO");
        if (path) {
          const retData = selectors.userData.risk.metaData(state, path);
          return retData;
        }

        if (pathList.length >= 1) {
          const metaDataArray = pathList.map((p) => {
            return {
              path: p,
              data: selectors.userData.risk.metaData(state, p),
            };
          });
          //NOTE: x and x.data could be empty
          return {
            hidden: metaDataArray.some((x) => x && x.data && x.data.hidden),
            errorShow: metaDataArray.some(
              (x) => x && x.data && x.data.errorShow
            ),
            error: Object.fromEntries(
              metaDataArray
                .filter((x) => x && x.data && x.data.errorShow)
                .flatMap((x) =>
                  Object.entries(x.data.error).map(([k, d]) => [
                    `${x.path} -- ${k}`,
                    d,
                  ])
                )
            ),
            value: undefined,
          };
        }
      };
      // #3046 -- createSelector() not needed in the end
      const metaDataMemoSelector = createSelector(
        [metaDataGenerate],
        (metaData) => metaData
      );

      // ***************************************
      // ** RETURN COMPONENT
      // ***************************************

      const RetComponent = (props) => {
        const {
          onChange,
          value,
          hidden,
          error,
          showDebug = false,

          [templatePropsPath]: dummyRemoval,
          children,
          debugLabel,
          id,
          onBlur = async () => {},
          ...otherProps
        } = props;
        const dispatch = useDispatch();

        const templateProps = templatePropsPath
          ? _.get(props, templatePropsPath)
          : props;

        if (!templateProps) {
          console.log("ERROR INFO:", { props, templatePropsPath });
          throw `Error in composeComponent -- can't find templateProps`;
        }
        const {
          path,
          pathList = [],
          group,
          label: labelOverride,
          helpText: helpTextOverride,
          helpTextFurther: helpTextFurtherOverride,
          ...otherTemplateProps
        } = templateProps;

        const [_newId] = useState(
          (function () {
            if (id) return id;
            if (!path) return undefined;
            const itemName = path
              .split("/")
              .find((x, i, arr) => i === arr.length - 1);
            return ["component", itemName, _.uniqueId()].join("_");
          })()
        );
        // NOTE: We can allow for path and pathList to be blank

        const templateData = (function () {
          if (path) {
            if (!_.isString(path)) {
              console.log("ERROR INFO:", { path });
              throw `Error in composeComponent -- path is not a string`;
            }
            return templateUtils.getData(template, path.split("/"))?.data;
          }

          if (pathList.length >= 1) {
            return {
              label: labelOverride,
              helpText: helpTextOverride,
              helpTextFurther: helpTextFurtherOverride,
            };
          }

          return {
            label: labelOverride,
            helpText: helpTextOverride,
            helpTextFurther: helpTextFurtherOverride,
          };
        })();

        // #3046 https://nuvolar.eu/connecting-functional-components-to-redux-store-with-useselector-hook-react-memo/
        const metaData = useSelector(
          (state) => metaDataGenerate(state, { path, pathList, debugLabel }),
          _.isEqual
        );

        const debugData = (function () {
          if (!showDebug) return undefined;
          return metaData;
        })();

        // REGISTER GROUPS
        React.useEffect(() => {
          if (!group) return;

          if (path)
            dispatch(actions.groupRegister({ path: path, group: group }));

          pathList.forEach((path) =>
            actions.groupRegister({ path: path, group: group })
          );
        }, []);

        if (debugLabel) {
          console.log("COMPOSE:", debugLabel, {
            path,
            pathList,
            group,
            props,
            otherProps,
            otherTemplateProps,
            metaData,
            templateData,
            composeOptions: options,
          });
        }

        return (
          <Component
            {...templateData?.html}
            {...otherProps}
            {...otherTemplateProps}
            onChange={async (v) => {
              if (!path) return;

              await dispatch(
                actions.updateValue({
                  path: path,
                  value: fnModifyValueOutput(v),
                })
              );
              await dispatch(
                actions.updateErrorShow({ path: path, value: true })
              );
            }}
            onBlur={async (...args) => {
              if (!path) return;

              await dispatch(
                actions.updateErrorShow({ path: path, value: true })
              );
              await onBlur(...args);
            }}
            value={metaData && fnModifyValueInput(metaData.value)}
            hidden={metaData && metaData.hidden}
            error={metaData && metaData.error}
            errorShow={metaData && metaData.errorShow}
            debugData={debugData}
            label={labelOverride || templateData?.label}
            helpText={helpTextOverride || templateData?.helpText}
            helpTextFurther={
              helpTextFurtherOverride || templateData?.helpTextFurther
            }
            id={_newId}
          >
            {children}
          </Component>
        );
      };
      RetComponent.displayName = Component.displayName;

      return RetComponent;
    },
    compareSalus: async ({
      store,
      riskStore,
      quoteAndBuyStore,
      salusData,
      fnCompare = () => {
        throw `Not implemented compareSalus().fnCompare`;
      },
    }) => {
      // Compares old salus again new
      const fnRiskGetSalus = async (state, outputComponentSetKey = false) => {
        const riskStoreState = state[riskStore.storeName];

        const rawData = _.pick(riskStoreState, [
          "componentSet",
          "componentSet_reverseMapping",
          "componentSetOwnership",
          "componentSetOwnership_reverseMapping",
          "componentTag",
          "componentTag_reverseMapping",
          "value",
          "registered",
        ]);

        if (!rawData) return;
        if (_.isEmpty(rawData)) return;
        if (_.isEmpty(rawData.value)) return;

        const salusData = riskStore.functions.salus.reduxToSalus(rawData, {
          outputComponentSetKey: outputComponentSetKey
            ? "componentSet"
            : undefined,
        });
        if (!salusData) return undefined;
        return salusData.data;
      };

      const fnQuoteAndBuyGetSalus = async (state) => {
        const retData =
          await quoteAndBuyStore.selectors.userData.risk.salusData(state);

        return retData;
      };

      // function getDifference(a, b) {
      //   // return undefined;
      //   const isObject = (v) => v && typeof v === "object";

      //   return Object.assign(
      //     ...Array.from(
      //       new Set([...Object.keys(a), ...Object.keys(b)]),
      //       (k) => ({
      //         [k]:
      //           isObject(a[k]) && isObject(b[k])
      //             ? getDifference(a[k], b[k])
      //             : a[k] === b[k],
      //       })
      //     )
      //   );
      // }

      //**RESET */
      await new Promise((resolve) =>
        store.dispatch(riskStore.actions.reset({}, () => resolve()))
      );
      await store.dispatch(quoteAndBuyStore.actions.reset());

      // return { salusData, riskState, quoteAndBuyState, differences: {} };
      //** LOAD DATA */
      await new Promise((resolve) =>
        store.dispatch(riskStore.actions.loadRisk(salusData, () => resolve()))
      ); //** "loadRisk()" is what's called on recall, so it DOESN'T treat items as updated upon load.

      await store.dispatch(
        quoteAndBuyStore.actions.salusLoad({ salusData: salusData })
      );

      // console.log("dddddddddd", {
      //   state,
      //   salusData,
      //   riskState,
      //   quoteAndBuyState,
      // });
      // throw `hhhhh`;
      //** GET DATA */
      const state = store.getState(); // DO this here to get the latest state

      const riskState = _.cloneDeep(await fnRiskGetSalus(state));
      const quoteAndBuyState = _.cloneDeep(await fnQuoteAndBuyGetSalus(state));

      // console.log("dddddddddd", {
      //   state,
      //   riskState,
      //   quoteAndBuyState,
      // });
      // throw `hhh`
      const differences = fnCompare(riskState, quoteAndBuyState);
      const quoteAndBuyStateErrorList = quoteAndBuyStore.selectors.errors.list(
        state,
        "Risk"
      );

      //**RESET */
      await new Promise((resolve) =>
        store.dispatch(riskStore.actions.reset({}, () => resolve()))
      );
      await store.dispatch(quoteAndBuyStore.actions.reset());

      //** RETURN */
      return {
        salusData,
        riskState,
        quoteAndBuyState,
        quoteAndBuyStateErrorList,
        differences,
      };
    },
  };
};

export default generate;
