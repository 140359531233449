import { isEmpty } from "@library/common/database/rules/helpers";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

export default (args) => {
	const { dataSet, functionArgs } = args;

	if (!dataSet) throw `Error -- missing dataSet`;
	if (!functionArgs) throw `Error -- missing functionArgs`;
	if (!functionArgs.length) throw `Error -- missing length`;
	const testValue =
		dataSet.getValue(
			new dataSetArgItem({
				useDefaultComponentSet: true,
				useDefaultComponentTag: true,
			}),
			"value"
		) || "";
	const isValid = testValue.length >= functionArgs.length;

	//	console.log("length", testValue, testValue.length, functionArgs.length, isValid);

	dataSet.setValue(
		new dataSetArgItem({
			key: "errors",
			value: {
				errorMessage: isValid ? undefined : functionArgs.errorMessage,
				errorKey: functionArgs.errorKey,
			},

			useDefaultComponentSet: true,
			useDefaultComponentTag: true,
		})
	);

	return null;
};
