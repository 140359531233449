import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

const generate = (args = {}) => {
  const { storeName, storageActions, storageSelectors } = args;

  return {};
};

export default generate;
