import dayjs from "dayjs";
import functions from "../../../general";
import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

const { dateComparison } = functions;

export default (args) => {
	throw `No longer in use`;
	const { dataSet, functionArgs } = args;
	const dh = new dataHelper({
		mappings: ["PaymentMethodInitial", "PaymentMethod"],
		dataSet,
		functionArgs,
	});

	if (dh.isPostRecall()) {
		dh.setValue("PaymentMethodInitial", dh.getValue("PaymentMethod"));
	}

	if (!dh.isPostRecall() && dh.isUpdated("PaymentMethodInitial")) {
		dh.setValue("PaymentMethod", dh.getValue("PaymentMethodInitial"));
	}

	// console.log("paymentMethodLogic:");
	dh.commit();
};
