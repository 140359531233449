import React from "react";
import PriceDisplay from "../Components/PriceDisplay";
import getPriceDetails from "../getPriceDetails";
import Divider from "../Components/Divider";
export const InstalmentDetailsText = (props) => {
  const { instalmentDetails, className } = props;

  if (instalmentDetails.HasVariedPayment) {
    if (instalmentDetails.VariedRepaymentStatus === "Next") {
      return (
        <>
          <span className={className}>
            1 payment of{" "}
            <strong>
              <PriceDisplay value={instalmentDetails.VariedPaymentAmount} />
            </strong>
          </span>

          <span className={className}>
            <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong>{" "}
            payments of{" "}
            <strong>
              <PriceDisplay value={instalmentDetails.InstalmentAmount} />
            </strong>
          </span>
        </>
      );
    }

    if (instalmentDetails.VariedRepaymentStatus === "Last") {
      return (
        <>
          <span className={className}>
            <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong>{" "}
            payments of{" "}
            <strong>
              <PriceDisplay value={instalmentDetails.InstalmentAmount} />
            </strong>
          </span>
          <span className={className}>
            a final payment of{" "}
            <strong>
              <PriceDisplay value={instalmentDetails.VariedPaymentAmount} />
            </strong>
          </span>
        </>
      );
    }
  }

  return (
    <>
      <span className={className}>
        <strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong> payments
        of{" "}
        <strong>
          <PriceDisplay value={instalmentDetails.InstalmentAmount} />
        </strong>
      </span>
    </>
  );
};

// ***************************************************
// ** MAIN
// ***************************************************

const TierItemMonthly = (props) => {
  const {
    data,
    classNamePound,
    classNamePence,
    classNamePostFix,
    showDivider = true,
  } = props;

  if (!data) return null;
  if (!data.HasInstalments) return null;
  if (!data.Instalments) return null;

  const instalmentDetails = data.Instalments[0];
  const priceDetails = getPriceDetails({
    instalmentDetails: instalmentDetails,
    isInstalments: true,
    info: "TierItemMonthly.jsx",
  });

  return (
    <div className="lctr-tieritem-monthly monthly card h-md-100 h-lg-auto border-0 bg-transparent">
      <div className="card-header text-center bg-transparent border-0 pb-1">
        {showDivider && <Divider />}
        {priceDetails.mainPriceText({
          classNamePound,
          classNamePence,
          classNamePostFix,
        })}
      </div>

      <div className="card-body price-info">
        {priceDetails.depositPriceText() &&
          priceDetails.depositPriceText().map((t, i) => (
            <span className="pb-1 d-block" key={i}>
              {t}
            </span>
          ))}
      </div>
    </div>
  );
};

export default TierItemMonthly;
