import { list, set, item } from "@library/common/database/classes";
import _ from "lodash";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";

import constants from "@library/common/constants/database";
import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import generateBaseRuleData from "../../../generateBaseRuleData";
import Name from "./Name";
import Occupation from "./Occupation";
import Licence from "./Licence";
import Claim from "./Claim";
import DateOfBirth from "./DateOfBirth";
import Address from "./Address";
import Telephone from "./Telephone";

import regExConstants from "@library/common/constants/regEx";
import templatePerson from "./Person";

const generateVehicleUseRule = (args = {}) => {
  const {
    ComponentTagVehicleUse,
    AdditionalInsuredSet,
    MainUserComponentId,
    ProposerVehicleUse,
    VehicleUseCurrent,
  } = args;

  return new ruleItem(
    {
      ...generateBaseRuleData("vehicle", "vehicleUseLogic"),
    },
    {
      ComponentTagVehicleUse,
      AdditionalInsuredSet,
      MainUserComponentId,
      ProposerVehicleUse,
      ...(VehicleUseCurrent ? { VehicleUseCurrent } : {}),
    }
  );
};

// modifyItem: [
// 	{
// 		path: "Address",
// 		componentTag: "Postcode",
// 		key: "rules",
// 		data: templates.Vehicle.logicRule,
// 	},
// ],

export default {
  generateVehicleUseRule,
  generateList: (args = {}, itemArgs = {}) => {
    const {
      ruleItemAdditionalDriverLogic,
      defaultLicenceCategoryValue,
      isMta: isMta = false,
    } = itemArgs;

    const {
      template: argTemplate = {},
      modifyItem: argModifyItems = [],
      ...argOther
    } = args;

    if (!ruleItemAdditionalDriverLogic) {
      console.error("args:", args);
      throw `Error in PersonAddDriver -- missing ruleItemAdditionalDriverLogic`;
    }
    const listProps = {
      template: {
        ...templatePerson.generateKeys(
          {},
          {
            msgDateOfBirth:
              "They must be 17 on or before the policy start date.",
            defaultLicenceCategoryValue,
            defaultEmailAddress: undefined,
            isMta: isMta,
          }
        ),
        Id: new item({
          submitKey: "Id",
        }),
        RelationshipToProposer: new item({
          // componentTag:  "RelationshipToProposer",
          submitKey: "RelationshipToProposer",
          exampleValue: "Proposer",
          rules: [
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "",
                // errorMessage: "Please enter the relationship.",
              }
            ),
          ],
        }),
        DriverType: new item({
          submitKey: "DriverType",
          defaultValue: "NmdDrvr",
          rules: [
            new ruleItem(
              {
                ...generateBaseRuleData("general", "isRequired"),
              },
              {
                errorMessage: "", // errorMessage: "Required",
              }
            ),
          ],
        }),
        ...argTemplate,
      },
      modifyItem: [
        {
          componentTag: "RelationshipToProposer",
          key: "rules",
          data: ruleItemAdditionalDriverLogic,
        },
        {
          componentTag: "MaritalStatus",
          key: "rules",
          data: ruleItemAdditionalDriverLogic,
        },
        {
          componentTag: "DateOfBirth",
          key: "rules",
          data: ruleItemAdditionalDriverLogic,
        },
        {
          componentTag: "DateOfBirth",
          key: "label",
          data: "What's their date of birth?",
        },
        {
          // path: "Name",
          componentTag: "IsResidentSinceBirth",
          key: "label",
          data: "Have they been a UK resident since birth?",
        },
        {
          componentTag: "ResidentSince",
          key: "label",
          data: "When did they become a UK resident?",
        },
        {
          path: "FullTimeOccupation",
          componentTag: "EmpCode",
          key: "label",
          data: "Industry they work in",
        },
        {
          path: "PartTimeOccupation",

          componentTag: "EmpCode",
          key: "label",
          data: "Secondary Industry they work in",
        },

        {
          componentTag: "HasSecondOccupation",
          key: "label",
          data: "Do they have a second occupation?",
        },

        {
          componentTag: "AccessToOtherVehicles",
          key: "label",
          data: "Do they own or use any other vehicle other than the one to be insured?",
        },
        {
          componentTag: "InsuranceRefused",
          key: "label",
          data: "Have they ever had insurance declined, cancelled or special terms imposed?",
        },
        {
          componentTag: "NonRTAConviction",
          key: "label",
          data: "Do they have any unspent non-motoring criminal convictions?",
        },
        {
          path: "Licence",
          componentTag: "Date",
          key: "label",
          data: "When did they obtain their full UK licence?",
        },
        {
          path: "Licence/Convictions",
          componentTag: "MonthBanQuestion",
          key: "label",
          data: "Did they receive a ban?",
        },
        {
          path: "Licence/Convictions",
          componentTag: "MonthBan",
          key: "label",
          data: "How many months were they banned for?",
        },
        {
          path: "Claims",
          componentTag: "NcbPrejudiced",
          key: "label",
          data: "Were they at fault for the incident?",
        },
        //
        ...argModifyItems,
      ],
      ...argOther,
    };
    /*

Claims

Did this claim occur on your van insurance policy?


*/
    // const modifyLabel = (obj) => {
    // 	const keyValues = Object.entries(obj).map(([key, data]) => {
    // 		if (key === "label") {
    // 			return {
    // 				[key]: "x" + data,
    // 			};
    // 		}
    // 		if (!_.isObject(data) || _.isArray(data))
    // 			return {
    // 				[key]: data,
    // 			};

    // 		return {
    // 			[key]: modifyLabel(data),
    // 		};
    // 	});
    // 	return Object.assign({}, ...keyValues);
    // };
    // const newListProps = modifyLabel(listProps);

    const retList = new list(listProps);

    // console.log("retList", retList);
    // throw `hhh`;

    return retList;
  },
};
