// import _ from "lodash";

// export const importData = (data) => {
//   if (!data) return undefined;

//   const newData = _.cloneDeep(data);

//   newData.Policies = newData.Policies.map((x, i) => ({
//     ...x,
//   }));

//   return newData;
// };

// export const exportData = (data = {}) => {
//   return data;
// };

// const fileItem = class {
//   constructor(data) {
//     this.data = data;
//     this.status = "START";
//     this.errorMsg = undefined;
//     this._uploadType = undefined;
//   }

//   reset() {
//     this.status = "START";
//     this.errorMsg = undefined;
//   }
//   setUploading() {
//     this.status = "UPLOADING";
//   }
//   setComplete() {
//     this.status = "COMPLETE";
//   }
//   setError(msg) {
//     this.status = "ERROR";
//     this.errorMsg = msg;
//   }

//   setUploadType(t) {
//     this._uploadType = t;
//   }

//   // set uploadType(v) {
//   //   this._uploadType = v;
//   // }
//   get uploadType() {
//     return this._uploadType;
//   }

//   get isStart() {
//     return this.status === "START";
//   }
//   get isUploading() {
//     return this.status === "UPLOADING";
//   }
//   get isComplete() {
//     return this.status === "COMPLETE";
//   }
//   get isError() {
//     return this.status === "ERROR";
//   }
//   get errorMessage() {
//     if (this.status !== "ERROR") return undefined;
//     return this.errorMsg;
//   }
// };

// export default { fileItem };

export default {};
