import React from "react";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
} from "react-router-dom";

// import DocumentsList from "./SubViews/List";
// import Upload from "./SubViews/UploadOnly";

const DocumentsList = React.lazy(() =>
  import("siteViews/Documents/SubViews/List")
);
const Upload = React.lazy(() =>
  import("siteViews/Documents/SubViews/UploadOnly")
);
// const DownloadWindow = React.lazy(() =>
//   import("siteViews/Documents/SubViews/DownloadWindow")
// );

// export const routesBasicSecure = [
//   {
//     path: "documents",
//     element: (
//       // <React.Suspense fallback={<Loading />}>
//       <Outlet />
//       // </React.Suspense>
//     ),
//     children: [
//       {
//         path: "downloadwindowsecure",
//         element: <DownloadWindow />,
//       },
//     ],
//   },
// ];


// export const routesBasic = [
//   {
//     path: "documents",
//     element: (
//       // <React.Suspense fallback={<Loading />}>
//       <Outlet />
//       // </React.Suspense>
//     ),
//     children: [
//       {
//         path: "downloadwindow",
//         element: <DownloadWindow />,
//       },
//     ],
//   },
// ];

const routes = [
  {
    path: "documents",
    element: (
      // <React.Suspense fallback={<Loading />}>
      <Outlet />
      // </React.Suspense>
    ),
    children: [
      {
        path: "",
        element: <DocumentsList routeExit="../" />,
      },
    ],
  },

  {
    path: "upload",
    element: (
      // <React.Suspense fallback={<Loading />}>
      <Outlet />
      // </React.Suspense>
    ),
    children: [
      {
        path: "",
        element: <Upload routeExit="../" />,
      },
    ],
  },
];

export default routes;
