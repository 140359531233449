import React, { useState, useEffect } from "react";

const useStatus = (initValue) => {
  const constants = {
    init: "init",
    ok: "okay",
    error: "error",
    refreshing: "refreshing",
  };

  const [value, setValue] = useState(initValue);
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();

  const [status, setStatus] = useState(constants.init);

  const statusData = {
    value: value,
    errorMsg: errorMsg,
    successMsg: successMsg,
    isInit: status === constants.init,
    isOk: status === constants.ok,
    isError: status === constants.error,
    isRefreshing: status === constants.refreshing,
  };

  const controlSet = {
    clear: () => setValue(undefined),
    setValue: (v) => setValue(v),
    setErrorMsg: (v) => setErrorMsg(v),
    clearErrorMsg: () => setErrorMsg(undefined),
    setInit: () => setStatus(constants.init),
    setError: (msg) => {
      setStatus(constants.error);
      if (msg) setErrorMsg(msg);
    },
    setOk: (msg) => {
      setStatus(constants.ok);
      if (msg) setSuccessMsg(msg);
    },
    setRefreshing: () => setStatus(constants.refreshing),
  };

  return [statusData, controlSet];
};

export default useStatus;
