export const generateKey = (status, checkvalue, comparator, message) => {
  const isMatch = comparator(status, checkvalue);

  if (!isMatch) return false;
  if (message) return message;
  return true;
};

export const generateKeys = (status, message, hitlist = []) => {
  return Object.fromEntries(
    hitlist.map(({ key, value, comparator = (a, b) => a === b }) => [
      key,
      generateKey(status, value, comparator, message),
    ])
  );
};
