// features/Documents/DocumentsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import chalk from "chalk";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  createExtraReducersSet,
  extraReducersSet,
  consoleLog,
} from "../common";

const generate = (args = {}) => {
  const { storeName, privateActions, abortChecker } = args;

  const initialState = {
    documents: {},
    documentToPolicyMapping: {},
    isRefreshing: false,
    isRehydrating: false,
    feedbackErrorMsg: undefined,
    // downloadStatus: {},
    // uploadFileStatus: {},
  };

  const _slice = createSlice({
    name: storeName,
    initialState,
    reducers: {
      // downloadSetStatus(state, action) {
      //   const { documentId, status } = action.payload || {};

      //   if (!documentId)
      //     throw `Error in downloadSetStatus -- missing documentId`;
      //   if (!status) throw `Error in downloadSetStatus -- missing status`;

      //   state.downloadStatus[documentId] = status;
      // },
      reset() {
        consoleLog({ storeName, subActionName: "reset" });
        return initialState;
      },
      importDocuments(state, action) {
        state.documents = action.payload;
      },
      uploadStatusReset(state, action) {
        state.uploadFileStatus = {};
      },
      updateIsViewed(state, action) {
        const { documentId, policyId } = action.payload || {};

        if (!documentId) throw `Error in updateIsViewed -- missing documentId`;
        if (!policyId) throw `Error in updateIsViewed -- missing policyId`;

        const foundPolicy = state.documents[policyId];
        if (!foundPolicy)
          throw `Error in updateIsViewed -- can't find policy ${policyId}`;

        const foundDocument = foundPolicy.find(
          (x) => x.DocumentId === documentId
        );
        if (!foundDocument)
          throw `Error in updateIsViewed -- can't find policy ${foundDocument}`;

        foundDocument.IsViewed = true;
      },
    },
    extraReducers: (builder) => {
      extraReducersSet(
        builder,
        privateActions.upload,
        "upload",
        {
          pending: (state, action) => {
            const { files = [] } = action.meta.arg;

            state.uploadFileStatus = Object.fromEntries(
              files.map((x, i) => {
                return [
                  i,
                  {
                    isUploading: false,
                    isUploaded: false,
                    errorMsg: undefined,
                  },
                ];
              })
            );
          },
          fulfilled: (state, action) => {
            const { payload } = action;
            // const { policyId } = action.meta.arg;
            // state.uploadFileStatus = undefined;
          },
          rejected: (state, data) => {
            // state.dummy = undefined;
          },
        },
        { storeName, abortChecker }
      );

      extraReducersSet(
        builder,
        privateActions.uploadStatusUpdate,
        "uploadStatusUpdate",
        {
          pending: (state, action) => {},
          fulfilled: (state, action) => {
            const { payload } = action;
            const { id, isUploading, isUploaded, errorMsg } = payload;

            if (!(id in state.uploadFileStatus))
              throw `Error -- can't find ${id} in uploadFileStatus`;

            if (isUploading) {
              state.uploadFileStatus[id] = {
                isUploading: true,
                isUploaded: false,
                errorMsg: undefined,
              };
              return;
            }

            if (isUploaded) {
              state.uploadFileStatus[id] = {
                isUploading: false,
                isUploaded: true,
                errorMsg: undefined,
              };
              return;
            }

            if (errorMsg) {
              state.uploadFileStatus[id] = {
                isUploading: false,
                isUploaded: false,
                errorMsg: errorMsg,
              };
              return;
            }
          },
          rejected: (state, data) => {
            // state.dummy = undefined;
          },
        },
        { storeName, abortChecker }
      );

      extraReducersSet(
        builder,
        privateActions.getPolicyDocuments,
        "getPolicyDocuments",
        {
          pending: (state, action) => {
            const { policyNumber } = action.meta.arg;
            state.isRefreshing = true;

            if (policyNumber in state.documents)
              delete state.documents[policyNumber];

            state.feedbackErrorMsg = undefined;
          },
          fulfilled: (state, action) => {
            const { payload } = action;
            const { policyId } = action.meta.arg;

            if (!policyId) throw `policyId missing`;

            state.isRefreshing = false;
            state.documents[policyId] = payload.documents;
            state.feedbackErrorMsg = payload.message;
          },
          rejected: (state, data) => {
            state.isRefreshing = false;
            state.feedbackErrorMsg = data.message;
          },
        },
        { storeName, abortChecker }
      );
      extraReducersSet(
        builder,
        privateActions.rehydratePolicyDocuments,
        "rehydratePolicyDocuments",
        {
          pending: (state, action) => {
            state.feedbackErrorMsg = undefined;
            state.isRehydrating = true;
          },
          fulfilled: (state, action) => {
            const { payload } = action;
            const { policyId } = action.meta.arg;

            if (!policyId) throw `policyId missing`;

            state.documents[policyId] = payload.documents;
            state.feedbackErrorMsg = payload.message;
            state.isRehydrating = false;
          },
          rejected: (state, data) => {
            state.feedbackErrorMsg = data.message;
            state.isRehydrating = false;
          },
        },
        { storeName, abortChecker }
      );
      // setDocumentDownloaded
      // extraReducersSet(builder,
      //   privateActions.setDocumentDownloaded,
      //   "setDocumentDownloaded",
      //   {
      //     pending: (state, action) => {
      //       state.feedbackErrorMsg = undefined;
      //     },
      //     fulfilled: (state, { payload }) => {
      //       state.feedbackErrorMsg = payload.message;
      //       //todo: find the policyNumber???
      //       // state.documents = state.documents.map((x) => {
      //       //   if (x.DocumentId === documentId) {
      //       //     return { ...x, IsViewed: isSuccess };
      //       //   }
      //       //   return x;
      //       // });
      //     },
      //     rejected: (state, data) => {
      //       // state.feedbackErrorMsg = "Oops. Something has gone wrong."
      //     },
      //     // throwError: true,
      //   },
      //   { storeName, abortChecker }
      // ),
    },
  });

  return { reducer: _slice.reducer, actions: _slice.actions };
};

export default generate;
