import React from "react";

import { TelephoneNumber } from "siteComponents";
import { generateCypressProps } from "siteFunctions/cypress";

export const ContactDetails = (props) => {
  return (
    <div
      className="contact-details"
      {...generateCypressProps("contact-details", props)}
    >
      <i className="bi bi-telephone" />
      <TelephoneNumber
        phonenumber={require("config").default.lookups.telephoneNumbers.sales}
        className="ps-1"
        isLink={true}
        isStrong={false}
      />
    </div>
  );

  return (
    <div
      className="contact-details"
      {...generateCypressProps("contact-details", props)}
    >
      <div className="text-nowrap text-end fw-bold">
        <span className="icon icon-telephone-fill" />
        <TelephoneNumber
          phonenumber={require("config").default.lookups.telephoneNumbers.sales}
          className="ps-1"
          isLink={true}
          isStrong={false}
        />
      </div>
      <div className="text-nowrap text-end fs-80">Mon - Fri: 9:00 - 17:30</div>
      <div className="text-nowrap text-end fs-80">Sat: 9:00 - 13:00</div>
    </div>
  );
};

export default ContactDetails;
