// import freedomConfigureStore from "@library/store/configureStore";
// import { Auth } from "aws-amplify";

import {
  configureStore,
  createListenerMiddleware,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import createSagaMiddleWare from "redux-saga";
const listenerMiddleware = createListenerMiddleware();

import _ from "lodash";
import siteConfig from "config";
// import { createMtaStore } from "@library/store";
import chalk from "chalk";
import styles from "ansi-styles";
// import { createMtaData } from "../_moveToLibrary/redux/mta";
import createDocumentData from "moveToLibrary/redux/documents";
import createAuth from "moveToLibrary/redux/auth";
import createSession from "moveToLibrary/redux/session";
import createSnapshot from "moveToLibrary/redux/snapshot";
import createDashboard from "moveToLibrary/redux/dashboard";
import createStorage from "moveToLibrary/redux/storage";
import createRenewal from "moveToLibrary/redux/renewals";
import applyCommonListeners from "moveToLibrary/redux/applyCommonListeners";
import { storeListeners as analyticsStoreListeners } from "siteManagers/analytics";

const debug = siteConfig.isDev;

// **********************************************
// STORES
// **********************************************
const abortChecker = (r) => r === "ABORT";

const storage = createStorage({
  storeName: "storage",
  abortChecker,
});

const auth = createAuth({
  storeName: "auth",
  services: require("./storeServices/auth").default,
  debug,
  abortChecker,
});
const documents = createDocumentData({
  storeName: "documents",
  services: require("./storeServices/documents").default,
  debug,
  abortChecker,
  // selectors: { auth: auth.selectors },
});

const dashboard = createDashboard({
  storeName: "dashboard",
  services: require("./storeServices/dashboard").default,
  debug,
  abortChecker,
});

const renewal = createRenewal({
  storeName: "renewal",
  services: require("./storeServices/renewal").default,
  debug,
  abortChecker,
});

const session = createSession({
  storeName: "session",
  excludeKeys: [],
  services: require("./storeServices/session").default,
  debug,
  abortChecker,
});

// import {
//   // mtaRiskCar,
//   // carRiskGetProps,
//   mta as mtaStore,
//   //  carRiskDatabase
// } from "./mta";

export const { mtaCar, mtaRiskCar, quoteAndBuyCar } = require("./mta");

export const stores = {
  mta: { car: { main: mtaCar, risk: mtaRiskCar } },
  renewal: renewal,
};

export const snapshotMtaCar = createSnapshot({
  storeName: "snapshotMtaCar",
  watchlist: [
    // "mtaRiskCar", //#QUOTEANDBUY
    "quoteAndBuyCar",
  ],
  debug,
  abortChecker,
});
// **********************************************
// MTA
// **********************************************

// console.log("mtaRiskCar", mtaRiskCar);
// throw `TEST`;
// **********************************************
// SAGA
// **********************************************

const sagaMiddleware = createSagaMiddleWare({
  onError: (err) => {
    setTimeout(() => {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/Error
      throw new Error(err, { cause: "SAGA" });
    }, 0);
  },
});

// **********************************************
// Final EXPORTS
// **********************************************
// console.log("mtaCar",mtaCar);
// throw "mtaCar"
const storeHitlist = [
  session,
  documents,
  auth,
  dashboard,
  renewal,
  storage,
  mtaCar,
  mtaRiskCar,
  // mta,
  snapshotMtaCar,
  quoteAndBuyCar,
];

storeHitlist.forEach((k, i) => {
  if (!k) {
    throw `Error in store/storeData -- "index: ${i} is missing"`;
  }
});

const fnGenerateObj = (hitlist, key) => {
  try {
    return Object.fromEntries(
      hitlist
        .filter((data) => data[key])
        .map((data) => [data.storeName, data[key]])
    );
  } catch (e) {
    console.warn("ERROR DUMP:", { hitlist, key });
    throw e;
  }
};
// export const database = {
//   getProps: {
//     mtaRiskCar: carRiskGetProps,
//   },
//   database: {
//     mtaRiskCar: carRiskDatabase,
//   },
// };

// export const riskStores = {
//   mtaRiskCar,
// };

export const selectors = fnGenerateObj(storeHitlist, "selectors");
export const components = fnGenerateObj(storeHitlist, "components");
export const actions = fnGenerateObj(storeHitlist, "actions");
export const hooks = fnGenerateObj(storeHitlist, "hooks");
export const classes = fnGenerateObj(storeHitlist, "classes");
export const listeners = fnGenerateObj(storeHitlist, "listeners");
export const reducers = {
  ...fnGenerateObj(storeHitlist, "reducer"),
  ...fnGenerateObj(storeHitlist, "reducers"), // legacy stores have "reducers" not "recuder"
};
export const helpers = {
  ...fnGenerateObj(storeHitlist, "helpers"),
  ...fnGenerateObj(storeHitlist, "functions"),
};

// const fnResetAll = async () => {
// //   if (stores.documents) await dispatch(stores.documents.actions.reset());
// //   if (stores.dashboard) await dispatch(stores.dashboard.actions.reset());
// //   if (stores.storage) await dispatch(stores.storage.actions.reset());
// //   // if (stores.mta) await dispatch(stores.mta.actions.reset());

// //   if (stores.session) await dispatch(stores.session.actions.reset());

// //   if (stores.session) await dispatch(stores.session.actions.save());

// };

const enhancers = storeHitlist
  .filter((data) => data.enhancer)
  .map((data) => data.enhancer);

const store = configureStore({
  devTools: siteConfig.isDev,
  reducer: reducers,
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(enhancers),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(sagaMiddleware)
      .concat(listenerMiddleware.middleware),
});

storeHitlist.filter((x) => x.saga).forEach((x) => sagaMiddleware.run(x.saga));

// https://blog.logrocket.com/redux-toolkits-new-listener-middleware-vs-redux-saga/

// **************************************************
// LISTENERS
// *************************************************
applyCommonListeners(
  listenerMiddleware,
  {
    auth,
    dashboard,
    renewal,
    documents,
    session,
    storage,
    mtaCar,
  },
  {
    consoleLog: (...args) => {
      console.log(chalk.blue.bold("LISTENER:"), ...args);
    },
    onLogout: async (dispatch) => {
      console.log("*********************************************");
      console.log("** onLogout **");
      console.log("*********************************************");

      await dispatch(actions.documents.reset());
      await dispatch(actions.dashboard.reset());
      await dispatch(actions.storage.reset());
      await dispatch(actions.mtaCar.reset());
      await dispatch(actions.renewal.reset());

      await dispatch(actions.session.regenerate()); // Added RE:#3235
      await dispatch(actions.session.save());

      // await dispatch(actions.session.reset());
      // await dispatch(actions.session.init());
      // await dispatch(actions.session.save());
    },
  }
);

analyticsStoreListeners(listenerMiddleware, {
  auth,
  dashboard,
  renewal,
  documents,
  session,
  storage,
  mta: [mtaCar],
});

mtaCar.applyListeners(listenerMiddleware);

// NOTE: Can't use eval or toDebug() here
// console.groupCollapsed("storeData");

// ({
//   selectors,
//   components,
//   actions,
//   helpers,
//   listeners,
//   hooks,
// }).toDebug();

// console.groupEnd();
export default store;
