import React, { useEffect } from "react";
import Base from "./Base";
import { useLocation } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import {
  DevContainer,
  JsonViewer,
  ButtonStyled,
  ButtonLink,
  PanelRenewal,
  TelephoneService,
} from "siteComponents";

const RenewalFeedbackView = (props) => {
  const location = useLocation();
  const feedbackType = props.feedbackType || location?.state?.feedbackType;

  const commonData = useOutletContext();

  // return <div>xxxx{feedbackType} </div>
  // **********************************************
  // EFFECTS
  // **********************************************
  useEffect(() => {
    const myFn = async () => {
      switch (feedbackType) {
        case "refused":
          break;

        default: {
          await commonData.functions.process.reset();
          await commonData.functions.session.save();
        }
      }
    };

    myFn();
  }, [feedbackType]);

  // **********************************************
  // VARS
  // **********************************************
  const [headerText, toolbarText, displayData] = (function () {
    switch (feedbackType) {
      case "refused":
        return [
          "We're sorry",
          undefined,
          <PanelRenewal>
            <div>Your payment has not been authorised.</div>
            <div className="mt-3">
              You can
              <ButtonLink
                onClick={commonData.functions.navigate.start}
                className="px-1"
              >
                <strong>try another payment</strong>
              </ButtonLink>
              or, alternatively, call us on{" "}
              <strong>
                <TelephoneService />
              </strong>{" "}
              and one of our agents will be happy to help.
            </div>
            <div className="mt-3">
              <ButtonStyled onClick={commonData.functions.navigate.exit}>
                Return home
              </ButtonStyled>
            </div>
          </PanelRenewal>,
        ];

      case "paymenterror":
      case "timeout":
      case "error":
      case "referred":
      case "declined":
        // case "unknown":
        return [
          <>We're sorry!</>,
          "technical error",
          <PanelRenewal>
            <p>There's been a problem processing your payment.</p>
            <p>
              Please call us on{" "}
              <strong>
                <TelephoneService />
              </strong>{" "}
              and one of our agents will be happy to help.
            </p>

            {["paymenterror", "timeout", "error"].includes(feedbackType) && (
              <div className="alert alert-danger text-center mt-3">
                <strong>
                  Please do not attempt to make the payment again.
                </strong>
              </div>
            )}

            <div className="mt-5">
              <ButtonStyled onClick={commonData.functions.navigate.exit}>
                Return home
              </ButtonStyled>
            </div>
          </PanelRenewal>,
        ];
    }

    throw `Error in Renewal FeedbackView -- unknown type "${feedbackType}"`;
  })();

  // Do we RESET the RENEWAL?
  useEffect(() => {
    const myFn = async () => {
      switch (feedbackType) {
        case "paymenterror":
        case "purchaseRepsonseError":
        case "timeout":
        case "referred":
        case "declined": {
          await commonData.functions.process.reset();
          await commonData.functions.session.save();
          break;
        }
      }
    };

    myFn();
  }, []);

  return (
    <Base
      pageId="FEEDBACK"
      headingText={headerText || "Oops!"}
      toolbarText={toolbarText}
    >
      {displayData}
    </Base>
  );
};

export default RenewalFeedbackView;
