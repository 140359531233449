// features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import JSONhelper from "@library/common/helpers/misc/json";
import {
  createFnGenerateAction,
  createFnGenerateActionWithThunkAPI,
} from "../common";

const generateStore = (args = {}) => {
  const { storeName, services, sessionSelectors } = args;

  const fnGenerateAction = createFnGenerateAction(storeName);
  const fnGenerateActionWithThunkApi =
    createFnGenerateActionWithThunkAPI(storeName);

  const init = fnGenerateAction("init", services.init);
  const get = fnGenerateAction("get", services.get);

  const save = fnGenerateActionWithThunkApi(
    "save",
    async (payload, thunkApi) => {
      const state = thunkApi.getState();
      const sessionId = sessionSelectors.getSessionId(state);
      const newPayload = { data: JSONhelper.stringify(state), sessionId };
      return await services.save(newPayload);
    }
  );

  const reset = fnGenerateActionWithThunkApi(
    "reset",
    async (payload, thunkApi) => {
      const state = thunkApi.getState();
      const sessionId = sessionSelectors.getSessionId(state);
      const newPayload = { sessionId };
      return await services.delete(newPayload);
    }
  );

  const regenerate = fnGenerateActionWithThunkApi(
    "regenerate",
    async (payload, thunkApi) => {
      const state = thunkApi.getState();
      const sessionId = sessionSelectors.getSessionId(state);
      const newPayload = { sessionId };

      await services.delete(newPayload);

      const newDataResponse = await services.init();

      return newDataResponse;
    }
  );

  const initAndGet = (params) => async (dispatch) => {
    await dispatch(init(params));
    await dispatch(get(params));
  };

  return { init, get, save, reset, regenerate, initAndGet };
};

export default generateStore;
