import React from "react";

const fnFormatCurrency = (v) =>
  new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(v);

const IptContent = (props) => {
  const { resultType, fee } = props;

  let iptContent = "";
  let iptFee = "";

  switch (resultType) {
    case "AP":
    case "RP": {
      iptContent = <span>All premiums are</span>;
      iptFee = <span><strong>{fnFormatCurrency(fee)}</strong> non-refundable adjustment fee</span>;
      break;
    }

    case "NIL": {
      iptContent = <span>This quote is</span>;
      iptFee = <span><strong>{fnFormatCurrency(fee)}</strong> non-refundable adjustment fee</span>;
      break;
    }

    case "RNL": {
      iptContent = <span>All premiums are</span>;
      iptFee = <span>non-refundable renewal fee of <strong>{fnFormatCurrency(fee)}</strong></span>;
      break;
    }

    default: {
      return null;
    }
  }

  return (
    <small data-cy="ipt-statement">
      {iptContent} inclusive of Insurance Premium Tax (IPT) at the current rate
      and a {iptFee}.
    </small>
  );
};

export const IptHeader = (props) => {
  const { resultType, fee, allowOnlineWrapup } = props;

  if (allowOnlineWrapup) {
    switch (resultType) {
      case "NIL": {
        break;
      }
      default: {
        return null;
      }
    }
  }

  return <IptContent resultType={resultType} fee={fee} />;
};

const IptPanel = (props) => {
  const {
    // curQuote,
    resultType,
    fee,
  } = props;

  //   if (curQuote) {
  //     return (
  //       <div className="ipt-statement">
  //         <div className="alert alert-secondary py-2 px-md-5 text-center">
  //           <IptContent resultType={resultType} fee={fee} />
  //         </div>
  //       </div>
  //     );
  //   }

  return (
    <div className="ipt-statement">
      <div className="alert alert-secondary py-2 px-md-5 text-center">
        <IptContent resultType={resultType} fee={fee} />
      </div>
    </div>
  );
};

export default IptPanel;
