import React from "react";

import Base from "./Base";
import _logo from "siteAssets/images/stella-logo-colour.svg";
import _logoNegative from "siteAssets/images/stella-logo-negative.svg";

import Header from "./Templates/Header";

const SiteLayout = (props) => {
  return <Base {...props} headerData={<Header />} />;
};

export default SiteLayout;
