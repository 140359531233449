import React, { useState, createContext, useContext, useReducer } from "react";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { ButtonStyled } from "../../Buttons";

const FullScreenContext = createContext();

// const [todos, dispatch] = useReducer(todosReducer);

const FullscreenComponent = (props) => {
  const { children } = props;

  const [Component, setComponent] = useState(undefined);

  // console.log("FullscreenComponent", Component);

  const controls = {
    set: (C) => setComponent(C),
    clear: () => setComponent(undefined),
  };

  // if (Component) return Component;

  return (
    <FullScreenContext.Provider value={controls}>
      {Component}
      {children}
    </FullScreenContext.Provider>
  );
};

export default { Component: FullscreenComponent, context: FullScreenContext };

// const style = {
//   position: "fixed",
//   height: "100%",
//   width: "100%",
//   left: 0,
//   top: 0,
//   backgroundColor: "white",
//   zIndex: 1000,
// };

// const FullscreenComponent = (props) => {
//   const { label, children } = props;

//   const [isFullScreen, setIsFullScreen] = useState(false);

//   return <span>{children}</span>;
//   if (!label) throw `Error in Fullscreen -- missing label `;

//   const handle = useFullScreenHandle();

//   if (isFullScreen) return <div style={style}>{children}</div>;

//   return (
//     <div>
//       <ButtonStyled
//         onClick={() => {
//           //   handle.enter();
//           setIsFullScreen(true);
//         }}
//       >
//         {label}
//       </ButtonStyled>

//       {/* <FullScreen handle={handle}>aaa{children}</FullScreen> */}
//     </div>
//   );
// };
