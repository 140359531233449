import React from "react";
import * as siteComponents from "siteComponents";

export default (args = {}) => {
  const baseRetData = require("./baseComponents").default(args);
  const generators = require("./generators").default({
    ...args,
    baseComponents: baseRetData,
    siteComponents: siteComponents,
  });

  return {
    ...baseRetData,

    generateOccupation: generators.generateOccupation,
    generateAddress: generators.generateAddress,
    generateVehicle: generators.generateVehicle,
  };
};
