import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import ButtonLabel from "@material-ui/icons/Search";
import _ from "lodash";

import { tidyPostcode } from "@library/common/helpers/misc/postcode";
import regExConstants from "@library/common/constants/regEx";

export default (args = {}) => {
  const {
    database,
    selectors,
    actions,
    siteComponents,
    storeComponentsSite,
    storeComponentsCustom,
    classes,
  } = args;

  const { TextBox, Button, ButtonStyledMta } = storeComponentsSite;
  // const { Button } = require("../../../getResources").default(args, "Address");

  const DefaultSearchTemplate = (props) => {
    [TextBox, Button].forEach((x, i) => {
      if (!x)
        throw `Error -- missing required component in storeComponentsSite in Address Search at index ${i}`;
    });

    return (
      <div className="row">
        <div className="col-10 col-md-5 pb-2">
          <TextBox
            id={props.IDHouseNumber}
            value={props.searchHouseNumber}
            onType={(value) => props.setSearchHouseNumber(value)}
            placeholder="House name or number"
            maxLength={props.houseNumberMaxLength}
          />
        </div>

        <div className="col-6 col-md-3 pb-2 mb-3">
          <TextBox
            id={props.IDPostcode}
            value={props.searchPostcode}
            onType={(value) => {
              const newValue =
                value !== undefined ? value.toUpperCase() : undefined;
              props.setSearchPostcode(newValue);
            }}
            onChange={(value) =>
              props.setSearchPostcode(value ? value.toUpperCase() : undefined)
            }
            placeholder="Postcode"
            maxLength={props.postcodeMaxLength}
          />
        </div>

        <div className="col-12">
          {props.isSearching && <props.PleaseWait />}
          {!props.isSearching && (
            <ButtonStyledMta
              type="button"
              className="btn btn-primary"
              label={
                <span>
                  <ButtonLabel />
                  Find My Address
                </span>
              }
              onClick={() => {
                if (
                  props.searchPostcode !== undefined &&
                  props.searchHouseNumber !== undefined &&
                  props.searchHouseNumber.length >=
                    props.houseNumberMinLength &&
                  props.searchPostcode.length >= props.postcodeMinLength
                ) {
                  props.setIsSearching(true);

                  const newSearchPostcode = tidyPostcode(props.searchPostcode);

                  props
                    .getData(props.searchHouseNumber, newSearchPostcode)
                    .then((response) => {
                      props.onClick(newSearchPostcode, response);
                      // props.setIsSearching(false);
                    });
                } else {
                  // Invalid - show validation message
                }
              }}
            />
          )}
        </div>
      </div>
    );
  };

  //** MAIN component
  return (props) => {
    const {
      pathList,
      onClick,
      forceShowErrors,
      heading,
      searchService,
      houseNumberMinLength = 1,
      postcodeMinLength = 3,
      houseNumberMaxLength = 30,
      postcodeMaxLength = 8,
      PleaseWait,
      SearchTemplate = DefaultSearchTemplate,
      ...otherProps
    } = props;

    const getData = useCallback((housenumber, postcode) => {
      return searchService({ housenumber, postcode });
    }, []);

    const [searchPostcode, setSearchPostcode] = useState();
    const [searchHouseNumber, setSearchHouseNumber] = useState();
    const [isSearching, setIsSearching] = useState(false);

    const isValidPostcode =
      searchPostcode !== "" &&
      searchPostcode !== undefined &&
      // regExConstants.postcode.ukPostcode.test(searchPostcode) &&
      searchPostcode.length >= postcodeMinLength &&
      searchPostcode.length <= postcodeMaxLength;

    const isValidHouse =
      searchHouseNumber !== "" &&
      searchHouseNumber !== undefined &&
      searchHouseNumber.length >= houseNumberMinLength &&
      searchHouseNumber.length <= houseNumberMaxLength;

    const IDHouseNumber = useCallback(_.uniqueId(`Address`), []);
    const IDPostcode = useCallback(_.uniqueId(`Address`), []);

    //** TESTING  */
    // useEffect(() => {
    // 	setSearchPostcode("ST4 7EG");
    // 	setSearchHouseNumber("50" + "x".repeat(50));
    // }, []);

    const searchTemplateProps = {
      pathList,
      onClick,
      forceShowErrors,
      heading,
      searchService,
      houseNumberMinLength,
      postcodeMinLength,
      houseNumberMaxLength,
      postcodeMaxLength,
      PleaseWait,
      getData,
      searchPostcode,
      setSearchPostcode,
      searchHouseNumber,
      setSearchHouseNumber,
      isSearching,
      setIsSearching,
      IDHouseNumber,
      IDPostcode,
      isValidPostcode,
      isValidHouse,
    };
    return <SearchTemplate {...otherProps} {...searchTemplateProps} />;
  };
};
