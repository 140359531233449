import _ from "lodash";

const generate = (args = {}) => {
  const { storeName, sessionActions, excludeKeys = [] } = args;

  return (createStore) => (reducer, initialState, enhancer) => {
    const sessionReducer = (state = initialState, action) => {
      const { payload = {} } = action;

      switch (action.type) {
        case `${storeName}/init/fulfilled`:
        case `${storeName}/get/fulfilled`: {
          if (!payload.data) return reducer(state, action);

          const replaceList = Object.keys(payload.data).filter(
            (x) => ![...excludeKeys, storeName].includes(x)
          );

          const retState = _.merge(
            // Keep the keys in the "excludeKeys" and "session store"
            _.pick(state, [...excludeKeys, storeName]),
            // Merge in everything else
            _.pick(payload.data, replaceList)
          );

          return reducer(retState, action);
        }

        default:
          return reducer(state, action);
      }
    };

    return createStore(sessionReducer, initialState, enhancer);
  };
};

export default generate;
