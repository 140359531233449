import dayjs from "dayjs";

import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";
import regExConstants from "@library/common/constants/regEx";

import * as constants from "@library/common/constants/salus";

import * as allStores from "siteStore";

const validateEmail = (v) => {
  if (v === undefined) return false;
  if (v === "") return false;
  return regExConstants.misc.email.test(v);
};

// const debugManager = require("siteManagers/debug").default;

export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper").default,
  } = args;

  // console.log("classOfUseLogic");

  // require("@library/common/helpers/misc/argChecker")(functionArgs, [
  // 	"AdditionalInsuredSet",
  // 	"AdditionalInsuredSet_RelationshipToProposer_TAG",
  // ]);

  //   require("@library/common/helpers/misc/argChecker")(functionArgs, [
  //     "storeName",
  //   ]);

  const dh = new dataHelper({
    logging: false,
    mappings: ["EmailConfirmed", "Email"],
    dataSet,
    functionArgs,
  });

  // console.log(
  // 	"EmailLogic",
  // 	dh.getValue("Email"),
  // 	dh.getValue("EmailConfirmed"),
  // 	dh,
  // 	dh.getState(),
  // 	functionArgs
  // );

  const isAgg = (function () {
    // TODO: make this neater
    // EXIT if NOT Q&B store
    if (!allStores) return;
    if (!allStores[functionArgs.storeName]) return;

    const quoteStore = allStores[functionArgs.storeName].quote;
    const getQuoteSource = quoteStore.selectors.makeGetQuoteSource();
    const curState = dh.getState();

    const quoteSource = getQuoteSource(curState) || {};
    const { IsAgg: isAgg = false } = quoteSource;
    return isAgg;
  })();

  // const isAgg = true;
  // console.log("EmailLogic", curState, getQuoteSource(curState));

  // debugManager.addToLog(
  // 	{ isAgg, isPostRecall: dh.isPostRecall(), quoteSource },
  // 	"emailLogic"
  // );

  // ** RECALL **
  if (dh.isPostRecall()) {
    // console.log("ddddd", quoteSource);
    // alert(JSON.stringify({ isAgg }));

    // debugManager.addToLog(
    // 	{ isAgg, isPostRecall: dh.isPostRecall(), quoteSource },
    // 	"emailLogic Post Recall"
    // );
    if (!isAgg) {
      dh.cloneValue("Email", "EmailConfirmed");
    }
  }

  // ** DIRECT **
  if (!dh.isPostRecall()) {
    if (dh.isUpdated("EmailConfirmed")) {
      dh.cloneValue("EmailConfirmed", "Email");
    }
  }

  if (!validateEmail(dh.getValue("Email"))) {
    dh.addError("Email", "Please enter a valid email");
  }

  dh.commit();

  // console.log("ddddd", dh);

  return;

  // if !

  // if (dh.isPostRecall()) {
  // 	dh.setValue("ClassOfUseFrontEnd", dh.getValue("ClassOfUse"));
  // 	dh.commit();
  // 	return;
  // }

  // const addDriverTree =
  // 	dh.dataSet.getTree({
  // 		argItem: functionArgs.AdditionalInsuredSet,
  // 		componentTagHitlist: [
  // 			functionArgs.AdditionalInsuredSet_RelationshipToProposer_TAG,
  // 		],
  // 	}) || [];

  // const hasSpouse = addDriverTree.some((x) =>
  // 	["SPOUSEREL", "COMSPOUSE", "CivPrtnr"].includes(
  // 		x.data.RelationshipToProposer.value
  // 	)
  // );

  // //** sync the ClassOfUse Value
  // const _ClassOfUse = (function () {
  // 	const _ClassOfUseFrontEnd = dh.getValue("ClassOfUseFrontEnd");

  // 	if (hasSpouse && _ClassOfUseFrontEnd === "C1") return "JC1";

  // 	return _ClassOfUseFrontEnd;
  // })();

  // dh.setValue("ClassOfUse", _ClassOfUse);

  // // console.log("classOfUseLogic", "_ClassOfUse", _ClassOfUse, dh);

  // dh.commit();
};
