import generateSlice from "./slice";
import generateActions from "./actions";
import generateSelectors from "./selectors";
import generateHooks from "./hooks";

const generateStore = (args = {}) => {
  const { storeName, services } = args;

  const fnThrowError = (msg, _storeName = storeName) => {
    throw ["Error in generateStore", _storeName, "-", msg]
      .filter((x) => x)
      .join(" ");
  };

  if (!storeName) fnThrowError("missing storeName");
  if (!services) fnThrowError("missing riskStoreConfig");

  // Check the services
  ["getRenewalData", "paymentLauncher"].forEach((k, i) => {
    if (!(k in services)) fnThrowError(`missing ${k} in config item no: ${i}`);
  });

  const _selectors = generateSelectors(args);
  const _actions = generateActions({ ...args, selectors: _selectors });
  const _slice = generateSlice({
    ...args,
    privateActions: _actions,
  });

  const hooks = generateHooks({
    ...args,
    privateActions: { ..._actions, ..._slice.actions },
    selectors: _selectors,
  });

  const retData = {
    storeName,
    reducer: _slice.reducer,
    actions: { ..._actions, ..._slice.actions },
    selectors: _selectors,
    hooks,
  };

  console.log("STORE", storeName, "=", retData);
  return retData;
};

export default generateStore;
