import React from "react";

import { RouterProvider } from "react-router-dom";

import routes, { routeData } from "./masterRoutes";

console.log("routeData", routeData);

// TODO:
// const generateManifest = (data) => {
//   const retData = {};
//   return retData;
// };

// export const manifest = generateManifest(routeData);

const Routes = () => {
  //   throw `Error in <Routes/>`;
  return <RouterProvider router={routes} />;
};

export default Routes;
