import postProcessAxiosFunctions from "@library/configs/site/configItem/postProcessAxiosFunctions";
import _ from "lodash";
import merge from "deepmerge";
import { useDispatch } from "react-redux";
// import { createServiceObj } from "@library/common/helpers/service";

import { createService as libraryCreateService } from "moveToLibrary/services";

export const createService = (args) => {
  // console.log("createService", args);
  // return;
  // const [mainArg, ...otherArgs] = args;
  return libraryCreateService({
    ...args,
    //, debounce: true
  });
};

export const createServiceSessionId = (args) => {
  const fnBase = createService(args);
  return (payload) => {
    const { store, selectors } = require("siteStore");
    const state = store.getState();
    const sessionId = selectors.session.getSessionId(state);

    return fnBase(payload, { headers: { ["x-session-id"]: sessionId } });
  };
};

export const generateLink = (url) => {
  if (!url) throw `Error in generateLink -- missing "url"`;

  return (payload) => {
    const finalUrl = [url, payload].filter((x) => x).join("/");
    return finalUrl;
  };
};

export const createServiceSecure = (args) => {
  const fnBase = createService(args);

  return async (payload) => {
    const { store, selectors, actions } = require("siteStore");

    const fnGetStateData = () => {
      const state = store.getState();
      const userToken = selectors.auth.getUserToken(state);
      const userId = selectors.dashboard.getUserId(state);
      const sessionId = selectors.session.getSessionId(state);

      return { userToken, userId, sessionId };
    };

    const { userToken, userId, sessionId } = fnGetStateData();

    if (!userToken)
      throw new Error("Error in createServiceSecure -- missing userToken");

    const response = await fnBase(payload, {
      headers: {
        Authorization: `Bearer ${userToken}`,
        ["x-user-id"]: userId,
        ["x-session-id"]: sessionId,
      },
    });

    // userToken change check
    const { userToken: postUserToken } = fnGetStateData();

    if (!_.isEqual(userToken, postUserToken)) {
      // console.log("USERCHECK", { userToken, postUserToken });
      return "ABORT";
    }

    // INVALID_TOKEN check
    if (
      response &&
      _.isObject(response) &&
      response.Status === "INVALID_TOKEN"
    ) {
      await store.dispatch(actions.auth.logout());
      return undefined;
    }

    return response;
  };
};

export const generateSet = (hitlist, options = {}) => {
  const { defaultFunction, endPointSet } = options;

  ["defaultFunction", "endPointSet"].forEach((k) => {
    if (!(k in options)) throw `Error in generateSet -- missing ${k}`;
  });

  return Object.fromEntries(
    hitlist.map(
      ({
        key,
        path,
        func = defaultFunction,
        data,
        method = "POST",
        postProcessAxios,
        requiredPayload = [],
        description,
        cacheKey,
        fnModifyUrl,
      }) => {
        return [
          key,
          func({
            axiosOptions: {
              headers: {
                "x-api-key": endPointSet.apiKey,
              },
              url: [endPointSet.url, path].filter((x) => x).join(""),
              data,
              method,
            },
            requiredPayload: requiredPayload,
            postProcessAxios: postProcessAxios,
            description: description || key,
            cacheKey: cacheKey,
            fnModifyUrl: fnModifyUrl,
          }),
        ];
      }
    )
  );
};

// ******************************************
// CLASSES
// ******************************************

export class EndPointSet {
  constructor(args = {}) {
    this.data = {};
    Object.entries(args).forEach(([k, data]) => {
      this.data[k] = new endPointItem(data);
    });
  }

  isRegistered(keyList = []) {
    const _keyList = !_.isArray(keyList) ? [keyList] : keyList;

    return _keyList.every((k) => k in this.data);
  }
  getItem(key) {
    if (!(key in this.data)) throw `Error in getItem -- can't find ${key}`;
    return this.data[key];
  }
}
export class endPointItem {
  constructor(args = {}) {
    this.data = {};

    ["url", "apiKey"].forEach((k) => {
      if (k in args) this.data[k] = args[k];
    });
  }

  // Getter
  get url() {
    if (!("url" in this.data)) throw `Error in endPointItem -- can't find url`;
    return this.data.url;
  }

  get apiKey() {
    if (!("apiKey" in this.data))
      throw `Error in endPointItem -- can't find apiKey`;
    return this.data.apiKey;
  }
}
