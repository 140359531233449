// RegisterScreen.js
import React, { useEffect, useState, useMemo, useCallback } from "react";
import PageLayout from "siteLayouts/Page/Split";

import _ from "lodash";
import { useNavigate } from "react-router-dom";

import { useForm, Controller, useController } from "react-hook-form";
import { NavLink, Outlet } from "react-router-dom";

import AutoQuoteAndBuy from "../AutoQuoteAndBuy";

import {
  TextBoxForm,
  DatePickerTextBox,
  PasswordTextBoxForm,
  DropDownForm,
  Loading,
  DevContainer,
  JsonViewer,
  Panel,
  PanelForm,
  Button,
  ButtonLink,
  ButtonStyled,
  ButtonSubmit,
  Feedback,
  Alert,
  Error,
} from "siteComponents";

// ***********************************************
// COMPONENTS
// ***********************************************

import FormItem, { FormItemTemplates } from "../../../common/FormItem";
import SidePanel from "../common/SidePanel";

// ***********************************************
//  MAIN COMPONENT
// ***********************************************
const AuthenticateBaseView = (props) => {
  const {
    routeRegister,
    routeForgotUsername,
    routeForgotPassword,
    // submitAction, // may be changed by <Activate />
    onSubmit,
    header,
    buttonText,
    blurbData,
    isLoading = false,
    FeedbackComponent = () => null,
    headingText,
    descriptionContent,
    panelcy = "authenticate",
    showSidePanel = false,
  } = props;

  if (!onSubmit) throw `Error in AuthenticateBaseView -- missing onSubmit`;

  // ***********************************************
  //  VARIABLES
  // ***********************************************
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: {
      isSubmitted,
      isValid,
      errors, // NOTE: For some reason, this needs to be here to that getFieldState() works in each form item
      // todo: fix the above
    },
    getFieldState,
    formState,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldFocusError: false,
  });

  const showFormError = isSubmitted && !_.isEmpty(errors);
  const isFormDisabled = false; //isLoading;

  // ***********************************************
  //  DEBUG
  // ***********************************************
  console.groupCollapsed("Authenticate FORM");
  //   console.log("getValues", getValues());
  console.log("errors", errors);
  console.groupEnd();

  // ***********************************************
  // FUNCTIONS
  // ***********************************************
  const fnOnSubmit = (data) => {
    if (isLoading) return;

    onSubmit(data);
  };

  // ***********************************************
  // FINAL RETURN
  // ***********************************************

  const formItemBaseProps = {
    getFieldState,
    formState,
    control,
    disabled: isFormDisabled,
    className: "row",
    classNameLabel: "col-12",
    classNameControl: "col-12",
    classNameError: "has-error",
    classNameValid: "is-validated",
  };

  // console.log("Props: ", props);
  //  throw `ChunkLoadError`
  return (
    <PageLayout
      headingText={headingText}
      descriptionContent={descriptionContent}
      data-cy="page-layout-authenticate"
      sideContent={showSidePanel && <SidePanel />}
    >
      <PanelForm
        className="auth-form-authenticate"
        header={header}
        data-cy={`auth-panel-${panelcy}`}
      >
        {blurbData}

        <form noValidate onSubmit={handleSubmit(fnOnSubmit)} className="m-0">
          <FormItem
            {...formItemBaseProps}
            className={routeForgotUsername ? "with-link" : ""}
            label="Your username"
            name="username"
            Component={TextBoxForm}
            rules={{
              required: true,
            }}
            lengthMin={6}
          />

          {routeForgotUsername && (
            <div className="row">
              <div className="col-12 redirect">
                <NavLink
                  to={routeForgotUsername}
                  data-cy="goto-forgot-username"
                  className="link-black"
                >
                  Need a reminder?
                </NavLink>
              </div>
            </div>
          )}

          <FormItem
            {...formItemBaseProps}
            className={routeForgotPassword ? "with-link" : ""}
            label="Your password"
            name="password"
            Component={PasswordTextBoxForm}
            rules={{
              required: true,
            }}
          />

          {routeForgotPassword && (
            <div className="row">
              <div className="col-12 redirect">
                <NavLink
                  to={routeForgotPassword}
                  data-cy="goto-forgot-password"
                  className="link-black"
                >
                  Forgotten your password?
                </NavLink>
              </div>
            </div>
          )}

          <ButtonSubmit isLoading={isLoading} className="w-100">
            {buttonText}
          </ButtonSubmit>

          <FeedbackComponent formError={showFormError} />

          <div className="row">
            <div className="col-12 pt-3">
              <a
                className="link-black"
                data-bs-toggle="collapse"
                href="#loginhelp"
                role="button"
                aria-expanded="false"
                aria-controls="loginhelp"
              >
                Need help logging in?
              </a>
              <div className="collapse" id="loginhelp">
                <div className="help-text text-purple form-text mt-2">
                  Please ensure when filling in your username and password that
                  you don't accidentally enter any leading or trailing spaces.
                  This can easily happen, especially if you copy and paste this
                  information. If you're still having problems, call us on{" "}
                  {require("config").default.lookups.telephoneNumbers.service}.
                </div>
              </div>
            </div>
          </div>
        </form>
      </PanelForm>

      {routeRegister && (
        <div className="row py-3">
          <div className="col-12">
            Don't have an account yet?{" "}
            <NavLink
              to={routeRegister}
              data-cy="goto-register"
              className="link-black fw-bold"
            >
              Create one
            </NavLink>
            .
          </div>
        </div>
      )}

      <DevContainer enabled={true}>
        {[
          [
            ["username", "mockuser_1692012097654"],
            ["password", "Password_!66472"],
          ],
          [
            ["username", "VTurner12"],
            ["password", "Testing123!"],
          ],
          [
            ["username", "VTurner49"],
            ["password", "Testing123!"],
            "has 2 additional drivers each driver has a claims and conviction each",
          ],
          [
            ["username", "VTurner12"],
            ["password", "Testing123!"],
            "Renewals testing",
          ],
          [
            ["username", "PolicyQuoteOnly"],
            ["password", "Testing123!"],
          ],
        ].map((data, i) => {
          return (
            <p key={i}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setValue(data[0][0], data[0][1]);
                  setValue(data[1][0], data[1][1]);
                  trigger();
                }}
              >
                {data[0][1]}{" "}
                {data[2] && (
                  <>
                    <br />({data[2]})
                  </>
                )}
              </button>
            </p>
          );
        })}

        {/* {(function () {
            const data = [
              // ["webReference", "69604-93310-59386"],
              ["username", "mockuser_1681308050318"],
              ["password", "Password_!30609"],
            ];
            return (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  data.forEach(([k, v]) => setValue(k, v));
                  trigger();
                }}
              >
                Login: ({data.map((x) => x[1]).join(",")})
              </button>
            );
          })()} */}
      </DevContainer>

      <AutoQuoteAndBuy trigger={trigger} setValue={setValue} />
    </PageLayout>
  );
};

export default AuthenticateBaseView;
