import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import _ from "lodash";
import PageRenewalLayout from "siteLayouts/Page/Renewal";
// import useBlockers from "../common/useBlockers";
import { useScrollToTop } from "moveToLibrary/hooks/useScrollTo";
import { useOutletContext, useLocation, Navigate } from "react-router-dom";

import { PanelRenewal, Error } from "siteComponents";

import SessionRefreshing from "../../common/SessionRefreshing";
import PleaseWait from "../resources/components/PleaseWait";

const Wrapper = (props) => {
  return (
    <PageRenewalLayout {...props}>
      {props.toolbarText && <Helmet>{props.toolbarText}</Helmet>}
      {props.children}
    </PageRenewalLayout>
  );
};

const useBlockers = (args = {}) => {
  //   console.log("dadadadadad", args);
  const { pageId } = args;

  const location = useLocation();
  const commonData = useOutletContext();
  const {
    functions,
    meta: { routes, renewalConfig, isInit },
  } = commonData;

  // console.log("commonData", commonData)
  // ** function **
  const fnGenerateRedirect = (_targetRoute = "..", info) => {
    const targetRoute = _targetRoute || "..";
    functions.console.log("Redirecting to:", targetRoute, info);
    console.log("---", { pageId, commonData });
    return <Navigate to={targetRoute} state={location.state} replace />;
  };

  // ** selectors **
  const hasConfig = renewalConfig?.policyNumber ? true : false;

  // ** final RETURNS **
  if (!hasConfig) {
    return fnGenerateRedirect(routes.exit, "Missing config");
  }

  if (!isInit) {
    switch (pageId) {
      case "WRAPUP":
      case "COMPLETE":
      case "FEEDBACK":
      case "FEEDBACKSPM":
        break;
      default:
        return fnGenerateRedirect(routes.start, "NOT isInit");
    }
  }

  return undefined;
};

const RenewalBase = (props) => {
  const {
    children,
    isLoading = false,
    isLoadingMessage = undefined,
    isCompleting = false,
    isCompletingMessage = undefined,
    className,
    headingText = "",
    pageId,
    showSessionSaving = true,
    ...otherProps
  } = props;

  const commonData = useOutletContext();

  const processingData = (function () {
    // return {
    //   isProcessing: true,
    //   message: "dd",
    // };

    if (isLoading)
      return {
        isProcessing: true,
        message: isLoadingMessage,
      };

    if (isCompleting)
      return {
        isProcessing: true,
        message: isCompletingMessage,
      };

    if (showSessionSaving && commonData.session.isSaving)
      return {
        isProcessing: true,
        message: commonData.session.message,
      };

    if (commonData.status.paymentLauncher.init)
      return {
        isProcessing: true,
        message: "Please wait a moment while we complete your changes.",
      };
    return {};
  })();

  const redirectObject = useBlockers({
    pageId,
  });

  //****************************************
  // PAYMENTLAUNCHER HANDLER
  //****************************************

  //** Payment Launcher **
  useEffect(() => {
    // if (processingData.isProcessing) return;
    if (_.isEmpty(commonData.values.paymentLauncher)) return;

    console.log("dsdsds", commonData.values.paymentLauncher);
    if (!commonData.values.paymentLauncher.WrapupComplete) return;

    if (commonData.values.paymentLauncher.RedirectUrl) {
      commonData.functions.navigate.loadUrl(
        commonData.values.paymentLauncher.RedirectUrl
      );
    }
  }, [_.isEmpty(commonData.values.paymentLauncher)]);

  //****************************************
  // VARS
  //****************************************
  const baseProps = {
    snapToTop: true,
    headingText: headingText,
    ["data-cy"]: "layout-page-renewal",
    className: [className].toClassName(),
    ...otherProps,
  };

  // if (statusData_start?.errorMessage)
  //   return (
  //     <Wrapper {...baseProps}>
  //       <Error>{statusData_start?.errorMessage}</Error>
  //     </Wrapper>
  //   );

  //****************************************
  // SCROLLTOTOP
  //****************************************
  // SCROLL TO TOP if the LOADING BANNER appears or disappears
  // NOTE: We're using the option "watchValue" argument -- we need 2xoccurences of useScrollToTop(), one of which will always be true
  useScrollToTop(processingData.isProcessing);
  useScrollToTop(!processingData.isProcessing);

  //****************************************
  // RETURN STATEMENTS
  //****************************************
  if (redirectObject) return redirectObject;

  if (processingData.isProcessing)
    return (
      <Wrapper
        {...baseProps}
        headingText={undefined} // Override any heading
        descriptionContent={undefined}
      >
        {/* <PanelRenewal className="py-3"> */}
        <SessionRefreshing>{processingData.message}</SessionRefreshing>
        {/* </PanelRenewal> */}
      </Wrapper>
    );

  return <Wrapper {...baseProps}>{children}</Wrapper>;
};

export default RenewalBase;
