import React from "react";

import { OpexIcon } from "./index";

const OpexTitle = (props) => {	
	return (
		<div className="d-inline-flex align-middle">
			<div className="opex-icon pe-3">
				<OpexIcon GroupCode={props.GroupCode} />
			</div>
			<span className="opex-title my-auto">{props.Title}</span>
		</div>
	);
};

export default OpexTitle;
