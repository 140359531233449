import { useEffect } from "react";
import {
  useDispatch as _useDispatch,
  useSelector as _useSelector,
} from "react-redux";
import _ from "lodash";
import useDispatchWithErrors from "moveToLibrary/hooks/useDispatchWithErrors";
import { selectors, actions } from "siteStore";
import { useNavigate } from "react-router-dom";

export const useDispatch = () => {
  // A modified version of useDispatch, but with ERROR HANDLING
  const dispatch = _useDispatch();
  const dispatchWithErrors = useDispatchWithErrors(dispatch);

  return dispatchWithErrors;
};

export const useNavigationFunctions = () => {
  const navigate = useNavigate();

  const navigationFunctions = {
    home: () =>
      navigate("/", {
        state: { policyNumber: undefined },
      }),
    documents: (args = {}) => {
      ["policyNumber"].argChecker(args);

      return navigate("/documents", {
        state: { policyNumber: args.policyNumber },
      });
    },

    upload: (args = {}) => {
      ["policyNumber"].argChecker(args);

      return navigate("/upload", {
        state: { policyNumber: args.policyNumber },
      });
    },

    policy: (args = {}) => {
      ["webReference", "eventId", "policyNumber"].argChecker(args);

      return navigate("/policy", {
        state: {
          webReference: args.webReference,
          eventId: args.eventId,
          policyNumber: args.policyNumber,
          // isMta: args.isMta,
        },
      });
    },
    mta: (args = {}) => {
      ["policyNumber"].argChecker(args);

      return navigate("/mta", {
        state: { policyNumber: args.policyNumber },
      });
    },

    renewal: (args = {}) => {
      ["policyNumber"].argChecker(args);

      return navigate("/renewal", {
        state: { policyNumber: args.policyNumber },
      });

      // ["webReference", "eventId"].argChecker(args);

      // return navigate("/renewal", {
      //   state: { webReference: args.webReference, eventId: args.eventId },
      // });
    },
  };

  return navigationFunctions;
};
