import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Outlet,
  useLocation,
  useNavigate,
  Navigate,
  useResolvedPath,
  useOutletContext,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";
import { ButtonLink } from "siteComponents";

const DisplayItem = ({ valueOld, valueNew, path }) => {
  const fnFormatValue = (v) => {
    if (!v) return "<missing>";
    if (v.startsWith("{")) return "<object>";
    if (v.startsWith("[")) return "<array>";
    if (_.isString(v)) return v;
    return JSON.stringify(v);
  };
  const [showValue, setShowValue] = useState(false);

  return (
    <div className="ps-2 m-0 row">
      <div className="col-12 text-primary">
        <ButtonLink onClick={() => setShowValue((v) => !v)} className="p-0 m-0">
          {path}
        </ButtonLink>
      </div>
      {showValue && (
        <div className="col-12 ps-5">
          <small>
            (<strong>{fnFormatValue(valueOld)}</strong> to
            <strong>{fnFormatValue(valueNew)}</strong>)
          </small>
        </div>
      )}
    </div>
  );
};

const SalusChangeData = () => {
  const commonData = useOutletContext();
  if (!commonData) return null;

  const data = useSelector(
    commonData.selectors.debug.salusChanges,
    require("lodash").isEqual
  );

  if (!data?.differences) return null;

  const fnFormatData = (dataArr = []) => {
    if (dataArr.length === 0) return "none";
    return dataArr.sort().map((path, i) => {
      if (_.isString(path)) {
        const oldData = JSON.stringify(
          _.get(initialData, path.replaceAll("/", "."))
        );
        const newData = JSON.stringify(
          _.get(currentData, path.replaceAll("/", "."))
        );
        // console.log("....", path, oldData, newData);

        return (
          <DisplayItem
            key={path}
            path={path}
            valueOld={oldData}
            valueNew={newData}
          />
        );
      }

      return (
        <p key={i} className="ps-2 m-0">
          OBJ:{JSON.stringify(path)}
        </p>
      );
    });
  };

  const { differences, initialData, currentData } = data;
  //   console.log("SalusChangeData changes:", differences);

  return (
    <div>
      <dl>
        <dt>Changed values</dt>
        <dd>{fnFormatData(differences.changes)}</dd>
        <dt>Missing in original salus</dt>
        <dd>{fnFormatData(differences.missing_from_initial_salus)}</dd>
        <dt>Missing in NEW salus</dt>
        <dd>{fnFormatData(differences.missing_from_current_salus)}</dd>
      </dl>
    </div>
  );
};

export default SalusChangeData;
