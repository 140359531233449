import React from "react";

import VehicleDetailsPC from "./Templates/Car.jsx";

const VehicleDetailsSwitcher = (props) => {
  const { className, ...otherProps } = props;

  const _className = [className, "vehicle-details"].toClassName();

  if (props.PolicyType === "PC")
    return <VehicleDetailsPC {...otherProps} className={_className} />;

  throw `Error in VehicleDetailsSwitcher -- ${props.PolicyType} not yet implemented.`;
  return null;
};

export default VehicleDetailsSwitcher;
