import React from "react";
import Panel from "./Basic";

const PanelBorder = (props) => {
    const { children, className, classNameBody } = props;

    const _classContainer = ["form-panel", className].toClassName();
    const _classNameBody = [classNameBody].toClassName();

    return (
        <Panel
            {...props}
            className={_classContainer}
            classNameBody={_classNameBody}
        >
            {children}
        </Panel>
    );
};

export default PanelBorder;
