import _ from "lodash";
import { useSelector } from "react-redux";
import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";

import {
  Routes,
  Route,
  Link,
  Outlet,
  useNavigate,
  useLocation,
  Navigate,
  matchRoutes,
  matchPath,
  useParams,
} from "react-router-dom";

const generate = (args = {}) => {
  const { privateActions, selectors } = args;

  const composeComponent = (Component, dispatch, key) => {
    const _action = privateActions[`set${key}`];
    const _selector = selectors[`get${key}`];

    const NewComponent = (props) => {
      const value = useSelector(_selector);

      return (
        <Component
          {...props}
          value={value}
          onChange={(v) => dispatch(_action(v))}
        />
      );
    };
    return NewComponent;
  };

  // ******************************************************************************
  // useEffectiveDate
  // ******************************************************************************
  const useEffectiveDate = (Component, dispatch) => {
    if (!Component) throw `Error in useEffectiveDate -- missing Component`;
    if (!dispatch) throw `Error in useEffectiveDate -- missing dispatch`;

    return useCallback(
      composeComponent(Component, dispatch, "EffectiveDate"),
      []
    );
  };

  // ******************************************************************************
  //Pager
  // ******************************************************************************

  const useMtaPager = (itemData, options = {}) => {
    if (!itemData) throw `Error in useMtaPager -- missing itemData`;
    if (itemData.length === 0) throw `Error in useMtaPager -- missing itemData`;

    const { onPagerPrev = async () => {}, onPagerNext = async () => {} } =
      options;

    if (itemData.length === 0)
      throw `Error in useMtaPager -- _itemData contains no items`;

    const [curIndex, setCurIndex] = useState(0);
    const [componentData, setComponentData] = useState({});
    const maxIndex = itemData.length - 1;
    const isStart = curIndex === 0;
    const isEnd = curIndex === maxIndex;

    // ****************************************************
    // FUNCTIONS
    // ****************************************************
    const fnReset = async () => {
      setCurIndex(0);
      setComponentData({});
    };

    const fnNext = async () => {
      const newIdx = curIndex + 1;
      if (newIdx > maxIndex) {
        await onPagerNext();
        return;
      }

      setCurIndex(newIdx);
    };

    const fnPrev = async () => {
      const newIdx = curIndex - 1;
      if (newIdx < 0) {
        await onPagerPrev();
        return;
      }
      setCurIndex(newIdx);
    };

    // ****************************************************
    // COMPONENT
    // ****************************************************
    const ReturnComponent = useCallback(
      (function () {
        const DummyComponent = () => {
          return;
        };

        if (curIndex === -1) return DummyComponent;
        if (!itemData[curIndex]) return DummyComponent;

        const { Component, props: extraProps } = itemData[curIndex];

        const newProps = {
          pager: {
            fnPrev,
            fnNext,
            isStart,
            isEnd,
          },
          componentData: componentData[curIndex],
          ...extraProps,
        };

        const FinalComponent = (props) => {
          return <Component {...newProps} {...props} />;
        };
        return FinalComponent;
      })(),
      [curIndex]
    );

    // console.log("useMtaPager", {
    //   itemData: _itemData,
    //   currentData: ReturnComponent,
    //   curIndex,
    //   maxIndex,
    //   location,
    //   isStart,
    //   isEnd,
    //   isPagerEnabled
    // });

    return [
      ReturnComponent,
      {
        fnReset,
        fnNext,
        fnPrev,
        isStart,
        isEnd,
      },
    ];
  };

  // ******************************************************************************
  // RETURN OBJECT
  // ******************************************************************************
  return { useEffectiveDate, useMtaPager };
};

export default generate;
