import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

const generate = (args = {}) => {
  const { storeName, storageActions, storageSelectors } = args;

  const useMountCount = (key) => {
    if (!key) throw `Error in useMountCount -- missing "key"`;

    const sessionKey = key;
    const dispatch = useDispatch();
    const [returnValue] = useState(
      useSelector(
        (state) => storageSelectors.getValue(state, sessionKey) || 0
      ) + 1
    );

    useEffect(() => {
      dispatch(
        storageActions.update({
          key: sessionKey,
          value: returnValue,
        })
      );
    }, []);

    return returnValue;
  };

  return { useMountCount };
};

export default generate;
