import { toTitleCase } from "@library/common/database/rules/helpers";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

export default {
	emptyStringToUndefined: (args) => {
		const { dataSet, functionArgs } = args;

		if (!dataSet) throw `Error -- missing dataSet`;
		if (!functionArgs) throw `Error -- missing functionArgs`;
		const testValue = dataSet.getValue(
			new dataSetArgItem({
				useDefaultComponentSet: true,
				useDefaultComponentTag: true,
			}),
			"value"
		);

		// console.log("dddddd", testValue);
		if (testValue === "") {
			dataSet.setValue(
				new dataSetArgItem({
					key: "value",
					value: undefined,
					useDefaultComponentSet: true,
					useDefaultComponentTag: true,
				})
			);
		}
	},
	titleCase: (args) => {
		const { dataSet, functionArgs } = args;

		if (!dataSet) throw `Error -- missing dataSet`;
		if (!functionArgs) throw `Error -- missing functionArgs`;
		const testValue = dataSet.getValue(
			new dataSetArgItem({
				useDefaultComponentSet: true,
				useDefaultComponentTag: true,
			}),
			"value"
		);

		if (typeof testValue === "string") {
			dataSet.setValue(
				new dataSetArgItem({
					key: "value",
					value: toTitleCase(testValue),
					useDefaultComponentSet: true,
					useDefaultComponentTag: true,
				})
			);
		}
		return null;
	},
	upperCase: (args) => {
		const { dataSet, functionArgs } = args;

		if (!dataSet) throw `Error -- missing dataSet`;
		if (!functionArgs) throw `Error -- missing functionArgs`;
		const testValue = dataSet.getValue(
			new dataSetArgItem({
				useDefaultComponentSet: true,
				useDefaultComponentTag: true,
			}),
			"value"
		);

		if (typeof testValue === "string") {
			dataSet.setValue(
				new dataSetArgItem({
					key: "value",
					value: testValue.toUpperCase(),
					useDefaultComponentSet: true,
					useDefaultComponentTag: true,
				})
			);
		}
		return null;
	},
	trim: (args) => {
		const { dataSet, functionArgs } = args;

		if (!dataSet) throw `Error -- missing dataSet`;
		if (!functionArgs) throw `Error -- missing functionArgs`;

		const testValue = dataSet.getValue(
			new dataSetArgItem({
				useDefaultComponentSet: true,
				useDefaultComponentTag: true,
			}),
			"value"
		);

		if (typeof testValue === "string") {
			dataSet.setValue(
				new dataSetArgItem({
					key: "value",
					value: testValue.trim(),
					useDefaultComponentSet: true,
					useDefaultComponentTag: true,
				})
			);
		}
		return null;
	},
};
