import React, { useState, useEffect, useRef } from "react";

import { Loading } from "./base";
import LibraryTextBox from "@library/common/components/base/form/TextBox";
import LibraryDatePickerTextBox from "@library/common/components/base/form/DatePicker/Textbox";
import LibraryDropDownType from "@library/common/components/base/form/DropDown/Type";
import { generateCypressProps } from "siteFunctions/cypress";
import AnimatedPulse from "./custom/Animated/Pulse";
import AnimatedOval from "./custom/Animated/Oval";

const ButtonBase = (props) => {
  //https://stackoverflow.com/questions/23123138/how-to-perform-debounce
  const {
    isLoading = false,
    loadingInline = false,
    children,
    className,
    LoadingComponent = () => <Loading size="6px" colour="" />,
    type,
    onClick = () => {},
    disableClickWhenLoading = true,
    disabled = false,
    innerRef,
  } = props;

  if (!children) return null;
  // if (disabled) return null;

  const handleOnClick = (e) => {
    if (disabled) return;
    if (disableClickWhenLoading && isLoading) return;
    onClick(e);
  };

  return (
    <button
      disabled={disabled}
      className={[
        "lctr-button",
        className,
        isLoading && !loadingInline && "btn-fb-loading active",
        disabled && "disabled",
      ].toClassName()}
      onClick={handleOnClick}
      type={type}
      {...generateCypressProps("button", props)}
      ref={innerRef}
    >
      {(function () {
        if (isLoading && !loadingInline)
          return (
            <span className="d-flex justify-content-center align-items-center">
              <LoadingComponent />
            </span>
          );

        return (
          <span
            className={[
              isLoading && "d-flex justify-content-center align-items-center",
            ].toClassName()}
          >
            <span>{children}</span>
            {isLoading && <LoadingComponent />}
          </span>
        );
      })()}
    </button>
  );
};

export const Button = (props) => <ButtonBase type="button" {...props} />;

export const ButtonLink = (props) => {
  const { className, ...otherProps } = props;

  return (
    <ButtonBase
      className={["btn", "btn-link", className].toClassName()}
      {...generateCypressProps("button-link", props)}
      {...otherProps}
    />
  );
};

export const ButtonClose = (props) => {
  const { className, content, ...otherProps } = props;

  return (
    <ButtonBase
      className={["btn", "btn-link", "p-0", className].toClassName()}
      {...otherProps}
    >
      {content ? <span>{content}</span> : <span>(X)</span>}
    </ButtonBase>
  );
};

export const ButtonStyled = (props) => {
  const { className, ...otherProps } = props;

  return (
    <ButtonBase
      type="button"
      className={["btn", "btn-fb", className].toClassName()}
      {...otherProps}
    />
  );
};

export const ButtonOutline = (props) => {
  const { className, ...otherProps } = props;

  return (
    <ButtonBase
      className={["btn", "btn-outline-fb", className].toClassName()}
      {...otherProps}
    />
  );
};

export const ButtonSubmit = (props) => {
  const {
    type, // Remove but don't use
    "data-cy": dataCy,
    ...otherProps
  } = props;

  return (
    <ButtonStyled
      type="submit"
      {...otherProps}
      {...generateCypressProps("button-submit", props)}
    />
  );
};

export const ButtonToggle = (props) => {
  const { className, isActive, checked, ...otherProps } = props;

  const _isActive = isActive || checked;
  return (
    <ButtonBase
      className={[
        "btn",
        // "btn-check",
        _isActive && "btn-fb",
        !_isActive && "btn-outline-fb",
        _isActive && "active",
        className,
      ].toClassName()}
      {...otherProps}
    />
  );
};

export const ButtonToggleStyled = (props) => {
  const { className, ...otherProps } = props;

  return (
    <ButtonToggle
      {...otherProps}
      className={["btn-toggle-fb", className].toClassName()}
    />
  );
};
{
  /* export const Checkbox = (props) => {
  const {
    checked = false,
    onSelect = () => {},
    onUnselect = () => {},
    className,
    ...otherProps
  } = props;



  return (
    <input
      {...otherProps}
      {...generateCypressProps("checkbox", props)}
      className={["btn-checkbox", "form-check-input", className].toClassName()}
      type="checkbox"
      checked={checked}
      onChange={(e) => {
        const value = e.target.value;
        if (e.target.checked) {
          onSelect(value);
        } else {
          onUnselect(value);
        }
      }}
    />
  );
}; */
}

export const ButtonStyledMta = (props) => {
  const { className, ...otherProps } = props;

  return (
    <ButtonBase
      type="button"
      className={["btn", "btn-mta", className].toClassName()}
      {...otherProps}
    />
  );
};

export const ButtonOutlineMta = (props) => {
  const { className, ...otherProps } = props;

  return (
    <ButtonBase
      className={["btn", "btn-outline-mta", className].toClassName()}
      {...otherProps}
    />
  );
};

export const ButtonToggleMta = (props) => {
  const { className, isActive, checked, ...otherProps } = props;

  const _isActive = isActive || checked;
  return (
    <ButtonBase
      className={[
        "btn",
        // "btn-check",
        _isActive && "btn-mta",
        !_isActive && "btn-outline-mta",
        _isActive && "active",
        className,
      ].toClassName()}
      {...otherProps}
    />
  );
};

export const ButtonStyledRenewal = (props) => {
  const { className, ...otherProps } = props;

  return (
    <ButtonBase
      type="button"
      className={["btn", "btn-renewal", className].toClassName()}
      {...otherProps}
    />
  );
};

export const ButtonOutlineRenewal = (props) => {
  const { className, ...otherProps } = props;

  return (
    <ButtonBase
      className={["btn", "btn-outline-renewal", className].toClassName()}
      {...otherProps}
    />
  );
};

export const ButtonToggleRenewal = (props) => {
  const { className, isActive, checked, ...otherProps } = props;

  const _isActive = isActive || checked;
  return (
    <ButtonBase
      className={[
        "btn",
        "btn-outline-renewal",
        _isActive && "active",
        className,
      ].toClassName()}
      {...otherProps}
    />
  );
};
