// import getProps from "./_getProps";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";
import dateHelpers from "@library/common/helpers/misc/date";
import _ from "lodash";
import dayjs from "dayjs";

export default {
	//**  converts anystring to a date for postRegistrations
	salusConvertStringToDate: (args) => {
		console.log("args:", args);
		throw `error salusConvertStringToDate disabled`;
		return;

		const { dataSet, functionArgs } = args;
		const argItem = new dataSetArgItem({
			key: "value",
			useDefaultComponentSet: true,
			useDefaultComponentTag: true,
		});

		const testValue = dataSet.getValue(argItem);

		//exit if it's not a date or undefined

		// console.log("dateFunctions.salusConvertStringToDate:", testValue);

		if (_.isUndefined(testValue)) return;

		// const newDate = dateHelpers.toString(testValue);

		const newDate = dayjs(testValue, "YYYY-MM-DDThh:mm:ss").toDate();

		dataSet.setValue({ ...argItem, value: newDate });

		// if (!_.isDate(testValue)) {
		// 	console.log("dddd not a date:", testValue);
		// 	const newValue = dateHelpers.toString(testValue);
		// 	dataSet.setValue({ ...argItem, value: newValue });
		// } else {
		// 	// if it's already a date, then format it an new way
		// 	console.log("dddd is a date:", testValue);

		// 	const newValue = dateHelpers.toString(testValue);
		// 	dataSet.setValue({ ...argItem, value: newValue });
		// }
	},
	// dateConvert: (args) => {
	// 	const { dataSet, functionArgs } = args;

	// 	// if (!dataSet.isPostRegistration()) return;

	// 	const argItem = new dataSetArgItem({
	// 		key: "value",
	// 		useDefaultComponentSet: true,
	// 		useDefaultComponentTag: true,
	// 	});

	// 	const testValue = dataSet.getValue(argItem);
	// 	if (!testValue || testValue instanceof Date) return;

	// 	const newValue = dayjs.parseZone(testValue).toDate();
	// 	dataSet.setValue({ ...argItem, value: newValue });
	// 	// console.log("dateConvert: converting", testValue, "to", newValue);
	// },

	dateComparison: (args) => {
		const { dataSet, functionArgs } = args;

		if (!dataSet) throw `Error -- missing dataSet`;
		if (!functionArgs) throw `Error -- missing functionArgs`;
		if (!("errorMessage" in functionArgs))
			throw `Error -- missing errorMessage`;

		if (
			!functionArgs.comparator ||
			!["compareDate", "today", "yearOffset", "compareDateString"].some(
				(x) => x in functionArgs
			)
		) {
			console.log("args:", args);
			throw `Error in dateFunctions -- missing a required arg`;
		}

		// ** get the source date
		const sourceArgItem = functionArgs.sourceDate
			? functionArgs.sourceDate
			: new dataSetArgItem({
					useDefaultComponentSet: true,
					useDefaultComponentTag: true,
			  });

		const thisValueRaw = dataSet.getValue(sourceArgItem, "value");
		const thisValue =
			thisValueRaw !== undefined ? dateHelpers.toDate(thisValueRaw) : undefined;

		// ** get the compareValue
		const compareValue = (function () {
			if ("today" in functionArgs && functionArgs.today === true)
				return dayjs().startOf("day").toDate();

			if ("compareDateString" in functionArgs)
				return dateHelpers.toDate(functionArgs.compareDateString);

			if ("yearOffset" in functionArgs)
				return dayjs()
					.startOf("day")
					.add(functionArgs.yearOffset, "y")
					.toDate();

			if ("compareDate" in functionArgs) {
				// disabled:
				// NOT needed, as we don't need to run rules for the date we're comparing against
				// especially as "compareDate" could have a "componentSetPath" which will cause problems.

				// dataSet.dependencyAdd(functionArgs.compareDate);

				const compareDateRaw = dataSet.getValue(
					functionArgs.compareDate,
					"value"
				);

				if (compareDateRaw === undefined) return undefined;

				return dateHelpers.toDate(compareDateRaw);
			}
		})();

		let errorMessage = undefined;

		if (thisValue !== undefined && compareValue !== undefined) {
			switch (functionArgs.comparator.toLowerCase()) {
				case "<": {
					if (thisValue >= compareValue) {
						errorMessage = functionArgs.errorMessage;
					}
					break;
				}
				case "<=": {
					if (thisValue > compareValue) {
						errorMessage = functionArgs.errorMessage;
					}
					break;
				}
				case ">": {
					if (thisValue <= compareValue) {
						errorMessage = functionArgs.errorMessage;
					}
					break;
				}
				case ">=": {
					if (thisValue < compareValue) {
						errorMessage = functionArgs.errorMessage;
					}
					break;
				}
				default: {
					console.log("ERROR:", functionArgs);
					throw `Error -- unknown comparator: ${functionArgs.comparator}`;
				}
			}
		}

		if (false) {
			console.groupCollapsed("dateFunctions", "dateComparison");
			console.log("thisValue:", thisValue);
			console.log("comparator:", functionArgs.comparator);
			console.log("comparevalue:", compareValue);
			console.log("errorMessage:", errorMessage);
			console.log("thisValue >= compareValue", thisValue >= compareValue);
			console.groupEnd();
		}

		dataSet.setValue({
			...sourceArgItem,
			key: "errors",
			value: { errorMessage, errorKey: functionArgs.errorKey },
		});
	},
};
