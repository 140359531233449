import React from "react";

import {
    RegPlate,
    BadgesPolicyStatus,
} from "siteComponents";

const CardHeader = (props) => {

    const { regNumber, vehicleDescription, status, prefix } = props;

    return (

        <div className="header-with-status d-flex align-items-center flex-column flex-md-row">
            <div className="order-2 order-md-1">
                <RegPlate
                    regNumber={regNumber}
                    justify="justify-content-md-center"
                />
            </div>

            <div className="description order-3 order-md-1 flex-grow-1">{vehicleDescription}</div>

            <div className="order-1 order-md-2">
                <BadgesPolicyStatus status={status} className="justify-content-end flex-wrap" prefix={prefix} />
            </div>
        </div>

    );

}

export default CardHeader;
