import React, { useEffect } from "react";
import { VehicleReg } from "siteComponents";

const RegPlate = (props) => {
    const { regNumber, className, justify = "justify-content-center" } = props;

    const _className = [
        "d-flex",
        justify,
        "align-items-center",
        "text-center",
        // "mb-3",
        // "mb-md-0",
        className,
    ].toClassName();

    return (
        <div className={_className}>
            <VehicleReg>{regNumber}</VehicleReg>
        </div>
    );
};

export default RegPlate;
