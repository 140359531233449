import dayjs from "dayjs";

import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import * as constants from "@library/common/constants/salus";

// const sessionStorageLogger =
//   require("../../../../../../../dev/sessionStorageLogger").default(
//     "additionalDriverLogic",
//     false // enabled
//   );

// sessionStorageLogger.reset();

export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper")
      .default,
  } = args;

  // console.log("dddd additionalDriverLogic");

  //MaritalStatus
  const dh = new dataHelper({
    logging: false,
    loggingName: "additionalDriverLogic",
    mappings: [
      "RelationshipToProposer",
      "MaritalStatus",
      "DateOfBirth",
      "ProposerDateOfBirth",
      "ProposerMaritalStatus",
      "ProposerForenames",
      "ProposerSurname",
      "ProposerTitle",
      "ProposerClassOfUse",
      "ProposerClassOfUseFrontEnd",
    ],
    dataSet,
    functionArgs,
  });

  const curRel = dh.getValue("RelationshipToProposer");
  const curMarStatus = dh.getValue("MaritalStatus");
  const curPropMarStatus = dh.getValue("ProposerMaritalStatus");

  const strProposerName =
    [
      dh.getValue("ProposerTitle"),
      dh.getValue("ProposerForenames"),
      dh.getValue("ProposerSurname"),
    ].join(" ") || "the proposer";

  //   sessionStorageLogger.log({
  //     commponentSet: dataSet.current.componentSet,
  //     functionArgs,
  //   });

  const addDriverTree =
    dataSet.getTreeSiblings({
      argItem: new dataSetArgItem({
        useDefaultComponentTag: true,
        useDefaultComponentSet: true,
      }),
      componentTagHitlist: ["RelationshipToProposer", "MaritalStatus"],
    }) || [];

  // ********************************************
  // ** FOR RECALL and DIRECT
  // ** Set the marital status based on the RelationshipToProposer
  // ********************************************
  if (dh.isUpdated("RelationshipToProposer") || dh.isPostRecall()) {
    const newMartialStatus = (function () {
      if (curRel === constants.relationship.civilPartner)
        return constants.maritalStatus.civilPartnered;

      if (curRel === constants.relationship.spouse)
        return constants.maritalStatus.married;

      return constants.maritalStatus.single;
    })();

    const newProposerMaritalStatus = (function () {
      // check that there's no other add drivers who are civilPartner or married
      // If there is, then *DON'T* update the proposerMaritalStatus
      if (
        addDriverTree
          .filter((x) => x.curComponentSet === false)
          .some((x) => {
            if (
              [
                constants.relationship.civilPartner,
                constants.relationship.spouse,
              ].includes(x.data.RelationshipToProposer.value)
            )
              return true;

            return false;
          })
      ) {
        return curPropMarStatus;
      }

      if (curRel === constants.relationship.civilPartner)
        return constants.maritalStatus.civilPartnered;

      if (curRel === constants.relationship.spouse)
        return constants.maritalStatus.married;

      return constants.maritalStatus.single;
    })();

    // console.log("DDDDDDDDDDD ADDDRIVER LOGIC", dh.updatePath, {
    //   newProposerMaritalStatus,
    //   addDriverTree,
    // });

    dh.setValue("ProposerMaritalStatus", newProposerMaritalStatus);
    dh.setValue("MaritalStatus", newMartialStatus);
  }

  if (dh.isPostRecall()) return;

  if (false) {
    // console.log("dddd addDriverlogic running", dh.getValue("ProposerSurname"));
    // check if the driver is a child, compare their date of births
    // Task 400 -- remove parent/child DOB validation
    // if (
    // 	dh.isUpdated(
    // 		"RelationshipToProposer",
    // 		"MaritalStatus",
    // 		"DateOfBirth",
    // 		"ProposerDateOfBirth"
    // 	)
    // ) {
    // 	if (
    // 		dh.getValue("RelationshipToProposer") === "OFFSPRNG" &&
    // 		dh.getValueDate("DateOfBirth") <= dh.getValueDate("ProposerDateOfBirth")
    // 	) {
    // 		const errorMessage =
    // 			"Birthdate for a child should be less than parent's birthdate";
    // 		dh.addError("RelationshipToProposer", errorMessage, false);
    // 		dh.addError("DateOfBirth", errorMessage, false);
    // 		dh.addError("ProposerDateOfBirth", errorMessage, false);
    // 	} else if (
    // 		dh.getValue("RelationshipToProposer") === "PARENT" &&
    // 		dh.getValueDate("DateOfBirth") >= dh.getValueDate("ProposerDateOfBirth")
    // 	) {
    // 		const errorMessage =
    // 			"Birthdate for a child should be greater than parent's birthdate";
    // 		dh.addError("RelationshipToProposer", errorMessage, false);
    // 		dh.addError("DateOfBirth", errorMessage, false);
    // 		dh.addError("ProposerDateOfBirth", errorMessage, false);
    // 	} else {
    // 		dh.clearError("ProposerDateOfBirth");
    // 	}
    // }
    // check the RelationshipToProposer is consistant with MaritalStatus
  }
  //disable the checking of the proposer's maritalStatus
  if (false) {
    if (curRel === constants.relationship.spouse) {
      // NOTE: curMarStatus could be undefined
      if (curMarStatus !== constants.maritalStatus.married) {
        const errorMessage = "";
        // "`Marital status` must match the `relationship to proposer`";
        dh.addError("RelationshipToProposer", errorMessage, false);
        dh.addError("MaritalStatus", errorMessage, false);
      }

      if (curPropMarStatus !== constants.maritalStatus.married) {
        const errorMessage =
          "Their relationship doesn't match the marital status of " +
          strProposerName +
          ". Please review and update.";
        // "`Marital status` of the main driver must be `Married`";
        dh.addError("RelationshipToProposer", errorMessage, false);
        // dh.addError("ProposerMaritalStatus", errorMessage, false);
      }
    }

    if (curRel === constants.relationship.civilPartner) {
      if (curMarStatus !== constants.maritalStatus.civilPartnered) {
        const errorMessage = "";
        // "`Marital status` must match the `relationship to proposer`";
        dh.addError("RelationshipToProposer", errorMessage, false);
        dh.addError("MaritalStatus", errorMessage, false);
      }

      if (curPropMarStatus !== constants.maritalStatus.civilPartnered) {
        const errorMessage =
          "Their relationship doesn't match the marital status of " +
          strProposerName +
          ". Please review and update.";
        // "`Marital status` of the main driver must be `civil partner`";
        dh.addError("RelationshipToProposer", errorMessage, false);
        // dh.addError("ProposerMaritalStatus", errorMessage, false);
      }
    }
  }

  // ****************************************************************
  // check if there's only one "COMSPOUSE" and "SPOUSEREL"
  // ****************************************************************

  dh.clearError("RelationshipToProposer"); // #2961

  if (
    //Check the current driver
    [
      constants.relationship.civilPartner,
      constants.relationship.spouse,
      constants.relationship.common,
    ].includes(dh.getValue("RelationshipToProposer")) &&
    // **AND**
    // check the AddDriver Tree as well
    addDriverTree
      .filter((x) => x.curComponentSet === false)
      .some((x) =>
        [
          constants.relationship.civilPartner,
          constants.relationship.spouse,
          constants.relationship.common,
        ].includes(x.data.RelationshipToProposer.value)
      )
  ) {
    const errorMessage =
      "You can only add one spouse, commonlaw partner, or civil partner to your policy.";
    dh.addError("RelationshipToProposer", errorMessage, false);

    // sessionStorageLogger.log({ msg: "adding error", addDriverTree });
  }

  if (dh.isUpdated("RelationshipToProposer")) {
    // Doesn't run now, but adds a dependencie for the generateHitlist function to run later.
    dh.runRules("ProposerClassOfUseFrontEnd");
  }

  dh.commit();
};
