import React, { useState } from "react";
import config from "config";
import { ButtonLink } from "siteComponents";
import { generateCypressProps } from "siteFunctions/cypress";
import { useDispatch } from "siteFunctions/hooks";
import { actions, selectors } from "siteStore";
import { connect, useSelector } from "react-redux";

const DevContainer = (props) => {
  const {
    children,
    heading,
    enabled = true,
    storageKey = "dev-container",
  } = props;
  const [isVisible, setIsVisible] = useState(true);

  const dispatch = useDispatch();

  const isHideAll = useSelector(
    (state) => selectors.storage.getValue(state, storageKey),
    require("lodash").isEqual
  );

  const fnHideAll = async () => {
    await dispatch(
      actions.storage.update({
        key: storageKey,
        value: true,
        persist: false,
      })
    );
  };

  if (isHideAll) return null;
  if (!enabled) return null;
  if (!isVisible) return null;
  if (!(config.isDev || config.isTest)) return null;

  return (
    <div
      className="dev-container my-3 p-2 bg-light rounded-3 border border-danger border-5 position-relative"
      {...generateCypressProps("dev-container", props)}
    >
      {heading && <p className="h5 fw-700">{heading}</p>}

      {children}

      <div className="position-absolute top-0 end-0">
        <ButtonLink
          onClick={(e) => {
            e.preventDefault();
            setIsVisible(false);
          }}
        >
          Hide
        </ButtonLink>

        <ButtonLink
          onClick={(e) => {
            e.preventDefault();
            fnHideAll();
          }}
          data-cy="dev-hide-all"
        >
          Hide all
        </ButtonLink>
      </div>
    </div>
  );
};

export default DevContainer;
