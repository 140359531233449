import React from "react";
import { Markup } from "interweave";

import ImgIncluded from "siteAssets/images/tick-white.png";
import { OpexDisplayInfo } from "./index";
//import { TickList } from "siteComponents";

const OpexDescription = (props) => {
	const { opx, hideSellingPoints = false } = props;
	if (!opx) return null;

	const { Title, Description, MainInfo, SellingPoints } = opx;

	const _className = "";

	const bulletList = SellingPoints && SellingPoints.map(sp => { return <span>{sp.Text}</span> });

	const htmlDescription = Description && Description.startsWith("<") && Description.endsWith(">");

	return (
		<div className={_className}>

			{Description && (
				<>
					{htmlDescription && (
						<div className="mb-3">
							<Markup content={Description} />
						</div>
					)}
					{!htmlDescription && (
						<p>{Description}</p>
					)}
				</>
			)}

			{MainInfo && (
				<>
					{MainInfo.map((mInfo) => {
						return (
							<OpexDisplayInfo
								key={mInfo.Id}
								displayInfo={mInfo}
								Title={Title}
							/>
						);
					})}
				</>
			)}

{/* 
			{bulletList && !hideSellingPoints && (
				<TickList
					className="gap-2 mb-2"
					listData={bulletList}
					iconUrl={ImgIncluded}
				/>
			)}
			*/}

		</div>
	);
};

export default OpexDescription;
