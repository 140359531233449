export * from "./base";
export * from "./Buttons";
export * from "./Dates";
export * from "./FileHandling";
export * from "./custom/Links";
export * from "./custom/Panel";
export * from "./custom/Cards";
export * from "./custom/PolicyDisplay";
export * from "./custom/Opex";

export const Fullscreen = require("./custom/Fullscreen").default;

export const VehicleDetails = require("./custom/VehicleDetails").default;
export const RegPlate =
  require("./custom/VehicleDetails/Templates/RegPlate").default;
export const CarIcon =
  require("./custom/VehicleDetails/Templates/CarIcon").default;
export const TabPanel = require("./custom/TabPanel").default;
export const Feedback = require("./custom/Feedback").default;
export const Alert = require("./custom/Feedback").Alert;
export const Error = require("./custom/Feedback").Error;
export const Timeout = require("./custom/Timeout").default;
export const Sticky = require("./custom/Sticky").default;
export const Maintenance = require("./custom/Maintenance").default;
export const SiteAlerts = require("./custom/SiteAlerts").default;

export const Modal = require("./custom/Modal").default;
export const ConfirmModal = require("./custom/ConfirmModal").default;
export const ConfirmModalButton =
  require("./custom/ConfirmModal/Button").default;

export const AnimatedPulse = require("./custom/Animated/Pulse").default;
export const AnimatedOval = require("./custom/Animated/Oval").default;

export const DevContainer = require("../dev/Container").default;

export const JsonViewer = require("../dev/JsonViewer").default;

export const CookieConsent = require("./custom/CookieConsent").default;
//export const Panel = require("./custom/Panel").default;
// export const SubPanel = require("./custom/Panel/Sub").default;
export const Checkbox = require("./custom/CheckBox").default;

export const BadgesPolicyStatus =
  require("./custom/Badges/PolicyStatus").default;
