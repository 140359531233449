import React from "react";
import _ from "lodash";

import DisplayItem from "./DisplayItem";

const DisplaySection = (props) => {

    const {
        heading,
        subheading,
        className,
        dataCy,
        children,
        data = [],
        missingText,
    } = props;

    if (children) {
        return (
            <div
                className={["policy-details", className].toClassName()}
                data-cy={dataCy}
            >
                <div className="policy-details-heading">{heading}</div>
                {subheading && (
                    <div className="policy-details-subheading">{subheading}</div>
                )}

                <div className="policy-details-content">
                    {children}
                </div>
            </div>
        );
    }

    return (
        <div
            className={["policy-details", className].toClassName()}
            data-cy={dataCy}
        >
            <div className="policy-details-heading">{heading}</div>

            {subheading && (
                <div className="policy-details-subheading">{subheading}</div>
            )}

            <div className="policy-details-content">
                {data.map(
                    (
                        {
                            label,
                            value,
                            lblMyAuto,
                            isHorizontalFormat,
                            isHzntlMobile,
                            labelClass,
                            valueClass,
                            dataCy,
                            className,
                            hzntDataCols = 7,
                            hzntSmDataCols = 9,
                            hzntMdDataCols = 9,
                            hzntXlDataCols = 9
                        },
                        i
                    ) => {
                        const _labelClass = [
                            lblMyAuto ? "my-auto" : "",
                            labelClass,
                        ].toClassName();
                        const _valueClass = [valueClass].toClassName();
                        return (
                            <DisplayItem
                                key={i}
                                dataCy={dataCy}
                                label={label}
                                value={value}
                                outerClass={className}
                                labelClass={_labelClass}
                                valueClass={_valueClass}
                                isHorizontalFormat={isHorizontalFormat}
                                isHzntlMobile={isHzntlMobile}
                                hzntDataCols={hzntDataCols}
                                hzntSmDataCols={hzntSmDataCols}
                                hzntMdDataCols={hzntMdDataCols}
                                hzntXlDataCols={hzntXlDataCols}
                            />
                        );
                    }
                )}
                {data.length === 0 && (
                    <DisplayItem
                        dataCy="item-missing"
                        value={missingText}
                        isHorizontalFormat={false}
                        isHzntlMobile={false}
                    />
                )}
            </div>
        </div>
    );
};

export default DisplaySection;