import React from "react";
import Base from "../Base";

import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { actions, selectors } from "siteStore";
import { ButtonLink, ButtonStyled } from "siteComponents";

const ErrorBoundary = (props) => {
  console.log("ErrorBoundary", props);

  // const navigate = useNavigate();

  const onClick = () => {
    // navigate("/");
    window.location.href = "/";
  };

  const isLoggedIn = useSelector(
    selectors.auth.isLoggedIn,
    require("lodash").isEqual
  );
  const linkText = isLoggedIn ? "return home" : "log in";
  const btnText = isLoggedIn ? "Return home" : "Log in";

  return (
    <Base heading="We're very sorry!" icon="icon-hand">
      <div>
        It appears that something unexpected has happened and we're unable to
        proceed with your request.
      </div>
      {/* <div>
        Please try again later or <ButtonLink onClick={onClick}>{linkText}</ButtonLink>.
      </div> */}
      <div>
        Please try again later or <a href="/">{linkText}</a>.
      </div>

      <div>
        <ButtonStyled onClick={onClick}>{btnText}</ButtonStyled>
      </div>
    </Base>
  );
};

export default ErrorBoundary;
