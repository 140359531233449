// RegisterScreen.js
import React, { useEffect } from "react";
import _ from "lodash";

import Base from "./Base";
import { Feedback, DevContainer, JsonViewer } from "siteComponents";

import { selectors, actions } from "siteStore";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "siteFunctions/hooks";
// import { useAnalyticsCar } from "siteManagers/analytics";

// ***********************************************
//  MAIN COMPONENT
// ***********************************************
const AuthenticateView = (props) => {
  const { routeDashboard } = props;

  const lastResponse =
    useSelector(
      selectors.auth.authenticate.getLastResponse,
      require("lodash").isEqual
    ) || {};
  const isLoading = useSelector(
    selectors.auth.authenticate.getIsLoading,
    require("lodash").isEqual
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const analytics = useAnalyticsCar();

  const onSubmit = async (data) => {
    const payload = {
      username: data.username,
      password: data.password,
    };
    // analytics.login.start();
    dispatch(actions.auth.authenticate(payload));
  };

  useEffect(() => {
    if (routeDashboard && lastResponse.isAuthenticated) {
      navigate(routeDashboard);
    }
  }, [lastResponse.isAuthenticated]);

  // console.log("lastResponse", lastResponse)

  return (
    <div>
      <Base
        {...props}
        onSubmit={onSubmit}
        isLoading={isLoading}
        showSidePanel={true}
        blurbData={
          <p>
            Enter your username and password that you used to create your
            account. The username may be your email address.
          </p>
        }
        header="Log in"
        buttonText="Log in"
        routeRegister="/register"
        routeForgotUsername="/forgotusername"
        routeForgotPassword="/forgotpassword"
        FeedbackComponent={({ formError }) => (
          <Feedback
            fallbackMsg="Sorry, there's been an error."
            config={[
              {
                key: "formError",
                type: "error",
                fallbackMsg: "Please correct the errors above.",
              },

              {
                key: "isUnsuccessful",
                type: "error",
              },
              { key: "isTechnicalError", type: "error" },
            ]}
            data={{
              ..._.pick(lastResponse, ["isUnsuccessful", "isTechnicalError"]),
              formError,
            }}
          />
        )}
      />
      <DevContainer heading="Last response">
        <JsonViewer>
          {{
            lastResponse,
          }}
        </JsonViewer>
      </DevContainer>
    </div>
  );
};

export default AuthenticateView;
