export default {
	Address: require("./data/Address").default,

	DateOfBirth: require("./data/DateOfBirth").default,
	Claim: require("./data/Claim").default,
	Conviction: require("./data/Conviction").default,
	Person: require("./data/Person").default,
	PersonAddDriver: require("./data/PersonAddDriver").default,
	PersonProposer: require("./data/PersonProposer").default,

	Name: require("./data/Name").default,
	Licence: require("./data/Licence").default,
	Telephone: require("./data/Telephone").default,
	// 
	Policy: require("./data/Policy").default,
	Postcode: require("./data/Postcode").default,
	...require("./data/Occupation").default,
};
