import React from "react";
import BaseComponent from "./Base.Component";

const PanelMta = (props) => {
  const { className, classNameHeading, classNameBody, ...otherProps } = props;

  const mainHeading = props.header || props.heading;

  const _classContainer = ["card", "mb-4", className].toClassName();
  const _classNameHeading = [
    "card-header",
    "heading-main",
    classNameHeading,
  ].toClassName();
  const _classNameBody = ["card-body", classNameBody].toClassName();

  return (
    <BaseComponent
      heading={mainHeading}
      classNameContainer={_classContainer}
      classNameHeading={_classNameHeading}
      classNameBody={_classNameBody}
      {...otherProps}
    />
  );
};

export default PanelMta;
