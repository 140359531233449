// import dayjs from "dayjs";
// import { employmentValidations } from "./joi/schema";
import dataHelper from "@library/common/database/rules/helpers/dataHelper";

import {
  employmentStatuses,
  employmentStatusesReverseMapping,
  employmentCodes,
  employerCodes,
} from "@library/common/constants/salus";

export default (args) => {
  const occupationBaseLogic = require("./baseLogic").default;

  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper").default,
  } = args;
  const dh = new dataHelper({
    mappings: ["Type", "OccCode", "EmpCode"],
    dataSet,
    functionArgs,
  });

  occupationBaseLogic(args, dh);

  dh.addErrorIfEmpty("Type", ""); // "Required")
  dh.addErrorIfEmpty("OccCode", ""); // "Required")
  dh.addErrorIfEmpty("EmpCode", ""); // "Required")

  dh.commit();
};
