// features/dashboard/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createAsyncThunk } from "@reduxjs/toolkit";

const generateErrObj = (error) => ({
  isTechnicalError: true,
  error,
});
import { createFnGenerateAction } from "../common";

const generateStore = (args = {}) => {
  const { storeName, services } = args;

  const fnGenerateAction = createFnGenerateAction(storeName);

  const refreshUserInfo = fnGenerateAction(
    "refreshUserInfo",
    services.refreshUserInfo
  );

  const getPolicyDetails = fnGenerateAction(
    "getPolicyDetails",
    services.getPolicyDetails
  );

  return { refreshUserInfo, getPolicyDetails };
};

export default generateStore;
