// service.js

import config from "config";
import _ from "lodash";
import {
  isFunctionNode,
  createServiceObj,
} from "@library/common/helpers/service";

const { endPoints } = config;
// import { store } from "siteStore";
// const fnComposeSecureRoutes = (endPoints) => {
//   const retData = _.cloneDeepWith(endPoints, (nodeItem) => {
//     if (_.isFunction(nodeItem)) {
//       const newNodeItem = (payload) =>
//         nodeItem(payload, { headers: { test: 1 } });
//       return newNodeItem;
//     }
//   });

//   return retData;
// };

export default endPoints; //endPointsWithSecurity;
