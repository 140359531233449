import React, { useEffect } from "react";
import config from "config";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate,
  Outlet,
  useLoaderData,
  useLocation,
  matchPath,
} from "react-router-dom";
import scrollToTop from "@library/common/components/base/functions/scrollToTop";
import usePrevious from "moveToLibrary/hooks/usePrevious";

//   const location = useLocation();

// import LandingView from "siteViews/Landing";
// import SiteLayout from "siteLayouts/Site";
import SiteLayoutError from "siteLayouts/Site/Error";

import Error404View from "siteViews/Errors/404";
import ErrorChunkView from "siteViews/Errors/Chunk";
import ErrorBoundaryView from "siteViews/Errors/ErrorBoundary";
import ErrorTimeoutView from "siteViews/Errors/Timeout";
import ErrorMaintenanceView from "siteViews/Errors/Maintenance";

import ProtectedRoute from "./outlets/ProtectedRoute";

// import SecurityView from "siteViews/Security";
// import SecurityViewTest from "siteViews/Security/Test";
// import SecurityViewTest2 from "siteViews/Security/Test2";
import { ErrorBoundaryReactRouter } from "siteComponents/Errors";

import routesLanding from "./subRoutes/landing";
import routesTest from "./subRoutes/test";
import routesAuth, { manifest as authManifest } from "./subRoutes/auth";
import routesLegacy from "./subRoutes/legacy";
import routesHelp from "./subRoutes/help";
// import routesDocuments from "./subRoutes/documents";

import { Timeout, Maintenance } from "siteComponents";

import SiteStore, { components } from "siteStore";
const { SessionChecker, SessionAutoSave } = components.session;
import { useDispatch } from "siteFunctions/hooks";
import { Loading } from "siteComponents";
import MtaExitContext from "./contextMtaExit";

const ElementTopLevel = (props) => {
  const { pathname } = useLocation();

  const prevPathname = usePrevious(pathname);
  const wasLastPathMta =
    matchPath("/mta/*", prevPathname || "") === null ? false : true;

  // useEffect(() => {
  //   console.log("dsadasd", prevPathname, "->", pathname, wasMta);
  // }, [pathname]);

  return (
    <SessionChecker useDispatch={useDispatch}>
      <Maintenance>
        <Timeout>
          <React.Suspense
            fallback={
              <div className="d-flex justify-content-center mt-5">
                <Loading />
              </div>
            }
          >
            <MtaExitContext.Provider value={wasLastPathMta}>
              <Outlet />
            </MtaExitContext.Provider>
          </React.Suspense>
        </Timeout>
      </Maintenance>
    </SessionChecker>
  );
};

export const routeData = [
  {
    // path: "/",
    element: <ElementTopLevel />,
    errorElement: <ErrorBoundaryReactRouter />,
    children: [
      // ******************************
      // Public routes
      // ******************************
      {
        element: <Outlet />,
        children: [
          ...routesTest,
          ...routesAuth,
          ...routesLegacy,
          ...routesHelp,
        ],
      },

      // ******************************
      // LANDING routes
      // ******************************
      {
        path: "",
        children: [...routesLanding],
      },

      // {
      //   path: "/aaaa",
      //   loader: () => {
      //     return "dddd";
      //   },
      //   element: <Aaaa />,
      // },
      // ******************************
      // Secure routes
      // ******************************
      {
        element: <ProtectedRoute routeLogin="/" />,
        children: [
          {
            element: <Outlet />,
            children: [
              // ...routesDocumentsBasicSecure,
              // ...routesDocument,
              // ...routesEdit,
              // ...routesDashboard,
            ],
          },
        ],
      },

      // ******************************
      // Legacy
      // ******************************
      {
        // https://1nn36n5b.r.eu-west-1.awstrack.me/L0/https:%2F%2Ffreedomweb.uat.cdlcloud.co.uk%2Ffreedom%2Fportalrecall.launch%3Fqsid=portal%26PolicyType=pc%26brandName=default%26token=a5f2742e-5bbd-4259-9b17-40d16e207345/1/010201860cc1d889-c7034824-84a5-479e-9040-15ca084546d7-000000/eBXCCc_TiOqjB103p2Zxz3IJ8Mo=307
        // children: [{ path: "*", element: <Navigate to="" /> }],
      },

      // ******************************
      // DEV
      // ******************************
      config.isDev
        ? {
            element: (
              <SiteLayoutError>
                <Outlet />
              </SiteLayoutError>
            ),
            path: "error",
            children: [
              { path: "maintenance", element: <ErrorMaintenanceView /> },
              { path: "chunk", element: <ErrorChunkView /> },
              { path: "errorboundary", element: <ErrorBoundaryView /> },
              {
                path: "timeout",
                element: <ErrorTimeoutView routeStart="/" />,
              },
            ],
          }
        : undefined,
      // ******************************
      // Fallback
      // ******************************
      {
        children: [{ path: "*", element: <Navigate to="" /> }],
      },
    ].filter((x) => x),
  },
];

// console.log("routeData", routeData);
const routesBrowser = createBrowserRouter(routeData);

export default routesBrowser;
