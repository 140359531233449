import { isEmpty } from "@library/common/database/rules/helpers";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

export default (args) => {
	const { dataSet, functionArgs } = args;

	if (!dataSet) throw `Error -- missing dataSet`;
	if (!functionArgs) throw `Error -- missing functionArgs`;

	const { mapping } = functionArgs;
	if (!mapping) throw `Error -- missing mapping`;

	// mapping = { jsonKey: "componentTag" }

	const JSONString = dataSet.getValue(
		new dataSetArgItem({
			useDefaultComponentSet: true,
			useDefaultComponentTag: true,
		}),
		"value"
	);

	const JSONData =
		JSONString === undefined ? undefined : JSON.parse(JSONString);

	Object.entries(mapping).forEach(([JSONkey, componentData]) => {
		let newValue = undefined;

		if (JSONData) {
			if (!(JSONkey in JSONData))
				throw `Error in splitJSON -- missing JSONkey ${JSONkey} in ${JSONString}`;
			newValue = JSONData[JSONkey];
		}
		const { componentTag, componentSet } = componentData;

		dataSet.setValue(
			new dataSetArgItem({
				key: "value",
				value: newValue,
				useDefaultComponentSet: componentSet === undefined,
				componentSet,
				componentTag,
			})
		);
	});

	return null;
};
