import React from "react";
import { ButtonToggle } from "../../../Buttons";
import { CardBorder } from "../../Cards";

const TabPanelViewCard = (props) => {
  const {
    cardHeaderText,
    validTabs,
    selectedData,
    setCurIdx,
    curIdx,
    tabsInHeader = false,
  } = props;

  const buttons = (
    <ul
      className={[
        "nav",
        tabsInHeader ? "nav-pills" : "flex-column",
      ].toClassName()}
    >
      {validTabs.map((x, i) => {
        return (
          <li className="nav-item" key={i}>
            <ButtonToggle
                    className="w-100"
                    isActive={i === curIdx}
                    onClick={() => setCurIdx(i)}
                    data-cy={x.dataCy}
            >
              {x.label}
            </ButtonToggle>
          </li>
        );
      })}
    </ul>
  );

  if (tabsInHeader) {
    return (
      <CardBorder className="tab-panel-card" header={buttons}>
        <div className="row">
          <div className="col-12 tab-panel-data p-3">{selectedData}</div>
        </div>
      </CardBorder>
    );
  }

  // ** Return statement **
  return (
    <CardBorder
      className="tab-panel-card"
      classNameBody="px-4"
      header={cardHeaderText}
    >
      <div className="row">
        <div className="col-3 d-flex align-items-stretch pe-5">
          <div className="tab-panel-buttons">{buttons}</div>
        </div>
        <div className="col-9 tab-panel-data p-3">{selectedData}</div>
      </div>
    </CardBorder>
  );
};

export default TabPanelViewCard;
