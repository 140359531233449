import React from "react";

// https://medium.com/trabe/catching-asynchronous-errors-in-react-using-error-boundaries-5e8a5fd7b971
const useDispatchWithErrors = (dispatch) => {
  if (!dispatch)
    throw `Error in useDispatchWithErrors -- missing dispatch function`;

  const [, setError] = React.useState();
  const fnRaiseError = React.useCallback(
    (e) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );

  // Final function
  return async (...args) => {
    try {
      return await dispatch(...args);
    } catch (e) {
      fnRaiseError(e);
    }
  };

  // return (...args) => {
  //   // return dispatch(...args)
  //   return Promise.resolve()
  //     .then(() => dispatch(...args))
  //     .catch(fnRaiseError);

  //   // try {
  //   //   return dispatch(...args);
  //   // } catch (e) {
  //   //   fnRaiseError(e);
  //   // }
  //   return dispatch(...args).catch((e) => {
  //     fnRaiseError(e);
  //   });
  // };
};

export default useDispatchWithErrors;
