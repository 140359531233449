export default (args) => {
  const baseArgs = {
    store: args.store,
    siteComponents: args.siteComponents,
    storeComponentsSite: args.baseComponents,
    storeComponentsCustom: args.baseComponents,
  };
  return {
    generateOccupation: require("./Occupation").default(baseArgs),
    generateAddress: require("./Address").default(baseArgs),
    generateVehicle: require("./Vehicle").default(baseArgs),
  };
};
