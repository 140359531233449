import React, { useState, useEffect, useCallback, useMemo } from "react";
// import _ from "lodash";

const useLookup = (serviceFunction, options = {}) => {
  const {
    preProcess = (response) => response,
    filterFunction = (item) => item,
    postProcess = (item) => item,
    fallbackValue = undefined,
  } = options;

  if (!serviceFunction) throw `Error in useService -- missing serviceFunction`;

  const [lastResponse, setLastResponse] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fnGetData = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      setLastResponse([]);
      const response = await serviceFunction();
      setIsLoading(false);
      setLastResponse(preProcess(response));
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const fnGetItem = (value) => {
    if (!lastResponse) return fallbackValue;
    if (isLoading) return fallbackValue;

    const foundItem = lastResponse.find((item) => filterFunction(item, value));

    if (!foundItem) return fallbackValue;

    return postProcess(foundItem);
  };

  useEffect(() => {
    fnGetData();
  }, []);

  // console.log("lastResponse", lastResponse);
  return fnGetItem;
  // const retData = {
  //   refresh: () => fnGetData(),
  //   data: lastResponse,
  //   isLoading: isLoading,
  //   isError: isError,
  //   findItem: fnGetItem,
  // };

  // return retData;
};

export default useLookup;

export const useLookupOptionList = (service) =>
  useLookup(service, {
    filterFunction: (item, value) => item.value === value,
    postProcess: (item) => item.label,
  });

export const useLookupOptionListWithCategories = (service) => {
  return useLookup(service, {
    preProcess: (response) => response.flatMap((x) => x.options),
    filterFunction: (item, value) => item.value === value,
    postProcess: (item) => item.label,
  });
};
