import dayjs from "dayjs";

import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import * as constants from "@library/common/constants/salus";

export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper").default,
  } = args;
  // alert("Risk logic")
  // NOTE: we need to include AdditionalInsuredSet as that is part of a RECALL, meaning that this code will then run
  // (ShowAdditionalDrivers ISN'T part of the recall, so won't trigger this code)
  const dh = new dataHelper({
    logging: false,
    loggingName: "riskLogic",
    mappings: [
      "ShowAdditionalDrivers",
      "AdditionalInsuredSet",
      "VehicleExtraDetailsConfirmation",
      "Confirmation",
    ],
    dataSet,
    functionArgs,
  });

  // console.log("dddddddd riskLogic", dh.mappings, dh.isPostRecall())
// throw `hhh`

  // console.log("ddddd addDriverTree", addDriverTree);
  // On abi code change, set VehicleExtraDetailsConfirmation to undefined

  if (dh.isPostRecall()) {
    const addDriverTree =
      dh.dataSet.getTree({
        argItem: functionArgs.AdditionalInsuredSet,
        componentTagHitlist: [],
      }) || [];

    // dh.setValue("ShowAdditionalDrivers", addDriverTree.length !== 0);

    dh.setValue("VehicleExtraDetailsConfirmation", true);
    dh.setValue("Confirmation", true);
  }

  dh.addError(
    "Confirmation",
    dh.getValue("Confirmation") === true
      ? undefined
      : `Sorry, we are unable to offer you cover if you do not agree to the above statements. If you would like to speak to someone please call ${
          require("config").default.lookups.telephoneNumbers.service
        }.`
  );

  dh.addError(
    "VehicleExtraDetailsConfirmation",
    dh.getValue("VehicleExtraDetailsConfirmation") === undefined
      ? ""
      : undefined
  );

  dh.commit();
};
