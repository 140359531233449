import React from "react";

const PriceItem = (props) => {

    const { value, postFix, className } = props;
  
    const [whole, decimal] = String(parseFloat(value).toFixed(2)).split(".");
  
    return (
      <div className={["mta-price-item", className].toClassName()}>
        <span className="pound">£{whole}</span>.
        <span className="pence">{decimal}</span>{" "}
        <span className="postfix">{postFix}</span>
      </div>
    );

};


export default PriceItem;