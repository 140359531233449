import dayjs from "dayjs";
// import functions from "../../../base";
import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

// const { dateComparison } = functions;

export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper").default,
  } = args;

  const dh = new dataHelper({
    mappings: ["BodySubStyle"],
    dataSet,
    functionArgs,
  });

  if (!dh.isPostRecall()) {
    dh.addErrorIfEmpty("BodySubStyle", "");
  }

  dh.commit();
};
