// features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createFnGenerateAction,
  createFnGenerateActionWithThunkAPI,
} from "../common";

const generateStore = (args = {}) => {
  const { storeName, services, selectors } = args;

  const fnGenerateAction = createFnGenerateAction(storeName);
  const fnGenerateActionWithThunkApi =
    createFnGenerateActionWithThunkAPI(storeName);

  // const rehydrateUserDetails = fnGenerateActionWithThunkApi(
  //   "rehydrateUserDetails",
  //   async (payload, thunkApi) => {
  //     const state = thunkApi.getState();

  //     if (true) {
  //       const [response] = await Promise.all([
  //         Promise.resolve({
  //           Status: "SUCCESS",
  //           UserInfo: selectors.getUserInfo(state),
  //         }),
  //         new Promise((resolve) => setTimeout(resolve, 500)),
  //       ]);

  //       return {
  //         isSuccess: response.Status === "SUCCESS",
  //         userInfo: response.UserInfo,
  //         message: response.Message,
  //       };
  //     }

  //     const newPayload = { ...payload };

  //     newPayload.userId = selectors.getUserId(state);

  //     return await services.getUserDetails(newPayload);
  //   }
  // );

  const create = fnGenerateAction("create", services.create);

  const activate = fnGenerateAction("activate", services.activate);
  const link = fnGenerateAction("link", services.link);

  const authenticate = fnGenerateAction("authenticate", services.authenticate);

  return {
    create,
    authenticate,
    activate,
    link,
    // rehydrateUserDetails
  };
};

export default generateStore;
