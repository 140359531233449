import React from "react"


const OpexDisplayInfo = (props) => {

    const { displayInfo, Title } = props;

	switch (displayInfo.Value) {
		case "ipid": {
			return (
				<p className={displayInfo.Value}>
					{displayInfo.Text} <a href={displayInfo.AdditionalText} target="_blank">{Title} Information Document</a>.
				</p>
			);
		}

		case "policy-wording": {
			return (
				<p className={displayInfo.Value}>
					For more details on the policy cover, including the benefits, terms, conditions and exclusions,
					it's important that you read and understand
					the <a href={displayInfo.AdditionalText} target="_blank" className="whitespace-no-wrap">Policy Wording</a> and 
					the product information.
				</p>
			);
		}
	}

	return (
		<p className={displayInfo.Value}>
			{displayInfo.Text}
		</p>
	);
};

export default OpexDisplayInfo;