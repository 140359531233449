import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  createContext,
  useContext,
} from "react";
import { connect, useSelector } from "react-redux";
import SiteLayoutError from "siteLayouts/Site/Error";
import MaintenanceView from "siteViews/Errors/Maintenance";
import { Markup } from "interweave";
import config from "config";
import services from "siteService";
import { generateCypressProps } from "siteFunctions/cypress";
import { useDispatch } from "siteFunctions/hooks";
import { actions, selectors } from "siteStore";
import _ from "lodash";

const SiteAlerts = (props) => {
  const {
    SiteType,
    className,
    Wrapper = ({ children }) => <>{children}</>,
    storageKey = "siteAlerts",
  } = props;

  ["SiteType"].argChecker(props);

  const bannerDataRaw = useSelector(
    (state) => selectors.storage.getValue(state, storageKey),
    require("lodash").isEqual
  );
  const bannerData = bannerDataRaw ? JSON.parse(bannerDataRaw) : undefined;

  const dispatch = useDispatch();

  useEffect(() => {
    const myFn = async () => {
      const response = await services.getSiteAlerts({ SiteType });

      const isEmpty = (function () {
        if (!response) return true;
        if (!_.isArray(response)) return true;
        if (response.length === 0) return true;
        return false;
      })();

      await dispatch(
        actions.storage.update({
          key: storageKey,
          value: isEmpty ? undefined : JSON.stringify(response),
          persist: false,
        })
      );
    };
    try {
      myFn();
    } catch (e) {
      //caught
    }
  }, []);

  const _className = ["site-alerts", className].toClassName();

  if (!bannerData) return null;

  return (
    <Wrapper>
      <div
        className={_className}
        {...generateCypressProps("site-alert", props)}
      >
        {bannerData.map((x, i) => {
          const _subClassName = [
            "alert",
            x.MessageType ? `alert-${x.MessageType}` : "alert-primary",
          ].toClassName();

          return (
            <div
              className={_subClassName}
              key={i}
              {...generateCypressProps("site-alert-child", props)}
            >
              <Markup content={x.MessageContent} />
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default SiteAlerts;
