import { createSlice } from "@reduxjs/toolkit";
import chalk from "chalk";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  createExtraReducersSet,
  extraReducersSet,
  consoleLog,
} from "../common";

const generate = (args = {}) => {
  const { storeName, snapshotActions, abortChecker } = args;

  // const consoleLog = (...args) => {
  //   if (!debug) return;
  //   console.log(chalk.green.bold("REDUX:"), storeName, ":", ...args);
  // };

  const initialState = {
    history: [],
  };

  const snapshotSlice = createSlice({
    name: storeName,
    initialState,
    reducers: {
      reset: (state) => {
        consoleLog({ storeName, subActionName: "reset" });
        return initialState;
      },
    },
    extraReducers: (builder) => {
      extraReducersSet(
        builder,
        snapshotActions.create,
        "create",
        {
          pending: (state) => {},
          fulfilled: (state, { payload }) => {
            state.history.push(payload);
          },
          rejected: (state, { payload }) => {},
        },
        { storeName, abortChecker }
      );

      extraReducersSet(
        builder,
        snapshotActions.commitAll,
        "commitAll",
        {
          pending: (state) => {},
          fulfilled: (state, { payload }) => {
            state.history = [];
          },
          rejected: (state, { payload }) => {},
        },
        { storeName, abortChecker }
      );

      extraReducersSet(
        builder,
        snapshotActions.commit,
        "commit",
        {
          pending: (state) => {},
          fulfilled: (state, { payload }) => {
            if (state.history.length === 0) return;
            state.history = state.history.slice(0, -1);
          },
          rejected: (state, { payload }) => {},
        },
        { storeName, abortChecker }
      );

      extraReducersSet(
        builder,
        snapshotActions.undo,
        "undo",
        {
          pending: (state) => {},
          fulfilled: (state, { payload }) => {
            if (state.history.length === 0) return;
            state.history = state.history.slice(0, -1);
          },
          rejected: (state, { payload }) => {},
        },
        { storeName, abortChecker }
      );

      // extraReducersSet(builder,
      //   snapshotActions.get,
      //   "get",
      //   {
      //     pending: (state) => {
      //       state.isLoading = true;
      //       state.hasLoadedData = false;
      //     },
      //     fulfilled: (state, { payload }) => {
      //       state.isLoading = false;
      //       // state.lastResponse = payload;
      //       state.hasLoadedData = payload ? true : false;
      //     },
      //     rejected: (state, { payload }) => {
      //       state.isLoading = false;
      //     },
      //   },
      //   { storeName, abortChecker }
      // ),
      // extraReducersSet(builder,
      //   snapshotActions.save,
      //   "save",
      //   {
      //     pending: (state) => {
      //       state.isSaving = true;
      //     },
      //     fulfilled: (state, { payload }) => {
      //       state.isSaving = false;
      //     },
      //     rejected: (state, { payload }) => {
      //       state.isSaving = false;
      //     },
      //   },
      //   { storeName, abortChecker }
      // ),
    },
  });

  return { reducer: snapshotSlice.reducer, actions: snapshotSlice.actions };
};

export default generate;
