import dayjs from "dayjs";

import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import { isNumeric } from "@library/common/database/rules/helpers";
//ncbOtherVehicleLogic

export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper").default,
  } = args;

  const dh = new dataHelper({
    mappings: ["OwnAndInsureAnotherVehicle", "OtherVehicleYearsNcd"],
    dataSet,
    functionArgs,
  });

  const _OwnAndInsureAnotherVehicle = dh.getValue("OwnAndInsureAnotherVehicle");
  const _OtherVehicleYearsNcd_visible = _OwnAndInsureAnotherVehicle === true;

  if (dh.isPostRecall()) {
    dh.setVisible("OtherVehicleYearsNcd", _OtherVehicleYearsNcd_visible);
  } else {
    if (dh.isUpdated("OwnAndInsureAnotherVehicle")) {
      if (_OwnAndInsureAnotherVehicle !== true) {
        dh.setValue("OtherVehicleYearsNcd", undefined);
      }
      dh.setVisible("OtherVehicleYearsNcd", _OtherVehicleYearsNcd_visible);
    }
  }

  if (!dh.isPostRecall()) {
    dh.addErrorIfEmpty("OwnAndInsureAnotherVehicle", "");
    if (_OtherVehicleYearsNcd_visible)
      dh.addErrorIfEmpty("OtherVehicleYearsNcd", "");
  }

  if (!_OtherVehicleYearsNcd_visible)
    dh.resetErrorsShow("OtherVehicleYearsNcd");

  dh.commit();
};
