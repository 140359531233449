import React from "react";
import { generateCypressProps } from "siteFunctions/cypress";

const PhoneNumberLink = (props) => {

	const { phonenumber, altLabel, className = "", isLink = false, isStrong = true } = props;

	const _className = ["whitespace-no-wrap", "link-black", className].toClassName();

	if (!isLink) {
		const noLinkClassName = ["tel-no-link", _className].toClassName();

		if (isStrong) {
			return (
				<strong
					className={noLinkClassName}
					{...generateCypressProps("telephone-number", props)}
				>
					{phonenumber}
				</strong>
			);
		}
		return (
			<span
				className={noLinkClassName}
				{...generateCypressProps("telephone-number", props)}
			>
				{phonenumber}
			</span>
		);
	}

	const displayText = altLabel != undefined ? altLabel : phonenumber;
	const display = isStrong ? (
		<strong>{displayText}</strong>
	) : (
		<span>{displayText}</span>
	);

	return (
		<a
			className={["tel-link", _className].toClassName()}
			href={`tel:${phonenumber}`}
			{...generateCypressProps("telephone-number", props)}
		>
			{display}
		</a>
	);
}

export default PhoneNumberLink;
