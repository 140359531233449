import React, { useEffect, useState, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import config from "config";
import services from "siteService";
import _ from "lodash";
import dateHelpers from "@library/common/helpers/misc/date";

import { useForm, Controller, useController, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions } from "siteStore";
import {
  isPostcode,
  isWebReference,
  isEmail,
} from "@library/common/helpers/validation";
import {
  TextBox,
  DatePickerTextBox,
  PasswordTextBoxForm,
  DropDownForm,
  Loading,
  TextArea,
  DevContainer,
  Panel,
  CheckBox,
} from "siteComponents";

// Dummy
const AutoQuoteAndBuy = (props) => {
  const {
    trigger,
    setValue,
    sessionKey = "AutoQuoteAndBuy",
    onClick = () => {},
  } = props;

  const [autoQuoteAndBuyData, setAutoQuoteAndBuyData] = useState(
    sessionStorage.getItem(sessionKey) || ""
  );

  const fnOnClick = (e) => {
    e.preventDefault();

    sessionStorage.setItem(sessionKey, autoQuoteAndBuyData);

    if (!autoQuoteAndBuyData) return;

    const objData = JSON.parse(autoQuoteAndBuyData);

    [
      ["webReference", objData.WebReference],
      ["dateOfBirth", dateHelpers.toString(new Date(objData.DateOfBirth))],
      ["postcode", objData.Postcode],
      ["emailAddress", objData.EmailAddress],
      ["emailAddressConfirm", objData.EmailAddress],
      ["username", objData.suggestedUsername],
      ["password", objData.suggestedPassword],
      ["passwordConfirm", objData.suggestedPassword],
      // ["securityQuestion1", "ARTIST"],
      ["securityAnswer1", objData.suggestedSecurityAnswer],
      // ["securityQuestion2", "FRIEND"],
      // ["securityAnswer2", "Bob"],
    ].forEach(([k, v]) =>
      setValue(k, v, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    );
    onClick();
    // trigger();
  };
  return (
    <DevContainer heading="AutoQuoteAndBuy">
      <p>Paste the results of AutoQuoteAndBuy below:</p>

      <button onClick={fnOnClick}>Populate form</button>
      <TextArea
        value={autoQuoteAndBuyData}
        onChange={(v) => {
          setAutoQuoteAndBuyData(v);
        }}
        style={{ height: "100px" }}
      />
    </DevContainer>
  );
};

export default AutoQuoteAndBuy;
