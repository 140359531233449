import { EndPointSet } from "../base/endpoints";

const baseArgs = {
  isStage: true,
  useStrictMode: false,
  google: { gtmId: "GTM-52MX29K" },
  liveChat: {
    useLiveChat: true,
    environment: "prod-euw2",
    deploymentId: "4374f567-f1da-4ff1-9281-2b6e119ef88a",
  },
  awsConfig: {},
  endPointSets: new EndPointSet({
    default: {
      url: "https://4qbnjdxz37.execute-api.eu-west-1.amazonaws.com/stella-live",
      apiKey: "CHvk1sEyXGarVCscxBWt71YFnX6Jnbr526Na5cXZ",
    },
    lookup: {
      url: "https://4qbnjdxz37.execute-api.eu-west-1.amazonaws.com/stella-live/GetFrontEndLists",
      apiKey: "CHvk1sEyXGarVCscxBWt71YFnX6Jnbr526Na5cXZ",
    },
    payment: {
      url: "https://1z6xkavxp8.execute-api.eu-west-1.amazonaws.com/stella-live",
      apiKey: "CHvk1sEyXGarVCscxBWt71YFnX6Jnbr526Na5cXZ",
    },
    ...Object.fromEntries(
      [
        ["userDetails", "UserDetails"],
        ["accounts", "Accounts"],
        ["documents", "UserDetails/Policy"],
        ["update", "Update"],
        ["test", "Test"],
      ].map(([key, path]) => {
        return [
          key,
          {
            url: `https://assmvrrusg.execute-api.eu-west-1.amazonaws.com/stella-live/${path}`,
            apiKey: "CHvk1sEyXGarVCscxBWt71YFnX6Jnbr526Na5cXZ",
          },
        ];
      })
    ),
  }),
};
const baseConfig = require("../base").default(baseArgs);

export default baseConfig;
