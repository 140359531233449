import { list, set, item } from "@library/common/database/classes";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";

import constants from "@library/common/constants/database";
import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import generateBaseRuleData from "../../../generateBaseRuleData";

const nameRule = new ruleItem(
	{
		...generateBaseRuleData("vehicle", "nameLogic"),
	},
	{
		Title: new dataSetArgItem({
			componentTag: "Title",
			useDefaultComponentSet: true,
		}),
		Surname: new dataSetArgItem({
			componentTag: "Surname",
			useDefaultComponentSet: true,
		}),
		Salutation: new dataSetArgItem({
			componentTag: "Salutation",
			useDefaultComponentSet: true,
		}),
		TitleGender: new dataSetArgItem({
			componentTag: "TitleGender",
			useDefaultComponentSet: true,
		}),
		Gender: new dataSetArgItem({
			componentTag: "Gender",
			componentSetPath: [".."],
		}),
	}
);

export default (args = {}) => {
	return new set({
		...args,

		items: {
			Forenames: new item({
				submitKey: "Forenames",
				exampleValue: "Daniel",
				label: "Forename",
				html: { maxLength: 30 },
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "trim"),
						},
						{}
					),
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter your forename",
						}
					),
					new ruleItem(
						{
							...generateBaseRuleData("general", "titleCase"),
						},
						{}
					),
				],
			}),
			Salutation: new item({
				submitKey: "Salutation",
				exampleValue: "Mr Dobson",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),
			// Suffix: new item({
			// 	// componentTag:  "Suffix",
			// 	submitKey: "Suffix",
			// 	exampleValue: null,
			// }),
			Surname: new item({
				// componentTag:  "Surname",
				submitKey: "Surname",
				exampleValue: "Dobson",
				label: "Surname",
				html: { maxLength: 30 },
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "trim"),
						},
						{}
					),
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter your surname.",
						}
					),

					new ruleItem(
						{
							...generateBaseRuleData("general", "titleCase"),
						},
						{}
					),
					new ruleItem(
						{
							...generateBaseRuleData("general", "minLength"),
						},
						{
							length: 2,
							errorMessage: "", // "Please enter a surname of 2 letters or more.",
						}
					),
					nameRule,
				],
			}),
			Title: new item({
				submitKey: "Title",
				exampleValue: "Mr",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter your title",
						}
					),
					nameRule,
				],
			}),

			TitleGender: new item({
				notes: "This is the combined field for both title and gender.",
				exampleValue: "{title:Mr, gender: M}",
				label: "Title",

				helpText: "",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter your title",
						}
					),
					nameRule,
					// new ruleItem({
					// 	...generateBaseRuleData("vehicle", "splitJSON"),},{
					// 	mapping: {
					// 		title: new dataSetArgItem({
					// 			componentTag: "Title",
					// 			useDefaultComponentSet: true,
					// 		}),
					// 		gender: new dataSetArgItem({
					// 			componentTag: "Gender",
					// 			componentSet: "Risk/Proposer",
					// 		}),
					// 	},
					// }),
				],
			}),
		},
	});
};
