import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import ConfirmModal from "./index";

const ConfirmModalButton = (props) => {
  const {
    onConfirm = () => {},
    onCancel = () => {},
    cancelText,
    confirmText,
    title,
    ButtonComponent,
    buttonText,
    children,
  } = props;

  [
    "cancelText",
    "confirmText",
    // "title",
    "ButtonComponent",
    // "buttonText",
  ].argChecker(props);

  const [showModalLogout, setShowModalLogout] = useState(false);

  return (
    <>
      {createPortal(
        <ConfirmModal
          title={title}
          enabled={showModalLogout}
          cancelText={cancelText}
          confirmText={confirmText}
          onConfirm={() => {
            setShowModalLogout(false);
            onConfirm();
          }}
          onCancel={() => {
            setShowModalLogout(false);
            onCancel();
          }}
        >
          {children}
        </ConfirmModal>,
        // Render the modal on the body, in case the ConfirmModalButton is within something that's hidden  (e.g. mobile hamburger menu)
        document.body
      )}

      {!showModalLogout && (
        <ButtonComponent
          onClick={() => {
            setShowModalLogout(true);
          }}
        >
          {buttonText}
        </ButtonComponent>
      )}
    </>
  );
};

export default ConfirmModalButton;
