import React, { useEffect } from "react";

const CarIcon = (props) => {

    return (
        <i className="icon policy-type-icon icon-car-side vehicle-details-icon" />
    );
}

export default CarIcon;
