import React from "react";

const ExternalLink = (props) => {

	const { href, label, className = "", target = "_blank", nowrap = true, title = "", dataCy } = props;

	const nowrapClass = nowrap ? "whitespace-no-wrap" : "";

	return (
		<a
			className={`ext-link ${nowrapClass} ${className}`}
			target={target}
			rel="noopener noreferrer"
			href={href}
			title={title}
			data-cy={dataCy}
		>
			{label}
		</a>
	);
}

export default ExternalLink;