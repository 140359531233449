import React, { useEffect } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { Markup } from "interweave";

const fnGetValue = (data, path) => {
  const retValue = _.get(data, path);
  return retValue;
};


const DisplayItem = (props) => {
  const {
    dataCy,
    label,
    value,
    html,
    path,
    data,
    separator = " ",
    fnFormat = (v) => v,

    outerClass = "",
    labelClass = "",
    valueClass = "",

    isHorizontalFormat = true,
    isHzntlMobile = true,
    hzntDataCols = 7,
    hzntSmDataCols = 7,
    hzntMdDataCols = 7,
    hzntXlDataCols = 8,
  } = props;

  const _path = _.isArray(path) ? path : [path];

  const _value = (function () {
    if (html) return <Markup content={html} tagName="div" />;
    if (value) return value;

    return _path.map((x, i) => {
      return (
        <>
          {i !== 0 && separator}
          {fnFormat(fnGetValue(data, x))}
        </>
      );
    });
  })();

  const _classNameOuter = [
    "row",
    "display-item",
    outerClass,
  ].toClassName();

  const _classNameLabel = [
    isHzntlMobile ? `col-${12 - hzntDataCols} col-sm-${12 - hzntSmDataCols} hz-mb` : "col-12",
    isHorizontalFormat ? `col-md-${12 - hzntMdDataCols} col-xl-${12 - hzntXlDataCols} hz-dp` : "",
    "data-label",
    labelClass,
  ].toClassName();

  const _classNameValue = [
    isHzntlMobile ? `col-${hzntDataCols} col-sm-${hzntSmDataCols} hz-mb` : "col-12",
    isHorizontalFormat ? `col-md-${hzntMdDataCols} col-xl-${hzntXlDataCols} hz-dp` : "",
    "data-value",
    valueClass,
  ].toClassName();

  return (
    <div className={_classNameOuter} data-cy={dataCy}>
      {label && (
        <div className={_classNameLabel}>{label}</div>
      )}
      <div className={_classNameValue}>{_value}</div>
    </div>
  );
};

export default DisplayItem;