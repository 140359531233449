import React, { useState, createContext, useContext } from "react";
import config from "config";

import ProtectedRoute from "../outlets/ProtectedRoute";
import ProtectedRouteForm from "../outlets/ProtectedRouteForm";
import { useSelector } from "react-redux";
import MtaExitContext from "../contextMtaExit";
import { actions, selectors } from "siteStore";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
  useOutletContext,
} from "react-router-dom";
import SiteLayout from "siteLayouts/Site";
import SiteWithImage from "siteLayouts/Site/WithImage";

import routesDocuments from "./documents";
import routesManageAccount from "./manageAccount";
import routesPolicy from "./policyViewer";

// import ChangePassword from "../Auth/Forms/Reset/ChangePassword";

// import routesMTA from "siteViews/MTA/routes";
const routesMTA = require("./mta").default;
const routesRenewals = require("./renewal").default;
// import DashboardView from "../Dashboard";

const DashboardView = React.lazy(() => import("siteViews/Dashboard"));
// **********************************
// Components
// **********************************

//Switches SITE LAYOUT if logged in or not
const SiteLayoutSwitcher = ({ children }) => {
  const isLoggedIn = useSelector(
    selectors.auth.isLoggedIn,
    require("lodash").isEqual
  );
  if (isLoggedIn) return <SiteLayout>{children}</SiteLayout>;
  return <SiteWithImage>{children}</SiteWithImage>;
};

const DashboardViewExtra = (props) => {
  const wasLastPathMta = useContext(MtaExitContext);

  return (
    <DashboardView
      {...props}
      autoRefreshUserInfo={!wasLastPathMta}
      showIsSessionSaving={wasLastPathMta}
    />
  );
};

const routes = [
  // **********************************
  // MAIN landing
  // **********************************
  {
    element: (
      <SiteLayoutSwitcher>
        <ProtectedRouteForm />
      </SiteLayoutSwitcher>
    ),
    children: [
      {
        path: "",
        element: <DashboardViewExtra />,
      },
    ],
  },

  // **********************************
  // SECURE routes
  // **********************************
  {
    element: (
      <SiteLayout>
        <ProtectedRoute routeLogin="/" />
      </SiteLayout>
    ),
    children: [
      {
        element: (
          // <React.Suspense fallback={<Loading />}>
          <Outlet />
          // </React.Suspense>
        ),
        children: [
          ...routesDocuments,
          ...routesMTA,
          ...routesManageAccount,
          ...routesPolicy,
          ...routesRenewals,
          // {
          //   path: "/passwordreset",
          //   element: <ChangePassword />,
          // },
        ],
      },
    ],
  },
];

export default routes;
