// PAGE: Address Generator in risk store.

import React, { useCallback, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
// import ButtonLabel from "@material-ui/icons/Search";

// import SubViewSearch from "./subComponents/Search";

// function usePrevious(value) {
// 	const ref = useRef();
// 	useEffect(() => {
// 		ref.current = value;
// 	});
// 	return ref.current;
// }

const requiredItemProps = [
  "HouseNameOrNumber",
  "address1",
  "address2",
  "address3",
  "address4",
  "postcode",
];

export default (args) => {
  // Generator function

  const { store, classes, services, storeComponentsCustom } = args;

  const SubViewSearchDefault = require("./subComponents/Search").default(args);
  const SubViewFoundDefault = require("./subComponents/Found").default(args);
  const SubViewEditDefault = require("./subComponents/Edit").default(args);

  //   const SubViewSearchDefault = undefined;
  //   const SubViewFoundDefault = undefined;
  //   const SubViewEditDefault = undefined;

  const { FormGroup: StoreFormGroup } = storeComponentsCustom;
  //   const { componentDefinition } = classes;

  return (props) => {
    const {
      itemProps,
      searchService,
      SubViewSearch = SubViewSearchDefault,
      SearchTemplate,
      FoundTemplate,
      SubViewFound = SubViewFoundDefault,
      SubViewEdit = SubViewEditDefault,
      SubViewSearch_PleaseWait = () => <p>Please wait...</p>,
      Container = ({ children }) => (
        <div className="address-lookup">{children}</div>
      ),

      SearchHeading,
      FoundHeading,
      EditHeading,
      Hyperlink = ({ children, onClick, title }) => {
        return (
          <button
            className="p-0 border-0 btn btn-link text-start align-top"
            onClick={onClick}
            title={title}
          >
            {children}
          </button>
        );
      },
    } = props;

    // ["SubViewSearch", "SubViewFound", "SubViewEdit"].forEach((k) => {
    //   if (!(k in props)) {
    //     console.log("ERROR INFO:", { props });
    //     throw `Error in generateAddress -- missing "${k}"`;
    //   }
    // });

    const views = {
      search: "search",
      searchAgain: "searchAgain",
      edit: "edit",
      found: "found",
      foundInvalid: "foundInvalid",
      foundMissing: "foundMissing",
    };

    if (!searchService)
      throw `Error in Address Generator -- missing searchService`;
    requiredItemProps.forEach((x) => {
      if (itemProps[x] === undefined)
        throw `Error in Address Generator -- missing DBitem = ${x}`;
    });

    const makeMapStateToProps = () => {
      const getValue = (state, path) =>
        store.selectors.userData.risk.metaData(state, path)?.value;

      const getIsValid = (state, path) =>
        store.selectors.errors.isValid(state, path);

      return (state) => {
        return {
          HouseNameOrNumber: getValue(state, itemProps.HouseNameOrNumber.path),
          address1: getValue(state, itemProps.address1.path),
          address2: getValue(state, itemProps.address2.path),
          address3: getValue(state, itemProps.address3.path),
          address4: getValue(state, itemProps.address4.path),
          postcode: getValue(state, itemProps.postcode.path),

          isValid: getIsValid(state, [
            itemProps.HouseNameOrNumber.path,
            itemProps.address1.path,
            itemProps.address2.path,
            itemProps.address3.path,
            itemProps.address4.path,
            itemProps.postcode.path,
          ]),
        };
      };
    };

    // const mapDispatchToProps = (dispatch) => ({
    //

    // update: (p) => {
    //   dispatch(actions.update(p));
    //   return Promise.resolve();
    // },
    // register: (p) => dispatch(actions.register(p)),
    // validate: (p) => dispatch(actions.validate(p)),
    // funcRegisterWithoutSaga: (p) => dispatch(actions.registerWithoutSaga(p)),
    // });

    const mapDispatchToProps = {
      update: store.actions.updateValue,
    };

    const pathList = Object.values(itemProps).map((item) => item.path);
    // const validateList = Object.values(itemProps).map(
    // 	({ componentId, componentSet }) =>
    // 		classes.validationListItem({
    // 			componentId,
    // 			componentSet,
    // 			debugInfo: "Address",
    // 		})
    // );

    // ************************************
    // ** MAIN component
    // ************************************
    const Component = connect(
      makeMapStateToProps,
      mapDispatchToProps
    )((props) => {
      const [currentView, setCurrentView] = useState(views.search);
      const {
        postcode,
        HouseNameOrNumber,
        address1,
        address2,
        address3,
        address4,
        update,
        // funcRegisterWithoutSaga,
        // register,
        // validate,
        forceShowErrors,

        // searchHeading = "Your home address",
        // foundHeading = "Your home address",
        // editHeading = "Your home address",
        onViewChange = () => {},
      } = props;
      // console.log("dddd", { props, itemProps });

      if (currentView === views.search && postcode !== undefined) {
        setCurrentView(views.found);
      }

      const [searchPostcode, setSearchPostcode] = useState();

      const setAddress = useCallback(
        (addressData = {}, resetErrors = false) => {
          console.log("retreived addressData", addressData);
          //errorsShow
          // NOTE: if the addressData is "undefined", we want to update the store with undefined values.
          const {
            HouseNameOrNumber,
            AddressLine1,
            AddressLine2,
            AddressLine3,
            AddressLine4,
            Postcode,
          } = addressData;

          const hitlist = [
            {
              path: itemProps.HouseNameOrNumber.path,
              value: HouseNameOrNumber,
            },
            {
              path: itemProps.address1.path,
              value: AddressLine1,
            },
            {
              path: itemProps.address2.path,
              value: AddressLine2,
            },
            {
              path: itemProps.address3.path,
              value: AddressLine3,
            },
            {
              path: itemProps.address4.path,
              value: AddressLine4,
            },
            {
              path: itemProps.postcode.path,
              value: Postcode,
            },
          ];

          hitlist.forEach((x) =>
            update({ path: x.path, value: x.value, hideError: resetErrors })
          );
        },
        []
      );

      // EFFECT: Startup
      useEffect(() => {
        console.log("ADDRESS", { itemProps });
        // const registerArray =
        //   Object.values(itemProps).map((x) => componentDefinition(x)) || [];

        // funcRegisterWithoutSaga(registerArray);
      }, []);

      // EFFECT: currentView change
      useEffect(() => {
        console.log("Address component", "currentView", ":", currentView);
        if (currentView === views.found) {
          if (!postcode) {
            setCurrentView(views.foundMissing);
            return;
          }
          if (!props.isValid) {
            setCurrentView(views.foundInvalid);
            return;
          }
        }
      }, [currentView, props.isValid]);

      useEffect(() => {
        onViewChange(currentView);
      }, [currentView]);

      switch (currentView) {
        case views.searchAgain:
        case views.search: {
          return (
            <Container>
              <StoreFormGroup
                databaseProps={{
                  pathList: [
                    itemProps.HouseNameOrNumber.path,
                    itemProps.address1.path,
                    itemProps.address2.path,
                    itemProps.address3.path,
                    itemProps.address4.path,
                    itemProps.postcode.path,
                  ],
                  label: SearchHeading,
                }}
              >
                <SubViewSearch
                  PleaseWait={SubViewSearch_PleaseWait}
                  pathList={pathList}
                  onClick={(searchPostcode, responseData) => {
                    setAddress(responseData);
                    setSearchPostcode(searchPostcode);
                    setCurrentView(views.found);
                  }}
                  forceShowErrors={forceShowErrors}
                  searchService={searchService}
                  SearchTemplate={SearchTemplate}
                />
              </StoreFormGroup>
            </Container>
          );
        }

        case views.foundMissing: {
          return (
            <Container>
              {/* <StoreFormGroup databaseProps={{ label: FoundHeading }}> */}
              <div className="py-3 px-md-3">
                Sorry, we couldn't find your address for postcode{" "}
                {searchPostcode}. <br />
                <Hyperlink
                  onClick={() => {
                    setAddress(undefined, true);
                    setCurrentView(views.searchAgain);
                  }}
                >
                  Try again
                </Hyperlink>{" "}
                or please enter your address manually below.
              </div>
              <SubViewEdit
                DBitems={itemProps}
                editHeading={EditHeading}
                forceShowErrors={forceShowErrors}
              />
              {/* </StoreFormGroup> */}
            </Container>
          );
        }
        case views.foundInvalid: {
          return (
            <Container>
              {/* <StoreFormGroup databaseProps={{ label: EditHeading }}> */}
              <div className="py-3 px-md-3">
                Please correct your address below, or{" "}
                <Hyperlink
                  onClick={() => {
                    setAddress(undefined, true);
                    setCurrentView(views.searchAgain);
                  }}
                >
                  search again
                </Hyperlink>
              </div>

              <SubViewEdit
                DBitems={itemProps}
                editHeading={EditHeading}
                forceShowErrors={true}
              />
              {/* </StoreFormGroup> */}
            </Container>
          );
        }
        case views.found: {
          return (
            <Container>
              <StoreFormGroup
                databaseProps={{
                  label: FoundHeading,
                  pathList: [
                    itemProps.HouseNameOrNumber.path,
                    itemProps.address1.path,
                    itemProps.address2.path,
                    itemProps.address3.path,
                    itemProps.address4.path,
                    itemProps.postcode.path,
                  ],
                }}
              >
                <SubViewFound
                  searchPostcode={searchPostcode}
                  postcode={postcode}
                  HouseNameOrNumber={HouseNameOrNumber}
                  address1={address1}
                  address2={address2}
                  address3={address3}
                  address4={address4}
                  onEdit={() => {
                    setCurrentView(views.edit);
                  }}
                  onSearch={() => {
                    setCurrentView(views.searchAgain);
                    setAddress(undefined, true);
                  }}
                  heading={FoundHeading}
                  FoundTemplate={FoundTemplate}
                />
              </StoreFormGroup>
            </Container>
          );
        }
        case views.edit: {
          return (
            <Container>
              {/* <StoreFormGroup databaseProps={{ label: EditHeading }}> */}
              <div className="py-3 px-md-3">
                Please edit your address below, or{" "}
                <Hyperlink
                  onClick={() => {
                    setAddress(undefined, true);
                    setCurrentView(views.searchAgain);
                  }}
                >
                  search again
                </Hyperlink>
              </div>

              <SubViewEdit
                DBitems={itemProps}
                forceShowErrors={forceShowErrors}
              />
              {/* </StoreFormGroup> */}
            </Container>
          );
        }
        default: {
          return null;
        }
      }
    });

    return Component;
  };
};
