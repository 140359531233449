import React from "react";
import ContactDetails from "../../ContactDetails";
import _logo from "siteAssets/images/stella-logo-colour.svg";
import _logoNegative from "siteAssets/images/stella-logo-negative.svg";
import ScreenFinder from "siteComponents/dev/ScreenFinder";

import {
  Outlet,
  Link,
  NavLink,
  useNavigate,
  useLocation,
} from "react-router-dom";

const Header = (props) => {
  const { logoHeader = _logo } = props;

  return (
    <div className="row">
      {/* Top left image */}
      <div className="col-6 d-flex align-items-center justify-content-start nav-left">
        <img
          className="brand-img img-fluid"
          src={logoHeader}
          alt="Stella Insurance"
        />
      </div>

      {/* Top right contact details */}
      <div className="col-6 d-flex align-items-center justify-content-end nav-right">
        <ContactDetails />
      </div>
    </div>
  );
};

export default Header;
