// features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import chalk from "chalk";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// import { importData as importUserInfo } from "./helpers/userInfo";
import {
  createExtraReducersSet,
  extraReducersSet,
  consoleLog,
} from "../common";

const generate = (args = {}) => {
  const { storeName, authActions, abortChecker } = args;

  const initialState = {
    // _userInfo: {}, // for user object
    // userInfoRehydrating: false,

    userToken: undefined, // for storing the JWT
    // error: undefined,
    // success: false, // for monitoring the registration process.
    createLastResponse: undefined,
    createLoading: false,
    createError: false,

    activateLastResponse: undefined,
    activateLoading: false,
    activateError: false,

    authenticateLastResponse: undefined,
    authenticateLoading: false,
    authenticateError: false,

    linkLastResponse: undefined,
    linkLoading: false,
    linkError: false,

    // sessionVariables: {},
  };

  const authSlice = createSlice({
    name: storeName,
    initialState,
    reducers: {
      resetActivate(state) {
        state.activateLastResponse = undefined;
        state.activateLoading = false;
        state.activateError = false;
      },
      resetAuthenticate(state) {
        state.authenticateLastResponse = undefined;
        state.authenticateLoading = false;
        state.authenticateError = false;
      },
      resetCreate(state) {
        state.createLastResponse = undefined;
        state.createLoading = false;
        state.createError = false;
      },
      resetLink(state) {
        state.linkLastResponse = undefined;
        state.linkLoading = false;
        state.linkError = false;
      },
      // setSessionVariable(state, { payload = {} }) {
      //   const { key, value } = payload;
      //   if (!key) return;
      //   if (!value) return;
      //   state.sessionVariables[key] = value;
      // },
      logout() {
        consoleLog({ storeName, subActionName: "logout" });
        return initialState;
      },
    },
    extraReducers: (builder) => {
      // extraReducersSet(builder,
      //   authActions.rehydrateUserDetails,
      //   "rehydrateUserDetails",
      //   {
      //     pending: (state) => {
      //       // state.userInfo = undefined;
      //       state.userInfoRehydrating = true;
      //     },
      //     fulfilled: (state, { payload }) => {
      //       state._userInfo = payload.userInfo;
      //       state.userInfoRehydrating = false;
      //     },
      //     rejected: (state, data) => {
      //       state.userInfoRehydrating = false;
      //     },
      //   },
      //   { storeName, abortChecker }
      // ),

      // register
      extraReducersSet(
        builder,
        authActions.create,
        "create",
        {
          pending: (state) => {
            state.createLoading = true;
            state.createLastResponse = undefined;
            state.createError = false;
          },
          fulfilled: (state, { payload }) => {
            state.createLoading = false;
            state.createLastResponse = payload;
          },
          rejected: (state, data) => {
            state.createLoading = false;
            state.createError = true;
            state.createLastResponse = { isTechnicalError: true };
          },
        },
        { storeName, abortChecker }
      );

      // activate
      extraReducersSet(
        builder,
        authActions.activate,
        "activate",
        {
          pending: (state) => {
            state.activateLoading = true;
            state.activateLastResponse = undefined;
            state.activateError = false;
          },
          fulfilled: (state, { payload }) => {
            state.activateLoading = false;
            state.activateLastResponse = payload;
            state.userToken = payload.userToken;
            // state._userInfo = payload.userInfo;
          },
          rejected: (state, data) => {
            state.activateLoading = false;
            state.activateError = true;
            state.activateLastResponse = { isTechnicalError: true };
          },
        },
        { storeName, abortChecker }
      );
      // link
      extraReducersSet(
        builder,
        authActions.link,
        "link",
        {
          pending: (state) => {
            state.linkLoading = true;
            state.linkLastResponse = undefined;
            state.linkError = false;
          },
          fulfilled: (state, { payload }) => {
            state.linkLoading = false;
            state.linkLastResponse = payload;
            state.userToken = payload.userToken;
            // state._userInfo = payload.userInfo;
          },
          rejected: (state, data) => {
            state.linkLoading = false;
            state.linkError = true;
            state.linkLastResponse = { isTechnicalError: true };
          },
        },
        { storeName, abortChecker }
      );
      // authenticate
      extraReducersSet(
        builder,
        authActions.authenticate,
        "authenticate",
        {
          pending: (state) => {
            state.authenticateLoading = true;
            state.authenticateError = false;
            state.authenticateLastResponse = undefined;
          },
          fulfilled: (state, { payload }) => {
            state.authenticateLoading = false;
            state.authenticateLastResponse = payload;
            state.userToken = payload.userToken;
            // state._userInfo = payload.userInfo;
          },
          rejected: (state, data) => {
            state.authenticateLoading = false;
            state.authenticateError = true;
            state.authenticateLastResponse = { isTechnicalError: true };
          },
        },
        { storeName, abortChecker }
      );
    },
  });

  return { reducer: authSlice.reducer, actions: authSlice.actions };
};

export default generate;
