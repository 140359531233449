// features/Documents/DocumentsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  createFnGenerateAction,
  createFnGenerateActionWithThunkAPI,
} from "../common";

const generateStore = (args = {}) => {
  const { storeName, selectors = {}, riskStoreConfig } = args;

  const fnGenerateAction = createFnGenerateAction(storeName);
  const fnGenerateActionWithThunkApi =
    createFnGenerateActionWithThunkAPI(storeName);

  const reset = fnGenerateActionWithThunkApi(
    "reset",
    async (payload = {}, thunkApi) => {
      if (riskStoreConfig.storeType === "legacy") {
        await new Promise((resolve) => {
          thunkApi.dispatch(
            riskStoreConfig.riskStore.actions.reset({}, () => resolve())
          );
        });
      }

      if (riskStoreConfig.storeType === "quoteAndBuy") {
        await thunkApi.dispatch(riskStoreConfig.riskStore.actions.reset());
      }

      return { request: payload };
    }
  );

  // **************************************************************
  // RISK EDIT
  // **************************************************************

  const riskActions = (function () {
    if (riskStoreConfig.storeType === "legacy") {
      const updateRisk = fnGenerateActionWithThunkApi(
        "updateRisk",
        async (payload, thunkApi) => {
          const newPayload = (_.isArray(payload) ? payload : [payload]) // Convert none-arrays into arrays
            .map((databaseProps) => {
              return new riskStoreConfig.riskStore.classes.componentDefinition({
                ...databaseProps,
              });
            });

          await new Promise((resolve) => {
            thunkApi.dispatch(
              riskStoreConfig.riskStore.actions.update(
                newPayload,
                () => resolve() // callback functions
              )
            );
          });
        }
      );

      const updateRiskByGroup = fnGenerateActionWithThunkApi(
        "updateRiskByGroup",
        async (payload = {}, thunkApi) => {
          const { group, ...data } = payload;
          await new Promise((resolve) => {
            thunkApi.dispatch(
              riskStoreConfig.riskStore.actions.update(
                { group, ...data },
                () => resolve() // callback functions
              )
            );
          });
        }
      );

      const updateRiskListAdd = fnGenerateActionWithThunkApi(
        "updateRiskListAdd",
        async (payload = {}, thunkApi) => {
          const { databaseKey, componentId, group, componentSet } = payload;

          if (!componentId)
            throw `Error in updateRiskListAdd -- missing ComponentId`;
          if (!componentSet)
            throw `Error in updateRiskListAdd -- missing componentSet`;
          if (!databaseKey)
            throw `Error in updateRiskListAdd -- missing databaseKey`;

          const fnAppendNode = async (componentId) => {
            const mainNode = riskStoreConfig.database.findTreeItem(databaseKey);

            //Register the children
            const registerList = (
              riskStoreConfig.riskStore.functions.registerDatabase({
                node: mainNode,
                useDefaults: true,
                parentComponentSet: componentSet,
              }) || []
            )
              .map((x) => {
                if (!("data" in x)) return x;
                const _newItem = { ...x, data: { ...x.data, group } };
                return _newItem;
              })
              // Register the componentSet
              // #2940 -- append the "componentSet" validation to the hitlist,
              // so that the VALIDATION call below *also* runs against the "componentSet"
              .concat(
                riskStoreConfig.riskStore.classes.componentDefinition({
                  componentId: componentId,
                  componentSetChild: componentSet,
                })
              );

            await thunkApi.dispatch(
              riskStoreConfig.riskStore.actions.registerWithoutSaga(
                registerList
              )
            );

            // Validate (Needs the componentSet to be registered first (done above))
            await new Promise((resolve) => {
              thunkApi.dispatch(
                riskStoreConfig.riskStore.actions.validate(
                  registerList,
                  true, //postRegistration
                  false, //postRecall
                  () => resolve()
                )
              );
            });
          };

          return await new Promise(async (resolve) => {
            await fnAppendNode(componentId);
            resolve();
          });
        }
      );

      const updateRiskListRemove = fnGenerateActionWithThunkApi(
        "updateRiskListRemove",
        async (payload = {}, thunkApi) => {
          const { componentSet } = payload;
          console.log("updateRiskListRemove", payload);

          if (!componentSet)
            throw `Error in updateRiskListAdd -- missing componentSet`;

          const fnRemoveNode = async (componentSet) => {
            await thunkApi.dispatch(
              riskStoreConfig.riskStore.actions.deleteComponentSet({
                componentSet: componentSet,
              })
            );
          };

          return await new Promise(async (resolve) => {
            await fnRemoveNode(componentSet);
            resolve();
          });
        }
      );

      return {
        updateRisk,
        updateRiskListAdd,
        updateRiskListRemove,
        updateRiskByGroup,
      };
    }

    if (riskStoreConfig.storeType === "quoteAndBuy") {
      const runRules = fnGenerateActionWithThunkApi(
        "runRules",
        async (payload, thunkApi) => {
          console.log("runRules", payload, { riskStoreConfig });

          await thunkApi.dispatch(
            riskStoreConfig.riskStore.actions.runRules({
              path: payload.riskPath,
            })
          );
        }
      );

      const updateRisk = fnGenerateActionWithThunkApi(
        "updateRisk",
        async (payload, thunkApi) => {
          console.log("updateRisk", payload, { riskStoreConfig });

          if (_.isArray(payload)) {
            console.log("ERROR INFO: ", { payload });
            throw `Error in MTA RISK STORE updateRisk -- payload is an array`;
          }

          ["path", "value"].forEach((key) => {
            if (!(key in payload)) {
              console.log("ERROR INFO: ", { payload });
              throw `Error in MTA RISK STORE updateRisk -- missing key "${key}"`;
            }
          });

          Object.keys(payload).forEach((key) => {
            if (!["path", "value", "hideError"].includes(key)) {
              console.log("ERROR INFO: ", { payload });
              throw `Error in MTA RISK STORE updateRisk -- found invalid key "${key}"`;
            }
          });

          const hideError = payload.hideError;

          await thunkApi.dispatch(
            riskStoreConfig.riskStore.actions.updateValue({
              path: payload.path,
              value: payload.value,
            })
          );

          if (hideError)
            await thunkApi.dispatch(
              riskStoreConfig.riskStore.actions.updateErrorShow({
                path: payload.path,
                value: false,
              })
            );
        }
      );

      const registerRiskItem = fnGenerateActionWithThunkApi(
        "registerRiskItem",
        async (payload, thunkApi) => {
          console.log("registerRiskItem", payload, { riskStoreConfig });

          await thunkApi.dispatch(
            riskStoreConfig.riskStore.actions.create({
              path: payload.path,
              defaultValue: payload.defaultValue,
              pathList: payload.pathList,
              group: payload.group,
            })
          );
        }
      );

      const updateErrorsShowByGroup = fnGenerateActionWithThunkApi(
        "updateErrorsShowByGroup",
        async (payload = {}, thunkApi) => {
          const { group, value } = payload;

          const state = thunkApi.getState();
          const pathList =
            riskStoreConfig.riskStore.selectors.userData.risk.getGroupPaths(
              state,
              group
            );

          await thunkApi.dispatch(
            riskStoreConfig.riskStore.actions.updateErrorShow({
              pathList: pathList,
              value: value,
            })
          );

          return;
          pathList.forEach(async (path) => {
            console.log("updateErrorsShowByGroup", path, "to", value);

            await thunkApi.dispatch(
              riskStoreConfig.riskStore.actions.updateErrorShow({
                path: path,
                value: value,
              })
            );
          });
          return;
          throw `not implemented updateRiskByGroup`;

          // const { group, ...data } = payload;
          // await new Promise((resolve) => {
          //   thunkApi.dispatch(
          //     riskStoreConfig.riskStore.actions.update(
          //       { group, ...data },
          //       () => resolve() // callback functions
          //     )
          //   );
          // });
        }
      );

      const updateRiskListAdd = fnGenerateActionWithThunkApi(
        "updateRiskListAdd",
        async (payload = {}, thunkApi) => {
          const {
            // databaseKey,
            // componentId,
            group,
            riskPath,
            childrenPathList = [],
          } = payload;

          await thunkApi.dispatch(
            riskStoreConfig.riskStore.actions.create({
              path: [riskPath, "+"].join(""),
            })
          );

          if (childrenPathList.length >= 1) {
            await thunkApi.dispatch(
              riskStoreConfig.riskStore.actions.create({
                pathList: childrenPathList,
                group: group,
              })
            );
          }
        }
      );

      const updateRiskListRemove = fnGenerateActionWithThunkApi(
        "updateRiskListRemove",
        async (payload = {}, thunkApi) => {
          console.log("payload", payload);

          await thunkApi.dispatch(
            riskStoreConfig.riskStore.actions.removeArrayItem({
              path: payload.riskPath,
            })
          );

          return;
          throw `not implemented updateRiskListRemove`;

          const { componentSet } = payload;
          console.log("updateRiskListRemove", payload);

          if (!componentSet)
            throw `Error in updateRiskListAdd -- missing componentSet`;

          const fnRemoveNode = async (componentSet) => {
            await thunkApi.dispatch(
              riskStoreConfig.riskStore.actions.deleteComponentSet({
                componentSet: componentSet,
              })
            );
          };

          return await new Promise(async (resolve) => {
            await fnRemoveNode(componentSet);
            resolve();
          });
        }
      );

      return {
        runRules,
        updateRisk,
        updateRiskListAdd,
        updateRiskListRemove,
        updateErrorsShowByGroup,
        registerRiskItem,
      };
    }
  })();
  // **************************************************************
  // END RISK EDIT
  // **************************************************************

  const getQuote = fnGenerateActionWithThunkApi(
    "getQuote",
    async (payload = {}, thunkApi) => {
      // const { storeName: storeNameMta, policyId } = payload;
      const state = thunkApi.getState();
      const storeNameMta = selectors.riskStore.getStoreName(state);
      const quotePayload = selectors.payloads.quote(state, storeNameMta);

      // console.log("dsadsadsadasd getQuote() ", { payload });
      const response = await riskStoreConfig.services.getQuote({
        ...quotePayload,
        ...payload,
      });

      return { quotePayload, response };
    }
  );

  const getSavedQuotes = fnGenerateActionWithThunkApi(
    "getSavedQuotes",
    async (payload = {}, thunkApi) => {
      // const { storeName: storeNameMta, policyId } = payload;
      // const state = thunkApi.getState();
      // const storeNameMta = selectors.riskStore.getStoreName(state);
      // const quotePayload = selectors.getQuotePayload(state, storeNameMta);

      const response = await riskStoreConfig.services.getSavedQuotes(payload);

      return response;
    }
  );

  const paymentLauncher = fnGenerateActionWithThunkApi(
    "paymentLauncher",
    async (payload, thunkApi) => {
      // const { dataPaths } = payload;
      // console.log("Edadaffasfdsaf", { dataPaths });
      // const state = thunkApi.getState();
      // const storeNameMta = selectors.riskStore.getStoreName(state);
      // const quotePayload = selectors.getQuotePayload(state, storeNameMta);

      // const paymentLauncherPayload = selectors.payloads.paymentLauncher(state);

      const response = await riskStoreConfig.services.paymentLauncher(payload);

      return response;
    }
  );

  // const getPurchaseResponse = fnGenerateActionWithThunkApi(
  //   "getPurchaseResponse",
  //   async (payload, thunkApi) => {
  //     // const { storeName: storeNameMta, policyId } = payload;
  //     const state = thunkApi.getState();
  //     // const storeNameMta = selectors.riskStore.getStoreName(state);
  //     // const quotePayload = selectors.getQuotePayload(state, storeNameMta);

  //     const servicePayload = payload;
  //     const response = await riskStoreConfig.services.getPurchaseResponse(servicePayload);

  //     return response;
  //   }
  // );

  // const reset = fnGenerateActionWithThunkApi(
  //   "reset",
  //   async (payload, thunkApi) => {}
  // );

  const {
    // start,
    restart,
    loadData,
  } = (function () {
    const fnGenerateResponse = (thunkApi) => {
      const postState = thunkApi.getState();
      return {
        salusData: selectors.riskStore.getSalusData(postState),
        riskData: selectors.riskStore.getStoreState(postState),
      };
    };

    const loadData = fnGenerateActionWithThunkApi(
      "loadData",
      async (payload = {}, thunkApi) => {
        const { policyData, salusData } = payload;
        return { policyData, salusData };
      }
    );

    // const start = fnGenerateActionWithThunkApi(
    //   "start",
    //   async (payload = {}, thunkApi) => {
    //     const fnGenerateError = (message = "Oops! There's been an error.") => {
    //       return thunkApi.rejectWithValue({
    //         isSuccess: false,
    //         message,
    //       });
    //     };
    //     console.log("start", { payload });
    //     const { riskStore, services } = riskStoreConfig;
    //     const { loadRisk } = riskStore.actions;

    //     ["eventId", "policyId"].forEach((k) => {
    //       // NOTE: policyData is used in the "slice"
    //       if (!(k in payload)) {
    //         throw `Error in start action -- missing "${k}"`;
    //       }
    //     });

    //     const { eventId, policyId } = payload;

    //     const response = await services.getSalusRisk({ policyId, eventId });

    //     if (!response) return fnGenerateError();
    //     if (!response.isSuccess) return fnGenerateError();

    //     const { data: salusData } = response;

    //     await thunkApi.dispatch(loadRisk(_.pick(salusData, ["Risk"])));

    //     // Get the processed salusData out of the risk store

    //     return fnGenerateResponse(thunkApi);
    //   }
    // );

    const restart = fnGenerateActionWithThunkApi(
      "restart",
      async (payload, thunkApi) => {
        //** This takes the contents of baseSalusData stored in the MTA store and copies into the main data
        console.log("restart", { payload });

        const { riskStore, services } = riskStoreConfig;
        const { loadRisk, reset } = riskStore.actions;

        const state = thunkApi.getState();
        const baseSalusData = selectors.private.getUserInfoSalus(state);

        if (!baseSalusData) {
          throw `Error in restart -- missing baseSalusData`;
        }

        await thunkApi.dispatch(reset()); //#3129
        await thunkApi.dispatch(loadRisk(_.pick(baseSalusData, ["Risk"])));
        return fnGenerateResponse(thunkApi);
      }
    );

    return {
      // start,
      restart,
      loadData,
    };
  })();

  // const getPolicyDocuments = fnGenerateAction(
  //   "getPolicyDocuments",
  //   services.getPolicyDocuments
  // );

  // const rehydratePolicyDocuments = fnGenerateActionWithThunkApi(
  //   "rehydratePolicyDocuments",
  //   async (payload, thunkApi) => {
  //     const newPayload = { ...payload };

  //     //Auto add the userId if possible
  //     if (selectors && selectors.auth) {
  //       const state = thunkApi.getState();
  //       newPayload.userId = selectors.dashboard.getUserId(state);
  //     }

  //     return await services.getPolicyDocuments(newPayload);
  //   }
  // );

  // const reset = createAsyncThunk(`${storeName}/reset`, async () => {});

  return {
    loadData,
    // start,
    restart,
    getQuote,
    getSavedQuotes,
    reset,

    paymentLauncher,
    // getPurchaseResponse,
    ...riskActions,
  };
};

export default generateStore;
