import services from "siteService";
import _ from "lodash";
import { retryFunction } from "moveToLibrary/functions";

const dummyRenewalResponse = {
  Status: "SUCCESS",
  RenewalSummary: {
    PreviousYear: {
      Fees: 1111,
      Premium: 123.45,
      TotalSellingPrice: 2345.22,
    },
    ConsecutiveRenewals: 2,
    YearsNcb: 3,
    ProtectedNcb: true,
    CoverFrom: "2023-12-01T00:00:00",
    CoverTo: "2024-12-01T00:00:00",
    Proposer: {
      Name: {
        Forenames: "DUMMYDATA",
        Salutation: "Mr FALLBACK",
        Surname: "FALLBACK",
        Title: "Mr",
      },
      DateOfBirth: "1967-07-31T00:00:00",
      ResidentSince: "1967-07-31T00:00:00",
      IsResidentSinceBirth: true,
      FullTimeOccupation: {
        Type: "Employee",
        OccCode: "B15",
        EmpCode: "160",
      },
      PartTimeOccupation: {
        Type: "None",
        OccCode: "220",
        EmpCode: "186",
      },
      HasSecondOccupation: false,
      Licence: {
        Category: "CatB",
        Convictions: [
          {
            Code: "SP30",
            Date: "2019-01-01T00:00:00",
            Disqualification: "0",
            Fine: "0",
            OffenceDate: "2019-01-01T00:00:00",
            Points: "0",
          },
        ],
        Date: "2010-01-01T00:00:00",
        Type: "Full Licence",
      },
      Gender: "M",
      InsuranceRefused: false,
      MaritalStatus: "S",
      DvlaRestriction: "N",
      NonRTAConviction: false,
      Smoker: false,
      AccessToOtherVehicles: "BLANK",
      Tests: "1",
      VehicleUse: "MainUser",
      NumberOfOtherVehicles: "0",
      DriverType: "Prpsr",
      StarRider: "No",
      ConsentForMedicalConditions: "NotKnown",
      ConsentForConvictions: "NotKnown",
      MainTelephone: {
        Id: "179633701",
        Usage: "Home",
        AreaCode: "07777",
        PhoneNumber: "777777",
        TelephoneNumber: "07777777777",
      },
      PaymentMethod: "F",
      Id: "319291",
      Address: {
        AbodeType: "House",
        Usage: "Main",
        CountryCode: "GB",
        Postcode: "CF82 8AA",
        HouseNameOrNumber: "3",
        AddressLine1: "Pengam Road",
        AddressLine2: "Ystrad Mynach",
        AddressLine3: "HENGOED",
        AddressLine4: "Mid Glamorgan",
      },
      ClientCategory: "STANDARD",
      ClientStatus: "Active",
      Claims: [
        {
          ClaimType: "M",
          ClaimDate: "2020-01-01T00:00:00",
          OwnDamageCost: "0",
          ThirdPartyCost: "0",
          NcbPrejudiced: false,
          PersonalInjury: false,
          UnderPolicyholdersCover: false,
          ClaimantName: "Mr G Lee",
          Code: "M051",
          BrokerReference: "319291/001/C1",
          Status: "Closed",
        },
      ],
      TelephoneNumbers: [
        {
          Id: "179633701",
          Usage: "Home",
          AreaCode: "07777",
          PhoneNumber: "777777",
          TelephoneNumber: "07777777777",
        },
        {
          Id: "179633701",
          Usage: "Home",
          AreaCode: "07777",
          PhoneNumber: "777777",
          TelephoneNumber: "07777777777",
        },
        {
          Id: "179633701",
          Usage: "Home",
          AreaCode: "07777",
          PhoneNumber: "777777",
          TelephoneNumber: "07777777777",
        },
        {
          Id: "179633701",
          Usage: "Home",
          AreaCode: "07777",
          PhoneNumber: "777777",
          TelephoneNumber: "07777777777",
        },
        {
          Id: "179633703",
          Usage: "Mobile",
          AreaCode: "07777",
          PhoneNumber: "777777",
          TelephoneNumber: "07777777777",
        },
      ],
      Email: "victoria.turner@freedomservicesgroup.co.uk",
      MotoringOrg: "BLANK",
      RelationshipToProposer: "Proposer",
      Userfields: [
        {
          ScreenPrompt: "Are you happy for us to contact you by email?",
          IsList: true,
          Code: "MRKEMAIL",
          Response: "Yes",
          ShortResponse: "Y",
        },
        {
          ScreenPrompt: "Are you happy for us to contact you by phone?",
          IsList: true,
          Code: "MRKPHONE",
          Response: "Yes",
          ShortResponse: "Y",
        },
        {
          ScreenPrompt: "Are you happy for us to contact you by post?",
          IsList: true,
          Code: "MRKPOST",
          Response: "Yes",
          ShortResponse: "Y",
        },
        {
          ScreenPrompt: "Are you happy for us to contact you by SMS?",
          IsList: true,
          Code: "MRKSMS",
          Response: "Yes",
          ShortResponse: "Y",
        },
      ],
    },
    Drivers: [
      {
        Name: {
          Forenames: "Gurjita",
          Salutation: "Miss Lee",
          Surname: "Lee",
          Title: "Miss",
        },
        DateOfBirth: "1970-01-01T00:00:00",
        ResidentSince: "1970-01-01T00:00:00",
        IsResidentSinceBirth: true,
        FullTimeOccupation: {
          Type: "Retired",
          OccCode: "R09",
          EmpCode: "947",
        },
        PartTimeOccupation: {
          Type: "None",
          OccCode: "220",
          EmpCode: "186",
        },
        HasSecondOccupation: false,
        Licence: {
          Category: "CatB",
          Convictions: [
            {
              Code: "SP30",
              Date: "2020-02-02T00:00:00",
              Disqualification: "0",
              Fine: "0",
              OffenceDate: "2020-02-02T00:00:00",
              Points: "0",
            },
          ],
          Date: "2000-01-01T00:00:00",
          Type: "Full Licence",
        },
        Gender: "F",
        InsuranceRefused: false,
        MaritalStatus: "S",
        DvlaRestriction: "N",
        NonRTAConviction: false,
        Smoker: false,
        AccessToOtherVehicles: "BLANK",
        Tests: "0",
        VehicleUse: "Frequently",
        DriverType: "NmdDrvr",
        StarRider: "No",
        ConsentForMedicalConditions: "NotKnown",
        ConsentForConvictions: "NotKnown",
        Id: "319306",
        Claims: [
          {
            ClaimType: "M",
            ClaimDate: "2021-02-02T00:00:00",
            OwnDamageCost: "0",
            ThirdPartyCost: "0",
            NcbPrejudiced: false,
            PersonalInjury: false,
            UnderPolicyholdersCover: false,
            ClaimantName: "Miss G Lee",
            Code: "M051",
            BrokerReference: "319291/001/C2",
            Status: "Closed",
          },
        ],
        MotoringOrg: "BLANK",
        RelationshipToProposer: "COMSPOUSE",
      },
      {
        Name: {
          Forenames: "Gurjitanna",
          Salutation: "Miss Lee-Lee",
          Surname: "Lee-Lee",
          Title: "Miss",
        },
        DateOfBirth: "1990-01-01T00:00:00",
        ResidentSince: "1990-01-01T00:00:00",
        IsResidentSinceBirth: true,
        FullTimeOccupation: {
          Type: "Employee",
          OccCode: "53D",
          EmpCode: "001",
        },
        PartTimeOccupation: {
          Type: "None",
          OccCode: "220",
          EmpCode: "186",
        },
        HasSecondOccupation: false,
        Licence: {
          Category: "CatB",
          Convictions: [
            {
              Code: "SP30",
              Date: "2020-03-03T00:00:00",
              Disqualification: "0",
              Fine: "0",
              OffenceDate: "2020-03-03T00:00:00",
              Points: "0",
            },
          ],
          Date: "2010-01-01T00:00:00",
          Type: "Full Licence",
        },
        Gender: "F",
        InsuranceRefused: false,
        MaritalStatus: "S",
        DvlaRestriction: "N",
        NonRTAConviction: false,
        Smoker: false,
        AccessToOtherVehicles: "BLANK",
        Tests: "0",
        VehicleUse: "Frequently",
        DriverType: "NmdDrvr",
        StarRider: "No",
        ConsentForMedicalConditions: "NotKnown",
        ConsentForConvictions: "NotKnown",
        Id: "319307",
        Claims: [
          {
            ClaimType: "M",
            ClaimDate: "2021-03-03T00:00:00",
            OwnDamageCost: "0",
            ThirdPartyCost: "0",
            NcbPrejudiced: false,
            PersonalInjury: false,
            UnderPolicyholdersCover: false,
            ClaimantName: "Miss G Lee-Lee",
            Code: "M051",
            BrokerReference: "319291/001/C3",
            Status: "Closed",
          },
        ],
        MotoringOrg: "BLANK",
        RelationshipToProposer: "OFFSPRNG",
      },
    ],
    Vehicle: {
      VehicleType: "Car",
      Abs: true,
      BodyStyle: "H",
      Lhd: false,
      Doors: "5",
      Seats: "5",
      Id: "179633687",
      AbiCode: "39199161",
      AbiProductionYears: "2013 - 2017",
      Alarm: "NONE",
      Purchased: "2016-09-09T00:00:00",
      Cc: "1598",
      Fuel: "D",
      Immob: "NONE",
      Manufacturer: "PEUGEOT",
      ModelDescription: "308 ACTIVE THP 125",
      Owner: "Proposer",
      Value: "5420",
      Paid: "5420",
      Keeper: true,
      RegNumber: "KN14UKA",
      Tracker: "NONE",
      Transmission: "M",
      WhereKept: "Driveway",
      KeptPostcode: "CF82 8AA",
      YearOfManufacture: "2014",
      Modifications: [],
      ModificationInd: false,
      PurchaseDate: "2020-01-01T00:00:00",
    },
  },
  RenewalResult: {
    QuoteType: "SUCCESS-RNL",
    RenewalType: "xxxx",
    RenewalDate: "2023-12-01T00:00:00",
    PolicyEndDate: "2023-11-30T00:00:00",
    IsPayInFull: true,
    AllowPayInFull: true,
    AllowPayMonthly: true,
    TotalRenewalAmount: 2222.22,
    RenewalAmount: 123.45,
    Ipt: 22.2,
    IncludedOpex: [{ testOPX: 1111 }],
    Documents: [{ test: 1111 }],
    Instalments: [{}],
  },
};

const session = {
  getRenewalData: async (args = {}) => {
    console.log("CALLING storeServices.renewal.getRenewalData", args);

    if (false) {
      const [response] = await Promise.all([
        Promise.resolve(dummyRenewalResponse),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]);

      const { Status, Message, ...otherData } = response;

      console.log("ddddd", { Status, Message, otherData });
      return {
        isSuccess: Status === "SUCCESS",
        message: Message,
        data: otherData,
      };
    }

    ["policyId", "eventId"].argChecker(args);

    // try {
    const response = await services.userDetails.getRenewalDetails(
      [args.policyId, "Events", args.eventId].join("/")
    );

    if (response === "ABORT") return "ABORT";

    const {
      Status,
      Message = "Sorry, there's been an error",
      ...otherData
    } = response;

    if (Status !== "SUCCESS") {
      return {
        isSuccess: false,
        message: Message,
      };
    }

    return {
      isSuccess: Status === "SUCCESS",
      data: otherData,
    };
    // } catch (e) {
    //   //TODO: Remove this. DUMMY FALL BACK if there's an error
    //   console.warn(
    //     "getRenewalData END POINT FAILED -- using fallback dummy data"
    //   );
    //   const { Status, Message, ...otherData } = dummyRenewalResponse;
    //   return {
    //     isSuccess: Status === "SUCCESS",
    //     message: Message,
    //     data: otherData,
    //   };
    // }
  },
  paymentLauncher: async (payload) => {
    console.log("paymentLauncher RENEWAL payload", { payload });

    // https://dev.azure.com/FreedomServicesGroup/Odyssey/_wiki/wikis/Odyssey.wiki/491/Renewal
    // https://dev.azure.com/FreedomServicesGroup/Odyssey/_wiki/wikis/Odyssey.wiki/463/RegisterPayment

    const retryResponse = await retryFunction(
      async () => await services.update.payment.register(payload),
      2
    );

    if (retryResponse === "ABORT") return "ABORT";

    if (!retryResponse.isSuccess) {
      // This "isSuccess" is from retryFunction(), not the actual endpoint
      throw retryResponse.err;
    }

    const response = retryResponse.response;

    console.log("paymentLauncher RENEWAL response", {
      retryResponse,
      response,
    });
    const { Status, Message, ...otherProps } = response || {};

    return {
      isSuccess: Status === "SUCCESS",
      message: Message,
      data: otherProps,
    };
  },
};

export default session;
