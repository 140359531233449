import { createSlice } from "@reduxjs/toolkit";
import { extraReducersSet } from "../common";
import _ from "lodash";

const generate = (args = {}) => {
  const {
    storeName,
    privateActions,
    abortChecker,
    errorMsgFallback = "Sorry, there's been an error",
  } = args;

  const initialState = {
    config: {},
    isInit: false,
    policyNumber: undefined,
    data: {},
    chosenOptions: {},
    statusInit: {},
    statusError: {},
    paymentLauncher: {},
  };

  const statusFunctions = {
    init: (state, key) => {
      state.statusInit = { ...state.statusInit, [key]: true };
    },
    complete: (state, key) => {
      state.statusInit = { ...state.statusInit, [key]: false };
    },
    error: {
      log: (state, key, value) => {
        state.statusError = { ...state.statusError, [key]: value };
      },
      clear: (state, key) => {
        state.statusError = { ...state.statusError, [key]: undefined };
      },
    },
  };

  const _slice = createSlice({
    name: storeName,
    initialState: initialState,
    reducers: {
      reset() {
        return initialState;
      },
      updatePaymentMethod(state, action) {
        state.chosenOptions["paymentMethod"] = action.payload;
      },
      updateAutoRenewal(state, action) {
        state.chosenOptions["autoRenewal"] = action.payload;
      },
      updateConfig(state, action) {
        state.config = action.payload;
      },
    },
    extraReducers: (builder) => {
      //**START
      extraReducersSet(
        builder,
        privateActions.start,
        "start",
        {
          pending: (state, action) => {
            statusFunctions.init(state, "start");
            statusFunctions.error.clear(state, "start");
            state.data = {};
            state.policyNumber = undefined;
            state.isInit = false;
          },
          fulfilled: (state, action) => {
            const { payload } = action;
            // const config = action.meta.arg;
            const { data } = payload;

            state.data = data;
            // state.config = config;
            state.isInit = true;

            statusFunctions.complete(state, "start");
            // statusFunctions.error.log(
            //   state,
            //   "start",
            //   payload.message || errorMsgFallback
            // );
          },
          rejected: (state, data) => {
            const { payload } = data;

            statusFunctions.complete(state, "start");
            statusFunctions.error.log(
              state,
              "start",
              payload?.message || errorMsgFallback
            );
          },
        },
        { storeName, abortChecker }
      );

      //**PAYMENTLAUNCHER*
      extraReducersSet(
        builder,
        privateActions.paymentLauncher,
        "paymentLauncher",
        {
          pending: (state, action) => {
            statusFunctions.init(state, "paymentLauncher");
            statusFunctions.error.clear(state, "paymentLauncher");
            state.paymentLauncher = {};
          },
          fulfilled: (state, action) => {
            const { payload } = action;

            statusFunctions.complete(state, "paymentLauncher");
            state.paymentLauncher = payload.data;
          },
          rejected: (state, data) => {
            const { payload } = data;
            statusFunctions.complete(state, "paymentLauncher");
            statusFunctions.error.log(
              state,
              "paymentLauncher",
              payload?.message || errorMsgFallback
            );
          },
        },
        { storeName, abortChecker }
      );
    },
  });

  return { reducer: _slice.reducer, actions: _slice.actions };
};

export default generate;
