import React, { useState } from "react";
import { generateCypressProps } from "siteFunctions/cypress";
// import ReactJson from "react-json-view";
import { JsonView, darkStyles, defaultStyles } from "react-json-view-lite";

const JsonViewer = (props) => {
  const { children, collapsedLevel = 1 } = props;

  if (!children) return null;

  // console.log("JsonViewer", children);

  // https://www.npmjs.com/package/react-json-view
  return (
    <span {...generateCypressProps("json-viewer", props)}>
      <JsonView
        data={children}
        shouldInitiallyExpand={(level) => collapsedLevel >= level}
        style={defaultStyles}
        // shouldInitiallyExpand={collapsedLevel}
      />
    </span>
  );

  // return (
  //   <span {...generateCypressProps("json-viewer", props)}>
  //     <pre>{JSON.stringify(children, null, 2)}</pre>
  //   </span>
  // );
};

export default JsonViewer;
