//QUOTEANDBUY TEST

import createQuoteAndBuy from "moveToLibrary/redux/quoteandbuy";
import rules from "./rules";
import {
  database as carRiskDatabase,
  rules as carRules,
} from "moveToLibrary/database/stella/carRisk";

import configData from "./configData";

export const store = createQuoteAndBuy({
  storeName: "quoteAndBuyCar",
  // rules: rules, // These will be the proper rules
  // template: require("./template").default,
  ...configData, //tempate and ruleList
  dev: {
    migrate: {
      database: carRiskDatabase,
      rules: carRules,

      rulesAdditional: {
        vehicleUse_CUSTOM_riskAdditionalInsuredSet: {
          // For ADDING and REMOVING additional drivers
          fn: (...args) => {
            const ruleFn = carRules.getRule({
              ruleLibrary: "vehicle",
              ruleType: "vehicleUseLogic",
            });

            // console.log("ddddsadsadsad", ...args);
            const retdata = ruleFn(...args);
            return retdata;
          },
          paths: ["Risk/AdditionalInsuredSet"],
          mappings: {
            AdditionalInsuredSet: "Risk/AdditionalInsuredSet",
            MainUserComponentId: "Risk/MainUserComponentId",
            ProposerVehicleUse: "Risk/Proposer/VehicleUse",
          },
          functionArgs: {
            ComponentTagVehicleUse: "VehicleUse",
          },
          isLegacy: true,
          errorKey: "vehicle/vehicleUseLogic", //Needs to be the same as the one generated in "migration.js"
        },
      },
    },
  },
  console: {
    ...console,
    // Nerf outs
    ...Object.fromEntries(
      [
        // Comment out as needed
        "time",
        "timeEnd",
        "log",
        "groupCollapsed",
        "groupEnd",
      ].map((k) => [k, () => null])
    ),
  },
});

export const riskConfig = require("./riskConfig").default({ store: store });
