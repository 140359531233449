import React, { useEffect } from "react";
import { useDispatch } from "siteFunctions/hooks";
import { useSelector } from "react-redux";

import useSalusCompare from "moveToLibrary/hooks/useSalusCompare";
import {
  useLocation,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

import {
  selectors as siteSelectors,
  actions,
  components,
  database,
  helpers,
  hooks,
  mtaCar,
} from "siteStore";
import Base from "../Base.jsx";
import {
  DevContainer,
  JsonViewer,
  ButtonStyled,
  ButtonLink,
  PanelMta,
  TelephoneService,
} from "siteComponents";

// import { getProps, mtaComponents } from "../../common";

const WraupFeedbackView = () => {
  const params = useParams();
  const {
    functions,
    selectors,
    feedbackComponents: { Wrapup: FeedbackWrapup },
  } = useOutletContext();

  const { type } = params;

  if (!type) throw `Error in WraupFeedbackView -- missing "type"`;

  // **********************************************
  // EFFECTS
  // **********************************************
  useEffect(() => {
    const myFn = async () => {
      switch (type) {
        case "refused":
          break;

        default: {
          await functions.process.reset();
          await functions.sessionSave();
        }
      }
    };

    myFn();
  }, [type]);

  // **********************************************
  // FINALISE
  // **********************************************
  // refused: fnGenerateUrl(args.routeFeedback, "refused"), //GOTO WRAPUP route -- BUTTON GOTO -- quote page (Need to NOT requote)
  // paymentError: fnGenerateUrl(
  //   args.routeFeedback,
  //   "paymenterror"
  // ), //GOTO WRAPUP route with an error
  // timeout: fnGenerateUrl(args.routeFeedback, "timeout"),
  // referred: fnGenerateUrl(args.routeFeedback, "referred"),
  // declined: fnGenerateUrl(args.routeFeedback, "declined"),
  // technicalError: fnGenerateUrl(
  //   args.routeFeedback,
  //   "error"
  // ),

  const [headerText, toolbarText, displayData] = (function () {
    const serviceTelephone =
      require("config").default.lookups.telephoneNumbers.service;

    // const Wrapper = ({ children, buttonData = [] }) => (
    //   <div className="row">
    //     <div className="col-12">{children}</div>
    //     {buttonData && <div className="col-12">{buttonData}</div>}
    //   </div>
    // );

    switch (type) {
      case "refused":
        return [
          "We're sorry",
          undefined,
          <PanelMta>
            <div>Your payment has not been authorised.</div>
            <div className="mt-3">
              You can
              <ButtonLink onClick={functions.navigation.quote} className="px-1">
                <strong>try another payment</strong>
              </ButtonLink>
              or, alternatively, call us on{" "}
              <strong>
                <TelephoneService />
              </strong>{" "}
              and one of our agents will be happy to help.
            </div>
            <div className="mt-3">
              <ButtonStyled onClick={functions.navigation.exit}>
                Return home
              </ButtonStyled>
            </div>
          </PanelMta>,
        ];

      case "paymenterror":
      case "timeout":
      case "referred":
      case "declined":
      case "error":
        return [
          "Sorry",
          "technical error",
          <PanelMta>
            <p>There's been a problem processing your payment.</p>
            <p>
              Please call us on{" "}
              <strong>
                <TelephoneService />
              </strong>{" "}
              and one of our agents will be happy to help.
            </p>

            {["paymenterror", "timeout", "error"].includes(type) && (
              <div className="alert alert-danger text-center mt-3">
                <strong>
                  Please do not attempt to make the payment again.
                </strong>
              </div>
            )}

            <div className="mt-5">
              <ButtonStyled onClick={functions.navigation.exit}>
                Return home
              </ButtonStyled>
            </div>
          </PanelMta>,
        ];
    }

    throw `Error in WraupFeedbackView -- unknown type "${type}"`;
  })();

  // Do we RESET the MTA?
  useEffect(() => {
    const myFn = async () => {
      switch (type) {
        case "paymenterror":
        case "timeout":
        case "referred":
        case "declined": {
          await functions.process.reset();
          await functions.sessionSave();
          break;
        }
      }
    };

    myFn();
  }, []);

  return (
    <Base
      className="mta-wrapup-feedback"
      headingText={headerText || "Oops!"}
      isFeedback={true}
      toolbarText={toolbarText}
      data-cy="layout-mta-feedback"
    >
      {displayData}
    </Base>
  );
};

export default WraupFeedbackView;
