// import axios from "axios";
// import { createServiceObj } from "@library/common/helpers/service";
import postProcessAxiosFunctions from "@library/configs/site/configItem/postProcessAxiosFunctions";

import {
  createService,
  createServiceSessionId,
  createServiceSecure,
  generateSet,
  EndPointSet as _EndPointSet,
  endPointItem as _endPointItem,
  generateLink,
} from "./resources";

import generateLookups from "./lookups";

// ******************************************
// Generate end points
// ******************************************
const lookupPostProcessAxiosStandard = [
  postProcessAxiosFunctions.JsonOptions,
  postProcessAxiosFunctions.JSONParse,
  postProcessAxiosFunctions.convertLabels,
];

const generateEndpoints = (args = {}) => {
  // requiredArgs.forEach((x) => {
  //   if (!x in args) {
  //     console.log("args:", args);
  //     throw `Error in base config endPoints -- missing ${x}`;
  //   }
  // });

  // console.log("ddd", args);
  const { endPointSets, isDev } = args;
  const requiredSets = [
    "default",
    "accounts",
    "test",
    "documents",
    "update",
    "userDetails",
  ];

  if (!endPointSets.isRegistered(requiredSets))
    throw `Error -- missing a required EndPointSet`;

  // ** HELPER FUNCTIONS **

  const generateLooksUps = (hitlist, options) => {
    const dataStatic = hitlist.filter((x) => x.staticData);
    const dataFn = hitlist.filter((x) => x.fn);
    const dataService = hitlist
      .filter((x) => x.listName)
      .map((x) => {
        return {
          ...x,
          data: {
            Operation: "single",
            ListName: x.listName,
          },
        };
      });

    return {
      ...Object.fromEntries(
        dataStatic.map((x) => [x.key, () => Promise.resolve(x.staticData)])
      ),
      ...Object.fromEntries(dataFn.map((x) => [x.key, x.fn()])),
      ...generateSet(dataService, {
        defaultFunction: createServiceSessionId,
        endPointSet: endPointSets.getItem("lookup"),
        ...options,
      }),
    };

    // if (!dataArray) throw `Error in generateLooksUps -- missing dataArray`;
    // if (!endPointSets)
    //   throw `Error in generateLooksUps -- missing endPointSets`;

    // return Object.fromEntries(
    //   dataArray.map((x) => {
    //     if (x.fn) return [x.key, x.fn];
    //     if (x.staticData) return [x.key, x.staticData];

    //     // console.log("x.postProcessAxios",x.postProcessAxios)
    //     const data = createServiceSessionId({
    //       postProcessAxios: (x.postProcessAxios || []).filter((x) => x),
    //       axiosOptions: {
    //         method: "POST",
    //         headers: {
    //           "x-api-key": endPointSets.getItem("default").apiKey,
    //         },
    //         url: `${endPointSets.getItem("default").url}/GetFrontEndLists`,
    //         data: {
    //           Operation: "single",
    //           ListName: x.listName,
    //         },
    //       },
    //     });

    //     return [x.key, data];
    //   })
    // );
  };

  // ** RETURN DATA **
  const returnData = {
    status: createService({
      axiosOptions: {
        method: "POST",
        url: `${endPointSets.getItem("default").url}/StatusCheck`,
        headers: {
          "x-api-key": endPointSets.getItem("default").apiKey,
        },
      },
    }),
    getSiteAlerts: createService({
      postProcessAxios: [
        (response) => {
          if (!response) return;
          if (!response.ShowAlert) return;
          if (!response.Alerts) return;
          if (response.Alerts.length === 0) return;

          const { Alerts } = response;
          // console.log("ddd",Alerts)

          return Alerts;
        },
      ],
      axiosOptions: {
        method: "POST",
        headers: {
          "x-api-key": endPointSets.getItem("default").apiKey,
        },
        url: `${endPointSets.getItem("default").url}/GetSiteAlerts`,
      },
    }),

    staticLinks: {
      //endPointSets.getItem("documents")
      getDocument: generateLink(
        `${endPointSets.getItem("documents").data.url}/pdf`
      ),
    },
    lookups: {
      ...generateLookups(endPointSets.getItem("lookup")),

      auth: {
        listSecurityQuestions: createServiceSessionId({
          postProcessAxios: [
            postProcessAxiosFunctions.JsonOptions,
            postProcessAxiosFunctions.JSONParse,
            postProcessAxiosFunctions.convertLabels,
          ],

          axiosOptions: {
            method: "POST",
            headers: {
              "x-api-key": endPointSets.getItem("default").apiKey,
            },
            url: `${endPointSets.getItem("default").url}/GetFrontEndLists`,
            data: {
              Operation: "single",
              ListName: "security-questions",
            },
          },
        }),
      },
    },
    search: {
      ...generateSet(
        [
          {
            key: "address",
            path: "/GetAddress",
          },
        ],
        {
          defaultFunction: createServiceSessionId,
          endPointSet: endPointSets.getItem("default"),
        }
      ),

      car: {
        ...generateSet(
          [
            {
              key: "dvlaSearch",
              path: "/DvlaSearch",
              postProcessAxios: [
                (response = {}) => {
                  const { Vehicles } = response;

                  if (_.isArray(Vehicles)) {
                    if (Vehicles.length >= 1) return Vehicles[0];
                    return undefined;
                  }

                  return Vehicles;
                },
              ],
              data: { LookupType: "PC" },
            },

            {
              key: "vehicleSearch",
              path: "/VehicleSearch",
              postProcessAxios: [
                (response) =>
                  postProcessAxiosFunctions.VehicleSearch(response, "PC"),
              ],
              data: {
                PolicyType: "PC",
                Operation: "get",
              },
            },
          ],

          {
            defaultFunction: createServiceSessionId,
            endPointSet: endPointSets.getItem("default"),
          }
        ),
      },
    },

    xxxlookupsOLD: {
      // all: generateLooksUps(
      //   [
      //     {
      //       key: "all",

      //       postProcessAxios: lookupPostProcessAxiosStandard,
      //       data: { Operation: "all" },
      //     },
      //   ],
      //   endPointSets
      // ),
      // Ocupations
      //ClassOfUse
      // WhereKept?
      auth: {
        listSecurityQuestions: createServiceSessionId({
          postProcessAxios: [
            postProcessAxiosFunctions.JsonOptions,
            postProcessAxiosFunctions.JSONParse,
            postProcessAxiosFunctions.convertLabels,
          ],

          axiosOptions: {
            method: "POST",
            headers: {
              "x-api-key": endPointSets.getItem("default").apiKey,
            },
            url: `${endPointSets.getItem("default").url}/GetFrontEndLists`,
            data: {
              Operation: "single",
              ListName: "security-questions",
            },
          },
        }),
      },
      ...generateLooksUps(
        [
          {
            key: "occupationCode",
            listName: "person-jobtitles",
            postProcessAxios: lookupPostProcessAxiosStandard,
          },
          {
            key: "carClassOfUse",
            listName: "vehicle-car-classofuse",
            postProcessAxios: lookupPostProcessAxiosStandard,
          },
          {
            key: "vehicleKeptOvernight",
            listName: "vehicle-keptovernight",
            postProcessAxios: lookupPostProcessAxiosStandard,
          },
          {
            key: "vehicleOwner",
            listName: "vehicle-owner",
            postProcessAxios: lookupPostProcessAxiosStandard,
          },
          {
            key: "relationshipToProposer",
            listName: "person-relationship",
            postProcessAxios: lookupPostProcessAxiosStandard,
          },
        ],
        endPointSets
      ),

      address: {
        ...generateSet(
          [
            {
              key: "search",
              path: "/GetAddress",
            },
          ],
          {
            defaultFunction: createServiceSessionId,
            endPointSet: endPointSets.getItem("default"),
          }
        ),
      },
      vehicle: {
        car: {
          search: {
            ...generateSet(
              [
                {
                  key: "lookup",
                  path: "/DvlaSearch",
                  postProcessAxios: [
                    (response = {}) => {
                      const { Vehicles } = response;

                      if (_.isArray(Vehicles)) {
                        if (Vehicles.length >= 1) return Vehicles[0];
                        return undefined;
                      }

                      return Vehicles;
                    },
                  ],
                  data: { LookupType: "PC" },
                },
              ],
              {
                defaultFunction: createServiceSessionId,
                endPointSet: endPointSets.getItem("default"),
              }
            ),
            manual: {
              ...generateLooksUps([
                {
                  key: "manufacturer",
                  listName: "vehicle-car-makes-models",
                  postProcessAxios: [
                    postProcessAxiosFunctions.JsonOptions,
                    postProcessAxiosFunctions.JSONParse,
                    postProcessAxiosFunctions.manualManufacturer,
                  ],
                },
              ]),
              ...generateSet(
                [
                  {
                    key: "results",
                    path: "/VehicleSearch",
                    postProcessAxios: [
                      (response) =>
                        postProcessAxiosFunctions.VehicleSearch(response, "PC"),
                    ],
                    data: {
                      PolicyType: "PC",
                      Operation: "get",
                    },
                  },
                ],
                {
                  defaultFunction: createServiceSessionId,
                  endPointSet: endPointSets.getItem("default"),
                }
              ),
            },
          },
          ...generateLooksUps([
            {
              key: "bodytype",
              listName: "vehicle-car-bodytype",
              postProcessAxios: lookupPostProcessAxiosStandard,
            },
            {
              key: "classofuse",
              listName: "vehicle-car-classofuse",
              postProcessAxios: [
                postProcessAxiosFunctions.JsonOptions,
                postProcessAxiosFunctions.JSONParse,
                postProcessAxiosFunctions.convertLabels,
              ],
            },
            // NOT USED ON STELLA
            // {
            //   key: "modlist",
            //   listName: "vehicle-car-modlist",
            //   postProcessAxios: [
            //     postProcessAxiosFunctions.JsonOptions,
            //     postProcessAxiosFunctions.JSONParse,
            //     postProcessAxiosFunctions.splitByCategory,
            //   ],
            // },
          ]),
        }, //END CAR

        ...generateLooksUps([
          {
            key: "alarm",
            listName: "vehicle-alarm",
            postProcessAxios: [
              postProcessAxiosFunctions.JsonOptions,
              postProcessAxiosFunctions.JSONParse,
              postProcessAxiosFunctions.convertLabels,
            ],
            cacheKey: "vehicle-alarm",
          },
          {
            key: "fueltype",
            cacheKey: "vehicle-fueltype",
            listName: "vehicle-fueltype",
            postProcessAxios: [
              ...lookupPostProcessAxiosStandard,
              (response) => {
                if (!response) return response;
                return response.map((x) => {
                  let newValue = x.value;
                  switch (x.value) {
                    case "FuelPetrol":
                      newValue = "P";
                      break;
                    case "FuelDiesel":
                      newValue = "D";
                      break;
                    case "FuelElec":
                      newValue = "E";
                      break;
                  }
                  return { ...x, value: newValue };
                });
              },
            ],
          },
          {
            key: "immobiliser",
            listName: "vehicle-immobiliser",
            postProcessAxios: [
              postProcessAxiosFunctions.JsonOptions,
              postProcessAxiosFunctions.JSONParse,
              postProcessAxiosFunctions.convertLabels,
            ],
            cacheKey: "vehicle-immobiliser",
          },
          {
            key: "keptovernight",
            listName: "vehicle-keptovernight",
            postProcessAxios: lookupPostProcessAxiosStandard,
          },
          {
            key: "owner",
            listName: "vehicle-owner",
            postProcessAxios: lookupPostProcessAxiosStandard,
          },
          {
            key: "tracker",
            listName: "vehicle-tracker",
            postProcessAxios: [
              postProcessAxiosFunctions.JsonOptions,
              postProcessAxiosFunctions.JSONParse,
              postProcessAxiosFunctions.convertLabels,
            ],
            cacheKey: "vehicle-tracker",
          },
          {
            cacheKey: "vehicle-transmission",

            key: "transmission",
            listName: "vehicle-transmission",
            postProcessAxios: lookupPostProcessAxiosStandard,
          },
          {
            key: "yearOfManufacture",
            staticData: (function () {
              let retData = [];
              const maxYear = new Date().getFullYear();
              for (let i = maxYear; i >= maxYear - 100; i--) {
                retData.push({ label: `${i}`, value: `${i}` });
              }
              return retData;
            })(),
          },
          {
            key: "annualMileage",
            staticData: (function () {
              let retData = [];
              for (let i = 1; i <= 25; i++) {
                let val = i * 1000;
                let display = val.toString();
                retData.push({ label: `Up to ${display}`, value: `${val}` });
              }

              retData.push({
                label: `Up to ${"30,000"}`,
                value: "30000",
              });
              retData.push({
                label: `Up to ${"50,000"}`,
                value: "50000",
              });
              retData.push({ label: `Over 50,000`, value: "60000" });
              return retData;
            })(),
          },
        ]),
      },

      // misc: generateSet([{ key: " " }], {
      //   defaultFunction: createServiceSessionId,
      //   endPointSet: endPointSets.getItem("default"),
      //   defaultPostProcessAxios: [
      //     postProcessAxiosFunctions.JsonOptions,
      //     postProcessAxiosFunctions.JSONParse,
      //     postProcessAxiosFunctions.convertLabels,
      //   ],
      // }),
    },

    error: generateSet([{ key: "log", path: "/ErrorNotification" }], {
      defaultFunction: createServiceSessionId,
      endPointSet: endPointSets.getItem("default"),
    }),

    auth: generateSet(
      [
        {
          key: "checkUsernameAvailable",
          path: "/CheckUsernameAvailable",
          requiredPayload: ["WebReference", "Username"],
        },
        {
          key: "checkPolicyDetails",
          path: "/CheckPolicyDetails",
          requiredPayload: ["WebReference", "DateOfBirth", "Postcode"],
        },
        {
          key: "create",
          path: "/Create",
          requiredPayload: [
            "WebReference",
            "DateOfBirth",
            "EmailAddress",
            "Postcode",
            "Username",
            "Password",
            "SecurityQuestions",
            "PolicyId",
            "ProposerId",
            "HasEmailChanged",
          ],
        },
        {
          key: "addPolicyToUser",
          path: "/AddPolicyToUser",
          requiredPayload: ["WebReference", "Username", "Password"],
        },
        {
          key: "activate",
          path: "/Activate",
          requiredPayload: ["WebReference", "Username", "Password", "Token"],
        },
        {
          key: "resendActivation",
          path: "/ResendActivation",
          requiredPayload: ["Username", "Password"],
        },
        {
          key: "authenticate",
          path: "/Authenticate",
          requiredPayload: ["Username", "Password"],
        },
        {
          key: "forgottenUsername",
          path: "/ForgottenUsername",
          requiredPayload: ["WebReference", "DateOfBirth", "Postcode"],
        },
        {
          key: "getSecurityQuestions",
          path: "/GetSecurityQuestions",
          requiredPayload: ["Username", "Postcode"],
        },
        {
          key: "forgottenPassword",
          path: "/ForgottenPassword",
          requiredPayload: ["Username", "SecurityQuestions"],
        },
        {
          key: "resetPassword",
          path: "/ResetPassword",
          requiredPayload: ["Username", "NewPassword", "Token"],
        },
        {
          key: "changePassword",
          path: "/ChangePassword",
          requiredPayload: ["Password", "NewPassword"],
          func: createServiceSecure,
        },
        {
          key: "getUserDetails",
          path: "/GetUserDetails",
          // requiredPayload: ["xxxx"],
          func: createServiceSecure,
        },
      ],
      {
        defaultFunction: createServiceSessionId,
        endPointSet: endPointSets.getItem("accounts"),
      }
    ),
    userDetails: generateSet(
      [
        {
          key: "notifications",
          path: "/Notifications",
          method: "get",
        },
        {
          key: "getPolicyDetails",
          path: "/Policy",
          method: "get",
        },
        {
          key: "getRenewalDetails",
          path: "/Renewal",
          method: "get",
        },
        { key: "getUserInfo", path: "", method: "get" },
      ],
      {
        defaultFunction: createServiceSecure,
        endPointSet: endPointSets.getItem("userDetails"),
      }
    ),
    documents: generateSet(
      [
        {
          key: "getPolicyDocuments",
          method: "get",
        },
        { key: "getDocument", method: "get" },
        {
          key: "upload",
          requiredPayload: ["WebReference", "UploadDetails"],
          fnModifyUrl: (url, payload) => {
            // /UserDetails/Policy/{policyId}/Documents/Upload
            const retUrl = [url, payload.PolicyId, "Documents", "Upload"].join(
              "/"
            );

            return retUrl;
          },
        },
        {
          key: "requestPrint",
          requiredPayload: ["WebReference", "DocumentIds", "PolicyId"],
          fnModifyUrl: (url, payload) => {
            // /UserDetails/Policy/{policyId}/Documents/Print
            const retUrl = [url, payload.PolicyId, "Documents", "Print"].join(
              "/"
            );

            return retUrl;
          },
        },
      ],
      {
        defaultFunction: createServiceSecure,
        endPointSet: endPointSets.getItem("documents"),
      }
    ),

    bank: {
      ...generateSet(
        [
          {
            key: "validateBankDetails",
            path: "/ValidateBankDetails",
          },
        ],
        {
          defaultFunction: createServiceSessionId,
          endPointSet: endPointSets.getItem("default"),
        }
      ),
    },
    update: {
      mta: generateSet(
        [
          {
            key: "quote",
            path: "/MtaQuote",
          },
          {
            key: "recall",
            path: "/MtaRecall",
          },
          {
            // https://dev.azure.com/FreedomServicesGroup/Odyssey/_wiki/wikis/Odyssey.wiki/461/AutoRenew
            key: "autorenew",
            path: "/AutoRenew",
          },
        ],
        {
          defaultFunction: createServiceSecure,
          endPointSet: endPointSets.getItem("update"),
        }
      ),
      payment: generateSet(
        [
          {
            key: "register",
            path: "/RegisterPayment",
          },
          {
            key: "purchaseResponse",
            path: "/GetPurchaseResponse",
          },
        ],
        {
          defaultFunction: createServiceSecure,
          endPointSet: endPointSets.getItem("payment"),
        }
      ),
    },

    // https://dev.azure.com/FreedomServicesGroup/Odyssey/_wiki/wikis/Odyssey.wiki/151/Persist
    persist: generateSet(
      [
        {
          key: "create",
          path: "/Persist",
          data: {
            Operation: "create",
          },
          requiredPayload: [
            // "Type",
            // , "Location"
          ],
        },
        {
          key: "delete",
          path: "/Persist",
          data: {
            Operation: "delete",
          },
          requiredPayload: ["Id"],
        },
        {
          key: "get",
          path: "/Persist",
          data: {
            Operation: "get",
          },
          requiredPayload: ["Id"],
        },
        {
          key: "save",
          path: "/Persist",
          data: {
            Operation: "save",
          },
          requiredPayload: [
            "Id",
            "DataString",
            // , "Location"
          ],
        },
      ],
      {
        defaultFunction: createService,
        endPointSet: endPointSets.getItem("default"),
      }
    ),

    test: {
      autoQuoteAndBuy: createServiceSessionId({
        axiosOptions: {
          method: "POST",
          headers: {
            "x-api-key": endPointSets.getItem("test").apiKey,
          },
          url: `${endPointSets.getItem("test").url}/AutoQuoteAndBuy`,
        },
      }),
    },
  };

  return returnData;
  // contactus: {
  // 	getLookupList: {
  // 		postProcessAxios: [
  // 			postProcessAxiosFunctions.JsonOptions,
  // 			postProcessAxiosFunctions.JSONParse,
  // 			postProcessAxiosFunctions.convertLabels,
  // 		],

  // 		axiosOptions: {
  // 			method: "POST",
  // 			headers: {
  // 				"x-api-key": contactusData.apiKey,
  // 			},
  // 			url: `${contactusData.endPoint}/GetFrontEndLists`,
  // 			data: {
  // 				Operation: "single",
  // 				ListName: "contact-us-types",
  // 			},
  // 		},
  // 	},
  // 	sendRequest: {
  // 		axiosOptions: {
  // 			method: "POST",
  // 			headers: {
  // 				"x-api-key": contactusData.apiKey,
  // 			},
  // 			url: `${contactusData.endPoint}/SendContactRequest`,
  // 			// validateStatus: (status) => {
  // 			// 	if (status === 400) return true; // Invalid request -- pass it to the front end
  // 			// 	return status >= 200 && status < 300;
  // 			// },
  // 		},
  // 	},
  // },

  // getSiteAlerts: {
  // 	axiosOptions: {
  // 		method: "POST",
  // 		headers: {
  // 			"x-api-key": AxiosData.apiKey,
  // 		},
  // 		url: `${AxiosData.endPoint}/GetSiteAlerts`,
  // 	},
  // },

  // payment: {
  // 	GetPolicyBalance: {
  // 		retryCount: 0,
  // 		axiosOptions: {
  // 			method: "POST",
  // 			headers: {
  // 				"x-api-key": AxiosData.apiKey,
  // 			},
  // 			url: `${AxiosData.endPoint}/GetPolicyBalance`,
  // 		},
  // 	},
  // 	RegisterAdhocPayment: {
  // 		retryCount: 0,
  // 		axiosOptions: {
  // 			method: "POST",
  // 			headers: {
  // 				"x-api-key": AxiosData.apiKey,
  // 			},
  // 			url: `${AxiosData.endPoint}/RegisterAdhocPayment`,
  // 		},
  // 	},
  // },
  // vehicle: {
  // 	transmission: {
  // 		// cache: true,

  // 		postProcessAxios: [
  // 			postProcessAxiosFunctions.JsonOptions,
  // 			postProcessAxiosFunctions.JSONParse,
  // 			postProcessAxiosFunctions.convertLabels,
  // 		],
  // 		retryCount: 0,
  // 		axiosOptions: {
  // 			method: "POST",
  // 			data: {
  // 				ListName: "vehicle-transmission",
  // 				Operation: "single",
  // 			},
  // 			headers: {
  // 				"x-api-key": AxiosData.apiKey,
  // 			},

  // 			url: `${AxiosData.endPoint}/GetFrontEndLists`,
  // 		},
  // 	},
  // 	fueltype: {
  // 		// cache: true,

  // 		postProcessAxios: [
  // 			postProcessAxiosFunctions.JsonOptions,
  // 			postProcessAxiosFunctions.JSONParse,
  // 			postProcessAxiosFunctions.convertLabels,
  // 			(response) => {
  // 				if (!response) return response;
  // 				return response.map((x) => {
  // 					let newValue = x.value;
  // 					switch (x.value) {
  // 						case "FuelPetrol":
  // 							newValue = "P";
  // 							break;
  // 						case "FuelDiesel":
  // 							newValue = "D";
  // 							break;
  // 						case "FuelElec":
  // 							newValue = "E";
  // 							break;
  // 					}
  // 					return { ...x, value: newValue };
  // 				});
  // 			},
  // 		],
  // 		retryCount: 0,
  // 		axiosOptions: {
  // 			method: "POST",
  // 			data: {
  // 				ListName: "vehicle-fueltype",
  // 				Operation: "single",
  // 			},
  // 			headers: {
  // 				"x-api-key": AxiosData.apiKey,
  // 			},

  // 			url: `${AxiosData.endPoint}/GetFrontEndLists`,
  // 		},
  // 	},
  // },
  // };
};

export const EndPointSet = _EndPointSet;
export const endPointItem = _endPointItem;
export default generateEndpoints;
