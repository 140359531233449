import services from "siteService";
import dateHelpers from "@library/common/helpers/misc/date";
import JSONhelper from "@library/common/helpers/misc/json";

import dayjs from "dayjs";

const sessionKey = "sessionId";
const session = {
  get: async (payload = {}) => {
    const { sessionId } = payload;
    const response = await services.persist.get({
      Id: sessionId,
    });
    if (response === "ABORT") return "ABORT";

    return {
      data: response.DataString,
      isSuccess: response.Response === "SUCCESS",
    };
  },
  save: async (payload = {}) => {
    const { data, sessionId } = payload;
    if (!data) return undefined;

    const response = await services.persist.save({
      Id: sessionId,
      DataString: data,
    });
    if (response === "ABORT") return "ABORT";

    return {
      isSuccess: response.Response === "SUCCESS",
    };
  },
  init: async (payload = {}) => {
    const { get = false } = payload;

    let sessionId = sessionStorage.getItem(sessionKey);
    let isSuccess = true;

    if (!sessionId) {
      const response = await services.persist.create();
      if (response === "ABORT") return "ABORT";

      const sessionIdNew = response.SessionId;
      const isSuccessNew = response.Response === "SUCCESS";

      sessionStorage.setItem(sessionKey, sessionIdNew);
      sessionId = sessionIdNew;
      isSuccess = isSuccessNew;
    }

    if (get) {
      const response = await services.persist.get({
        Id: sessionId,
      });
      if (response === "ABORT") return "ABORT";

      let isSuccess = response.isSuccess;

      if (!response.DataString) return { sessionId };

      return {
        sessionId,
        isSuccess,
        data: JSONhelper.parse(response.DataString),
      };
    }

    return { sessionId, isSuccess };
  },
  delete: async (payload = {}) => {
    const { sessionId } = payload;

    // #3235 Use try/catch as we need to contiue even if the backend fails
    try {
      const response = await services.persist.delete({
        Id: sessionId,
      });
      if (response === "ABORT") return "ABORT";
    } catch (e) {
      // Do nothing
    }

    sessionStorage.removeItem(sessionKey);
  },
};

export default session;
