import services from "siteService";
import dateHelpers from "@library/common/helpers/misc/date";
import JSONhelper from "@library/common/helpers/misc/json";

import dayjs from "dayjs";
import { generateKeys } from "siteHelpers/services";
import _ from "lodash";

const dashboard = {
  // getPolicyDetails: async (args = {}) => {
  //   ["webReference", "eventId"].argChecker(args);

  //   // throw new Error("Error in refreshUserInfo");
  //   if (false) {
  //     const { store, selectors } = require("siteStore");
  //     const state = store.getState();
  //     const defaultNotifications =
  //       selectors.auth.getDefaultNotifications(state);

  //     const [response] = await Promise.all([
  //       Promise.resolve({
  //         Status: "SUCCESS",
  //         notifications: defaultNotifications,
  //       }),
  //       new Promise((resolve) => setTimeout(resolve, 500)),
  //     ]);

  //     return response;
  //   }

  //   const response = await services.userDetails.getPolicyDetails(
  //     [args.webReference, "Events", args.eventId].join("/")
  //   );

  //   return {
  //     isSuccess: response.Status === "SUCCESS",
  //     message: response.Message,
  //     data: { Risk: response.Risk },
  //   };
  // },

  refreshUserInfo: async () => {
    // throw new Error("Error in refreshUserInfo");
    if (false) {
      const { store, selectors } = require("siteStore");
      const state = store.getState();
      const defaultNotifications =
        selectors.auth.getDefaultNotifications(state);

      const [response] = await Promise.all([
        Promise.resolve({
          Status: "SUCCESS",
          notifications: defaultNotifications,
        }),
        new Promise((resolve) => setTimeout(resolve, 500)),
      ]);

      return response;
    }

    const response = await services.userDetails.getUserInfo();
    if (response === "ABORT") return "ABORT";

    return {
      isSuccess: response.Status === "SUCCESS",
      message: response.Message,
      userInfo: response.UserInfo,
    };
  },
};

export default dashboard;
