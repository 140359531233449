import React, { useEffect, useState } from "react";
import services from "siteService";
import {
  Outlet,
  Link,
  NavLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { selectors, actions } from "siteStore";
import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";

import { DevContainer, JsonViewer, Button, ConfirmModal } from "siteComponents";

import Base from "./Base";
import _ from "lodash";

const PageSecureLayout = (props) => {
  // const isLoggedIn = useSelector(selectors.auth.isLoggedIn);
  const userInfo =
    useSelector(selectors.dashboard.getUserInfo, require("lodash").isEqual) ||
    {};
  const isLoggedIn = useSelector(
    selectors.auth.isLoggedIn,
    require("lodash").isEqual
  );
  // const currentPolicy = useSelector(selectors.dashboard.getCurrentPolicy);
  // const vehicleDetails = useSelector(selectors.dashboard.getVehicleDetails);

  // const dispatch = useDispatch();

  // const location = useLocation();

  // const currentPolicy = (function () {
  //   if (!location) return;
  //   if (!location.state) return;
  //   if (!location.state.policyNumber) return;

  //   return useSelector((state) =>
  //     selectors.dashboard.getPolicyList(state, location.state.policyNumber)
  //   );
  // })();

  if (!isLoggedIn) return null;

  const { children, ...otherProps } = props;

  return (
    <Base {...otherProps}>
      {/* <Menu /> */}

      {/* <div className="border border-success rounded-3 p-3 my-3 bg-light">
        <h5>(LAYOUT PAGE) Your chosen policy:</h5>
        <VehicleDetailsSwitcher
          PolicyType={currentPolicy.PolicyType}
          {...currentPolicy.VehicleData}
        />
      </div> */}

      {children}

      <DevContainer heading="userInfo" enabled={false}>
        {userInfo && <JsonViewer>{userInfo}</JsonViewer>}
      </DevContainer>

      {/* <DevContainer heading="Current Policy">
        {currentPolicy && (
          <Button
            onClick={() => {
              dispatch(actions.dashboard.currentPolicyClear());
            }}
          >
            Clear current policy
          </Button>
        )}
      </DevContainer> */}
    </Base>
  );
};

export default PageSecureLayout;
