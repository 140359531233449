import React, { useCallback } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import _store, {
  selectors as _selectors,
  actions as _actions,
  helpers as _helpers,
  components as _components,
  hooks as _hooks,
  // database as _database,
  classes as _classes,
  // riskStores as _riskStores,
  mtaCar as _mtaCar,
  stores as _stores,
  mtaRiskCar as _mtaRiskCar,
  snapshotMtaCar as _snapshotMtaCar,
  quoteAndBuyCar as _quoteAndBuyCar,
} from "./storeData";

// let persistor = persistStore(store);

const StoreProvider = (props) => {
  const { children } = props;

  return (
    <Provider store={_store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      {children}
      {/* </PersistGate> */}
    </Provider>
  );
};

export const quoteAndBuyCar = _quoteAndBuyCar;

export const stores = _stores;
export const mtaCar = _mtaCar;
export const mtaRiskCar = _mtaRiskCar;

export const snapshotMtaCar = _snapshotMtaCar;
export const store = _store;
export const selectors = _selectors;
export const actions = _actions;
export const helpers = _helpers;
export const components = _components;
export const hooks = _hooks;
// export const database = _database;
export const classes = _classes;
// export const riskStores = _riskStores;
// HOOKS

// export const useResetStore = (dispatch) => {
//   if (!dispatch) throw `Error -- missing "dispatch"`;
//   return useCallback(
//     () =>
//       dispatch(actions.auth.logout())
//         .then(() => dispatch(actions.documents.reset()))
//         .then(() => dispatch(actions.dashboard.reset())),
//     [dispatch]
//   );
// };

export default StoreProvider;
