import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";
import _ from "lodash";
import compare from "@library/common/helpers/misc/compareObjects";
import {
  DevContainer,
  JsonViewer,
  Button,
  TextArea,
  ButtonLink,
} from "siteComponents";

import { actions, selectors, helpers } from "siteStore";

const fnCsvToArray = (text) => {
  let p = "",
    row = [""],
    ret = [row],
    i = 0,
    r = 0,
    s = !0,
    l;
  for (l of text) {
    if ('"' === l) {
      if (s && l === p) row[i] += l;
      s = !s;
    } else if ("," === l && s) l = row[++i] = "";
    else if ("\n" === l && s) {
      if ("\r" === p) row[i] = row[i].slice(0, -1);
      row = ret[++r] = [(l = "")];
      i = 0;
    } else row[i] += l;
    p = l;
  }
  return ret;
};

const fnGenerateDifferences = async (
  salusData,
  store,
  riskStore,
  quoteAndBuyStore
) => {
  // const state = store.getState();
  // console.log("ddddd", mtaCar)
  // throw `hh`
  const differences = await helpers.quoteAndBuyCar.compareSalus({
    store: store,
    riskStore: riskStore,
    quoteAndBuyStore: quoteAndBuyStore,
    salusData: salusData,
    fnCompare: compare,
  });
  return differences;
};

const DisplayItem = ({ data, exclusionList }) => {
  const [displayData, setDisplayData] = useState(false);
  const {
    quoteAndBuyState,
    riskState,
    quoteAndBuyStateErrorList: _quoteAndBuyStateErrorList,
  } = data;

  const quoteAndBuyStateErrorList = _quoteAndBuyStateErrorList.filter(
    (x) => !exclusionList.includes(x.path)
  );

  const fnProcessItem = (
    v = [],
    { quoteAndBuyState = undefined, riskState = undefined }
  ) => {
    if (v.length === 0) return "None";

    return v.map((x, i) => {
      const fnGetValue = (stateData, path) => {
        const retData = _.get(stateData, path.replaceAll("/", "."));
        if (retData === undefined) return "undefined";
        return JSON.stringify(retData);
      };

      const oldValue = riskState && fnGetValue(riskState, x);
      const newValue = quoteAndBuyState && fnGetValue(quoteAndBuyState, x);

      return (
        <p key={i} className="m-0 ps-2">
          <small>
            {JSON.stringify(x)}: (
            {[oldValue, newValue].filter(Boolean).join(" to ")})
          </small>
        </p>
      );
    });
  };

  //exclusionList

  const hasDifferences =
    [
      data.differences.different,
      data.differences.missing_from_first,
      data.differences.missing_from_second,
    ].some((x) => x.length >= 1) || quoteAndBuyStateErrorList.length >= 1;

  //   console.log("ddddddd", data);

  if (!hasDifferences)
    return (
      <div
        className={[
          "border",
          "m-2",
          "p-2",
          "text-wrap",
          "bg-success",
        ].toClassName()}
      >
        <p>{data.Heading} - No Differences</p>
      </div>
    );

  return (
    <div
      className={[
        "border",
        "m-2",
        "p-2",
        "text-wrap",
        "bg-warning",
      ].toClassName()}
    >
      <h6>
        {data.Heading} ERRORS FOUND{" "}
        <ButtonLink onClick={() => setDisplayData((v) => !v)}>
          {displayData ? "Hide" : "Show"}
        </ButtonLink>
      </h6>

      {displayData && (
        <>
          <p>
            <ButtonLink onClick={() => console.log(data.Heading, data)}>
              Console.log()
            </ButtonLink>
          </p>
          {quoteAndBuyStateErrorList.length >= 1 &&
            quoteAndBuyStateErrorList.map((x, i) => (
              <p key={i} className="text-danger">
                ERROR: {JSON.stringify(x)}
              </p>
            ))}
          <dl>
            <dt>Different</dt>{" "}
            <dd>
              {fnProcessItem(data.differences.different, {
                quoteAndBuyState,
                riskState,
              })}
            </dd>
            <dt>Missing in OLD REDUX STORE</dt>
            <dd>
              {fnProcessItem(data.differences.missing_from_first, {
                riskState,
              })}
            </dd>
            <dt>Missing in NEW REDUX STORE</dt>
            <dd>
              {fnProcessItem(data.differences.missing_from_second, {
                quoteAndBuyState,
              })}
            </dd>
          </dl>
        </>
      )}
    </div>
  );
};

const BaseComponent = ({ policyList = [], exclusionList = [] }) => {
  const { store, mtaRiskCar, quoteAndBuyCar } = require("siteStore");

  const [displayData, setDisplayData] = useState(undefined);
  const [loadingMessage, setloadingMessage] = useState(undefined);

  const fnRefreshData = async () => {
    setloadingMessage(<p>Please wait...</p>);
    const newData = [];

    // NOTE: Needs to be a "FOR LOOP" as we're calling ASYNC functions
    let i = 0;
    const itemCount = policyList.length;

    for (const policy of policyList) {
      i = i + 1;
      setloadingMessage(
        <p>
          ({i}/{itemCount})
        </p>
      );
      const { Risk, Heading } = policy;

      console.log("SALUS CHECKING:", Heading);

      const differenceData = await fnGenerateDifferences(
        {
          Risk: Risk,
        },
        store,
        mtaRiskCar,
        quoteAndBuyCar
      );

      //   const retData = {
      //     ...differenceData,
      //     differences: differenceData.differences,
      //     Heading,
      //   };

      const retData = {
        ...differenceData,
        differences: Object.fromEntries(
          Object.entries(differenceData.differences).map(([k, d]) => {
            return [k, d.filter((x) => !exclusionList.includes(x))];
          })
        ),
        Heading,
      };

      newData.push(retData);
    }

    // console.log("newData", newData);
    setloadingMessage(undefined);
    setDisplayData(newData);
  };

  useEffect(() => {
    const myFn = async () => {
      // setloadingMessage(<p>Please wait...</p>);

      await fnRefreshData();
      //   setloadingMessage(undefined);
    };

    myFn();
  }, []);

  if (policyList.length === 0) return <div>No policies imported</div>;

  const hasData = displayData && displayData.length >= 1;

  if (loadingMessage) return loadingMessage;

  console.log("SALUS COMPARE", { displayData });

  return (
    <div>
      <Button
        onClick={async () => {
          setloadingMessage(<p>Please wait...</p>);
          await fnRefreshData();
          setloadingMessage(undefined);
        }}
      >
        Refresh
      </Button>
      {!hasData && <p>Missing data</p>}
      {hasData &&
        displayData.map((x, i) => {
          return <DisplayItem key={i} data={x} exclusionList={exclusionList} />;
        })}
    </div>
  );
};

const QuoteAndBuyStoreSalusChecker = () => {
  const [isEnabled, setIsEnabled] = useState(false);

  // const riskStore = mtaCar.riskStoreConfig.riskStore;
  const policyList = useSelector(
    (state) =>
      isEnabled
        ? (selectors.dashboard.getPolicyList(state) || [])
            .filter((x) => x.MtaInfo)
            .map((x) => {
              return {
                Risk: x.MtaInfo.Risk,
                Heading: ["PolicyId:", x.PolicyId].join(" "),
              };
            })
        : [],
    _.isEqual
  );

  if (!isEnabled)
    return (
      <div>
        <p>
          Compares all the policies by "loading and retrieving" data into both
          OLD and NEW REDUX stores, and comparing the output.
        </p>
        <p>
          NOTE: only run on the MAIN dashboard page as it clears down an in
          progress MTA
        </p>
        <Button onClick={() => setIsEnabled(true)}>Show</Button>
      </div>
    );

  return (
    <div>
      <Button onClick={() => setIsEnabled(false)}>CLOSE</Button>
      <BaseComponent policyList={policyList} />
    </div>
  );
};

export const QuoteAndBuyStoreSalusHitlist = () => {
  // https://eu-west-1.console.aws.amazon.com/dynamodbv2/home?region=eu-west-1#item-explorer?operation=QUERY&table=salus-test-FinalRequestResponseLogs-1S6U4P99CQDQH
  // https://eu-west-1.console.aws.amazon.com/dynamodbv2/home?region=eu-west-1#table?name=salus-live-FinalRequestResponseLogs-D8CF4LCFGUOV
  // Goto EXPLORE ITEMS
  // Index to requestTypeRequestDate, RequestType=MtaQuote MultiQuote
  // FILTER Origin begins with "main" or "myaccount.withstella"

  const [isEnabled, setIsEnabled] = useState(false);
  const rawData = require("./quoteAndBuyAWSData.csv");
  const [policyListString, setPolicyListString] = useState(rawData);
  // const riskStore = mtaCar.riskStoreConfig.riskStore;

  const policyList = (function () {
    if (!isEnabled) return [];
    if (!policyListString) return [];

    const retData = fnCsvToArray(policyListString)
      .filter((x) => x[0] !== "RequestId")
      //DEDUPE
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t[0] === value[0])
      )
      .map((x, i, arr) => {
        const requestId = x[0];
        const riskData = JSON.parse(
          x[2].replaceAll(`""`, `"`).slice(1).slice(0, -1)
        ).Risk;
        return {
          Heading: ["(", i + 1, "/", arr.length, ") ", requestId].join(""),
          Risk: riskData,
          requestId,
        };
      })
      .filter((x) => x.Risk.PolicyTypeCode === "PC");

    const filterList = [
      // "dtspvk_2ed278c6-3456-4b3b-bebb-81e9aba2d9a4_RBdqvFTQjoEEiaA=", // Proposer from "M" to "S"  -- due to additionalDriverLogic running on LOAD
      //   "dtspvk_9f416ff3-fb27-44ce-8e67-32c21c0ebcb7_RBd9AFcejoEEeiw=", //"Risk/BusinessMileage": ("10000" to 1000)  -- due to vehicleBusinessMileageLogic running on LOAD
      // "dtspvk_64ca3778-90a2-437d-9912-a4c6d150b67a_RAmb6E1djoEErvA="//"Risk/BusinessMileage": ("10000" to undefined), ProtectedBonus "false" to undefined -- due to ncbLogic running on LOAD
      //   "dtspvk_74e1d13d-fab3-4ce0-abd9-3862d6394448_RR5ISHHmDoEEjUA=", // Residentsince?
      //   "dtspvk_acfdb1d0-b1a1-4d66-837a-7d288f3ae902_RCpy4EX3joEEWwg=",
      //   "dtspvk_52062e78-1963-4468-9dbb-989718be85cc_RMBe-FlPDoEEUHA=", // Proposer from "M" to "S
      // "dtspvk_64ca3778-90a2-437d-9912-a4c6d150b67a_RAmb6E1djoEErvA=" // protected NCD
      //   "zvdufk_d5596932-0094-41a2-8eaf-f6015e9e30da_RPQMLG1XjoEEJfg=",
      // "zvdufk_bd538a31-7ecc-458d-af83-2cf46f78f267_RWNf6F63joEEZtg=",
      // "zvdufk_f29b6a6a-9205-40a9-b737-4bc42a17cd78_RWOLeF9ijoEEvzA="
    ];
    // isssues:
    //  Proposer from "M" to "S" -- additional driver is spouse
    // "Risk/BusinessMileage": default into to 1000 or undefined
    // Residentsince -- sorted?
    // Risk/ExtraDriverDetails/ProtectedNcd goes from FALSE to UDNEFINED in NCBLOGIC if some otehr values are SET
    // Name formatting
    //

    if (filterList.length >= 1)
      return retData.filter((x) => filterList.includes(x.requestId));
    // console.log("dddd", retData)
    // return retData.slice(0, 30);
    return retData;
  })();

  //   console.log("ddddd policyList", policyList);

  if (!isEnabled)
    return (
      <div>
        Reads the hitlist file:
        <Button onClick={() => setIsEnabled(true)}>Show</Button>
      </div>
    );

  //   return <div>xxxxxx</div>;
  return (
    <div>
      <Button onClick={() => setIsEnabled(false)}>CLOSE</Button>
      {/* <TextArea
        value={policyListString}
        onChange={(v) => setPolicyListString(v)}
        style={{ height: "100px" }}
      /> */}
      <BaseComponent
        policyList={policyList}
        exclusionList={[
          //   "Risk/RiskData/Confirmation",
          //   "Risk/RiskData/VehicleExtraDetailsConfirmation",
          //   "Risk/MainUserComponentId",
          //   "Risk/BusinessMileage",
          //   "Risk/Proposer/ResidentSince",
          //   "Risk/Proposer/MaritalStatus",
          //   "Risk/ExtraDriverDetails/ProtectedNcd",
          "Risk/StartDate",
        ]}
      />
    </div>
  );
};

export default QuoteAndBuyStoreSalusChecker;
