const globalDebug = false;

const requiredArgs = ["google", "awsConfig", "endPointSets"];

export default (args) => {
  requiredArgs.forEach((x) => {
    if (!(x in args)) {
      console.log("args:", args);
      throw `Error in base config -- missing ${x}`;
    }
  });

  console.log("config args:", args);

  const isDev = args.isDev || false;
  const useStrictMode = args.useStrictMode || false;
  const isTest = args.isTest || false;
  const isStage = args.isStage || false;
  const isProd = args.isProd || false;
  const liveDisable = (preferedValue) => (!isDev ? false : preferedValue);

  return {
    endPoints: require("./endpoints").default({
      endPointSets: args.endPointSets,
      isDev,
      isTest,
      isStage,
      isProd,
    }),
    // awsConfig: args.awsConfig,
    isDev,
    isTest,
    isStage,
    isProd,
    useStrictMode,
    google: args.google,
    debug: {
      clearConsoleOnRefresh: false,
    },
    liveChat: args.liveChat,
    lookups: {
      telephoneNumbers: {
        sales: "0333 996 9718",
        service: "0333 996 9718",
        claims: "0345 528 0076",
      },
      email: {
        service: "policy@withstella.co.uk",
      },
      documents: {
        toba: "https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/TOBA-Car-Stella.pdf",
        privacyPolicy: "https://withstella.co.uk/privacy/",
      },
      errors: {
        boundary: {
          whitelist: ["Script error."],
        },
      },
    },
  };
};
