import React from "react";
import App from "./App";
import { createRoot } from "react-dom/client";

// __webpack_nonce__ = 'c29tZSBjb29sIHN0cmluZyB3aWxsIHBvcCB1cCAxMjM=';

if (module.hot) {
  console.clear();
  module.hot.accept();

  console.log("****************************************************");
  console.log("HOT RELOAD");
  console.log("process.env.NODE_ENV=", process.env.NODE_ENV);
  console.log("****************************************************");
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
