import React from "react";

import { OpexTitle } from "./index";
import { ExternalLink } from "siteComponents";

const OpexIncluded = (props) => {	

	const { opex } = props;

	if (!opex.CoverLevels)
		return null;

	const isTiered = opex.CoverLevels.length > 1;
	const selectedLevel = opex.CoverLevels.length == 1 ? opex.CoverLevels[0] : opex.CoverLevels.find(x => x.Code == opex.SelectedLevel);

	return (
		<div
				className={["opex", "opex-inc", opex.GroupCode].toClassName()}
				data-cy={`inc-opex-${opex.GroupCode}`}
			  >
				<OpexTitle GroupCode={opex.GroupCode} Title={isTiered ? `${opex.Title} (${selectedLevel.Name})` : selectedLevel.Name} />
				
				<div className="opex-read-more">
				  For more details please read the{" "}
				  <ExternalLink href={selectedLevel.WordingUrl} label="Policy Wording" dataCy="opex-wording" />{" "}
				  and{" "}
				  <ExternalLink href={selectedLevel.IpidUrl} label="Product Information" dataCy="opex-ipid" />.
				</div>

			  </div>
	);
};

export default OpexIncluded;
