import React from "react";

import { PanelRenewal } from "siteComponents";

const PleaseWait = ({ children }) => {
  return (
    <PanelRenewal className="renewal-session-refreshing">
      <div className="row">
        <div className="col-12 d-flex justify-content-center mb-4">
          <span className="icon icon-clock" />
        </div>
        <div className="col-12 d-flex justify-content-center">{children}</div>
      </div>
    </PanelRenewal>
  );
};

export default PleaseWait;
