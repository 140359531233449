import dayjs from "dayjs";

import dataHelper from "@library/common/database/rules/helpers/dataHelper";
// ConvictionLogic.js
export default (args) => {
  const {
    dataSet,
    functionArgs,
    dataHelper = require("@library/common/database/rules/helpers/dataHelper")
      .default,
  } = args;

  const dh = new dataHelper({
    mappings: ["OffenceCode", "AlcoholReadingType", "AlcoholReadingAmount"],
    dataSet,
    functionArgs,
  });

  const existingOffenceCode = dh.getValue("OffenceCode") || "";
  const isVisible = ["DR10", "DR20", "DR40", "DR50"].includes(
    existingOffenceCode.toUpperCase()
  );
  // existingOffenceCode.toUpperCase().substring(0, 2) === "DR" &&
  // !["DR10", "DR20", "DR40", "DR50", "DR70", "DR80", "DR90"].includes(
  // 	existingOffenceCode.toUpperCase()
  // );
  //	existingOffenceCode.toUpperCase().substring(0, 2) === "DR" &&
  //// ignore these drug related offences (TICKET 434)
  //	!["DR70", "DR80", "DR90"].includes(existingOffenceCode.toUpperCase());

  dh.setVisible("AlcoholReadingType", isVisible);
  dh.setVisible("AlcoholReadingAmount", isVisible);

  if (!isVisible) {
    dh.setData("value", "AlcoholReadingType", undefined);
    dh.setData("value", "AlcoholReadingAmount", undefined);
    dh.addError("AlcoholReadingType", undefined);
    dh.addError("AlcoholReadingAmount", undefined);
  } else {
    dh.addError(
      "AlcoholReadingType",
      !dh.getValue("AlcoholReadingType")
        ? "" //"Please enter the alcohol reading type"
        : undefined
    );

    dh.addError(
      "AlcoholReadingAmount",
      !dh.getValue("AlcoholReadingAmount")
        ? "" // "Please enter the alcohol reading amount"
        : undefined
    );
  }
  dh.commit();
};
