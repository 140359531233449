import riskUtils from "./risk";
import templateUtils from "./template";
import _ from "lodash";
const fnOutputErrorInfo = (...args) => {
  console.log("********************************************");
  console.log("ERRORINFO:", ...args);
  console.log("********************************************");
};
// template
const salusUtils = {
  import: (salusData, template, fnLogCreatedItem = () => {}) => {
    // Uses the template to import salusData

    const _fnRecursive = (salusNode, path = [], level = 1) => {
      const templateData = templateUtils.getData(template, path);

      const fnDebug = (...args) => {
        return;
        console.log(" ".repeat(level * 2), "ddd", path.join("/"), ...args);
      };

      fnDebug(templateData.found, { template, templateData });

      // ------------------------
      // If we've found a templateData node, then output it
      // ------------------------
      if (templateData?.found) {
        if (!templateData?.data?.salus?.fnFrom) {
          fnOutputErrorInfo(path.join("/"), {
            template,
            templateData,
            salusData,
            salusNode,
            path,
            level,
          });
          throw `Error in risSalusUtils.import -- missing templateData.data.salus.fnFrom`;
        }
        fnLogCreatedItem(path); // This is used by the slice to create a list of rules to run

        // if (path.join("/") === "Risk/BusinessMileage") {
        //   console.log(path.join("/"), {
        //     salusNode,
        //     converted: templateData.data.salus.fnFrom(salusNode),
        //   });

        //   throw `hhh`;
        // }

        return riskUtils.create.dataNode({
          value: templateData.data.salus.fnFrom(salusNode),
        });
      }

      // ------------------------
      // ELSE is OBJECT ARRAY
      // ------------------------
      if (_.isArray(salusNode)) {
        // fnDebug("ARRAY");

        const fnGenerateId = (id) => `Item_${id}`;

        const _arrayData = salusNode
          .map((curArrItem, i) => {
            const id = fnGenerateId(i);
            const newPath = path.map((curPath, pathIdx, arr) => {
              if (pathIdx === arr.length - 1) return `${curPath}[${id}]`;
              return curPath;
            });

            const _data = _fnRecursive(curArrItem, newPath, level + 1);

            if (_data === undefined) return undefined;
            return {
              data: _data,
              id: id,
            };
          })
          .filter(Boolean);

        if (_arrayData.length === 0) return undefined;

        return riskUtils.create.dataNode({
          arrayData: _arrayData.map(({ data, id }) => {
            const newArrItem = riskUtils.create.arrayItem(id);
            newArrItem.data = data;
            return newArrItem;
          }),
        });
      }

      // ------------------------
      // ELSE is OBJECT SET
      // ------------------------
      if (_.isObject(salusNode)) {
        // fnDebug("OBJECT");

        const _returnObj = Object.fromEntries(
          Object.entries(salusNode)
            .map(([l, x]) => [l, _fnRecursive(x, [...path, l], level + 1)])
            .filter(([l, d]) => d !== undefined)
        );

        if (_.isEmpty(_returnObj)) return undefined;

        return _returnObj;
      }

      // console.log("ddddd", path.join("/"), { salusNode, templateData });
      fnDebug("UNKNOWN");
      return undefined;
    };

    const retData = _fnRecursive(salusData, []);
    // console.log("ddddd retData", retData);
    if (!retData) {
      console.log("ERROR INFO:", { salusData, template, fnLogCreatedItem });

      throw `Error in riskSalus.import -- no data returned`;
    }
    return retData;
  },
};

export default salusUtils;
