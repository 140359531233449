import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";

import regExConstants from "@library/common/constants/regEx";
import generateBaseRuleData from "../../../generateBaseRuleData";

export default {
	generateRules: () => {
		return [
			new ruleItem(
				{
					...generateBaseRuleData("general", "trim"),
				},
				{}
			),

			new ruleItem(
				{
					...generateBaseRuleData("general", "upperCase"),
				},
				{}
			),
			// new ruleItem(
			// 	{
			// 		...generateBaseRuleData("general", "regEx"),
			// 	},
			// 	{
			// 		regEx: regExConstants.postcode.ukPostcode,
			// 		errorMessage: "",
			// 		// errorMessage: "Please enter a valid UK Postcode",
			// 	}
			// ),
			// new ruleItem({
			// 	...generateBaseRuleData("general", "regEx"),},{

			// 	regEx: regExConstants.postcode.channelIslandsPostcode,
			// 	invert: true,
			// 	errorMessage: "Sorry, we don't insure the channel islands",
			// }),
		];
	},
};
