import { isEmpty } from "@library/common/database/rules/helpers";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";
// isRequired.js
export default (args) => {
  const { dataSet, functionArgs } = args;

  if (!dataSet) throw `Error -- missing dataSet`;
  if (!functionArgs) throw `Error -- missing functionArgs`;

  const testValue = dataSet.getValue(
    new dataSetArgItem({
      useDefaultComponentSet: true,
      useDefaultComponentTag: true,
    }),
    "value"
  );

  // console.log("isRequired testValue = ", testValue);
  const isValid = !isEmpty(testValue);

  //   console.log(
  //     "...isRequired value:",
  //     dataSet.current.componentTag,
  //     testValue,
  //     "=",
  //     isValid
  //   );

  // console.warn(
  // 	"... (2) isRequired",
  // 	dataSet.current,
  // 	// dataSet,
  // 	testValue,
  // 	isEmpty(testValue)
  // );

  dataSet.setValue(
    new dataSetArgItem({
      key: "errors",
      value: {
        errorMessage: isValid ? undefined : functionArgs.errorMessage,
        errorKey: functionArgs.errorKey,
      },

      useDefaultComponentSet: true,
      useDefaultComponentTag: true,
    })
  );

  return null;
};
