import React from "react";
import PageLayout from "siteLayouts/Page";
import config from "config";
import { Loading } from "siteComponents";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
} from "react-router-dom";

import TestView from "siteViews/Test/SubViews/Test";
import Error404View from "siteViews/Test/SubViews/Error404";

const Wrapper = (props) => {
  return (
    // <React.Suspense fallback={<Loading />}>
    <Outlet />
    // </React.Suspense>
  );
};

const routes = [
  {
    path: "test",
    element: <Wrapper />,
    children: [
      {
        path: "",
        element: <TestView />,
      },
      {
        path: "error/404",
        element: <Error404View />,
      },
    ],
  },
];

export default routes;
