import { createAsyncThunk } from "@reduxjs/toolkit";
import logSymbols from "log-symbols";
import chalk from "chalk";

export const consoleLog = (args = {}, ...otherData) => {
  const { type, storeName, subActionName } = args;

  if (!storeName) throw `Error in consoleLog -- missing storeName`;

  const symbol = (function () {
    switch (type) {
      case "warning":
        return logSymbols.warning;
      case "error":
        return logSymbols.error;
      case "success":
        return logSymbols.success;
      case "info":
        return logSymbols.info;
    }
    return undefined;
  })();

  const storeNameFormatted = (function () {
    switch (type) {
      case "warning":
        return chalk.yellow.bold(storeName.toUpperCase());
      case "error":
        return chalk.red.bold(storeName.toUpperCase());
      case "success":
        return chalk.green.bold(storeName.toUpperCase());
      case "info":
        return chalk.green.dim(storeName.toUpperCase());
    }
    return chalk.bold(storeName.toUpperCase());
  })();

  console.groupCollapsed(
    ...[chalk(storeNameFormatted, symbol, chalk.black(subActionName))].filter(
      (x) => x
    )
  );
  console.log(...otherData);

  console.groupEnd();
};

export const createFnGenerateAction = (storeName) => (name, fn) =>
  createAsyncThunk(`${storeName}/${name}`, async (payload, thunkAPI) => {
    try {
      return fn(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        isTechnicalError: true,
        error,
      });
    }
  });

export const createFnGenerateActionWithThunkAPI = (storeName) => (name, fn) =>
  createAsyncThunk(`${storeName}/${name}`, async (payload, thunkAPI) => {
    try {
      return fn(payload, thunkAPI);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        isTechnicalError: true,
        error,
      });
    }
  });

export const createExtraReducersSet = (
  action,
  actionName,
  config = {},
  options = {}
) => {
  throw `No longer in use`;
  const { storeName } = options;

  const retData = {};

  if (config.pending) {
    retData[action.pending] = (state, action) => {
      consoleLog({
        storeName,
        type: "info",
        subActionName: `${actionName}.pending`,
      });

      config.pending(state, action);
    };
  }

  if (config.fulfilled) {
    retData[action.fulfilled] = (state, action) => {
      consoleLog(
        {
          storeName,
          type: "success",
          subActionName: `${actionName}.fulfilled`,
        },
        action
      );

      config.fulfilled(state, action);
    };
  }

  if (config.rejected) {
    retData[action.rejected] = (state, data) => {
      consoleLog(
        {
          storeName,
          type: "error",
          subActionName: `${actionName}.rejected`,
        },
        data
      );

      // throw data.error;
      if (data && data.error && data.error.message) {
        console.error(data.error.message);
      }
      config.rejected(state, data);
    };
  }

  if (config.throwError) {
    retData[action.rejected] = (state, data) => {
      if (data.error) throw data.error;
      throw new Error(`Error in "${actionName}"`);
    };
  }

  return retData;
};

export const extraReducersSet = (
  builder,
  action,
  actionName,
  config = {},
  options = {}
) => {
  const { storeName, abortChecker } = options;

  const consoleLogData = { action, options };

  if (config.pending) {
    builder.addCase(action.pending, (state, action) => {
      consoleLog(
        {
          storeName,
          type: "info",
          subActionName: `${actionName}.pending`,
        },
        consoleLogData
      );

      config.pending(state, action);
    });
  }

  if (config.fulfilled) {
    builder.addCase(action.fulfilled, (state, action) => {
      if (abortChecker && abortChecker(action.payload)) {
        consoleLog(
          {
            storeName,
            type: "error",
            subActionName: `${actionName}.aborted`,
          },
          { ...consoleLogData }
        );
        return;
      }

      consoleLog(
        {
          storeName,
          type: "success",
          subActionName: `${actionName}.fulfilled`,
        },
        consoleLogData
      );
      config.fulfilled(state, action);
    });
  }

  if (config.rejected) {
    builder.addCase(action.rejected, (state, data) => {
      consoleLog(
        {
          storeName,
          type: "error",
          subActionName: `${actionName}.rejected`,
        },
        { ...consoleLogData, data }
      );
      // throw data.error;
      if (data && data.error && data.error.message)
        console.error(data.error.message);
      config.rejected(state, data);
    });
  }

  if (config.throwError) {
    builder.addCase(action.rejected, (state, data) => {
      if (data.error) throw data.error;
      throw new Error(`Error in "${actionName}"`);
    });
  }
};
