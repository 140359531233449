import React from "react";
import { Loading } from "siteComponents";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
} from "react-router-dom";

// import ManageView from "./index";
const ManageView = React.lazy(() => import("siteViews/ManageAccount"));

const routes = [
  {
    path: "manage-account",
    element: (
      // <React.Suspense fallback={<Loading />}>
      <Outlet />
      // </React.Suspense>
    ),
    children: [
      {
        path: "",
        element: <ManageView routeExit="../" />,
      },
    ],
  },
];

export default routes;
