import React from "react";

import {
  ButtonStyled,
  ButtonOutline,
  ButtonStyledMta,
  ButtonOutlineMta,
  LoadingRow,
  Panel,
  Error,
  ConfirmModalButton,
  DevContainer,
} from "siteComponents";

const ButtonPanel = (props) => {
  const {
    onPrev,
    prevLabel,
    onNext,
    nextLabel,
    errorMsg,
    disabledPrev = false,
    disabledNext = false,
    className,

    dataCyPrev = "mta-button-prev",
    dataCyNext = "mta-button-next",
  } = props;

  return (
    <div
      className={["mta-button-panel", className].toClassName()}
      data-cy="mta-confirm"
    >
      <div className="row">
        {onPrev && (
          <div className="col-12 col-md-3 order-2 order-md-1">
            <ButtonOutlineMta
              onClick={onPrev}
              className="px-5"
              disabled={disabledPrev}
              data-cy={dataCyPrev}
            >
              <span className="fw-bolder">{prevLabel}</span>
            </ButtonOutlineMta>
          </div>
        )}

        {onNext && (
          <div className="col-12 col-md-9 order-1 order-md-2 pb-3 pb-md-0 text-end">
            <ButtonStyledMta
              onClick={onNext}
              className="w-100"
              disabled={disabledNext}
              data-cy={dataCyNext}
            >
              <span className="fw-bolder">{nextLabel}</span>
            </ButtonStyledMta>
          </div>
        )}
      </div>

      {errorMsg && (
        <div className="row mt-3">
          <div className="col-12">
            <Error>{errorMsg}</Error>
          </div>
        </div>
      )}
    </div>
  );
};

export default ButtonPanel;
