import React, { useState, useEffect } from "react";

import useSessionTimeout from "moveToLibrary/hooks/useSessionTimeout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "siteFunctions/hooks";
import { useSelector } from "react-redux";
import { actions, selectors } from "siteStore";
import TimeoutView from "siteViews/Errors/Timeout";
import SiteLayoutError from "siteLayouts/Site/Error";

const Timeout = (props) => {
  const { children } = props;
  const [showTimeoutComponent, setShowTimeoutComponent] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(
    selectors.auth.isLoggedIn,
    require("lodash").isEqual
  );

  const [isTimeOut, fnClearTimeout] = useSessionTimeout({
    timeout: 1000 * 60 * 30, //30 minutes
    // timeout: 1000 * 5, //5 seconds
    // timeout: 1000 * 60 * 5, //5 minutes
    onTimeout: async () => {
      if (!isLoggedIn) return;

      await dispatch(actions.auth.logout());
      setShowTimeoutComponent(true);
    },
  });

  const onClearTimeout = async () => {
    await navigate("/");
    await fnClearTimeout();
    setShowTimeoutComponent(false);
  };

  if (showTimeoutComponent)
    return (
      <SiteLayoutError>
        <TimeoutView routeStart="/" clearTimeout={onClearTimeout} />
      </SiteLayoutError>
    );

  return <>{children}</>;
};

export default Timeout;
