const fnOutputErrorInfo = (...args) => {
  console.log("********************************************");
  console.log("ERRORINFO:", ...args);
  console.log("********************************************");
};

import _ from "lodash";

// import { fnIsArray } from "../utils";
import riskUtils from "../utils/risk";
import templateUtils from "../utils/template";

import fnGenerateLegacy from "./generateLegacy";
import {
  fnCreate,
  fnUpdate,
  fnUpdateAddSubKey,
  fnUpdateRemoveSubKey,
} from "./baseFunctions";

class processor {
  constructor(args = {}) {
    ["rules", "state"].forEach((key) => {
      if (!(key in args)) {
        throw `Error in processor() -- missing "${key}"`;
      }
    });
    console.log("CREATE PROCESSOR:", args.debugData, { args });

    this.template = args.template;
    this.debugData = args.debugData;
    this.stateInit = _.cloneDeep(args.state);
    this.state = args.state;
    this.runHistoryPathCount = {};
    this.runHistoryRules = {};
    this.rules = args.rules;
    this._console = args._console || console;
  }

  debugData() {
    this._console.log("DEBUG", "processor", { ...this });
  }

  hasChanged(path) {
    // NOTE: oldItem could be undefined as we've just created the item

    const oldItem = riskUtils.find.item(this.stateInit, path);
    const newItem = riskUtils.find.item(this.state, path);

    // NONE ARRAY
    const retValue = (function () {
      if (riskUtils.is.dataNodeArray(oldItem)) {
        // Check for added or removed ids
        const listOld = oldItem?._arrayData.map((x) => x.id);
        const listNew = newItem?._arrayData.map((x) => x.id);

        if (listOld.some((idOld) => !listNew.includes(idOld))) return true;
        if (listNew.some((idNew) => !listOld.includes(idNew))) return true;

        return false;
        return !_.isEqual(oldItem?._arrayData, newItem?._arrayData);
      }

      if (riskUtils.is.dataNode(oldItem)) {
        return !_.isEqual(oldItem?._value, newItem?._value);
      }
    })();

    // if (path === "Risk/AdditionalInsuredSet") {
    //   console.log("DDDDDDDDD HASCHANGED ", path, {
    //     retValue,
    //     oldItem,
    //     newItem,
    //     arrayOld: oldItem._arrayData,
    //     arrayNew: newItem._arrayData,
    //   });
    // }
    return retValue;
  }

  runRules(updatePath = "", options = {}) {
    const fnLog = this._console.log;
    // const _console = this._console;
    const state = this.state;
    const {
      isPostSalusLoad = false,
      isPostRegistration = false,
      debugInfo,
    } = options;

    const persistedData = {}; // Data that we'll be persisted between recursive calls

    if (!_.isString(updatePath)) {
      fnLog("ERROR INFO", { updatePath, options });
      throw `Error in runRules -- updatePath is not a string`;
    }

    const _fnRunRuleRecursive = (updatePath, level = 1) => {
      const rules = this.rules;
      const template = this.template;
      // Remaps the mappings to genuine routes
      const fnCreateNewMappings = (ruleData) => {
        //** update mappingBase with genuine values
        //** update mappings with genuine values

        // Risk/AdditionalInsuredSet[0]/Name/Surname
        // Risk/AdditionalInsuredSet[0]
        // replace all mappings Risk/AdditionalInsuredSet[] with Risk/AdditionalInsuredSet[0]

        // Update mappingBase
        // Do a replace of all the mappings
        // Check if there's any mappings which don't have a mapping base

        if (!ruleData.mappingBase) return ruleData.mappings;

        const _mappingBase = ruleData.mappingBase
          .split("/")
          .map((segmentValue, i) => {
            const curPathValue = updatePathArray[i];
            if (curPathValue === segmentValue) return segmentValue;
            // If array, return the curPathValue
            if (
              riskUtils.searchPath.array.isArray(segmentValue) &&
              riskUtils.searchPath.array.isArrayWithIndex(curPathValue)
            )
              return curPathValue;

            // This is the error
            fnLog("ERRORINFO:", {
              segmentValue,
              updatePathArray,
              i,
              curPathValue,
              ruleData,
            });
            throw `Error in runRules -- can't find "${curPathValue}" in "${updatePath}"`;
          })
          .join("/")
          .replaceAll("/[", "[");

        const retData = Object.fromEntries(
          Object.entries(ruleData.mappings)
            .map(([k, d]) => {
              if (d.startsWith("/")) return [k, [_mappingBase, d].join("")];

              return [k, d];
            })
            .map(([k, d]) => {
              if (d.includes("[]")) {
                fnLog("ERRORINFO:", {
                  updatePathArray,
                  ruleData,
                  d,
                });
                throw `Error in runRules -- found mapping with [] "${d}"`;
              }
              return [k, d];
            })
        );

        return retData;
      };

      const fnGetRulesByMappings = (updatePath) => {
        const updatePathArray = updatePath.split("/");
        return (
          Object.entries(rules)
            // Filter for where the pathlist matches the path
            .filter(([ruleName, ruleData]) => {
              return ruleData.paths.some((rulePath) => {
                return rulePath.split("/").every((segmentValue, i) => {
                  if (segmentValue === "*") return true;

                  if (riskUtils.searchPath.array.isArray(segmentValue)) {
                    if (
                      !riskUtils.searchPath.array.isArrayWithIndex(
                        updatePathArray[i]
                      )
                    )
                      return false;

                    if (
                      riskUtils.searchPath.array.parse(segmentValue).path ===
                      riskUtils.searchPath.array.parse(updatePathArray[i]).path
                    )
                      return true;

                    return false;
                  }
                  if (segmentValue === updatePathArray[i]) return true; // Case sensitive
                  return false;
                });
              });
            })
            .map(([ruleName, ruleData]) => {
              // Update the mappings with actual values
              const newMappings = fnCreateNewMappings(ruleData);

              return {
                ruleName: ruleName,
                fn: ruleData.fn,
                mappings: newMappings,
                functionArgs: ruleData.functionArgs,
                isLegacy: ruleData.isLegacy,
                errorKey: ruleData.errorKey,
              };
            })
        );
      };
      // console.log("ddddddd", { ruleHistory });
      if (updatePath.includes("*"))
        throw `Error in fnRunRules -- can't have "*"`;
      if (!updatePath) {
        fnOutputErrorInfo({ options, debugInfo });
        throw `Error in runRules -- missing updatePath`;
      }
      //NOTE: We need to allow _fnRunRule rule to run multiple times for the same "updatePath"
      // as e.g. a rule can update the value, and we need to update the error

      // if (updatePath in this.runHistoryPathCount) {
      //   fnLog(`runRules -- already ran "${updatePath}" -- exiting`);
      //   return;
      // }

      this.runHistoryPathCount[updatePath] =
        this.runHistoryPathCount[updatePath] + 1;

      //Safety check:
      if (this.runHistoryPathCount[updatePath] > 20) {
        throw `Error in processor -- ran the rules for path "${updatePath}" too many times"`;
      }

      const updatePathArray = updatePath.split("/");

      // Find the rule
      //todo: caching?

      this._console.time("FINDING RULES");

      const foundRules = (function () {
        const useNewMethod = true;

        // ARRAY (NOT an ACTION)
        // console.log("ddddddd", updatePath);
        if (riskUtils.searchPath.array.isArrayWithIndex(updatePath)) {
          const cleanPath = updatePath
            .split("/")
            .map((x, i, arr) => {
              if (i === arr.length - 1) {
                return riskUtils.searchPath.array.clean(x);
              }
              return x;
            })
            .join("/");
          //fnCleanArray

          const foundRules = fnGetRulesByMappings(cleanPath);
          // console.log("ddddddddddddddddd  ARRAY", updatePath, cleanPath, {
          //   foundRules,
          //   rules,
          //   template,
          // });
          return foundRules;
        }

        //NOT ARRAY ACTION
        if (!riskUtils.searchPath.array.isArray(updatePath)) {
          if (useNewMethod) {
            const _foundTemplateRules = templateUtils.getData(
              template,
              updatePath.split("/")
            )?.data?.ruleList;

            if (!_foundTemplateRules) return [];
            return _foundTemplateRules.map((ruleName) => {
              const ruleData = rules[ruleName];
              const newMappings = fnCreateNewMappings(ruleData);
              return {
                ruleName: ruleName,
                fn: ruleData.fn,
                mappings: newMappings,
                functionArgs: ruleData.functionArgs,
                isLegacy: ruleData.isLegacy,
                errorKey: ruleData.errorKey,
              };
            });
          }
          //Version 1 below DO NOT DELETE
          if (!useNewMethod) {
            return fnGetRulesByMappings(updatePath);
          }
        }

        // ARRAY  ACTION
        if (riskUtils.searchPath.array.isArrayAction(updatePath)) {
          // We don't want to run any rules on an ARRAY ACTION
          return [];
        }

        return [];
      })();

      this._console.timeEnd("FINDING RULES");

      const updatePathClean =
        riskUtils.searchPath.array.removeAction(updatePath);

      this._console.groupCollapsed(
        "PROCESSOR().runRule",
        `"${updatePath}"`,
        `(${foundRules.length})`
      );
      this._console.log("updatePathClean:", updatePathClean);
      this._console.log("rules:", rules);
      this._console.log("template:", template);
      foundRules.forEach((x) => this._console.log("RULE:", x.ruleName));
      this._console.groupEnd();

      // console.log("ddddd foundRules", foundRules);

      // Run the rules
      foundRules
        // .filter((x) => {
        //   // ruleHistory check
        //   console.log("ddddd", { ruleHistory, updatePath, x });
        //   if (
        //     ruleHistory.some(
        //       (his) => his.path === updatePath && his.ruleName === x.ruleName
        //     )
        //   )
        //     return false;

        //   return true;
        // })

        .forEach(
          ({
            errorKey,
            isLegacy = false,
            fn,
            ruleName,
            mappings = {},
            functionArgs = {},
          }) => {
            this._console.time(
              ["PROCESSOR RUN RULE", updatePath, ruleName].join(" ")
            );
            this.runHistoryRules[ruleName] = true;

            if (isLegacy) {
              const { pathListBatch = [] } = options;
              // console.log("pathListBatch",pathListBatch)
              // throw `hhh`

              const protectedList = (function () {
                if (!isPostRegistration) return [];

                // console.log("ddddddddddd protectedList", updatePath, {
                //   pathListBatch,
                //   isArrayWithIndex:
                //     riskUtils.searchPath.array.isArrayWithIndex(updatePath),
                //   included: pathListBatch.includes(updatePath),
                // });

                if (
                  !riskUtils.searchPath.array.isArrayWithIndex(updatePath) &&
                  !pathListBatch.includes(updatePath)
                ) {
                  return [...pathListBatch, updatePath];
                }

                return pathListBatch;
              })();

              const legacy = fnGenerateLegacy(state, {
                persistedData: persistedData,
                _console: this._console,
                errorKey: errorKey || ruleName,
                ruleName,
                mappings,
                updatePath: updatePathClean,
                isPostSalusLoad,
                isPostRegistration,

                fnRunRule: (updatePath) =>
                  _fnRunRuleRecursive(updatePath, level + 1),
                fnHasChanged: (path) => this.hasChanged(path),
                protectedList: protectedList,
                level: level,

                // fnIsNew: (path) => {
                //   console.log("TODO: isNew");
                //   return this.isNew(path);
                // },
                // ruleHistory: [
                //   ...ruleHistory,
                //   { ruleName: ruleName, path: updatePathClean },
                // ],
              });

              // this._console.groupCollapsed(
              //   "PROCESSOR().runRule",
              //   updatePath,
              //   ruleName
              // );
              // this._console.groupEnd();

              if (legacy.runRule) {
                fn({
                  dataHelper: legacy.dataHelper,
                  dataSet: legacy.dataSet,
                  functionArgs: { ...mappings, ...functionArgs },
                  state: riskUtils.find.data(state),
                  executionPath: updatePathClean,
                  console: this._console,
                  persistedData: persistedData,
                });
              }
            } else {
              fn(
                {
                  state: riskUtils.find.data(state),
                  persistedData: persistedData,
                  updatePath: updatePathClean,
                  isPostSalusLoad,
                  isPostRegistration,
                },
                {
                  path: updatePathClean,
                }
              );
              // throw `Not yet implemented -- runRules isLegacy = false`;
            }
            this._console.timeEnd(
              ["PROCESSOR RUN RULE", updatePath, ruleName].join(" ")
            );
          }
        );

      // console.groupEnd();
    };

    this.runHistoryPathCount[updatePath] = 0;
    _fnRunRuleRecursive(updatePath);
  }

  runRulesBatch(pathList = [], options = {}) {
    if (pathList.length === 0) return;

    pathList.forEach((path) => {
      this.runRules(path, { ...options, pathListBatch: pathList });
    });
  }
  testState() {
    const state = this.state;

    console.groupCollapsed("TESTING");

    const hitlist = [];

    riskUtils.process.all(state, "Risk", (obj, args = {}) => {
      hitlist.push({ path: args.searchPath.join("/"), value: obj._value });
    });

    hitlist.forEach((x) => {
      console.groupCollapsed("Runing rules for", x.path, x.value);
      this.runRules(x.path);
      console.groupEnd();
    });

    console.groupEnd();
  }

  create(path) {
    return fnCreate(this.state, path);
  }

  updateValue(path, value) {
    const state = this.state;
    return fnUpdate(state, path, "_value", value);
  }
  updateHidden(path, value) {
    const state = this.state;
    return fnUpdate(state, path, "_hidden", value);
  }

  updateErrorShow(path, value) {
    const state = this.state;
    return fnUpdate(state, path, "_errorShow", value);
  }

  updateErrorAdd(path, errorKey, description) {
    const state = this.state;
    return fnUpdateAddSubKey(state, path, "_error", errorKey, description);
  }

  updateErrorRemove(path, errorKey) {
    const state = this.state;
    return fnUpdateRemoveSubKey(state, path, "_error", errorKey);
  }
}

export default processor;
