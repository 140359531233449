import React, { useEffect } from "react";
import config from "config";
import _ from "lodash";
import { useSelector } from "react-redux";
import { selectors, actions, helpers } from "siteStore";
import { useDispatch } from "siteFunctions/hooks";

import GlobalState from "@library/common/components/devTools/GlobalState";
import { database as carRiskDatabase } from "moveToLibrary/database/stella/carRisk";

import ReduxQuoteAndBuy from "./QuoteAndBuy";

import {
  Button,
  DevContainer,
  VehicleDetails,
  ButtonStyled,
  ButtonOutline,
  PanelHeader,
  RegPlate,
  CarIcon,
  BadgesPolicyStatus,
  CardHeaderVehicleStatus,
  TelephoneService,
  TextBox,
  Error,
  JsonViewer,
} from "siteComponents";

const EditLandingView = (props) => {
  const dispatch = useDispatch();

  const myCount = useSelector(
    selectors.carMta.getCounter,
    require("lodash").isEqual
  );
  // const documentList = useSelector(selectors.documents.selectAll);

  useEffect(() => {
    // console.log("dddd", actions.carMta.getServerStatus())
    dispatch(actions.carMta.getServerStatus());
    // dispatch(actions.documents.getDocuments());
  }, []);

  // console.log("documentList", documentList);

  return (
    <div>
      <p className="h2">Edit TEST view</p>
      {/* <button
        aria-label="Increment value"
        onClick={() => dispatch(actions.carMta.helloWorld.increment())}
      >
        Increment
      </button> */}
      {/* <span>{count}</span> */}
      {/* <button
        aria-label="Decrement value"
        onClick={() => dispatch(actions.carMta.helloWorld.decrement())}
      >
        Decrement
      </button> */}
      Count= {myCount}
      <button
        aria-label="helloWorldRequest"
        onClick={() => dispatch(actions.carMta.helloWorldRequest())}
      >
        helloWorldRequest
      </button>
      <button
        aria-label="Get documents"
        onClick={() => dispatch(actions.documents.getDocuments())}
      >
        Get documents
      </button>
      <button
        aria-label="Login"
        onClick={() => dispatch(actions.security.login())}
      >
        Login
      </button>
    </div>
  );
};

const TestView = () => {
  if (!config.isDev) return null;

  return (
    <div>
      <p> THis is the TEST VIEW</p> <ReduxQuoteAndBuy />
    </div>
  );
};
export default TestView;
