import React, { useState, useEffect, useRef } from "react";
import MUICheckbox from "@mui/material/Checkbox";

const sxData = {
  padding: 0,
  margin: 0,
  color: "#7975be",
  "&.Mui-checked": {
    color: "#7975be",
  },
};

const Checkbox = (props) => {
  const {
    checked = false,
    onSelect = () => {},
    onUnselect = () => {},
    disabled,
    ...otherProps
  } = props;

  return (
    <MUICheckbox
      checked={checked}
      onChange={(e) => {
        const _checked = e.target.checked;
        if (_checked) onSelect();
        else onUnselect();
      }}
      disabled={disabled}
      {...otherProps}
      sx={sxData}
    />
  );
};

export default Checkbox;
