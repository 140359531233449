import React, { useEffect } from "react";
import Base from "./Base";
import { useLocation } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

import {
  DevContainer,
  JsonViewer,
  ButtonStyled,
  TelephoneService,
  PanelRenewal,
} from "siteComponents";
import dateHelpers from "@library/common/helpers/misc/date";
import dayjs from "dayjs";

const fnFormatDate = (d) => {
  if (!d) throw `Error in fnFormatDate -- passed in empty value`;
  return dayjs(d).format("DD MMM YYYY");
};

const RenewalCompleteView = (props) => {
  const location = useLocation();
  const commonData = useOutletContext();

  const dataWrapup = location?.state?.dataWrapup;

  console.groupCollapsed("RENEWAL/Wrapup.jsx");
  console.log({ dataWrapup, location });
  console.groupEnd();

  if (!dataWrapup) return commonData.functions.navigate.components.exit;

  // Saftey check.
  if (!["AP"].includes(dataWrapup.purchaseType)) {
    throw `Error in RENEWAL/Wrapup.jsx -- unknown wrapup type`;
  }

  // **********************************************
  // EFFECT
  // **********************************************
  useEffect(() => {
    const myFn = async () => {
      await commonData.functions.process.reset();
      await commonData.functions.process.refreshUserInfo();
    };
    myFn();
  }, []);

  // **********************************************
  // VARS
  // **********************************************
  //TODO
  const startDate = dateHelpers.toDate(dataWrapup.startDate);
  const isWrapupComplete = dataWrapup.isWrapupComplete;

  console.log("Complete.jsx", {
    dataWrapup,
    startDate,
    isWrapupComplete,
  });

  // **********************************************
  // FINALISE
  // **********************************************

  const [heading, displayData] = (function () {
    const fragmentButtonExit = (
      <ButtonStyled
        onClick={commonData.functions.navigate.exit}
        data-cy="btn-renewal-exit"
      >
        Return home
      </ButtonStyled>
    );

    if (!isWrapupComplete) {
      return [
        "Thank you, your renewal is being processed",
        <PanelRenewal data-cy="wrapup-failure">
          <p>
            Your policy will be effective from{" "}
            <strong>{fnFormatDate(startDate)}</strong>.
          </p>

          {dataWrapup.emailAddress && (
            <p>
              A confirmation email will be sent to{" "}
              <strong>{dataWrapup.emailAddress}</strong> once we've completed
              processing your renewal.
            </p>
          )}
          <p>
            If you have any questions, please don't hesitate to get in touch
            with us on <TelephoneService />.
          </p>

          {fragmentButtonExit}
        </PanelRenewal>,
      ];
    }

    return [
      "Thank you, your policy has been renewed",
      <PanelRenewal data-cy="wrapup-success">
        <p>
          Your policy will be effective from{" "}
          <strong>{fnFormatDate(startDate)}</strong>.
        </p>
        {dataWrapup.emailAddress && (
          <p>
            A confirmation email has been sent to{" "}
            <strong>{dataWrapup.emailAddress}</strong>.
          </p>
        )}
        <p>
          If you have any questions, please don't hesitate to get in touch with
          us on <TelephoneService />.
        </p>

        {fragmentButtonExit}
      </PanelRenewal>,
    ];
  })();

  return (
    <Base
      pageId="COMPLETE"
      headingText={heading}
      className="renewal-complete"
      data-cy="renewal-complete"
      showSessionSaving={false}
    >
      {displayData}
    </Base>
  );
};

export default RenewalCompleteView;
