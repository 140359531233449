import React from "react";

const PriceCard = (props) => {
    const { dataCy, priceItem, footerContent, isSelected } = props;
  
    const _className = [
      "card",
      "mta-price-card",
      //"h-md-100",
      "border-0",
      "bg-transparent",
      isSelected ? "is-selected" : "",
    ].toClassName();
  
    return (
      <div className={_className} data-cy={dataCy}>
        <div className="card-header border-0 bg-transparent">
          {priceItem}
        </div>
        {footerContent && (
          <div className="card-footer border-0 bg-transparent">
            <small>
              {footerContent}
            </small>
          </div>
        )}
      </div>
    );
}

export default PriceCard;