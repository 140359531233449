// features/Documents/DocumentsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  createFnGenerateAction,
  createFnGenerateActionWithThunkAPI,
} from "../common";

const generateStore = (args = {}) => {
  const { storeName, services, selectors = {} } = args;

  const fnGenerateAction = createFnGenerateAction(storeName);
  const fnGenerateActionWithThunkApi =
    createFnGenerateActionWithThunkAPI(storeName);

  const getDocument = fnGenerateAction("getDocument", services.getDocument);

  // const getPolicyDocuments = fnGenerateActionWithThunkApi(
  //   "getPolicyDocuments",
  //   async (payload, thunkApi) => {
  //     const newPayload = { ...payload };

  //     //Auto add the userId if possible
  //     if (selectors && selectors.auth) {
  //       const state = thunkApi.getState();
  //       newPayload.userId = selectors.dashboard.getUserId(state);
  //     }

  //     return await services.getPolicyDocuments(newPayload);
  //   }
  // );

  const getPolicyDocuments = fnGenerateAction(
    "getPolicyDocuments",
    services.getPolicyDocuments
  );

  const rehydratePolicyDocuments = fnGenerateActionWithThunkApi(
    "rehydratePolicyDocuments",
    async (payload, thunkApi) => {
      const newPayload = { ...payload };

      //Auto add the userId if possible
      // if (selectors && selectors.auth) {
      //   const state = thunkApi.getState();
      //   newPayload.userId = selectors.dashboard.getUserId(state);
      // }

      return await services.getPolicyDocuments(newPayload);
    }
  );

  // const setDocumentDownloaded = fnGenerateActionWithThunkApi(
  //   "setDocumentDownloaded",
  //   async (payload, thunkApi) => {
  //     const newPayload = { ...payload };

  //     //Auto add the userId if possible
  //     // if (selectors && selectors.auth) {
  //     //   const state = thunkApi.getState();
  //     //   newPayload.userId = selectors.dashboard.getUserId(state);
  //     // }

  //     return await services.setDocumentDownloaded(newPayload);
  //   }
  // );

  const uploadStatusUpdate = fnGenerateActionWithThunkApi(
    "uploadStatusUpdate",
    async (payload, thunkApi) => {
      return payload;
    }
  );

  const upload = fnGenerateActionWithThunkApi(
    "upload",
    async (payload, thunkApi) => {
      // console.log("ddd payload", payload);
      //Auto add the userId if possible

      // const userId = (function () {
      //   if (selectors && selectors.auth) {
      //     const state = thunkApi.getState();
      //     return selectors.dashboard.getUserId(state);
      //   }
      // })();

      const { files, webReference, comments, policyId } = payload;

      let _remainingUploads = undefined;
      let additionalPayload = {};

      // *******************************************************
      // LOOP
      // *******************************************************
      // NOTE: we can't use files.forEach() as it doesn't cater for await
      for (let i = 0; i < files.length; i++) {
        const {
          data,
          uploadType,
          onStart = () => console.log("not implemented", "onStart"),
          onSuccess = () => console.log("not implemented", "onSuccess"),
          onError = (msg) => console.log("not implemented", "onError", msg),
        } = files[i];

        try {
          const _payload = {
            files: [{ data, description: uploadType }], // overwrite the files in "payload" with the individual file
            webReference: webReference,
            policyId: policyId,
            ...(i === 0 ? { comments } : {}), //Only add comments on the first loop
            ...additionalPayload, // Add the diaryId etc.
          };

          onStart();

          const response = await services.upload(_payload);

          if (response && response.isSuccess) {
            onSuccess();

            if ("remainingUploads" in response) {
              _remainingUploads = response.remainingUploads;
            }

            additionalPayload = {
              ...additionalPayload,
              ...("diaryId" in response ? { diaryId: response.diaryId } : {}),
              ...("taskId" in response ? { taskId: response.taskId } : {}),
            };
          } else {
            console.error(
              "document",
              "upload",
              "none successs",
              response.message
            );

            onError(response.message);
          }
        } catch (e) {
          console.error("document", "upload", "error", e);
          onError(e.message);
        }
      }
      // *******************************************************
      // END LOOP
      // *******************************************************

      if (_remainingUploads === undefined) return {};

      return {
        remainingUploads: _remainingUploads,
      };
    }
  );

  // const reset = createAsyncThunk(`${storeName}/reset`, async () => {});

  return {
    getPolicyDocuments,
    // setDocumentDownloaded,
    getDocument,
    rehydratePolicyDocuments,
    upload,
    uploadStatusUpdate,
  };
};

export default generateStore;
