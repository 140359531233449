import React from "react";

const OpexIcon = (props) => {
	const { GroupCode, className } = props;
	const _className =
		[
			`icon-st-opex-${GroupCode}`,
			className
		]
		.filter((x) => x)
		.join(" ");
		
	return <span className={_className} />;
};

export default OpexIcon;
